import { Col, Row, Space, Typography, Image, Carousel } from "antd";

const { Text, Title } = Typography;

const ConversationGBM = ({ transcript }) => {
  return (
    <Space size={10} style={{ alignItems: "start" }}>
      {transcript.content && (
        <Row>
          <Col span={24}>
            <Space
              size={10}
              direction="vertical"
              style={{
                width: 300,
                border: "1px solid #C0C0C0",
                padding: 10,
                borderRadius: 10,
              }}
            >
              {transcript.type === "richCard" &&
                transcript.content.standaloneCard && (
                  <>
                    <Image
                      preview={false}
                      src={
                        transcript.content.standaloneCard.cardContent.media &&
                        transcript.content.standaloneCard.cardContent.media
                          .contentInfo.fileUrl
                      }
                    />
                    <Text>
                      {
                        transcript.content.standaloneCard.cardContent
                          .description
                      }
                    </Text>
                  </>
                )}
              {transcript.type === "richCard" &&
                transcript.content.carouselCard && (
                  <>
                    <Carousel autoplay>
                      {transcript.content.carouselCard.cardContents?.map(
                        (cardContent) => {
                          return (
                            <Space>
                              <Title level={5}>{cardContent.title}</Title>
                              <Image
                                preview={false}
                                src={cardContent?.media?.contentInfo.fileUrl}
                              />
                              <Text>{cardContent.description}</Text>
                            </Space>
                          );
                        }
                      )}
                    </Carousel>
                  </>
                )}
            </Space>
          </Col>
        </Row>
      )}
    </Space>
  );
};

export default ConversationGBM;
