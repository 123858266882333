import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import useAccountUsersTableColumns from "./AccountUsersTableColumns";
import { useWindowSize } from "../../../shared-components/PageReSize";

const AccountUsersTable = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const { dataColumns, actionColumns } = useAccountUsersTableColumns();
  const [height] = useWindowSize();

  const searchText =
    state.filters.searchText["account-users-search-text-box"] &&
    state.filters.searchText["account-users-search-text-box"];
  let filteredUsers = state.accountUsers.users;
  if (searchText) {
    filteredUsers = state.accountUsers.users.filter((entry) => {
      return (
        entry.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
        entry.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        entry.lastName.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <>
      <Table
        columns={dataColumns.concat(actionColumns)}
        dataSource={filteredUsers}
        onChange={(pagination, filters, sorter) => {
          dispatchReducer({
            type: "RD_SET_FILTERED_INFO",
            payload: {
              component: "account-user-table",
              filteredInfo: filters,
            },
          });
          dispatchReducer({
            type: "RD_SET_SORTED_INFO",
            payload: {
              component: "account-users-table",
              sortedInfo: sorter,
            },
          });
        }}
        rowKey="rowKey"
        showSorterTooltip={false}
        pagination={{
          pageSize: Math.round((height - 250) / 45),
          showTotal: () => "Total : " + filteredUsers.length,
        }}
      />
    </>
  );
};

export default AccountUsersTable;
