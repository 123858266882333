import React from "react";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { Bar } from "@ant-design/plots";
import { Row, Col, Tooltip, Typography, Empty } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const IntentsChart = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchMiddleware({
        type: "MWD_LOAD_ANALYTICS_INTENTS_DATA",
        payload: {
          component: "analytics-intents-chart",
          analyticsFilterParams: {
            dateFilter: state.analytics.filterByDateForCharts,
            channelFilter: state.analytics.filterByChannelForCharts,
          },
        },
      });
    },
    {
      date: state.analytics.filterByDateForCharts,
      channel: state.analytics.filterByChannelForCharts,
      botInAction: state.application.botInAction,
    }
  );

  const color = [
    "#FF6B3B",
    "#626681",
    "#FFC100",
    "#9FB40F",
    "#76523B",
    "#DAD5B5",
    "#0E8E89",
    "#E19348",
    "#F383A2",
    "#247FEA",
    "#2BCB95",
    "#B1ABF4",
    "#1D42C2",
    "#1D9ED1",
    "#D64BC0",
    "#255634",
    "#8C8C47",
    "#8CDAE5",
    "#8E283B",
    "#791DC9",
  ];

  let intents =
    state.analytics.knowledgeAreaCharts.intents &&
    state.analytics.knowledgeAreaCharts.intents.find(
      (intents) =>
        intents._id === "Knowledge.KnowledgeBase.MTE2MTIzMjYwMzA1MDA0Mjk4MjQ"
    );
  if (intents) {
    intents._id = "KnowledgeBase";
  }

  const config = {
    color: color,
    data: state.analytics.knowledgeAreaCharts.intents,
    xField: "count",
    yField: "_id",
    seriesField: "_id",
    legend: {
      position: "right",
    },
  };

  return (
    <>
      {state.analytics.knowledgeAreaCharts.intents ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> Intent Count</Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This statistic provides the count of the client's desired intent, it displays the top intention of your clients."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.knowledgeAreaCharts.intents.length ? (
            <Bar {...config} style={{ height: "300px" }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-intents-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default IntentsChart;
