import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Dropdown,
  Row,
  Col,
  Typography,
  Input,
  Card,
  Space,
  Tooltip,
  Button,
} from "antd";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { BiBot } from "react-icons/bi";
import { HiLightBulb } from "react-icons/hi";
import { Translation } from "../../translation/translation";

const { Text } = Typography;

const ConversationSummaryPanel = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const translation = Translation().conversations.footer.conversationSummary;

  const spanRef = useRef(null);

  const [
    isConversationSummaryPanelVisible,
    setIsConversationSummaryPanelVisible,
  ] = useState(false);
  const [numberOfConversationToSummarize, setNumberOfConversationToSummarize] =
    useState(0);
  const [isTyping, setIsTyping] = useState(false);

  const [textWithDelay, setTextWithDelay] = useState("");

  let conversationSummary =
    state.conversations.ai.conversationSummary?.summary.replace(
      "<|im_end|>",
      ""
    );

  const isConversationSummaryEnabled =
    state.currentAccount.account.modules.conversation?.conversation_summary;

  // Setting the text with delay
  useEffect(() => {
    const typeDelay = 30;

    if (!conversationSummary) {
      return;
    }
    const typeText = () => {
      for (let index = 0; index <= conversationSummary.length; index++) {
        setTimeout(() => {
          setTextWithDelay(conversationSummary.slice(0, index));
        }, index * typeDelay);
      }
      setIsTyping(false);
    };
    typeText();
  }, [conversationSummary]);

  // Blinking the cursor
  useEffect(() => {
    const blinkInterval = setInterval(() => {
      if (spanRef.current && spanRef.current.style) {
        if (spanRef.current.style.visibility === "hidden") {
          spanRef.current.style.visibility = "visible";
        } else {
          spanRef.current.style.visibility = "hidden";
        }
      }
    }, 500);

    return () => {
      clearInterval(blinkInterval);
    };
  }, []);

  useEffect(() => {
    dispatchReducer({
      type: "CLEAR_CONVERSATION_SUMMARY",
    });
    setTextWithDelay(undefined);
    setNumberOfConversationToSummarize(0);
  }, [state.conversations.currentConversation]);

  return (
    <Dropdown
      trigger={["click"]}
      open={isConversationSummaryPanelVisible}
      onOpenChange={(visible) => {
        setIsConversationSummaryPanelVisible(visible);
      }}
      dropdownRender={() => (
        <div
          style={{
            height: 455,
            width: 400,
            borderRadius: 5,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            background: "white",
            padding: 12,
            marginBottom: 10,
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Space
                style={{
                  textAlign: "center",
                }}
                size={5}
              >
                <Text strong> Summarize conversation</Text>
                <BiBot
                  size={17}
                  style={{
                    color: "#F05742",
                    marginLeft: 5,
                  }}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical">
                <Text>Please enter the number of messages to summarize.</Text>
                <Space size={13}>
                  <Input
                    value={numberOfConversationToSummarize}
                    onChange={(e) => {
                      setNumberOfConversationToSummarize(e.target.value);
                    }}
                    style={{
                      width: 120,
                    }}
                  />
                  <Tooltip
                    title={
                      !isConversationSummaryEnabled
                        ? "Conversation summary feature not activated. Please contact iNextLabs"
                        : ""
                    }
                  >
                    <Button
                      type="primary"
                      disabled={
                        numberOfConversationToSummarize === "" ||
                        !/^[0-9]+$/.test(numberOfConversationToSummarize) ||
                        numberOfConversationToSummarize > 500 ||
                        !isConversationSummaryEnabled
                      }
                      onClick={() => {
                        dispatchMiddleware({
                          type: "SUMMARIZE_CONVERSATION",
                          payload: {
                            conversationId:
                              state.conversations.currentConversation._id,
                            numberOfConversationToSummarize:
                              numberOfConversationToSummarize === 0
                                ? 500
                                : numberOfConversationToSummarize,
                            component: "conversation-summary-panel",
                          },
                        });
                        setIsTyping(true);
                        setTextWithDelay(undefined);
                        dispatchReducer({
                          type: "CLEAR_CONVERSATION_SUMMARY",
                        });
                      }}
                    >
                      Summarize
                    </Button>
                  </Tooltip>
                </Space>
                {numberOfConversationToSummarize > 500 && (
                  <Text type="danger">Maximum 500 messages to summarize</Text>
                )}
              </Space>
            </Col>

            <Col span={24}>
              {isTyping || conversationSummary ? (
                <Card size="small">
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text strong>Summary:</Text>

                    {!isTyping && (
                      <Text>
                        Tokens:{" "}
                        {
                          state.conversations.ai.conversationSummary?.usage
                            ?.total_tokens
                        }
                      </Text>
                    )}
                  </Space>
                  <br />
                  <Text>
                    {textWithDelay && <Text>{textWithDelay}</Text>}

                    {isTyping && (
                      <span
                        ref={spanRef}
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          animation: "blink 0.5s linear infinite",
                        }}
                      >
                        |
                      </span>
                    )}
                  </Text>
                </Card>
              ) : (
                <Card>
                  <Space direction="vertical" size={50}>
                    <Card
                      size="small"
                      style={{
                        textAlign: "center",
                        color: "grey",
                        fontStyle: "italic",
                      }}
                    >
                      Your conversation summary will appear here.
                    </Card>

                    <Card
                      size="small"
                      style={{
                        color: "grey",
                        fontStyle: "italic",
                      }}
                    >
                      <Space style={{ textAlign: "center" }}>
                        <Text strong> Tips</Text>
                        <HiLightBulb
                          size={19}
                          style={{
                            color: "#fcd53f",
                          }}
                        />
                      </Space>
                      <br />
                      <Text>
                        1. Enter the number of messages to summarize. (Enter 0
                        to summarize all messages.)
                        <br />
                        2. Click the summarize button.
                      </Text>
                    </Card>
                  </Space>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      )}
    >
      <Tooltip title={translation.infoText}>
        <MdOutlineSpeakerNotes
          size={22}
          style={{
            color: isConversationSummaryPanelVisible
              ? "#F05742"
              : "rgb(108 107 107)",
            marginTop: "3px",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Dropdown>
  );
};

export default ConversationSummaryPanel;
