import { useState } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Radio,
  Input,
  Button,
  Divider,
  Space,
  Typography,
} from "antd";
import { IoCloseSharp } from "react-icons/io5";
import { appInsights } from "../../AppInsights";
import { URIs, mode } from "../../constants/env";
import { Translation } from "../../translation/translation";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";

const { Text, Link } = Typography;

const BroadcastPricingCalculator = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const [form] = Form.useForm();

  const translation = Translation().broadcastManager.header;

  const [recipientCountry, setRecipientCountry] = useState(null);
  const [messageType, setMessageType] = useState("marketingMessage");
  const [totalPrice, setTotalPrice] = useState(0);

  const broadcastPricing = state.broadcastManager.broadcastPriceList;

  const handleFormSubmit = (values) => {
    setTotalPrice(
      (
        values.receipientCount *
        broadcastPricing.find(({ country }) => country === recipientCountry)[
          messageType === "marketingMessage" ? "marketing" : "utility"
        ]
      ).toFixed(4)
    );

    appInsights.trackEvent({
      name: "Broadcast Price Calculated",
      properties: {
        environment: mode,
        messageType,
        recipientCountry: values.recipientCountry,
        clientId: state.currentAccount.account.client_id,
      },
    });
  };

  return (
    <Modal
      title={
        <Text strong>
          {state.formPanel["broadcast-pricing-calculator-form-panel"] &&
            state.formPanel["broadcast-pricing-calculator-form-panel"].title}
        </Text>
      }
      open={
        state.formPanel["broadcast-pricing-calculator-form-panel"] &&
        state.formPanel["broadcast-pricing-calculator-form-panel"].visible
      }
      footer={null}
      onCancel={() =>
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "broadcast-pricing-calculator-form-panel",
          },
        })
      }
      width={400}
    >
      {broadcastPricing ? (
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleFormSubmit}
          form={form}
          style={{
            marginTop: -10,
          }}
        >
          <Text>
            All broadcast messages will be charged as per Meta (Facebook)
            WhatsApp Business Platform pricing. Please see the {"  "}
            <Link
              href={
                URIs.DOCUMENTATIONS +
                "/docs/bot-settings/channels/whatsapp/waba_pricing.html"
              }
              target="_blank"
            >
              docs
            </Link>
            {"  "}
            for more details.
          </Text>

          <Divider />

          <Row gutter={16} align="bottom">
            {/* Receipient Country */}
            <Col span={24}>
              <Form.Item
                name="recipientCountry"
                label={
                  translation.broadcastPricingCalculator.broadcastPricingForm
                    .recipientCountryFormItem.label
                }
                rules={[
                  {
                    required: true,
                    message:
                      translation.broadcastPricingCalculator
                        .broadcastPricingForm.recipientCountryFormItem.required,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={
                    translation.broadcastPricingCalculator.broadcastPricingForm
                      .recipientCountryFormItem.placeholder
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase()?.includes(input.toLowerCase())
                  }
                  options={broadcastPricing.map(({ country }) => ({
                    label: country,
                    value: country,
                  }))}
                  onChange={(value) => {
                    setRecipientCountry(value);
                    setTotalPrice(0);
                  }}
                />
              </Form.Item>
            </Col>

            {/* Message Type */}
            <Col span={24}>
              <Form.Item
                name="messageType"
                label={
                  translation.broadcastPricingCalculator.broadcastPricingForm
                    .messageTypeFormItem.label
                }
                valuePropName="checked"
              >
                <Radio.Group
                  onChange={(e) => {
                    setMessageType(e.target.value);
                    setTotalPrice(0);
                  }}
                  value={messageType}
                >
                  <Radio value="marketingMessage">
                    {
                      translation.broadcastPricingCalculator
                        .broadcastPricingForm.messageTypeFormItem
                        .marketingMessage
                    }
                  </Radio>
                  <Radio value="utilityMessage">
                    {
                      translation.broadcastPricingCalculator
                        .broadcastPricingForm.messageTypeFormItem.utilityMessage
                    }
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {/* Recipient Count */}
            <Col span={24}>
              <Space>
                <Form.Item
                  name="receipientCount"
                  label={
                    translation.broadcastPricingCalculator.broadcastPricingForm
                      .recipientCountFormItem.label
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        translation.broadcastPricingCalculator
                          .broadcastPricingForm.recipientCountFormItem.required,
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message:
                        translation.broadcastPricingCalculator
                          .broadcastPricingForm.recipientCountFormItem.invalid,
                    },
                    {
                      validator: (_, value) =>
                        value <= 100000
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                translation.broadcastPricingCalculator.broadcastPricingForm.recipientCountFormItem.maximum
                              )
                            ),
                    },
                  ]}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Input
                    min={1}
                    placeholder={
                      translation.broadcastPricingCalculator
                        .broadcastPricingForm.recipientCountFormItem.placeholder
                    }
                    style={{ width: "100px" }}
                    onChange={() => setTotalPrice(0)}
                  />
                </Form.Item>

                {recipientCountry && messageType && (
                  <Space
                    style={{
                      marginTop: 38,
                    }}
                  >
                    <IoCloseSharp size={20} />

                    <Space
                      style={{
                        marginBottom: 5,
                      }}
                    >
                      {"$" +
                        broadcastPricing.find(
                          ({ country }) => country === recipientCountry
                        )[
                          messageType === "marketingMessage"
                            ? "marketing"
                            : "utility"
                        ]}
                    </Space>
                  </Space>
                )}
              </Space>
            </Col>

            <Col span={24}>
              <Divider />
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    {
                      translation.broadcastPricingCalculator
                        .broadcastPricingForm.calculateFormItem.buttonText
                    }
                  </Button>
                </Form.Item>

                {totalPrice !== 0 && (
                  <Space
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    {translation.broadcastPricingCalculator.broadcastPricingForm
                      .totalPrice.label +
                      ":" +
                      " "}

                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"$" + totalPrice}
                    </span>
                  </Space>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
      ) : (
        <ComponentLoader
          component={"broadcast-pricing-calculator-form-panel"}
          loadingMessage="Loading... Please wait..."
          errorMessage="Error loading pricing information. Please try again later."
        />
      )}
    </Modal>
  );
};
export default BroadcastPricingCalculator;
