import React, { useState, useEffect } from "react";
import { Drawer, Typography, AutoComplete, Row, Col } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import ContactManagerForm from "./ContactManagerForm";
import AssociateContacts from "./AssociateContacts";

const { Text } = Typography;

const ContactManagerFormPanel = ({ currentConversationChannel, type }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [selectedContact, setSelectedContact] = useState();
  const [addToExistContact, setAddToExistContact] = useState();
  const [addToContact, setAddToContact] = useState();

  let contacts = state.contactManager.contacts;

  // Remove all state while form panel is closed
  useEffect(() => {
    setAddToExistContact();
    setAddToContact();
    setSelectedContact();
  }, [state.formPanel["contact-manager-form-panel"]?.visible]);

  return (
    <Drawer
      title={
        addToExistContact
          ? "Add To Existing Contact"
          : addToContact
          ? "Create New Contact"
          : state.formPanel?.["contact-manager-form-panel"]?.title ?? null
      }
      width={720}
      destroyOnClose={true}
      open={
        state.formPanel["contact-manager-form-panel"] &&
        state.formPanel["contact-manager-form-panel"].visible
      }
      onClose={() => {
        addToExistContact &&
          dispatchMiddleware({
            type: "MWD_LOAD_CONTACT_MANAGER_DATA",
            payload: { component: "contact-manager-table" },
          });
        setAddToExistContact(false);
        setAddToContact(false);
        setSelectedContact();
        //In case the user would have selected contacts in Broadcast Manager and came to Contact Manager & unsubscribed users.
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "contact-manager-form-panel",
          },
        });
        dispatchReducer({
          type: "SET_FORM_MODE",
          payload: {
            component: "contact-manager-form",
            mode: "",
          },
        });

        dispatchReducer({
          type: "RD_CLEAR_ACTION_ROW_KEY",
          payload: { component: "client-responses-table" },
        });
        setAddToExistContact(false);
      }}
    >
      {state.apiCallStatus?.["contact-manager-associate-contact-form-panel"]
        ?.loading ? (
        <FullPageLoader
          component="contact-manager-associate-contact-form-panel"
          loadingMessage="Loading user details... Please wait..."
        />
      ) : (
        <>
          {/* For conversation/response add to contact */}
          {type === "associate_contact" &&
            !addToContact &&
            !addToExistContact && (
              <AssociateContacts
                setAddToContact={setAddToContact}
                setAddToExistContact={setAddToExistContact}
              />
            )}
          {/* For conversation/response add to exist contact */}
          {!state.apiCallStatus["contact-manager-form"]?.loading &&
            addToExistContact && (
              <Row
                style={{
                  marginBottom: 13,
                  paddingBottom: 15,
                  borderBottom: "1px solid #a8a8a8",
                }}
              >
                <Col span={12}>
                  <Text strong> Select Existing Contact</Text>
                </Col>

                {addToExistContact && (
                  <Col span={12}>
                    <AutoComplete
                      style={{ width: "97%", marginLeft: 12 }}
                      placeholder="Select contact"
                      value={
                        selectedContact?.firstName
                          ? selectedContact?.displayName +
                            " | " +
                            selectedContact?.firstName
                          : selectedContact?.displayName
                      }
                      options={contacts.map((contact, index) => {
                        return {
                          key: index,
                          label: contact.firstName
                            ? contact.displayName + " | " + contact.firstName
                            : contact.displayName,
                          value: contact.displayName,
                          contact,
                        };
                      })}
                      onSelect={(_, record) => {
                        setSelectedContact(record.contact);
                        dispatchReducer({
                          type: "SET_FORM_MODE",
                          payload: {
                            component: "contact-manager-form",
                            mode: "edit",
                          },
                        });
                      }}
                      onClear={() => setSelectedContact()}
                      filterOption={true}
                      allowClear
                    />
                  </Col>
                )}
              </Row>
            )}
          {(type !== "associate_contact" ||
            addToContact ||
            (addToExistContact && selectedContact)) && (
            <ContactManagerForm
              currentConversationChannel={currentConversationChannel}
              selectedContact={selectedContact}
              addToExistContact={addToExistContact}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export default ContactManagerFormPanel;
