import React from "react";
import { Row, Col, Card } from "antd";
import TicketsByAssigneeChart from "./TicketsByAssigneeChart";
import TicketsByStatusChart from "./TicketsByStatusChart";
import TicketsRaisedTodayDateChart from "./TicketsRaisedTodayDateChart";
import TicketsTimeTakenChart from "./TicketsTimeTakenChart";
import TicketsByCreatedChart from "./TicketsByCreatedChart";

const TicketsCharts = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <Card type="inner" style={{ height: 280 }}>
          <TicketsByCreatedChart />
        </Card>
      </Col>

      <Col span={8}>
        <Card type="inner" style={{ height: 280 }}>
          <TicketsByAssigneeChart />
        </Card>
      </Col>

      <Col span={8}>
        <Card type="inner" style={{ height: 280 }}>
          <TicketsByStatusChart />
        </Card>
      </Col>

      <Col span={8}>
        <Card type="inner" style={{ height: 280 }}>
          <TicketsTimeTakenChart />
        </Card>
      </Col>

      <Col span={16}>
        <Card type="inner" style={{ height: 280 }}>
          <TicketsRaisedTodayDateChart />
        </Card>
      </Col>
    </Row>
  );
};

export default TicketsCharts;
