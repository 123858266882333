const intentsAndPhrasesReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_INTENTS_AND_PHRASES":
      return {
        ...state,
        intentsAndPhrases: {
          ...state.intentsAndPhrases,
          ...{ inp: action.inp },
        },
      };

    case "RD_UPDATE_INTENTS_AND_PHRASES":
      return {
        ...state,
        intentsAndPhrases: {
          ...state.intentsAndPhrases,
          ...{
            inp: state.intentsAndPhrases.inp.map((inp) =>
              inp.displayName === action.payload.inp.displayName
                ? Object.assign({}, inp, action.payload.inp)
                : inp
            ),
          },
        },
      };

    case "RD_CLEAR_INTENTS_AND_PHRASES_DATA":
      return {
        ...state,
        intentsAndPhrases: {
          ...state.intentsAndPhrases,
          ...{ inp: undefined },
        },
      };

    case "RD_SET_TESTED_INTENT":
      return {
        ...state,
        intentsAndPhrases: {
          ...state.intentsAndPhrases,
          ...{ testedIntent: action.testResult },
        },
      };

    default:
      return state;
  }
};

export default intentsAndPhrasesReducer;
