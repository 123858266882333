import React from "react";
import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import useMessageTemplatesTableColumns from "./MessageTemplatesTableColumns";
import { useWindowSize } from "../../../shared-components/PageReSize";

const MessageTemplatesTables = () => {
  const [state] = useGlobalContext();
  const { dataColumns } = useMessageTemplatesTableColumns();
  const [height] = useWindowSize();

  const searchText =
    state.filters.searchText["message-templates-search-text-box"] &&
    state.filters.searchText["message-templates-search-text-box"];

  let filteredTemplates =
    state.messageTemplates.templates?.find(
      (template) => template.botId === state.application.botInAction
    )?.waba_templates || [];

  if (searchText) {
    filteredTemplates = filteredTemplates.filter((template) => {
      return (
        template.category.toLowerCase().includes(searchText.toLowerCase()) ||
        template.name.toLowerCase().includes(searchText.toLowerCase()) ||
        template.components
          .find((component) => component.type.toLowerCase() === "body")
          .text.toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <Table
      columns={dataColumns}
      dataSource={filteredTemplates}
      pagination={{
        pageSize: Math.round((height - 250) / 55),
        showTotal: () => "Total : " + filteredTemplates.length,
      }}
      rowKey="name"
    />
  );
};

export default MessageTemplatesTables;
