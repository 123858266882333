import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { mode } from "../constants/env";
import { appInsights } from "../AppInsights";
import Socket from "../socket";

export const useNotificationMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************* NOTIFICATIONS ***************////
      ////*******************************************////
      case "GET_ALL_NOTIFICATIONS":
        await callApi({
          urls: [
            apiEndPoints.NOTIFICATION.concat(
              "?userID=",
              state.currentUser.user.userID,
              "&pagesize=",
              action.payload.pageSize,
              "&pagenumber=",
              action.payload.pageNumber
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([notifications]) => {
            dispatchReducer({
              type: "SET_ALL_NOTIFICATIONS",
              payload: {
                notificationList: notifications,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Get Notification Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "REMOVE_ALL_NOTIFICATIONS":
        await callApi({
          urls: [
            apiEndPoints.NOTIFICATION.concat(
              "?userID=",
              state.currentUser.user.userID,
              "&isDeleteAll=",
              true
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "SET_REMOVE_ALL_NOTIFICATIONS",
              payload: {
                notifications: [],
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Remove Notification Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "SEND_NOTIFICATION":
        await callApi({
          urls: [apiEndPoints.NOTIFICATION],
          options: {
            method: "POST",
            body: action.payload.notification,
          },
          onSuccess: ([notification]) => {
            //emit - new notification to the all the users in their portal
            Socket.emit(
              "sendNotification",
              state.currentAccount.account.client_id,
              notification
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Send Notification Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "UPDATE_NOTIFICATION_STATUS":
        await callApi({
          urls: [apiEndPoints.NOTIFICATION.concat("?_id=", action.payload._id)],
          options: {
            method: "PUT",
            body: action.payload.notification,
          },
          onSuccess: ([updatedNotification]) => {
            dispatchReducer({
              type: "SET_UPDATED_NOTIFICATION_STATUS",
              payload: {
                notification: updatedNotification,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Notification Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "REMOVE_NOTIFICATION":
        await callApi({
          urls: [apiEndPoints.NOTIFICATION.concat("?_id=", action.payload._id)],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "SET_REMOVE_NOTIFICATION",
              payload: {
                _id: action.payload._id,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Remove Notification Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
