import { Col, Row, Space, Tabs } from "antd";
import TicketsHeader from "./TicketsHeader";
import TicketsTable from "./TicketsTable";
import TicketInfoPanel from "./TicketsInfoPanel";
import { useGlobalContext } from "../../contexts/globalContext";
import { TbListCheck } from "react-icons/tb";
import useConnectMiddleware from "../../connect/connectMiddleware";
import "./Tickets.less";
import ClientResponsesViewConversationPanel from "../client-responses/ClientResponsesViewConversationPanel";

const { TabPane } = Tabs;

const Tickets = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(() => {
    if (!state.accountUsers.users) {
      dispatchMiddleware({
        type: "MWD_LOAD_ACCOUNT_USERS_DATA",
        payload: { component: "account-users-table" },
      });
    }
  });

  return (
    <Tabs type="card">
      <TabPane
        tab={
          <Space size={15}>
            <TbListCheck size={18} style={{ margin: -4 }} /> Tickets
          </Space>
        }
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <TicketsHeader />
          </Col>
          <Col span={24}>
            <TicketsTable />
          </Col>
          <TicketInfoPanel />
          <ClientResponsesViewConversationPanel />
        </Row>
      </TabPane>
    </Tabs>
  );
};
export default Tickets;
