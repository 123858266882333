import { Drawer, Modal } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import WhatsAppFlowsForm from "./WhatsAppFlowsForm";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";

const { confirm } = Modal;

const WhatsAppFlowsFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Drawer
      title={state.formPanel?.["whatsapp-flows-form-panel"]?.title}
      width={800}
      destroyOnClose={true}
      open={state.formPanel?.["whatsapp-flows-form-panel"]?.visible}
      bodyStyle={{ backgroundColor: "#F4F7F9" }}
      maskClosable={false}
      onClose={() => {
        confirm({
          title: "Are you sure you want to close the flow?",
          content:
            "When clicked the OK button, the unsaved changes will be lost",
          onOk() {
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "whatsapp-flows-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "whatsapp-flows-form",
                mode: "",
              },
            });
            state.form?.["whatsapp-flows-form"].mode === "edit" &&
              dispatchReducer({
                type: "RD_CLEAR_ACTION_ROW_KEY",
                payload: { component: "whatsapp-flows-table" },
              });
            state.whatsappFlows.currentWhatsAppFlow &&
              dispatchReducer({
                type: "SET_CURRENT_WHATSAPP_FLOW",
                payload: {
                  currentWhatsAppFlow: null,
                },
              });
          },
          onCancel() {},
        });
      }}
    >
      {state.apiCallStatus["whatsapp-flows-gallery"] &&
      state.apiCallStatus["whatsapp-flows-gallery"].loading ? (
        <FullPageLoader
          component="whatsapp-flows-gallery"
          loadingMessage="Loading... Please wait..."
        />
      ) : (
        <WhatsAppFlowsForm />
      )}
    </Drawer>
  );
};

export default WhatsAppFlowsFormPanel;
