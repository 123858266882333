import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Space,
  Image,
  Input,
  Form,
  Divider,
  Button,
  Tabs,
} from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { SettingOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AccessDenied from "../../../shared-components/AccessDenied";

const { Text } = Typography;
const { TextArea } = Input;

const AccountGeneral = () => {
  const [state] = useGlobalContext();
  const navigate = useNavigate();

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {!state.currentUser.permission.ACCOUNT_GENERAL_VIEW ? (
        <AccessDenied />
      ) : (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <SettingOutlined size={18} style={{ margin: -4 }} />
                  General
                </Space>
              ),
              children: (
                <Form>
                  <Row gutter={24}>
                    <Col span={4}>
                      <Space
                        style={{ width: "100%", justifyContent: "center" }}
                      >
                        <Image
                          src={
                            state.currentAccount.account.general.company_logo
                          }
                          style={{
                            border: "1px solid #EFEFEE",
                            padding: 15,
                            width: 150,
                          }}
                          preview={false}
                        />
                      </Space>
                    </Col>
                    <Col span={1}>
                      <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    <Col span={19}>
                      <Row gutter={[0, 15]}>
                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Company</Text>
                            </Col>
                            <Col span={24}>
                              <Text type="secondary">
                                {state.currentAccount.account.general.company}
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Registered Name</Text>
                            </Col>
                            <Col span={24}>
                              <Text type="secondary">
                                {state.currentAccount.account.general.reg_name}
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Industry</Text>
                            </Col>
                            <Col span={24}>
                              <Text type="secondary">
                                {state.currentAccount.account.general.industry}
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Phone Number</Text>
                            </Col>
                            <Col span={24}>
                              <Input
                                disabled
                                defaultValue={
                                  state.currentAccount.account.general
                                    .phone_number
                                }
                                style={{ width: 500 }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Email</Text>
                            </Col>
                            <Col span={24}>
                              <Input
                                disabled
                                defaultValue={
                                  state.currentAccount.account.general
                                    .email_address
                                }
                                style={{ width: 500 }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Address</Text>
                            </Col>
                            <Col span={24}>
                              <TextArea
                                disabled
                                rows={4}
                                defaultValue={state.currentAccount.account.general.address.concat(
                                  "\n ",
                                  state.currentAccount.account.general.city.concat(
                                    "- ",
                                    state.currentAccount.account.general
                                      .postal_code
                                  )
                                )}
                                style={{ width: 500 }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Divider />
                          <Space size="small">
                            <Button disabled>Cancel</Button>
                            <Button type="primary" disabled>
                              Save
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default AccountGeneral;
