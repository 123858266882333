const broadcastManagerReducer = (state, action) => {
  switch (action.type) {
    case "SET_BROADCASTS":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{ broadcasts: action.broadcasts },
        },
      };

    case "CLEAR_BROADCASTS":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{ broadcasts: undefined },
        },
      };

    case "SET_BROADCAST_MANAGER_FILTERED_INFO":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            filters: {
              ...state.broadcastManager.filters,
              ...{ filteredInfo: action.filteredInfo },
            },
          },
        },
      };

    case "CREATE_BROADCAST":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            broadcasts: {
              ...state.broadcastManager.broadcasts,
              ...{
                // To initiate a conversation, a request for feedback is broadcasted to the user
                //If there are no broadcasts available, we cannot spread any broadcasts. Therefore, we handle this scenario by adding an empty array.
                data: [
                  action.broadcast,
                  ...(state.broadcastManager.broadcasts?.data || []),
                ],
              },
            },
          },
        },
      };

    case "DELETE_SINGLE_BROADCAST":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            broadcasts: {
              ...state.broadcastManager.broadcasts,
              ...{
                data: state.broadcastManager.broadcasts.data.filter(
                  (broadcast) =>
                    action.payload.actionRowKey !==
                    (broadcast.notificationID || broadcast._id)
                ),
              },
            },
          },
        },
      };

    case "SET_SINGLE_CONTACT_TO_POPULATE":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            singleContactToPopulate: action.payload.contact,
          },
        },
      };

    case "CLEAR_POPULATED_CONTACTS":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            singleContactToPopulate: undefined,
            multipleContactsToPopulate: undefined,
          },
        },
      };

    case "SET_MULTIPLE_CONTACTS_TO_POPULATE":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            multipleContactsToPopulate: action.payload.contacts,
          },
        },
      };

    case "SET_BROADCAST_PRICE_LIST":
      return {
        ...state,
        broadcastManager: {
          ...state.broadcastManager,
          ...{
            broadcastPriceList: action.payload.broadcastPriceList,
          },
        },
      };

    default:
      return state;
  }
};

export default broadcastManagerReducer;
