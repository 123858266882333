import React, { useReducer, useContext } from "react";
import { combineReducers } from "../reducers";
import applicationReducer from "../reducers/applicationReducer";
import accountUsersReducer from "../reducers/accountUsersReducer";
import accountBillingReducer from "../reducers/accountBillingReducer";
import currentAccountReducer from "../reducers/currentAccountReducer";
import currentUserReducer from "../reducers/currentUserReducer";
import contactManagerReducer from "../reducers/contactManagerReducer";
import broadcastManagerReducer from "../reducers/broadcastManagerReducer";
import analyticsReducer from "../reducers/analyticsReducer";
import messageTemplatesReducer from "../reducers/messageTemplatesReducer";
import supportTicketsReducer from "../reducers/supportTicketsReducer";
import clientResponsesReducer from "../reducers/clientResponsesReducer";
import apiCallStatusReducer from "../reducers/apiCallStatusReducer";
import botKnowledgebaseReducer from "../reducers/botKnowledgebaseReducer";
import formPanelReducer from "../reducers/formPanelReducer";
import infoPanelReducer from "../reducers/infoPanelReducer";
import filterPanelReducer from "../reducers/filterPanelReducer";
import conversationsReducer from "../reducers/conversationsReducer";
import filtersReducer from "../reducers/filtersReducer";
import formReducer from "../reducers/formReducer";
import questionsAndAnswersReducer from "../reducers/questionsAndAnswersReducer";
import intentsAndPhrasesReducer from "../reducers/intentsAndPhrasesReducer";
import liveAgentSettingsReducer from "../reducers/liveAgentSettingsReducer";
import broadcastSettingsReducer from "../reducers/broadcastSettingsReducer";
import dashBoardReducer from "../reducers/dashBoardReducer";
import responseSettingReducer from "../reducers/responsesSettingReducer";
import contactManagerSettingsReducer from "../reducers/contactManagerSettingsReducer";
import conversationFlowReducer from "../reducers/conversationFlowReducer";
import automatedrepliesReducer from "../reducers/automatedRepliesReducer";
import webhooksReducer from "../reducers/webhooksReducer";
import deliveryServicesReducer from "../reducers/deliveryServicesReducer";
import cannedResponsesReducer from "../reducers/cannedResponsesReducer";
import unrecognizedIntentsReducer from "../reducers/unrecognizedIntentsReducer";
import whatsappFlowsReducer from "../reducers/whatsappFlowsReducer";
import botConfigurationReducer from "../reducers/botConfigurationReducer";
import notificationReducer from "../reducers/notificationsReducer";
import { useApplicationMiddleware } from "../middleware/applicationMiddleware";
import { useBroadcastManagerMiddleware } from "../middleware/broadcastManagerMiddleware";
import { useContactManagerMiddleware } from "../middleware/contactManagerMiddleware";
import { useAccountUsersMiddleware } from "../middleware/accountUsersMiddleware";
import { useAccountBillingMiddleware } from "../middleware/accountBillingMiddleware";
import { useLiveAgentSettingsMiddleware } from "../middleware/liveAgentSettingsMiddleware";
import { useBroadcastSettingsMiddleware } from "../middleware/broadcastSettingsMiddleware";
import { useAccountApiMiddleware } from "../middleware/accountApiMiddleware";
import { useClientResponsesMiddleware } from "../middleware/clientResponsesMiddleware";
import { useMessageTemplatesMiddleware } from "../middleware/messageTemplatesMiddleware";
import { useSupportTicketstMiddleware } from "../middleware/supportTicketsMiddleware";
import { useQuestionsAndAnswersMiddleware } from "../middleware/questionsAndAnswersMiddleware";
import { useCurrentUserMiddleware } from "../middleware/currentUserMiddleware";
import { useConversationsMiddleware } from "../middleware/conversationsMiddleware";
import { useConversationsMiddlewarev2 } from "../middleware/conversationsMiddlewarev2";
import { useIntentsAndPhrasesMiddleware } from "../middleware/intentsAndPhrasesMiddleware";
import { useAnalyticsMiddleware } from "../middleware/analyticsMiddleware";
import { useDashBoardMiddleware } from "../middleware/dashBoardMiddleware";
import { useConversationFlowMiddleware } from "../middleware/conversationFlowMiddleware";
import { useFeedbackMiddleware } from "../middleware/feedbackFormMiddleware";
import { useResponsesSettingMiddleware } from "../middleware/responsesSettingMiddleware";
import { useContactManagerSettingsMiddleware } from "../middleware/contactManagerSettingsMiddleware";
import { useAutomatedRepliesMiddleware } from "../middleware/automatedRepliesMiddleware";
import { useWebhooksMiddleware } from "../middleware/webhooksMiddleware";
import { useDeliveryServicesMiddleware } from "../middleware/deliveryServicesMiddleware";
import { useCannedResponsesMiddleware } from "../middleware/cannedResponsesMiddleware";
import { useUnrecognizedIntentsMiddleware } from "../middleware/unrecognizedIntentsMiddleware";
import { useBotKnowledgebaseMiddleware } from "../middleware/botKnowledgebaseMiddleware";
import { useWhatsappFlowsMiddleware } from "../middleware/whatsappFlowsMiddleware";
import { useBotConfigurationMiddleware } from "../middleware/botConfigurationMiddleware";
import { useNotificationMiddleware } from "../middleware/notificationsMiddleware";

const initialState = {
  application: {
    botInAction: undefined,
    enums: undefined,
    applicationRoles: [],
    selectedLanguage: undefined,
  },
  currentAccount: {
    account: {},
    apiKey: undefined,
    pubSubTokenUrl: undefined,
    whatsappUsage: undefined, //WhatsApp usage of the bot-in-action (if bot has WhatsApp channel)
  },
  accountBilling: {
    subscription: undefined,
  },
  currentUser: {
    user: undefined,
    permission: {},
  },
  accountUsers: {
    users: undefined,
  },
  liveAgentSettings: undefined,
  broadcastSettings: undefined,
  responseSettings: {
    responseProperties: undefined,
    responseCategories: undefined,
    currentResponseCategory: undefined,
  },
  contactManagerSettings: {},
  automatedReplies: {
    broadcastAutomatedReplies: undefined,
  },
  webhooks: {
    webhooksList: undefined,
    responseValueByPropertyName: undefined,
  },
  deliveryServices: {
    lalamoveConfiguration: undefined,
  },
  cannedResponses: {
    liveAgentCannedResponses: undefined,
    cannedResponsesFolderList: undefined,
    currentCannedResponseFolder: undefined,
  },
  conversations: {
    contactsWithTranscripts: undefined,
    contactsCountInDatabase: undefined,
    currentConversation: {},
    lastUpdatedAt: undefined,
    liveConversations: [],
    waitingForLiveAgent: [],
    coAgentLiveConversations: [],
    onHoldConversations: [],
    unreadConversations: [], // Used in conversation v1
    unreadMessagesCount: 0, // Used in conversation v2
    newMessagePosition: undefined,
    //While click conversation icon in response table
    conversationFilter: undefined,
    liveAgentSettingsAcrossBots: undefined,
    conversationUserState: undefined,
    ai: {
      aiAssistantHelpText: undefined,
      conversationSummary: undefined,
    },
  },
  contactManager: {
    contacts: undefined,
    contactProperties: undefined,
    validContactsCount: undefined,
    importedContacts: undefined,
    //While importing contacts, mobile number/display name missing contacts
    invalidContacts: undefined,
  },
  questionsAndAnswers: {
    qna: undefined,
  },
  intentsAndPhrases: {
    inp: undefined,
    testedIntent: undefined,
  },
  messageTemplates: {
    templates: undefined,
  },
  unrecognizedIntents: undefined,
  botKnowledgebase: undefined,
  whatsappFlows: undefined,
  botConfiguration: undefined,
  broadcastManager: {
    broadcasts: undefined,
    singleContactToPopulate: undefined,
    multipleContactsToPopulate: undefined,
  },
  supportTickets: {
    tickets: undefined,
    ticketInfo: {},
  },
  clientResponses: {
    responses: undefined,
    responseCategories: undefined,
    responseCategoriesCollapsed: false,
    currentResponseCategory: undefined,
    currentResponse: undefined,
    responseCustomColumnDropdownValues: undefined,
    responsesTimeline: undefined,
  },
  analytics: {
    filterByDateForCharts: "thirtydays",
    filterByChannelForCharts: undefined,
    responsesCharts: {
      responsesByChannel: undefined,
      responsesByAction: undefined,
      responsesByAssignedPerson: undefined,
      responsesByDate: undefined,
    },
    broadcastCharts: {
      broadcastMessageByDate: undefined,
      broadcastMessageByTemplate: undefined,
      broadcastMessageByChannel: undefined,
      broadcastByTemplate: undefined,
      broadcastByChannel: undefined,
      broadcastByDate: undefined,
    },
    conversationCharts: {
      liveagentCountByChannel: undefined,
      conversationCount: undefined,
      conversationsByChannel: undefined,
      conversationsByDate: undefined,
      messagesByChannel: undefined,
      messagesByDate: undefined,
      conversationByBrowser: undefined,
      conversationByLocation: undefined,
      noneIntentMessage: undefined,
      activeAndInactiveUsersCount: undefined,
    },
    knowledgeAreaCharts: {
      unrecognizedIntentsByChannel: undefined,
      unrecognizedIntentsByDate: undefined,
      unrecognizedIntentsByAssignee: undefined,
      unrecognizedIntentsByStatus: undefined,
    },
    contactCharts: {
      contactByCreatedSource: undefined,
      contactSubscriptionStatus: undefined,
      contactByCountry: undefined,
    },
  },
  dashBoard: {
    broadcastsCount: undefined,
    broadcastsMessageSentCount: undefined,
    clientResponsesCount: undefined,
    conversationByDate: undefined,
    latestNews: undefined,
    waitingForLiveAgent: undefined,
    conversationByToday: undefined,
  },
  notification: {
    notifications: null,
    currentNotification: null,
  },
  conversationFlow: {
    flows: undefined,
  },

  tickets: undefined,

  apiCallStatus: {},
  filters: {
    searchText: {},
    sortedInfo: {},
    filteredInfo: {},
    selectedRowKeys: [],
    actionRowKey: "",
    pagination: undefined,
  },
  formPanel: {},
  infoPanel: {},
  form: {},
  filterPanel: {},
};

const rootReducer = combineReducers({
  application: applicationReducer,
  accountUsers: accountUsersReducer,
  accountBilling: accountBillingReducer,
  currentAccount: currentAccountReducer,
  currentUser: currentUserReducer,
  contactManager: contactManagerReducer,
  questionsAndAnswers: questionsAndAnswersReducer,
  intentsAndPhrases: intentsAndPhrasesReducer,
  messageTemplates: messageTemplatesReducer,
  botKnowledgebase: botKnowledgebaseReducer,
  whatsappFlows: whatsappFlowsReducer,
  botConfiguration: botConfigurationReducer,
  broadcastManager: broadcastManagerReducer,
  supportTickets: supportTicketsReducer,
  clientResponses: clientResponsesReducer,
  apiCallStatus: apiCallStatusReducer,
  formPanel: formPanelReducer,
  infoPanel: infoPanelReducer,
  filterPanel: filterPanelReducer,
  conversations: conversationsReducer,
  filters: filtersReducer,
  form: formReducer,
  analytics: analyticsReducer,
  liveAgentSettings: liveAgentSettingsReducer,
  broadcastSettings: broadcastSettingsReducer,
  dashBoard: dashBoardReducer,
  conversationFlow: conversationFlowReducer,
  responseSettings: responseSettingReducer,
  contactManagerSettings: contactManagerSettingsReducer,
  automatedReplies: automatedrepliesReducer,
  webhooks: webhooksReducer,
  deliveryServices: deliveryServicesReducer,
  cannedResponses: cannedResponsesReducer,
  unrecognizedIntents: unrecognizedIntentsReducer,
  notification: notificationReducer,
});

const Context = React.createContext({});

const useReducerMiddleware = (reducer, initialState) => {
  const [state, dispatchReducer] = useReducer(reducer, initialState);

  const middlewareFunctions = [
    useApplicationMiddleware(),
    useBroadcastManagerMiddleware(),
    useContactManagerMiddleware(),
    useQuestionsAndAnswersMiddleware(),
    useIntentsAndPhrasesMiddleware(),
    useAccountUsersMiddleware(),
    useAccountBillingMiddleware(),
    useLiveAgentSettingsMiddleware(),
    useBroadcastSettingsMiddleware(),
    useAccountApiMiddleware(),
    useClientResponsesMiddleware(),
    useMessageTemplatesMiddleware(),
    useSupportTicketstMiddleware(),
    useCurrentUserMiddleware(),
    useConversationsMiddleware(),
    useConversationsMiddlewarev2(),
    useAnalyticsMiddleware(),
    useDashBoardMiddleware(),
    useConversationFlowMiddleware(),
    useFeedbackMiddleware(),
    useResponsesSettingMiddleware(),
    useContactManagerSettingsMiddleware(),
    useAutomatedRepliesMiddleware(),
    useWebhooksMiddleware(),
    useDeliveryServicesMiddleware(),
    useCannedResponsesMiddleware(),
    useUnrecognizedIntentsMiddleware(),
    useBotKnowledgebaseMiddleware(),
    useWhatsappFlowsMiddleware(),
    useBotConfigurationMiddleware(),
    useNotificationMiddleware(),
  ];

  const dispatchMiddleware = (action) => {
    middlewareFunctions.forEach((middleware) => {
      middleware(state, action, dispatchReducer);
    });
  };

  return [state, dispatchReducer, dispatchMiddleware];
};

export const GlobalContextProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(rootReducer, initialState);
  // const store = React.useMemo(() => [state, dispatch], [state]);
  const [state, dispatchReducer, dispatchMiddleware] = useReducerMiddleware(
    rootReducer,
    initialState
  );

  return (
    <Context.Provider value={[state, dispatchReducer, dispatchMiddleware]}>
      {children}
    </Context.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(Context);
};
