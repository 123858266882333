import React from "react";
import { Drawer } from "antd";
import { LikeOutlined } from "@ant-design/icons";
import FeedbackForm from "./FeedbackForm";
import { useGlobalContext } from "../../contexts/globalContext";

const FeedbackFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <>
      <LikeOutlined
        onClick={() => {
          dispatchReducer({
            type: "OPEN_FORM_PANEL",
            payload: {
              component: "feedback-form-panel",
              title: "Send Feedback to iNextLabs",
            },
          });
        }}
        style={{ fontSize: "20px", margin: "auto" }}
      />

      <Drawer
        width={540}
        title={
          state.formPanel["feedback-form-panel"] &&
          state.formPanel["feedback-form-panel"].title
        }
        open={
          state.formPanel["feedback-form-panel"] &&
          state.formPanel["feedback-form-panel"].visible
        }
        onClose={() => {
          dispatchReducer({
            type: "CLOSE_FORM_PANEL",
            payload: {
              component: "feedback-form-panel",
            },
          });
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <FeedbackForm />
      </Drawer>
    </>
  );
};

export default FeedbackFormPanel;
