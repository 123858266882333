import { Column } from "@ant-design/plots";
import { Typography, Tooltip, Row, Col, Empty } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const ActiveAndInactiveUsersCount = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_ANALYTICS_ACTIVE_AND_INACTIVE_USERS_COUNT",
      component: "active-and-inactive-users-count",
    });
  });

  const config = {
    data: state.analytics.conversationCharts.activeAndInactiveUsersCount,
    isGroup: true,
    xField: "month",
    yField: "count",
    seriesField: "status",
    color: ["#40a9ff", "#2fc25b"],
  };

  return (
    <>
      {state.analytics.conversationCharts.activeAndInactiveUsersCount ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> Active and Inactive Users</Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This statistic shows the count of active and inactive users in conversaion"
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.conversationCharts.activeAndInactiveUsersCount
            .length ? (
            <Column {...config} style={{ height: "200px" }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="active-and-inactive-users-count"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ActiveAndInactiveUsersCount;
