import React, { useState, useEffect } from "react";
import { URIs } from "../../constants/env";
import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  Steps,
  Row,
  Col,
  Button,
  Typography,
  Card,
  Space,
  Tooltip,
  Tag,
  Divider,
  Dropdown,
  Input,
  Modal,
  Result,
  Form,
  AutoComplete,
} from "antd";
import { TbCircleCheck } from "react-icons/tb";
import { AiOutlineMergeCells } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import {
  DownloadOutlined,
  RocketOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  DownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import CSVFileUpload from "../broadcast-manager/CSVFileUpload";
import {
  CustomTitle,
  CustomParagraph,
} from "../../shared-components/CustomTyphography";
import { formatFileSize } from "../../utils/utility";
import { importContactsMaxSize } from "../../constants/enums";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";

const { Text, Link } = Typography;
const { confirm } = Modal;

const ImportContactsV2 = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const contactProperties = state.contactManagerSettings.contactProperties;

  const currentStep = state.contactManager.importContacts?.currentStep || 0;
  const importedColumns = state.contactManager.importContacts?.columns || [];
  const importedCSVFile = state.contactManager.importContacts?.csvFile || null;
  const importedCSVData = state.contactManager.importContacts?.csvData || [];

  const UploadCSVFile = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [importedDataSource, setImportedDataSource] = useState([]);
    const [fileSize, setFileSize] = useState(null);
    const [csvFile, setCSVFile] = useState(null);

    const handleCancelImport = () => {
      setImportedDataSource(null);
      setData([]);
      setColumns([]);
    };

    const handleGetParsedData = (columns, data, dataSource, dataFile) => {
      setImportedDataSource(dataSource);
      setData(data);
      setColumns(columns);
      setCSVFile(dataFile);
    };

    useEffect(() => {
      handleGetParsedData(
        importedColumns,
        importedCSVData,
        "csv",
        importedCSVFile
      );
    }, []);

    return (
      <Row gutter={[14, 16]}>
        <Col span={24}>
          <Text>
            <CustomTitle title="NOTE:" />
          </Text>
          <ul>
            <li>
              <CustomParagraph paragraph="You can import contacts by uploading a CSV file. The maximum file size is 5 MB." />
            </li>
            <li>
              <CustomParagraph paragraph="Please ensure that your CSV file has the first row as the header and includes the following columns." />
              <ul>
                <li>
                  <CustomParagraph paragraph="Name" />
                </li>
                <li>
                  <Text
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Mobile Number (Should be in{" "}
                    <Link
                      href={
                        URIs.DOCUMENTATIONS +
                        "/docs/contacts/import-contacts.html#formatting-mobile-numbers"
                      }
                      target="_blank"
                    >
                      {" "}
                      international format
                    </Link>
                    {". "}
                    Ex: +6512345678).
                  </Text>
                </li>
              </ul>
            </li>
          </ul>
        </Col>

        <Col span={15} offset={4}>
          <Card
            style={{
              borderRadius: "10px",
              border: "2px solid #f3eeee",
            }}
          >
            <CSVFileUpload
              handleCancelImport={handleCancelImport}
              handleGetParsedData={handleGetParsedData}
              importedDataSource={importedDataSource}
              data={data}
              setData={setData}
              columns={columns}
              setColumns={setColumns}
              setFileSize={setFileSize}
              source="contacts"
              csvFile={importedCSVFile}
            />

            {fileSize > importContactsMaxSize && (
              <Col span={24}>
                <Text style={{ color: "red", fontSize: 13 }}>
                  INXT10507: CSV file must be under 5MB in size
                </Text>
              </Col>
            )}
          </Card>
        </Col>

        <Col
          span={24}
          style={{
            // For avoiding screen flickering
            marginTop: data.length ? 90 : 110,
            textAlign: "center",
          }}
        >
          <Space>
            <CustomParagraph paragraph="Need help getting started?" />
            <RocketOutlined style={{ color: "#fc846f" }} />
          </Space>
        </Col>

        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Space size="large">
            <Link
              href={URIs.DOCUMENTATIONS + "/docs/contacts/import-contacts.html"}
              target="_blank"
              style={{
                fontSize: 13,
              }}
            >
              View import guide
              <QuestionCircleOutlined
                style={{ color: "#fc846f", marginLeft: 5 }}
              />
            </Link>

            <Link
              onClick={() => {
                const contacts = [
                  {
                    name: "Abraham",
                    email: "Abraham@example.com",
                    mobile: "+33934567890",
                    location: "Paris",
                  },
                  {
                    name: "Angelina",
                    email: "Angelina@example.com",
                    mobile: "+12139345678",
                    location: "Los Angeles",
                  },
                  {
                    name: "Harrison",
                    email: "Harrison@example.com",
                    mobile: "+1212934567",
                    location: "USA",
                  },
                  {
                    name: "Caroline",
                    email: "Caroline@example.com",
                    mobile: "+656229345",
                    location: "Singapore",
                  },
                ];

                const headers = ["Name", "Email", "Mobile", "Location"];
                const rows = contacts.map((contact) => [
                  contact.name,
                  contact.email,
                  contact.mobile,
                  contact.location,
                ]);
                const csvData = [headers, ...rows]
                  .map((row) => row.join(","))
                  .join("\n");

                const blob = new Blob([csvData], {
                  type: "text/csv;charset=utf-8;",
                });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", "sample.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Revoke the Object URL after download
                URL.revokeObjectURL(url);
              }}
              style={{
                fontSize: 13,
              }}
            >
              Download sample CSV file
              <DownloadOutlined style={{ color: "#fc846f", marginLeft: 5 }} />
            </Link>
          </Space>
        </Col>

        <Col span={24}>
          <Divider />

          <Tooltip
            title={!data?.length ? "Please upload a CSV file" : ""}
            placement="topRight"
          >
            <Button
              type="primary"
              disabled={!data?.length}
              style={{
                float: "right",
              }}
              onClick={() => {
                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_CURRENT_STEP",
                  payload: {
                    currentStep: 1,
                  },
                });

                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_COLUMNS",
                  payload: {
                    columns: columns,
                  },
                });

                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_CSV_FILE",
                  payload: {
                    csvFile,
                  },
                });

                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_CSV_DATA",
                  payload: {
                    data,
                  },
                });
              }}
            >
              Next
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  };

  const ContactColumnsMapping = () => {
    // Function to render contact columns options for mapping
    const renderTableColumnsOption = (columns, csvColumnIndex, category) =>
      columns
        .filter(
          (tableColumn) =>
            // check if the column is already mapped
            !importedColumns
              .map((column) => column.mappedColumn?.value)
              .includes("{" + tableColumn.key + "}") &&
            (category === "Required"
              ? ["displayName", "mobile"].includes(tableColumn.key)
              : !["displayName", "mobile"].includes(tableColumn.key))
        )
        .sort(
          (a, b) =>
            (a.display_order || Infinity) - (b.display_order || Infinity)
        )
        .map((tableColumn, tableColumnIndex) => (
          <Button
            key={tableColumnIndex}
            type="text"
            style={{
              width: "100%",
              textAlign: "left",
              borderBottom: "1px solid #f0f0f0",
              fontSize: 13,
              paddingLeft: 0,
            }}
            onClick={() => {
              dispatchReducer({
                type: "SET_IMPORT_CONTACT_COLUMN_MAPPING",
                payload: {
                  csvColumnIndex,
                  mappedColumn: {
                    label: "{" + tableColumn.display_name + "}",
                    value: "{" + tableColumn.key + "}",
                  },
                },
              });

              form.resetFields();
            }}
          >
            {"{" + tableColumn.display_name + "}"}
          </Button>
        ));

    return (
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Text>
            <CustomTitle title="NOTE:" />
          </Text>
          <ul>
            <li>
              <CustomParagraph paragraph="Map the columns of your CSV file from left to the corresponding contact fields on the right." />
            </li>
            <li>
              <CustomParagraph paragraph="Display name and mobile number are required fields." />
            </li>
            <li>
              <CustomParagraph paragraph="You can create new field by clicking the 'Add New Contact Field' button inside the dropdown." />
            </li>
          </ul>
        </Col>

        <Col span={22}>
          <Card
            style={{
              borderRadius: "10px",
              border: "2px solid #f3eeee",
            }}
            size="small"
            title={
              <Row gutter={[10, 0]}>
                <Col span={8}>
                  <CustomTitle title="CSV Columns" />
                </Col>
                <Col span={1}></Col>
                <Col span={15}>
                  <CustomTitle title="Matched Contact Fields" />
                </Col>
              </Row>
            }
          >
            {importedColumns?.map((csvColumn, csvColumnIndex) => {
              return (
                <Row
                  align="middle"
                  style={{
                    height: "55px",
                    borderBottom:
                      csvColumnIndex === importedColumns.length - 1
                        ? "0px"
                        : "1px solid #f0f0f0",
                  }}
                  gutter={[10, 0]}
                  key={csvColumnIndex}
                >
                  <Col span={8}>
                    <CustomParagraph paragraph={csvColumn.title} />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <Dropdown
                      trigger="click"
                      dropdownRender={() => (
                        <div
                          style={{
                            width: 345,
                            maxHeight: 400,
                            borderRadius: "5px",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            background: "white",
                            padding: "10px",
                          }}
                        >
                          <Row gutter={[10, 10]}>
                            <Col
                              span={24}
                              style={{
                                maxHeight: 200,
                                overflowY: "auto",
                              }}
                            >
                              <Space
                                direction="vertical"
                                style={{
                                  width: "100%",
                                }}
                              >
                                {["Required", "Optional"].map(
                                  (category, index) => {
                                    const tableColumns =
                                      renderTableColumnsOption(
                                        contactProperties,
                                        csvColumnIndex,
                                        category
                                      );

                                    return (
                                      <Space
                                        key={index}
                                        direction="vertical"
                                        size={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <Text
                                          type="secondary"
                                          style={{
                                            fontSize: 13,
                                          }}
                                        >{`${category} Fields:`}</Text>
                                        {tableColumns.length === 0 ? (
                                          <Text
                                            key={`no-columns-${category}`}
                                            type="secondary"
                                            style={{
                                              fontSize: 13,
                                            }}
                                          >
                                            -
                                          </Text>
                                        ) : (
                                          tableColumns
                                        )}
                                      </Space>
                                    );
                                  }
                                )}
                              </Space>
                            </Col>

                            <Col span={24}>
                              <Dropdown
                                trigger={["click"]}
                                dropdownRender={() => (
                                  <Form
                                    layout="vertical"
                                    autoComplete="off"
                                    onFinish={(values) => {
                                      dispatchReducer({
                                        type: "CREATE_NEW_CONTACT_FIELD",
                                        payload: {
                                          field: {
                                            key: values.fieldName,
                                            display_name: values.fieldName,
                                          },
                                        },
                                      });

                                      form.resetFields();
                                    }}
                                    form={form}
                                    style={{
                                      borderRadius: "5px",
                                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                                      background: "white",
                                      padding: "10px",
                                      width: 350,
                                    }}
                                  >
                                    <Row gutter={[10, 10]}>
                                      <Col
                                        span={24}
                                        style={{
                                          borderBottom: "1px solid #f0f0f0",
                                        }}
                                      >
                                        <Text>
                                          <CustomTitle title="Create New Field" />
                                        </Text>
                                      </Col>

                                      <Col
                                        span={24}
                                        style={{
                                          borderBottom: "1px solid #f0f0f0",
                                        }}
                                      >
                                        <Form.Item
                                          label="Field Name:"
                                          name="fieldName"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter field name",
                                            },
                                            {
                                              max: 50,
                                              message:
                                                "Field name should not exceed 50 characters",
                                            },
                                            // check if the field name already exists
                                            {
                                              validator: (_, value) => {
                                                if (
                                                  contactProperties.some(
                                                    (property) =>
                                                      property.key === value
                                                  )
                                                ) {
                                                  return Promise.reject(
                                                    "Field name already exists"
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            },
                                          ]}
                                          className="import-contacts-create-new-field-form-item"
                                        >
                                          <Input placeholder="Enter new field name. Ex: age " />
                                        </Form.Item>
                                      </Col>

                                      <Col
                                        span={24}
                                        style={{
                                          height: 30,
                                        }}
                                      >
                                        <Space>
                                          <Form.Item>
                                            <Button>Cancel</Button>
                                          </Form.Item>

                                          <Form.Item>
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                            >
                                              Save
                                            </Button>
                                          </Form.Item>
                                        </Space>
                                      </Col>
                                    </Row>
                                  </Form>
                                )}
                                style={{
                                  width: 300,
                                }}
                              >
                                <Button
                                  type="text"
                                  danger
                                  style={{
                                    width: "90%",
                                    textAlign: "left",
                                  }}
                                >
                                  <PlusOutlined /> Add New Contact Field
                                </Button>
                              </Dropdown>
                            </Col>
                          </Row>
                        </div>
                      )}
                    >
                      <Button
                        style={{
                          width: "90%",
                          textAlign: "left",
                          color: csvColumn.mappedColumn ? "black" : "#bfbfbf",
                          fontSize: csvColumn.mappedColumn ? "14px" : "13px",
                        }}
                      >
                        {csvColumn.mappedColumn?.label ||
                          `Select contact field to map. Ex: {${contactProperties[csvColumnIndex]?.display_name}}`}
                        <DownOutlined
                          style={{
                            float: "right",
                            marginTop: "7px",
                          }}
                        />
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              );
            })}
          </Card>
        </Col>

        {importedColumns.some(({ mappedColumn }) => mappedColumn) &&
          importedColumns.filter(
            ({ mappedColumn }) =>
              mappedColumn &&
              (mappedColumn.value === "{mobile}" ||
                mappedColumn.value === "{displayName}")
          ).length !== 2 && (
            <Col span={24}>
              <Text style={{ color: "red", fontSize: 13 }}>
                Display name and mobile number are required fields
              </Text>
            </Col>
          )}

        <Col span={22}>
          <Card
            style={{
              borderRadius: "10px",
              border: "2px solid #f3eeee",
            }}
            size="small"
            title={<CustomTitle title="Tags" />}
          >
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <CustomParagraph paragraph="You can add tags to the contacts you are importing. Tags help you to categorize your contacts and send targeted messages." />
              </Col>
              <Col span={20}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size="middle"
                >
                  <Space>
                    <Form
                      form={form}
                      layout="inline"
                      onFinish={(e) => {
                        dispatchReducer({
                          type: "ADD_IMPORT_CONTACTS_TAGS",
                          payload: {
                            tag: e.tag,
                          },
                        });

                        form.resetFields();
                      }}
                    >
                      <Form.Item
                        name="tag"
                        rules={[
                          {
                            max: 50,
                            message: "Tags must be less than 50 characters",
                          },
                        ]}
                      >
                        <AutoComplete
                          allowClear={{ right: <CloseOutlined /> }}
                          placeholder="Create tags for contacts. Ex: VIP, Regular Customer, etc."
                          className="import-contacts-tags-input"
                          options={[
                            ...new Set(
                              state.contactManager.contacts
                                .sort((a, b) => a.timestamp - b.timestamp)
                                .flatMap((contact) =>
                                  contact.tags
                                    ? contact.tags
                                        .split(",")
                                        .map((tag) => tag.trim())
                                    : []
                                )
                                .filter((tag) => tag.trim() !== "")
                            ),
                          ].map((tag) => ({
                            value: tag,
                            label: tag,
                          }))}
                          style={{ width: 400 }}
                          filterOption={true}
                          dropdownMatchSelectWidth={false}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Tooltip
                          title={
                            state.contactManager.importContacts?.tags?.length >=
                            30
                              ? "You can add up to 30 tags"
                              : ""
                          }
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                              state.contactManager.importContacts?.tags
                                ?.length >= 30
                            }
                          >
                            Add
                          </Button>
                        </Tooltip>
                      </Form.Item>
                    </Form>
                  </Space>

                  <Card
                    size="small"
                    style={{
                      borderRadius: "10px",
                      border: "2px solid #f3eeee",
                    }}
                  >
                    {state.contactManager.importContacts?.tags?.length ? (
                      state.contactManager.importContacts.tags.map(
                        (tag, index) => (
                          <Tag
                            key={index}
                            closable
                            color="blue"
                            type="round"
                            style={{ borderRadius: "4px", marginBottom: "5px" }}
                            onClose={() => {
                              dispatchReducer({
                                type: "REMOVE_IMPORT_CONTACTS_TAGS",
                                payload: {
                                  tag,
                                },
                              });
                            }}
                          >
                            {tag}
                          </Tag>
                        )
                      )
                    ) : (
                      <CustomParagraph paragraph="No tags added" />
                    )}
                  </Card>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Divider />

          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() =>
                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_CURRENT_STEP",
                  payload: {
                    currentStep: 0,
                  },
                })
              }
            >
              Back
            </Button>

            <Button
              type="primary"
              disabled={
                importedColumns.filter(
                  ({ mappedColumn }) =>
                    mappedColumn &&
                    (mappedColumn.value === "{mobile}" ||
                      mappedColumn.value === "{displayName}")
                ).length !== 2
              }
              onClick={() =>
                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_CURRENT_STEP",
                  payload: {
                    currentStep: 2,
                  },
                })
              }
            >
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const ContactSubmit = () => {
    const importedContacts =
      state.contactManager.importContacts?.importedContacts;
    const validContactsCount =
      state.contactManager.importContacts?.validContactsCount;
    const invalidContacts =
      state.contactManager.importContacts?.invalidContacts;

    const exportFailedContacts = () => {
      const headers = // should be dynamic based on the columns
        invalidContacts
          .map((contact) => Object.keys(contact))
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index);

      const rows = invalidContacts.map((contact) => Object.values(contact));

      const csvData = [headers, ...rows].map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Invalid contacts.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the Object URL after download
      URL.revokeObjectURL(url);
    };

    return importedContacts ? (
      <Row>
        {invalidContacts?.length === 0 ? (
          <Col span={24}>
            <Result
              status="success"
              title="Contacts Successfully Imported"
              subTitle={`You have successfully imported ${validContactsCount} out of ${importedContacts?.length} contacts to your contacts list.`}
              extra={[
                <Space>
                  <Button
                    key="done"
                    onClick={() => {
                      dispatchReducer({
                        type: "CLOSE_FORM_PANEL",
                        payload: {
                          component:
                            "contact-manager-import-contacts-form-panel",
                        },
                      });

                      dispatchReducer({
                        type: "CLEAR_IMPORT_CONTACT_DETAILS",
                      });
                    }}
                  >
                    Done
                  </Button>

                  <Button
                    key="sendBroadcast"
                    onClick={() => {
                      // Navigate to broadcast manager
                      navigate("/broadcast-manager");
                      sessionStorage.setItem(
                        "selectedMenu",
                        "/broadcast-manager"
                      );

                      dispatchReducer({
                        type: "OPEN_FORM_PANEL",
                        payload: {
                          component: "broadcast-form-panel",
                          title: "Create New Broadcast",
                        },
                      });

                      dispatchReducer({
                        type: "CLOSE_FORM_PANEL",
                        payload: {
                          component:
                            "contact-manager-import-contacts-form-panel",
                        },
                      });

                      dispatchReducer({
                        type: "CLEAR_IMPORT_CONTACT_DETAILS",
                      });
                    }}
                    type="primary"
                  >
                    Create Broadcast
                  </Button>
                </Space>,
              ]}
            />
          </Col>
        ) : validContactsCount === 0 ? (
          <Col span={24}>
            <Result
              status="error"
              title="Import Failed"
              subTitle={
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <CustomParagraph
                      paragraph={`All ${importedContacts?.length} contacts failed to import. Download invalid contacts to view the reasons for failure.`}
                    />
                  </Col>

                  <Col
                    span={24}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <CustomTitle title="Reasons for failure:" />

                    <ul>
                      <li>
                        <CustomParagraph paragraph="Invalid display name." />
                      </li>
                      <li>
                        <CustomParagraph paragraph="Mobile number is not in the correct format." />
                      </li>

                      <li>
                        <CustomParagraph paragraph="Mobile number mapped to the wrong column." />
                      </li>
                    </ul>
                  </Col>
                </Row>
              }
              extra={[
                <Space>
                  <Button
                    key="sendBroadcast"
                    onClick={() => {
                      dispatchReducer({
                        type: "SET_IMPORT_CONTACT_CURRENT_STEP",
                        payload: {
                          currentStep: 0,
                        },
                      });

                      dispatchReducer({
                        type: "CLEAR_IMPORT_CONTACT_DETAILS",
                      });
                    }}
                  >
                    Import Again
                  </Button>

                  <Button
                    type="primary"
                    key="failedContacts"
                    onClick={() => exportFailedContacts()}
                  >
                    Download Invalid Contacts
                  </Button>
                </Space>,
              ]}
              className="import-contacts-failed-result"
            />
          </Col>
        ) : (
          <Col span={24}>
            <Result
              status="warning"
              title="Import Partially Failed"
              subTitle={
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <CustomParagraph
                      paragraph={
                        `${
                          importedContacts?.length - validContactsCount
                        } out of ${
                          importedContacts?.length
                        } contacts failed to import. ` +
                        `Download invalid contacts to view the reasons for failure.`
                      }
                    />
                  </Col>

                  <Col
                    span={24}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <CustomTitle title="Reasons for failure:" />

                    <ul>
                      <li>
                        <CustomParagraph paragraph="Invalid display name." />
                      </li>
                      <li>
                        <CustomParagraph paragraph="Mobile number is not in the correct format." />
                      </li>

                      <li>
                        <CustomParagraph paragraph="Mobile number mapped to the wrong column." />
                      </li>
                    </ul>
                  </Col>
                </Row>
              }
              extra={[
                <Space>
                  <Button
                    key="importAgain"
                    onClick={() => {
                      dispatchReducer({
                        type: "SET_IMPORT_CONTACT_CURRENT_STEP",
                        payload: {
                          currentStep: 0,
                        },
                      });

                      dispatchReducer({
                        type: "CLEAR_IMPORT_CONTACT_DETAILS",
                      });
                    }}
                  >
                    Import Again
                  </Button>

                  <Button
                    type="primary"
                    key="failedContacts"
                    onClick={() => exportFailedContacts()}
                  >
                    Download Invalid Contacts
                  </Button>
                </Space>,
              ]}
            />
          </Col>
        )}
      </Row>
    ) : (
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <CustomParagraph paragraph="Review the details below and click 'Submit' to import the contacts." />
        </Col>

        <Col span={24}>
          <Card
            title={<CustomTitle title="CSV File Details" />}
            style={{
              borderRadius: "10px",
              border: "2px solid #f3eeee",
              width: "90%",
            }}
            size="small"
          >
            <Row gutter={[10, 10]}>
              <Col span={8}>
                <CustomParagraph paragraph="File Name" />
              </Col>
              <Col span={1}> : </Col>
              <Col span={15}>
                <CustomParagraph paragraph={importedCSVFile?.name} />
              </Col>

              <Col span={8}>
                <CustomParagraph paragraph="File Size" />
              </Col>
              <Col span={1}> : </Col>

              <Col span={15}>
                <CustomParagraph
                  paragraph={formatFileSize(importedCSVFile?.size)}
                />
              </Col>

              <Col span={8}>
                <CustomParagraph paragraph="Contacts" />
              </Col>

              <Col span={1}> : </Col>

              <Col span={15}>
                <CustomParagraph paragraph={importedCSVData.length} />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title={<CustomTitle title="Column Mapping" />}
            style={{
              borderRadius: "10px",
              border: "2px solid #f3eeee",
              width: "90%",
            }}
            size="small"
          >
            <Space
              direction="vertical"
              style={{
                width: "100%",
              }}
            >
              {importedColumns.map((column, index) => {
                return (
                  <Row key={index} gutter={[10, 10]}>
                    <Col span={8}>
                      <CustomParagraph paragraph={column.title} />
                    </Col>

                    <Col span={1}>:</Col>

                    <Col span={12}>
                      <CustomParagraph
                        paragraph={column.mappedColumn?.label || "-"}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title={<CustomTitle title="Tags" />}
            style={{
              borderRadius: "10px",
              border: "2px solid #f3eeee",
              width: "90%",
            }}
            size="small"
          >
            {state.contactManager.importContacts?.tags?.length ? (
              state.contactManager.importContacts.tags.map((tag, index) => (
                <Tag
                  key={index}
                  color="blue"
                  type="round"
                  style={{ borderRadius: "4px", marginBottom: "5px" }}
                >
                  {tag}
                </Tag>
              ))
            ) : (
              <CustomParagraph paragraph="No tags added" />
            )}
          </Card>
        </Col>

        <Col span={24}>
          <Divider />

          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() =>
                dispatchReducer({
                  type: "SET_IMPORT_CONTACT_CURRENT_STEP",
                  payload: {
                    currentStep: 1,
                  },
                })
              }
            >
              Back
            </Button>

            <Button
              type="primary"
              onClick={() => {
                confirm({
                  title: "Are you sure you want to import the contacts?",
                  content:
                    "When clicked the OK button, the contacts will be imported.",
                  onOk() {
                    dispatchMiddleware({
                      type: "IMPORT_CONTACTS",
                      payload: {
                        component: "contact-manager-import-contacts-form-panel",
                        // Payload to be sent to the API in middleware
                      },
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              Submit
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const steps = [
    {
      title: "Upload CSV File",
      content: <UploadCSVFile />,
      icon: <BiImport />,
    },
    {
      title: "Map Columns",
      content: <ContactColumnsMapping />,
      icon: <AiOutlineMergeCells />,
    },
    {
      title: "Review & Submit",
      content: <ContactSubmit />,
      icon: <TbCircleCheck />,
      //  disabled: !state.botKnowledgebase?.uploadedFile,
    },
  ];

  return (
    <Drawer
      title={
        state.formPanel["contact-manager-import-contacts-form-panel"] &&
        state.formPanel["contact-manager-import-contacts-form-panel"].title
      }
      open={
        state.formPanel["contact-manager-import-contacts-form-panel"] &&
        state.formPanel["contact-manager-import-contacts-form-panel"].visible
      }
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "contact-manager-import-contacts-form-panel",
          },
        });

        dispatchReducer({
          type: "CLEAR_IMPORT_CONTACT_DETAILS",
        });
      }}
      width={750}
      destroyOnClose={true}
    >
      <Steps
        current={currentStep || 0}
        items={steps.map((step) => ({
          ...step,
          title: step.title,
        }))}
      />
      <div
        style={{
          marginTop: "15px",
        }}
      >
        {state.apiCallStatus["contact-manager-import-contacts-form-panel"] &&
        state.apiCallStatus["contact-manager-import-contacts-form-panel"]
          .loading ? (
          <FullPageLoader
            component="contact-manager-import-contacts-form-panel"
            loadingMessage="Importing contacts... Please wait..."
          />
        ) : (
          steps[currentStep || 0].content
        )}
      </div>
    </Drawer>
  );
};

export default ImportContactsV2;
