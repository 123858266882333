const deliveryServicesReducer = (state, action) => {
  switch (action.type) {
    case "SET_LALAMOVE_CONFIGURATION":
      return {
        ...state,
        deliveryServices: {
          ...state.deliveryServices,
          ...{
            lalamoveConfiguration: action.payload.lalamoveConfiguration,
          },
        },
      };

    default:
      return state;
  }
};

export default deliveryServicesReducer;
