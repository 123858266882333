import React from "react";
import { Row, Col, Card } from "antd";
import UnrecognizedIntentsCountByDateChart from "./UnrecognizedIntentsCountByDateChart";
import UnrecognizedIntentsByChannelChart from "./UnrecognizedIntentsByChannelChart";
import UnrecognizedIntentsByAssigneeChart from "./UnrecognizedIntentsByAssigneeChart";
import UnrecognizedIntentsByStatusChart from "./UnrecognizedIntentsByStatusChart";

const KnowledgeAreaCharts = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <Card type="inner" style={{ height: 350 }}>
          <UnrecognizedIntentsByChannelChart />
        </Card>
      </Col>

      <Col span={8}>
        <Card type="inner" style={{ height: 350 }}>
          <UnrecognizedIntentsByAssigneeChart />
        </Card>
      </Col>

      <Col span={8}>
        <Card type="inner" style={{ height: 350 }}>
          <UnrecognizedIntentsByStatusChart />
        </Card>
      </Col>

      <Col span={24}>
        <Card type="inner" style={{ height: 350 }}>
          <UnrecognizedIntentsCountByDateChart />
        </Card>
      </Col>
    </Row>
  );
};

export default KnowledgeAreaCharts;
