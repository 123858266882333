import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Drawer,
  Row,
  Avatar,
  Button,
  Space,
  Tag,
  Divider,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import CurrentUserFormPanel from "./CurrentUserFormPanel";
import { URIs, mode } from "../../constants/env";
import { apiEndPoints } from "../../constants/enums";

const { Text } = Typography;

const CurrentUserInfo = () => {
  const [state, dispatchReducer] = useGlobalContext();

  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the conversationId from the cookie for each bot
    state.currentAccount.account.bots?.forEach((bot) => {
      document.cookie = `conversationId-${bot?.bot_id}=; expires=Thu, 18 Dec 1997 12:00:00 UTC`;
    });

    // "conversationId=; expires=Thu, 18 Dec 1997 12:00:00 UTC";

    sessionStorage.removeItem("selectedMenu");
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  const changePasswordUrl = () => {
    let environment = URIs.PORTAL.replace(/^https?:\/\//, ""); // remove "https://" from the beginning

    const updatedUrl = apiEndPoints.CHANGE_PASSWORD.replace(
      "{{mode}}",
      environment
    );
    return updatedUrl;
  };

  return (
    <Drawer
      width={450}
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "current-user-info-panel",
          },
        });
      }}
      open={
        state.infoPanel["current-user-info-panel"] &&
        state.infoPanel["current-user-info-panel"].visible
      }
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Row>
        <Col span={24} style={{ textAlign: "center" }}>
          <Avatar
            size={150}
            icon={<UserOutlined />}
            src={state.currentUser.user.profilePicture}
            style={{ border: "1px solid #c5c5c5", margin: "auto" }}
          />
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{ textAlign: "center", paddingTop: 25, fontWeight: "bold" }}
        >
          <Space>{state.currentUser.user.displayName.toUpperCase()}</Space>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: "center" }}>
          <Space>{state.currentUser.user.email}</Space>
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{ textAlign: "center", paddingTop: 25, lineHeight: 2.25 }}
        >
          {state.currentUser.user.role.map((userRoleId) => {
            const applicationRole = state.application.applicationRoles.find(
              (applicationRole) => applicationRole._id === userRoleId
            );
            return (
              <Tag color="blue" key={userRoleId}>
                {applicationRole.name}
              </Tag>
            );
          })}
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: "center", paddingTop: 25 }}>
          <Space>
            <Button
              onClick={() => {
                dispatchReducer({
                  type: "OPEN_FORM_PANEL",
                  payload: {
                    component: "user-profile-setting-form-panel",
                    title: "Edit User Details",
                  },
                });
              }}
            >
              Profile Settings
            </Button>
            <Button type="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Space>
        </Col>
      </Row>

      {mode !== "DEV" && (
        <Row>
          <Col span={24} style={{ textAlign: "center", paddingTop: 25 }}>
            <Space
              style={{
                color: "#F05742",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                window.location.href = changePasswordUrl();
              }}
            >
              Change Password
            </Space>
          </Col>
        </Row>
      )}

      <CurrentUserFormPanel />

      <Divider />

      <Row>
        <Col
          span={24}
          style={{ textAlign: "center", paddingTop: 25, fontWeight: "bold" }}
        >
          <Avatar
            size={75}
            src={state.currentAccount.account.general.company_logo}
            style={{ border: "1px solid #c5c5c5", margin: "auto" }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: "center", paddingTop: 10 }}>
          <Text strong>{state.currentAccount.account.general.company}</Text>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: "center", paddingTop: 25 }}>
          <Space>
            <Button
              onClick={() => {
                navigate("/account-settings");
                localStorage.setItem("selectedMenu", "account-settings");
                dispatchReducer({
                  type: "CLOSE_INFO_PANEL",
                  payload: {
                    component: "current-user-info-panel",
                  },
                });
              }}
            >
              Account Settings
            </Button>
          </Space>
        </Col>
      </Row>
    </Drawer>
  );
};

export default CurrentUserInfo;
