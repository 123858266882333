import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints, systemContactProperties } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { contactSources } from "../constants/enums";
import { mode } from "../constants/env";

export const useContactManagerMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////***********************************************////
      ////*************** CONTACT_MANAGER ***************////
      ////***********************************************////

      //Load Contact Manager data
      case "MWD_LOAD_CONTACT_MANAGER_DATA":
        await callApi({
          urls: [apiEndPoints.CONTACTS.concat("/contactproperties")],
          options: {
            method: "GET",
          },
          onSuccess: async ([contactProperties]) => {
            let contactPropertiesData = (
              contactProperties[0].allproperties || []
            ).sort((a, b) => a.localeCompare(b));

            // Convert contact properties to array of data
            contactPropertiesData = contactPropertiesData
              .filter(
                (contactProperty) =>
                  !systemContactProperties.includes(contactProperty)
              )
              .map((property) => ({ key: property }));

            const contactFilter = action.payload.enums.find(
              (enumKey) => enumKey._id === "contactFilter"
            ) || { key: "contactFilter", type: "none" };

            dispatchReducer({
              type: "SET_CONTACT_FILTER",
              payload: {
                contactFilter,
              },
            });

            contactPropertiesData = contactPropertiesData.filter(
              (contactProperty) =>
                !contactProperty.key.includes("contactFilter")
            );

            //Merge contact properties and enums based on id
            contactPropertiesData = contactPropertiesData
              .map((contactProperty) => {
                const matchedEnum = action.payload.enums.find(
                  (enumKey) =>
                    enumKey._id?.toLowerCase() ===
                    contactProperty.key?.toLowerCase()
                );

                if (matchedEnum) {
                  contactProperty.display_name = matchedEnum.display_name;
                  contactProperty.display_order = matchedEnum.display_order;
                  contactProperty.width = matchedEnum.width || 100;
                  contactProperty._id = matchedEnum._id || contactProperty.key;
                }
                return contactProperty;
              }) // no display name found then use key as display name
              .map((contactProperty) => ({
                ...contactProperty,
                display_name:
                  contactProperty.display_name || contactProperty.key,
              }));

            dispatchReducer({
              type: "SET_CONTACT_PROPERTIES",
              payload: {
                contactProperties: contactPropertiesData,
              },
            });

            dispatchReducer({
              type: "SET_CONTACT_COLUMNS",
              payload: {
                tableColumns: contactPropertiesData.some(
                  (contactProperty) => contactProperty.display_order
                )
                  ? contactPropertiesData
                      .filter(
                        (contactProperty) => contactProperty.display_order
                      )
                      .sort((a, b) => a.display_order - b.display_order)
                  : contactPropertiesData.slice(0, 5),
              },
            });

            await callApi({
              urls: [apiEndPoints.CONTACTS],
              options: {
                method: "GET",
              },
              onSuccess: ([contacts]) => {
                dispatchReducer({
                  type: "SET_CONTACT_MANAGER",
                  payload: {
                    contacts: contacts.data,
                  },
                });
                dispatchReducer({
                  type: "SUCCESS_API_TRANSACTION",
                  payload: { component: action.payload.component },
                });
              },
              onError: (error) => {
                appInsights.trackException({
                  exception: error,
                  properties: {
                    title: "Load Contacts Error",
                    clientId: state.currentAccount.account.client_id,
                    environment: mode,
                  },
                });
                dispatchReducer({
                  type: "ERROR_API_TRANSACTION",
                  payload: { component: action.payload.component, error },
                });
              },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        break;

      //Add / Edit Contact Manager data
      case "MWD_ADD_EDIT_CONTACT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            ...(state.form["contact-manager-form"].mode === "add"
              ? [apiEndPoints.CONTACTS]
              : [
                  apiEndPoints.CONTACTS.concat(
                    "?_id=",
                    state.filters.actionRowKey["contact-manager-table"]
                  ),
                ]),
          ],
          options: {
            method:
              state.form["contact-manager-form"].mode === "add"
                ? "POST"
                : "PUT",
            body: action.payload.contact,
          },
          onSuccess: ([response]) => {
            if (state.form["contact-manager-form"].mode === "add") {
              dispatchReducer({ type: "ADD_CONTACT", contact: response });
              openNotificationWithIcon("success", "Contact added successfully");
              dispatchReducer({
                type: "CLOSE_FORM_PANEL",
                payload: {
                  component: "contact-manager-form-panel",
                },
              });
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "contact-manager-form",
                  mode: "",
                },
              });
              dispatchReducer({
                type: "RD_CLEAR_ACTION_ROW_KEY",
                payload: { component: "contact-manager-table" },
              });

              appInsights.trackEvent({
                name: "Contact Created",
                properties: {
                  clientId: state.currentAccount.account.client_id,
                  createdSource: action.payload.contact.createdSource,
                  environment: mode,
                },
              });
            } else if (state.form["contact-manager-form"].mode === "edit") {
              dispatchReducer({
                type: "EDIT_CONTACT",
                contact: {
                  id: state.filters.actionRowKey["contact-manager-table"],
                  data: response,
                },
              });
              openNotificationWithIcon(
                "success",
                "Contact modified successfully"
              );
              dispatchReducer({
                type: "CLOSE_FORM_PANEL",
                payload: {
                  component: "contact-manager-form-panel",
                },
              });
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "contact-manager-form",
                  mode: "",
                },
              });
            }
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Manual Add Contact Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Delete Contact Manager data
      case "MWD_DELETE_SINGLE_CONTACT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.CONTACTS.concat("?_ids=", action.payload.actionRowKey),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "DELETE_SINGLE_CONTACT",
              actionRowKey: action.payload.actionRowKey,
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "contact-manager-table" },
            });
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "contact-manager-info-panel",
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon("success", "Contact deleted successfully");
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Contact Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Import contacts
      case "IMPORT_CONTACTS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        const mappingColumns = state.contactManager.importContacts?.columns
          .filter((column) => column.mappedColumn) // Filter items with mappedColumn
          .map((column) => ({
            key: column.mappedColumn.value.replace("{", "").replace("}", ""),
            value: column.key,
          }));

        const tags = state.contactManager.importContacts?.tags?.join(", ");

        const formData = new FormData();
        formData.append(
          "csvFile",
          state.contactManager.importContacts?.csvFile
        );
        formData.append("mapping", JSON.stringify(mappingColumns));
        formData.append("createdSource", contactSources.importContact);
        formData.append(
          "createdDate",
          new Date().toISOString().replace("Z", "+00:00")
        );
        formData.append(
          "subscribedDate",
          new Date().toISOString().replace("Z", "+00:00")
        );
        formData.append("isUnsubscribed", false);
        if (tags) {
          formData.append("tags", tags);
        }

        await callApi({
          urls: [
            apiEndPoints.CONTACTS.concat("/import?checkContactValue=mobile"),
          ],
          options: {
            method: "POST",
            body: formData,
            contentType: "auto",
          },
          onSuccess: ([importContactResponse]) => {
            const {
              contacts,
              importedContacts,
              inValidContacts,
              validContacts,
            } = importContactResponse;

            dispatchReducer({
              type: "SET_CONTACT_MANAGER",
              payload: {
                contacts,
              },
            });

            dispatchReducer({
              type: "SET_IMPORTED_CONTACTS",
              payload: {
                importedContacts,
              },
            });
            dispatchReducer({
              type: "SET_INVALID_CONTACTS",
              payload: {
                invalidContacts: inValidContacts,
              },
            });
            dispatchReducer({
              type: "SET_VALID_CONTACTS_COUNT",
              payload: {
                validContactsCount: validContacts,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "contact-manager-import-contacts-form",
                mode: "",
              },
            });

            appInsights.trackEvent({
              name: "Imported Contacts",
              properties: {
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Import Contacts Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Delete multiple contacts
      case "MWD_DELETE_MULTIPLE_CONTACTS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.CONTACTS.concat(
              "?_ids=",
              action.payload.selectedRowKeys
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_CLEAR_SELECTED_ROW_KEYS",
              payload: { component: "contact-manager-table" },
            });
            dispatchReducer({
              type: "RD_DELETE_MULTIPLE_CONTACTS",
              payload: {
                selectedRowKeys: action.payload.selectedRowKeys,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Contacts deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Multiple Contacts Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
