import React, { useState, useEffect } from "react";
import {
  Steps,
  Space,
  Row,
  Col,
  Card,
  Select,
  Form,
  Typography,
  Radio,
  Input,
  Button,
  Divider,
  Modal,
  Tooltip,
  Tag,
} from "antd";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { FaKey, FaDollarSign, FaHashtag, FaWallet } from "react-icons/fa";
import { BsBank2, BsCreditCard2Back } from "react-icons/bs";
import BroadcastPricingCalculator from "../../../broadcast-manager/BroadcastPricingCalculator";
import { ComponentLoader } from "../../../../shared-components/LoadingIndicator";
import { appInsights } from "../../../../AppInsights";

const { Option } = Select;
const { Text, Title } = Typography;
const { confirm } = Modal;

const BuyAddOnForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState({});

  const subscriptionDetails = state.accountBilling.subscription || {};

  const whatsappPackages = [
    {
      id: "WHATSAPP-PACK-50",
      price: 50,
    },
    {
      id: "WHATSAPP-PACK-100",
      price: 100,
    },
    {
      id: "WHATSAPP-PACK-150",
      price: 150,
    },
    {
      id: "WHATSAPP-PACK-200",
      price: 200,
    },
  ];

  const rechargePrice = whatsappPackages.find(
    (whatsappPackage) => whatsappPackage.id === formValues.rechargeAmount
  )?.price;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const whatsappUsage = state.currentAccount.whatsappUsage;

  const handleFormSubmit = (values) => {
    setFormValues({
      ...formValues,
      ...values,
    });
    if (subscriptionDetails.buyAddonsCurrentStep === 2) {
      confirm({
        title: "Are you sure you want to recharge your account?",
        content:
          "When clicked the OK button, the recharge process will be initiated.",
        onOk() {
          appInsights.trackEvent({
            name: "Buy Add-ons - Triggered",
            properties: {
              bot_id: botInAction.bot_id,
              waba_id: "<<waba_id>>",
              subscription_id: "<<subscription_id>>",
              package_id: formValues.rechargeAmount,
              quantity: formValues.purchaseQuantity,
              waba_client_id: "<<waba_client_id>>",
              channelId: "<<channel_id>>",
              client_name: state.currentAccount.account.general?.company,
              card_id:
                formValues.paymentMode === "card"
                  ? "<<card_id>>"
                  : "<<Bank Transfer>>",
              currency_code: subscriptionDetails?.subscriptions[0]?.currency,
            },
          });

          dispatchMiddleware({
            type: "BUY_ADDONS",
            payload: {
              component: "buy-addons-form",
              addonDetails: {
                bot_id: botInAction.bot_id,
                waba_id:
                  botInAction?.bot_channel_info?.WHATSAPP?.waba_account_id,
                subscription_id: formValues.subscriptionName,
                package_id: formValues.rechargeAmount,
                quantity: formValues.purchaseQuantity,
                waba_client_id:
                  botInAction?.bot_channel_info?.WHATSAPP?.waba_client_id,
                channelId:
                  botInAction?.bot_channel_info?.WHATSAPP?.waba_channel_id?.[0],
                client_name: state.currentAccount.account.general?.company,
                card_id:
                  formValues.paymentMode === "card"
                    ? subscriptionDetails.paymentMethod.cardId
                    : null,
                currency_code: subscriptionDetails?.subscriptions[0]?.currency,
              },
            },
          });
        },
        onCancel() {},
      });
    } else {
      dispatchReducer({
        type: "SET_BUYADDONS_CURRENT_STEP",
        payload: {
          current: (subscriptionDetails.buyAddonsCurrentStep || 0) + 1,
        },
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      rechargeAmount: "WHATSAPP-PACK-150",
      purchaseQuantity: 1,
      paymentMode: "card",
    });
  }, []);

  // Basic Details Form
  const BasicDetails = () => {
    return (
      <Row gutter={[0, 15]}>
        {/*Subscription Name*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Space
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Space size="middle">
                <FaKey color="#F05742" size={18} />
                <Text strong>SUBSCRIPTION</Text>
              </Space>

              <Space size="middle">
                <Text strong>Current Balance:</Text>
                <Text strong>
                  {state.apiCallStatus?.["whatsapp-usage-button"]?.loading ? (
                    <ComponentLoader
                      component={"whatsapp-usage-button"}
                      loadingMessage=""
                      errorMessage=""
                    />
                  ) : whatsappUsage &&
                    whatsappUsage.currentBalance !== undefined ? (
                    <Tag
                      color={
                        whatsappUsage.currentBalance < 10
                          ? "red"
                          : whatsappUsage.currentBalance < 100
                          ? "orange"
                          : "green"
                      }
                    >
                      ${whatsappUsage.currentBalance.toFixed(2)}
                    </Tag>
                  ) : (
                    "-"
                  )}
                </Text>
              </Space>
            </Space>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="subscriptionName"
                  label="Which subscription you want to add?"
                  rules={[
                    {
                      required: true,
                      message: "Please select the subscription",
                    },
                    { whitespace: true },
                  ]}
                  hasFeedback
                  className="buy-addon-form-item"
                >
                  <Select
                    style={{ width: 350 }}
                    placeholder="Select the subscription"
                  >
                    {subscriptionDetails.subscriptions.map(
                      (subscription, index) => (
                        <Option key={index} value={subscription.id}>
                          {subscription.planCode}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Recharge amount*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Space
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Space direction="vertical" size={0}>
                <Space size="middle">
                  <FaDollarSign color="#F05742" size={18} />
                  <Text strong>RECHARGE AMOUNT</Text>
                </Space>

                <Form.Item
                  name="rechargeAmount"
                  label="How much you want to recharge?"
                  rules={[
                    {
                      required: true,
                      message: "Please select the recharge amount",
                    },
                    { whitespace: true },
                  ]}
                  hasFeedback
                  className="buy-addon-form-item"
                >
                  <Radio.Group>
                    <Radio value="WHATSAPP-PACK-50">
                      <Text>$50</Text>
                    </Radio>
                    <Radio value="WHATSAPP-PACK-100">
                      <Text>$100</Text>
                    </Radio>
                    <Radio value="WHATSAPP-PACK-150">
                      <Text>$150</Text>
                    </Radio>
                    <Radio value="WHATSAPP-PACK-200">
                      <Text>$200</Text>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>

              <Space direction="vertical" size={0}>
                <Space size="middle">
                  <FaDollarSign color="#F05742" size={18} />
                  <Text strong>COST ESTIMATOR</Text>
                </Space>

                <Space direction="vertical">
                  <Text>How much you need to recharge?</Text>
                  <Button
                    type="primary"
                    size="middle"
                    onClick={() => {
                      dispatchReducer({
                        type: "OPEN_FORM_PANEL",
                        payload: {
                          component: "broadcast-pricing-calculator-form-panel",
                          title: "Broadcast Pricing Estimator",
                        },
                      });

                      !state.broadcastManager.broadcastPriceList &&
                        dispatchMiddleware({
                          type: "LOAD_BROADCAST_PRICE_LIST",
                          payload: {
                            component:
                              "broadcast-pricing-calculator-form-panel",
                          },
                        });
                    }}
                  >
                    Cost Estimator
                  </Button>
                </Space>
              </Space>
            </Space>
          </Card>
        </Col>

        {/*Recharge quantity*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Space size="middle">
                <FaHashtag color="#F05742" size={18} />
                <Text strong>PURCHASE QUANTITY</Text>
              </Space>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="purchaseQuantity"
                  label="How many quantity you want to purchase? (Max 20)"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the purchase quantity",
                    },
                    {
                      validator: (_, value) => {
                        if (/\D/.test(value)) {
                          // Check for non-digit characters
                          return Promise.reject(
                            "Please enter a valid quantity"
                          );
                        } else if (
                          parseInt(value, 10) > 20 ||
                          parseInt(value, 10) === 0 ||
                          value === ""
                        ) {
                          return Promise.reject(
                            "Please enter a valid quantity (maximum 20)"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  hasFeedback
                  className="buy-addon-form-item"
                >
                  <Input
                    style={{ width: 250 }}
                    placeholder="Enter the recharge quantity"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  };

  // Payment Mode Form
  const PaymentMode = () => {
    return (
      <Card size="small" className="card-rounded-corner">
        <Row>
          <Space size="middle">
            <FaWallet color="#F05742" size={18} />
            <Text strong>PAYMENT MODE</Text>
          </Space>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="paymentMode"
              label="Which payment mode you want to use?"
              className="buy-addon-form-item"
            >
              <Radio.Group initialValue="card" direction="horizontal">
                <Radio
                  value="card"
                  style={{
                    width: "48%",
                  }}
                >
                  <Tooltip title="3.75% transaction fee will be charged for credit card payments">
                    <Row
                      gutter={[0, 12]}
                      style={{
                        background: "linear-gradient(to right, #f5f5f5, #ddd)",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        padding: "10px",
                      }}
                    >
                      <Col span={24}>
                        <Space>
                          <Text strong>Credit Card</Text>
                          <BsCreditCard2Back
                            size={18}
                            style={{
                              color: "#F05742",
                              marginBottom: -5,
                            }}
                          />
                        </Space>
                      </Col>

                      <Col span={24}>
                        <Text
                          style={{
                            color: "#333",
                            letterSpacing: "2px",
                            marginBottom: "8px",
                          }}
                          strong
                        >
                          **** **** ****{" "}
                          {subscriptionDetails.paymentMethod.lastFourDigits}
                        </Text>
                      </Col>

                      <Col span={7}>
                        <Text
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "1px",
                          }}
                        >
                          {subscriptionDetails.paymentMethod.cardType}
                        </Text>
                      </Col>

                      <Col
                        span={12}
                        style={{
                          textAlign: "right",
                          color: "#333",
                          letterSpacing: "2px",
                        }}
                      >
                        <Text>
                          {subscriptionDetails.paymentMethod.expiryMonth}/
                          {subscriptionDetails.paymentMethod.expiryYear}
                        </Text>
                      </Col>
                    </Row>
                  </Tooltip>
                </Radio>

                <Radio
                  value="bank"
                  style={{
                    width: "49%",
                  }}
                >
                  <Row
                    gutter={[0, 5]}
                    style={{
                      background: "linear-gradient(to right, #f1fcff, #c9e6f9)",
                      border: "1px solid #a1cae1",
                      borderRadius: "8px",
                      boxShadow: "5px 6px 10px rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                    }}
                  >
                    <Col span={24}>
                      <Space>
                        <Text strong>Bank Transfer</Text>
                        <BsBank2
                          size={18}
                          style={{
                            color: "#F05742",
                            marginBottom: -5,
                          }}
                        />
                      </Space>
                    </Col>

                    <Col span={24}>
                      <Text
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        Note: This is not fully automated. You will receive
                        invoice, then confirm your payment with our account
                        manager.
                      </Text>
                    </Col>
                  </Row>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  };

  // Review + pay
  const ReviewPay = () => {
    return (
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Title level={5}> Summary: </Title>
        </Col>
        <Col span={8}>Subscription Name: </Col>
        <Col span={16}>
          {
            subscriptionDetails.subscriptions.find(
              (subscription) => subscription.id === formValues.subscriptionName
            )?.planCode
          }
        </Col>

        <Col span={8}>Recharge Amount: </Col>
        <Col span={16}>${rechargePrice}</Col>

        <Col span={8}>Purchase Quantity: </Col>
        <Col span={16}>{formValues.purchaseQuantity}</Col>

        <Col span={8}>Payment Mode: </Col>
        <Col span={16}>
          {formValues.paymentMode === "card" ? "Credit Card" : "Bank Transfer"}
        </Col>

        <Col span={8}>Total Amount:</Col>
        <Col span={16}>
          <Text strong>
            {rechargePrice
              ? formValues.paymentMode === "card"
                ? `$${(
                    rechargePrice * formValues.purchaseQuantity +
                    rechargePrice * formValues.purchaseQuantity * 0.037
                  ).toFixed(2)}`
                : `$${(rechargePrice * formValues.purchaseQuantity).toFixed(2)}`
              : " - "}
          </Text>

          {formValues.paymentMode === "card" && (
            <Text secondary style={{ fontSize: "12px" }}>
              {" "}
              (Including processing fee)
            </Text>
          )}
        </Col>
      </Row>
    );
  };

  const steps = [
    {
      title: "Basic Details",
      content: <BasicDetails />,
    },
    {
      title: "Payment Mode",
      content: <PaymentMode />,
    },
    {
      title: "Review + Pay",
      content: <ReviewPay />,
    },
  ];

  return (
    <Space
      style={{
        width: "100%",
      }}
      direction="vertical"
      size="middle"
    >
      <Steps
        size="small"
        progressDot
        current={subscriptionDetails.buyAddonsCurrentStep || 0}
        items={steps.map((step) => ({
          ...step,
          title: step.title,
        }))}
      />

      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        onFinish={handleFormSubmit}
      >
        {steps[subscriptionDetails.buyAddonsCurrentStep || 0].content}

        <Divider />

        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {subscriptionDetails.buyAddonsCurrentStep > 0 ? (
            <Button
              onClick={() => {
                dispatchReducer({
                  type: "SET_BUYADDONS_CURRENT_STEP",
                  payload: {
                    current: subscriptionDetails.buyAddonsCurrentStep - 1,
                  },
                });
              }}
            >
              Back
            </Button>
          ) : (
            <Button
              onClick={() => {
                dispatchReducer({
                  type: "CLOSE_ADD_ONS_PLAN_DETAILS",
                });
              }}
            >
              Cancel
            </Button>
          )}

          <Button type="primary" htmlType="submit">
            {subscriptionDetails.buyAddonsCurrentStep === 2 ? "Pay" : "Next"}
          </Button>
        </Space>
      </Form>

      <BroadcastPricingCalculator />
    </Space>
  );
};

export default BuyAddOnForm;
