const messageTemplate = (state, action) => {
  switch (action.type) {
    case "SET_MESSAGE_TEMPLATES":
      const { templates } = state.messageTemplates;
      // While refresh will get the latest templates and replace the old one
      const { latestTemplates } = action.payload;

      return {
        ...state,
        messageTemplates: {
          ...state.messageTemplates,
          templates: templates?.some(
            (template) => template.botId === latestTemplates.botId
          )
            ? templates.map((template) =>
                template.botId === latestTemplates.botId
                  ? latestTemplates
                  : template
              )
            : [...(templates || []), latestTemplates],
        },
      };

    case "ADD_TEMPLATE":
      return {
        ...state,
        messageTemplates: {
          ...state.messageTemplates,
          ...{
            templates: state.messageTemplates.templates?.map((template) => {
              if (template.botId === action.payload.botId) {
                return {
                  ...template,
                  waba_templates: [
                    action.payload.createdTemplate,
                    ...(template.waba_templates || []),
                  ],
                };
              }
              return template;
            }),
          },
        },
      };

    case "SET_HEADER_MEDIA_ID":
      return {
        state,
        messageTemplates: {
          ...state.messageTemplates,
          uploadedMedia: action.payload.uploadedMedia,
        },
      };

    case "DELETE_TEMPLATE":
      return {
        ...state,
        messageTemplates: {
          ...state.messageTemplates,
          ...{
            templates: state.messageTemplates.templates?.map((template) => {
              if (template.botId === action.payload.botId) {
                return {
                  ...template,
                  waba_templates: template?.waba_templates?.filter(
                    (template) => template.name !== action.payload.templateName
                  ),
                };
              }
              return template;
            }),
          },
        },
      };

    default:
      return state;
  }
};

export default messageTemplate;
