import React from "react";
import { Line } from "@ant-design/plots";

const ClientResponsesFeedbackChart = () => {
  let data = [
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-10",
      value: 0,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-10",
      value: 0,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-11",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-11",
      value: 3,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-11",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-11",
      value: 3,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-12",
      value: 3,
    },

    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-12",
      value: 1,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-13",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-13",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-13",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-14",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-14",
      value: 0,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-15",
      value: 1,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-15",
      value: 2,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-16",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-16",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-17",
      value: 3,
    },

    {
      question: "Did our customer service address your needs?",
      date: "2023-04-18",
      value: 2,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-19",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-20",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-23",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-24",
      value: 2,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-25",
      value: 4,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-26",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-26",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-27",
      value: 1,
    },
    {
      question: "Overall, how easy was it to interact with our live agent?",
      date: "2023-04-27",
      value: 2,
    },
    {
      question: "Did our customer service address your needs?",
      date: "2023-04-29",
      value: 0,
    },
  ];

  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "question",
    yAxis: {
      label: {
        formatter: (v) => {
          // Not supporting ===
          if (v == 0) {
            return `\u{1F622}`;
          } else if (v == 1) {
            return `\u{1F615}`;
          } else if (v == 2) {
            return `\u{1F610}`;
          } else if (v == 3) {
            return `\u{1F60A}`;
          } else if (v == 4) {
            return `\u{1F603}`;
          }
        },
      },
    },
    legend: {
      position: "top",
    },
    smooth: true,
    style: {
      height: 240,
      padding: 7,
      boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.1)",
    },
  };
  return <Line {...config} />;
};

export default ClientResponsesFeedbackChart;
