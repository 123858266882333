import React from "react";
import { Row, Empty } from "antd";

const NoData = () => {
  return (
    <Row
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Row>
  );
};

export default NoData;
