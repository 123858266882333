import { useConnectApi } from "../connect/connectApi";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { URIs } from "../constants/env";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useFeedbackMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      case "MWD_CREATE_FEEDBACK_FORM":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [URIs.FEEDBACK_LOGIC_APP],
          options: {
            method: "POST",
            body: action.payload.feedbackForm,
            isTokenRequired: false,
          },
          onSuccess: () => {
            openNotificationWithIcon(
              "success",
              "Feedback form submitted successfully"
            );

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });

            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "feedback-form-panel",
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Create Feedback Form Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
    }
  };
};
