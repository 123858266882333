const infoPanelReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_INFO_PANEL":
      return {
        ...state,
        infoPanel: {
          ...state.infoPanel,
          ...{
            [action.payload.component]: {
              visible: true,
              title: action.payload.title,
            },
          },
        },
      };

    case "CLOSE_INFO_PANEL":
      return {
        ...state,
        infoPanel: {
          ...state.infoPanel,
          ...{
            [action.payload.component]: {
              visible: false,
              title: "",
            },
          },
        },
      };

    default:
      return state;
  }
};
export default infoPanelReducer;
