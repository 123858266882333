import React from "react";
import { Row, Col, Card } from "antd";
import ResponsesByChannelChart from "./ResponsesByChannelChart";
import ResponsesByActionChart from "./ResponsesByActionChart";
import ResponsesByAssignedPersonChart from "./ResponsesByAssignedPersonChart";
import ResponsesByDateChart from "./ResponsesByDateChart";

const ResponsesCharts = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <ResponsesByChannelChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <ResponsesByActionChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <ResponsesByAssignedPersonChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <ResponsesByDateChart />
        </Card>
      </Col>
    </Row>
  );
};

export default ResponsesCharts;
