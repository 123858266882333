import { useState, useEffect } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Typography,
  Upload,
  Input,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { BiImport } from "react-icons/bi";
import { TbDatabase, TbVersions, TbTags } from "react-icons/tb";
import openNotificationWithIcon from "../../../utils/notification";
import { Translation } from "../../../translation/translation";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import "./BotKnowledgebase.less";
import BotKnowledgebaseTemplate from "./BotKnowledgebaseTemplate";

const { Text } = Typography;

const BotKnowledgebaseForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [knowledgebaseSource, setKnowledgebaseSource] = useState("document");

  const [knowledgebaseFile, setKnowledgebaseFile] = useState(null);
  const [isHoveredUploadFileCard, setIsHoveredUploadFileCard] = useState(false);
  const [knowledgebaseTemplate, setKnowledgebaseTemplate] = useState(null);

  const translation =
    Translation().botKnowledgeArea.botKnowledgebase.formPanel.uploadFileForm;

  const uploadedFile = state.botKnowledgebase?.uploadedFile;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const handleSelectTemplate = (template) => {
    setKnowledgebaseTemplate(template);

    dispatchReducer({
      type: "CLOSE_INFO_PANEL",
      payload: {
        component: "knowledgebase-templates-info-panel",
      },
    });

    knowledgebaseFile && setKnowledgebaseFile(null);
  };

  const handleFormSubmit = (values) => {
    if (knowledgebaseSource === "document") {
      if (knowledgebaseFile) {
        dispatchMiddleware({
          type: "ADD_KNOWLEDGEBASE_FILE",
          payload: {
            botInAction,
            knowledgebaseFile,
            ...values,
            tags: values.tags?.filter((tag) => tag.key),
            component: "knowledgebase-upload-form",
          },
        });
      } else {
        openNotificationWithIcon(
          "error",
          translation.knowledgebaseFileFormItem.required
        );
      }
    } else if (knowledgebaseSource === "template") {
      const formValues = {
        ...values,
        tags: values.tags?.filter((tag) => tag.key),
        knowledgebaseSource: "template",
        selectedTemplate: knowledgebaseTemplate,
      };

      dispatchReducer({
        type: "ADD_KNOWLEDGEBASE_FILE",
        payload: {
          uploadedFile: {
            ...formValues,
            kb_name: values.knowledgebaseName,
            kb_version: values.knowledgebaseVersion,
            file_name: " ",

            // When click previous button, the data will be lost if we don't do this
            formValues,
            data: knowledgebaseTemplate.contents.map(({ title, content }) => {
              return {
                section_title: title,
                section_content: content,
              };
            }),
            isModified: false,
          },
        },
      });

      dispatchReducer({
        type: "SET_CURRENT_STEP",
        payload: 2,
      });
    } else {
      // Handle other cases (if needed)
    }
  };

  useEffect(() => {
    if (uploadedFile) {
      form.setFieldsValue({
        ...uploadedFile.formValues,
        tags: uploadedFile.formValues.tags?.length
          ? uploadedFile.formValues.tags.map((tag) => {
              return {
                key: tag.key,
                value: tag.value,
              };
            })
          : [{ key: "", value: "" }],
      });

      setKnowledgebaseFile(uploadedFile.formValues.knowledgebaseFile);
      setKnowledgebaseSource(
        uploadedFile.formValues.knowledgebaseSource || "document"
      );
      setKnowledgebaseTemplate(uploadedFile.formValues.selectedTemplate);
    } else {
      form.setFieldsValue({
        tags: [{ key: "", value: "" }],
      });
    }
  }, [uploadedFile]);

  return state.apiCallStatus["knowledgebase-upload-form"] &&
    state.apiCallStatus["knowledgebase-upload-form"].loading ? (
    <FullPageLoader
      component="knowledgebase-upload-form"
      loadingMessage={translation.loadingMessage}
    />
  ) : (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      autoComplete="off"
      layout="vertical"
      onFinishFailed={() => {
        openNotificationWithIcon("error", translation.error);
      }}
    >
      <Row gutter={[20, 24]}>
        {/*Knowledgebase Name*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <TbDatabase color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>
                  {translation.knowledgebaseNameFormItem.title}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="knowledgebaseName"
                  label={translation.knowledgebaseNameFormItem.label}
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: translation.knowledgebaseNameFormItem.required,
                    },
                    {
                      max: 35,
                      message: translation.knowledgebaseNameFormItem.maximum,
                    },
                  ]}
                  hasFeedback
                  className="kb-form-item"
                >
                  <Input
                    placeholder={
                      translation.knowledgebaseNameFormItem.placeholder
                    }
                    style={{ width: 500 }}
                    autoFocus
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Knowledgebase File*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <BiImport color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>
                  {translation.knowledgebaseFileFormItem.title}
                </Text>
              </Col>
              <Col span={24}>{translation.knowledgebaseFileFormItem.label}</Col>
            </Row>
            <Row
              style={{
                marginTop: 7,
              }}
            >
              <Col
                span={11}
                onMouseEnter={() =>
                  !knowledgebaseFile && setIsHoveredUploadFileCard(true)
                }
                onMouseLeave={() =>
                  !knowledgebaseFile && setIsHoveredUploadFileCard(false)
                }
              >
                {knowledgebaseFile ? (
                  <Space
                    className="kb-file-upload uploadComplete"
                    direction="vertical"
                    size={10}
                  >
                    <Text>{knowledgebaseFile.name}</Text>
                    <Upload
                      showUploadList={false}
                      maxCount={1}
                      accept=".doc,.docx,.pdf"
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(info) => {
                        setKnowledgebaseSource("document");

                        info.file.size > 1048576
                          ? openNotificationWithIcon(
                              "error",
                              translation.knowledgebaseFileFormItem
                                .maximumSizeError
                            )
                          : setKnowledgebaseFile(info.file);

                        knowledgebaseTemplate && setKnowledgebaseTemplate(null);
                      }}
                    >
                      <Button>
                        {
                          translation.knowledgebaseFileFormItem
                            .chooseAnotherFile
                        }
                      </Button>
                    </Upload>
                  </Space>
                ) : (
                  <Upload
                    showUploadList={false}
                    maxCount={1}
                    accept=".doc,.docx,.pdf"
                    beforeUpload={() => {
                      return false;
                    }}
                    onChange={(info) => {
                      setKnowledgebaseSource("document");

                      info.file.size > 1048576
                        ? openNotificationWithIcon(
                            "error",
                            translation.knowledgebaseFileFormItem
                              .maximumSizeError
                          )
                        : setKnowledgebaseFile(info.file);

                      knowledgebaseTemplate && setKnowledgebaseTemplate(null);
                    }}
                    className="kb-file-upload"
                  >
                    <Space direction="vertical" size={0}>
                      <Text>
                        {translation.knowledgebaseFileFormItem.infoText1}
                      </Text>
                      <Text strong>
                        {translation.knowledgebaseFileFormItem.infoText2}
                      </Text>
                      {isHoveredUploadFileCard && (
                        <Text
                          style={{
                            fontSize: 10.5,
                            marginBottom: -10,
                          }}
                          type="secondary"
                        >
                          Note: Upload DOC, DOCX, or PDF files under 1MB
                        </Text>
                      )}
                    </Space>
                  </Upload>
                )}
              </Col>

              <Col span={1} className="center-alignment">
                Or
              </Col>

              <Col span={11}>
                {knowledgebaseTemplate ? (
                  <Space
                    className="kb-file-upload uploadComplete"
                    direction="vertical"
                    size={10}
                  >
                    <Text strong>{knowledgebaseTemplate.title}</Text>
                    <Button
                      onClick={() => {
                        setKnowledgebaseSource("template");
                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component: "knowledgebase-templates-info-panel",
                          },
                        });

                        knowledgebaseFile && setKnowledgebaseFile(null);
                      }}
                    >
                      Choose another template
                    </Button>
                  </Space>
                ) : (
                  <Space
                    direction="vertical"
                    size={0}
                    className="kb-file-upload"
                    onClick={() => {
                      setKnowledgebaseSource("template");
                      dispatchReducer({
                        type: "OPEN_INFO_PANEL",
                        payload: {
                          component: "knowledgebase-templates-info-panel",
                        },
                      });

                      knowledgebaseFile && setKnowledgebaseFile(null);
                    }}
                  >
                    <Text>Click here to use knowledge from</Text>
                    <Text strong>Suggested Templates</Text>
                  </Space>
                )}
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Knowledgebase Version*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <TbVersions color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>
                  {translation.knowledgebaseVersionFormItem.title}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="knowledgebaseVersion"
                  label={translation.knowledgebaseVersionFormItem.label}
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message:
                        translation.knowledgebaseVersionFormItem.required,
                    },
                    {
                      max: 5,
                      message: translation.knowledgebaseVersionFormItem.maximum,
                    },
                    {
                      pattern: new RegExp(/^[0-9.]*$/),
                      message: translation.knowledgebaseVersionFormItem.pattern,
                    },
                  ]}
                  hasFeedback
                  className="kb-form-item"
                >
                  <Input
                    placeholder={
                      translation.knowledgebaseVersionFormItem.placeholder
                    }
                    style={{ width: 200 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Knowledgebase Tags*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <TbTags color="#F05742" size={21} />
              </Col>
              <Col span={23}>
                <Text strong>
                  {translation.knowledgebaseTagsFormList.title}
                </Text>
              </Col>
            </Row>

            <Space
              direction="vertical"
              style={{
                width: 500,
              }}
            >
              <Text>{translation.knowledgebaseTagsFormList.label}</Text>

              <Form.List name="tags">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row
                        gutter={10}
                        key={key}
                        style={{
                          marginBottom:
                            fields.length - 1 === parseInt(name) ? 0 : 10,
                          width: 530,
                        }}
                      >
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            name={[name, "key"]}
                            rules={[
                              {
                                max: 35,
                                message:
                                  translation.knowledgebaseTagsFormList
                                    .keyFormItem.maximum,
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                                message:
                                  translation.knowledgebaseTagsFormList
                                    .keyFormItem.pattern,
                              },
                            ]}
                            className="kb-form-item"
                          >
                            <Input
                              placeholder={
                                translation.knowledgebaseTagsFormList
                                  .keyFormItem.placeholder
                              }
                              onChange={(e) => {
                                if (
                                  e.target.value.length === 1 &&
                                  e.nativeEvent.inputType === "insertText"
                                ) {
                                  add();
                                }
                                if (
                                  fields.length > 1 &&
                                  e.target.value.length === 0 &&
                                  (e.nativeEvent.inputType ===
                                    "deleteContentBackward" ||
                                    e.nativeEvent.inputType ===
                                      "deleteContentForward")
                                ) {
                                  remove(name);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "value"]}
                            rules={[
                              {
                                max: 35,
                                message:
                                  translation.knowledgebaseTagsFormList
                                    .valueFormItem.maximum,
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                                message:
                                  translation.knowledgebaseTagsFormList
                                    .valueFormItem.pattern,
                              },
                            ]}
                            className="kb-form-item"
                          >
                            <Input
                              placeholder={
                                translation.knowledgebaseTagsFormList
                                  .valueFormItem.placeholder
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          {
                            //show button only have more than one item
                            fields.length > 1 && (
                              <DeleteOutlined
                                style={{
                                  color: "#F05742",
                                  margin: "9px 0 0 0",
                                }}
                                onClick={() => remove(name)}
                              />
                            )
                          }

                          {uploadedFile &&
                            uploadedFile.tags?.length !== 0 &&
                            //show button only on last item
                            fields.length - 1 === parseInt(name) && (
                              <PlusOutlined
                                style={{
                                  color: "#F05742",
                                  margin: "9px 0 0 0",
                                }}
                                onClick={() => add()}
                              />
                            )}
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <Divider />

          <Form.Item>
            <Space
              style={{
                display: "flex",
                justifyContent:
                  knowledgebaseSource === "template"
                    ? "space-between"
                    : "flex-start",
              }}
            >
              <Button
                onClick={() => {
                  dispatchReducer({
                    type: "SET_CURRENT_STEP",
                    payload: 0,
                  });
                }}
              >
                {translation.previous.buttonText}
              </Button>

              <Button type="primary" htmlType="submit">
                {knowledgebaseSource === "template"
                  ? "Next"
                  : translation.save.buttonText}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <BotKnowledgebaseTemplate handleSelectTemplate={handleSelectTemplate} />
    </Form>
  );
};

export default BotKnowledgebaseForm;
