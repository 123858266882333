const contactManagerSettingsReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTACT_PROPERTIES":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          ...{
            contactProperties: action.payload.contactProperties,
          },
        },
      };

    case "SET_CONTACT_COLUMNS":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          tableColumns: action.payload.tableColumns,
        },
      };

    case "RD_REMOVE_CONTACT_PROPERTY":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          tableColumns: state.contactManagerSettings.tableColumns.filter(
            (property) => property.key !== action.payload.contactProperty.key
          ),
        },
      };

    case "RD_ADD_CONTACT_PROPERTY":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          tableColumns: [
            ...state.contactManagerSettings.tableColumns,
            {
              key: "",
            },
          ],
        },
      };

    case "SET_SELECTED_CONTACT_PROPERTY":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          tableColumns: action.payload.tableColumns,
        },
      };

    case "SET_CONTACT_FILTER":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          contactFilter: action.payload.contactFilter,
        },
      };

    case "CREATE_NEW_CONTACT_FIELD":
      return {
        ...state,
        contactManagerSettings: {
          ...state.contactManagerSettings,
          contactProperties: [
            ...state.contactManagerSettings.contactProperties,
            action.payload.field,
          ],
        },
      };

    default:
      return state;
  }
};

export default contactManagerSettingsReducer;
