import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import moment from "moment";
import { mode } from "../constants/env";
import { appInsights } from "../AppInsights";

export const useDashBoardMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////***************** DASHBOARD ***************////
      ////*******************************************////

      // Load broadcast count
      case "LOAD_BROADCASTS_COUNT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.BROADCAST_COUNT.concat(
              "?timestamp=thirtydays&dashboard=true&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcasts]) => {
            dispatchReducer({
              type: "SET_BROADCASTS_COUNT",
              payload: {
                broadcastsCount: broadcasts
                  .sort((a, b) => moment(a._id).unix() - moment(b._id).unix())
                  .map((broadcast) => broadcast.count),
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Broadcast Count Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Load broadcast message count
      case "LOAD_BROADCASTS_MESSAGE_SENT_COUNT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.BROADCAST_MESSAGE_COUNT.concat(
              "?sent=thirtydays&dashboard=true&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcasts]) => {
            dispatchReducer({
              type: "SET_BROADCASTS_MESSAGE_SENT_COUNT",
              payload: {
                broadcastsMessageSentCount: broadcasts
                  .sort((a, b) => moment(a._id).unix() - moment(b._id).unix())
                  .map((broadcast) => broadcast.count),
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load Broadcast Message Sent Count Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Load waiting for liveagent count
      case "MWD_LOAD_WAITING_FOR_LIVE_AGENT_COUNT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.WAITING_FOR_LIVE_AGENT_COUNT.concat(
              "?live_agent_status=WAITING&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([liveAgentWaitingCount]) => {
            dispatchReducer({
              type: "RD_SET_WAITING_FOR_LIVE_AGENT_COUNT",
              payload: {
                liveAgentWaitingCount: liveAgentWaitingCount.map(
                  (waitingForliveAgent) => waitingForliveAgent.count
                ),
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load Waiting for Live Agent Count Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load client responses count
      case "MWD_LOAD_CLIENT_RESPONSES_COUNT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.CLIENT_RESPONSES_COUNT.concat(
              "?leadDate=thirtydays&dashboard=true&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([clientResponses]) => {
            dispatchReducer({
              type: "RD_SET_CLIENT_RESPONSES_COUNT",
              payload: clientResponses
                .sort((a, b) => moment(a._id).unix() - moment(b._id).unix())
                .map((clientResponse) => clientResponse.count),
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load client responses count error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load conversation by date
      case "MWD_LOAD_CONVERSATION_BY_DATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_BY_DATE.concat(
              "?last_modified=thirtydays&dashboard=true&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([conversationByDate]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_BY_DATE",
              payload: {
                conversationByDate: conversationByDate.map(
                  (conversation) => conversation.count
                ),
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load conversation by date data error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load conversation by today
      case "MWD_LOAD_CONVERSATION_BY_TODAY_COUNT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_BY_DATE.concat(
              "?last_modified=today&dashboard=true&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([conversationByToday]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_BY_TODAY_COUNT",
              payload: {
                conversationByToday: conversationByToday.map(
                  (conversation) => conversation.count
                ),
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load conversation by today count error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load client responses count
      case "MWD_LOAD_LATEST_NEWS_UPDATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [apiEndPoints.LATEST_NEWS_UPDATE.concat("?is_disable=", false)],
          options: {
            method: "GET",
          },
          onSuccess: ([latestNews]) => {
            dispatchReducer({
              type: "RD_SET_LATEST_NEWS_UPDATE",
              payload: {
                latestNews: ["DEV"].includes(mode)
                  ? [
                      {
                        title: "Attach notes to conversations 📝",
                        shortDescription:
                          "Enhancing Communication Efficiency and Organization through Interactive Note-Taking",
                        image: "/images/latest-updates/conversation-notes.png",
                        description: {
                          text: "Take better control of your conversations with the new 'Add Notes' feature. ✍️🗒️ You can now add contextual notes to your interactions, making it easy to capture important points, follow up effectively, and maintain a comprehensive record of each conversation. 📝🧐 This feature is available for all subscription plans, helping you stay organized and efficient. 📋🚀",
                          image:
                            "/images/latest-updates/conversation-notes.png",
                        },
                      },
                      {
                        title: "Teams-based HR Bot🤖",
                        shortDescription:
                          "Revolutionizing Human Resources Management with Intelligent Teams Integration",
                        image: "/images/latest-updates/teams-hr-bot.png",
                        description: {
                          text: "Introducing our Teams-based HR Bot! 🤖 This powerful bot is designed to streamline HR processes within Microsoft Teams. It can assist with leave requests, claim approvals, FAQs, and more. 📅💼 Empower your HR team with this seamless integration, making HR tasks efficient and user-friendly. 🚀👥",
                          image: "/images/latest-updates/teams-hr-bot.png",
                        },
                      },
                      {
                        title: "Knowledgebase Analytics 🤖🧠📊",
                        shortDescription:
                          "Extracting Insights and Maximizing Efficiency in Knowledge Management",
                        image: "/images/latest-updates/bot-kb.png",
                        description: {
                          text: "Introducing Knowledgebase Analytics! 🧠📊 When you see the questions that didn't get good answers, you can understand where the bot might have made mistakes. This helps you update and improve the bot's knowledge so that it can do better in the future and provide more accurate responses. It's a way to learn from those mistakes and make the bot even smarter. 🌟🤖",
                          image: "/images/latest-updates/bot-kb.png",
                        },
                      },
                    ]
                  : latestNews,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(
              JSON.stringify(error),

              {
                title: "Load latest news error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              }
            );
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
