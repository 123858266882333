import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Tooltip, Modal } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { getUserDateTime } from "../../../utils/utility";

const { confirm } = Modal;

const useWebhooksTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const webhooksDataColumn = [
    {
      title: "WEBHOOK NAME",
      dataIndex: "webhookName",
      key: "webhookName",
      ellipsis: true,
      width: 200,
      sorter: (a, b) => {
        let valueA = a.webhookName == null ? "" : a.webhookName;
        let valueB = b.webhookName == null ? "" : b.webhookName;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: "SERVICE PROVIDER NAME",
      dataIndex: "serviceProvider",
      key: "serviceProvider",
      ellipsis: true,
      width: 250,
      sorter: (a, b) => {
        let valueA = a.webhookName == null ? "" : a.webhookName;
        let valueB = b.webhookName == null ? "" : b.webhookName;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: 220,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Space>
            {`${record?.triggerCriteria?.module} (${record?.triggerCriteria?.criteriaValue} category)`}
          </Space>
        );
      },
    },
    {
      title: "WEBHOOK TYPE",
      dataIndex: "webhookType",
      key: "webhookType",
      width: 180,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Space>
            {record?.createContactFromResponse === true
              ? "Contact trigger"
              : record?.autoEmailTrigger === true
              ? "Email trigger"
              : "External"}
          </Space>
        );
      },
    },
    {
      title: "CREATED BY",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 160,
      ellipsis: true,
      render: (_, record) => {
        return <Space>{record?.createdBy}</Space>;
      },
    },
    {
      title: "CREATED DATE",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 180,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Space>
            {getUserDateTime(
              record.createdDate,
              state.currentUser.user.timeZone,
              state.currentUser.user.dateFormat
            )}
          </Space>
        );
      },
    },
  ];

  const webhooksActionColumn = [
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Space size={24}>
              <Tooltip
                placement="topRight"
                title={
                  record.whatsappNotification === true
                    ? "WhatsAppNotification Webhook can't be editable, you can create a new one by deleting the existing one"
                    : state.currentUser.permission.WEBHOOK_EDIT
                    ? "Edit Webhook"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission.WEBHOOK_EDIT ? (
                  <EditOutlined
                    style={{
                      color:
                        record?.whatsappNotification === true
                          ? "#e2e2e2"
                          : "#F05742",
                      pointerEvents:
                        record?.whatsappNotification === true ? "none" : "auto",
                    }}
                    disabled={
                      record?.whatsappNotification === true ? true : false
                    }
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "webhooks-table",
                          actionRowKey: record._id,
                        },
                      });
                      dispatchReducer({
                        type: "OPEN_FORM_PANEL",
                        payload: {
                          component: "webhooks-form-panel",
                          title: "Edit Webhook",
                        },
                      });
                      dispatchReducer({
                        type: "SET_FORM_MODE",
                        payload: {
                          component: "webhooks-form",
                          mode: "edit",
                        },
                      });
                    }}
                  />
                ) : (
                  <EditOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>

              {state.apiCallStatus["webhooks-" + record._id + "-delete"] &&
              state.apiCallStatus["webhooks-" + record._id + "-delete"]
                .loading ? (
                <ComponentLoader
                  component={"webhooks-" + record._id + "-delete"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission.WEBHOOK_DELETE
                      ? "Delete Webhook"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.WEBHOOK_DELETE ? (
                    <DeleteOutlined
                      style={{ color: "#F05742" }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_ACTION_ROW_KEY",
                          payload: {
                            component: "webhooks-table",
                            actionRowKey: record._id,
                          },
                        });
                        confirm({
                          title: "Are you sure you want to delete the webhook?",
                          content:
                            "When clicked the OK button, the webhook will be deleted permanently",
                          onOk() {
                            dispatchMiddleware({
                              type: "MWD_DELETE_WEBHOOK",
                              payload: {
                                component: "webhooks-" + record._id + "-delete",
                                actionRowKey: record._id,
                              },
                            });
                          },
                          onCancel() {},
                        });
                      }}
                    />
                  ) : (
                    <DeleteOutlined style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return { webhooksDataColumn, webhooksActionColumn };
};

export default useWebhooksTableColumns;
