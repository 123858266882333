import { DeleteOutlined } from "@ant-design/icons";
import { BiConversation } from "react-icons/bi";
import { Space, Tooltip } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { getUserDateTime } from "../../utils/utility";

const TicketsTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const ticketsDataColumns = [
    {
      title: "TICKET ID",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      fixed: "left",
      width: 50,
      ellipsis: true,
      render: (ticketNumber) => {
        return <Space>{ticketNumber ? ticketNumber : "-"}</Space>;
      },
    },
    {
      title: "CREATED DATE",
      dataIndex: "createdData",
      key: "createdData",
      ellipsis: true,
      fixed: "left",
      width: 110,
      render: (_, record) => {
        return getUserDateTime(
          record.createdData,
          state.currentUser.user.timeZone,
          state.currentUser.user.dateFormat
        );
      },
    },
    {
      title: "SUBJECT",
      dataIndex: "subject",
      key: "subject",
      ellipsis: true,
      fixed: "left",
      width: 180,
      render: (subject) => {
        return <Space>{subject ? subject : "-"}</Space>;
      },
    },
    {
      title: "PRIORITY",
      dataIndex: "priority",
      key: "priority",
      ellipsis: true,
      fixed: "left",
      width: 70,
      render: (priority) => {
        return <Space>{priority ? priority : "-"}</Space>;
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      fixed: "left",
      width: 60,
      render: (status) => {
        return <Space>{status ? status : "-"}</Space>;
      },
    },
    {
      title: "ASSIGNED TO",
      dataIndex: "assignedTo",
      key: "assignedTo",
      ellipsis: true,
      fixed: "left",
      width: 120,
      render: (assignedTo) => {
        return <Space>{assignedTo ? assignedTo : "-"}</Space>;
      },
    },
  ];

  const ticketsActionColumn = [
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      onCell: () => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // This will avoid onRow being called
          },
        };
      },

      render: (_, record) => {
        return (
          <Space size={24}>
            <Tooltip placement="top" title="View Conversation">
              <BiConversation
                size="16px"
                style={{
                  color: "#F05742",
                  cursor: "pointer",
                  marginTop: 2,
                }}
                onClick={() => {
                  dispatchReducer({
                    type: "RD_SET_CONVERSATION_FILTER",
                    payload: {
                      conversationFilter: true,
                    },
                  });

                  dispatchReducer({
                    type: "OPEN_INFO_PANEL",
                    payload: {
                      component:
                        "client-responses-view-conversation-info-panel",
                    },
                  });

                  // Clear existing conversation
                  if (state.conversations.contactsWithTranscripts) {
                    dispatchReducer({
                      type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
                    });
                    dispatchReducer({
                      type: "RD_CLEAR_CURRENT_CONVERSATION",
                    });
                  }

                  // Load selected conversation based on unrecognized intent
                  dispatchMiddleware({
                    type: "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS",
                    payload: {
                      conversationId: record.conversationId,
                      botId: record.botId,
                      component: "conversations-layout",
                      contactId: record.conversationId + "_" + record.botId,
                      leadDate: record.createdData,
                    },
                  });
                }}
              />
            </Tooltip>

            <Tooltip placement="left" title="Delete Tickets">
              <DeleteOutlined style={{ color: "#F05742" }} />
            </Tooltip>
          </Space>
        );
      },
      ellipsis: true,
      width: 60,
    },
  ];

  return { ticketsDataColumns, ticketsActionColumn };
};
export default TicketsTableColumns;
