import React, { useEffect, useState } from "react";
import { Drawer, Row, Steps } from "antd";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  CloudUploadOutlined,
  ProfileOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import WebhooksForm from "./WebhooksForm";
import WebhooksTrigger from "./WebhooksTrigger";
import SubmitWebhook from "./SubmitWebhook";

const WebhooksFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  const [currentPosition, setCurrentPosition] = useState(0);
  const [webhooksData, setWebhooksData] = useState(null);
  const [triggerWebhooksData, setTriggerWebhooksData] = useState(null);
  const [autoEmailTrigger, setAutoEmailTrigger] = useState(false);
  const [whatsappNotification, setWhatsappNotification] = useState(false);

  const [external, setExternal] = useState(false);
  const [createContactFromResponse, setCreateContactFromResponse] =
    useState(false);
  const [selectedWebhookType, setSelectedWebhookType] = useState("external");

  const handleWebhooksData = (value) => {
    const { webhookType, ...updatedWebhooksData } = value;

    if (webhookType === "createContactFromResponse") {
      // Update the createContactFromResponse and autoEmailTrigger fields
      updatedWebhooksData.createContactFromResponse = true;
      updatedWebhooksData.autoEmailTrigger = false;
      updatedWebhooksData.external = false;
    } else if (webhookType === "autoEmailTrigger") {
      // Update the createContactFromResponse and autoEmailTrigger fields
      updatedWebhooksData.createContactFromResponse = false;
      updatedWebhooksData.autoEmailTrigger = true;
      updatedWebhooksData.external = false;
    } else if (webhookType === "whatsappNotification") {
      // Update the createContactFromResponse and autoEmailTrigger fields
      updatedWebhooksData.createContactFromResponse = false;
      updatedWebhooksData.autoEmailTrigger = false;
      updatedWebhooksData.external = false;
      updatedWebhooksData.whatsappNotification = true;
    } else if (webhookType === "external") {
      // Update the createContactFromResponse and autoEmailTrigger fields
      updatedWebhooksData.createContactFromResponse = false;
      updatedWebhooksData.autoEmailTrigger = false;
      updatedWebhooksData.external = true;
    }

    setWebhooksData(updatedWebhooksData);
    setCurrentPosition(currentPosition + 1);
  };

  const handleTriggerWebhooksData = (value) => {
    setTriggerWebhooksData(
      webhooksData?.whatsappNotification ? { ...value, ...webhooksData } : value
    );
    setCurrentPosition(currentPosition + 1);
  };

  const contentSteps = [
    {
      title: "Webhook",
      content: (
        <WebhooksForm
          webhooksData={webhooksData}
          onFinish={handleWebhooksData}
          setCurrentPosition={setCurrentPosition}
          currentPosition={currentPosition}
          selectedWebhookType={selectedWebhookType}
          setSelectedWebhookType={setSelectedWebhookType}
          autoEmailTrigger={autoEmailTrigger}
          setAutoEmailTrigger={setAutoEmailTrigger}
          external={external}
          setExternal={setExternal}
          createContactFromResponse={createContactFromResponse}
          whatsappNotification={whatsappNotification}
          setWhatsappNotification={setWhatsappNotification}
          setCreateContactFromResponse={setCreateContactFromResponse}
        />
      ),
    },
    {
      title: "Trigger",
      content: (
        <WebhooksTrigger
          triggerWebhooksData={triggerWebhooksData}
          onFinish={handleTriggerWebhooksData}
          setCurrentPosition={setCurrentPosition}
          currentPosition={currentPosition}
          webhooksData={webhooksData}
        />
      ),
    },
    {
      title: "Submit",
      content: (
        <SubmitWebhook
          triggerWebhooksData={triggerWebhooksData}
          webhooksData={webhooksData}
          setCurrentPosition={setCurrentPosition}
          setWebhooksData={setWebhooksData}
          setTriggerWebhooksData={setTriggerWebhooksData}
          setAutoEmailTrigger={setAutoEmailTrigger}
          setExternal={setExternal}
          setCreateContactFromResponse={setCreateContactFromResponse}
          setSelectedWebhookType={setSelectedWebhookType}
          setWhatsappNotification={setWhatsappNotification}
        />
      ),
    },
  ];

  const handleIsStepDisabled = (stepNumber) => {
    if (stepNumber === 0) {
      return false;
    }
    if (stepNumber === 1) {
      return webhooksData === null;
    }
    if (stepNumber === 2) {
      return webhooksData === null || triggerWebhooksData === null;
    }
  };

  return (
    <Drawer
      title={state.formPanel["webhooks-form-panel"]?.title}
      open={state.formPanel["webhooks-form-panel"]?.visible}
      width={650}
      destroyOnClose={true}
      onClose={() => {
        setWebhooksData(null);
        setTriggerWebhooksData(null);
        setCurrentPosition(0);
        setAutoEmailTrigger(false); // Reset other state variables as well
        setExternal(false);
        setCreateContactFromResponse(false);
        setSelectedWebhookType("external"); // Set the default value for webhook type

        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "webhooks-form-panel",
          },
        });
        dispatchReducer({
          type: "SET_FORM_MODE",
          payload: {
            component: "webhooks-form",
            mode: "",
            title: "",
          },
        });
      }}
    >
      {state.apiCallStatus["webhooks-form-panel"] &&
      state.apiCallStatus["webhooks-form-panel"].loading ? (
        <FullPageLoader
          component="webhooks-form-panel"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <>
          <Steps onChange={setCurrentPosition} current={currentPosition}>
            <Steps.Step
              disabled={handleIsStepDisabled(0)}
              title="Webhook"
              icon={<CloudUploadOutlined />}
            />
            <Steps.Step
              disabled={handleIsStepDisabled(1)}
              title="Trigger"
              icon={<ProfileOutlined />}
            />
            <Steps.Step
              disabled={handleIsStepDisabled(2)}
              title="Submit"
              icon={<CheckCircleOutlined />}
            />
          </Steps>

          <Row style={{ marginTop: "20px" }}>
            {contentSteps[currentPosition].content}
          </Row>
        </>
      )}
    </Drawer>
  );
};

export default WebhooksFormPanel;
