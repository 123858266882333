import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Analytics from "../pages/analytics/Analytics";
import AccountSettings from "../pages/accounts-settings/AccountSettings";
import AccountGeneral from "../pages/accounts-settings/account-general/AccountGeneral";
import AccountBilling from "../pages/accounts-settings/account-billing/AccountBilling";
import AccountAPI from "../pages/accounts-settings/account-api/AccountAPI";
import LiveAgent from "../pages/accounts-settings/live-agent/LiveAgent";
import Broadcast from "../pages/accounts-settings/broadcast/Broadcast";
import Responses from "../pages/accounts-settings/response/Responses";
import AutomatedReplies from "../pages/accounts-settings/automated-replies/AutomatedReplies";
import AccountUsers from "../pages/accounts-settings/account-users/AccountUsers";
import AccountContacts from "../pages/accounts-settings/account-contacts/AccountContacts";
import DeliveryServices from "../pages/accounts-settings/delivery-services/DeliveryServices";
import BroadcastManager from "../pages/broadcast-manager/BroadcastManager";
import ContactManager from "../pages/contact-manager/ContactManager";
// import SupportTickets from "../pages/support-tickets/SupportTickets";
import ClientResponses from "../pages/client-responses/ClientResponses";
import KnowledgeArea from "../pages/knowledge-area/KnowledgeArea";
import ActivityArea from "../pages/activity-area/ActivityArea";
import Conversations from "../pages/conversations/Conversations";
import Conversationsv2 from "../pages/conversations-v2/Conversations";
import ModuleNotSubscribed from "../shared-components/ModuleNotSubscribed";
import BotWHATSAPP from "../pages/bot-settings/whatsapp/BotWHATSAPP";
import BotProfile from "../pages/bot-settings/BotProfile";
import BotINSTAGRAM from "../pages/bot-settings/BotINSTAGRAM";
import BotFACEBOOK from "../pages/bot-settings/BotFACEBOOK";
import BotWEB from "../pages/bot-settings/web/BotWEB";
import BotTELEGRAM from "../pages/bot-settings/BotTELEGRAM";
import BotGBM from "../pages/bot-settings/BotGBM";
import BotAMB from "../pages/bot-settings/BotAMB";
import BotTEAMS from "../pages/bot-settings/BotTEAMS";
import BotLINE from "../pages/bot-settings/BotLINE";
import BotALEXA from "../pages/bot-settings/BotALEXA";
import BotSLACK from "../pages/bot-settings/BotSLACK";
import BotGBP from "../pages/bot-settings/BotGBP";
import Webhooks from "../pages/accounts-settings/webhooks/Webhooks";
import CannedResponses from "../pages/accounts-settings/canned-response/CannedResponses";
import Logout from "../shared-components/Logout";
import { useGlobalContext } from "../contexts/globalContext";
import ContentModeration from "../pages/accounts-settings/content-moderation/ContentModeration";
import Tickets from "../pages/tickets/Ticket";

const routes = [
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/",
    element: <Dashboard />,
    exact: true,
  },
  {
    path: "/analytics/responses-charts",
    element: <Analytics tab={1} />,
  },
  {
    path: "/analytics/conversation-charts",
    element: <Analytics tab={2} />,
  },
  {
    path: "/analytics/broadcast-charts",
    element: <Analytics tab={3} />,
  },
  {
    path: "/analytics/contact-charts",
    element: <Analytics tab={4} />,
  },
  {
    path: "/analytics/knowledge-area-charts",
    element: <Analytics tab={5} />,
  },
  {
    path: "/analytics/tickets-charts",
    element: <Analytics tab={6} />,
  },
  {
    path: "/contacts",
    element: <ContactManager tab={1} />,
  },
  {
    path: "knowledge-area/message-templates",
    element: <KnowledgeArea tab={1} />,
  },
  {
    path: "/knowlege-area/unrecognized-intents",
    element: <KnowledgeArea tab={2} />,
  },
  {
    path: "/knowlege-area/bot-knowledgebase",
    element: <KnowledgeArea tab={3} />,
  },
  {
    path: "/knowlege-area/whatsapp-flows",
    element: <KnowledgeArea tab={4} />,
  },
  {
    path: "/knowlege-area/bot-configuration",
    element: <KnowledgeArea tab={5} />,
  },
  {
    path: "/activity-area/activity-logs",
    element: <ActivityArea tab={1} />,
  },
  {
    path: "/activity-area/planned-activities",
    element: <ActivityArea tab={2} />,
  },
  {
    path: "/activity-area/conversation-flows",
    element: <ActivityArea tab={3} />,
  },
  {
    path: "/account-settings",
    element: <AccountSettings />,
  },
  {
    path: "/account-settings/general",
    element: <AccountGeneral />,
  },
  {
    path: "/account-settings/billing",
    element: <AccountBilling />,
  },
  {
    path: "/account-settings/api",
    element: <AccountAPI />,
  },
  {
    path: "/account-settings/account-users",
    element: <AccountUsers />,
  },
  {
    path: "/account-settings/notification",
    element: "",
  },
  {
    path: "/account-settings/broadcast",
    element: <Broadcast />,
  },
  {
    path: "/account-settings/live-agent",
    element: <LiveAgent />,
  },
  {
    path: "/account-settings/responses",
    element: <Responses />,
  },
  {
    path: "/account-settings/automated-replies",
    element: <AutomatedReplies />,
  },
  {
    path: "/account-settings/contacts",
    element: <AccountContacts />,
  },
  {
    path: "/account-settings/canned-responses",
    element: <CannedResponses />,
  },
  {
    path: "/account-settings/content-moderate",
    element: <ContentModeration />,
  },
  {
    path: "/account-settings/webhooks",
    element: <Webhooks />,
  },
  {
    path: "/account-settings/delivery-services",
    element: <DeliveryServices tab={1} />,
  },
  {
    path: "/broadcast-manager",
    element: <BroadcastManager />,
  },
  {
    path: "/bot-settings/general",
    element: <BotProfile />,
  },
  {
    path: "/bot-settings/whatsapp",
    element: <BotWHATSAPP />,
  },
  {
    path: "/bot-settings/gbm",
    element: <BotGBM />,
  },
  {
    path: "/bot-settings/amb",
    element: <BotAMB />,
  },
  {
    path: "/bot-settings/instagram",
    element: <BotINSTAGRAM />,
  },
  {
    path: "/bot-settings/facebook",
    element: <BotFACEBOOK />,
  },
  {
    path: "/bot-settings/directline",
    element: <BotWEB />,
  },
  {
    path: "/bot-settings/telegram",
    element: <BotTELEGRAM />,
  },
  {
    path: "/bot-settings/teams",
    element: <BotTEAMS />,
  },
  {
    path: "/bot-settings/line",
    element: <BotLINE />,
  },
  {
    path: "/bot-settings/alexa",
    element: <BotALEXA />,
  },
  {
    path: "/bot-settings/slack",
    element: <BotSLACK />,
  },
  {
    path: "/bot-settings/gbp",
    element: <BotGBP />,
  },
  // {
  //   path: "/support-tickets",
  //   element: <SupportTickets />,
  // },
  {
    path: "/tickets",
    element: <Tickets />,
  },
  {
    path: "/client-responses",
    element: <ClientResponses />,
  },
  {
    path: "/conversations",
    // element added conditionally before the routes
    element: "",
  },
  {
    path: "/module-not-subscribed/conversation",
    element: <ModuleNotSubscribed />,
  },
];

const RoutingList = () => {
  const [state] = useGlobalContext();

  // Set conversation routes based on live agent version
  const updatedRoutes = routes.map((route) => {
    if (route.path === "/conversations") {
      const liveAgentVersion = parseInt(
        state.currentAccount.account.modules.conversation.live_agent_version
      );
      const element =
        liveAgentVersion === 2 ? <Conversationsv2 /> : <Conversations />;
      return { ...route, element };
    }
    return route;
  });

  return (
    <Routes>
      {updatedRoutes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Routes>
  );
};

export default RoutingList;
