import React, { useState } from "react";
import { Drawer, Row, Col, Typography, Tag } from "antd";
import { BellOutlined } from "@ant-design/icons";
import moment from "moment";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiBroadcast } from "react-icons/bi";
import { AiOutlineUsergroupAdd, AiOutlineCloseCircle } from "react-icons/ai";
import { HiLightBulb } from "react-icons/hi";
import { PiWebhooksLogoDuotone } from "react-icons/pi";
import { useGlobalContext } from "../../contexts/globalContext";

const { Text } = Typography;

const NotificationCenter = () => {
  const [state, dispatchReducer] = useGlobalContext();

  const [isShowMoreActive, setShowMoreActive] = useState(false);
  const [notificationID, setNotificationID] = useState(null);

  const showDrawer = () => {
    dispatchReducer({
      type: "OPEN_INFO_PANEL",
      payload: {
        component: "notification-center-info-panel",
      },
    });
  };

  const onClose = () => {
    dispatchReducer({
      type: "CLOSE_INFO_PANEL",
      payload: {
        component: "notification-center-info-panel",
      },
    });
  };

  return (
    <>
      <BellOutlined
        onClick={showDrawer}
        style={{ fontSize: "20px", margin: "auto" }}
      />

      <Drawer
        title="Notification Center"
        width={480}
        onClose={onClose}
        open={
          state.infoPanel["notification-center-info-panel"] &&
          state.infoPanel["notification-center-info-panel"].visible
        }
        bodyStyle={{
          paddingBottom: 80,
          backgroundColor: "#f3f3f3",
          padding: "10px",
        }}
      >
        {/* <Row justify="end">
          <Col span={4}>
            <Button
              type="text"
              onClick={handleNotificationDelete}
              style={{
                fontSize: "12px",
                color: "#f15e42",
                padding: "0em",
              }}
            >
              <DeleteOutlined />
              Delete all
            </Button>
          </Col>
        </Row> */}
        {[
          // {
          //   _id: "1",
          //   moduleType: "WEBHOOKS",
          //   isRead: false,
          //   createdAt: new Date(),
          //   notificationTitle: "New webhook created",
          //   notificationMessage:
          //     "New webhook auto email trigger has been created by iNextLabs Dev Test (devtest@inextlabs.com)",
          // },
          // {
          //   _id: "2",
          //   moduleType: "USERS",
          //   isRead: false,
          //   createdAt: new Date(),
          //   notificationTitle: "New user added !!!",
          //   notificationMessage: `User "shanthi"(shanthi@gmail.com) has been added by iNextLabs Dev Test (devtest@inextlabs.com)`,
          // },
          // {
          //   _id: "3",
          //   moduleType: "LIVE AGENT",
          //   isRead: false,
          //   createdAt: new Date(),
          //   notificationTitle: "Live agent configuration changed",
          //   notificationMessage:
          //     "LiveAgent configuration for the bot 'iNextLabs-Test-Bot' have been modified by iNextlabs Dev Test (devtest@inextlabs.com)",
          //   valuesChanged: {
          //     "Availability.Monday.Open": "12:10:00 to 12:10:00",
          //     "LiveAgent available timezone":
          //       "(GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi to (GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi",
          //   },
          //   tags: ["Tag1", "Tag2"],
          // },
          // {
          //   _id: "4",
          //   moduleType: "BROADCAST",
          //   isRead: false,
          //   createdAt: new Date(),
          //   notificationTitle: "Broadcast configuration changed",
          //   notificationMessage:
          //     "LiveAgent configuration for the bot 'iNextLabs-Test-Bot' have been modified by iNextlabs Dev Test (devtest@inextlabs.com)",
          //   valuesChanged: {
          //     property1: "oldValue",
          //     property2: "newValue",
          //   },
          // },
          // {
          //   _id: "6",
          //   moduleType: "WEBHOOKS",
          //   isRead: false,
          //   createdAt: new Date(),
          //   notificationTitle: "New webhook created",
          //   notificationMessage:
          //     "New webhook auto email trigger has been created by iNextlabs Dev Test (devtest@inextlabs.com)",
          // },
        ].map((notification, index) => (
          <Col
            style={{
              color: "#f15e42",
              border: "1px solid #c3c3c3",
              borderRadius: "5px",
              padding: "0.8em",
              display: "flex",
              flexDirection: "column",
              margin: "0.5em 0em",
            }}
            span={24}
            key={index}
          >
            <Row justify="space-between" gutter={[10, 10]}>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
                flex="auto"
                span={23}
              >
                {notification.moduleType === "LIVE AGENT" ? (
                  <MdOutlineSupportAgent size="20" />
                ) : notification.moduleType === "BROADCAST" ? (
                  <BiBroadcast size="20" />
                ) : notification.moduleType === "USERS" ? (
                  <AiOutlineUsergroupAdd size="20" />
                ) : notification.moduleType === "WEBHOOKS" ? (
                  <PiWebhooksLogoDuotone size="20" />
                ) : (
                  <HiLightBulb size="20" />
                )}
                &nbsp;
                {notification.moduleType}{" "}
                <Text
                  style={{
                    fontWeight: "100",
                    fontSize: "10px",
                    color: "#858382",
                  }}
                >
                  &nbsp;-&nbsp;{moment(notification.createdAt).fromNow()}
                </Text>
              </Col>
              <Col span={1}>
                {notification?._id && (
                  <AiOutlineCloseCircle
                    onClick={() => {}}
                    style={{
                      cursor: "pointer",
                    }}
                    color="#f15e42"
                  />
                )}
              </Col>
            </Row>
            <Col
              span={24}
              style={{
                fontWeight: "600",
                lineHeight: "1.8em",
                color: "#000000",
                fontSize: "12px",
                margin: "5px 0px",
              }}
              flex="auto"
            >
              {notification?.notificationTitle}
            </Col>
            <Col
              span={24}
              style={{
                color: "#000000",
                fontSize: "12px",
              }}
            >
              {notification?.notificationMessage}
            </Col>
            <Col span={24}>
              {notification?.valuesChanged && (
                <Text
                  style={{
                    color: "#f15e42",
                    cursor: "pointer",
                    marginTop: "10px",
                    fontSize: "11px",
                  }}
                  onClick={() => {
                    setShowMoreActive(!isShowMoreActive);
                    setNotificationID(notification._id);
                  }}
                >
                  {isShowMoreActive ? "Show less" : "Show more"}
                </Text>
              )}
            </Col>
            <Col span={24}>
              {isShowMoreActive &&
                notificationID === notification?._id &&
                Object.keys(notification?.valuesChanged)?.map(
                  (valueDifference, index) => (
                    <Text
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        lineHeight: "30px",
                        fontSize: "11px",
                      }}
                    >
                      {`${valueDifference
                        .toUpperCase()
                        .replaceAll(".", " > ")
                        .replaceAll("_", " ")} changed from`}
                      <Tag
                        color="orange"
                        style={{
                          maxWidth: "350px",
                          whiteSpace: "pre-line",
                          wordWrap: "break-word",
                        }}
                      >
                        {Object.values(notification?.valuesChanged)[index]}
                      </Tag>
                    </Text>
                  )
                )}
            </Col>
            <Col span={24}>
              {notification?.tags &&
                notification?.tags?.map((tag, index) => (
                  <Tag
                    key={index}
                    style={{ width: "max-content", marginTop: "10px" }}
                    color="gold"
                  >
                    {tag}
                  </Tag>
                ))}
            </Col>
          </Col>
        ))}
      </Drawer>
    </>
  );
};

export default NotificationCenter;
