import { useEffect, useState } from "react";
import { Typography, List, Card, Col, Row, Image } from "antd";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { useGlobalContext } from "../../contexts/globalContext";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import { mode } from "../../constants/env";

const { Title } = Typography;

const DashboardLatestUpdate = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [selectedNews, setSelectedNews] = useState(null);

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_LATEST_NEWS_UPDATE_DATA",
      payload: { component: "latest-news-update" },
    });
  });

  const latestUpdatesConfiguration = [
    {
      backgroundColor: "#ece7f0",
      borderRadius: "10px",
      border: "1px solid #c5b9d8",
      bordertop: "5px solid #ece7f0",
    },
    {
      backgroundColor: "#ebf4e0",
      borderRadius: "10px",
      border: "1px solid #c2d9b3",
      bordertop: "5px solid #ebf4e0",
    },
    {
      backgroundColor: "#e4e9ec",
      borderRadius: "10px",
      border: "1px solid #b2c9e0",
      bordertop: "5px solid #e4e9ec",
    },
  ];
  useEffect(() => {
    setSelectedNews({
      ...state.dashBoard?.latestNews?.[0],
      ...{
        latestUpdatesConfiguration: latestUpdatesConfiguration[0],
      },
    });
  }, [state.dashBoard?.latestNews]);
  return (
    <>
      {state.dashBoard?.latestNews ? (
        <>
          <Title level={5}>LATEST UPDATES</Title>

          {mode === "DEV" ? (
            <>
              <List
                grid={{
                  gutter: 16,
                  column: 3,
                }}
                dataSource={state.dashBoard?.latestNews.map(
                  (newsUpdate, index) => {
                    return {
                      ...newsUpdate,
                      ...{
                        latestUpdatesConfiguration:
                          latestUpdatesConfiguration[index],
                      },
                    };
                  }
                )}
                renderItem={(newsUpdate) => (
                  <List.Item onClick={() => setSelectedNews(newsUpdate)}>
                    <Card style={newsUpdate.latestUpdatesConfiguration}>
                      <Row gutter={16}>
                        <Col span={6}>
                          <Image
                            src={newsUpdate.image}
                            alt={newsUpdate.title}
                            preview={false}
                            style={{
                              width: "100px",
                              borderRadius: "10px",
                            }}
                          />
                        </Col>
                        <Col
                          span={17}
                          offset={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <List.Item.Meta
                            title={newsUpdate.title}
                            description={newsUpdate.shortDescription}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
              />
              {selectedNews && (
                <Card
                  style={{
                    borderRadius: "10px",
                    border: `${selectedNews?.latestUpdatesConfiguration?.border}`,
                    borderTop: ` ${selectedNews?.latestUpdatesConfiguration?.bordertop} `,
                  }}
                >
                  <Row gutter={16}>
                    <Col span={6}>
                      <Image
                        src={selectedNews?.description?.image}
                        preview={false}
                        alt={selectedNews.title}
                        style={{
                          width: "70%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    </Col>

                    <Col
                      span={18}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <List.Item.Meta
                        title={<Title level={5}>{selectedNews.title}</Title>}
                        description={selectedNews?.description?.text}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
            </>
          ) : (
            <List
              dataSource={state.dashBoard?.latestNews}
              renderItem={(newsUpdate) => (
                <List.Item>
                  <List.Item.Meta
                    title={newsUpdate.header}
                    description={newsUpdate.bodyText}
                  />
                </List.Item>
              )}
            />
          )}
        </>
      ) : (
        <FullPageLoader
          component="latest-news-update"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default DashboardLatestUpdate;
