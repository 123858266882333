import React from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Table, Row, Col, Typography, Space } from "antd";
import { getUserDateTime } from "../../utils/utility";
import "./ClientResponses.less";

const { Text } = Typography;

const PrintInvoicePanel = ({ selectedResponse }) => {
  const [state] = useGlobalContext();

  const company = state.currentAccount.account.general;

  selectedResponse = {
    ...selectedResponse,
    items: selectedResponse.items?.map((item) => {
      return {
        ...item,
        price:
          typeof item.price === "string" || typeof item.price === "number"
            ? item.price
            : (item.price.currency || "") + " " + (item.price.totalPrice || ""),
      };
    }),
  };

  return (
    <Row id="contentToPrint" gutter={[0, 20]}>
      <Col
        span={12}
        offset={6}
        style={{
          width: "100%",
          textAlign: "center",
          borderBottom: "1px dashed",
        }}
      >
        <Space
          direction="vertical"
          size={7}
          style={{
            marginBottom: 10,
          }}
        >
          {company.reg_name && <Text strong> {company.reg_name}</Text>}

          {company.address ||
            (company.city && (
              <Text>
                {" "}
                {company.address ??
                  company.address + " " + company.city ??
                  company.city}
              </Text>
            ))}

          {company.country ||
            (company.postal_code && (
              <Text>
                {company.country ??
                  company.country + " - " + company.postal_code ??
                  company.postal_code}
              </Text>
            ))}

          {company.phone_number && <Text>{company.phone_number}</Text>}

          {company.email_address && <Text>{company.email_address}</Text>}
        </Space>
      </Col>

      <Col
        span={11}
        style={{
          width: "100%",
          borderBottom: "1px dashed",
        }}
      >
        <Space
          direction="vertical"
          size={7}
          style={{
            margin: "10px 0",
          }}
        >
          {selectedResponse.userProfileName && (
            <Text strong ellipsis>
              {selectedResponse.userProfileName}
            </Text>
          )}

          {selectedResponse.deliveryAddress && (
            <Text ellipsis> {selectedResponse.deliveryAddress}</Text>
          )}

          {selectedResponse.userPhoneNumber && (
            <Text ellipsis>{selectedResponse.userPhoneNumber}</Text>
          )}

          {selectedResponse.userEmail && (
            <Text ellipsis>{selectedResponse.userEmail}</Text>
          )}

          {selectedResponse.distance && (
            <Text ellipsis>{"Distance: " + selectedResponse.distance}</Text>
          )}
          {selectedResponse.deliveryMethod && (
            <Text ellipsis>
              {"Delivery Method: " + selectedResponse.deliveryMethod}
            </Text>
          )}

          <Text ellipsis>
            Delivery Charges:{" "}
            {selectedResponse.deliveryCharges === 0
              ? "0"
              : selectedResponse.deliveryCharges || "-"}
          </Text>

          {selectedResponse.paymentMethod && (
            <Text ellipsis>
              {"Payment Method: " + selectedResponse.paymentMethod}
            </Text>
          )}
          {selectedResponse.paymentStatus && (
            <Text ellipsis>
              {"Payment Status: " + selectedResponse.paymentStatus}
            </Text>
          )}
        </Space>
      </Col>

      <Col
        span={24}
        style={{
          width: "100%",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        {selectedResponse.orderId && (
          <div>
            <Text strong>Order ID: </Text>
            <Text>{selectedResponse.orderId}</Text>
          </div>
        )}

        {selectedResponse.timestamp && (
          <Space>
            <Text>
              {getUserDateTime(
                selectedResponse.timestamp,
                state.currentUser.user.timeZone,
                state.currentUser.user.dateFormat
              )}
            </Text>
          </Space>
        )}
      </Col>

      <Col span={24}>
        <Table
          dataSource={selectedResponse.items}
          columns={[
            {
              title: "Item Name",
              dataIndex: "name",
              key: "name",
              width: "60%",
              align: "left",
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              key: "quantity",
              width: "10%",
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
              width: "25%",
              align: "right",
            },
          ]}
          style={{
            width: "100%",
            border: "1px dashed",
          }}
          bordered
          pagination={false}
          className="custom-table"
        />
      </Col>

      <Col
        span={12}
        offset={6}
        style={{
          width: "100%",
          textAlign: "center",
          borderBottom: "1px dashed",
          marginBottom: 10,
          marginTop: 15,
        }}
      >
        {selectedResponse.price && (
          <div
            style={{
              margin: "10px 0",
            }}
          >
            <Text strong>Total Price: </Text>
            <Text>
              {selectedResponse.price.currency +
                " " +
                selectedResponse.price.totalPrice}
            </Text>

            {selectedResponse.price.discount && (
              <div
                style={{
                  margin: "10px 0",
                }}
              >
                <Text strong>Discount: </Text>

                <Text>{selectedResponse.price.discount}</Text>
              </div>
            )}
          </div>
        )}
      </Col>

      {selectedResponse.note && (
        <Col
          span={24}
          style={{
            width: "100%",
            borderBottom: "1px dashed",
          }}
        >
          <Space
            style={{
              margin: "10px 0",
            }}
          >
            Notes: {" " + selectedResponse.note}
          </Space>
        </Col>
      )}

      <Col
        span={24}
        style={{
          width: "100%",
          borderBottom: "1px dashed",
          textAlign: "center",
        }}
      >
        <Space
          style={{
            margin: "10px 0",
          }}
        >
          THANK YOU VISIT AGAIN!
        </Space>
      </Col>
    </Row>
  );
};

export default PrintInvoicePanel;
