const contactManagerReducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTACT_MANAGER":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            contacts: action.payload.contacts,
          },
        },
      };

    case "ADD_CONTACT":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{ contacts: [action.contact, ...state.contactManager.contacts] },
        },
      };

    case "EDIT_CONTACT":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            contacts: state.contactManager.contacts.map((contact) =>
              contact._id === action.contact.id
                ? Object.assign({}, contact, action.contact.data)
                : contact
            ),
          },
        },
      };

    case "DELETE_SINGLE_CONTACT":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            contacts: state.contactManager.contacts.filter(
              (contact) => action.actionRowKey !== contact._id
            ),
          },
        },
      };

    case "RD_DELETE_MULTIPLE_CONTACTS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            contacts: state.contactManager.contacts.filter(
              (contact) => !action.payload.selectedRowKeys.includes(contact._id)
            ),
          },
        },
      };

    case "RD_CLEAR_INVALID_CONTACTS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            invalidContacts: undefined,
          },
        },
      };

    case "SET_CONTACT_MANAGER_FILTERED_INFO":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            filters: {
              ...state.contactManager.filters,
              ...{ filteredInfo: action.filteredInfo },
            },
          },
        },
      };

    case "SET_CONTACT_MANAGER_SELECTED_ROW_KEYS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            filters: {
              ...state.contactManager.filters,
              ...{ selectedRowKeys: action.selectedRowKeys },
            },
          },
        },
      };

    case "SET_CONTACT_MANAGER_ACTION_ROW_KEY":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            filters: {
              ...state.contactManager.filters,
              ...{ actionRowKey: action.actionRowKey },
            },
          },
        },
      };

    case "CLEAR_CONTACT_MANAGER_SELECTED_ROW_KEYS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            filters: {
              ...state.contactManager.filters,
              ...{ selectedRowKeys: [] },
            },
          },
        },
      };

    case "CLEAR_CONTACT_MANAGER_ACTION_ROW_KEY":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            filters: {
              ...state.contactManager.filters,
              ...{ actionRowKey: "" },
            },
          },
        },
      };

    case "SET_IMPORT_CONTACT_CURRENT_STEP":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{ currentStep: action.payload.currentStep },
            },
          },
        },
      };

    case "SET_IMPORT_CONTACT_COLUMNS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{ columns: action.payload.columns },
            },
          },
        },
      };

    case "SET_IMPORT_CONTACT_CSV_FILE":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{ csvFile: action.payload.csvFile },
            },
          },
        },
      };

    case "SET_IMPORT_CONTACT_CSV_DATA":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{ csvData: action.payload.data },
            },
          },
        },
      };

    case "SET_IMPORT_CONTACT_COLUMN_MAPPING":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{
                columns: state.contactManager.importContacts.columns.map(
                  (csvColumn, index) => {
                    if (index === action.payload.csvColumnIndex) {
                      return {
                        ...csvColumn,
                        ...{ mappedColumn: action.payload.mappedColumn },
                      };
                    }
                    return csvColumn;
                  }
                ),
              },
            },
          },
        },
      };

    case "ADD_IMPORT_CONTACTS_TAGS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{
                tags: [
                  ...(state.contactManager.importContacts.tags || []),
                  action.payload.tag,
                ],
              },
            },
          },
        },
      };

    case "REMOVE_IMPORT_CONTACTS_TAGS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              ...{
                tags: state.contactManager.importContacts.tags.filter(
                  (tag) => tag !== action.payload.tag
                ),
              },
            },
          },
        },
      };

    case "CLEAR_IMPORT_CONTACT_DETAILS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {},
          },
        },
      };

    case "SET_IMPORTED_CONTACTS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              importedContacts: action.payload.importedContacts,
            },
          },
        },
      };

    case "SET_INVALID_CONTACTS":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              invalidContacts: action.payload.invalidContacts,
            },
          },
        },
      };

    case "SET_VALID_CONTACTS_COUNT":
      return {
        ...state,
        contactManager: {
          ...state.contactManager,
          ...{
            importContacts: {
              ...state.contactManager.importContacts,
              validContactsCount: action.payload.validContactsCount,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default contactManagerReducer;
