import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Row, Col, Form, Button, Space, Tooltip, Image, Modal } from "antd";
import InputEmoji from "react-input-emoji";
import { AiOutlineSend } from "react-icons/ai";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { quickReplyIcon, wabaProvider } from "../../constants/enums";
import CannedResponses from "../accounts-settings/canned-response/CannedResponses";
import { useConnectApi } from "../../connect/connectApi";
import { apiEndPoints } from "../../constants/enums";
import openNotificationWithIcon from "../../utils/notification";
import "./Conversations.less";

//For auto focusing input field on page load, created new component
const Input = ({ handleOnEnter, setSelectedMessage, selectedMessage }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <InputEmoji
      ref={inputRef}
      value={selectedMessage}
      onChange={(e) => {
        setSelectedMessage(e);
      }}
      onEnter={(e) => handleOnEnter(e)}
      borderRadius={4}
      placeholder="Type a new message"
      style={{ width: "50px" }}
    />
  );
};

const { confirm } = Modal;

const ConversationFooter = () => {
  const { callApi } = useConnectApi();
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();
  const [showCannedResponses, setShowCannedResponses] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState();
  const [isProfanityFound, setIsProfanityFound] = useState(false);

  //live Agent Settings based on bot
  let liveAgentSettings =
    state.conversations.liveAgentSettingsAcrossBots?.bots?.find(
      (bot) => bot.botId === state.conversations.currentConversation.bot_id
    ) || state.conversations.liveAgentSettingsAcrossBots;

  const filteredBot = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.conversations.currentConversation.bot_id
  );

  const handleRecentMessageSubmit = () => {
    // Call the content moderation API to check for profanity
    if (
      ["STANDARD", "STARTER"].includes(filteredBot.subscription_package) &&
      liveAgentSettings?.liveAgentProfanity
    ) {
      callApi({
        urls: [apiEndPoints.CONTENT_MODERATION_CHECK_PROFANITY],
        options: {
          method: "POST",
          body: {
            text: selectedMessage,
          },
        },
        onSuccess: ([profanityCheck]) => {
          if (profanityCheck.foundProfanity) {
            setSelectedMessage(profanityCheck.cleanText);
            setIsProfanityFound(true);
            openNotificationWithIcon("error", "Profanity word found");
          } else {
            dispatchMiddleware({
              type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
              payload: {
                component: `agent-message-${state.conversations.currentConversation._id}-form`,
                message: selectedMessage,
                botId: state.conversations.currentConversation.bot_id,
                conversationId:
                  state.conversations.currentConversation.conversation_id,
              },
            });
            setIsProfanityFound(false);
            resetFormField();
          }
        },
      });
    } else {
      dispatchMiddleware({
        type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
        payload: {
          component:
            "agent-message-" +
            state.conversations.currentConversation._id +
            "-form",
          message: selectedMessage,
          botId: state.conversations.currentConversation.bot_id,
          conversationId:
            state.conversations.currentConversation.conversation_id,
        },
      });
      resetFormField();
    }
  };

  const resetFormField = () => {
    form.resetFields();
  };

  const handleInitiateConversation = () => {
    const botDetails = state.currentAccount.account.bots.filter(
      (bot) => bot.bot_id === state.conversations.currentConversation.bot_id
    );
    const initiateConversationPayload = {
      bot: botDetails[0],
      broadcastContent: {
        campaignName: "Initiate Conversation",
        channel: state.conversations.currentConversation.channel,
        recipient: `+${state.conversations.currentConversation.conversation_id}`,
        template: {
          logToTranscripts: true,
          templateMessage: "Initiate Conversation (Template Message by agent)",
          namespace: botDetails[0].bot_channel_info.WHATSAPP.waba_namespace,
          language: {
            policy: "deterministic",
            code: liveAgentSettings?.initiateConversationTemplate?.language,
          },
          name: liveAgentSettings?.initiateConversationTemplate?.name,
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: state.conversations.currentConversation.name,
                },
              ],
            },
            {
              type: "button",
              sub_type: "quick_reply",
              index: "0",
              parameters: [
                {
                  type: "payload",
                },
              ],
            },
          ],
        },
      },
      channelConfigurationInfo: {
        "api-provider":
          wabaProvider[botDetails[0].bot_channel_info.WHATSAPP.waba_provider]
            .providerReference.providerName,
        "waba-key": botDetails[0].bot_channel_info.WHATSAPP.waba_apikey,
      },
      mediaContent: {
        hasMedia: false,
        mediaType: "",
        mediaData: {},
      },
      recipientType: "single",
      submitType: "start-broadcast",
      successMessage: "Conversation initiated successfully",
      component:
        "agent-initiate-" +
        state.conversations.currentConversation._id +
        "-button",
    };

    dispatchMiddleware({
      type: "START_BROADCAST",
      payload: {
        ...initiateConversationPayload,
      },
    });
  };

  const checkFor24Hours = () => {
    if (state.conversations.currentConversation.messages) {
      const isFromUserOnly = state.conversations.currentConversation.messages
        .filter((message) => message.is_from_user)
        ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));

      const lastUserMessageTime = new Date(
        isFromUserOnly[isFromUserOnly.length - 1]?.date
      );
      const currentTime = new Date();

      const hoursBetweenDates =
        Math.abs(lastUserMessageTime.getTime() - currentTime.getTime()) /
        (60 * 60 * 1000);

      if (hoursBetweenDates >= 24) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  };

  const handleInputChange = (e) => {
    setSelectedMessage(e.target.value);
    setIsProfanityFound(false); // Reset isProfanityFound when input value is changed
  };

  return (
    <>
      {state.conversations.liveConversations.includes(
        state.conversations.currentConversation._id
      ) ? (
        <Form
          form={form}
          onFinish={handleRecentMessageSubmit}
          autoComplete="off"
        >
          <Row
            style={{
              padding: "22px 15px",
              backgroundColor: "#FAFBFA",
              height: 78,
            }}
            gutter={[8, 0]}
          >
            <Col span={22}>
              <Row gutter={[0, 0]}>
                <Col span={1}>
                  {showCannedResponses ? (
                    <Image
                      src={quickReplyIcon.quickReplyColoredIcon}
                      preview={false}
                      width={30}
                    />
                  ) : (
                    <Image
                      src={quickReplyIcon.quickReplyGreyIcon}
                      preview={false}
                      width={30}
                      onClick={() => {
                        setShowCannedResponses(true);
                      }}
                    />
                  )}

                  <Modal
                    bodyStyle={{ height: 550, overflow: "auto" }}
                    visible={showCannedResponses}
                    width="1250px"
                    onOk={() => {
                      setShowCannedResponses(false);
                    }}
                    onCancel={() => {
                      setShowCannedResponses(false);
                    }}
                    footer={null}
                  >
                    <CannedResponses
                      type="cannedResponse"
                      setShowCannedResponses={setShowCannedResponses}
                      setSelectedMessage={setSelectedMessage}
                      selectedMessage={selectedMessage}
                    />
                  </Modal>
                </Col>

                <Col span={23}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="message" style={{ marginBottom: 5 }}>
                        {/* Using custom input component */}
                        <Input
                          handleOnEnter={(e) => handleRecentMessageSubmit(e)}
                          setSelectedMessage={setSelectedMessage}
                          selectedMessage={selectedMessage}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    {isProfanityFound && (
                      <Col span={20} offset={1}>
                        <Space
                          style={{
                            color: "#F05742",
                            marginLeft: "10px",
                          }}
                        >
                          Profanity found in the message
                        </Space>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Button
                  disabled={
                    state.apiCallStatus[
                      "agent-leave-" +
                        state.conversations.currentConversation._id +
                        "-button"
                    ] &&
                    state.apiCallStatus[
                      "agent-leave-" +
                        state.conversations.currentConversation._id +
                        "-button"
                    ].loading
                  }
                  type="primary"
                  htmlType="submit"
                  style={{
                    borderRadius: 5,
                  }}
                >
                  <AiOutlineSend style={{ color: "#ffffff" }} size={20} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          {state.conversations.currentConversation.channel?.toUpperCase() ===
            "WHATSAPP" && checkFor24Hours() ? (
            //
            //INITIATE CONVERSATION BUTTON - AFTER 24 HOURS (FOR WHATSAPP ONLY)
            //
            <Space
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {state.apiCallStatus[
                "agent-initiate-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ] &&
              state.apiCallStatus[
                "agent-initiate-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ].loading ? (
                <ComponentLoader
                  component={
                    "agent-initiate-" +
                    state.conversations.currentConversation._id +
                    "-button"
                  }
                  loadingMessage="Initiating conversation..."
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    state.conversations.coAgentLiveConversations.includes(
                      state.conversations.currentConversation._id
                    )
                      ? "Already talking to another agent"
                      : state.currentUser.permission.JOIN_CONVERSATION //USER ACTION NAME REMAINS SAME FOR JOIN & INITIATE CONVERSATION
                      ? `It has been more than 24 hours since the customer last replied. 
                      You will need to re-initiate the conversation with a template message and 
                      wait for the customer to respond.`
                      : "You do not have required permission"
                  }
                >
                  <Button
                    type="primary"
                    disabled={
                      !state.currentUser.permission.JOIN_CONVERSATION ||
                      state.conversations.coAgentLiveConversations.includes(
                        state.conversations.currentConversation._id
                      )
                    }
                    onClick={() => {
                      confirm({
                        title:
                          "Are you sure you want to initiate the conversation?",
                        content:
                          "When you click the OK button, you will start the conversation",
                        onOk() {
                          handleInitiateConversation();
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Initiate Conversation
                  </Button>
                </Tooltip>
              )}
            </Space>
          ) : (
            //
            //JOIN CONVERSATION BUTTON - FOR ALL CHANNEL, INCLUDING WHATSAPP (IF LESS THAN 24 HORUS)
            //
            <Space
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {state.apiCallStatus[
                "agent-join-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ] &&
              state.apiCallStatus[
                "agent-join-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ].loading ? (
                <ComponentLoader
                  component={
                    "agent-join-" +
                    state.conversations.currentConversation._id +
                    "-button"
                  }
                  loadingMessage="Joining conversation..."
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    state.conversations.coAgentLiveConversations.includes(
                      state.conversations.currentConversation._id
                    )
                      ? "Already talking to another agent"
                      : state.currentUser.permission.JOIN_CONVERSATION
                      ? "Join Conversation"
                      : "You do not have required permission"
                  }
                >
                  <Button
                    type="primary"
                    disabled={
                      !state.currentUser.permission.JOIN_CONVERSATION ||
                      state.conversations.coAgentLiveConversations.includes(
                        state.conversations.currentConversation._id
                      )
                    }
                    onClick={() => {
                      confirm({
                        title:
                          "Are you sure you want to join the conversation?",
                        content:
                          "When you click the OK button, you will be joined to the conversation",
                        onOk() {
                          dispatchMiddleware({
                            type: "MWD_CONVERSATIONS_AGENT_JOIN",
                            payload: {
                              component:
                                "agent-join-" +
                                state.conversations.currentConversation._id +
                                "-button",
                              botId:
                                state.conversations.currentConversation.bot_id,
                              conversationId:
                                state.conversations.currentConversation
                                  .conversation_id,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Join Conversation
                  </Button>
                </Tooltip>
              )}
            </Space>
          )}
        </>
      )}
    </>
  );
};

export default ConversationFooter;
