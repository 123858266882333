import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useApplicationMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////***********************************************////
      ////***************** APPLICATION *****************////
      ////***********************************************////

      //Load application data such as Roles, Current User Profile, Current Account Profile, Current User Permission
      case "MWD_LOAD_APPLICATION_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.APPLICATION_ROLE,
            apiEndPoints.CURRENT_USER_PROFLE,
            apiEndPoints.ACCOUNT,
            apiEndPoints.ENUMS,
          ],
          options: {
            method: "GET",
          },
          onSuccess: async ([applicationRoles, user, account, enums]) => {
            dispatchReducer({
              type: "SET_APPLICATION_ROLES",
              applicationRoles: applicationRoles.data,
            });
            dispatchReducer({ type: "SET_CURRENT_USER", user });

            //Converting rolePermission into key value pair
            const applicationPermissions = Object.fromEntries(
              user.rolePermission.map((rolePermission) => [
                rolePermission.actionid,
                rolePermission.user_action,
              ])
            );

            dispatchReducer({
              type: "SET_CURRENT_ACCOUNT",
              payload: { account },
            });
            dispatchReducer({
              type: "RD_SET_ENUMS",
              payload: {
                enums: enums.data,
              },
            });

            const defaultBot =
              account.bots.find((bot) => bot.bot_default === true) ||
              account.bots[0];

            dispatchReducer({
              type: "RD_SET_BOT_IN_ACTION",
              payload: {
                botInAction: defaultBot.bot_id,
                account,
                clientId: account.client_id,
                event: "applicationOnLoad",
              },
            });

            // Set prefered language
            dispatchReducer({
              type: "SET_LANGUAGE",
              payload: {
                language: localStorage.getItem("selectedLanguage") ?? "EN",
              },
            });

            user.rolePermission.forEach((permission) => {
              dispatchReducer({
                type: "UPDATE_CURRENT_USER_PERMISSION",
                permission: {
                  [applicationPermissions[permission.actionid]]: true,
                },
              });
            });

            dispatchReducer({
              type: "SET_LALAMOVE_CONFIGURATION",
              payload: {
                lalamoveConfiguration: account?.delivery_services?.lalamove,
              },
            });

            user.userID &&
              account.live_agent.live_agent_pubsub_hubname &&
              (await callApi({
                urls: [
                  apiEndPoints.GENERATE_PUB_SUB_TOKEN_URL.concat(
                    "?liveAgentHubName=",
                    account.live_agent.live_agent_pubsub_hubname,
                    "&userId=",
                    user.userID
                  ),
                ],
                options: {
                  method: "GET",
                },
                onSuccess: ([pubSubTokenUrl]) => {
                  dispatchReducer({
                    type: "RD_SET_PUBSUB_TOKEN_URL",
                    payload: { pubSubTokenUrl },
                  });

                  dispatchReducer({
                    type: "SUCCESS_API_TRANSACTION",
                    payload: { component: action.payload.component },
                  });
                },
                onError: (error) => {
                  appInsights.trackException({
                    exception: error,
                    properties: {
                      title: "Load PubSub Token Error",
                      clientId: state.currentAccount.account.client_id,
                      environment: mode,
                    },
                  });
                  dispatchReducer({
                    type: "ERROR_API_TRANSACTION",
                    payload: { component: action.payload.component, error },
                  });
                },
              }));

            await callApi({
              urls: [
                apiEndPoints.CONVERSATIONS.concat(
                  "/conversations/unreadMessagesCount"
                ),
              ],
              options: {
                method: "GET",
              },
              onSuccess: ([unreadMessagesCount]) => {
                dispatchReducer({
                  type: "SET_UNREAD_MESSAGE_COUNT",
                  payload: { unreadMessagesCount },
                });

                dispatchReducer({
                  type: "SUCCESS_API_TRANSACTION",
                  payload: { component: action.payload.component },
                });
              },
              onError: (error) => {
                dispatchReducer({
                  type: "ERROR_API_TRANSACTION",
                  payload: { component: action.payload.component, error },
                });
              },
            });
          },

          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Application Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      ////*******************************************////
      ////*************** UPDATE ENUMS **************////
      ////*******************************************////

      //Add multiple enums
      case "MWD_ADD_MULTIPLE_ENUMS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.ENUMS],
          options: {
            method: "POST",
            body: action.payload.enumValues,
          },
          onSuccess: ([enums]) => {
            dispatchReducer({
              type: "RD_SET_ENUMS",
              payload: {
                enums: enums.data,
              },
            });
            openNotificationWithIcon(
              "success",
              "Response field updated successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Response Settings Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
