import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const Logout = () => {
  const { instance } = useMsal();

  useEffect(() => {
    sessionStorage.removeItem("selectedMenu");
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }, [instance]);
  return <></>;
};

export default Logout;
