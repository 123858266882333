import { useState } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Space, Tabs, Row, Col, Layout } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import BroadcastManagerTable from "./BroadcastManagerTable";
import BroadcastManagerHeader from "./BroadcastManagerHeader";
import BroadcastManagerFormPanel from "./BroadcastManagerFormPanel";
import BroadcastPricingCalculator from "./BroadcastPricingCalculator";
import "./BroadcastManager.less";
import useConnectMiddleware from "../../connect/connectMiddleware";
import {
  FullPageLoader,
  TabLoader,
} from "../../shared-components/LoadingIndicator";
import AccessDenied from "../../shared-components/AccessDenied";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";
import GetStarted from "../../shared-components/GetStarted";

const BroadcastManager = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [showBroadcastScreen, setShowBroadcastScreen] = useState(false);

  useConnectMiddleware(
    () => {
      if (
        state.currentAccount.account.modules.broadcast.enabled &&
        state.currentUser.permission.BROADCAST_VIEW
      ) {
        dispatchReducer({ type: "CLEAR_BROADCASTS" });
        dispatchMiddleware({
          type: "LOAD_BROADCASTS_DATA",
          payload: { component: "broadcast-manager-table", pageNumber: 1 },
        });
        dispatchMiddleware({
          type: "MWD_LOAD_BROADCAST_SETTINGS",
          payload: {
            component: "broadcast-settings",
          },
        });
      }
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return (
    <>
      {!state.currentAccount.account.modules.broadcast.enabled ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModuleNotSubscribed module="broadcast" />
        </Layout.Content>
      ) : !state.currentUser.permission.BROADCAST_VIEW ? (
        <AccessDenied />
      ) : state.broadcastManager.broadcasts &&
        state.broadcastManager.broadcasts.length === 0 &&
        !showBroadcastScreen ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GetStarted
            source="broadcasts"
            setShowBroadcastScreen={setShowBroadcastScreen}
          />
        </Layout.Content>
      ) : state.broadcastManager.broadcasts || showBroadcastScreen ? (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space>
                  <NotificationOutlined />
                  Broadcasts
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <BroadcastManagerHeader />
                  </Col>

                  <Col span={24}>
                    <BroadcastManagerTable />
                  </Col>
                  <BroadcastManagerFormPanel />
                  <BroadcastPricingCalculator />
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            <TabLoader
              component="broadcast-manager-table"
              loadingMessage="Synchronizing broadcasts..."
              errorMessage="Error synchronizing broadcasts"
            />
          }
        />
      ) : (
        <FullPageLoader
          component="broadcast-manager-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default BroadcastManager;
