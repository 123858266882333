import {
  Row,
  Col,
  Card,
  Space,
  Typography,
  Collapse,
  Result,
  Image,
  Button,
  Layout,
} from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { channels } from "../../constants/enums";
import BotSettingsMenu from "./BotSettingsMenu";

const { Text } = Typography;
const { Panel } = Collapse;

const BotGBM = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {botToBeEdited.bot_channel_info.GBM ? (
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Card type="inner">
                  <Row>
                    <Col span={24}>
                      <Text strong>Agent</Text>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">
                        {botToBeEdited.bot_channel_info.GBM.gbm_agent
                          ? botToBeEdited.bot_channel_info.GBM.gbm_agent
                          : "-"}
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card type="inner">
                  <Space
                    direction="vertical"
                    size={16}
                    style={{ width: "100%" }}
                  >
                    <Text strong>Locale Entry Points</Text>
                    {botToBeEdited.bot_channel_info.GBM.entry_points.locale
                      .length ? (
                      <Collapse accordion defaultActiveKey={[0]}>
                        {botToBeEdited.bot_channel_info.GBM.entry_points.locale.map(
                          (localeInfo, index) => {
                            return (
                              <Panel
                                header={"Location - " + (index + 1)}
                                key={index}
                              >
                                <Row gutter={[0, 24]}>
                                  <Row>
                                    <Col span={24}>
                                      <Text strong>Address</Text>
                                    </Col>
                                    <Col span={24}>
                                      <Text type="secondary">
                                        {localeInfo.address
                                          ? localeInfo.address
                                          : "-"}
                                      </Text>
                                    </Col>
                                  </Row>
                                  <Col span={24}>
                                    <Row>
                                      <Col span={24}>
                                        <Text strong>Location Id</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text type="secondary">
                                          {localeInfo.location_id
                                            ? localeInfo.location_id
                                            : "-"}
                                        </Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={24}>
                                    {" "}
                                    <Row>
                                      <Col span={24}>
                                        <Text strong>Place Id</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text type="secondary">
                                          {localeInfo.place_id
                                            ? localeInfo.place_id
                                            : "-"}
                                        </Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={24}>
                                    {" "}
                                    <Row>
                                      <Col span={24}>
                                        <Text strong>Status</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text type="secondary">
                                          {localeInfo.status
                                            ? localeInfo.status
                                            : "-"}
                                        </Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Panel>
                            );
                          }
                        )}
                      </Collapse>
                    ) : (
                      <Text>Entry points not defined</Text>
                    )}
                  </Space>
                </Card>
              </Col>
              <Col span={12}>
                <Card type="inner">
                  <Space direction="vertical" size={16}>
                    <Text strong>Non-locale Entry Points</Text>
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Text strong>Domain</Text>
                          </Col>
                          <Col span={24}>
                            <Text type="secondary">
                              {botToBeEdited.bot_channel_info.GBM.entry_points
                                .non_locale.domain
                                ? botToBeEdited.bot_channel_info.GBM
                                    .entry_points.non_locale.domain
                                : "-"}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Text strong>Phone Number</Text>
                          </Col>
                          <Col span={24}>
                            <Text type="secondary">
                              {botToBeEdited.bot_channel_info.GBM.entry_points
                                .non_locale.phone_number
                                ? botToBeEdited.bot_channel_info.GBM
                                    .entry_points.non_locale.phone_number
                                : "-"}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Text strong>Region</Text>
                          </Col>
                          <Col span={24}>
                            <Text type="secondary">
                              {botToBeEdited.bot_channel_info.GBM.entry_points
                                .non_locale.region
                                ? botToBeEdited.bot_channel_info.GBM
                                    .entry_points.non_locale.region
                                : "-"}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Space>
                </Card>
              </Col>
            </Row>
          ) : (
            <Result
              icon={
                <Image src={channels["GBM"].logo} preview={false} width={75} />
              }
              title={channels["GBM"].displayName}
              style={{ width: 700 }}
              extra={
                <Space direction="vertical" size={20}>
                  <Text type="secondary">
                    Business Messages is a mobile conversational channel that
                    combines entry points on Google Maps, Search, and brand
                    websites to create rich, asynchronous messaging experiences
                    that delight customers and drive business results.
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_SEND_REQUEST",
                        payload: {
                          channel: "GBM",
                        },
                      });
                    }}
                  >
                    Request to enable {channels["GBM"].displayName}
                  </Button>
                </Space>
              }
            />
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotGBM;
