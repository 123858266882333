import { useEffect } from "react";
import { Drawer, Layout, Empty } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import ConversationHeader from "../conversations-v2/ConversationHeader";
import ConversationTranscripts from "../conversations-v2/ConversationTranscripts";
import ConversationFooter from "../conversations-v2/ConversationFooter";
import ConversationInfoPanel from "../conversations-v2/ConversationInfoPanel";
import ConversationNotesInfoPanel from "../conversations-v2/ConversationNotesInfoPanel";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import { useWindowSize } from "../../shared-components/PageReSize";

const ClientResponsesViewConversationPanel = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();

  useEffect(() => {
    if (state.conversations.currentConversation.unreadMessagesCount) {
      setTimeout(() => {
        dispatchMiddleware({
          type: "CLEAR_UNREAD_MESSAGES_COUNT",
          payload: {
            contactId: state.conversations.currentConversation._id,
            unreadMessagesCount:
              state.conversations.currentConversation.unreadMessagesCount,
            component:
              "conversations-contact-" +
              state.conversations.currentConversation._id +
              "-list",
          },
        });
      }, 3000);
    }
  }, [state.conversations.currentConversation.unreadMessagesCount]);

  return (
    <Drawer
      title="Conversation details"
      placement="right"
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "client-responses-view-conversation-info-panel",
          },
        });

        // Clear the conversation filter
        dispatchReducer({
          type: "RD_SET_CONVERSATION_FILTER",
          payload: {
            conversationFilter: false,
          },
        });

        // Clear the conversations with transcripts
        dispatchReducer({
          type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
        });

        // Clear the current conversation
        dispatchReducer({
          type: "RD_CLEAR_CURRENT_CONVERSATION",
        });
      }}
      open={
        state.infoPanel["client-responses-view-conversation-info-panel"] &&
        state.infoPanel["client-responses-view-conversation-info-panel"].visible
      }
      width={800}
      destroyOnClose={true}
      className="client-responses-view-conversation-info-panel"
    >
      {state.apiCallStatus?.["conversations-layout"]?.loading ||
      state.apiCallStatus?.["conversations-layout"]?.error ? (
        <FullPageLoader
          component="conversations-layout"
          loadingMessage="Loading... Please wait..."
        />
      ) : state.conversations.contactsWithTranscripts ? (
        <Layout.Content
          style={{
            height: height - 70, //To fix the grid height
            display: "grid",
            gridTemplateColumns:
              state.infoPanel["conversation-info-panel"]?.visible === true ||
              state.infoPanel["conversation-notes-info-panel"]?.visible === true
                ? "4.5fr 2.5fr"
                : "7fr",
            border: "1px solid #E9E9E9",
          }}
        >
          <Layout.Content
            style={{
              height: "100%",
              borderRight: "1px solid #E9E9E9",
            }}
          >
            <ConversationHeader />
            <ConversationTranscripts />
            <ConversationFooter />
          </Layout.Content>
          {state.infoPanel["conversation-info-panel"]?.visible === true && (
            <Layout.Content
              style={{
                height: "100%",
                borderRight: "1px solid #E9E9E9",
              }}
            >
              <ConversationInfoPanel />
            </Layout.Content>
          )}

          {state.infoPanel["conversation-notes-info-panel"]?.visible ===
            true && (
            <Layout.Content
              style={{
                height: "100%",
                borderRight: "1px solid #E9E9E9",
              }}
            >
              <ConversationNotesInfoPanel />
            </Layout.Content>
          )}
        </Layout.Content>
      ) : (
        <Empty
          description="Conversation not found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{
            marginTop: "10%",
          }}
        />
      )}
    </Drawer>
  );
};

export default ClientResponsesViewConversationPanel;
