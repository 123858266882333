import React, { useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Space,
  Row,
  Col,
  Image,
  Typography,
  List,
  Tooltip,
  Avatar,
  Popconfirm,
  Form,
  Input,
  Button,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  calculateDateTimeToBeDisplayed,
  extractInitialsFromName,
} from "../../utils/utility";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  TabLoader,
  ComponentLoader,
  FullPageLoader,
} from "../../shared-components/LoadingIndicator";
import { Translation } from "../../translation/translation";
import { useWindowSize } from "../../shared-components/PageReSize";
// <-------- Based on usage we can implement this later -------->
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

const { Text, Title } = Typography;
const { TextArea } = Input;

const ConversationNotesInfoPanel = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();
  const translation = Translation().conversations.footer.notes;
  const [form] = Form.useForm();

  const agentNotes =
    state.conversations.currentConversation.agent_notes?.data || [];

  const handleFormSubmit = (values) => {
    dispatchMiddleware({
      type: "ADD_EDIT_LIVE_AGENT_NOTE",
      payload: {
        note: {
          title: values.title,
          content: values.content,
          liveAgentID: state.currentUser.user.userID,
        },
        component: "live-agent-notes-form",
      },
    });
  };

  // const textEditorConfiguration = {
  //   toolbar: [
  //     ["bold", "italic", "underline", "strike"], // toggled buttons
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     [{ indent: "-1" }, { indent: "+1" }],
  //   ],
  // };

  useEffect(() => {
    dispatchReducer({
      type: "CLOSE_INFO_PANEL",
      payload: {
        component: "conversation-note-text-editor-info-panel",
      },
    });
    state.infoPanel["conversation-note-text-editor-info-panel"]?.visible &&
      form.resetFields();
  }, [state.conversations.currentConversation.agent_notes]);

  return (
    <div
      style={{
        backgroundColor: "#F0F2F5",
        height: "100%",
      }}
    >
      <Space
        size="large"
        style={{
          padding: "16px 20px",
        }}
      >
        <CloseOutlined
          style={{ fontSize: "18px" }}
          onClick={() => {
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "conversation-notes-info-panel",
              },
            });
          }}
        />
        <Text strong style={{ fontSize: "16px" }}>
          Notes
        </Text>
      </Space>

      {state.conversations.currentConversation.agent_notes?.totalRecords > 0 ||
      state.infoPanel["conversation-note-text-editor-info-panel"]?.visible ? (
        <Row
          style={{
            padding: "0px 10px",
          }}
        >
          {/* Search Notes */}
          {!state.infoPanel["conversation-note-text-editor-info-panel"]
            ?.visible && (
            <Col span={24}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search notes"
                allowClear={{
                  clearIcon: (
                    <CloseOutlined
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_SEARCH_TEXT",
                          payload: {
                            component: "live-agent-notes-search-text-box",
                            searchText: {
                              ...state.filters.searchText?.[
                                "live-agent-notes-search-text-box"
                              ],
                              [state.conversations.currentConversation._id]: "",
                            },
                          },
                        });

                        dispatchMiddleware({
                          type: "LOAD_LIVE_AGENT_NOTES",
                          payload: {
                            component: "live-agent-notes-search-text-box",
                            searchText: "",
                            actionType: "search",
                            pageNumber: 1,
                          },
                        });
                      }}
                    />
                  ),
                }}
                value={
                  state.filters.searchText?.[
                    "live-agent-notes-search-text-box"
                  ]?.[state.conversations.currentConversation._id]
                }
                onChange={(e) => {
                  const currentValue = e.target.value;

                  dispatchReducer({
                    type: "RD_SET_SEARCH_TEXT",
                    payload: {
                      component: "live-agent-notes-search-text-box",
                      searchText: {
                        ...state.filters.searchText?.[
                          "live-agent-notes-search-text-box"
                        ],
                        [state.conversations.currentConversation._id]:
                          currentValue,
                      },
                    },
                  });
                }}
                onPressEnter={(e) => {
                  dispatchMiddleware({
                    type: "LOAD_LIVE_AGENT_NOTES",
                    payload: {
                      component: "live-agent-notes-search-text-box",
                      searchText: e.target.value,
                      actionType: "search",
                      pageNumber: 1,
                    },
                  });
                }}
                style={{
                  borderRadius: "5px",
                }}
              />
            </Col>
          )}

          {/* Notes List */}
          {!state.infoPanel["conversation-note-text-editor-info-panel"]
            ?.visible && (
            <Col span={24}>
              <div
                id="scrollableDivForNotesList"
                style={{
                  height: height - 200,
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={agentNotes?.length}
                  next={() => {
                    dispatchMiddleware({
                      type: "LOAD_LIVE_AGENT_NOTES",
                      payload: {
                        pageNumber:
                          Math.floor(
                            state.conversations.currentConversation.agent_notes
                              ?.data?.length / Math.round(height / 89)
                          ) + 1,
                        pageSize: Math.round(height / 60),
                        searchText:
                          state.filters.searchText?.[
                            "live-agent-notes-search-text-box"
                          ]?.[state.conversations.currentConversation._id],
                        component: "live-agent-notes-list",
                      },
                    });
                  }}
                  hasMore={
                    agentNotes?.length <
                    state.conversations.currentConversation.agent_notes
                      ?.totalRecords
                  }
                  loader={
                    <TabLoader
                      component="live-agent-notes-list"
                      loadingMessage="Loading notes..."
                      errorMessage="Error loading contacts"
                    />
                  }
                  scrollableTarget="scrollableDivForNotesList"
                >
                  <List
                    dataSource={agentNotes}
                    size="small"
                    loading={
                      state.apiCallStatus?.["live-agent-notes-search-text-box"]
                        ?.loading
                    }
                    style={{
                      height: "100%",
                      marginTop: 10,
                    }}
                    renderItem={(note, index) => (
                      <List.Item
                        key={index}
                        style={{
                          padding: "5px 5px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          margin: "10px 0px",
                        }}
                      >
                        <Row
                          style={{
                            padding: "5px 8px",
                            width: "100%",
                          }}
                        >
                          <Col span={22}>
                            <Text
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#000",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatchReducer({
                                  type: "OPEN_INFO_PANEL",
                                  payload: {
                                    component:
                                      "conversation-note-text-editor-info-panel",
                                  },
                                });
                                form.setFieldsValue({
                                  title: note.title,
                                  content: note.content,
                                  liveAgentID: note.liveAgentID,
                                });

                                dispatchReducer({
                                  type: "RD_SET_ACTION_ROW_KEY",
                                  payload: {
                                    component: "live-agent-notes-form",
                                    actionRowKey: note._id,
                                  },
                                });

                                dispatchReducer({
                                  type: "SET_FORM_MODE",
                                  payload: {
                                    component: "live-agent-notes-form",
                                    mode: "edit",
                                    title: "",
                                  },
                                });
                              }}
                            >
                              {note.title}
                            </Text>
                          </Col>

                          <Col
                            span={2}
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {state.apiCallStatus?.[
                              "live-agent-note-" + note._id + "-delete"
                            ]?.loading ? (
                              <ComponentLoader
                                component={
                                  "live-agent-note-" + note._id + "-delete"
                                }
                                loadingMessage=""
                                errorMessage=""
                              />
                            ) : state.currentUser.permission
                                .CONVERSATIONS_ANOTHER_AGENT_NOTE_DELETE ||
                              state.currentUser.user.userID ===
                                note.liveAgentID ? (
                              <Popconfirm
                                title="Are you sure you want to delete this note?"
                                placement="topRight"
                                icon={
                                  <AiOutlineExclamationCircle
                                    size={18}
                                    style={{
                                      color: "#faad14",
                                      marginTop: "1px",
                                    }}
                                  />
                                }
                                onConfirm={() => {
                                  dispatchMiddleware({
                                    type: "DELETE_LIVE_AGENT_NOTE",
                                    payload: {
                                      noteId: note._id,
                                      component:
                                        "live-agent-note-" +
                                        note._id +
                                        "-delete",
                                    },
                                  });
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteOutlined style={{ color: "#F05742" }} />
                              </Popconfirm>
                            ) : (
                              <Tooltip
                                title={translation.deleteNote.warningText}
                                placement="topRight"
                              >
                                <DeleteOutlined
                                  style={{
                                    color: "#C0C0C0",
                                    cursor: "not-allowed",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Col>

                          <Col span={24}>
                            <Text
                              type="secondary"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {
                                // show 50 characters of content
                                note.content?.length > 50
                                  ? note.content.substring(0, 50) + "..."
                                  : note.content
                              }
                            </Text>
                          </Col>

                          <Col span={22}>
                            <Text
                              style={{
                                fontSize: "11px",
                                color: "#505050",
                              }}
                            >
                              {calculateDateTimeToBeDisplayed(
                                note.timestamp,
                                state.currentUser.user.timeZone
                              )}
                            </Text>
                          </Col>

                          <Col
                            span={2}
                            style={{
                              marginLeft: "auto",
                            }}
                          >
                            <Tooltip
                              title={
                                state.accountUsers.users.find(
                                  (user) => user.userID === note.liveAgentID
                                )?.displayName || "Unknown"
                              }
                              placement="topRight"
                            >
                              <Avatar
                                size="small"
                                style={{
                                  backgroundColor:
                                    state.accountUsers.users.find(
                                      (user) => user.userID === note.liveAgentID
                                    )?.colorCode || "#000",
                                  textAlign: "center",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  lineHeight: "24px", // Adjust this value to vertically center the text
                                  display: "inline-block", // Display as inline-block to center vertically
                                  verticalAlign: "middle",
                                }}
                              >
                                {extractInitialsFromName(
                                  state.accountUsers.users.find(
                                    (user) => user.userID === note.liveAgentID
                                  )?.displayName || "Unknown"
                                )}
                              </Avatar>
                            </Tooltip>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </InfiniteScroll>
              </div>

              <Button
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
                type="primary"
                shape="circle"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => {
                  dispatchReducer({
                    type: "OPEN_INFO_PANEL",
                    payload: {
                      component: "conversation-note-text-editor-info-panel",
                    },
                  });

                  dispatchReducer({
                    type: "SET_FORM_MODE",
                    payload: {
                      component: "live-agent-notes-form",
                      mode: "add",
                      title: "",
                    },
                  });
                }}
              />
            </Col>
          )}

          {/* Notes editor */}
          {state.infoPanel["conversation-note-text-editor-info-panel"]
            ?.visible && (
            <>
              {state.apiCallStatus?.["live-agent-notes-form"]?.loading ? (
                <FullPageLoader
                  component="live-agent-notes-form"
                  loadingMessage="Submitting... Please wait..."
                />
              ) : (
                <Form
                  layout="vertical"
                  autoComplete="off"
                  onFinish={handleFormSubmit}
                  form={form}
                  style={{
                    width: "100%",
                    marginBottom: 10,
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 35,
                    }}
                  >
                    <Form.Item>
                      <Popconfirm
                        title="Confirm going back? Unsaved changes will be lost."
                        icon={
                          <AiOutlineExclamationCircle
                            size={18}
                            style={{
                              color: "#faad14",
                              marginTop: "1px",
                            }}
                          />
                        }
                        onConfirm={() => {
                          dispatchReducer({
                            type: "CLOSE_INFO_PANEL",
                            payload: {
                              component:
                                "conversation-note-text-editor-info-panel",
                            },
                          });
                          form.resetFields();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button size="small">Back</Button>
                      </Popconfirm>
                    </Form.Item>

                    {(state.form["live-agent-notes-form"].mode === "add" ||
                      (state.form["live-agent-notes-form"].mode === "edit" &&
                        (state.currentUser.permission
                          .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                          state.currentUser.user.userID ===
                            form.getFieldValue("liveAgentID")))) && (
                      <Form.Item>
                        <Button type="primary" size="small" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item>
                    )}
                  </Row>

                  <Tooltip
                    title={
                      state.form["live-agent-notes-form"].mode === "add" ||
                      (state.form["live-agent-notes-form"].mode === "edit" &&
                        (state.currentUser.permission
                          .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                          state.currentUser.user.userID ===
                            form.getFieldValue("liveAgentID")))
                        ? ""
                        : translation.editNote.warningText
                    }
                  >
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please input your title!",
                        },
                        {
                          max: 50,
                          message:
                            "Title should not be more than 50 characters!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Title"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #6C6B6B",
                          borderRadius: "4px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        autoFocus
                        disabled={
                          !(
                            state.form["live-agent-notes-form"].mode ===
                              "add" ||
                            (state.form["live-agent-notes-form"].mode ===
                              "edit" &&
                              (state.currentUser.permission
                                .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                                state.currentUser.user.userID ===
                                  form.getFieldValue("liveAgentID")))
                          )
                        }
                      />
                    </Form.Item>
                  </Tooltip>

                  <Tooltip
                    title={
                      state.form["live-agent-notes-form"].mode === "add" ||
                      (state.form["live-agent-notes-form"].mode === "edit" &&
                        (state.currentUser.permission
                          .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                          state.currentUser.user.userID ===
                            form.getFieldValue("liveAgentID")))
                        ? ""
                        : translation.editNote.warningText
                    }
                  >
                    <Form.Item
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Please input your content!",
                        },
                        {
                          max: 1024,
                          message:
                            "Content should not be more than 1024 characters!",
                        },
                      ]}
                    >
                      <TextArea
                        size="large"
                        placeholder="Content"
                        style={{
                          border: "none",
                          borderRadius: "4px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                        autoSize={{ minRows: 4 }}
                        showCount
                        maxLength={1024}
                        disabled={
                          !(
                            state.form["live-agent-notes-form"].mode ===
                              "add" ||
                            (state.form["live-agent-notes-form"].mode ===
                              "edit" &&
                              (state.currentUser.permission
                                .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                                state.currentUser.user.userID ===
                                  form.getFieldValue("liveAgentID")))
                          )
                        }
                      />

                      {/* <------- Based on usage we can implement this later --------> */}
                      {/* <ReactQuill
                        theme="snow"
                        modules={textEditorConfiguration}
                        placeholder="Content"
                        style={{
                          border: "none",
                          borderRadius: "4px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          backgroundColor: !(
                            state.form["live-agent-notes-form"].mode ===
                              "add" ||
                            (state.form["live-agent-notes-form"].mode ===
                              "edit" &&
                              (state.currentUser.permission
                                .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                                state.currentUser.user.userID ===
                                  form.getFieldValue("liveAgentID")))
                          )
                            ? "#f5f5f5"
                            : "#ffffff",
                        }}
                        readOnly={
                          !(
                            state.form["live-agent-notes-form"].mode ===
                              "add" ||
                            (state.form["live-agent-notes-form"].mode ===
                              "edit" &&
                              (state.currentUser.permission
                                .CONVERSATIONS_ANOTHER_AGENT_NOTE_EDIT ||
                                state.currentUser.user.userID ===
                                  form.getFieldValue("liveAgentID")))
                          )
                        }
                        // onChange={(content, delta, source, editor) => {
                        //   setContentCount(editor.getLength() - 1);
                        // }}
                      /> */}
                    </Form.Item>
                  </Tooltip>
                </Form>
              )}
            </>
          )}
        </Row>
      ) : (
        // Notes not found
        <Row
          style={{
            textAlign: "center",
            padding: "0 20px",
          }}
          gutter={[10, 10]}
        >
          <Col span={24}>
            <Title level={5} style={{ marginBottom: "20px" }}>
              Effective note-taking
            </Title>
          </Col>

          <Col span={24}>
            <Image
              src="/images/module-not-found/notes.jpg"
              preview={false}
              width="60%"
              height="auto"
              style={{
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              textAlign: "left",
            }}
          >
            <Text style={{ fontSize: "14px", marginTop: "20px" }} strong>
              Taking effective notes during a conversation is crucial for:
            </Text>
          </Col>

          <Col span={24}>
            <ul
              style={{
                textAlign: "left",
                marginTop: "10px",
                paddingLeft: "20px",
              }}
            >
              <li>
                <Text>Remembering key points and details.</Text>
              </li>
              <li>
                <Text>Documenting discussions for future reference.</Text>
              </li>
              <li>
                <Text>Organizing thoughts and understanding topics.</Text>
              </li>
            </ul>
          </Col>

          <Col
            span={24}
            style={{
              textAlign: "left",
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                dispatchReducer({
                  type: "OPEN_INFO_PANEL",
                  payload: {
                    component: "conversation-note-text-editor-info-panel",
                  },
                });

                dispatchReducer({
                  type: "SET_FORM_MODE",
                  payload: {
                    component: "live-agent-notes-form",
                    mode: "add",
                    title: "",
                  },
                });
              }}
            >
              Add Note
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ConversationNotesInfoPanel;
