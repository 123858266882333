import { Col, Row, Space, Typography, Button, Image } from "antd";

const { Text } = Typography;

const ConversationWEB = ({ transcript }) => {
  return (
    <Space size={10} style={{ alignItems: "start" }}>
      {transcript.content && (
        <Row>
          <Col span={24}>
            <Space
              size={10}
              direction="vertical"
              style={{
                width: 300,
                border: "1px solid #C0C0C0",
                padding: 10,
                borderRadius: 10,
              }}
            >
              {transcript.type === "application/vnd.microsoft.card.hero" && (
                <Row gutter={[0, 14]}>
                  {transcript.content.images && (
                    <Col span={24}>
                      {transcript.content.images.map((image, index) => (
                        <Image
                          key={index}
                          style={{ textAlign: "right" }}
                          preview={false}
                          src={image.url}
                        />
                      ))}
                    </Col>
                  )}

                  {transcript.content.title && (
                    <Col span={24}>
                      <Col>
                        <Text strong>{transcript.content.title}</Text>
                      </Col>
                    </Col>
                  )}

                  {transcript.content.text && (
                    <Col span={24}>
                      <Col>
                        <Text>{transcript.content.text}</Text>
                      </Col>
                    </Col>
                  )}

                  {transcript.content.buttons && (
                    <Col span={24}>
                      <Space
                        style={{
                          display: "flex",
                          flexDirection:
                            transcript.content.buttons.length > 2
                              ? "column"
                              : "row",
                          alignItems:
                            transcript.content.buttons.length > 2
                              ? "flex-start"
                              : "center",
                        }}
                      >
                        {transcript.content.buttons.map((action, index) => (
                          <Button key={index}>{action.title}</Button>
                        ))}
                      </Space>
                    </Col>
                  )}
                </Row>
              )}

              {transcript.type ===
                "application/vnd.microsoft.card.thumbnail" && (
                <>
                  <Row>
                    <Col span={16}>
                      <Col>
                        <Text strong>{transcript.content.title}</Text>
                      </Col>
                      <Col>
                        <Text type="secondary" ellipsis>
                          {transcript.content.subtitle}
                        </Text>
                      </Col>
                    </Col>

                    <Col span={8}>
                      {transcript.content.images &&
                        transcript.content.images.map((image) => (
                          <Image
                            style={{ textAlign: "right" }}
                            preview={false}
                            src={image.url}
                          />
                        ))}
                    </Col>

                    <Col span={24}>
                      <Col>
                        <Text>{transcript.content.text}</Text>
                      </Col>
                      <Col style={{ marginTop: "5px" }}>
                        {transcript.content.buttons.map((action) => (
                          <Button>{action.title}</Button>
                        ))}
                      </Col>
                    </Col>
                  </Row>
                </>
              )}

              {transcript.type ===
                "application/vnd.microsoft.card.adaptive" && (
                <>
                  {transcript.content.body &&
                    transcript.content.body.map((body) => (
                      <Text>{body.text}</Text>
                    ))}
                  {transcript.content.body &&
                    transcript.content.body.map(
                      (body) =>
                        body.choices &&
                        body.choices.map((choice) => (
                          <Text style={{ borderRadius: 5 }}>
                            {choice.value}
                          </Text>
                        ))
                    )}
                  {transcript.content.actions &&
                    transcript.content.actions.map((action) => {
                      return (
                        <>
                          <Button style={{ borderRadius: 5 }}>
                            {action.title}
                          </Button>
                        </>
                      );
                    })}
                </>
              )}
            </Space>
          </Col>
        </Row>
      )}
    </Space>
  );
};

export default ConversationWEB;
