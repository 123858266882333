import {
  Row,
  Col,
  Space,
  Typography,
  Result,
  Image,
  Button,
  Layout,
} from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { channels } from "../../constants/enums";
import BotSettingsMenu from "./BotSettingsMenu";

const { Text } = Typography;

const BotAMB = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {botToBeEdited.bot_channel_info.AMB ? (
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Text strong>Brand</Text>
                  </Col>
                  <Col span={24}>
                    <Text type="secondary">
                      {botToBeEdited.bot_channel_info.AMB.amb_brand
                        ? botToBeEdited.bot_channel_info.AMB.amb_brand
                        : "-"}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Result
              icon={
                <Image src={channels["AMB"].logo} preview={false} width={75} />
              }
              title={channels["AMB"].displayName}
              style={{ width: 700 }}
              extra={
                <Space direction="vertical" size={20}>
                  <Text type="secondary">
                    Now your customers can ask for information, schedule
                    appointments, and even make purchases right in Messages on
                    your iPhone or iPad. Which makes your customer connecting
                    with your business as easy as texting their favorite people.
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_SEND_REQUEST",
                        payload: {
                          channel: "AMB",
                        },
                      });
                    }}
                  >
                    Request to enable {channels["AMB"].displayName}
                  </Button>
                </Space>
              }
            />
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotAMB;
