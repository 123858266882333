import { useState } from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Result,
  Image,
  Button,
  Layout,
} from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { channels } from "../../../constants/enums";
import BotSettingsMenu from "../BotSettingsMenu";
import BotWEBCustomize from "./BotWEBChatForm";
import { mode } from "../../../constants/env";

const { Text } = Typography;

const BotWEB = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [isCreatingTemplate, setisCreatingTemplate] = useState(false);

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "25px",
            width: "100%",
          }}
        >
          {botToBeEdited.bot_channel_info.WEB ? (
            ["DEV", "STAGING"].includes(mode) ? (
              <>
                {isCreatingTemplate ? (
                  <Row align={"middle"}>
                    <Col span={24}>
                      <BotWEBCustomize />
                    </Col>
                  </Row>
                ) : (
                  <Row
                    gutter={[0, 24]}
                    justify={"center"}
                    align={"middle"}
                    style={{ height: "100%" }}
                  >
                    <Col span={12}>
                      <Space
                        direction="vertical"
                        size={20}
                        align={"center"}
                        justify={"center"}
                      >
                        <Image
                          src="/images/webchat-customize.png"
                          preview={false}
                          width={300}
                        />
                        <Text type="secondary">
                          Never miss a beat in customer service with iNextLabs’
                          voicebot. Our AI-driven voicebot ensures your
                          customers get timely and precise answers, enhancing
                          satisfaction and trust. Easily integrated across
                          various channels, from sleek websites to mobile apps,
                          our voicebot provides round-the-clock support without
                          the complexity.
                        </Text>
                        <Button
                          type="primary"
                          onClick={() => {
                            setisCreatingTemplate(true);
                          }}
                        >
                          Create {channels["WEB"].displayName}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <Row gutter={[0, 24]}>
                <Col span={24}>Direct Line Details goes here</Col>
              </Row>
            )
          ) : (
            <Result
              icon={
                <Image src={channels["WEB"].logo} preview={false} width={75} />
              }
              style={{ width: 700, display: "block", margin: "auto" }}
              title={channels["WEB"].displayName}
              extra={
                <Space direction="vertical" size={20}>
                  <Text type="secondary">
                    Now your customers can ask for information, schedule
                    appointments, and even make purchases right in Messages on
                    your iPhone or iPad. Which makes your customer connecting
                    with your business as easy as texting their favorite people.
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_SEND_REQUEST",
                        payload: {
                          channel: "WEB",
                        },
                      });
                    }}
                  >
                    Request to enable {channels["WEB"].displayName}
                  </Button>
                </Space>
              }
            />
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotWEB;
