import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Typography, Button, Modal, Table } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";

const { Text } = Typography;
const { confirm } = Modal;

const ContactsFromClientResponses = ({ handleGetParsedData }) => {
  const navigate = useNavigate();
  const [state, dispatchReducer] = useGlobalContext();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const handleGetContactsData = () => {
    const columns = Object.keys(
      state.broadcastManager.multipleContactsToPopulate[0]
    ).map((column) => {
      const columnConfig = {
        title: column,
        key: "{" + column + "}",
        dataIndex: column,
      };

      // Sorting for customer name only
      if (column === "Customer Name") {
        columnConfig.sorter = (a, b) => {
          const valueA = a[column] || "";
          const valueB = b[column] || "";

          return valueA.localeCompare(valueB);
        };
      }

      return columnConfig;
    });

    const data = state.broadcastManager.multipleContactsToPopulate.reduce(
      (uniqueContacts, contact) => {
        const phoneNumber = contact["WhatsApp Number"];
        if (
          !uniqueContacts.some(
            (contact) => contact["WhatsApp Number"] === phoneNumber
          )
        ) {
          uniqueContacts.push(contact);
        }
        return uniqueContacts;
      },
      []
    );

    setColumns(columns);
    setData(data);

    const keys = Object.keys(data[0]);

    const commaSeparatedString = [
      keys.join(","),
      data.map((row) => keys.map((key) => row[key]).join(",")).join("\n"),
    ].join("\n");

    let csvData = new Blob([commaSeparatedString], { type: "text/csv" });
    const csvFile = new File([csvData], "contacts.csv");

    handleGetParsedData(columns, data, "contacts", csvFile);
  };

  useEffect(() => {
    handleGetContactsData();
  }, []);

  return (
    <>
      <Space
        className="label-file-upload uploadComplete"
        direction="vertical"
        size={10}
      >
        <Text strong>
          {state.broadcastManager.multipleContactsToPopulate.length + " "}
          contact(s) fetched from Responses
        </Text>
        <Text> {data.length + " "} valid contact(s)</Text>
        <Space>
          <Button
            onClick={() => {
              setIsPreviewModalVisible(true);
            }}
          >
            Preview
          </Button>
          <Button
            onClick={() => {
              confirm({
                title: "Are you sure you want to cancel this broadcast?",
                content:
                  "When clicked the OK button, the broadcast will be cancelled and redirect you back to the responses page",
                onOk() {
                  // Navigate to client responses
                  navigate("/client-responses");
                  sessionStorage.setItem("selectedMenu", "/client-responses");
                  setData([]);
                  dispatchReducer({
                    type: "CLEAR_POPULATED_CONTACTS",
                  });
                  dispatchReducer({
                    type: "RD_CLEAR_SELECTED_ROW_KEYS",
                    payload: {
                      component: "client-response-table-selected-row",
                    },
                  });
                  dispatchReducer({
                    type: "CLOSE_FORM_PANEL",
                    payload: {
                      component: "broadcast-form-panel",
                    },
                  });
                },
                onCancel() {},
              });
            }}
          >
            Cancel
          </Button>
        </Space>
      </Space>

      <Modal
        title={"Preview (" + data.length + " Records)"}
        cancelButtonProps={{ style: { display: "none" } }}
        open={isPreviewModalVisible}
        onOk={() => {
          setIsPreviewModalVisible(false);
        }}
        onCancel={() => {
          setIsPreviewModalVisible(false);
        }}
        width="800px"
      >
        <div style={{ maxHeight: 400, overflow: "auto" }}>
          <Table
            dataSource={data}
            columns={columns}
            pagination={{ position: ["none", "none"], defaultPageSize: 0 }}
            scroll={{ y: 240, x: "8vw" }}
            size="small"
            rowKey={Math.random()}
          />
        </div>
      </Modal>
    </>
  );
};

export default ContactsFromClientResponses;
