import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Col, Space, Input, Button, Tooltip } from "antd";
import { appInsights } from "../../../AppInsights";
import { useGlobalContext } from "../../../contexts/globalContext";

const WebhooksHeader = () => {
  const [state, dispatchReducer] = useGlobalContext();
  return (
    <Row>
      <Col span={18}>
        <Space>
          <Input
            disabled={state.webhooks.webhooksList === 0}
            value={
              state.filters.searchText["webhooks-search-text-box"] &&
              state.filters.searchText["webhooks-search-text-box"]
            }
            prefix={<SearchOutlined />}
            placeholder="Search by webhook name"
            style={{ width: 400 }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "webhooks-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
          />
        </Space>
      </Col>

      <Col span={6} style={{ textAlign: "right" }}>
        <Tooltip
          placement="topRight"
          title={
            state.currentUser.permission.WEBHOOK_CREATE
              ? "Add Webhook"
              : "You do not have required permission"
          }
        >
          <Button
            type="primary"
            disabled={!state.currentUser.permission.WEBHOOK_CREATE}
            icon={<PlusOutlined />}
            onClick={() => {
              appInsights.trackEvent({
                name: "Add Webhook",
                properties: {
                  clientId: state.currentAccount.account.client_id,
                },
              });
              dispatchReducer({
                type: "OPEN_FORM_PANEL",
                payload: {
                  component: "webhooks-form-panel",
                  title: "Create Webhook",
                },
              });
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "webhooks-form",
                  mode: "add",
                  title: "Create Webhook",
                },
              });
            }}
          >
            Create Webhook
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default WebhooksHeader;
