import { Button, Col, Input, Row, Tooltip } from "antd";
import { PlusOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Translation } from "../../../translation/translation";

const WhatsAppFlowsHeader = () => {
  const translation = Translation().botKnowledgeArea.whatsappFlows.header;

  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  return (
    <Row>
      <Col span={20}>
        <Input
          style={{ width: 400 }}
          value={
            state.filters.searchText["whatsapp-flows-search-text-box"] &&
            state.filters.searchText["whatsapp-flows-search-text-box"]
          }
          prefix={<SearchOutlined />}
          placeholder={translation.searchWhatsappFlows.placeholder}
          allowClear={{ clearIcon: <CloseOutlined /> }}
          onChange={(e) => {
            const currentValue = e.target.value;
            dispatchReducer({
              type: "RD_SET_SEARCH_TEXT",
              payload: {
                component: "whatsapp-flows-search-text-box",
                searchText: currentValue,
              },
            });
          }}
        />
      </Col>

      <Col span={4} style={{ textAlign: "right" }}>
        <Tooltip
          title={
            !state.currentUser.permission.WHATSAPP_FLOWS_CREATE &&
            translation.noAccess
          }
          placement="topLeft"
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatchReducer({
                type: "OPEN_FORM_PANEL",
                payload: {
                  component: "whatsapp-flows-form-panel",
                  title: translation.createWhatsappFlow.formPanelTitle,
                },
              });
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "whatsapp-flows-form",
                  mode: "add",
                },
              });
              !state.whatsappFlows.whatsappFlowsGallery &&
                dispatchMiddleware({
                  type: "LOAD_WHATSAPP_FLOWS_GALLERY",
                  payload: {
                    component: "whatsapp-flows-gallery",
                  },
                });
            }}
            disabled={!state.currentUser.permission.WHATSAPP_FLOWS_CREATE}
          >
            {translation.createWhatsappFlow.buttonText}
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default WhatsAppFlowsHeader;
