import React from "react";
import { Dropdown, Row, Col, Typography, Space } from "antd";
import {
  QuestionCircleOutlined,
  ApiOutlined,
  ExceptionOutlined,
} from "@ant-design/icons";
import { URIs } from "../../constants/env";

const { Text } = Typography;

const HelpCenter = () => {
  const handleMenuSelection = (action) => {
    switch (action.key) {
      case "help":
        window.open(URIs.HELP_AND_SUPPORT_DOCUMENTATION, "_blank");
        break;

      case "api":
        window.open(URIs.API_DOCUMENTATION, "_blank");
        break;

      default:
    }
  };

  const items = [
    {
      key: "help",
      label: (
        <Row>
          <Col span={3}>
            <Space size={15}>
              <ExceptionOutlined
                style={{
                  fontSize: 20,
                  color: "#F05742",
                  margin: "5px 0px 0px 5px",
                }}
              />
            </Space>
          </Col>
          <Col span={21}>
            <Row>
              <Col span={24}>
                <Text strong>Help & Support Documents</Text>
              </Col>
              <Col span={24}>
                <Text>Have questions? Find answers here.</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      key: "api",
      label: (
        <Row>
          <Col span={3}>
            <Space size={15}>
              <ApiOutlined
                style={{
                  fontSize: 20,
                  color: "#F05742",
                  margin: "5px 0px 0px 5px",
                }}
              />
            </Space>
          </Col>
          <Col span={21}>
            <Row>
              <Col span={24}>
                <Text strong>API Documents</Text>
              </Col>
              <Col span={24}>
                <Text>Developer's resource center</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Dropdown
      style={{ marginTop: 15 }}
      menu={{
        items,
        style: {
          width: 350,
          padding: 15,
          borderRadius: 5,
        },
        onClick: handleMenuSelection,
      }}
    >
      <QuestionCircleOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
    </Dropdown>
  );
};

export default HelpCenter;
