import { IoIosInformationCircleOutline } from "react-icons/io";
import { Layout, Space } from "antd";

const Validation = ({ validationMessage }) => {
  return (
    <Layout.Content
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IoIosInformationCircleOutline size="3em" color="#F05742" />
      {validationMessage.map((message, index) => {
        return (
          <Space style={{ color: "#F05742", marginTop: 10 }} key={index}>
            {message}
          </Space>
        );
      })}
    </Layout.Content>
  );
};

export default Validation;
