import { useGlobalContext } from "../../../contexts/globalContext";
import { Space, Divider, Tag, Tooltip, Image } from "antd";
import { BiConversation } from "react-icons/bi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { DownloadOutlined } from "@ant-design/icons";
import { channels } from "../../../constants/enums";

// const { confirm } = Modal;

const useActivityLogsTableColumns = () => {
  const [state] = useGlobalContext();

  const dataColumns = [
    {
      title: "ACTIVITY DATE",
      dataIndex: "activityDate",
      key: "activityDate",
      ellipsis: true,
      fixed: "left",
      width: 100,
    },
    {
      title: "CHANNEL",
      dataIndex: "channel",
      key: "channel",
      ellipsis: true,
      fixed: "left",
      align: "center",
      width: 75,
      render: (_, record) => {
        return (
          channels[record.channel] && (
            <Image
              src={channels[record.channel].logo}
              width={23}
              preview={false}
            />
          )
        );
      },
    },
    {
      title: "ACTIVITY TYPE",
      dataIndex: "activityType",
      key: "activityType",
      width: 100,
    },
    {
      title: "",
      dataIndex: [
        "activityType",
        "conversationFlow",
        "broadcastTemplate",
        "isPlanned",
        "recipientCount",
      ],
      key: "activityType",
      width: 375,
      render: (_, record) => (
        <Space size={10}>
          {record.activityType === "CONVERSATION" && (
            <Space size={0}>
              <Tag color="blue">Flow: {record.conversationFlow}</Tag>
              {record.isPlanned && <Tag color="blue">Planned</Tag>}
            </Space>
          )}
          {record.activityType === "BROADCAST" && (
            <Space size={0}>
              <Tag color="green">Template: {record.broadcastTemplate}</Tag>
              {record.isPlanned && <Tag color="green">Planned</Tag>}
              <Tag color="green">Recipients: {record.recipientCount}</Tag>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  const actionColumns = [
    {
      title: "ACTION",
      key: "action",
      width: 75,
      align: "left",
      render: (_, record) => {
        return (
          <Space size={24}>
            {record.conversationFlow && (
              <Space size={5}>
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission
                      .BOT_ACTIVITY_LOG_VIEW_CONVERSATION
                      ? "View Conversation"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission
                    .BOT_ACTIVITY_LOG_VIEW_CONVERSATION ? (
                    <BiConversation
                      size={16}
                      style={{ color: "#F05742", marginBottom: -4 }}
                    />
                  ) : (
                    <BiConversation
                      size={16}
                      style={{ color: "#C0C0C0", marginBottom: -4 }}
                    />
                  )}
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission.BOT_ACTIVITY_LOG_VIEW_INSIGHT
                      ? "Insight"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission
                    .BOT_ACTIVITY_LOG_VIEW_INSIGHT ? (
                    <HiOutlineLightBulb
                      size={18}
                      style={{ color: "#F05742", marginBottom: -3 }}
                    />
                  ) : (
                    <HiOutlineLightBulb
                      size={18}
                      style={{ color: "#C0C0C0", marginBottom: -3 }}
                    />
                  )}
                </Tooltip>
              </Space>
            )}

            {record.broadcastTemplate && (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.BOT_ACTIVITY_LOG_DOWNLOAD_REPORT
                    ? "Download Report"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission
                  .BOT_ACTIVITY_LOG_DOWNLOAD_REPORT ? (
                  <DownloadOutlined style={{ color: "#F05742" }} />
                ) : (
                  <DownloadOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return { dataColumns, actionColumns };
};

export default useActivityLogsTableColumns;
