import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Input, Col, Row, Button, Tooltip, Divider, Space } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { CloseOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { TiArrowSync } from "react-icons/ti";
import { BiBroadcast } from "react-icons/bi";
import { Translation } from "../../../translation/translation";

const MessageTemplatesHeader = ({ wabaProviderName }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const translation = Translation().messageTemplates.header;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  let filteredTemplates =
    state.messageTemplates.templates?.find(
      (template) => template.botId === state.application.botInAction
    )?.waba_templates || [];

  // For create broadcast button
  const allowedTimeRange = state.broadcastSettings?.allowedTimeRange;

  const isWithinTimeRange = () => {
    const currentTime = moment();
    const fromTime = moment(allowedTimeRange?.fromTime, "h:mm a");
    const toTime = moment(allowedTimeRange?.toTime, "h:mm a");

    return currentTime.isBetween(fromTime, toTime);
  };

  return (
    <Row>
      <Col span={21}>
        <Space>
          <Input
            disabled={filteredTemplates.length === 0}
            prefix={<SearchOutlined />}
            placeholder="Search by template category, name or message"
            value={
              state.filters.searchText["message-templates-search-text-box"] &&
              state.filters.searchText["message-templates-search-text-box"]
            }
            style={{ width: 400 }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "message-templates-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
          />
          <Divider type="vertical" />

          <Tooltip
            placement="top"
            title={translation.refreshTemplates.infoText}
          >
            <Button
              icon={<TiArrowSync size={18} style={{ margin: -3 }} />}
              onClick={() => {
                dispatchMiddleware({
                  type: "MWD_LOAD_MESSAGE_TEMPLATE",
                  payload: {
                    wabaApikey:
                      botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey,
                    botId: state.application.botInAction,
                    wabaProvider: wabaProviderName,
                    component: "message-templates-table",
                  },
                });
              }}
            />
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              state.currentUser.permission.BROADCAST_CREATE
                ? allowedTimeRange
                  ? isWithinTimeRange()
                    ? "Create Broadcast"
                    : `Broadcast allowed from ${allowedTimeRange?.fromTime} to ${allowedTimeRange?.toTime} only`
                  : "Create Broadcast"
                : "You do not have the required permission"
            }
          >
            <Button
              icon={<BiBroadcast style={{ margin: -2 }} />}
              disabled={
                !state.currentUser.permission.BROADCAST_CREATE ||
                (allowedTimeRange && !isWithinTimeRange())
              }
              onClick={() => {
                // Navigate to broadcast page
                navigate("/broadcast-manager");
                sessionStorage.setItem("selectedMenu", "/broadcast-manager");

                dispatchReducer({
                  type: "OPEN_FORM_PANEL",
                  payload: {
                    component: "broadcast-form-panel",
                    title: "Create New Broadcast",
                  },
                });
                dispatchMiddleware({
                  type: "MWD_LOAD_AUTOMATED_REPLY_DATA",
                  payload: { component: "automated-replies-table" },
                });
              }}
            />
          </Tooltip>
        </Space>
      </Col>

      <Col span={3} style={{ textAlign: "right" }}>
        <Tooltip
          placement="topRight"
          title={
            botInAction?.bot_channel_info.WHATSAPP &&
            botInAction.bot_channel_info.WHATSAPP.waba_integrated_onboarding
              ? translation.createTemplate.infoText
              : translation.createTemplate.pending
          }
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            disabled={
              !botInAction.bot_channel_info.WHATSAPP?.waba_integrated_onboarding
            }
            onClick={() => {
              dispatchReducer({
                type: "OPEN_FORM_PANEL",
                payload: {
                  component: "message-template-form-panel",
                  title: translation.createTemplate.title,
                },
              });
            }}
          >
            {translation.createTemplate.buttonText}
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default MessageTemplatesHeader;
