import { LogLevel } from "@azure/msal-browser";
import { URIs } from "./constants/env";

export const b2cPolicies = {
  names: {
    signIn: "B2C_1_signin",
    forgotPassword: "B2C_1_reset_pwd",
    editProfile: "B2C_1_profileediting",
  },
  authorities: {
    signIn: {
      authority:
        "https://inextlabsidentity.b2clogin.com/inextlabsidentity.onmicrosoft.com/B2C_1_signin",
    },
    forgotPassword: {
      authority:
        "https://inextlabsidentity.b2clogin.com/inextlabsidentity.onmicrosoft.com/B2C_1_reset_pwd",
    },
    editProfile: {
      authority:
        "https://inextlabsidentity.b2clogin.com/inextlabsidentity.onmicrosoft.com/B2C_1_profileediting",
    },
  },
  authorityDomain: "inextlabsidentity.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: "10d33577-bc99-484d-9db1-8c489138eebc", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: URIs.B2C_LOGIN, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: URIs.B2C_POST_LOGOUT, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    "https://inextlabsidentity.onmicrosoft.com/node-api/iNextIdentityNodeAPI.Read",
  ],
};
