import { Table } from "antd";
import TicketsTableColumns from "./TicketsTableColumns";
import { useGlobalContext } from "../../contexts/globalContext";

const TicketsTable = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const { ticketsDataColumns, ticketsActionColumn } = TicketsTableColumns();

  let ticketsDetails = [
    {
      ticketNumber: "T-001",
      createdData: "02-07-2010",
      userName: "iNextlabs Dev Text",
      subject: "List the product",
      description:
        "While listing the product, it only shows a few products and items instead of the entire inventory. This issue is affecting our users' ability to browse and purchase products effectively. We need to investigate and fix the underlying cause to ensure a seamless shopping experience for our customers.",
      priority: "high",
      status: "open",
      botId: "msd-enquiry-bot",
      conversationId: "919207429236",
      assignedTo: "devtest@inextlabs.com",

      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },

        {
          date: "2024-01-03",

          action: "statusChanged",

          performedBy: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },

          status: "closed",
        },

        {
          date: "2024-01-04",

          action: "subjectAndDescriptionChanged",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          from: {
            subject: "old subject",

            description: "old description",
          },

          to: {
            subject: "new subject",

            description: "new description",
          },
        },
      ],
    },
    {
      ticketNumber: "T-002",
      createdData: "01-06-2011",
      userName: "Sachin",
      subject: "Fix login issue",
      description:
        "When users try to log in to the portal, they encounter an error message stating 'Invalid username or password.' This issue prevents users from accessing their accounts and using our services. We must address this login issue promptly to restore normal functionality for our users.",
      priority: "medium",
      status: "inprocess",
      botId: "msd-enquiry-bot",
      assignedTo: "sachinraj77@gmail.com",
      conversationId: "919207429236",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },
      ],
    },
    {
      ticketNumber: "T-003",
      createdData: "03-08-2012",
      userName: "Nandha kumar",
      subject: "Update user profile",
      description:
        "The user profile information displayed on the portal is outdated and inaccurate. Users are requesting to update their profiles with the correct information to ensure the accuracy of their accounts. We need to implement a mechanism to allow users to update their profiles easily and ensure data integrity.",
      priority: "low",
      status: "close",
      botId: "msd-enquiry-bot",
      assignedTo: "nandhakumarv@inextlabs.com",
      conversationId: "916383705270",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },
      ],
    },
    {
      ticketNumber: "T-004",
      createdData: "01-05-2013",
      userName: "Kaja sherief",
      subject: "Add new feature",
      description:
        "In our bot, we need to add additional features such as voice and image upload functionality. This enhancement will enrich the user experience and provide more interaction options for our users. We must prioritize this feature addition to stay competitive in the market.",
      priority: "medium",
      status: "open",
      botId: "msd-enquiry-bot",
      assignedTo: "kajasheriefa@inextlabs.com",
      conversationId: "919677007475",
      history: [
        {
          date: "2024-01-04",

          action: "subjectAndDescriptionChanged",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          from: {
            subject: "old subject",

            description: "old description",
          },

          to: {
            subject: "new subject",

            description: "new description",
          },
        },
      ],
    },
    {
      ticketNumber: "T-005",
      createdData: "12-22-20214",
      userName: "Shanthi",
      subject: "Fix checkout process",
      description:
        "The checkout process on our website is not functioning properly, resulting in errors during payment processing. This issue is causing frustration among our customers and impacting our revenue. We need to identify and resolve the root cause of the checkout process failure to ensure smooth transactions for our users.",
      priority: "high",
      status: "inprocess",
      assignedTo: "shanthib@inextlabs.com",
      botId: "msd-enquiry-bot",
      conversationId: "919677007475",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },
        {
          date: "2024-01-04",

          action: "subjectAndDescriptionChanged",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          from: {
            subject: "old subject",

            description: "old description",
          },

          to: {
            subject: "new subject",

            description: "new description",
          },
        },
      ],
    },
    {
      ticketNumber: "T-006",
      createdData: "02-21-2015",
      userName: "Ashok kumar",
      subject: "Update payment gateway",
      description:
        "We need to update our payment gateway to support additional payment methods and improve security measures. This update will enhance the user experience and increase customer trust in our platform. It's crucial to implement these changes promptly to stay ahead of the competition.",
      priority: "medium",
      status: "close",
      assignedTo: "dpani@inextlabs.com",
      botId: "msd-enquiry-bot",
      conversationId: "919677007475",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },

        {
          date: "2024-01-03",

          action: "statusChanged",

          performedBy: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },

          status: "closed",
        },
      ],
    },
    {
      ticketNumber: "T-007",
      createdData: "11-06-2018",
      userName: "Shalu",
      subject: "Optimize website performance",
      description:
        "Our website performance is subpar, with slow page load times and frequent timeouts. This issue is driving away potential customers and negatively impacting our business. We need to optimize our website's performance by optimizing code, compressing images, and leveraging caching techniques.",
      priority: "low",
      status: "open",
      botId: "msd-enquiry-bot",
      assignedTo: "ashokm@inextlabs.com",
      conversationId: "918754466528",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },

        {
          date: "2024-01-03",

          action: "statusChanged",

          performedBy: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },

          status: "closed",
        },

        {
          date: "2024-01-04",

          action: "subjectAndDescriptionChanged",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          from: {
            subject: "old subject",

            description: "old description",
          },

          to: {
            subject: "new subject",

            description: "new description",
          },
        },
      ],
    },
    {
      ticketNumber: "T-008",
      createdData: "09-09-2023",
      userName: "Intern",
      subject: "Resolve issue with search functionality",
      description:
        "The search functionality on our platform is not working as expected, returning incorrect or incomplete results. This issue hampers users' ability to find relevant information and affects their overall experience. We must investigate and fix the underlying problem to restore the search functionality's effectiveness.",
      priority: "high",
      status: "inprocess",
      botId: "msd-enquiry-bot",
      assignedTo: "arungonal@inextlabs.com",
      conversationId: "918754466528",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },

        {
          date: "2024-01-03",

          action: "statusChanged",

          performedBy: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },

          status: "closed",
        },
      ],
    },
    {
      ticketNumber: "T-009",
      createdData: "05-01-2020",
      userName: "John",
      subject: "Design UI for new feature",
      description:
        "We need to design a user-friendly UI for a new feature in our application. The UI should be intuitive, visually appealing, and consistent with our brand identity. It's essential to prioritize good design to enhance the user experience and drive adoption of the new feature.",
      priority: "medium",
      status: "close",
      botId: "msd-enquiry-bot",
      assignedTo: "arungonal@inextlabs.com",
      conversationId: "918754466528",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },

        {
          date: "2024-01-04",

          action: "subjectAndDescriptionChanged",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          from: {
            subject: "old subject",

            description: "old description",
          },

          to: {
            subject: "new subject",

            description: "new description",
          },
        },
      ],
    },
    {
      ticketNumber: "T-010",
      createdData: "03-01-2022",
      userName: "Arun",
      subject: "Implement localization support",
      description:
        "We need to implement localization support to make our application accessible to users worldwide. This feature will enable users to use our platform in their preferred language and improve engagement and user satisfaction. It's essential to prioritize localization to expand our user base and reach new markets.",
      priority: "high",
      status: "open",
      botId: "msd-enquiry-bot",
      assignedTo: "john@inextlabs.com",
      conversationId: "918754466528",
      history: [
        {
          date: "2024-01-01",

          action: "created",

          performedBy: {
            // created from bot

            bot: "botId",

            // created from portal

            user: {
              email: "shanthi@inextlabs.com",

              role: "liveagent",

              name: "shanthi",
            },
          },
        },

        {
          date: "2024-01-02",

          action: "assigned",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          assignedTo: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },
        },

        {
          date: "2024-01-03",

          action: "statusChanged",

          performedBy: {
            email: "ashok@inextlabs.com",

            role: "liveagent",

            name: "Ashok",
          },

          status: "closed",
        },

        {
          date: "2024-01-04",

          action: "subjectAndDescriptionChanged",

          performedBy: {
            email: "nandha@inextlabs.com",

            role: "admin",

            name: "Nandha",
          },

          from: {
            subject: "old subject",

            description: "old description",
          },

          to: {
            subject: "new subject",

            description: "new description",
          },
        },
      ],
    },
  ];

  state.tickets = ticketsDetails;

  const searchText =
    state.filters.searchText["tickets-search-text-box"] &&
    state.filters.searchText["tickets-search-text-box"];

  if (searchText) {
    ticketsDetails = state.tickets.filter((tickets) => {
      return (
        tickets.ticketNumber
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        tickets.subject?.toLowerCase().includes(searchText.toLowerCase()) ||
        tickets.description?.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <Table
      columns={ticketsDataColumns.concat(ticketsActionColumn)}
      dataSource={ticketsDetails}
      size="middle"
      rowKey="ticketNumber"
      onRow={(selectedTicket) => {
        return {
          onClick: () => {
            dispatchReducer({
              type: "OPEN_INFO_PANEL",
              payload: {
                component: "ticket-info-panel",
              },
            });

            dispatchReducer({
              type: "RD_SET_ACTION_ROW_KEY",
              payload: {
                component: "tickets-table",
                actionRowKey: selectedTicket.ticketNumber,
              },
            });
          },
          onMouseEnter: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "#f5f5f5";
              }
            );
          },
          onMouseLeave: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "";
              }
            );
          },

          style: { cursor: "pointer" },
        };
      }}
    />
  );
};

export default TicketsTable;
