import React from "react";
import { Row, Col, Card } from "antd";
import ContactBySourceChart from "./ContactBySourceChart";
import ContactSubscriptionStatusChart from "./ContactSubscriptionStatusChart";
import ContactCountByCountry from "./ContactCountByCounty";
import { mode } from "../../../constants/env";

const ContactCharts = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span="8">
        <Card type="inner" style={{ height: 270 }}>
          <ContactBySourceChart />
        </Card>
      </Col>

      {(mode === "STAGING" || mode === "DEV") && (
        <Col span="8">
          <Card type="inner" style={{ height: 270 }}>
            <ContactCountByCountry />
          </Card>
        </Col>
      )}

      <Col span="8">
        <Card type="inner" style={{ height: 270 }}>
          <ContactSubscriptionStatusChart />
        </Card>
      </Col>
    </Row>
  );
};

export default ContactCharts;
