import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Tabs, Layout } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import AutomatedRepliesFormPanel from "./AutomatedRepliesFormPanel";
import AutomatedRepliesHeader from "./AutomatedRepliesHeader";
import AutomatedRepliesTable from "./AutomatedRepliesTable";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RobotOutlined } from "@ant-design/icons";
import AccessDenied from "../../../shared-components/AccessDenied";
import GetStarted from "../../../shared-components/GetStarted";

const AutomatedReplies = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const navigate = useNavigate();
  const [showAutomatedReplyScreen, setShowAutomatedReplyScreen] =
    useState(false);

  useConnectMiddleware(() => {
    state.currentUser.permission.ACCOUNT_AUTOMATED_REPLIES_VIEW &&
      dispatchMiddleware({
        type: "MWD_LOAD_AUTOMATED_REPLY_DATA",
        payload: { component: "automated-replies-table" },
      });
  });

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {!state.currentUser.permission.ACCOUNT_AUTOMATED_REPLIES_VIEW ? (
        <AccessDenied />
      ) : state.automatedReplies.broadcastAutomatedReplies &&
        state.automatedReplies.broadcastAutomatedReplies.length === 0 &&
        !showAutomatedReplyScreen ? (
        <Layout.Content
          style={{
            height: "97%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GetStarted
            source="automated-replies"
            setShowAutomatedReplyScreen={setShowAutomatedReplyScreen}
          />
        </Layout.Content>
      ) : state.automatedReplies.broadcastAutomatedReplies ||
        showAutomatedReplyScreen ? (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <RobotOutlined size={18} style={{ margin: -4 }} />
                  Automated Replies
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <AutomatedRepliesHeader />
                  </Col>
                  <Col span={24}>
                    <AutomatedRepliesTable />
                  </Col>
                  <AutomatedRepliesFormPanel />
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["automated-replies-table"] &&
            state.apiCallStatus["automated-replies-table"].loading && (
              <TabLoader
                component="automated-replies-table"
                loadingMessage="Synchronizing automated replies..."
                errorMessage="Error synchronizing automated replies"
              />
            )
          }
        />
      ) : (
        <FullPageLoader
          component="automated-replies-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};
export default AutomatedReplies;
