import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Col,
  Drawer,
  Row,
  Space,
  Typography,
  Image,
  Switch,
  Button,
  Tooltip,
  Timeline,
} from "antd";
import { getUserDateTime } from "../../utils/utility";
import { channels } from "../../constants/enums";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { BiConversation, BiBroadcast } from "react-icons/bi";
import { MdHistoryToggleOff, MdHistory } from "react-icons/md";
import ContactManagerFormPanel from "../contact-manager/ContactManagerFormPanel";
import ConversationInfoPanel from "../conversations/ConversationInfoPanel";
import parsePhoneNumber from "libphonenumber-js";
import { Translation } from "../../translation/translation";
import { TabLoader } from "../../shared-components/LoadingIndicator";

const { Text } = Typography;
const { Item } = Timeline;

const ClientResponsesInfoPanel = ({ isContactAlreadyExist }) => {
  const navigate = useNavigate();
  const translation = Translation().clientResponses.infoPanel;

  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [isShowAllDetails, setIsShowAllDetails] = useState();

  const currentResponseCategory = state.clientResponses.currentResponseCategory;
  // For handling view conversation, create new broadcast
  let currentResponse = state.clientResponses.currentResponse;
  const responsesTimeline = state.clientResponses.responsesTimeline;
  const permission = state.currentUser.permission;

  // Add "+" to conversationId if it is whatsapp channel
  if (currentResponse?.channel.toUpperCase() === "WHATSAPP") {
    currentResponse.conversationId = currentResponse.conversationId.includes(
      "+"
    )
      ? currentResponse.conversationId
      : "+" + currentResponse.conversationId;
  }

  let selectedResponse = state.clientResponses.responses.data.find(
    (response) =>
      response._id === state.filters.actionRowKey["client-responses-table"]
  );

  //Convert selected response to array of data
  if (selectedResponse) {
    selectedResponse = Object.keys(selectedResponse)?.map((key) => ({
      key: key,
      value: selectedResponse[key],
    }));

    //Merge selected response and enums based on id
    selectedResponse = selectedResponse.map((selectedResponse) => {
      let matchedResponse =
        state.application.enums?.responses?.data?.find(
          (enumKey) => enumKey._id === selectedResponse.key
        ) || {};

      if (matchedResponse) {
        selectedResponse.display_name =
          matchedResponse.display_name ?? selectedResponse.key;
        selectedResponse.display = matchedResponse.display;
        selectedResponse.isSystemField = matchedResponse.is_global;
        selectedResponse.dataType = matchedResponse.dataType;
        selectedResponse.display_order = parseInt(
          matchedResponse.display_order
        );
      } else {
        selectedResponse.display_name = selectedResponse.key;
      }
      return selectedResponse;
    });

    //Filter display false
    selectedResponse =
      selectedResponse &&
      selectedResponse.filter((response) => response.display !== false);

    //Sort selected response based on display order
    selectedResponse = selectedResponse.sort((a, b) => {
      if (a.display_order && b.display_order) {
        return (a.display_order || 0) - (b.display_order || 0);
      } else {
        return (b.display_order || 0) - (a.display_order || 0);
      }
    });

    //Sort custom fields on top
    selectedResponse = selectedResponse.sort((a, b) => {
      if (a.isSystemField && b.isSystemField) {
        return (b.isSystemField || 0) - (a.isSystemField || 0);
      } else {
        return (a.isSystemField || 0) - (b.isSystemField || 0);
      }
    });
  }

  //Filter default reponse properties
  let defaultResponseProperties =
    selectedResponse &&
    selectedResponse.filter((response) =>
      ["channel", "userprofilename", "leaddate"].includes(
        response.key.toLowerCase()
      )
    );

  //Sort default response property
  defaultResponseProperties =
    defaultResponseProperties &&
    defaultResponseProperties.sort((a, b) =>
      a.display_name?.localeCompare(b.display_name)
    );

  //Remove default response property in selected response
  selectedResponse =
    selectedResponse &&
    selectedResponse.filter(
      (response) =>
        !defaultResponseProperties.some(
          (defaultResponseProperty) =>
            defaultResponseProperty.key === response.key
        )
    );

  //Convert default response property as object
  const defaultResponsePropertiesAsObject =
    defaultResponseProperties &&
    Object.assign(
      {},
      ...defaultResponseProperties?.map((responseProperty) => ({
        [responseProperty.key]: responseProperty.value,
      }))
    );

  //Filter custom fields
  const customFields =
    selectedResponse &&
    selectedResponse.filter((response) => !response.isSystemField);

  //Filter system fields
  const systemFields =
    selectedResponse &&
    selectedResponse.filter((response) => response.isSystemField);

  //Show custom fields by default
  if (!isShowAllDetails && customFields && customFields.length !== 0) {
    selectedResponse = customFields;
  }

  // Check current bot is notify bot or not
  const isNotifyBot =
    state.currentAccount.account.bots
      .find((bot) => bot.bot_id === state.application.botInAction)
      ?.bot_version?.toUpperCase() === "V0.1";

  return (
    <>
      {selectedResponse && (
        <Drawer
          title={currentResponseCategory.concat(" Details")}
          destroyOnClose={true}
          width={450}
          onClose={() => {
            dispatchReducer({
              type: "CLEAR_RESPONSES_TIMELINE",
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "client-responses-table" },
            });
            dispatchReducer({
              type: "CLEAR_CURRENT_RESPONSE",
            });
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "client-response-info-panel",
              },
            });
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "conversation-info-panel",
              },
            });
            setIsShowAllDetails();
          }}
          open={
            state.infoPanel["client-response-info-panel"] &&
            state.infoPanel["client-response-info-panel"].visible
          }
          bodyStyle={{ paddingBottom: 80 }}
        >
          {!state.infoPanel["conversation-info-panel"]?.visible ? (
            <>
              {" "}
              {customFields.length !== 0 && systemFields.length !== 0 && (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                  }}
                >
                  <Text>Show all details</Text>
                  <Switch
                    size="small"
                    style={{ marginLeft: "10px", marginTop: 2 }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => {
                      setIsShowAllDetails(checked);
                    }}
                  />
                </Row>
              )}
              <Row>
                <Space size={10} style={{ width: "100%" }}>
                  {defaultResponsePropertiesAsObject.channel && (
                    <Image
                      src={
                        channels[
                          defaultResponsePropertiesAsObject.channel.toUpperCase()
                        ]?.logo
                      }
                      width={23}
                      preview={false}
                    />
                  )}

                  <Space
                    style={{
                      width: 370,
                      justifyContent: "space-between",
                    }}
                  >
                    <Space>
                      <Text strong ellipsis>
                        {defaultResponsePropertiesAsObject.userProfileName
                          ? defaultResponsePropertiesAsObject.userProfileName
                          : "-"}
                      </Text>
                    </Space>

                    <Space>
                      {defaultResponsePropertiesAsObject.leadDate && (
                        <Text type="secondary" ellipsis>
                          {getUserDateTime(
                            defaultResponsePropertiesAsObject.leadDate,
                            state.currentUser.user.timeZone,
                            state.currentUser.user.dateFormat
                          )}
                        </Text>
                      )}
                    </Space>
                  </Space>
                </Space>
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 15,
                }}
              >
                <Col span={12}>
                  {!isContactAlreadyExist ? (
                    <Tooltip
                      placement="topRight"
                      title={
                        state.currentUser.permission.CONTACT_CREATE
                          ? "Add contact"
                          : "You do not have required permission"
                      }
                    >
                      <Button
                        danger
                        disabled={!state.currentUser.permission.CONTACT_CREATE}
                        onClick={() => {
                          dispatchReducer({
                            type: "OPEN_FORM_PANEL",
                            payload: {
                              component: "contact-manager-form-panel",
                              title: "Associate Contact",
                            },
                          });
                          dispatchReducer({
                            type: "SET_FORM_MODE",
                            payload: {
                              component: "contact-manager-form",
                              mode: "add",
                            },
                          });
                          dispatchMiddleware({
                            type: "MWD_LOAD_CONTACT_MANAGER_DATA",
                            payload: { component: "contact-manager-table" },
                          });
                        }}
                      >
                        Add to Contact
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      danger
                      onClick={() => {
                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component: "conversation-info-panel",
                          },
                        });
                      }}
                    >
                      View Contact
                    </Button>
                  )}
                </Col>

                <Col span={12}>
                  <Space
                    size={17}
                    style={{
                      float: "right",
                    }}
                  >
                    {!isNotifyBot && (
                      <Tooltip
                        placement="topLeft"
                        title={
                          state.currentUser.permission
                            .RESPONSE_VIEW_CONVERSATION
                            ? "View Conversation"
                            : "You do not have required permission"
                        }
                      >
                        {state.currentUser.permission
                          .RESPONSE_VIEW_CONVERSATION ? (
                          <BiConversation
                            style={{
                              fontSize: 20,
                              marginTop: 3,
                              color: "#F05742",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const selectedResponse =
                                state.clientResponses.responses.data.find(
                                  (response) =>
                                    response._id ===
                                    state.filters.actionRowKey[
                                      "client-responses-table"
                                    ]
                                );

                              dispatchReducer({
                                type: "RD_SET_CONVERSATION_FILTER",
                                payload: {
                                  conversationFilter: true,
                                },
                              });

                              dispatchReducer({
                                type: "OPEN_INFO_PANEL",
                                payload: {
                                  component:
                                    "client-responses-view-conversation-info-panel",
                                },
                              });

                              // Clear existing conversation
                              if (state.conversations.contactsWithTranscripts) {
                                dispatchReducer({
                                  type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
                                });
                                dispatchReducer({
                                  type: "RD_CLEAR_CURRENT_CONVERSATION",
                                });
                              }

                              // Load selected conversation based on response
                              dispatchMiddleware({
                                type: "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS",
                                payload: {
                                  conversationId:
                                    selectedResponse.conversationId.replace(
                                      "+",
                                      ""
                                    ),
                                  botId:
                                    selectedResponse.botId ||
                                    selectedResponse.bot_id,
                                  component: "conversations-layout",
                                  contactId: (
                                    selectedResponse.conversationId +
                                    "_" +
                                    selectedResponse.botId
                                  ).replace("+", ""),
                                  leadDate:
                                    selectedResponse.leadDate ||
                                    selectedResponse.responseDate,
                                  category: currentResponseCategory,
                                },
                              });
                            }}
                          />
                        ) : (
                          <BiConversation style={{ color: "#C0C0C0" }} />
                        )}
                      </Tooltip>
                    )}

                    {state.currentAccount.account.modules.broadcast.enabled &&
                      currentResponse?.channel.toUpperCase() === "WHATSAPP" && (
                        <Tooltip
                          placement="topLeft"
                          title={
                            !state.currentUser.permission.BROADCAST_CREATE
                              ? "You do not have required permission"
                              : parsePhoneNumber(
                                  currentResponse.conversationId
                                )?.isValid()
                              ? "Create a new broadcast"
                              : "Invalid phone number"
                          }
                        >
                          {state.currentUser.permission.BROADCAST_CREATE &&
                          // Check if the phone number is valid
                          parsePhoneNumber(
                            currentResponse.conversationId
                          )?.isValid() ? (
                            <BiBroadcast
                              style={{
                                color: "#F05742",
                                fontSize: 19,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // Navigate to broadcast view
                                navigate("/broadcast-manager");
                                sessionStorage.setItem(
                                  "selectedMenu",
                                  "/broadcast-manager"
                                );

                                dispatchReducer({
                                  type: "OPEN_FORM_PANEL",
                                  payload: {
                                    component: "broadcast-form-panel",
                                    title: "Create New Broadcast",
                                  },
                                });

                                const phoneNumber = parsePhoneNumber(
                                  currentResponse.conversationId
                                );

                                // Split the phone number and country code
                                if (phoneNumber) {
                                  dispatchReducer({
                                    type: "SET_SINGLE_CONTACT_TO_POPULATE",
                                    payload: {
                                      contact: {
                                        displayName:
                                          currentResponse.userProfileName,
                                        countryCode:
                                          phoneNumber.countryCallingCode,
                                        phoneNumberWithoutCountryCode:
                                          phoneNumber.nationalNumber,
                                      },
                                    },
                                  });
                                }

                                dispatchReducer({
                                  type: "CLOSE_INFO_PANEL",
                                  payload: {
                                    component: "client-response-info-panel",
                                  },
                                });
                              }}
                            />
                          ) : (
                            <BiBroadcast
                              style={{
                                color: "#C0C0C0",
                                fontSize: 19,
                              }}
                            />
                          )}
                        </Tooltip>
                      )}

                    {/*Responses Timeline*/}
                    {
                      <Tooltip
                        title={
                          permission.RESPONSES_TIMELINE_VIEW
                            ? !responsesTimeline
                              ? translation.responseTimeline.showInfoText
                              : translation.responseTimeline.hideInfoText
                            : translation.noAccess
                        }
                        placement="topLeft"
                      >
                        {responsesTimeline ? (
                          <MdHistoryToggleOff
                            style={{
                              fontSize: 20,
                              color: "#F05742",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatchReducer({
                                type: "CLEAR_RESPONSES_TIMELINE",
                              });
                            }}
                          />
                        ) : (
                          <MdHistory
                            style={{
                              fontSize: 20,
                              color: permission.RESPONSES_TIMELINE_VIEW
                                ? "#F05742"
                                : "#C0C0C0",
                              cursor: permission.RESPONSES_TIMELINE_VIEW
                                ? "pointer"
                                : "",
                            }}
                            onClick={() => {
                              permission.RESPONSES_TIMELINE_VIEW &&
                                dispatchMiddleware({
                                  type: "LOAD_RESPONSES_TIMELINE",
                                  payload: {
                                    component: "client-responses-timeline-list",
                                  },
                                });
                            }}
                          />
                        )}
                      </Tooltip>
                    }
                  </Space>
                </Col>
              </Row>
              {/*Info panel content */}
              {state.apiCallStatus["client-responses-timeline-list"] &&
              state.apiCallStatus["client-responses-timeline-list"].loading ? (
                <TabLoader
                  component="client-responses-timeline-list"
                  loadingMessage="Loading... Please wait..."
                  errorMessage="Error loading responses timeline. Please try again"
                />
              ) : responsesTimeline ? (
                <Space
                  size={10}
                  style={{
                    width: "90%",
                  }}
                  direction="vertical"
                >
                  <Space>User Responses Timeline</Space>

                  <Timeline>
                    {responsesTimeline?.map((response, index) => {
                      return (
                        <Item key={index} dot={response.dot}>
                          {index !== 0 && (
                            <Space direction="vertical" size={2}>
                              <Text strong>{response.category}</Text>
                              <Text type="secondary">
                                ({" "}
                                {getUserDateTime(
                                  response.responseDate,
                                  state.currentUser.user.timeZone,
                                  state.currentUser.user.dateFormat
                                )}{" "}
                                )
                              </Text>
                            </Space>
                          )}
                        </Item>
                      );
                    })}
                  </Timeline>
                </Space>
              ) : (
                <Row gutter={[0, 8]} style={{ marginTop: 5 }}>
                  {selectedResponse?.map((response, index) => {
                    return (
                      <Col span={24} key={index}>
                        <Space
                          direction="vertical"
                          size={0}
                          style={
                            !response.isSystemField
                              ? {
                                  width: "100%",
                                  padding: 10,
                                  backgroundColor: "#F8FFEE",
                                  border: "1px solid #BCE68E",
                                  borderRadius: 5,
                                }
                              : {
                                  width: "100%",
                                  padding: 5,
                                  backgroundColor: "#FFFFFF",
                                  border: "1px solid #F0F0F0",
                                  borderRadius: 5,
                                }
                          }
                        >
                          <Text strong>
                            {response.display_name
                              ? response.display_name
                              : response.key}
                          </Text>
                          <Text type="secondary">
                            {response.value ? (
                              typeof response.value === "object" &&
                              currentResponseCategory.toLowerCase() !==
                                "feedback" &&
                              response.value[0] ? (
                                <div
                                  style={{
                                    width: "100%",
                                    overflow: "auto",
                                  }}
                                >
                                  <table
                                    style={{
                                      border: "1px solid",
                                      marginTop: "5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <tr>
                                      {Object.keys(response.value[0])?.map(
                                        (key, index) => (
                                          <th
                                            style={{
                                              border: "1px solid",
                                              padding: "8px",
                                            }}
                                            key={index}
                                          >
                                            {key}
                                          </th>
                                        )
                                      )}
                                    </tr>

                                    {response.value?.map((item, index) => (
                                      <tr key={index}>
                                        {Object.values(item)?.map(
                                          (value, index) => (
                                            <td
                                              key={index}
                                              style={{
                                                border: "1px solid",
                                                padding: "8px",
                                              }}
                                            >
                                              {value
                                                .toString()
                                                .split("\n")
                                                .map(function (item, idx) {
                                                  return (
                                                    <span key={idx}>
                                                      {item}
                                                      <br />
                                                    </span>
                                                  );
                                                })}
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    ))}
                                  </table>
                                </div>
                              ) : typeof response.value === "object" &&
                                Array.isArray(response.value) &&
                                currentResponseCategory.toLowerCase() ===
                                  "feedback" ? (
                                response.value?.map(
                                  (feedbackDetails, index) => {
                                    return (
                                      <Text key={index}>
                                        {feedbackDetails.PostBackData
                                          ? feedbackDetails.PostBackData
                                          : feedbackDetails.survey_response
                                          ? feedbackDetails.survey_response
                                          : feedbackDetails.Response}
                                      </Text>
                                    );
                                  }
                                )
                              ) : response.dataType === "DATETIME" ? (
                                getUserDateTime(
                                  response.value,
                                  state.currentUser.user.timeZone,
                                  state.currentUser.user.dateFormat
                                )
                              ) : (
                                response.value
                                  .toString()
                                  .split("\n")
                                  .map((value, index) => {
                                    return channels[value] ? (
                                      <Image
                                        src={channels[value].logo}
                                        width={23}
                                        preview={false}
                                        style={{
                                          marginTop: "10px",
                                          marginBottom: "5px",
                                        }}
                                        key={index}
                                      />
                                    ) : (
                                      <Text key={index} ellipsis>
                                        {value}
                                      </Text>
                                    );
                                  })
                              )
                            ) : (
                              "-"
                            )}
                          </Text>
                        </Space>
                      </Col>
                    );
                  })}
                </Row>
              )}
              <ContactManagerFormPanel type="associate_contact" />
            </>
          ) : (
            <ConversationInfoPanel
              isContactAlreadyExist={isContactAlreadyExist}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

export default ClientResponsesInfoPanel;
