import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Space, Input, Divider, Tooltip, Row, Col, Modal } from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  CloseOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { RiFilterOffLine } from "react-icons/ri";
import { useGlobalContext } from "../../contexts/globalContext";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { Translation } from "../../translation/translation";

const { confirm } = Modal;

const ContactManagerHeader = ({ type }) => {
  const navigate = useNavigate();

  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const contactProperties =
    state.contactManagerSettings.contactProperties || [];

  // Filter contacts based on the filter type
  const contactFilter = state.contactManagerSettings.contactFilter || {
    type: "none",
  };

  let filteredContacts = state.contactManager.contacts.filter((contact) => {
    const contactValue = contact[contactFilter?.value?.columnName] || "";
    const currentUserValue =
      state.accountUsers.users?.find(
        (user) => user._id === state.currentUser.user._id
      )?.[contactFilter?.value?.columnValue] || "";

    return state.currentUser.user.role.includes(
      state.application.applicationRoles.find(
        (role) => role.name?.toLowerCase() === "admin"
      )?._id
    )
      ? true
      : contactFilter.type === "none"
      ? true
      : contactFilter.type === "createdBy"
      ? contact.createdBy === state.currentUser.user._id
      : contactFilter.type === "byColumnName/Value" &&
        contactFilter.value?.filterType === "equals"
      ? contactValue === currentUserValue
      : contactFilter.value?.filterType === "notEquals"
      ? contactValue !== currentUserValue
      : contactFilter.value?.filterType === "contains" &&
        contactValue?.includes(currentUserValue);
  });
  const selectedRowKeys =
    state.filters.selectedRowKeys["contact-manager-table"];

  const translation = Translation().contactManager.header;

  return (
    <Row>
      <Col span={17}>
        <Space>
          <Input
            disabled={filteredContacts.length === 0}
            value={
              state.filters.searchText["contact-manager-search-text-box"] &&
              state.filters.searchText["contact-manager-search-text-box"]
            }
            prefix={<SearchOutlined />}
            placeholder="Search contacts by name, email, mobile, etc."
            style={{ width: 400 }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "contact-manager-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
          />

          <Divider type="vertical" />

          <Tooltip placement="top" title="Clear filter(s)">
            <Button
              disabled={
                state.filters.filteredInfo["contact-manager-table"]
                  ? state.filters.filteredInfo["contact-manager-table"]
                      .isUnsubscribed
                    ? false
                    : true
                  : true
              }
              icon={<RiFilterOffLine style={{ margin: -2 }} />}
              onClick={() => {
                dispatchReducer({
                  type: "RD_SET_FILTERED_INFO",
                  payload: {
                    component: "contact-manager-table",
                    filteredInfo: {},
                  },
                });
              }}
            />
          </Tooltip>

          {type && type === "standard" && (
            <Tooltip
              placement="top"
              title={
                state.currentUser.permission.CONTACTS_EXPORT
                  ? "Export contacts"
                  : "You do not have required permission"
              }
            >
              <Button
                disabled={
                  filteredContacts.length === 0 ||
                  !state.currentUser.permission.CONTACTS_EXPORT
                }
                icon={<DownloadOutlined />}
                onClick={() => {
                  //CSV Title
                  const contactManagerExportColumnsTitle =
                    contactProperties.map((column) => column.display_name);

                  //Remove comma from contact values and replace comma with space except tags
                  const modifiedContacts = filteredContacts.map((contact) => {
                    const newContact = { ...contact };

                    Object.keys(newContact).forEach((key) => {
                      if (key === "tags") {
                        newContact[key] = `"${newContact[key]}"`;
                      } else if (typeof newContact[key] === "string") {
                        newContact[key] = newContact[key].replace(/,/g, " ");
                      }
                    });
                    return newContact;
                  });

                  //Combain CSV Title with values
                  const commaSeparatedString = `${contactManagerExportColumnsTitle.join(
                    ","
                  )}\n${modifiedContacts
                    .map((contact) =>
                      contactProperties
                        .map((column) => contact[column.key])
                        .join(",")
                    )
                    .join("\n")}`;

                  //Create URL for export
                  const a = document.createElement("a");
                  a.href = URL.createObjectURL(
                    new Blob([commaSeparatedString], { type: "text/csv" })
                  );
                  a.setAttribute("download", "contacts.csv");
                  document.body.appendChild(a);
                  a.click();
                }}
              />
            </Tooltip>
          )}

          {type && type === "standard" && (
            <Tooltip placement="top" title="Settings">
              <Button
                icon={
                  <SettingOutlined
                    style={{
                      margin: -2,
                    }}
                  />
                }
                onClick={() => {
                  // Navigate to responses settings
                  navigate("/account-settings/contacts");
                  sessionStorage.setItem(
                    "selectedMenu",
                    "/account-settings/contacts"
                  );
                }}
              />
            </Tooltip>
          )}
        </Space>
      </Col>
      <Col span={7}>
        <Row>
          <Col offset={6} span={6} style={{ textAlign: "right" }}>
            {type && type === "standard" && (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.CONTACTS_IMPORT
                    ? translation.importContact.infoText
                    : "You do not have required permission"
                }
              >
                <Button
                  type="primary"
                  style={{ borderRadius: 4, width: 80 }}
                  disabled={!state.currentUser.permission.CONTACTS_IMPORT}
                  onClick={() => {
                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "contact-manager-import-contacts-form-panel",
                        title: "Import Contacts",
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "contact-manager-import-contacts-form",
                        mode: "add",
                      },
                    });
                  }}
                >
                  {translation.importContact.buttonText}
                </Button>
              </Tooltip>
            )}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {type && type === "standard" && (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.CONTACT_CREATE
                    ? translation.addContact.infoText
                    : "You do not have required permission"
                }
              >
                <Button
                  type="primary"
                  style={{ borderRadius: 4, width: 80 }}
                  disabled={!state.currentUser.permission.CONTACT_CREATE}
                  onClick={() => {
                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "contact-manager-form-panel",
                        title: "Add New Contact",
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "contact-manager-form",
                        mode: "add",
                      },
                    });
                  }}
                >
                  {translation.addContact.buttonText}
                </Button>
              </Tooltip>
            )}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {type && type === "standard" && (
              <>
                {state.apiCallStatus["contact-manager-multiple-delete"] &&
                state.apiCallStatus["contact-manager-multiple-delete"]
                  .loading ? (
                  <ComponentLoader
                    component="contact-manager-multiple-delete"
                    loadingMessage="Loading..."
                    errorMessage=""
                  />
                ) : (
                  <Tooltip
                    placement="topRight"
                    title={
                      state.currentUser.permission.CONTACT_DELETE
                        ? selectedRowKeys && selectedRowKeys.length >= 1
                          ? translation.deleteContact.infoText
                          : "Select atleast one contact"
                        : "You do not have required permission"
                    }
                  >
                    <Button
                      type="primary"
                      style={{ borderRadius: 4, width: 80 }}
                      disabled={
                        state.currentUser.permission.CONTACT_DELETE
                          ? selectedRowKeys
                            ? selectedRowKeys.length < 1
                            : true
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title:
                            "Are you sure you want to delete the contacts?",
                          content: `When clicked the OK button, ${
                            selectedRowKeys?.length
                          } contact${
                            selectedRowKeys?.length > 1 ? "s" : ""
                          } will be deleted permanently`,
                          onOk() {
                            dispatchMiddleware({
                              type: "MWD_DELETE_MULTIPLE_CONTACTS",
                              payload: {
                                component: "contact-manager-multiple-delete",
                                selectedRowKeys,
                              },
                            });
                          },
                          onCancel() {},
                        });
                      }}
                    >
                      {translation.deleteContact.buttonText}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactManagerHeader;
