import { useGlobalContext } from "../../../contexts/globalContext";
import { Input, Row, Col, Tooltip, Button, Space } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Translation } from "../../../translation/translation";

const BotKnowledgebaseHeader = () => {
  const [state, dispatchReducer] = useGlobalContext();

  const translation = Translation().botKnowledgeArea.botKnowledgebase.header;

  return (
    <Row>
      <Col span={17}>
        <Input
          placeholder={translation.searchKnowledgebase.placeholder}
          allowClear={{ clearIcon: <CloseOutlined /> }}
          style={{ width: 400 }}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            const currentValue = e.target.value;
            dispatchReducer({
              type: "RD_SET_SEARCH_TEXT",
              payload: {
                component: "bot-knowledgebase-search-text-box",
                searchText: currentValue,
              },
            });
          }}
        />
      </Col>

      <Col span={7} style={{ textAlign: "right" }}>
        <Space size="large">
          <Tooltip
            title={
              state.currentUser.permission.BOT_KNOWLEDGEBASE_TEST
                ? ""
                : translation.noAccess
            }
          >
            <Button
              disabled={!state.currentUser.permission.BOT_KNOWLEDGEBASE_TEST}
              type="primary"
              onClick={() => {
                dispatchReducer({
                  type: "OPEN_INFO_PANEL",
                  payload: {
                    component: "knowledgebase-test-info-panel",
                  },
                });

                localStorage.setItem(
                  "knowledgebaseTestConversationID",
                  "user" + Date.now()
                );
              }}
              style={{
                borderRadius: 4,
              }}
            >
              {translation.testKnowledgebase.buttonText}
            </Button>
          </Tooltip>

          <Tooltip
            title={
              state.currentUser.permission.BOT_KNOWLEDGEBASE_CREATE
                ? ""
                : translation.noAccess
            }
            placement="topRight"
          >
            <Button
              disabled={!state.currentUser.permission.BOT_KNOWLEDGEBASE_CREATE}
              type="primary"
              onClick={() => {
                dispatchReducer({
                  type: "OPEN_FORM_PANEL",
                  payload: {
                    component: "knowledgebase-form-panel",
                    title: translation.addKnowledgebase.formPanelTitle,
                  },
                });

                dispatchReducer({
                  type: "SET_CURRENT_STEP",
                  payload: 0,
                });
              }}
              style={{
                borderRadius: 4,
              }}
            >
              {translation.addKnowledgebase.buttonText}
            </Button>
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};

export default BotKnowledgebaseHeader;
