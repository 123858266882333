import React, { useEffect } from "react";
import { Form, Row, Col, Space, Button } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import openNotificationWithIcon from "../../../utils/notification";
import ReactJson from "react-json-view";

const BotConfigurationForm = () => {
  const [form] = Form.useForm();
  const [state, ,] = useGlobalContext();

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      botConfiguration: JSON.stringify(state.botConfiguration, null, 3),
    });
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      autoComplete="off"
      layout="vertical"
      onFinishFailed={() => {
        openNotificationWithIcon(
          "error",
          "Form Submission Failed! Please check the form for errors"
        );
      }}
    >
      <Row gutter={[0, 16]}>
        <Col span={22}>
          <Form.Item
            name="botConfiguration"
            label="Here, you can control and adjust how your bot talks and behaves."
            rules={[
              {
                validator: (_, value) => {
                  try {
                    JSON.parse(value);
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject("Please enter valid JSON.");
                  }
                },
              },
            ]}
            hasFeedback
          >
            {/* <Input.TextArea
              autoSize={{
                minRows: 10,
              }}
              placeholder="Provide your bot configuration JSON here."
            /> */}
            {console.log(state.botConfiguration)}
            <ReactJson
              src={state.botConfiguration}
              theme="summerfruit:inverted"
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              onAdd={() => {
                return true;
              }}
              onEdit={() => {
                return true;
              }}
              onDelete={() => {
                return true;
              }}
              collapsed={2}
              style={{
                border: "1px solid #a8a8a8",
                padding: "1rem",
                borderRadius: "0.5rem",
                fontFamily: "monospace",
                color: "#333",
                fontSize: "1rem",
                lineHeight: "1.5rem",
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space>
            <Form.Item>
              <Button>Cancel</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default BotConfigurationForm;
