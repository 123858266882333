import { useState, useEffect, useRef } from "react";
import { Layout, Result, Button, Space } from "antd";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import RoutingList from "../routers/RoutingList";
import SideMenu from "./SideMenu";
import AppHeader from "./app-header/AppHeader";
import { FullPageLoader } from "../shared-components/LoadingIndicator";
import { useGlobalContext } from "../contexts/globalContext";
import useConnectMiddleware from "../connect/connectMiddleware";
import { Buffer } from "buffer";
import { useMsal } from "@azure/msal-react";
import { URIs } from "../constants/env";

window.Buffer = Buffer;

const AppContainerContent = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const { instance } = useMsal();

  const [collapsed, setCollapsed] = useState(true);
  const ws = useRef(null);
  const [webSocketStatus, setWebSocketStatus] = useState({
    status: "closed",
    date: new Date(),
  });
  const [webSocketURL, setWebSocketURL] = useState();

  const handleOnCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_APPLICATION_DATA",
      payload: { component: "application-container" },
    });
  });

  //To get the Token from AZURE PUB SUB
  useEffect(() => {
    if (
      state.currentUser.user &&
      state.currentAccount.account &&
      webSocketStatus.status === "closed" &&
      state.currentAccount.pubSubTokenUrl
    ) {
      ws.current = null;
      console.log("Connecting Azure Pub Sub to generate token");

      setWebSocketURL(state.currentAccount.pubSubTokenUrl);

      //     let pubSubConnectionPromise = new Promise(async (resolve, reject) => {
      //       let token;
      //       let serviceClient = new WebPubSubServiceClient(
      //         "Endpoint=https://liveagentchat.webpubsub.azure.com;AccessKey=Fc6iBOzeSXwMQvGrUOC9TtojGj7fxkOGhuyYSkUB3Dc=;Version=1.0;",
      //         state.currentAccount.account.live_agent.live_agent_pubsub_hubname
      //       );
      //       token = await serviceClient.getClientAccessToken({
      //         userId: state.currentUser.user.userID,
      //       });

      //       console.log(
      //         "Token",
      //         token,
      //         state.currentAccount.account.live_agent.live_agent_pubsub_hubname,
      //         state.currentUser.user.userID
      //       );

      //       if (token) {
      //         resolve(token.url);
      //       } else {
      //         reject();
      //       }
      //     });

      //     pubSubConnectionPromise.then(
      //       (url) => {
      //         setWebSocketURL(url);
      //       },
      //       (error) => {
      //         console.log("PubSub Connection Error:", error);
      //       }
      //     );

      const wsCurrent = ws.current;

      return () => {
        if (wsCurrent) {
          wsCurrent.close();
        }
      };
    }
  }, [
    state.currentUser.user,
    state.currentAccount.account,
    webSocketStatus,
    state.currentAccount.pubSubTokenUrl,
  ]);

  //WEB SOCKET CONNECTION
  useEffect(() => {
    if (webSocketURL && webSocketStatus.status === "closed") {
      console.log("Web Socket URL", webSocketURL);
      ws.current = new WebSocket(webSocketURL);

      ws.current.onopen = () => {
        console.log("Socket Open", new Date());
        setWebSocketStatus({ status: "open", date: new Date() });
      };
      ws.current.onclose = () => {
        console.log("Socket Closed", new Date());
        setWebSocketStatus({ status: "closed", date: new Date() });
      };
      ws.current.onerror = () => {
        console.log("Socket Error", new Date());
        setWebSocketStatus({ status: "closed", date: new Date() });
      };
    }
  }, [webSocketURL]);

  let hasPlayedDeliveryStatusAudio = false;

  //To receive messages from socket
  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      const webSocketMessage = JSON.parse(e.data);

      if (webSocketMessage.event_type === "conversations") {
        console.log("Message:", webSocketMessage);

        if (webSocketMessage.message_type === "JOIN") {
          if (Notification.permission !== "granted")
            Notification.requestPermission();
          else {
            const notification = new Notification("Live Agent Requested", {
              icon: URIs.PORTAL.concat("/inextlabs-logo-icon.png"),
              body: "A website visitor is requesting for a Live Agent. Login to the portal to view details",
            });
            notification.onclick = function () {
              window.open(URIs.PORTAL.concat("/conversations"));
            };
          }

          new Audio(state.currentUser.user.alertTone).play();
        } else {
          // Play message tone if the message is not a delivery status
          if (
            !hasPlayedDeliveryStatusAudio &&
            webSocketMessage.message_type !== "DELIVERY_STATUS"
          ) {
            new Audio(state.currentUser.user.messageTone).play();
            hasPlayedDeliveryStatusAudio = true;
          }
        }

        // Don't dispatch if the message delivery status is SENT
        if (webSocketMessage.deliveryStatus?.status?.toUpperCase() !== "SENT") {
          dispatchMiddleware({
            type: "MWD_CONVERSATIONS_FROM_WEB_SOCKET",
            payload: webSocketMessage,
          });
        }
      } else {
        console.log("Broadcast Status Received");
      }
    };
  });

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  });

  return (
    <>
      {state.currentUser.user ? (
        state.currentAccount.account.account_status.toUpperCase() ===
        "ACTIVE" ? (
          <>
            <AppHeader />
            <Layout>
              <SideMenu
                collapsed={collapsed}
                handleOnCollapse={handleOnCollapse}
                setCollapsed={setCollapsed}
              />
              <Layout style={{ background: "#ffffff" }}>
                <Layout.Content style={{ margin: "16px" }}>
                  <RoutingList />
                </Layout.Content>
              </Layout>
            </Layout>
          </>
        ) : (
          <Result
            status="warning"
            title="Account is not active"
            subTitle={
              <Space
                style={{
                  width: "60%",
                }}
              >
                Your account is currently inactive. This may be due to a variety
                of reasons such as payment issues or account maintenance. Please
                contact your account administrator to resolve this issue.
              </Space>
            }
            style={{
              marginTop: "5%",
            }}
            extra={[
              <Space>
                <Button
                  key="console"
                  onClick={() => {
                    // Clear the conversationId from the cookie for each bot
                    state.currentAccount.account.bots?.forEach((bot) => {
                      document.cookie = `conversationId-${bot?.bot_id}=; expires=Thu, 18 Dec 1997 12:00:00 UTC`;
                    });

                    sessionStorage.removeItem("selectedMenu");
                    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                  }}
                >
                  Logout
                </Button>

                <Button
                  key="contact"
                  type="primary"
                  onClick={async () => {
                    window.open(
                      "mailto:support@inextlabs.com?subject=" +
                        "Account activation request" +
                        "&body=" +
                        "Dear Support Team," +
                        "%0D%0A%0D%0A" +
                        "I am writing to request the activation of my account. Please assist me with this request." +
                        "%0D%0A%0D%0A" +
                        "Thank you." +
                        "%0D%0A%0D%0A" +
                        "Regards," +
                        "%0D%0A" +
                        state.currentUser.user.displayName
                    );
                  }}
                >
                  Contact Support
                </Button>
              </Space>,
            ]}
          />
        )
      ) : (
        <FullPageLoader
          component="application-container"
          loadingMessage="Loading... Please wait..."
          showLogoutLink="true"
        />
      )}
    </>
  );
};

const AppContainer = () => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <AppContainerContent />
    </MsalAuthenticationTemplate>
  );
};

export default AppContainer;
