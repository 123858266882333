import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Result,
  Image,
  Layout,
  Card,
  Tag,
  Tooltip,
  Collapse,
} from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { channels, wabaProvider } from "../../../constants/enums";
import BotSettingsMenu from "../BotSettingsMenu";
import { ConnectButton } from "360dialog-connect-button";
import {
  FullPageLoader,
  ComponentLoader,
} from "../../../shared-components/LoadingIndicator";
import { URIs } from "../../../constants/env";
import {
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  MdContentCopy,
  MdVerified,
  MdPeopleAlt,
  MdSettings,
  MdBusinessCenter,
  MdManageAccounts,
  MdOutlineBarChart,
} from "react-icons/md";
import { PiBooksBold } from "react-icons/pi";
import { FaQrcode } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { RiProfileFill, RiContactsBookUploadFill } from "react-icons/ri";
import { BiSolidMessageDots } from "react-icons/bi";
import { TiArrowSync } from "react-icons/ti";
import openNotificationWithIcon from "../../../utils/notification";
import { getUserDateTime } from "../../../utils/utility";
import { appInsights } from "../../../AppInsights";
import BotWHATSAPPOnboarding from "./BotWHATSAPPOnboarding";
import {
  CustomTitle,
  CustomParagraph,
} from "../../../shared-components/CustomTyphography";

const { Text, Paragraph } = Typography;

const BotWHATSAPP = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [maskApiKey, setMaskApiKey] = useState();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const currentMonthUsage = state.currentAccount.whatsappUsage?.usage
    ?.sort((a, b) => new Date(a.period_date) - new Date(b.period_date))
    ?.slice(-1)?.[0];

  const phoneNumber =
    botToBeEdited.bot_channel_info?.WHATSAPP?.waba_phone_number;
  const formattedPhoneNumber = phoneNumber?.replace("+", "");

  //Close whatsapp usage info panel
  useEffect(() => {
    dispatchReducer({
      type: "RD_CLEAR_WHATSAPP_USAGE_DATA",
    });

    botToBeEdited.bot_channel_info.WHATSAPP?.waba_provider ===
      "INEXTLABS-CLOUD" &&
      botToBeEdited.bot_channel_info.WHATSAPP?.waba_integrated_onboarding &&
      dispatchMiddleware({
        type: "REFRESH_WHATSAPP_PHONE_NUMBER_STATUS",
        payload: {
          component: "whatsapp-phone-number-status-refresh-button",
          wabaId: botToBeEdited.bot_channel_info.WHATSAPP?.waba_id,
          wabaPhoneNumberId:
            botToBeEdited.bot_channel_info.WHATSAPP?.waba_phone_number_id,
        },
      });
  }, [state.application.botInAction]);

  //Callback from 360 Dialog
  const handleCallback = (clientDetails) => {
    appInsights.trackEvent({
      name: "360Dialog Callback Received",
      properties: {
        clientId: state.currentAccount.account.client_id,
      },
    });
    dispatchMiddleware({
      type: "ONBOARD_WHATSAPP_USING_360DIALOG",
      payload: {
        clientDetails,
        component: "configure-whatsapp-button",
      },
    });
  };

  const ShowUsage = () => {
    return (
      <Row
        style={{
          marginTop: "10px",
        }}
      >
        {!currentMonthUsage ? (
          <Col span={24}>
            <Tooltip
              placement="top"
              title={
                (!botToBeEdited.bot_channel_info?.WHATSAPP
                  ?.waba_integrated_onboarding &&
                  "Pending WhatsApp Configuration") ||
                (state.currentUser.permission.WHATSAPP_USAGE_VIEW
                  ? "Show WhatAppUsage"
                  : "You do not have required permission")
              }
            >
              <Button
                type="primary"
                disabled={
                  !botToBeEdited.bot_channel_info?.WHATSAPP
                    ?.waba_integrated_onboarding ||
                  !state.currentUser.permission.WHATSAPP_USAGE_VIEW
                }
                onClick={() => {
                  appInsights.trackEvent({
                    name: "WhatsApp Usage View",
                    properties: {
                      clientId: state.currentAccount.account.client_id,
                    },
                  });
                  dispatchMiddleware({
                    type: "MWD_LOAD_WHATSAPP_USAGE_DATA",
                    payload: {
                      botId: botToBeEdited.bot_id,
                      clientId:
                        botToBeEdited.bot_channel_info.WHATSAPP.waba_client_id,
                      channelId:
                        botToBeEdited?.bot_channel_info?.WHATSAPP
                          ?.waba_channel_id?.[0],
                      currentMonth: new Date().getMonth(),
                      component: "bot-settings-whatsapp-button",
                    },
                  });
                }}
                loading={
                  state.apiCallStatus["bot-settings-whatsapp-button"] &&
                  state.apiCallStatus["bot-settings-whatsapp-button"]?.loading
                }
              >
                Show Usage
              </Button>
            </Tooltip>
          </Col>
        ) : (
          <Col span={24}>
            <Card type="inner">
              <CloseOutlined
                onClick={() => {
                  dispatchReducer({
                    type: "RD_CLEAR_WHATSAPP_USAGE_DATA",
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#F05742",
                  float: "right",
                }}
              />
              <Row>
                <Col span={24}>
                  <Text strong>Current Month Usage </Text>
                </Col>

                <Col span={24} style={{ marginTop: "10px" }}>
                  <Text strong>Business Initiated Quantity</Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary">
                    {currentMonthUsage.business_initiated_quantity}
                  </Text>
                </Col>

                <Col span={24} style={{ marginTop: "10px" }}>
                  <Text strong>User Initiated Quantity</Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary">
                    {currentMonthUsage.user_initiated_quantity}
                  </Text>
                </Col>

                <Col span={24} style={{ marginTop: "10px" }}>
                  <Text strong>Total Price</Text>
                </Col>
                <Col span={24}>
                  <Row>
                    <Text type="secondary">
                      ${currentMonthUsage.total_price.toFixed(2)}
                    </Text>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "20px 0px 0px 20px",
            width: "100%",
          }}
        >
          {botToBeEdited.bot_channel_info.WHATSAPP ? (
            state.apiCallStatus["configure-whatsapp-button"] &&
            state.apiCallStatus["configure-whatsapp-button"].loading ? (
              <FullPageLoader
                component="configure-whatsapp-button"
                loadingMessage="Loading... Please wait..."
              />
            ) : (
              <Row gutter={[10, 16]}>
                <Col span={16}>
                  {botToBeEdited.bot_channel_info.WHATSAPP
                    ?.waba_integrated_onboarding ? (
                    botToBeEdited.bot_channel_info.WHATSAPP.waba_provider ===
                    "INEXTLABS-CLOUD" ? (
                      <Row gutter={[0, 13]}>
                        <Col span={24}>
                          <CustomTitle title="WhatsApp Business Account Details:" />
                        </Col>

                        <Col span={24}>
                          <Card
                            style={{
                              border: "1px solid #d9d9d9",
                              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
                              borderRadius: "5px",
                            }}
                            size="small"
                          >
                            <Row gutter={[0, 13]}>
                              <Col span={24}>
                                <Space>
                                  <CustomTitle
                                    title={
                                      botToBeEdited.bot_channel_info.WHATSAPP
                                        ?.waba_verified_name || "-"
                                    }
                                  />
                                  <Tooltip title="This is the name of the WhatsApp Business Account. This name will be displayed to the customers on WhatsApp.">
                                    <IoIosInformationCircleOutline
                                      style={{
                                        color: "#757373",
                                        marginBottom: -2,
                                      }}
                                    />
                                  </Tooltip>
                                </Space>
                              </Col>

                              <Col span={17}>
                                <Row gutter={[0, 13]}>
                                  <Col span={7}>
                                    <CustomParagraph paragraph="Phone Number" />
                                  </Col>
                                  <Col span={1}>:</Col>
                                  <Col span={16}>
                                    <Tooltip title="This is the phone number to which the Bot is configured. Broadcast will be sent from this phone number.">
                                      <CustomParagraph
                                        paragraph={
                                          botToBeEdited.bot_channel_info
                                            .WHATSAPP?.waba_phone_number
                                        }
                                      />
                                    </Tooltip>
                                  </Col>

                                  <Col span={7}>
                                    <Space>
                                      <CustomParagraph paragraph="Account ID" />
                                      <Tooltip title="Unique identifier for the WhatsApp Business Account. This ID is used to manage the templates.">
                                        <IoIosInformationCircleOutline
                                          style={{
                                            color: "#757373",
                                            marginBottom: -2,
                                          }}
                                        />
                                      </Tooltip>
                                    </Space>
                                  </Col>
                                  <Col span={1}>:</Col>
                                  <Col span={16}>
                                    <Paragraph
                                      copyable={{
                                        text: botToBeEdited.bot_channel_info
                                          .WHATSAPP?.waba_id,
                                      }}
                                      className="copy-value"
                                    >
                                      <CustomParagraph
                                        paragraph={
                                          botToBeEdited.bot_channel_info
                                            .WHATSAPP?.waba_id
                                        }
                                      />
                                    </Paragraph>
                                  </Col>

                                  <Col span={7}>
                                    <CustomParagraph paragraph="Phone Number ID" />
                                    <Tooltip title="Unique identifier for the WhatsApp Business Account's phone number. This ID is used to send templates to the customers.">
                                      <IoIosInformationCircleOutline
                                        style={{
                                          color: "#757373",
                                          marginBottom: -2,
                                          marginLeft: 4,
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span={1}>:</Col>
                                  <Col span={16}>
                                    <Paragraph
                                      copyable={{
                                        text: botToBeEdited.bot_channel_info
                                          .WHATSAPP?.waba_phone_number_id,
                                      }}
                                      className="copy-value"
                                    >
                                      <CustomParagraph
                                        paragraph={
                                          botToBeEdited.bot_channel_info
                                            .WHATSAPP?.waba_phone_number_id
                                        }
                                      />
                                    </Paragraph>
                                  </Col>

                                  <Col span={7}>
                                    <CustomParagraph paragraph="Onboarded Date" />
                                  </Col>
                                  <Col span={1}>:</Col>
                                  <Col span={16}>
                                    <CustomParagraph
                                      paragraph={
                                        botToBeEdited.bot_channel_info.WHATSAPP
                                          ?.waba_last_onboarded_time
                                          ? getUserDateTime(
                                              botToBeEdited.bot_channel_info
                                                .WHATSAPP
                                                ?.waba_last_onboarded_time,
                                              state.currentUser.user.timeZone,
                                              state.currentUser.user.dateFormat
                                            )
                                          : "-"
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Col>

                              <Col span={7}>
                                <Space
                                  style={{
                                    float: "right",
                                  }}
                                >
                                  <CustomParagraph paragraph="Status:" />
                                  <Space size="small">
                                    <Tag
                                      color={
                                        botToBeEdited.bot_channel_info.WHATSAPP?.waba_phone_number_status?.toUpperCase() ===
                                        "CONNECTED"
                                          ? "green"
                                          : "red"
                                      }
                                      type="round"
                                      style={{
                                        borderRadius: "4px",
                                        fontSize: "12px",
                                        marginRight: 0,
                                      }}
                                    >
                                      {botToBeEdited.bot_channel_info.WHATSAPP
                                        ?.waba_phone_number_status
                                        ? botToBeEdited.bot_channel_info.WHATSAPP?.waba_phone_number_status
                                            .charAt(0)
                                            .toUpperCase() +
                                          botToBeEdited.bot_channel_info.WHATSAPP?.waba_phone_number_status
                                            .slice(1)
                                            .toLowerCase()
                                        : "Not Available"}
                                    </Tag>

                                    {state.apiCallStatus?.[
                                      "whatsapp-phone-number-status-refresh-button"
                                    ]?.loading ? (
                                      <ComponentLoader
                                        component="whatsapp-phone-number-status-refresh-button"
                                        loadingMessage=""
                                        errorMessage=""
                                      />
                                    ) : (
                                      <Tooltip title="Refresh Phone Number Status">
                                        <TiArrowSync
                                          style={{
                                            cursor: "pointer",
                                            color: "#F05742",
                                            marginBottom: -2,
                                          }}
                                          onClick={() => {
                                            dispatchMiddleware({
                                              type: "REFRESH_WHATSAPP_PHONE_NUMBER_STATUS",
                                              payload: {
                                                component:
                                                  "whatsapp-phone-number-status-refresh-button",
                                                wabaId:
                                                  botToBeEdited.bot_channel_info
                                                    .WHATSAPP?.waba_id,
                                                wabaPhoneNumberId:
                                                  botToBeEdited.bot_channel_info
                                                    .WHATSAPP
                                                    ?.waba_phone_number_id,
                                              },
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Space>
                                </Space>
                              </Col>
                            </Row>
                          </Card>
                        </Col>

                        <Col span={24}>
                          <Card type="inner" size="small">
                            <Space direction="vertical" size={0}>
                              <CustomTitle title="Insights this month:" />

                              <CustomParagraph
                                paragraph="You can view the usage of your WhatsApp Business Account for this month. This includes all conversation details. Ex: Free-tier conversation, paid conversation details."
                                urlLabel="Explore more"
                                urlValue={`${URIs.WABA}/wa/manage/insights`}
                              />
                            </Space>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <Row gutter={[0, 13]}>
                        <Col span={24}>
                          <Card type="inner">
                            <Text style={{ marginRight: "10px" }} strong>
                              <CustomTitle title="Connection Status:" />
                            </Text>

                            <Tag color="green">Connected</Tag>
                          </Card>
                        </Col>

                        <Col span={24}>
                          <Card type="inner">
                            <Text style={{ marginRight: "10px" }} strong>
                              <CustomTitle title="Phone Number:" />
                            </Text>

                            {!botToBeEdited.bot_channel_info.WHATSAPP
                              .waba_phone_number ? (
                              <Text type="secondary">
                                Phone Number will be updated shortly
                              </Text>
                            ) : (
                              <Text type="secondary">
                                {
                                  botToBeEdited.bot_channel_info.WHATSAPP
                                    .waba_phone_number
                                }
                              </Text>
                            )}
                            <br />
                            <CustomParagraph paragraph="Phone number to which the Bot is configured. Broadcast will be sent from this phone number. If you want to generate a QR code, please reach to our support." />
                          </Card>
                        </Col>

                        <Col span={24}>
                          <Card type="inner">
                            <Text style={{ marginRight: "10px" }} strong>
                              <CustomTitle title="API Key:" />
                            </Text>

                            {!maskApiKey ? (
                              <>
                                <Text type="secondary">{"*".repeat(30)}</Text>
                                <EyeOutlined
                                  style={{ marginLeft: 10 }}
                                  onClick={() => setMaskApiKey(true)}
                                />
                                <MdContentCopy
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: 10,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      botToBeEdited.bot_channel_info.WHATSAPP
                                        .waba_apikey
                                    );
                                    openNotificationWithIcon(
                                      "success",
                                      "API Key copied to clipboard"
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Text type="secondary">
                                  {botToBeEdited.bot_channel_info.WHATSAPP
                                    .waba_apikey ?? "-"}
                                </Text>
                                <EyeInvisibleOutlined
                                  style={{ marginLeft: 10 }}
                                  onClick={() => setMaskApiKey(false)}
                                />
                                <MdContentCopy
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: 10,
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      botToBeEdited.bot_channel_info.WHATSAPP
                                        .waba_apikey
                                    );
                                    openNotificationWithIcon(
                                      "success",
                                      "API Key copied to clipboard"
                                    );
                                  }}
                                />
                              </>
                            )}

                            <br />
                            <CustomParagraph paragraph="API Key for the WhatsApp Business Account. Please treat this as confidential and do not share." />
                          </Card>
                        </Col>

                        {!state.currentAccount.account.subscription_info
                          ?.subscription_ids?.length && (
                          <Card type="inner">
                            <Row gutter={[0, 2]}>
                              <Col span={24}>
                                <Text style={{ marginRight: "10px" }} strong>
                                  <CustomTitle title="Broadcast Limit :" />
                                </Text>
                                <Text type="secondary">
                                  {
                                    state.currentAccount.account.modules
                                      .broadcast.broadcast_limit
                                  }
                                </Text>
                              </Col>
                              <Col span={24}>
                                <CustomParagraph
                                  paragraph="Maximum messages that you can send in a month. To change this limit, please contact your account manager or raise a support request."
                                  urlLabel="Learn more"
                                  urlValue={`${URIs.DOCUMENTATIONS}/docs/channels/whatsapp/waba-intro.html`}
                                />
                              </Col>
                            </Row>
                          </Card>
                        )}

                        <Col span={24}>
                          <Card type="inner">
                            <Space direction="vertical" size={0}>
                              <Text style={{ marginRight: "10px" }} strong>
                                <CustomTitle title="Insights this month:" />
                              </Text>

                              <CustomParagraph paragraph="You can view the usage of your WhatsApp Business Account for this month. This includes the business-initiated and user-initiated messages, and the total price for the messages sent." />
                            </Space>

                            <ShowUsage />
                          </Card>
                        </Col>
                      </Row>
                    )
                  ) : (
                    // If WhatsApp is not yet configured
                    <Card type="inner">
                      <Row gutter={[0, 8]} align="middle">
                        <Col span={24}>
                          <Text style={{ marginRight: "10px" }} strong>
                            <CustomTitle title="Connection Status :" />
                          </Text>

                          <Tag color="red">Pending Configuration</Tag>
                        </Col>

                        {/* Configuring WhatsApp button */}
                        <Col span={24}>
                          <Space direction="vertical">
                            <CustomParagraph paragraph="WhatsApp is not yet configured. Click on the button below to configure WhatsApp." />

                            {botToBeEdited.bot_channel_info.WHATSAPP
                              .waba_provider === "INEXTLABS-CLOUD" ? (
                              <Button
                                type="primary"
                                onClick={() => {
                                  dispatchReducer({
                                    type: "OPEN_INFO_PANEL",
                                    payload: {
                                      component:
                                        "configure-whatsapp-info-panel",
                                    },
                                  });
                                }}
                              >
                                Configure WhatsApp
                              </Button>
                            ) : (
                              <ConnectButton
                                style={{
                                  backgroundColor: "#f44336",
                                  color: "white",
                                  border: "none",
                                  outline: "none",
                                  padding: "5px 20px",
                                  cursor: "pointer",
                                }}
                                callback={handleCallback}
                                partnerId={wabaProvider.DIALOG360.partnerId}
                                requestedNumber={
                                  botToBeEdited.bot_channel_info.WHATSAPP
                                    .waba_phone_number &&
                                  botToBeEdited.bot_channel_info.WHATSAPP.waba_phone_number.replace(
                                    "+",
                                    ""
                                  )
                                }
                                queryParameters={{
                                  next: botToBeEdited.bot_channel_info.WHATSAPP
                                    .waba_apikey
                                    ? "login"
                                    : "signup",
                                }}
                                label={"Configure WhatsApp"}
                              />
                            )}

                            <BotWHATSAPPOnboarding
                              botToBeEdited={botToBeEdited}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </Col>

                {botToBeEdited.bot_channel_info.WHATSAPP
                  .waba_integrated_onboarding && (
                  <Col span={8}>
                    <Space direction="vertical">
                      <CustomTitle title="Useful Links" />

                      <Collapse
                        defaultActiveKey={["1"]}
                        size="small"
                        expandIconPosition="end"
                        accordion
                        style={{
                          width: "100%",
                        }}
                        className="useful-links-collapse"
                      >
                        <Collapse.Panel
                          header={
                            <Space align="center">
                              <MdSettings
                                size={22}
                                style={{
                                  color: "#757373",
                                  marginBottom: -5,
                                }}
                              />
                              <CustomTitle title="Business Facebook Settings" />
                            </Space>
                          }
                          key="1"
                        >
                          <Row gutter={[0, 13]}>
                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space align="center">
                                  <MdPeopleAlt
                                    size={20}
                                    style={{
                                      color: "#ff0059",
                                      marginBottom: -3,
                                    }}
                                  />

                                  <CustomTitle title="Contributor Management" />
                                </Space>
                                <CustomParagraph
                                  paragraph="Efficiently invite and assign contributors to your business assets and partners."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/latest/settings/business_users`}
                                />
                              </Space>
                            </Col>

                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space align="center">
                                  <RiProfileFill
                                    size={20}
                                    style={{
                                      color: "#FFA500",
                                      marginBottom: -3,
                                    }}
                                  />

                                  <CustomTitle title="Profile Upgradation" />
                                </Space>

                                <CustomParagraph
                                  paragraph="Explore profile, boost conversation limits, set up auto welcome, enable two-step verification."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/wa/manage/phone-numbers/?phone_number=${formattedPhoneNumber}&childRoute=PHONE_PROFILE%2fPROFILE`}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Collapse.Panel>

                        <Collapse.Panel
                          header={
                            <Space>
                              <MdBusinessCenter
                                size={22}
                                style={{
                                  color: "#c4463b",
                                  marginBottom: -2,
                                }}
                              />
                              <CustomTitle title="Business Account" />
                            </Space>
                          }
                          key="2"
                        >
                          <Row gutter={[0, 13]}>
                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space>
                                  <MdOutlineBarChart
                                    size={20}
                                    style={{
                                      color: "#105dff",
                                      marginBottom: -4,
                                    }}
                                  />

                                  <CustomTitle title="Account Insights" />
                                </Space>

                                <CustomParagraph
                                  paragraph="Overview of your WhatsApp account, insights into this month's conversations, and free-tier conversation."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/wa/manage/home`}
                                />
                              </Space>
                            </Col>

                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space>
                                  <MdVerified
                                    size={20}
                                    style={{
                                      color: "#14ba17",
                                      marginBottom: -3,
                                    }}
                                  />
                                  <CustomTitle title="Verify Account" />
                                </Space>

                                <CustomParagraph
                                  paragraph="Users who verify their account with a green tick and integrate with the WhatsApp Business Platform can download a certificate."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/wa/manage/phone-numbers`}
                                />
                              </Space>
                            </Col>

                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space>
                                  <FaQrcode
                                    size={19}
                                    style={{
                                      color: "black",
                                      marginBottom: -3,
                                    }}
                                  />
                                  <CustomTitle title="Generate QR Code" />
                                </Space>
                                <CustomParagraph
                                  paragraph="Generate a QR code for your WhatsApp number to share with your customers."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/wa/manage/phone-numbers/?phone_number=${formattedPhoneNumber}&childRoute=PHONE_PROFILE%2fMESSAGE_QR`}
                                />
                              </Space>
                            </Col>

                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space>
                                  <BiSolidMessageDots
                                    size={20}
                                    style={{
                                      color: "#2ca5c7",
                                      marginBottom: -5,
                                    }}
                                  />
                                  <CustomTitle title="Conversation Insights" />
                                </Space>

                                <CustomParagraph
                                  paragraph="List your conversations and check whether messages have been sent, delivered, or received."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/wa/manage/insights`}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Collapse.Panel>

                        <Collapse.Panel
                          header={
                            <Space>
                              <MdManageAccounts
                                size={23}
                                style={{
                                  color: "#930cf6",
                                  marginBottom: -5,
                                }}
                              />
                              <CustomTitle title="Commerce Manager" />
                            </Space>
                          }
                          key="3"
                        >
                          <Row gutter={[0, 13]}>
                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space>
                                  <RiContactsBookUploadFill
                                    size={19}
                                    style={{
                                      color: "#25a325",
                                      marginBottom: -3,
                                    }}
                                  />
                                  <CustomTitle title="Create Catalogue" />
                                </Space>

                                <CustomParagraph
                                  paragraph="A catalogue serves as a container for advertising or selling items. Use Commerce Manager to create and manage catalogues for various item."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/products/catalogs/new`}
                                />
                              </Space>
                            </Col>

                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Space>
                                  <PiBooksBold
                                    size={21}
                                    style={{
                                      color: "#eb3636",
                                      marginBottom: -3,
                                    }}
                                  />
                                  <CustomTitle title="Manage Catalogues" />
                                </Space>
                                <CustomParagraph
                                  paragraph="Assist to selling your items on Facebook and Instagram. During setup, you'll be able to connect an existing catalogue of inventory that you'd like to sell, or create a new one."
                                  urlLabel="Explore more"
                                  urlValue={`${URIs.WABA}/commerce`}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </Space>
                  </Col>
                )}
              </Row>
            )
          ) : (
            <Result
              icon={
                <Image
                  src={channels["WHATSAPP"].logo}
                  preview={false}
                  width={75}
                />
              }
              title={channels["WHATSAPP"].displayName}
              style={{ width: 700 }}
              extra={
                <Space direction="vertical" size={20}>
                  <Text type="secondary">
                    With the capability of instantly reaching global audiences
                    at scale, WhatsApp business is the best thing for your
                    business to utilize its full potential of WhatsApp. Leverage
                    the power of iNextLabs' advanced conversational AI on the
                    world's most popular channel-WhatsApp. Create conversational
                    marketing campaigns, set up your eCommerce store, facilitate
                    payments and deliver top-notch support. Expand your customer
                    service reach with iNextLabs' WhatsApp-enabled customer
                    engagement solution and ensure faster and more engaging
                    customer service.
                  </Text>

                  <Button
                    type="primary"
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_SEND_REQUEST",
                        payload: {
                          channel: "WHATSAPP",
                        },
                      });
                    }}
                  >
                    Request to enable {channels["WHATSAPP"].displayName}
                  </Button>
                </Space>
              }
            />
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotWHATSAPP;
