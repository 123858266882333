import { useGlobalContext } from "../../../contexts/globalContext";
import { Table } from "antd";
import { useWindowSize } from "../../../shared-components/PageReSize";
import useWhatsAppFlowsTableColumns from "./WhatsAppFlowsTableColumns";

const WhatsAppFlowsTable = () => {
  const [state] = useGlobalContext();
  const [height] = useWindowSize();
  const { dataColumns, actionColumns } = useWhatsAppFlowsTableColumns();

  const searchText =
    state.filters.searchText["whatsapp-flows-search-text-box"] &&
    state.filters.searchText["whatsapp-flows-search-text-box"];

  let filteredWhatsAppFlows = state.whatsappFlows?.flows;

  if (searchText) {
    filteredWhatsAppFlows = state.whatsappFlows?.flows.filter(
      (whatsappFlow) => {
        return (
          whatsappFlow.name &&
          whatsappFlow.name.toLowerCase().includes(searchText.toLowerCase())
        );
      }
    );
  }

  return (
    <Table
      columns={dataColumns.concat(actionColumns)}
      dataSource={filteredWhatsAppFlows}
      pagination={{
        pageSize: Math.round((height - 250) / 55),
        showTotal: () => "Total : " + filteredWhatsAppFlows?.length || 0,
      }}
      rowKey="name"
    />
  );
};

export default WhatsAppFlowsTable;
