import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { appInsights } from "../AppInsights";
import { mode, URIs } from "../constants/env";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";

export const useBotKnowledgebaseMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    const qna = action.payload.botInAction?.qna;

    if (qna) {
      const knowledgebaseTestConversationID = localStorage.getItem(
        "knowledgebaseTestConversationID"
      );

      switch (action.type) {
        //Load knowledgebase data
        case "LOAD_BOT_KNOWLEDGEBASE_DETAILS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              apiEndPoints.BOT_KNOWLEDGEBASE.concat(
                "&data_source=" +
                  qna.data_source +
                  "&index_name=" +
                  qna.search_index_name +
                  "&search_name=" +
                  qna.search_name
              ),
            ],
            options: {
              method: "GET",
              isTokenRequired: false,
              header: {
                "search-api-key": qna.search_api_key,
              },
            },
            onSuccess: ([knowledgebaseFiles]) => {
              dispatchReducer({
                type: "SET_KNOWLEDGEBASE_FILES",
                payload: {
                  knowledgebaseFiles,
                },
              });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Load Knowledgebase Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        // Upload file
        case "ADD_KNOWLEDGEBASE_FILE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          const {
            knowledgebaseName,
            knowledgebaseFile,
            knowledgebaseVersion,
            tags,
          } = action.payload;
          const formData = new FormData();
          formData.append("file", knowledgebaseFile);
          formData.append("kb_name", knowledgebaseName);
          formData.append("kb_version", knowledgebaseVersion);
          formData.append("tags", JSON.stringify(tags));
          formData.append("page_to_delete", JSON.stringify([]));

          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE_UPLOAD.concat("&v=1.0")],
            options: {
              method: "POST",
              body: formData,
              contentType: "auto",
              isTokenRequired: false,
            },
            onSuccess: async ([uploadedFile]) => {
              dispatchReducer({
                type: "ADD_KNOWLEDGEBASE_FILE",
                payload: {
                  uploadedFile: {
                    ...uploadedFile,
                    formValues: {
                      knowledgebaseName,
                      knowledgebaseFile,
                      knowledgebaseVersion,
                      tags,
                    },
                  },
                },
              });

              openNotificationWithIcon(
                "success",
                "Knowledgebase File Processed Successfully"
              );

              dispatchReducer({
                type: "SET_CURRENT_STEP",
                payload: 2,
              });

              appInsights.trackEvent({
                name: "Bot Knowledgebase - File Upload",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account.general?.company,
                  fileName: action.payload.knowledgebaseFile.name,
                  fileSize: action.payload.knowledgebaseFile.size,
                  fileType: action.payload.knowledgebaseFile.type,
                },
              });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Knowledgebase File Upload Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        // Review file
        case "REVIEW-SUBMIT_KNOWLEDGEBASE_FILE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE],
            options: {
              method: "POST",
              header: {
                "openai-api-key": qna.openai_api_key,
                "search-api-key": qna.search_api_key,
              },
              body: {
                ...action.payload.formValues,
                data_source: qna.data_source,
                user_id: state.currentUser.user.userID,
                index_name: qna.search_index_name,
                mongodb_collection_name: qna.mongodb_collection_name,
                openai_service_name: qna.search_openai_servicename,
                openai_embeddings_name: qna.search_openai_embeddingname,
                search_name: qna.search_name,
              },
              isTokenRequired: false,
            },
            onSuccess: async ([reviewedFile]) => {
              dispatchReducer({
                type: "ADD_REVIEWED_KNOWLEDGEBASE_FILE",
                payload: {
                  reviewedFile,
                },
              });

              dispatchReducer({
                type: "ADD_KNOWLEDGEBASE_FILE",
                payload: {
                  uploadedFile: null,
                },
              });

              localStorage.setItem(
                "knowledgebaseTestConversationID",
                "user" + Date.now()
              );
              openNotificationWithIcon(
                "success",
                "Knowledgebase File Uploaded Successfully"
              );

              dispatchReducer({
                type: "CLOSE_FORM_PANEL",
                payload: {
                  component: "knowledgebase-form-panel",
                },
              });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });

              appInsights.trackEvent(action.payload.appinsightsLogs);
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Knowledgebase Review File Upload Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        case "TEST_BOT_KNOWLEDGEBASE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });

          const bodyContent = {
            data_source: qna.data_source,
            openai_service_name: qna.search_openai_servicename,
            openai_completion_name: qna.openai_completion_name,
            search_name: qna.search_name,
            search_response_count: 2,
            system_prompt: qna.qna_system_message,
            customer_name: state.currentAccount.account.general?.company,
            openai_embeddings_name: qna.search_openai_embeddingname,
            temperature: 0,
            max_tokens: 200,
            top_p: 1,
            past_message_count: 5,
            mongodb_collection_name: qna.mongodb_collection_name,
            question: action.payload.query,
            index_name: qna.search_index_name,
            is_published: action.payload.status,
          };

          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE_TEST],
            options: {
              method: "POST",
              header: {
                "search-api-key": qna.search_api_key,
                "openai-api-key": qna.openai_api_key,
                "conversation-id": knowledgebaseTestConversationID,
                "client-id": state.currentAccount.account.client_id,
              },
              body: JSON.stringify(bodyContent),
              contentType: "application/json",
              isTokenRequired: false,
            },
            onSuccess: ([testResult]) => {
              dispatchReducer({
                type: "TEST_BOT_KNOWLEDGEBASE",
                payload: { testResult: testResult },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
              appInsights.trackEvent({
                name: "Bot Knowledgebase - Test",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account.general?.company,
                  question: action.payload.query,
                  tokenUsed: testResult.total_tokens,
                  isPublished: action.payload.status,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Test knowledgebase error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        case "CHANGE_BOT_KNOWLEDGEBASE_STATUS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });

          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE_STATUS],
            options: {
              method: "POST",
              header: {
                "search-api-key": qna.search_api_key,
              },
              body: {
                kb_id: action.payload.id,
                data_source: qna.data_source,
                index_name: qna.search_index_name,
                search_name: qna.search_name,
                is_published: action.payload.status,
              },
              isTokenRequired: false,
            },
            onSuccess: ([knowledgeBaseStatus]) => {
              dispatchReducer({
                type: "SET_KNOWLEDGEBASE_STATUS",
                payload: { knowledgeBaseStatus, kb_id: action.payload.id },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
              openNotificationWithIcon(
                "success",
                action.payload.status
                  ? "Knowledgebase Published Successfully"
                  : "Knowledgebase Unpublished Successfully"
              );

              appInsights.trackEvent({
                name: "Bot Knowledgebase - Publish/Unpublish",
                properties: {
                  status:
                    action.payload.status === true
                      ? "Published"
                      : "Unpublished",
                  kbId: action.payload.id,
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account.general?.company,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: action.payload.status
                    ? "Publish knowledgebase error"
                    : "Unpublish knowledgebase error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        case "DELETE_KNOWLEDGEBASE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          const deleteBodyContent = {
            kb_id: action.payload.actionRowKey,
            data_source: qna.data_source,
            index_name: qna.search_index_name,
            search_name: qna.search_name,
          };
          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE],
            options: {
              method: "DELETE",
              header: {
                "search-api-key": qna.search_api_key,
              },
              body: deleteBodyContent,
              isTokenRequired: false,
            },
            onSuccess: () => {
              dispatchReducer({
                type: "DELETE_BOT_KNOWLEDGEBASE",
                payload: {
                  kb_id: action.payload.actionRowKey,
                },
              });
              localStorage.setItem(
                "knowledgebaseTestConversationID",
                "user" + Date.now()
              );
              openNotificationWithIcon(
                "success",
                "Knowledgebase Deleted Successfully"
              );
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });

              appInsights.trackEvent({
                name: "Bot Knowledgebase - Delete",
                properties: {
                  kbId: action.payload.actionRowKey,
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account.general?.company,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Delete knowledgebase error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        //Load single knowledgebase data
        case "LOAD_SINGLE_BOT_KNOWLEDGEBASE_DETAILS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              URIs.BOT_KNOWLEDGEBASE_FUNCTION_APP.replace(
                "{apiEndPoint}",
                "/api/knowledgebase/" + action.payload.actionRowKey
              ) +
                "&data_source=" +
                qna.data_source +
                "&index_name=" +
                qna.search_index_name +
                "&search_name=" +
                qna.search_name,
            ],
            options: {
              method: "GET",
              isTokenRequired: false,
              header: {
                "search-api-key": qna.search_api_key,
              },
            },
            onSuccess: ([singleBotKnowledgebaseFile]) => {
              dispatchReducer({
                type: "SET_SINGLE_BOT_KNOWLEDGEBASE_FILE",
                payload: {
                  singleBotKnowledgebaseFile,
                },
              });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });

              appInsights.trackEvent({
                name: "Bot Knowledgebase - Load Single Knowledgebase",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account.general?.company,
                  kbName: action.payload.title,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Load Single Knowledgebase Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        // Add_Edit bot kb section
        case "ADD_EDIT_BOT_KNOWLEDGEBASE_SECTION":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE_SECTION],
            options: {
              method: "PUT",
              header: {
                "openai-api-key": qna.openai_api_key,
                "search-api-key": qna.search_api_key,
              },
              body: {
                ...(action.payload.formValues || {}),
                kb_id:
                  state.filters.actionRowKey?.["bot-knowledgebase-info-panel"],
                section_id:
                  state.form["bot-knowledgebase-section-form"]?.mode === "edit"
                    ? state.filters.actionRowKey?.[
                        "bot-knowledgebase-edit-section"
                      ]
                    : "",
                data_source: qna.data_source,
                index_name: qna.search_index_name,
                search_name: qna.search_name,
                openai_service_name: qna.search_openai_servicename,
                openai_embeddings_name: qna.search_openai_embeddingname,
              },
              isTokenRequired: false,
            },
            onSuccess: ([botKnowledgebaseSection]) => {
              if (
                state.form["bot-knowledgebase-section-form"]?.mode === "add"
              ) {
                dispatchReducer({
                  type: "ADD_BOT_KNOWLEDGEBASE_SECTION",
                  payload: {
                    botKnowledgebaseSection,
                  },
                });

                appInsights.trackEvent({
                  name: "Bot Knowledgebase - Add Section",
                  properties: {
                    environment: mode,
                    clientId: state.currentAccount.account.client_id,
                    clientName: state.currentAccount.account.general?.company,
                    sectionTitle: botKnowledgebaseSection.section_title,
                  },
                });
              } else {
                dispatchReducer({
                  type: "EDIT_BOT_KNOWLEDGEBASE_SECTION",
                  payload: {
                    botKnowledgebaseSection,
                  },
                });

                dispatchReducer({
                  type: "RD_CLEAR_ACTION_ROW_KEY",
                  payload: { component: "bot-knowledgebase-edit-section" },
                });

                appInsights.trackEvent({
                  name: "Bot Knowledgebase - Edit Section",
                  properties: {
                    environment: mode,
                    clientId: state.currentAccount.account.client_id,
                    clientName: state.currentAccount.account.general?.company,
                    sectionTitle: botKnowledgebaseSection.section_title,
                  },
                });
              }

              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "bot-knowledgebase-section-form",
                  mode: "",
                },
              });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });

              openNotificationWithIcon(
                "success",
                "Knowledgebase File Modified Successfully"
              );
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Edit Knowledgebase Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        // Delete knowledgebase section
        case "DELETE_BOT_KNOWLEDGEBASE_SECTION":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });

          await callApi({
            urls: [apiEndPoints.BOT_KNOWLEDGEBASE_SECTION],
            options: {
              method: "DELETE",
              header: {
                "search-api-key": qna.search_api_key,
              },
              body: {
                kb_id:
                  state.filters.actionRowKey?.["bot-knowledgebase-info-panel"],
                section_id: action.payload.section_id,
                data_source: qna.data_source,
                index_name: qna.search_index_name,
                search_name: qna.search_name,
              },
              isTokenRequired: false,
            },
            onSuccess: () => {
              dispatchReducer({
                type: "DELETE_BOT_KNOWLEDGEBASE_SECTION",
                payload: {
                  section_id: action.payload.section_id,
                },
              });
              localStorage.setItem(
                "knowledgebaseTestConversationID",
                "user" + Date.now()
              );
              openNotificationWithIcon(
                "success",
                "Knowledgebase Section Deleted Successfully"
              );
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });

              appInsights.trackEvent({
                name: "Bot Knowledgebase - Delete Section",
                properties: {
                  sectionId: action.payload.section_id,
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account.general?.company,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Delete knowledgebase section error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;
      }
    }
  };
};
