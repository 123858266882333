import React, { useState } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Space,
  Typography,
  Row,
  Col,
  Image,
  Avatar,
  Tooltip,
  Dropdown,
} from "antd";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  MdOutlineDone,
  MdOutlineDoneAll,
  MdOutlineError,
} from "react-icons/md";
import { BiSolidComponent } from "react-icons/bi";
import {
  getUserDateTime,
  calculateDateTimeToBeDisplayed,
} from "../../utils/utility";
import {
  botProfilePictures,
  mimeToFileType,
  fileIcon,
} from "../../constants/enums";
import ConversationINSTAGRAM from "./ConversationINSTAGRAM";
import ConversationWHATSAPP from "./ConversationWHATSAPP";
import ConversationWEB from "./ConversationWEB";
import ConversationGBM from "./ConversationGBM";

const { Text } = Typography;

const ConversationBubble = ({ transcript, channel }) => {
  const [state] = useGlobalContext();
  const [hoveredLiveAgentMessage, setHoveredLiveAgentMessage] = useState(null);

  // Based on the file type, we are showing the file icon
  const fileType =
    // Check if the message is a document
    transcript.type === "document" && typeof transcript.message === "object"
      ? // Message from agent
        transcript.message.type.includes("application")
        ? // Compare the mime type with the file type
          mimeToFileType[transcript.message.type?.toLowerCase()]
        : transcript.message.type?.split(".").pop().toLowerCase()
      : // Message from user, after refreshing the page
        (transcript.content_url || transcript.message)
          ?.split(".")
          .pop()
          .toLowerCase();

  // set delivery status order
  if (
    Array.isArray(transcript.deliveryStatus) &&
    transcript.deliveryStatus?.length
  ) {
    const statusOrder = { sent: 1, delivered: 2, read: 3 };
    transcript.deliveryStatus = transcript.deliveryStatus?.sort(
      (a, b) => statusOrder[a.status] - statusOrder[b.status]
    );
  }

  // Show message info
  const DeliveryStatusPanel = () => {
    return (
      <Space
        style={{
          width: 260,
          borderRadius: 5,
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          background: "white",
          padding: 12,
          marginBottom: 5,
        }}
        direction="vertical"
      >
        <Row>
          {/* Read */}
          <Col span={4}>
            <MdOutlineDoneAll style={{ fontSize: 20, color: "#3186f5" }} />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: 12 }}>Read</Text>
          </Col>
          <Col span={12}>
            <Text style={{ fontSize: 12 }} ellipsis>
              {(() => {
                const readStatus = transcript.deliveryStatus?.find(
                  ({ status }) => status?.toUpperCase() === "READ"
                );
                return readStatus
                  ? calculateDateTimeToBeDisplayed(
                      readStatus.timestamp,
                      state.currentUser.user.timeZone
                    )
                  : "-";
              })()}
            </Text>
          </Col>

          {/* Delivered */}
          <Col span={4}>
            <MdOutlineDoneAll style={{ fontSize: 20 }} />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: 12 }}>Delivered</Text>
          </Col>
          <Col span={12}>
            <Text style={{ fontSize: 12 }} ellipsis>
              {(() => {
                const deliveredStatus = transcript.deliveryStatus?.find(
                  ({ status }) =>
                    status?.toUpperCase() === "DELIVERED" ||
                    status?.toUpperCase() === "READ"
                );
                return deliveredStatus
                  ? calculateDateTimeToBeDisplayed(
                      deliveredStatus.timestamp,
                      state.currentUser.user.timeZone
                    )
                  : "-";
              })()}
            </Text>
          </Col>
        </Row>
      </Space>
    );
  };

  return (
    <Space
      style={{
        width: "100%",
        justifyContent: transcript.is_from_user ? "left" : "right",
      }}
    >
      <Row gutter={8}>
        <Col
          onMouseEnter={() => {
            // Show message info only for live agent messages, and only for WhatsApp, and only if there is delivery status
            state.conversations.currentConversation.channel?.toUpperCase() ===
              "WHATSAPP" &&
              transcript.is_agent &&
              Array.isArray(transcript.deliveryStatus) &&
              transcript.deliveryStatus.length &&
              setHoveredLiveAgentMessage(transcript.messageId);
          }}
          onMouseLeave={() => {
            setHoveredLiveAgentMessage(null);
          }}
        >
          {/* Message info */}
          {hoveredLiveAgentMessage === transcript?.messageId && (
            <Dropdown
              trigger={["click"]}
              dropdownRender={() => <DeliveryStatusPanel />}
              placement="topRight"
            >
              <InfoCircleOutlined
                style={{
                  margin: "2px 6px 0 0",
                  color: "#848484",
                  fontSize: 16,
                }}
              />
            </Dropdown>
          )}

          <Space
            direction="vertical"
            size={0}
            style={{
              backgroundColor: transcript.is_from_user
                ? "#FFFFFF"
                : transcript.is_agent
                ? "#f5f5dd"
                : "#E0FCD6",
              padding: "5px 10px",
              borderRadius: 5,
              minWidth: 100,
              maxWidth: 550,
            }}
          >
            {["whatsapp", "instagram", "web", "gbm"].includes(
              channel.toLowerCase()
            ) &&
            (transcript.type === "interactive" ||
              transcript.type === "richCard" ||
              transcript.type === "application/vnd.microsoft.card.adaptive" ||
              transcript.type === "application/vnd.microsoft.card.hero" ||
              transcript.type === "application/vnd.microsoft.card.thumbnail" ||
              // For WhatsApp flows the message is sent as a stringified JSON object
              (transcript.message?.[0] === "{" &&
                transcript.message[transcript.message.length - 1] === "}")) ? (
              channel === "INSTAGRAM" ? (
                <div style={{ overflowX: "scroll" }}>
                  <Space>
                    <ConversationINSTAGRAM transcript={transcript} />
                  </Space>
                </div>
              ) : channel === "WHATSAPP" ? (
                <div style={{ overflow: "auto" }}>
                  <Space direction="vertical">
                    <ConversationWHATSAPP transcript={transcript} />
                  </Space>
                </div>
              ) : channel === "FACEBOOK" ? (
                <Space>Facebook Component goes here</Space>
              ) : channel === "WEB" ? (
                <div style={{ overflow: "auto" }}>
                  <Space>
                    <ConversationWEB transcript={transcript} />
                  </Space>
                </div>
              ) : channel === "GBM" ? (
                <div style={{ overflowX: "scroll" }}>
                  <Space>
                    <ConversationGBM transcript={transcript} />
                  </Space>
                </div>
              ) : (
                channel === "AMB" && <Space>AMB Component goes here</Space>
              )
            ) : (
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {transcript.type === "text" ||
                transcript.type === "text/html" ? (
                  <Text>{transcript.message}</Text>
                ) : ["image", "video", "document"].includes(transcript.type) ? (
                  <Space
                    direction="vertical"
                    style={{
                      width:
                        transcript.type === "document" &&
                        transcript.is_from_user
                          ? 180
                          : 300,
                      border: "1px solid #C0C0C0",
                      padding: 10,
                      borderRadius: 10,
                    }}
                  >
                    {transcript.type === "image" ? (
                      <Image
                        // While sending image, we are sending message as image url
                        // From backend, we are using image url as content_url
                        src={transcript.content_url || transcript.message}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    ) : transcript.type === "video" ? (
                      <video
                        width="100%"
                        controls
                        src={transcript.content_url || transcript.message}
                      />
                    ) : transcript.type === "document" ? (
                      <Space
                        size={10}
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const link = document.createElement("a");
                          link.target = "_blank";

                          // For sending document, we are sending message as file object
                          // From backend, we are using file url as message
                          if (typeof transcript.message === "object") {
                            link.href = URL.createObjectURL(transcript.message);
                            link.download = transcript.message.name;
                          } else {
                            link.href = transcript.content_url;
                          }
                          document.body.appendChild(link);

                          link.click();
                          document.body.removeChild(link);
                        }}
                      >
                        <Image
                          src={
                            fileIcon[fileType] ||
                            "/images/document-placeholder.png"
                          }
                          height={30}
                          preview={false}
                        />

                        {typeof transcript.message === "object" ? (
                          <Text>
                            {/* Remove the file extension from the file name */}
                            {transcript.message.name
                              .split(".")
                              .slice(0, -1)
                              .join(".")}
                          </Text>
                        ) : transcript.is_agent ? (
                          (() => {
                            // Extract the file name from the URL
                            // Extract the file name from the URL
                            let extractedFileName = transcript.content_url
                              ?.split("/")
                              .pop()
                              .replace(/^laa_\d+_/, "")
                              .replace(/%20/g, " ");

                            // Remove everything after and including the last dot
                            const lastDotIndex =
                              extractedFileName.lastIndexOf(".");
                            extractedFileName = extractedFileName.slice(
                              0,
                              lastDotIndex
                            );

                            return <Text>{extractedFileName}</Text>;
                          })()
                        ) : (
                          `Document`
                        )}
                      </Space>
                    ) : (
                      ""
                    )}
                  </Space>
                ) : (
                  <Space
                    style={{
                      border: "1px solid #C0C0C0",
                      padding: "10px 20px 10px 10px",
                      borderRadius: 10,
                    }}
                    direction="vertical"
                  >
                    <Space>
                      <BiSolidComponent
                        style={{
                          fontSize: 20,
                          color: "#F05742",
                          marginBottom: -5,
                        }}
                      />
                      <Text strong>Interactive Message Displayed</Text>
                    </Space>

                    {transcript.altText}
                  </Space>
                )}
              </Space>
            )}

            {/* Handle sending, sent message  */}
            <Space
              style={{
                width: "100%",
                justifyContent: "right",
              }}
            >
              {transcript.isSending ? (
                <Text style={{ fontSize: 10 }} type="secondary">
                  sending...
                </Text>
              ) : (
                <Space>
                  {transcript.isSendFailed ? (
                    <Tooltip title="Message failed to send">
                      <MdOutlineError
                        style={{
                          marginBottom: -5,
                          color: "red",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <>
                      <Text style={{ fontSize: 10 }} type="secondary">
                        {getUserDateTime(
                          transcript.date,
                          state.currentUser.user.timeZone,
                          "DD/MM/yyyy - hh:mm a"
                        )}
                      </Text>

                      {state.conversations.currentConversation.channel?.toUpperCase() ===
                        "WHATSAPP" && (
                        <Space
                          style={{
                            marginTop: 3,
                          }}
                        >
                          {Array.isArray(transcript.deliveryStatus) &&
                            transcript.deliveryStatus.length && (
                              <Tooltip
                                title={`Message ${transcript.deliveryStatus[
                                  transcript.deliveryStatus.length - 1
                                ].status?.toLowerCase()}`}
                              >
                                {transcript.deliveryStatus[
                                  transcript.deliveryStatus.length - 1
                                ].status?.toUpperCase() === "SENT" ? (
                                  <MdOutlineDone
                                    style={{
                                      fontSize: 14,
                                      color: "#6C6B6B",
                                    }}
                                  />
                                ) : transcript.deliveryStatus[
                                    transcript.deliveryStatus.length - 1
                                  ].status?.toUpperCase() === "DELIVERED" ? (
                                  <MdOutlineDoneAll
                                    style={{
                                      fontSize: 14,
                                      color: "#6C6B6B",
                                    }}
                                  />
                                ) : transcript.deliveryStatus[
                                    transcript.deliveryStatus.length - 1
                                  ].status?.toUpperCase() === "READ" ? (
                                  <MdOutlineDoneAll
                                    style={{
                                      fontSize: 14,
                                      color: "#3186f5",
                                    }}
                                  />
                                ) : (
                                  <MdOutlineError
                                    style={{
                                      fontSize: 14,
                                      color: "red",
                                    }}
                                  />
                                )}
                              </Tooltip>
                            )}
                        </Space>
                      )}
                    </>
                  )}
                </Space>
              )}
            </Space>
          </Space>
        </Col>

        <Col>
          <Space>
            {!transcript.is_from_user && !transcript.is_agent && (
              <Avatar
                size="large"
                src={
                  <Image
                    preview={false}
                    src={
                      botProfilePictures[
                        state.currentAccount.account.bots.find(
                          (bot) =>
                            bot.bot_id ===
                            state.conversations.currentConversation.bot_id
                        )?.bot_icon
                      ]
                    }
                  />
                }
              />
            )}

            {!transcript.is_from_user &&
              transcript.is_agent &&
              (transcript.agent_id &&
              state.accountUsers.users &&
              state.accountUsers.users.find(
                (user) => user.userID === transcript.agent_id
              )?.profilePicture ? (
                <Avatar
                  size="large"
                  src={
                    <Image
                      preview={false}
                      src={state.currentUser.user.profilePicture}
                    />
                  }
                />
              ) : (
                <Avatar
                  size="large"
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#B0B0B0" }}
                />
              ))}
          </Space>
        </Col>
      </Row>
    </Space>
  );
};

export default ConversationBubble;
