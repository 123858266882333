const notificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_ALL_NOTIFICATIONS":
      return {
        ...state,
        notification: {
          ...state.notification,
          notifications: action.payload.notifications,
        },
      };

    case "SET_UPDATED_NOTIFICATION_STATUS":
      return {
        ...state,
        notification: {
          ...state.notification,
          notifications: {
            ...state.notification.notifications,
            data: state.notification?.notifications.data.filter(
              (notification) =>
                notification._id !== action.payload.notification?._id
            )?.length
              ? [
                  ...state.notification.notifications.data.filter(
                    (notification) =>
                      notification._id !== action.payload.notification?._id
                  ),
                  action.payload.notification,
                ]
              : [action.payload.notification],
          },
        },
      };

    case "SET_REMOVE_NOTIFICATION":
      return {
        ...state,
        notification: {
          ...state.notification,
          ...{
            notifications: {
              ...state.notifications?.notifications,
              data: state.notification?.notifications.data.filter(
                (notification) => notification._id !== action.payload?._id
              ),
            },
          },
        },
      };

    case "SET_REMOVE_ALL_NOTIFICATIONS":
      return {
        ...state,
        notification: {
          ...state.notification,
          notifications: {
            data: action.payload.notifications,
          },
        },
      };
    default:
      return state;
  }
};

export default notificationReducer;
