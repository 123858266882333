import React, { useState } from "react";
import { Button, Space, Modal, Table, Upload, Typography } from "antd";
import Papa from "papaparse";
import { importContactsMaxSize } from "../../constants/enums";

const { Text } = Typography;

const CSVFileUpload = ({
  importedDataSource,
  handleGetParsedData,
  handleCancelImport,
  setData,
  data,
  setColumns,
  columns,
  setFileSize,
  source,
  // Used from import contacts page
  csvFile,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataFileName, setDataFileName] = useState(null);

  const handleImageUpload = (e) => {
    setDataFileName(e.file.name);

    Papa.parse(e.file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        parseFile(results, e.file);
      },
    });
  };

  const parseFile = (results, dataFile) => {
    // Filtered Column Names
    const columns = results.meta.fields.map((headerColumn) => {
      const columnObject = {};
      columnObject["title"] = headerColumn;
      columnObject["key"] = "{" + headerColumn + "}";
      columnObject["dataIndex"] = headerColumn;
      return columnObject;
    });

    const data = results.data;

    setColumns(columns);
    setData(data);

    handleGetParsedData(columns, data, "csv", dataFile);
  };

  return (
    <>
      {data?.length > 0 && importedDataSource === "csv" ? (
        <>
          <Space
            className="label-file-upload uploadComplete"
            direction="vertical"
            size={10}
          >
            <div>
              <b>{data.length} record(s)</b> fetched from <br />"
              {dataFileName || csvFile?.name}"
            </div>
            <Space>
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Preview
              </Button>
              <Button
                onClick={() => {
                  setData([]);
                  handleCancelImport();
                  setFileSize();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Space>
          <Modal
            title={"Preview (" + data.length + " Records)"}
            cancelButtonProps={{ style: { display: "none" } }}
            open={isModalVisible}
            onOk={() => {
              setIsModalVisible(false);
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            width="1250px"
          >
            <div style={{ maxHeight: 400, overflow: "auto" }}>
              <Table
                dataSource={data}
                columns={columns}
                pagination={{ position: ["none", "none"], defaultPageSize: 0 }}
                scroll={{ y: 240, x: "100vw" }}
                size="small"
                rowKey={Math.random()}
              />
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Space className="label-file-upload">
            <Upload
              name="data-file-upload"
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.readAsText(file);

                setFileSize(file.size);

                return (
                  source === "contacts" &&
                  file.size > importContactsMaxSize &&
                  true
                );
              }}
              showUploadList={false}
              maxCount={1}
              accept=".csv"
              onChange={handleImageUpload}
            >
              <Space direction="vertical" size={0}>
                <Text>Click here to upload your</Text>
                <Text strong>CSV File</Text>
              </Space>
            </Upload>
          </Space>
        </>
      )}
    </>
  );
};

export default CSVFileUpload;
