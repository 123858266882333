import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Input,
  Col,
  Row,
  Button,
  Tooltip,
  Form,
  Select,
  Card,
  Typography,
  Radio,
  Space,
  Divider,
  Tag,
  Image,
  Dropdown,
  Checkbox,
  Modal,
  Collapse,
  Empty,
} from "antd";
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineStrikethrough,
} from "react-icons/ai";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  HiOutlineTemplate,
  HiOutlineQuestionMarkCircle,
  HiOutlineEmojiHappy,
} from "react-icons/hi";
import { FaExchangeAlt } from "react-icons/fa";
import {
  MdOutlineTypeSpecimen,
  MdMessage,
  MdOutlineDone,
  MdClose,
} from "react-icons/md";
import { TbCategory } from "react-icons/tb";
import { LuLanguages } from "react-icons/lu";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { PiSquaresFour } from "react-icons/pi";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { templateLanguages, wabaProvider } from "../../../constants/enums";
import { Translation } from "../../../translation/translation";
import openNotificationWithIcon from "../../../utils/notification";
import {
  FullPageLoader,
  TabLoader,
  ComponentLoader,
} from "../../../shared-components/LoadingIndicator";
import parsePhoneNumber from "libphonenumber-js";
import "./MessageTemplates.less";
import { appInsights } from "../../../AppInsights";
import { mode } from "../../../constants/env";
import MessageTemplatePreviewPanel from "./MessageTemplatePreviewPanel";
import {
  CustomTitle,
  CustomParagraph,
} from "../../../shared-components/CustomTyphography";
import { URIs } from "../../../constants/env";

const { Text, Link } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const MessageTemplateForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // Get translated text
  const templateForm = Translation().messageTemplates.formPanel.form;

  const [channel] = useState("WHATSAPP");

  // For Form
  const [template, setTemplate] = useState(null);
  const [templateCategory, setTemplateCategory] = useState("MARKETING");
  const [templateType, setTemplateType] = useState("textOnly");
  const [headerType, setHeaderType] = useState("text");
  const [buttons, setButtons] = useState({});
  const [buildingBlocks, setBuildingBlocks] = useState([
    "HEADER",
    "BODY",
    "FOOTER",
    "BUTTONS",
  ]);
  const [carouselTemplate, setCarouselTemplate] = useState({});
  const [limitedTimeOfferTemplate, setLimitedTimeOfferTemplate] = useState({
    hasExpiration: true,
  });

  // For Preview
  const [templateBody, setTemplateBody] = useState(null);
  const [templateHeader, setTemplateHeader] = useState(null);
  const [templateFooter, setTemplateFooter] = useState(null);
  const [templateButtons, setTemplateButtons] = useState(null);
  const [templateCarouselCards, setTemplateCarouselCards] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [preview, setPreview] = useState(null);

  // For Mapping values
  const [headerMapping, setHeaderMapping] = useState(null);
  const [headerPlaceHolders, setHeaderPlaceHolders] = useState(null);
  const [bodyMapping, setBodyMapping] = useState(null);
  const [urlMapping, setUrlMapping] = useState(null);
  const [bodyPlaceHolders, setBodyPlaceHolders] = useState(null);
  const [urlPlaceHolders, setUrlPlaceHolders] = useState(null);

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const resetMapping = () => {
    setHeaderMapping(null);
    setBodyMapping(null);
    setCarouselTemplate({
      carouselCards: [],
    });
    form.resetFields([
      "templateHeader",
      "templateBody",
      "carouselCardList",
      "templateFooter",
      "templateButtons",
    ]);
    setTemplateCarouselCards(null);
    setTemplateButtons(null);
    setPreview(null);
  };

  const handleReplaceHeaderPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];
    if (column.match(/[^{}]+(?=})/g)) {
      setHeaderMapping({
        ...headerMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)[0] + "}",
      });
    } else {
      setHeaderMapping({ ...headerMapping, [placeHolder]: column });
    }
  };

  const handleReplaceBodyPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];
    if (column.match(/[^{}]+(?=})/g)) {
      setBodyMapping({
        ...bodyMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)[0] + "}",
      });
    } else {
      setBodyMapping({ ...bodyMapping, [placeHolder]: column });
    }
  };

  const handleReplaceUrlPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];
    if (column.match(/[^{}]+(?=})/g)) {
      setUrlMapping({
        ...urlMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)[0] + "}",
      });
    } else {
      setUrlMapping({ ...urlMapping, [placeHolder]: column });
    }
  };

  // For Preview create template structure
  const handleCreateTemplate = (e) => {
    // Validate all the buttons are created in proper for carousel template
    if (templateType === "carousel") {
      if (e.carouselCardList?.length < 2) {
        openNotificationWithIcon(
          "error",
          "INXT 18002: Minimum 2 cards required for carousel template."
        );
        return;
      }
    }

    let createdTemplate = {};

    const {
      templateName,
      templateCategory,
      templateLanguage,
      allowcategorychange,
      templateHeaderText,
      templateBody,
      templateButtons,
      carouselCardList,
      templateFooter,
      limitedTimeOfferHeading,
      copyCodeButtonValue,
    } = e;

    createdTemplate.name = templateName;
    createdTemplate.category = templateCategory;
    createdTemplate.language = templateLanguage;
    createdTemplate.allow_category_change = allowcategorychange === "true";

    if (templateType === "textOnly") {
      createdTemplate.components = [
        {
          type: "BODY",
          text: templateBody,
        },
      ];
    } else if (templateType === "media&interactive") {
      const components = [
        {
          type: "BODY",
          text: templateBody,
        },
      ];

      if (templateHeaderText && headerType === "text") {
        components.unshift({
          type: "HEADER",
          format: headerType.toUpperCase(),
          text: templateHeaderText,
        });
      } else if (
        headerType === "image" ||
        headerType === "video" ||
        headerType === "document"
      ) {
        components.unshift({
          type: "HEADER",
          format: headerType.toUpperCase(),
        });
      }

      if (templateFooter) {
        components.push({
          type: "FOOTER",
          text: templateFooter,
        });
      }

      if (buttons?.createdButtons?.length) {
        components.push({
          type: "BUTTONS",
          buttons: buttons.createdButtons.map((button, index) => {
            const buttonObject = {
              type:
                button.type === "quickReply"
                  ? "QUICK_REPLY"
                  : button.type === "url"
                  ? "URL"
                  : button.type === "flow"
                  ? "FLOW"
                  : "PHONE_NUMBER",
            };

            if (button.type === "url") {
              buttonObject.url = templateButtons[index]?.urlButtonValue;
              buttonObject.text = templateButtons[index]?.urlButtonLabel;
            }

            if (button.type === "phoneNumber") {
              buttonObject.phone_number =
                templateButtons[index].phoneNumberButtonValue;
              buttonObject.text =
                templateButtons[index]?.phoneNumberButtonLabel;
            }

            if (button.type === "quickReply") {
              buttonObject.text = templateButtons[index]?.quickReplyButton;
            }

            if (button.type === "flow") {
              buttonObject.text = templateButtons[index]?.flowButtonLabel;
              buttonObject.flow_id = templateButtons[index]?.flowId;
              buttonObject.navigate_screen =
                state.whatsappFlows?.currentWhatsAppFlow?.assets?.screens?.[0]?.id;

              appInsights.trackEvent({
                name: `Flow button template created`,
                properties: {
                  environment: mode,
                  clientName: state.currentAccount.account?.general?.company,
                  clientId: state.currentAccount.account.client_id,
                },
              });
            }

            return buttonObject;
          }),
        });
      }

      createdTemplate.components = components;
    } else if (templateType === "carousel") {
      createdTemplate.components = [
        {
          type: "BODY",
          text: templateBody,
        },
        {
          type: "CAROUSEL",
          cards: carouselCardList.map((card, cardIndex) => {
            return {
              components: [
                {
                  type: "HEADER",
                  format: "IMAGE",
                },
                {
                  type: "BODY",
                  text: card.carouselCardBody,
                },
                {
                  type: "BUTTONS",
                  buttons: carouselTemplate.carouselCards[
                    cardIndex
                  ].buttons.map((button, buttonIndex) => {
                    const buttonObject = {
                      type:
                        button.type === "quickReply"
                          ? "QUICK_REPLY"
                          : button.type === "url"
                          ? "URL"
                          : "PHONE_NUMBER",
                    };

                    if (button.type === "url") {
                      buttonObject.url =
                        card.carouselCardButtonList[
                          buttonIndex
                        ]?.urlButtonValue;
                      buttonObject.text =
                        card.carouselCardButtonList[
                          buttonIndex
                        ]?.urlButtonLabel;
                    }

                    if (button.type === "phoneNumber") {
                      buttonObject.phone_number =
                        card.carouselCardButtonList[
                          buttonIndex
                        ].phoneNumberButtonValue;
                      buttonObject.text =
                        card.carouselCardButtonList[
                          buttonIndex
                        ].phoneNumberButtonLabel;
                    }

                    if (button.type === "quickReply") {
                      buttonObject.text =
                        card.carouselCardButtonList[
                          buttonIndex
                        ].quickReplyButton;
                    }

                    return buttonObject;
                  }),
                },
              ],
            };
          }),
        },
      ];
    } else if (templateType === "catalog") {
      createdTemplate.components = [
        {
          type: "BODY",
          text: templateBody,
        },
        {
          type: "FOOTER",
          text: templateFooter,
        },
        {
          type: "BUTTONS",
          buttons: [
            {
              type: "CATALOG",
              text: e.quickReplyButton,
            },
          ],
        },
      ];
    } else if (templateType === "limitedTimeOffer") {
      createdTemplate.components = [
        {
          type: "HEADER",
          format: "IMAGE",
        },
        {
          type: "limited_time_offer",
          limited_time_offer: {
            text: limitedTimeOfferHeading,
            has_expiration: limitedTimeOfferTemplate.hasExpiration,
          },
        },
        {
          type: "BODY",
          text: templateBody,
        },
        {
          type: "BUTTONS",
          buttons: [
            ...(copyCodeButtonValue
              ? [
                  {
                    type: "copy_code",
                    example: copyCodeButtonValue,
                  },
                ]
              : []),
            {
              type: "URL",
              text: e.urlButtonLabel,
              url: e.urlButtonValue,
            },
          ],
        },
      ];
    }

    setTemplate(createdTemplate);

    const createdTemplateHeader = createdTemplate.components.find(
      (component) => component?.type === "HEADER"
    );

    const createdTemplateBody = createdTemplate.components.find(
      (component) => component?.type === "BODY"
    );
    setTemplateBody(createdTemplateBody);

    setTemplateHeader(
      createdTemplate.components.find(
        (component) => component?.type === "HEADER"
      )
    );
    setTemplateFooter(
      createdTemplate.components.find(
        (component) => component?.type === "FOOTER"
      )
    );
    const createdTemplateButtons = createdTemplate.components.find(
      (component) => component?.type === "BUTTONS"
    );
    setTemplateButtons(createdTemplateButtons);

    const createdTemplateCarouselCards = createdTemplate.components.find(
      (component) => component?.type === "CAROUSEL"
    );
    setTemplateCarouselCards(createdTemplateCarouselCards?.cards);

    const createdTemplateLimitedTimeOffer = createdTemplate.components.find(
      (component) => component?.type === "limited_time_offer"
    );
    setLimitedTimeOfferTemplate({
      ...limitedTimeOfferTemplate,
      ...(createdTemplateLimitedTimeOffer || {}),
    });

    setPreview({
      ...(preview || {}),
      header: createdTemplateHeader?.text,
      body: createdTemplateBody?.text,
      carousel: templateCarouselCards || createdTemplateCarouselCards?.cards,
      limitedTimeOfferTemplate: createdTemplateLimitedTimeOffer,
    });

    setHeaderPlaceHolders(createdTemplateHeader?.text?.match(/[^{}]+(?=})/g));

    setBodyPlaceHolders(createdTemplateBody?.text?.match(/[^{}]+(?=})/g));

    const urlWithPlaceHolders = createdTemplateButtons?.buttons
      .find(({ type }) => type === "URL")
      ?.url?.match(/[^{}]+(?=})/g);

    if (urlWithPlaceHolders) {
      setUrlPlaceHolders(urlWithPlaceHolders);
    } else {
      setUrlPlaceHolders(null);
    }
    setShowPreview(true);
  };

  // Submit Template
  const handleSubmitTemplate = () => {
    dispatchMiddleware({
      type: "CREATE_TEMPLATE",
      payload: {
        template,
        templateType,
        wabaApikey: botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey,
        wabaProvider:
          wabaProvider[botInAction.bot_channel_info?.WHATSAPP?.waba_provider]
            ?.providerReference.providerName,
        component: "message-template-form",
      },
    });
  };

  // Validate all the placeholders are replaced
  const isDisableTemplateCreation = () => {
    const currentNumberWabaProvider =
      wabaProvider[botInAction.bot_channel_info?.WHATSAPP?.waba_provider]
        ?.providerReference.providerName;

    const { header, body, url, headerURL } = preview;

    const hasDoubleBraces = (str) => str?.includes("{{") || str?.includes("}}");
    const isInvalidUrl =
      currentNumberWabaProvider !== "INEXTLABS-CLOUD" &&
      headerURL &&
      !/^(https:\/\/[^\s$.?#].[^\s]*)$/gm.test(headerURL);

    return (
      hasDoubleBraces(header) ||
      hasDoubleBraces(body) ||
      hasDoubleBraces(url) ||
      isInvalidUrl
    );
  };

  // Validate all the buttons are created in proper order
  const validateButtonsGrouping = (buttons) => {
    const callToActionType = ["url", "phoneNumber"];

    //Create a new property in the buttonsArray called buttonCategory
    const buttonsArrayWithButtonCategory = buttons?.createdButtons?.map(
      (button) => {
        return {
          ...button,
          buttonCategory:
            //Check if the button type is in the callToActionType array, if yes, then set the buttonCategory as callToAction, else set it as quickReply
            callToActionType.includes(button.type)
              ? "callToAction"
              : "quickReply",
        };
      }
    );

    //Check if the buttonsArrayWithButtonCategory has any non-consecutive duplicate buttonCategory
    let hasNonConsecutiveDuplicateButtonCategory = false;
    const elementIndices = new Map();

    buttonsArrayWithButtonCategory?.forEach(
      ({ buttonCategory: element }, index) => {
        hasNonConsecutiveDuplicateButtonCategory =
          hasNonConsecutiveDuplicateButtonCategory ||
          (elementIndices.has(element) &&
            index !== elementIndices.get(element) + 1);
        elementIndices.set(element, index);
      }
    );

    //If there is a non-consecutive duplicate buttonCategoy, then show an error message
    if (hasNonConsecutiveDuplicateButtonCategory) {
      openNotificationWithIcon(
        "error",
        "INXT 18001  Button creation should follow the correct order. You cannot have “Quick Reply” buttons between “URL / Phone Number” buttons or vice versa."
      );

      appInsights.trackException({
        exception: JSON.stringify(buttonsArrayWithButtonCategory),
        properties: {
          title:
            "Button creation should follow the correct order. You cannot have “Quick Reply” buttons between “URL / Phone Number” buttons or vice versa",
          clientId: state.currentAccount.account.client_id,
          environment: mode,
        },
      });
    } else {
      setButtons(buttons);
    }

    return hasNonConsecutiveDuplicateButtonCategory;
  };

  // Set form values
  useEffect(() => {
    form.setFieldsValue({
      allowcategorychange: "true",
      templateType: "textOnly",
    });

    setButtons({
      type: "quickReply",
      createdButtons: [],
    });
  }, []);

  // Set mapping values
  useEffect(() => {
    // Set header mapping
    if (headerMapping && headerPlaceHolders) {
      const headerPreviewMessage = headerPlaceHolders.reduce(
        (message, headerPlaceHolder) =>
          headerMapping[headerPlaceHolder]
            ? message.replace(
                `{{${headerPlaceHolder}}}`,
                headerMapping[headerPlaceHolder]
              )
            : message,
        templateHeader.text
      );

      setPreview((prevPreview) => ({
        ...prevPreview,
        header: headerPreviewMessage,
      }));

      // Update the placeholder values in the template
      if (headerPlaceHolders.length && template.components) {
        template.components = template.components.map((component) => {
          if (component?.type === "HEADER") {
            return {
              ...component,
              text: templateHeader.text,
              example: {
                header_text: Object.values(headerMapping),
              },
            };
          }
          return component;
        });
      }
    }

    // Set body mapping
    if (bodyMapping && bodyPlaceHolders) {
      const bodyPreviewMessage = bodyPlaceHolders.reduce(
        (message, bodyPlaceHolder) =>
          bodyMapping[bodyPlaceHolder]
            ? message.replace(
                `{{${bodyPlaceHolder}}}`,
                bodyMapping[bodyPlaceHolder]
              )
            : message,
        templateBody.text
      );

      setPreview((prevPreview) => ({
        ...prevPreview,
        body: bodyPreviewMessage,
      }));

      // Update the placeholder values in the template
      if (bodyPlaceHolders.length && template.components) {
        template.components = template.components.map((component) => {
          if (component?.type === "BODY") {
            return {
              ...component,
              text: templateBody.text,
              example: {
                body_text: [Object.values(bodyMapping)],
              },
            };
          }
          return component;
        });
      }
    }

    // Set url mapping
    if (urlMapping && urlPlaceHolders) {
      const urlPreviewMessage = urlPlaceHolders.reduce(
        (message, urlPlaceHolder) =>
          urlMapping[urlPlaceHolder]
            ? message.replace(
                `{{${urlPlaceHolder}}}`,
                urlMapping[urlPlaceHolder]
              )
            : message,
        templateButtons?.buttons?.find(({ type }) => type === "URL")?.url
      );

      setPreview((prevPreview) => ({
        ...prevPreview,
        url: urlPreviewMessage,
      }));

      // Update the placeholder values in the template
      if (urlPlaceHolders.length && template.components) {
        template.components = template.components.map((component) => {
          if (component?.type === "BUTTONS") {
            return {
              ...component,
              buttons: templateButtons.buttons.map((button) => {
                if (button.type === "URL") {
                  return {
                    ...button,
                    url: templateButtons.buttons.find(
                      ({ type }) => type === "URL"
                    )?.url,
                    example: Object.values(urlMapping),
                  };
                }
                return button;
              }),
            };
          }
          return component;
        });
      }
    }

    // Set header media url
    if (preview?.headerURL) {
      // Update the url values in the template
      if (template.components) {
        template.components = template.components.map((component) => {
          if (component?.type === "HEADER") {
            return {
              ...component,
              example: {
                header_handle: [
                  botInAction.bot_channel_info?.WHATSAPP?.waba_provider ===
                  "INEXTLABS-CLOUD"
                    ? state.messageTemplates.uploadedMedia?.[0]?.mediaId
                    : preview?.headerURL,
                ],
              },
            };
          }
          return component;
        });
      }
    }

    // Set carousel cards
    if (templateCarouselCards) {
      // Update the url values in the template
      if (template.components) {
        template.components = template.components.map((component) => {
          if (component?.type === "CAROUSEL") {
            return {
              ...component,
              cards: preview?.carousel,
            };
          }
          return component;
        });
      }
    }
  }, [headerMapping, bodyMapping, urlMapping, showPreview, preview?.headerURL]);

  // Template Header Component
  const TemplateHeaderFormItem = () => {
    return (
      <Card
        title={
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text>
              {
                templateForm.templateContent.mediaInteractiveFormItem
                  .headerFormItem.title
              }
            </Text>

            <Select
              size="small"
              value={templateType === "limitedTimeOffer" ? "image" : headerType}
              style={{
                width: 130,
              }}
              onChange={(value) => {
                setHeaderType(value);
                setPreview((prevPreview) => ({
                  ...prevPreview,
                  headerURL: null,
                }));
              }}
            >
              {templateType === "limitedTimeOffer" ? (
                <Option value="image">
                  {
                    templateForm.templateContent.mediaInteractiveFormItem
                      .headerFormItem.image
                  }
                </Option>
              ) : (
                <>
                  <Option value="text">
                    {
                      templateForm.templateContent.mediaInteractiveFormItem
                        .headerFormItem.text
                    }
                  </Option>
                  <Option value="image">
                    {
                      templateForm.templateContent.mediaInteractiveFormItem
                        .headerFormItem.image
                    }
                  </Option>
                  <Option value="video">
                    {
                      templateForm.templateContent.mediaInteractiveFormItem
                        .headerFormItem.video
                    }
                  </Option>
                  <Option value="document">
                    {
                      templateForm.templateContent.mediaInteractiveFormItem
                        .headerFormItem.document
                    }
                  </Option>
                </>
              )}
            </Select>
          </Space>
        }
        size="small"
        headStyle={{
          backgroundColor: "#F4F7F9",
        }}
      >
        {/* Render header UI */}
        {(() => {
          switch (templateType === "limitedTimeOffer" ? "image" : headerType) {
            case "text":
              return (
                <Form.Item
                  name="templateHeaderText"
                  rules={[
                    {
                      required: true,
                      message:
                        templateForm.templateContent.mediaInteractiveFormItem
                          .headerFormItem.required,
                    },
                    { whitespace: true },
                    {
                      min: 5,
                      message:
                        templateForm.templateContent.mediaInteractiveFormItem
                          .headerFormItem.minimum,
                    },
                    {
                      max: 60,
                      message:
                        templateForm.templateContent.mediaInteractiveFormItem
                          .headerFormItem.maximum,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Input
                    placeholder={
                      templateForm.templateContent.mediaInteractiveFormItem
                        .headerFormItem.placeholder
                    }
                    showCount
                    maxLength={60}
                  />
                </Form.Item>
              );

            case "image":
              return (
                <Space
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src="/images/image-placeholder.png"
                    preview={false}
                    height={150}
                    width={230}
                  />
                </Space>
              );

            case "video":
              return (
                <Space
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src="/images/video-placeholder.jpg"
                    preview={false}
                    height={150}
                    width={230}
                  />
                </Space>
              );

            case "document":
              return (
                <Space
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src="/images/document-placeholder.png"
                    preview={false}
                    height={150}
                    width={230}
                  />
                </Space>
              );

            default:
              return (
                <Form.Item
                  name="templateHeaderText"
                  rules={[
                    {
                      required: true,
                      message:
                        templateForm.templateContent.mediaInteractiveFormItem
                          .headerFormItem.required,
                    },
                    { whitespace: true },
                    {
                      min: 5,
                      message:
                        templateForm.templateContent.mediaInteractiveFormItem
                          .headerFormItem.minimum,
                    },
                    {
                      max: 60,
                      message:
                        templateForm.templateContent.mediaInteractiveFormItem
                          .headerFormItem.maximum,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Input
                    placeholder={
                      templateForm.templateContent.mediaInteractiveFormItem
                        .headerFormItem.placeholder
                    }
                    showCount
                    maxLength={60}
                  />
                </Form.Item>
              );
          }
        })()}
      </Card>
    );
  };

  // Template Body Component
  const TemplateBodyFormItem = () => {
    return (
      <Card
        title={
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text>{templateForm.templateBodyFormItem.title}</Text>
            <Space size="middle">
              {/* Variables */}
              <Button
                size="small"
                onClick={() => {
                  const currentTemplateBody =
                    form.getFieldValue("templateBody") || "";

                  const variableNumber =
                    currentTemplateBody.split("{{")?.length;

                  const updatedTemplateBody = `${currentTemplateBody}{{${variableNumber}}}`;

                  variableNumber < 11 &&
                    form.setFieldsValue({
                      templateBody: updatedTemplateBody,
                    });
                }}
                icon={<PlusOutlined style={{ marginTop: 4 }} />}
              >
                {templateForm.templateBodyFormItem.addVariable}
              </Button>

              {/* Emoji */}
              <Dropdown
                trigger={["click"]}
                dropdownRender={() => (
                  <Space
                    style={{
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    }}
                  >
                    <Picker
                      data={data}
                      previewPosition="none"
                      onEmojiSelect={(emoji) => {
                        const selectionStart =
                          form.getFieldValue("selectionStart");
                        const templateBody = form.getFieldValue("templateBody");

                        if (!templateBody) {
                          form.setFieldsValue({
                            templateBody: emoji.native,
                          });
                        } else {
                          form.setFieldsValue({
                            templateBody:
                              //Based on the cursor position, add the emoji
                              //Front + emoji + back
                              templateBody?.slice(0, selectionStart) +
                              emoji.native +
                              templateBody?.slice(selectionStart),
                          });
                        }
                      }}
                    />
                  </Space>
                )}
              >
                <Button
                  size="small"
                  icon={
                    <HiOutlineEmojiHappy
                      style={{
                        margin: -3,
                      }}
                    />
                  }
                />
              </Dropdown>

              {/* Bold */}
              <Button
                size="small"
                icon={
                  <AiOutlineBold
                    style={{
                      margin: -3,
                    }}
                  />
                }
                onClick={() => {
                  const selectionStart = form.getFieldValue("selectionStart");
                  const selectionEnd = form.getFieldValue("selectionEnd");
                  const templateBody = form.getFieldValue("templateBody");

                  if (templateBody) {
                    const selectedText = templateBody.substring(
                      selectionStart,
                      selectionEnd
                    );

                    if (selectedText) {
                      const updatedValue =
                        templateBody.slice(0, selectionStart) +
                        `*${selectedText}*` +
                        templateBody.slice(selectionEnd);

                      form.setFieldsValue({
                        templateBody: updatedValue,
                      });
                    }
                  }
                }}
              />

              {/* Italic */}
              <Button
                size="small"
                icon={
                  <AiOutlineItalic
                    style={{
                      margin: -3,
                    }}
                  />
                }
                onClick={() => {
                  const selectionStart = form.getFieldValue("selectionStart");
                  const selectionEnd = form.getFieldValue("selectionEnd");
                  const templateBody = form.getFieldValue("templateBody");

                  if (templateBody) {
                    const selectedText = templateBody.substring(
                      selectionStart,
                      selectionEnd
                    );

                    if (selectedText) {
                      const updatedValue =
                        templateBody.slice(0, selectionStart) +
                        `_${selectedText}_` +
                        templateBody.slice(selectionEnd);

                      form.setFieldsValue({
                        templateBody: updatedValue,
                      });
                    }
                  }
                }}
              />

              {/* Strike */}
              <Button
                size="small"
                icon={
                  <AiOutlineStrikethrough
                    style={{
                      margin: -3,
                    }}
                  />
                }
                onClick={() => {
                  const selectionStart = form.getFieldValue("selectionStart");
                  const selectionEnd = form.getFieldValue("selectionEnd");
                  const templateBody = form.getFieldValue("templateBody");

                  if (templateBody) {
                    const selectedText = templateBody.substring(
                      selectionStart,
                      selectionEnd
                    );

                    if (selectedText) {
                      const updatedValue =
                        templateBody.slice(0, selectionStart) +
                        `~${selectedText}~` +
                        templateBody.slice(selectionEnd);

                      form.setFieldsValue({
                        templateBody: updatedValue,
                      });
                    }
                  }
                }}
              />
            </Space>
          </Space>
        }
        size="small"
        headStyle={{
          backgroundColor: "#F4F7F9",
        }}
      >
        <Form.Item
          name="templateBody"
          rules={[
            {
              required: true,
              message: templateForm.templateBodyFormItem.required,
            },
            { whitespace: true },
            {
              min: 5,
              message: templateForm.templateBodyFormItem.minimum,
            },
            {
              max: templateType === "limitedTimeOffer" ? 600 : 1024,
              message: templateForm.templateBodyFormItem.maximum,
            },
          ]}
          hasFeedback
          className="template-form-item-textarea"
        >
          <Input.TextArea
            placeholder={templateForm.templateBodyFormItem.placeholder}
            autoSize={{ minRows: 2 }}
            showCount
            maxLength={templateType === "limitedTimeOffer" ? 600 : 1024}
            onKeyUp={(e) => {
              // To get the cursor position
              const { selectionStart, selectionEnd } = e.target;

              form.setFieldsValue({
                selectionStart,
                selectionEnd,
              });
            }}
            onMouseUp={(e) => {
              // To get the cursor position
              const { selectionStart, selectionEnd } = e.target;

              form.setFieldsValue({
                selectionStart,
                selectionEnd,
              });
            }}
          />
        </Form.Item>
      </Card>
    );
  };

  // Template Footer Component
  const TemplateFooterFormItem = ({ placeholder }) => {
    return (
      <Card
        title={
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text>
              {
                templateForm.templateContent.mediaInteractiveFormItem
                  .footerFormItem.title
              }
            </Text>
          </Space>
        }
        size="small"
        headStyle={{
          backgroundColor: "#F4F7F9",
        }}
      >
        <Form.Item
          name="templateFooter"
          rules={[
            { whitespace: true },
            {
              min: 5,
              message:
                templateForm.templateContent.mediaInteractiveFormItem
                  .footerFormItem.minimum,
            },
            {
              max: 60,
              message:
                templateForm.templateContent.mediaInteractiveFormItem
                  .footerFormItem.maximum,
            },
            {
              pattern: /^((?!\{\{).)*$/,
              message:
                templateForm.templateContent.mediaInteractiveFormItem
                  .footerFormItem.invalid,
            },
          ]}
          hasFeedback
          className="template-form-item"
        >
          <Input
            placeholder={
              placeholder ||
              templateForm.templateContent.mediaInteractiveFormItem
                .footerFormItem.placeholder
            }
            showCount
            maxLength={60}
          />
        </Form.Item>
      </Card>
    );
  };

  // Quick Reply Button Form Item
  const QuickReplyButtonFormItem = ({ buttonIndex, placeholder }) => {
    return (
      <Form.Item
        name={
          buttonIndex !== undefined
            ? [buttonIndex, "quickReplyButton"]
            : "quickReplyButton"
        }
        rules={[
          {
            required: true,
            message: templateForm.quickReplyButtonFormItem.required,
          },
          { whitespace: true },
          {
            max: 20,
            message: templateForm.quickReplyButtonFormItem.maximum,
          },
        ]}
        className="template-form-item"
      >
        <Input
          placeholder={
            placeholder || templateForm.quickReplyButtonFormItem.placeholder
          }
          showCount
          maxLength={20}
        />
      </Form.Item>
    );
  };

  // URL Button Label Form Item
  const URLButtonLabelFormItem = ({ buttonIndex, placeholder }) => {
    return (
      <Form.Item
        name={
          buttonIndex !== undefined
            ? [buttonIndex, "urlButtonLabel"]
            : "urlButtonLabel"
        }
        rules={[
          {
            required: true,
            message: templateForm.urlButtonLabelFormItem.required,
          },
          { whitespace: true },
          {
            max: 20,
            message: templateForm.urlButtonLabelFormItem.maximum,
          },
        ]}
        className="template-form-item"
      >
        <Input
          placeholder={
            templateType === "carousel"
              ? "Button label here. Ex: Buy now"
              : placeholder || templateForm.urlButtonLabelFormItem.placeholder
          }
          bordered={false}
          showCount
          maxLength={20}
        />
      </Form.Item>
    );
  };

  // URL Button Value Form Item
  const URLButtonValueFormItem = ({ buttonIndex, placeholder }) => {
    return (
      <>
        <Divider />

        <Form.Item
          name={
            buttonIndex !== undefined
              ? [buttonIndex, "urlButtonValue"]
              : "urlButtonValue"
          }
          rules={[
            {
              required: true,
              message: templateForm.urlButtonValueFormItem.required,
            },
            { whitespace: true },
            {
              max: 1024,
              message: templateForm.urlButtonValueFormItem.maximum,
            },
            {
              pattern: /^(https):\/\/[^\s$.?#].[^\s]*$/gm,
              message: templateForm.urlButtonValueFormItem.invalid,
            },
          ]}
          className="template-form-item"
        >
          <Input
            placeholder={
              templateType === "carousel"
                ? "Button redirect URL here. Ex: https://wa.me/p/251532154842690/914447455633"
                : placeholder || templateForm.urlButtonValueFormItem.placeholder
            }
            onKeyDown={(e) => {
              // don't allow { and } in the url
              // after } is pressed, don't allow any other key

              if (
                e.key === "{" || // Condition 1: Check if the pressed key is "{"
                e.key === "}" || // Condition 2: Check if the pressed key is "}"
                (e.target.value.includes("}}") &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete")
              ) {
                e.preventDefault(); // Prevent the default behavior of the event unless it's "Backspace" or "Delete"
              }
            }}
            bordered={false}
            maxLength={1024}
          />
        </Form.Item>
      </>
    );
  };

  // Phone Number Button Label Form Item
  const PhoneNumberButtonLabelFormItem = ({ buttonIndex }) => {
    return (
      <Form.Item
        name={[buttonIndex, "phoneNumberButtonLabel"]}
        rules={[
          {
            required: true,
            message: templateForm.phoneNumberButtonLabelFormItem.required,
          },
          { whitespace: true },
          {
            max: 20,
            message: templateForm.phoneNumberButtonLabelFormItem.maximum,
          },
        ]}
        className="template-form-item"
      >
        <Input
          placeholder={templateForm.phoneNumberButtonLabelFormItem.placeholder}
          showCount
          bordered={false}
          maxLength={20}
        />
      </Form.Item>
    );
  };

  // Phone Number Button Value Form Item
  const PhoneNumberButtonValueFormItem = ({ buttonIndex }) => {
    return (
      <>
        <Divider />

        <Form.Item
          name={[buttonIndex, "phoneNumberButtonValue"]}
          rules={[
            {
              required: true,
              message: templateForm.phoneNumberButtonValueFormItem.required,
            },
            { whitespace: true },
            {
              validator: (_, value) => {
                try {
                  parsePhoneNumber(value).isValid();
                  return Promise.resolve();
                } catch (error) {
                  return Promise.reject(
                    templateForm.phoneNumberButtonValueFormItem.invalid
                  );
                }
              },
            },
          ]}
          className="template-form-item"
        >
          <Input
            placeholder={
              templateForm.phoneNumberButtonValueFormItem.placeholder
            }
            bordered={false}
          />
        </Form.Item>
      </>
    );
  };

  // Copy Code  Button Value Form Item
  const CopyCodeButtonValueFormItem = ({ buttonIndex }) => {
    return (
      <Form.Item
        name={
          buttonIndex !== undefined
            ? [buttonIndex, "copyCodeButtonValue"]
            : "copyCodeButtonValue"
        }
        rules={[
          {
            required: limitedTimeOfferTemplate.hasExpiration ? true : false,
            message: "Please enter the button value",
          },
          { whitespace: true },
          {
            max: 15,
            message: "Maximum 15 characters allowed",
          },
        ]}
        className="template-form-item"
      >
        <Input
          placeholder="Button value here. Ex: BUY1GET1"
          maxLength={15}
          showCount
        />
      </Form.Item>
    );
  };

  // Flow button form item
  const FlowButtonLabelFormItem = ({ buttonIndex }) => {
    return (
      <Form.Item
        name={[buttonIndex, "flowButtonLabel"]}
        rules={[
          {
            required: true,
            message: "Please enter the button label",
          },
          { whitespace: true },
          {
            max: 25,
            message: "Maximum 25 characters allowed",
          },
        ]}
        className="template-form-item"
      >
        <Input
          placeholder="Flow button label here. Ex: Sign up"
          showCount
          bordered={false}
          maxLength={25}
        />
      </Form.Item>
    );
  };

  // Flow button value form item
  const FlowButtonValueFormItem = ({ buttonIndex }) => {
    return (
      <>
        <Divider />

        <Row>
          <Col span={23}>
            <Form.Item
              name={[buttonIndex, "flowId"]}
              rules={[
                {
                  required: true,
                  message: "Please select the flow",
                },
              ]}
              className="template-form-item"
            >
              <Select
                placeholder="Select the flow. Ex: Sign up flow"
                bordered={false}
                showSearch
                onSelect={(flowId) => {
                  dispatchReducer({
                    type: "CLEAR_CURRENT_WHATSAPP_FLOW",
                  });

                  dispatchMiddleware({
                    type: "LOAD_WHATSAPP_FLOW_ASSETS",
                    payload: {
                      flowId,
                      component: "whatsapp-flows-assets",
                    },
                  });
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                notFoundContent={
                  state.apiCallStatus?.["whatsapp-flows-table"]?.loading ||
                  state.apiCallStatus?.["whatsapp-flows-table"]?.error ? (
                    <Space
                      style={{
                        width: "100%",
                        height: 50,
                      }}
                    >
                      <TabLoader
                        component="whatsapp-flows-table"
                        loadingMessage="Loading... Please wait..."
                        errorMessage="Error loading flows. Please try again later."
                      />
                    </Space>
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No flows found"
                    />
                  )
                }
              >
                {state.whatsappFlows?.flows
                  .filter(({ status }) => status === "PUBLISHED")
                  .map((flow) => (
                    <Select.Option value={flow.id} key={flow.id}>
                      {flow.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={1}>
            {state.apiCallStatus?.["whatsapp-flows-assets"]?.loading && (
              <ComponentLoader
                component={"whatsapp-flows-assets"}
                loadingMessage=""
                errorMessage=""
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  return state.apiCallStatus["message-template-form"] &&
    state.apiCallStatus["message-template-form"].loading ? (
    <FullPageLoader
      component="message-template-form"
      loadingMessage="Submitting... Please wait..."
    />
  ) : !showPreview ? (
    <Form
      layout="vertical"
      autoComplete="off"
      onFinish={handleCreateTemplate}
      form={form}
      onFinishFailed={() => {
        openNotificationWithIcon("error", templateForm.enterAllFieldsWarning);
      }}
    >
      <Row gutter={[20, 24]}>
        {/*Template Name*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <HiOutlineTemplate color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>{templateForm.templateNameFormItem.title}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="templateName"
                  label={templateForm.templateNameFormItem.label}
                  rules={[
                    {
                      required: true,
                      message: templateForm.templateNameFormItem.required,
                    },
                    { whitespace: true },
                    {
                      min: 5,
                      message: templateForm.templateNameFormItem.minimum,
                    },
                    {
                      max: 50,
                      message: templateForm.templateNameFormItem.maximum,
                    },
                    {
                      pattern: /^[a-z0-9_]+$/,
                      message: templateForm.templateNameFormItem.pattern,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Input
                    placeholder={templateForm.templateNameFormItem.placeholder}
                    style={{ width: 500 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Template Channel*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <TbDeviceMobileMessage color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>{templateForm.templateChannelFormItem.title}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="channel"
                  label={templateForm.templateChannelFormItem.label}
                  className="template-form-item"
                  valuePropName="checked"
                >
                  <Radio.Group value={channel}>
                    <Radio value="WHATSAPP">
                      {templateForm.templateChannelFormItem.whatsapp}
                    </Radio>
                    <Radio disabled value="FACEBOOK">
                      {templateForm.templateChannelFormItem.facebook}
                    </Radio>
                    <Radio disabled value="INSTAGRAM">
                      {templateForm.templateChannelFormItem.instagram}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Template Category*/}
        <Col span={12}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={2}>
                <TbCategory color="#F05742" size={20} />
              </Col>
              <Col span={22}>
                <Text strong>
                  {templateForm.templateCategoryFormItem.title}
                </Text>

                <Tooltip
                  placement="topLeft"
                  title={
                    <Space direction="vertical">
                      <Text style={{ color: "white" }}>
                        Marketing templates are used to send promotional or
                        offer messages to the customers.
                      </Text>
                      <Text style={{ color: "white" }}>
                        Utility templates are used to send transactional or
                        service messages to the customers.
                      </Text>
                      <Text style={{ color: "white", fontSize: "13px" }}>
                        Note: Template category cannot be changed once the
                        template is created. Marketing templates are costlier
                        than utility templates. You can check <br />
                        <Link
                          onClick={() => {
                            dispatchReducer({
                              type: "OPEN_FORM_PANEL",
                              payload: {
                                component:
                                  "broadcast-pricing-calculator-form-panel",
                                title: "Price Estimator",
                              },
                            });

                            !state.broadcastManager.broadcastPriceList &&
                              dispatchMiddleware({
                                type: "LOAD_BROADCAST_PRICE_LIST",
                                payload: {
                                  component:
                                    "broadcast-pricing-calculator-form-panel",
                                },
                              });
                          }}
                        >
                          price estimator
                        </Link>{" "}
                        for more details.
                      </Text>
                    </Space>
                  }
                >
                  <HiOutlineQuestionMarkCircle
                    size={16}
                    style={{
                      margin: "0 5px -3px 5px",
                      color: "#727272",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="templateCategory"
                  label={templateForm.templateCategoryFormItem.label}
                  rules={[
                    {
                      required: true,
                      message: templateForm.templateCategoryFormItem.required,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Select
                    placeholder={
                      templateForm.templateCategoryFormItem.placeholder
                    }
                    style={{ width: "90%" }}
                    onChange={(value) => setTemplateCategory(value)}
                    value={templateCategory}
                  >
                    <Select.Option value="MARKETING">
                      {templateForm.templateCategoryFormItem.marketing}
                    </Select.Option>
                    <Select.Option value="UTILITY">
                      {templateForm.templateCategoryFormItem.utility}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Allow Template Category Change*/}
        <Col span={12}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={2}>
                <FaExchangeAlt
                  color="#F05742"
                  size={18}
                  style={{
                    marginBottom: -3,
                  }}
                />
              </Col>
              <Col
                span={19}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text strong>
                  {templateForm.allowCategoryChangeFormItem.title}
                </Text>
                <Tooltip
                  placement="topRight"
                  title={templateForm.allowCategoryChangeFormItem.tooltipText}
                >
                  <HiOutlineQuestionMarkCircle
                    size={16}
                    style={{
                      margin: "0 5px 0 5px",
                      color: "#727272",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="allowcategorychange"
                  label={templateForm.allowCategoryChangeFormItem.label}
                  rules={[
                    {
                      required: true,
                      message:
                        templateForm.allowCategoryChangeFormItem.required,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Select
                    placeholder={
                      templateForm.allowCategoryChangeFormItem.placeholder
                    }
                    style={{ width: "90%" }}
                  >
                    <Select.Option value="true">
                      {templateForm.allowCategoryChangeFormItem.yes}
                    </Select.Option>
                    <Select.Option value="false">
                      {templateForm.allowCategoryChangeFormItem.no}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Template Language*/}
        <Col span={12}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={2}>
                <LuLanguages color="#F05742" size={18} />
              </Col>
              <Col
                span={19}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text strong>
                  {templateForm.templateLanguageFormItem.title}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="templateLanguage"
                  label={templateForm.templateLanguageFormItem.label}
                  rules={[
                    {
                      required: true,
                      message: templateForm.templateLanguageFormItem.required,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Select
                    showSearch
                    placeholder={
                      templateForm.templateLanguageFormItem.placeholder
                    }
                    style={{ width: "90%" }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {templateLanguages.map((language, index) => {
                      return (
                        <Select.Option value={language.key} key={index}>
                          {language.value + " (" + language.key + ")"}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Template Type*/}
        <Col span={12}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={2}>
                <MdOutlineTypeSpecimen color="#F05742" size={18} />
              </Col>
              <Col
                span={19}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text strong>{templateForm.templateTypeFormItem.title}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="templateType"
                  label={templateForm.templateTypeFormItem.label}
                  rules={[
                    {
                      required: true,
                      message: templateForm.templateTypeFormItem.required,
                    },
                  ]}
                  hasFeedback
                  className="template-form-item"
                >
                  <Select
                    placeholder={templateForm.templateTypeFormItem.placeholder}
                    style={{ width: "90%" }}
                    onChange={(value) => {
                      // Check if carousel is selected and WhatsApp WABA bot exists
                      if (
                        value === "carousel" &&
                        !botInAction?.bot_channel_info?.WHATSAPP
                          ?.waba_bot_exists
                      ) {
                        openNotificationWithIcon(
                          "error",
                          "INXT 18002  Carousel template type is not supported for Notify package. Upgrade your package to use this feature."
                        );
                      } else {
                        // Allow selecting other template types
                        setTemplateType(value);
                        resetMapping();
                      }
                    }}
                  >
                    <Select.Option value="textOnly">
                      {templateForm.templateTypeFormItem.textOnly}
                    </Select.Option>
                    <Select.Option value="media&interactive">
                      {templateForm.templateTypeFormItem.mediaInteractive}
                    </Select.Option>
                    {
                      // Temporaryly disable carousel for INEXTLABS-CLOUD
                    }
                    {wabaProvider[
                      botInAction.bot_channel_info?.WHATSAPP?.waba_provider
                    ]?.providerReference.providerName !== "INEXTLABS-CLOUD" && (
                      <Select.Option value="carousel">
                        {templateForm.templateTypeFormItem.carousel}
                      </Select.Option>
                    )}

                    <Select.Option value="catalog">Catalog</Select.Option>
                    <Select.Option value="limitedTimeOffer">
                      Limited-Time Offer
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Template Content*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <MdMessage color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>{templateForm.templateContent.title}</Text>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={24}>{templateForm.templateContent.description}</Col>
              <Col span={24}>
                {templateType === "textOnly" ? (
                  <TemplateBodyFormItem />
                ) : templateType === "media&interactive" ? (
                  <Card size="small">
                    <Row gutter={[0, 5]}>
                      <Col span={1}>
                        <PiSquaresFour color="#F05742" size={20} />
                      </Col>
                      <Col span={23}>
                        <Text strong>
                          {
                            templateForm.templateContent
                              .mediaInteractiveFormItem.buildingBlocks.title
                          }
                        </Text>
                      </Col>

                      {/* Building Blocks */}
                      <Col span={24}>
                        <Checkbox.Group
                          value={buildingBlocks}
                          onChange={(values) => {
                            setBuildingBlocks(values);
                            !values.includes("BUTTONS") && setButtons({});
                          }}
                        >
                          <Checkbox value="HEADER">
                            {
                              templateForm.templateContent
                                .mediaInteractiveFormItem.buildingBlocks.header
                            }
                          </Checkbox>
                          <Tooltip
                            title={
                              templateForm.templateContent
                                .mediaInteractiveFormItem.buildingBlocks
                                .bodyMandatoryTooltip
                            }
                          >
                            <Checkbox value="BODY" disabled>
                              {
                                templateForm.templateContent
                                  .mediaInteractiveFormItem.buildingBlocks.body
                              }
                            </Checkbox>
                          </Tooltip>
                          <Checkbox value="FOOTER">
                            {
                              templateForm.templateContent
                                .mediaInteractiveFormItem.buildingBlocks.footer
                            }
                          </Checkbox>
                          <Checkbox value="BUTTONS">
                            {
                              templateForm.templateContent
                                .mediaInteractiveFormItem.buildingBlocks.buttons
                            }
                          </Checkbox>
                        </Checkbox.Group>
                      </Col>
                    </Row>

                    <Row
                      gutter={[0, 25]}
                      style={{
                        marginTop: 20,
                      }}
                    >
                      {/* Template Header*/}
                      {buildingBlocks?.includes("HEADER") && (
                        <Col span={24}>
                          <Card
                            title={
                              <Space
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text>
                                  {
                                    templateForm.templateContent
                                      .mediaInteractiveFormItem.headerFormItem
                                      .title
                                  }
                                </Text>

                                <Select
                                  size="small"
                                  value={headerType}
                                  style={{
                                    width: 130,
                                  }}
                                  onChange={(value) => {
                                    setHeaderType(value);
                                    setPreview((prevPreview) => ({
                                      ...prevPreview,
                                      headerURL: null,
                                    }));
                                  }}
                                >
                                  <Option value="text">
                                    {
                                      templateForm.templateContent
                                        .mediaInteractiveFormItem.headerFormItem
                                        .text
                                    }
                                  </Option>
                                  <Option value="image">
                                    {
                                      templateForm.templateContent
                                        .mediaInteractiveFormItem.headerFormItem
                                        .image
                                    }
                                  </Option>
                                  <Option value="video">
                                    {
                                      templateForm.templateContent
                                        .mediaInteractiveFormItem.headerFormItem
                                        .video
                                    }
                                  </Option>
                                  <Option value="document">
                                    {
                                      templateForm.templateContent
                                        .mediaInteractiveFormItem.headerFormItem
                                        .document
                                    }
                                  </Option>
                                </Select>
                              </Space>
                            }
                            size="small"
                            headStyle={{
                              backgroundColor: "#F4F7F9",
                            }}
                          >
                            {/* Render header UI */}
                            {(() => {
                              switch (headerType) {
                                case "text":
                                  return (
                                    <Form.Item
                                      name="templateHeaderText"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .headerFormItem.required,
                                        },
                                        { whitespace: true },
                                        {
                                          min: 5,
                                          message:
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .headerFormItem.minimum,
                                        },
                                        {
                                          max: 60,
                                          message:
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .headerFormItem.maximum,
                                        },
                                      ]}
                                      hasFeedback
                                      className="template-form-item"
                                    >
                                      <Input
                                        placeholder={
                                          templateForm.templateContent
                                            .mediaInteractiveFormItem
                                            .headerFormItem.placeholder
                                        }
                                        showCount
                                        maxLength={60}
                                      />
                                    </Form.Item>
                                  );

                                case "image":
                                  return (
                                    <Space
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Image
                                        src="/images/image-placeholder.png"
                                        preview={false}
                                        height={150}
                                        width={230}
                                      />
                                    </Space>
                                  );

                                case "video":
                                  return (
                                    <Space
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Image
                                        src="/images/video-placeholder.jpg"
                                        preview={false}
                                        height={150}
                                        width={230}
                                      />
                                    </Space>
                                  );

                                case "document":
                                  return (
                                    <Space
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Image
                                        src="/images/document-placeholder.png"
                                        preview={false}
                                        height={150}
                                        width={230}
                                      />
                                    </Space>
                                  );

                                default:
                                  return (
                                    <Form.Item
                                      name="templateHeaderText"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .headerFormItem.required,
                                        },
                                        { whitespace: true },
                                        {
                                          min: 5,
                                          message:
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .headerFormItem.minimum,
                                        },
                                        {
                                          max: 60,
                                          message:
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .headerFormItem.maximum,
                                        },
                                      ]}
                                      hasFeedback
                                      className="template-form-item"
                                    >
                                      <Input
                                        placeholder={
                                          templateForm.templateContent
                                            .mediaInteractiveFormItem
                                            .headerFormItem.placeholder
                                        }
                                        showCount
                                        maxLength={60}
                                      />
                                    </Form.Item>
                                  );
                              }
                            })()}
                          </Card>
                        </Col>
                      )}

                      {/* Template Body*/}
                      {buildingBlocks?.includes("BODY") && (
                        <Col span={24}>
                          <TemplateBodyFormItem />
                        </Col>
                      )}

                      {/* Template Footer*/}
                      {buildingBlocks?.includes("FOOTER") && (
                        <Col span={24}>
                          <Card
                            title={
                              <Space
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text>
                                  {
                                    templateForm.templateContent
                                      .mediaInteractiveFormItem.footerFormItem
                                      .title
                                  }
                                </Text>
                              </Space>
                            }
                            size="small"
                            headStyle={{
                              backgroundColor: "#F4F7F9",
                            }}
                          >
                            <Form.Item
                              name="templateFooter"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    templateForm.templateContent
                                      .mediaInteractiveFormItem.footerFormItem
                                      .required,
                                },
                                { whitespace: true },
                                {
                                  min: 5,
                                  message:
                                    templateForm.templateContent
                                      .mediaInteractiveFormItem.footerFormItem
                                      .minimum,
                                },
                                {
                                  max: 60,
                                  message:
                                    templateForm.templateContent
                                      .mediaInteractiveFormItem.footerFormItem
                                      .maximum,
                                },
                                {
                                  pattern: /^((?!\{\{).)*$/,
                                  message:
                                    templateForm.templateContent
                                      .mediaInteractiveFormItem.footerFormItem
                                      .invalid,
                                },
                              ]}
                              hasFeedback
                              className="template-form-item"
                            >
                              <Input
                                placeholder={
                                  templateForm.templateContent
                                    .mediaInteractiveFormItem.footerFormItem
                                    .placeholder
                                }
                                showCount
                                maxLength={60}
                              />
                            </Form.Item>
                          </Card>
                        </Col>
                      )}

                      {/* Template Buttons*/}
                      {buildingBlocks?.includes("BUTTONS") && (
                        <Col span={24}>
                          <Form.List name="templateButtons">
                            {(fields, { add, remove }) => (
                              <Card
                                title={
                                  <Space
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <Text>
                                      {
                                        templateForm.templateContent
                                          .mediaInteractiveFormItem
                                          .buttonsFormItem.title
                                      }
                                    </Text>

                                    <Space size="middle">
                                      <Button
                                        size="small"
                                        icon={
                                          <PlusOutlined
                                            style={{ marginTop: 4 }}
                                          />
                                        }
                                        style={{ textAlign: "center" }}
                                        disabled={fields.length === 10}
                                        onClick={() => {
                                          add();
                                          const createdButtons = [
                                            ...(buttons.createdButtons || []),
                                            {
                                              text: "",
                                              type:
                                                buttons.createdButtons.some(
                                                  (button) =>
                                                    button.type === "url"
                                                ) &&
                                                buttons.createdButtons.some(
                                                  (button) =>
                                                    button.type ===
                                                    "phoneNumber"
                                                )
                                                  ? "quickReply"
                                                  : buttons.createdButtons.some(
                                                      (button) =>
                                                        button.type === "url"
                                                    )
                                                  ? "phoneNumber"
                                                  : "url",
                                            },
                                          ];

                                          setButtons({
                                            createdButtons,
                                          });
                                        }}
                                      >
                                        {
                                          templateForm.templateContent
                                            .mediaInteractiveFormItem
                                            .buttonsFormItem.addButton
                                        }
                                      </Button>
                                    </Space>
                                  </Space>
                                }
                                size="small"
                                headStyle={{
                                  backgroundColor: "#F4F7F9",
                                }}
                              >
                                <Space
                                  direction="vertical"
                                  style={{
                                    width: "100%",
                                  }}
                                  size="middle"
                                >
                                  {!fields.length && (
                                    <Row gutter={[10, 8]}>
                                      <Col span={24}>
                                        <Text
                                          type="secondary"
                                          style={{
                                            fontSize: 13,
                                          }}
                                        >
                                          {
                                            templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .buttonsFormItem.infoText
                                          }
                                        </Text>
                                      </Col>

                                      <Col span={24}>
                                        <Space direction="vertical" size={0}>
                                          <Space>
                                            <MdOutlineDone
                                              style={{
                                                color: "green",
                                              }}
                                              size={18}
                                            />
                                            <Text
                                              type="secondary"
                                              style={{
                                                fontSize: 13,
                                              }}
                                            >
                                              {
                                                templateForm.templateContent
                                                  .mediaInteractiveFormItem
                                                  .buttonsFormItem.validGrouping
                                                  .infoText
                                              }
                                            </Text>
                                          </Space>

                                          <Space>
                                            <MdClose
                                              style={{
                                                color: "red",
                                                marginBottom: -3,
                                              }}
                                              size={18}
                                            />
                                            <Text
                                              type="secondary"
                                              style={{
                                                fontSize: 13,
                                              }}
                                            >
                                              {
                                                templateForm.templateContent
                                                  .mediaInteractiveFormItem
                                                  .buttonsFormItem
                                                  .invalidGrouping.infoText
                                              }
                                            </Text>
                                          </Space>
                                        </Space>
                                      </Col>

                                      <Col span={10}>
                                        <Space
                                          direction="vertical"
                                          size="small"
                                        >
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "green",
                                            }}
                                          >
                                            {
                                              templateForm.templateContent
                                                .mediaInteractiveFormItem
                                                .buttonsFormItem.validGrouping
                                                .exampleTitle
                                            }
                                          </Text>

                                          {[
                                            {
                                              number: 1,
                                              tags: [
                                                {
                                                  label:
                                                    templateForm
                                                      .urlButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .phoneNumberButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                              ],
                                            },
                                            {
                                              number: 2,
                                              tags: [
                                                {
                                                  label:
                                                    templateForm
                                                      .phoneNumberButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .urlButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                              ],
                                            },
                                            {
                                              number: 3,
                                              tags: [
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .phoneNumberButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .urlButtonLabelFormItem
                                                      .title,
                                                },
                                              ],
                                            },
                                          ].map((example) => (
                                            <Space
                                              size={0}
                                              key={example.number}
                                            >
                                              <Text
                                                style={{
                                                  marginRight: 5,
                                                  fontSize: 13,
                                                }}
                                                type="secondary"
                                              >
                                                {example.number}.
                                              </Text>
                                              {example.tags.map(
                                                (tag, index) => (
                                                  <Tag
                                                    key={index}
                                                    color={
                                                      tag.label ===
                                                      "Quick Reply"
                                                        ? "orange"
                                                        : "blue"
                                                    }
                                                    style={{ fontSize: 11 }}
                                                  >
                                                    {tag.label}
                                                  </Tag>
                                                )
                                              )}
                                            </Space>
                                          ))}
                                        </Space>
                                      </Col>

                                      <Col span={1}>
                                        <Divider
                                          type="vertical"
                                          style={{
                                            height: "100%",
                                          }}
                                        />
                                      </Col>

                                      <Col span={12}>
                                        <Space
                                          direction="vertical"
                                          size="small"
                                        >
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "red",
                                            }}
                                          >
                                            {
                                              templateForm.templateContent
                                                .mediaInteractiveFormItem
                                                .buttonsFormItem.invalidGrouping
                                                .exampleTitle
                                            }
                                          </Text>

                                          {[
                                            {
                                              number: 1,
                                              tags: [
                                                {
                                                  label:
                                                    templateForm
                                                      .phoneNumberButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .urlButtonLabelFormItem
                                                      .title,
                                                },
                                              ],
                                            },
                                            {
                                              number: 2,
                                              tags: [
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .urlButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                              ],
                                            },
                                            {
                                              number: 3,
                                              tags: [
                                                {
                                                  label:
                                                    templateForm
                                                      .urlButtonLabelFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .quickReplyButtonFormItem
                                                      .title,
                                                },
                                                {
                                                  label:
                                                    templateForm
                                                      .phoneNumberButtonLabelFormItem
                                                      .title,
                                                },
                                              ],
                                            },
                                          ].map((example) => (
                                            <Space
                                              size={0}
                                              key={example.number}
                                            >
                                              <Text
                                                style={{
                                                  marginRight: 5,
                                                  fontSize: 13,
                                                }}
                                                type="secondary"
                                              >
                                                {example.number}.
                                              </Text>
                                              {example.tags.map(
                                                (tag, index) => (
                                                  <Tag
                                                    key={index}
                                                    color={
                                                      tag.label ===
                                                      "Quick Reply"
                                                        ? "orange"
                                                        : "blue"
                                                    }
                                                    style={{ fontSize: 11 }}
                                                  >
                                                    {tag.label}
                                                  </Tag>
                                                )
                                              )}
                                            </Space>
                                          ))}
                                        </Space>
                                      </Col>
                                    </Row>
                                  )}
                                  {fields.map((field, index) => (
                                    <Card
                                      key={field.key}
                                      title={
                                        <Space
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                          align="center"
                                        >
                                          <Text>
                                            {templateForm.templateContent
                                              .mediaInteractiveFormItem
                                              .buttonsFormItem.button.title +
                                              " " +
                                              (index + 1)}
                                          </Text>
                                          <Space size="middle">
                                            <Select
                                              size="small"
                                              value={
                                                buttons.createdButtons?.[index]
                                                  ?.type
                                              }
                                              style={{
                                                width: 130,
                                              }}
                                              onChange={(value) => {
                                                const updatedButtons =
                                                  buttons.createdButtons.map(
                                                    (button, i) => {
                                                      if (i === index) {
                                                        return {
                                                          ...button,
                                                          text: undefined,
                                                          url: undefined,
                                                          phoneNumber:
                                                            undefined,
                                                          type: value,
                                                        };
                                                      } else {
                                                        return button;
                                                      }
                                                    }
                                                  );

                                                // Validate buttons grouping after updating button type
                                                validateButtonsGrouping({
                                                  ...buttons,
                                                  createdButtons:
                                                    updatedButtons,
                                                });

                                                // Load WhatsApp flows if button type is "flow" and conditions are met

                                                value === "flow" &&
                                                  botInAction?.bot_channel_info
                                                    ?.WHATSAPP
                                                    ?.waba_account_id &&
                                                  dispatchMiddleware({
                                                    type: "LOAD_WHATSAPP_FLOWS",
                                                    payload: {
                                                      component:
                                                        "whatsapp-flows-table",
                                                      botInAction,
                                                      wabaAccountId:
                                                        botInAction
                                                          ?.bot_channel_info
                                                          ?.WHATSAPP
                                                          ?.waba_account_id,
                                                    },
                                                  });
                                              }}
                                            >
                                              <Option
                                                value="url"
                                                disabled={
                                                  buttons.createdButtons?.filter(
                                                    (button) =>
                                                      button.type === "url"
                                                  ).length >= 2
                                                }
                                              >
                                                {
                                                  templateForm
                                                    .urlButtonLabelFormItem
                                                    .title
                                                }
                                              </Option>
                                              <Option
                                                value="phoneNumber"
                                                disabled={buttons.createdButtons?.some(
                                                  (button) =>
                                                    button.type ===
                                                    "phoneNumber"
                                                )}
                                              >
                                                {
                                                  templateForm
                                                    .phoneNumberButtonLabelFormItem
                                                    .title
                                                }
                                              </Option>
                                              <Option value="quickReply">
                                                {
                                                  templateForm
                                                    .quickReplyButtonFormItem
                                                    .title
                                                }
                                              </Option>

                                              <Option
                                                value="flow"
                                                disabled={buttons.createdButtons?.some(
                                                  (button) =>
                                                    button.type === "flow"
                                                )}
                                              >
                                                Flow
                                              </Option>
                                            </Select>

                                            <DeleteOutlined
                                              style={{ color: "#F05742" }}
                                              onClick={() => {
                                                remove(index);
                                                const updatedButtons = [
                                                  ...buttons.createdButtons,
                                                ];
                                                updatedButtons.splice(index, 1);
                                                setButtons({
                                                  ...buttons,
                                                  createdButtons:
                                                    updatedButtons,
                                                });
                                              }}
                                            />
                                          </Space>
                                        </Space>
                                      }
                                      size="small"
                                      headStyle={{
                                        backgroundColor: "#F4F7F9",
                                      }}
                                    >
                                      {/* Based on selected dropdown form item should render */}
                                      {buttons.createdButtons[index]?.type ===
                                        "quickReply" && (
                                        <QuickReplyButtonFormItem
                                          buttonIndex={index}
                                        />
                                      )}

                                      {buttons.createdButtons[index]?.type ===
                                        "url" && (
                                        <Space
                                          key={index}
                                          style={{
                                            width: "100%",
                                          }}
                                          size={0}
                                          direction="vertical"
                                        >
                                          <URLButtonLabelFormItem
                                            buttonIndex={index}
                                          />

                                          <URLButtonValueFormItem
                                            buttonIndex={index}
                                          />
                                        </Space>
                                      )}

                                      {buttons.createdButtons[index]?.type ===
                                        "phoneNumber" && (
                                        <Space
                                          key={index}
                                          style={{
                                            width: "100%",
                                          }}
                                          size={0}
                                          direction="vertical"
                                        >
                                          <PhoneNumberButtonLabelFormItem
                                            buttonIndex={index}
                                          />

                                          <PhoneNumberButtonValueFormItem
                                            buttonIndex={index}
                                          />
                                        </Space>
                                      )}

                                      {buttons.createdButtons[index]?.type ===
                                        "flow" &&
                                        (botInAction?.bot_channel_info?.WHATSAPP
                                          ?.waba_account_id ? (
                                          <Space
                                            key={index}
                                            style={{
                                              width: "100%",
                                            }}
                                            direction="vertical"
                                          >
                                            <Text>
                                              Flow button used to trigger a
                                              flows when the user clicks on the
                                              button. You can select a flow from
                                              the list of flows available for
                                              your number.{" "}
                                              <Link
                                                style={{
                                                  fontSize: 13,
                                                }}
                                                onClick={() => {
                                                  navigate(
                                                    "/knowlege-area/whatsapp-flows"
                                                  );

                                                  dispatchReducer({
                                                    type: "CLOSE_FORM_PANEL",
                                                    payload: {
                                                      component:
                                                        "message-template-form-panel",
                                                    },
                                                  });
                                                }}
                                              >
                                                Create new flow
                                              </Link>
                                            </Text>

                                            <FlowButtonLabelFormItem
                                              buttonIndex={index}
                                            />

                                            <FlowButtonValueFormItem
                                              buttonIndex={index}
                                            />
                                          </Space>
                                        ) : (
                                          <Text
                                            type="danger"
                                            style={{
                                              fontSize: 13,
                                            }}
                                          >
                                            Flows not enabled for this number.
                                            Reach out to support to enable
                                            flows.
                                          </Text>
                                        ))}
                                    </Card>
                                  ))}
                                </Space>

                                {fields.length !== 0 && (
                                  <Text
                                    style={{
                                      display: "block",
                                      textAlign: "right",
                                      marginTop: 10,
                                    }}
                                  >
                                    {fields.length + " / 10"}
                                  </Text>
                                )}
                              </Card>
                            )}
                          </Form.List>
                        </Col>
                      )}
                    </Row>
                  </Card>
                ) : templateType === "carousel" ? (
                  <Card
                    size="small"
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Row gutter={[0, 15]}>
                      <Col span={1}>
                        <PiSquaresFour color="#F05742" size={20} />
                      </Col>
                      <Col span={23}>
                        <Text strong>
                          {
                            templateForm.templateContent
                              .mediaInteractiveFormItem.buildingBlocks.title
                          }
                        </Text>
                      </Col>

                      <Col span={24}>
                        {
                          templateForm.templateContent.carouselFormItem
                            .description
                        }
                      </Col>

                      <Col span={24}>
                        <TemplateBodyFormItem />
                      </Col>

                      <Col span={24}>
                        <Form.List name="carouselCardList">
                          {(fields, { add, remove }) => (
                            <Card
                              title={
                                <Space
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Text>
                                    {
                                      templateForm.templateContent
                                        .carouselFormItem.cards.title
                                    }
                                  </Text>
                                  <Space size="middle">
                                    <Button
                                      size="small"
                                      icon={
                                        <PlusOutlined
                                          style={{ marginTop: 4 }}
                                        />
                                      }
                                      style={{ textAlign: "center" }}
                                      disabled={fields.length === 10}
                                      onClick={() => {
                                        add();
                                        const updatedCarouselCards = [
                                          ...(carouselTemplate.carouselCards ||
                                            []),
                                          {
                                            headerType: "image",
                                            url: "",
                                            body: "",
                                            buttons: [
                                              //url button by default
                                              {
                                                type: "url",
                                                text: "",
                                                url: "",
                                              },
                                            ],
                                          },
                                        ];

                                        setCarouselTemplate({
                                          ...carouselTemplate,
                                          carouselCards: updatedCarouselCards,
                                        });
                                      }}
                                    >
                                      {
                                        templateForm.templateContent
                                          .carouselFormItem.cards.addCard
                                      }
                                    </Button>
                                  </Space>
                                </Space>
                              }
                              size="small"
                              headStyle={{
                                backgroundColor: "#F4F7F9",
                              }}
                            >
                              <Space
                                direction="vertical"
                                style={{
                                  width: "100%",
                                }}
                                size="middle"
                              >
                                {!fields.length && (
                                  <Row gutter={[10, 8]}>
                                    <Col
                                      span={24}
                                      className="selection_form_item"
                                    >
                                      <Text
                                        type="secondary"
                                        style={{
                                          fontSize: 13,
                                        }}
                                      >
                                        {
                                          templateForm.templateContent
                                            .carouselFormItem.cards.description
                                        }
                                      </Text>
                                    </Col>
                                  </Row>
                                )}

                                {fields.map((cardField, cardIndex) => (
                                  <Collapse
                                    key={cardIndex}
                                    size="small"
                                    defaultActiveKey={[
                                      "0",
                                      "1",
                                      "2",
                                      "3",
                                      "4",
                                      "5",
                                      "6",
                                      "7",
                                      "8",
                                      "9",
                                    ]}
                                    className="template-carosal-card-form-item"
                                  >
                                    <Collapse.Panel
                                      header={
                                        <Space
                                          style={{
                                            width: "100%",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Text>
                                            {
                                              templateForm.templateContent
                                                .carouselFormItem.cards.card
                                            }{" "}
                                            {cardIndex + 1}
                                          </Text>
                                          <DeleteOutlined
                                            style={{ color: "#F05742" }}
                                            onClick={() => {
                                              remove(cardIndex);
                                              const updatedCarouselCards = [
                                                ...carouselTemplate.carouselCards,
                                              ];

                                              updatedCarouselCards.splice(
                                                cardIndex,
                                                1
                                              );

                                              setCarouselTemplate({
                                                ...carouselTemplate,
                                                carouselCards:
                                                  updatedCarouselCards,
                                              });
                                            }}
                                          />
                                        </Space>
                                      }
                                      key={cardField.key}
                                    >
                                      <Row gutter={[0, 16]} key={cardField.key}>
                                        {/* Render card header UI */}
                                        <Col span={24}>
                                          <Card
                                            title={
                                              <Space
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Text>
                                                  {" "}
                                                  {
                                                    templateForm.templateContent
                                                      .carouselFormItem.cards
                                                      .header.title
                                                  }{" "}
                                                </Text>
                                                <Space size="middle">
                                                  <Select
                                                    size="small"
                                                    {...cardField}
                                                    value={
                                                      carouselTemplate
                                                        .carouselCards?.[
                                                        cardIndex
                                                      ]?.headerType
                                                    }
                                                    style={{
                                                      width: 130,
                                                    }}
                                                    onChange={(value) => {
                                                      setCarouselTemplate({
                                                        ...carouselTemplate,
                                                        carouselCards:
                                                          carouselTemplate.carouselCards.map(
                                                            (card, i) => {
                                                              if (
                                                                i === cardIndex
                                                              ) {
                                                                return {
                                                                  ...card,
                                                                  headerType:
                                                                    value,
                                                                };
                                                              } else {
                                                                return card;
                                                              }
                                                            }
                                                          ),
                                                      });
                                                    }}
                                                  >
                                                    <Option value="image">
                                                      {
                                                        templateForm
                                                          .templateContent
                                                          .carouselFormItem
                                                          .cards.header.image
                                                      }
                                                    </Option>
                                                    {/* <Option value="video">
                                                     {
                                                      templateForm.templateContent
                                                        .carouselFormItem.cards
                                                        .header.video
                                                     }
                                                    </Option> */}
                                                  </Select>
                                                </Space>
                                              </Space>
                                            }
                                            size="small"
                                            headStyle={{
                                              backgroundColor: "#F4F7F9",
                                            }}
                                          >
                                            <Space
                                              style={{
                                                width: "100%",
                                                textAlign: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {carouselTemplate.carouselCards?.[
                                                cardIndex
                                              ]?.headerType === "image" ? (
                                                <Image
                                                  src="/images/image-placeholder.png"
                                                  preview={false}
                                                  height={150}
                                                  width={230}
                                                />
                                              ) : (
                                                <Image
                                                  src="/images/video-placeholder.jpg"
                                                  preview={false}
                                                  height={150}
                                                  width={230}
                                                />
                                              )}
                                            </Space>
                                          </Card>
                                        </Col>

                                        {/* Render card body UI */}
                                        <Col span={24}>
                                          <Card
                                            title={
                                              <Space
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Text>
                                                  {
                                                    templateForm.templateContent
                                                      .carouselFormItem.cards
                                                      .body.title
                                                  }
                                                </Text>
                                              </Space>
                                            }
                                            size="small"
                                            headStyle={{
                                              backgroundColor: "#F4F7F9",
                                            }}
                                          >
                                            <Form.Item
                                              name={[
                                                cardField.name,
                                                "carouselCardBody",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    templateForm.templateContent
                                                      .carouselFormItem.cards
                                                      .body.required,
                                                },
                                                { whitespace: true },
                                                {
                                                  min: 5,
                                                  message:
                                                    templateForm.templateContent
                                                      .carouselFormItem.cards
                                                      .body.minimum,
                                                },
                                                {
                                                  max: 150,
                                                  message:
                                                    templateForm.templateContent
                                                      .carouselFormItem.cards
                                                      .body.maximum,
                                                },
                                                {
                                                  pattern: /^((?!\{\{).)*$/,
                                                  message:
                                                    templateForm.templateContent
                                                      .carouselFormItem.cards
                                                      .body.invalid,
                                                },
                                              ]}
                                              hasFeedback
                                              className="template-form-item-textarea"
                                            >
                                              <Input.TextArea
                                                placeholder={
                                                  templateForm.templateContent
                                                    .carouselFormItem.cards.body
                                                    .placeholder
                                                }
                                                autoSize={{ minRows: 2 }}
                                                showCount
                                                maxLength={150}
                                                onKeyDown={(e) => {
                                                  // don't allow { and } in the url
                                                  // after } is pressed, don't allow any other key

                                                  if (
                                                    e.key === "{" || // Condition 1: Check if the pressed key is "{"
                                                    e.key === "}" || // Condition 2: Check if the pressed key is "}"
                                                    (e.target.value.includes(
                                                      "}}"
                                                    ) &&
                                                      e.key !== "Backspace" &&
                                                      e.key !== "Delete")
                                                  ) {
                                                    e.preventDefault(); // Prevent the default behavior of the event unless it's "Backspace" or "Delete"
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                          </Card>
                                        </Col>

                                        {/* Render card buttons UI */}
                                        <Col span={24}>
                                          <Form.Item className="template-form-item">
                                            <Form.List
                                              name={[
                                                cardField.name,
                                                "carouselCardButtonList",
                                              ]}
                                              initialValue={[
                                                {
                                                  text: "",
                                                  url: "",
                                                  phoneNumber: "",
                                                  type: "url",
                                                },
                                              ]}
                                            >
                                              {(subFields, subOpt) => (
                                                <Card
                                                  size="small"
                                                  title={
                                                    <Space
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      {
                                                        templateForm
                                                          .templateContent
                                                          .carouselFormItem
                                                          .cards.buttons.title
                                                      }
                                                      <Space size="middle">
                                                        <Button
                                                          size="small"
                                                          icon={
                                                            <PlusOutlined
                                                              style={{
                                                                marginTop: 4,
                                                              }}
                                                            />
                                                          }
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                          disabled={
                                                            subFields.length ===
                                                            2
                                                          }
                                                          onClick={() => {
                                                            const updatedCarouselCard =
                                                              carouselTemplate
                                                                .carouselCards[
                                                                cardIndex
                                                              ];

                                                            const updatedButtons =
                                                              [
                                                                ...(updatedCarouselCard.buttons ||
                                                                  []),
                                                                {
                                                                  // add url button
                                                                  text: "",
                                                                  url: "",
                                                                  phoneNumber:
                                                                    "",
                                                                  type:
                                                                    updatedCarouselCard.buttons.some(
                                                                      (
                                                                        button
                                                                      ) =>
                                                                        button.type ===
                                                                        "url"
                                                                    ) &&
                                                                    updatedCarouselCard.buttons.some(
                                                                      (
                                                                        button
                                                                      ) =>
                                                                        button.type ===
                                                                        "phoneNumber"
                                                                    )
                                                                      ? "quickReply"
                                                                      : updatedCarouselCard.buttons.some(
                                                                          (
                                                                            button
                                                                          ) =>
                                                                            button.type ===
                                                                            "url"
                                                                        )
                                                                      ? "phoneNumber"
                                                                      : "url",
                                                                },
                                                              ];

                                                            updatedCarouselCard.buttons =
                                                              updatedButtons;

                                                            setCarouselTemplate(
                                                              {
                                                                ...(carouselTemplate ||
                                                                  {}),
                                                                carouselCards:
                                                                  carouselTemplate.carouselCards.map(
                                                                    (
                                                                      card,
                                                                      i
                                                                    ) => {
                                                                      if (
                                                                        i ===
                                                                        cardIndex
                                                                      ) {
                                                                        return updatedCarouselCard;
                                                                      } else {
                                                                        return card;
                                                                      }
                                                                    }
                                                                  ),
                                                              }
                                                            );

                                                            subOpt.add();
                                                          }}
                                                        >
                                                          {
                                                            templateForm
                                                              .templateContent
                                                              .carouselFormItem
                                                              .cards.buttons
                                                              .addButton
                                                          }
                                                        </Button>
                                                      </Space>
                                                    </Space>
                                                  }
                                                  headStyle={{
                                                    backgroundColor: "#F4F7F9",
                                                  }}
                                                >
                                                  <Space
                                                    direction="vertical"
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    size="middle"
                                                  >
                                                    {subFields.map(
                                                      (
                                                        subField,
                                                        buttonIndex
                                                      ) => (
                                                        <Card
                                                          key={subField.key}
                                                          title={
                                                            <Space
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "space-between",
                                                                width: "100%",
                                                              }}
                                                              align="center"
                                                            >
                                                              <Text>
                                                                {templateForm
                                                                  .templateContent
                                                                  .carouselFormItem
                                                                  .cards.buttons
                                                                  .button +
                                                                  " " +
                                                                  (buttonIndex +
                                                                    1)}
                                                              </Text>

                                                              <Space size="middle">
                                                                <Select
                                                                  size="small"
                                                                  {...subField}
                                                                  value={
                                                                    carouselTemplate
                                                                      .carouselCards[
                                                                      cardIndex
                                                                    ]?.buttons[
                                                                      buttonIndex
                                                                    ]?.type
                                                                  }
                                                                  style={{
                                                                    width: 130,
                                                                  }}
                                                                  onChange={(
                                                                    value
                                                                  ) => {
                                                                    const updatedCarouselCard =
                                                                      carouselTemplate
                                                                        .carouselCards[
                                                                        cardIndex
                                                                      ];
                                                                    const updatedButtons =
                                                                      updatedCarouselCard.buttons.map(
                                                                        (
                                                                          button,
                                                                          i
                                                                        ) => {
                                                                          if (
                                                                            i ===
                                                                            buttonIndex
                                                                          ) {
                                                                            return {
                                                                              ...button,
                                                                              text: undefined,
                                                                              url: undefined,
                                                                              phoneNumber:
                                                                                undefined,
                                                                              type: value,
                                                                            };
                                                                          } else {
                                                                            return button;
                                                                          }
                                                                        }
                                                                      );

                                                                    updatedCarouselCard.buttons =
                                                                      updatedButtons;

                                                                    setCarouselTemplate(
                                                                      {
                                                                        ...carouselTemplate,
                                                                        carouselCards:
                                                                          carouselTemplate.carouselCards.map(
                                                                            (
                                                                              card,
                                                                              i
                                                                            ) => {
                                                                              if (
                                                                                i ===
                                                                                cardIndex
                                                                              ) {
                                                                                return updatedCarouselCard;
                                                                              } else {
                                                                                return card;
                                                                              }
                                                                            }
                                                                          ),
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <Option value="url">
                                                                    {
                                                                      templateForm
                                                                        .urlButtonLabelFormItem
                                                                        .title
                                                                    }
                                                                  </Option>
                                                                  <Option value="phoneNumber">
                                                                    {
                                                                      templateForm
                                                                        .phoneNumberButtonLabelFormItem
                                                                        .title
                                                                    }
                                                                  </Option>
                                                                  <Option value="quickReply">
                                                                    {
                                                                      templateForm
                                                                        .quickReplyButtonFormItem
                                                                        .title
                                                                    }
                                                                  </Option>
                                                                </Select>

                                                                <DeleteOutlined
                                                                  style={{
                                                                    color:
                                                                      subFields.length ===
                                                                      1
                                                                        ? "grey"
                                                                        : "#F05742",
                                                                    cursor:
                                                                      subFields.length ===
                                                                      1
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                  }}
                                                                  onClick={() => {
                                                                    if (
                                                                      subFields.length ===
                                                                      1
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    subOpt.remove(
                                                                      subField.name
                                                                    );

                                                                    const updatedCarouselCard =
                                                                      carouselTemplate
                                                                        .carouselCards[
                                                                        cardIndex
                                                                      ];
                                                                    const updatedButtons =
                                                                      updatedCarouselCard.buttons;

                                                                    updatedButtons.splice(
                                                                      buttonIndex,
                                                                      1
                                                                    );

                                                                    updatedCarouselCard.buttons =
                                                                      updatedButtons;

                                                                    setCarouselTemplate(
                                                                      {
                                                                        ...carouselTemplate,
                                                                        carouselCards:
                                                                          carouselTemplate.carouselCards.map(
                                                                            (
                                                                              card,
                                                                              i
                                                                            ) => {
                                                                              if (
                                                                                i ===
                                                                                cardIndex
                                                                              ) {
                                                                                return updatedCarouselCard;
                                                                              } else {
                                                                                return card;
                                                                              }
                                                                            }
                                                                          ),
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              </Space>
                                                            </Space>
                                                          }
                                                          size="small"
                                                          headStyle={{
                                                            backgroundColor:
                                                              "#F4F7F9",
                                                          }}
                                                        >
                                                          {/* Based on selected dropdown form item should render */}
                                                          {carouselTemplate
                                                            .carouselCards[
                                                            cardIndex
                                                          ]?.buttons[
                                                            buttonIndex
                                                          ]?.type ===
                                                            "quickReply" && (
                                                            <QuickReplyButtonFormItem
                                                              buttonIndex={
                                                                buttonIndex
                                                              }
                                                            />
                                                          )}

                                                          {carouselTemplate
                                                            .carouselCards[
                                                            cardIndex
                                                          ]?.buttons[
                                                            buttonIndex
                                                          ]?.type === "url" && (
                                                            <Space
                                                              key={buttonIndex}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              size={0}
                                                              direction="vertical"
                                                            >
                                                              <URLButtonLabelFormItem
                                                                buttonIndex={
                                                                  buttonIndex
                                                                }
                                                              />

                                                              <URLButtonValueFormItem
                                                                buttonIndex={
                                                                  buttonIndex
                                                                }
                                                              />
                                                            </Space>
                                                          )}

                                                          {carouselTemplate
                                                            .carouselCards[
                                                            cardIndex
                                                          ]?.buttons[
                                                            buttonIndex
                                                          ]?.type ===
                                                            "phoneNumber" && (
                                                            <Space
                                                              key={buttonIndex}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              size={0}
                                                              direction="vertical"
                                                            >
                                                              <PhoneNumberButtonLabelFormItem
                                                                buttonIndex={
                                                                  buttonIndex
                                                                }
                                                              />

                                                              <PhoneNumberButtonValueFormItem
                                                                buttonIndex={
                                                                  buttonIndex
                                                                }
                                                              />
                                                            </Space>
                                                          )}
                                                        </Card>
                                                      )
                                                    )}
                                                  </Space>

                                                  {subFields.length !== 0 && (
                                                    <Text
                                                      style={{
                                                        display: "block",
                                                        textAlign: "right",
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      {subFields.length +
                                                        " / 2"}
                                                    </Text>
                                                  )}
                                                </Card>
                                              )}
                                            </Form.List>
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Collapse.Panel>
                                  </Collapse>
                                ))}
                              </Space>

                              {fields.length !== 0 && (
                                <Text
                                  style={{
                                    display: "block",
                                    textAlign: "right",
                                    marginTop: 10,
                                  }}
                                >
                                  {fields.length + " / 10"}
                                </Text>
                              )}
                            </Card>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  </Card>
                ) : templateType === "catalog" ? (
                  <Card size="small">
                    <Row gutter={[0, 15]}>
                      <Col span={1}>
                        <PiSquaresFour color="#F05742" size={20} />
                      </Col>
                      <Col span={23}>
                        <Text strong>
                          {
                            templateForm.templateContent
                              .mediaInteractiveFormItem.buildingBlocks.title
                          }
                        </Text>
                      </Col>

                      <Col span={24}>
                        <CustomParagraph paragraph="Catalog template empowers businesses to seamlessly showcase products within the app, enhancing customer engagement and enabling convenient shopping experiences." />
                      </Col>

                      <Col span={24}>
                        <Space direction="vertical" size={1}>
                          <CustomTitle title="Requirements" />

                          <CustomParagraph
                            paragraph="You must have inventory uploaded to Meta in an ecommerce catalog connected to your WhatsApp Business Account."
                            urlLabel="Learn more about setting up your catalog."
                            urlValue={`${URIs.WABA}/products/catalogs/new`}
                          />
                        </Space>
                      </Col>

                      <Col span={24}>
                        <TemplateBodyFormItem />
                      </Col>

                      <Col span={24}>
                        <TemplateFooterFormItem placeholder="Type your footer text. Ex: Best grocery deals on WhatsApp!" />
                      </Col>

                      <Col span={24}>
                        <Card
                          size="small"
                          title="BUTTON"
                          headStyle={{
                            backgroundColor: "#F4F7F9",
                          }}
                        >
                          <QuickReplyButtonFormItem placeholder="Type your button label. Ex: View catalog" />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                ) : templateType === "limitedTimeOffer" ? (
                  <Card size="small">
                    <Row gutter={[0, 15]}>
                      <Col span={1}>
                        <PiSquaresFour color="#F05742" size={20} />
                      </Col>
                      <Col span={23}>
                        <Text strong>
                          {
                            templateForm.templateContent
                              .mediaInteractiveFormItem.buildingBlocks.title
                          }
                        </Text>
                      </Col>

                      <Col span={24}>
                        <CustomParagraph paragraph="Limited-time offer templates allow you to display expiration dates and running countdown timers for offer codes in template messages, making it easy for you to communicate time-bound offers and drive customer engagement." />
                      </Col>

                      <Col span={24}>
                        <TemplateHeaderFormItem />
                      </Col>

                      <Col span={24}>
                        <Card
                          title="LIMITED-TIME OFFER"
                          size="small"
                          headStyle={{
                            backgroundColor: "#F4F7F9",
                          }}
                        >
                          <Row gutter={[0, 15]}>
                            <Col span={24}>
                              <Card
                                title="HEADER"
                                size="small"
                                headStyle={{
                                  backgroundColor: "#F4F7F9",
                                }}
                              >
                                <Form.Item
                                  name="limitedTimeOfferHeading"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter the header text!",
                                    },
                                    { whitespace: true },
                                    {
                                      min: 5,
                                      message: "Minimum 5 characters required!",
                                    },
                                    {
                                      max: 16,
                                      message:
                                        "Header text should be less than 16 characters!",
                                    },
                                  ]}
                                  hasFeedback
                                  className="template-form-item"
                                >
                                  <Input
                                    placeholder="Type your header text. Ex: Buy 1 Get 1 Free"
                                    showCount
                                    maxLength={16}
                                  />
                                </Form.Item>
                              </Card>
                            </Col>

                            <Col span={24}>
                              <Card
                                title="EXPIRATION DATE"
                                size="small"
                                headStyle={{
                                  backgroundColor: "#F4F7F9",
                                }}
                              >
                                <Row gutter={[0, 15]}>
                                  <Col span={24}>
                                    <CustomParagraph paragraph="Offer expiration is optional. If you don't set an expiration date, the offer will be displayed without a countdown timer. If you set an expiration date, the text will change to red if the message is viewed and the offer code is expiring within the next hour." />
                                  </Col>

                                  <Col span={24}>
                                    <Form.Item
                                      name="limitedTimeOfferExpirationDate"
                                      hasFeedback
                                      className="template-form-item"
                                    >
                                      <Checkbox
                                        defaultChecked={
                                          limitedTimeOfferTemplate.hasExpiration
                                        }
                                        onChange={(e) => {
                                          setLimitedTimeOfferTemplate({
                                            ...limitedTimeOfferTemplate,
                                            hasExpiration:
                                              !limitedTimeOfferTemplate.hasExpiration,
                                          });
                                        }}
                                      >
                                        Set expiration date for the offer code
                                        (optional)
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>

                                  {limitedTimeOfferTemplate.hasExpiration && (
                                    <Col span={24}>
                                      <CustomParagraph paragraph="You can set the expiration date and time while doing the broadcast." />
                                    </Col>
                                  )}
                                </Row>
                              </Card>
                            </Col>

                            <Col span={24}>
                              <TemplateBodyFormItem />
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col span={24}>
                        <Card
                          size="small"
                          title="BUTTONS"
                          headStyle={{
                            backgroundColor: "#F4F7F9",
                          }}
                        >
                          <Row gutter={[0, 15]}>
                            <Col span={24}>
                              <Card
                                size="small"
                                title="COPY CODE BUTTON"
                                headStyle={{
                                  backgroundColor: "#F4F7F9",
                                }}
                              >
                                <Space direction="vertical">
                                  <CustomParagraph paragraph="Copy code button label can't be modifiable. It will be displayed as 'Copy offer code' in the WhatsApp." />

                                  <CopyCodeButtonValueFormItem />

                                  <CustomParagraph paragraph="While doing the broadcast, you can set the actual offer code." />
                                </Space>
                              </Card>
                            </Col>

                            <Col span={24}>
                              <Card
                                size="small"
                                title="URL BUTTON"
                                headStyle={{
                                  backgroundColor: "#F4F7F9",
                                }}
                              >
                                <Space
                                  key={1}
                                  style={{
                                    width: "100%",
                                  }}
                                  size={0}
                                  direction="vertical"
                                >
                                  <URLButtonLabelFormItem placeholder="Button label here. Ex: Start Shopping" />

                                  <URLButtonValueFormItem placeholder="Button redirect URL here. Ex: https://www.yourwebsite.com" />
                                </Space>
                              </Card>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                ) : null}
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Action Buttons */}
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Divider />
          <Space size="middle">
            <Form.Item>
              <Button
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to close the form?",
                    content:
                      "When clicked the OK button, the unsaved form inputs will be lost",
                    onOk() {
                      dispatchReducer({
                        type: "CLOSE_FORM_PANEL",
                        payload: {
                          component: "message-template-form-panel",
                        },
                      });
                    },
                    onCancel() {},
                  });
                }}
              >
                {templateForm.formFooter.cancel.buttonText}
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  state.apiCallStatus?.["whatsapp-flows-assets"]?.loading
                }
              >
                {templateForm.formFooter.previewAndSubmit.buttonText}
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  ) : (
    <MessageTemplatePreviewPanel
      templateHeader={templateHeader}
      templateFooter={templateFooter}
      templateButtons={templateButtons}
      buttons={buttons}
      templateType={templateType}
      templateCarouselCards={templateCarouselCards}
      setTemplateCarouselCards={setTemplateCarouselCards}
      headerPlaceHolders={headerPlaceHolders}
      bodyPlaceHolders={bodyPlaceHolders}
      urlPlaceHolders={urlPlaceHolders}
      headerMapping={headerMapping}
      bodyMapping={bodyMapping}
      urlMapping={urlMapping}
      preview={preview}
      setPreview={setPreview}
      handleReplaceHeaderPlaceHolder={handleReplaceHeaderPlaceHolder}
      handleReplaceBodyPlaceHolder={handleReplaceBodyPlaceHolder}
      handleReplaceUrlPlaceHolder={handleReplaceUrlPlaceHolder}
      handleSubmitTemplate={handleSubmitTemplate}
      isDisableTemplateCreation={isDisableTemplateCreation}
      setShowPreview={setShowPreview}
    />
  );
};

export default MessageTemplateForm;
