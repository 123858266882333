const broadcastSettingsReducer = (state, action) => {
  switch (action.type) {
    case "SET_BROADCAST_SETTINGS":
      return {
        ...state,
        broadcastSettings: action.payload.broadcastSettings,
      };

    case "CLEAR_BROADCAST_SETTINGS":
      return {
        ...state,
        broadcastSettings: null,
      };

    default:
      return state;
  }
};

export default broadcastSettingsReducer;
