import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Space, Input, Divider, Tooltip, Row, Col } from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { RiFilterOffLine } from "react-icons/ri";
import { TiArrowSync } from "react-icons/ti";
import { BsCurrencyDollar } from "react-icons/bs";
import { convertObjectToString } from "../../utils/utility";
import { Translation } from "../../translation/translation";

const BroadcastManagerHeader = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const translation = Translation().broadcastManager.header;

  const allowedTimeRange = state.broadcastSettings?.allowedTimeRange;

  const isWithinTimeRange = () => {
    const currentTime = moment();
    const fromTime = moment(allowedTimeRange?.fromTime, "h:mm a");
    const toTime = moment(allowedTimeRange?.toTime, "h:mm a");

    return currentTime.isBetween(fromTime, toTime);
  };

  // Convert filter object to string
  const filteredValue = convertObjectToString(
    state.filters.filteredInfo?.["broadcast-manager-table"] || {}
  );
  const sortedValue =
    state.filters.sortedInfo?.["broadcast-manager-table"] || {};
  const pagination =
    state.filters.pagination?.["broadcast-manager-table"] || {};

  return (
    <Row>
      <Col span={18}>
        <Space>
          <Input
            disabled={state.broadcastManager.broadcasts.length === 0}
            prefix={<SearchOutlined />}
            placeholder="Search by Broadcast & Template names"
            value={
              state.filters.searchText["broadcast-manager-search-text-box"] &&
              state.filters.searchText["broadcast-manager-search-text-box"]
            }
            style={{ width: 400 }}
            onChange={(e) => {
              const currValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "broadcast-manager-search-text-box",
                  searchText: currValue,
                },
              });
            }}
            onPressEnter={(e) => {
              dispatchMiddleware({
                type: "LOAD_BROADCASTS_DATA",
                payload: {
                  component: "broadcast-manager-table",
                  filteredValue,
                  ...sortedValue,
                  searchText: e.target.value,
                  pageSize: pagination.pageSize,
                },
              });

              dispatchReducer({
                type: "RD_SET_PAGINATION",
                payload: {
                  component: "broadcast-manager-table",
                  pagination: {
                    ...state.filters.pagination?.["broadcast-manager-table"],
                    current: 1,
                  },
                },
              });
            }}
            allowClear={{
              clearIcon: (
                <CloseOutlined
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_SEARCH_TEXT",
                      payload: {
                        component: "broadcast-manager-search-text-box",
                        searchText: "",
                      },
                    });

                    dispatchMiddleware({
                      type: "LOAD_BROADCASTS_DATA",
                      payload: {
                        component: "broadcast-manager-table",
                        pageNumber: 1,
                        pageSize: pagination?.pageSize,
                        filteredValue,
                        ...sortedValue,
                        searchText: "",
                      },
                    });

                    dispatchReducer({
                      type: "RD_SET_PAGINATION",
                      payload: {
                        component: "broadcast-manager-table",
                        pagination: {
                          ...state.filters.pagination?.[
                            "broadcast-manager-table"
                          ],
                          current: 1,
                        },
                      },
                    });
                  }}
                />
              ),
            }}
          />

          <Divider type="vertical" />

          <Tooltip placement="top" title="Clear filter(s)">
            <Button
              disabled={
                state.filters.filteredInfo["broadcast-manager-table"]
                  ? state.filters.filteredInfo["broadcast-manager-table"]
                      .sentDate ||
                    state.filters.filteredInfo["broadcast-manager-table"]
                      .channel
                    ? false
                    : true
                  : true
              }
              icon={<RiFilterOffLine style={{ margin: -2 }} />}
              onClick={() => {
                dispatchReducer({
                  type: "RD_SET_FILTERED_INFO",
                  payload: {
                    component: "broadcast-manager-table",
                    filteredInfo: {},
                  },
                });

                dispatchMiddleware({
                  type: "LOAD_BROADCASTS_DATA",
                  payload: {
                    component: "broadcast-manager-table",
                    pageNumber: 1,
                    pageSize: pagination?.pageSize,
                    ...sortedValue,

                    searchText:
                      state.filters.searchText?.[
                        "broadcast-manager-search-text-box"
                      ],
                  },
                });

                dispatchReducer({
                  type: "RD_SET_PAGINATION",
                  payload: {
                    component: "broadcast-manager-table",
                    pagination: {
                      ...pagination,
                      current: 1,
                    },
                  },
                });
              }}
            />
          </Tooltip>

          <Tooltip placement="top" title="Refresh Data">
            <Button
              icon={<TiArrowSync size={18} style={{ margin: -3 }} />}
              onClick={() => {
                dispatchMiddleware({
                  type: "LOAD_BROADCASTS_DATA",
                  payload: {
                    component: "broadcast-manager-table",
                    pageNumber: 1,
                    pageSize: pagination?.pageSize,
                    filteredValue,
                    ...sortedValue,
                    searchText:
                      state.filters.searchText?.[
                        "broadcast-manager-search-text-box"
                      ],
                  },
                });

                dispatchReducer({
                  type: "RD_SET_PAGINATION",
                  payload: {
                    component: "broadcast-manager-table",
                    pagination: {
                      ...pagination,
                      current: 1,
                    },
                  },
                });
              }}
            />
          </Tooltip>

          <Tooltip
            placement="top"
            title={translation.broadcastPricingCalculator.infoText}
          >
            <Button
              icon={<BsCurrencyDollar size={18} style={{ margin: -4 }} />}
              onClick={() => {
                dispatchReducer({
                  type: "OPEN_FORM_PANEL",
                  payload: {
                    component: "broadcast-pricing-calculator-form-panel",
                    title: translation.broadcastPricingCalculator.title,
                  },
                });

                !state.broadcastManager.broadcastPriceList &&
                  dispatchMiddleware({
                    type: "LOAD_BROADCAST_PRICE_LIST",
                    payload: {
                      component: "broadcast-pricing-calculator-form-panel",
                    },
                  });
              }}
            />
          </Tooltip>

          <Tooltip
            placement="top"
            title={translation.broadcastSettings.infoText}
          >
            <Button
              icon={
                <SettingOutlined
                  onClick={() => {
                    // Navigate to responses settings
                    navigate("/account-settings/broadcast");
                    sessionStorage.setItem(
                      "selectedMenu",
                      "/account-settings/broadcast"
                    );
                  }}
                  style={{
                    margin: -2,
                  }}
                />
              }
            />
          </Tooltip>
        </Space>
      </Col>

      <Col span={6} style={{ textAlign: "right" }}>
        <Tooltip
          placement="topRight"
          title={
            state.currentUser.permission.BROADCAST_CREATE
              ? allowedTimeRange
                ? isWithinTimeRange()
                  ? "Create Broadcast"
                  : `Broadcast allowed from ${allowedTimeRange?.fromTime} to ${allowedTimeRange?.toTime} only`
                : "Create Broadcast"
              : "You do not have the required permission"
          }
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={
              !state.currentUser.permission.BROADCAST_CREATE ||
              (allowedTimeRange && !isWithinTimeRange())
            }
            onClick={() => {
              dispatchReducer({
                type: "OPEN_FORM_PANEL",
                payload: {
                  component: "broadcast-form-panel",
                  title: "Create New Broadcast",
                },
              });
              dispatchMiddleware({
                type: "MWD_LOAD_AUTOMATED_REPLY_DATA",
                payload: { component: "automated-replies-table" },
              });
            }}
          >
            Create Broadcast
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default BroadcastManagerHeader;
