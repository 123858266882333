import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Tabs, Row, Col, Layout } from "antd";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CopyOutlined } from "@ant-design/icons";
import CannedResponsesMenu from "./CannedResponsesMenu";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import CannedResponsesList from "./CannedResponsesList";
import { useGlobalContext } from "../../../contexts/globalContext";
import AccessDenied from "../../../shared-components/AccessDenied";
import GetStarted from "../../../shared-components/GetStarted";

const CannedResponses = ({
  type,
  setShowCannedResponses,
  setSelectedMessage,
  selectedMessage,
}) => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const [showCannedResponsesScreen, setShowCannedResponsesScreen] =
    useState(false);

  useConnectMiddleware(() => {
    state.currentUser.permission.CANNED_RESPONSES_VIEW &&
      dispatchMiddleware({
        type: "MWD_LOAD_CANNED_RESPONSES_DATA",
        payload: { component: "canned-responses-table" },
      });

    dispatchMiddleware({
      type: "MWD_LOAD_CANNED_RESPONSES_FOLDER_LIST",
      payload: { component: "canned-responses-table" },
    });
  });

  return (
    <>
      {type !== "cannedResponse" && (
        <Space
          size={15}
          style={{ cursor: "pointer", marginBottom: 10 }}
          onClick={() => navigate("/account-settings")}
        >
          <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
          Go back to Settings
        </Space>
      )}

      {!state.currentUser.permission.CANNED_RESPONSES_VIEW ? (
        <AccessDenied />
      ) : state.cannedResponses.liveAgentCannedResponses &&
        state.cannedResponses.liveAgentCannedResponses.length === 0 &&
        !showCannedResponsesScreen ? (
        <Layout.Content
          style={{
            height: "97%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GetStarted
            source="canned-responses"
            setShowCannedResponsesScreen={setShowCannedResponsesScreen}
          />
        </Layout.Content>
      ) : (state.cannedResponses.liveAgentCannedResponses &&
          state.cannedResponses.cannedResponsesFolderList) ||
        showCannedResponsesScreen ? (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <CopyOutlined size={18} style={{ margin: -4 }} />
                  Canned Responses
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={4}>
                    <CannedResponsesMenu type={type} />
                  </Col>
                  <Col
                    offset={type === "cannedResponse" ? 1 : ""}
                    span={type === "cannedResponse" ? 18 : 20}
                  >
                    <CannedResponsesList
                      type={type}
                      setShowCannedResponses={setShowCannedResponses}
                      setSelectedMessage={setSelectedMessage}
                      selectedMessage={selectedMessage}
                    />
                  </Col>
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["canned-responses-table"] &&
            state.apiCallStatus["canned-responses-table"].loading && (
              <TabLoader
                component="canned-responses-table"
                loadingMessage="Synchronizing canned responses..."
                errorMessage="Error synchronizing canned responses"
              />
            )
          }
        />
      ) : (
        <FullPageLoader
          component="canned-responses-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};
export default CannedResponses;
