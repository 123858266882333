import { useNavigate } from "react-router-dom";
import { Row, Col, Tabs, Space } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import BroadcastForm from "./BroadcastForm";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { BiBroadcast } from "react-icons/bi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AccessDenied from "../../../shared-components/AccessDenied";
import ModuleNotSubscribed from "../../../shared-components/ModuleNotSubscribed";

const Brodcast = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();

  useConnectMiddleware(
    () => {
      if (
        state.currentAccount.account.modules.broadcast.enabled &&
        state.currentUser.permission.ACCOUNT_BROADCAST_VIEW
      ) {
        state.broadcastSettings &&
          dispatchReducer({
            type: "CLEAR_BROADCAST_SETTINGS",
          });

        dispatchMiddleware({
          type: "MWD_LOAD_BROADCAST_SETTINGS",
          payload: {
            component: "broadcast-settings",
          },
        });
      }
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      <Tabs
        type="card"
        items={[
          {
            key: "1",
            label: (
              <Space size={15}>
                <BiBroadcast size={18} style={{ margin: -4 }} />
                Broadcast
              </Space>
            ),
            children: state.currentAccount.account.modules.broadcast.enabled ? (
              state.currentUser.permission.ACCOUNT_BROADCAST_VIEW ? (
                <>
                  {state.broadcastSettings ? (
                    <Row>
                      <Col span={24}>
                        <BroadcastForm />
                      </Col>
                    </Row>
                  ) : (
                    <FullPageLoader
                      component="broadcast-settings"
                      loadingMessage="Loading... Please wait..."
                    />
                  )}
                </>
              ) : (
                <AccessDenied />
              )
            ) : (
              <ModuleNotSubscribed module="broadcast" />
            ),
          },
        ]}
        tabBarExtraContent={
          state.apiCallStatus["broadcast-settings"]?.loading && (
            <TabLoader
              component="broadcast-settings"
              loadingMessage="Synchronizing broadcast..."
              errorMessage="Error synchronizing broadcast"
            />
          )
        }
      />
    </>
  );
};

export default Brodcast;
