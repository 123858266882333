import React from "react";
import { Space, Typography } from "antd";

const { Text } = Typography;

const ConnectAPISource = () => {
  return (
    <Space className="label-file-upload">
      <Space direction="vertical" size={0}>
        <Text>Click here to pull data from</Text>
        <Text strong>External Sources (API)</Text>
      </Space>
    </Space>
  );
};

export default ConnectAPISource;
