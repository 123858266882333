import { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Row,
  Col,
  Avatar,
  Space,
  Form,
  Input,
  Divider,
  Button,
  Select,
  Upload,
  Modal,
  Dropdown,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import { timeZone, dateFormat, languageSelector } from "../../constants/enums";
import { customNotificationTone } from "../../constants/enums";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { compressAccurately } from "image-conversion";

const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

const CurrentUserForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [form] = Form.useForm();
  const [userProfilePic, setUserProfilePic] = useState(undefined);
  const [notificationAlertTone, setNotificationAlertTone] = useState(undefined);
  const [notificationMessageTone, setNotificationMessageTone] =
    useState(undefined);
  const [playAlertTone, setPlayAlertTone] = useState("Play");
  const [alertNotification, setAlertNotification] = useState();
  const [playMessageTone, setPlayMessageTone] = useState("Play");
  const [messageNotification, setMessageNotification] = useState();

  form.setFieldsValue({
    firstName: state.currentUser.user.firstName,
    lastName: state.currentUser.user.lastName,
    displayName: state.currentUser.user.displayName,
    email: state.currentUser.user.email,
    mobileNumber: state.currentUser.user.mobileNumber,
    language: state.currentUser.user.language,
    dateFormat: state.currentUser.user.dateFormat,
    timeZone: state.currentUser.user.timeZone,
    profilePicture: state.currentUser.user.profilePicture,
    alertTone: state.currentUser.user.alertTone,
    messageTone: state.currentUser.user.messageTone,
  });

  useEffect(() => {
    state.currentUser.user.profilePicture &&
      setUserProfilePic(state.currentUser.user.profilePicture);

    state.currentUser.user.alertTone &&
      setNotificationAlertTone(state.currentUser.user.alertTone);

    state.currentUser.user.messageTone &&
      setNotificationMessageTone(state.currentUser.user.messageTone);
  }, [
    state.currentUser.user.profilePicture,
    state.currentUser.user.alertTone,
    state.currentUser.user.messageTone,
  ]);

  const handleUpdateUserProfile = async (e) => {
    const formUserProfile = {
      firstName: e.firstName,
      lastName: e.lastName,
      displayName: e.displayName,
      email: e.email,
      mobileNumber: e.mobileNumber,
      language: e.language,
      dateFormat: e.dateFormat,
      timeZone: e.timeZone,
      profilePicture: userProfilePic,
      userID: state.currentUser.user.userID,
      role: state.currentUser.user.role,
      alertTone: notificationAlertTone,
      messageTone: notificationMessageTone,
    };

    dispatchMiddleware({
      type: "MWD_UPDATE_CURRENT_USER",
      payload: {
        user: formUserProfile,
        component: "current-user-form",
      },
    });
  };

  const handleUploadProfilePic = async (e) => {
    const files = e.file;
    compressAccurately(files, 100).then(async (res) => {
      const base64 = await convertBase64(res);
      setUserProfilePic(base64);
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    let alertTone;
    let messageTone;

    setAlertNotification(new Audio(notificationAlertTone));
    setMessageNotification(new Audio(notificationMessageTone));

    if (alertTone) {
      alertTone.pause();
      setPlayAlertTone("Play");
    }
    if (notificationAlertTone) {
      alertTone = new Audio(notificationAlertTone);
      setAlertNotification(alertTone);
      alertTone.onended = () => {
        setPlayAlertTone("Play");
      };
    }

    if (messageTone) {
      messageTone.pause();
      setPlayMessageTone("Play");
    }
    if (notificationMessageTone) {
      messageTone = new Audio(notificationMessageTone);
      setMessageNotification(messageTone);
      messageTone.onended = () => {
        setPlayMessageTone("Play");
      };
    }
  }, [notificationAlertTone, notificationMessageTone]);

  const handleAlertToneClick = () => {
    if (playAlertTone === "Play") {
      alertNotification.play(notificationAlertTone);
      setPlayAlertTone("Pause");
    } else {
      alertNotification.pause();
      setPlayAlertTone("Play");
    }
  };

  const handleMessageToneClick = () => {
    if (playMessageTone === "Play") {
      messageNotification.play(notificationMessageTone);
      setPlayMessageTone("Pause");
    } else {
      messageNotification.pause();
      setPlayMessageTone("Play");
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <Form.Item name="profilePicture" style={{ marginBottom: 0 }}>
          <Upload
            onChange={handleUploadProfilePic}
            showUploadList={false}
            onPreview={false}
            userProfilePic={userProfilePic}
            maxCount={1}
            beforeUpload={() => {
              return false;
            }}
            accept=".jpg, .jpeg, .webp, .png"
            style={{ height: 1 }}
          >
            Change Profile Picture
          </Upload>
        </Form.Item>
      ),
    },
    {
      key: "2",
      label: (
        <Space
          style={{ padding: "3px 0px 3px 0px" }}
          onClick={() => {
            confirm({
              title: "Are you sure you want to remove the profile picture?",
              content:
                "When clicked the OK button, the profile picture will be removed",
              onOk() {
                setUserProfilePic(undefined);
              },
              onCancel() {},
            });
          }}
        >
          Remove Profile Picture
        </Space>
      ),
    },
  ];

  return (
    <>
      {state.apiCallStatus["current-user-form"] &&
      state.apiCallStatus["current-user-form"].loading ? (
        <FullPageLoader
          component="current-user-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleUpdateUserProfile}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24} style={{ display: "flex" }}>
                  {userProfilePic ? (
                    <Avatar
                      name="profilePic"
                      size={120}
                      src={userProfilePic}
                      style={{ border: "1px solid #c5c5c5", margin: "auto" }}
                    />
                  ) : (
                    <Avatar
                      name="profilePic"
                      size={120}
                      icon={<UserOutlined />}
                      style={{ border: "1px solid #c5c5c5", margin: "auto" }}
                    />
                  )}
                </Col>

                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 40,
                  }}
                >
                  <Dropdown menu={{ items }} placement="bottomLeft">
                    <Space
                      style={{
                        textDecoration: "underline",
                        color: "#F05742",
                        cursor: "pointer",
                      }}
                    >
                      Edit Profile Picture
                    </Space>
                  </Dropdown>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                  { whitespace: true },
                  {
                    min: 3,
                    message: "First name should be more then 3 characters",
                  },
                  {
                    max: 35,
                    message: "First name should not be more then 20 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the first name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                  { whitespace: true },
                  {
                    min: 1,
                    message: "Last name should be more then 1 characters",
                  },
                  {
                    max: 25,
                    message: "Last name should not be more then 10 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the last name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="displayName"
                label="Display Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter display name",
                  },
                  { whitespace: true },
                  {
                    min: 3,
                    message: "Display name should be more then 3 characters",
                  },
                  {
                    max: 30,
                    message:
                      "Display name should not be more then 30 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the last name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    type: "email",
                    message: "Email should be a valid email address",
                  },
                  {
                    max: 50,
                    message: "Email id should not more then 50 characters",
                  },
                ]}
                hasFeedback
              >
                <Input
                  disabled={state.form.mode === "edit"}
                  placeholder="Enter the email"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                rules={[
                  {
                    pattern: /[+][1-9]\d+/,
                    message: "Please enter valid country code",
                  },
                  { whitespace: true },
                  {
                    max: 30,
                    message: "Mobile number should not more then 30 characters",
                  },
                ]}
                hasFeedback
                valuePropName="value"
              >
                <PhoneInput
                  className="phone-number-input-box"
                  placeholder="Enter the mobile number"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="language" label="Language">
                <Select
                  showSearch
                  placeholder="Select language"
                  options={languageSelector}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="dateFormat" label="Date Format">
                <Select
                  showSearch
                  placeholder="Select date format"
                  options={dateFormat}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="timeZone" label="Timezone" valuePropName="value">
                <Select
                  showSearch
                  placeholder="Select timezone"
                  options={timeZone}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="alertTone"
                label="Alert Tone"
                valuePropName="value"
              >
                <Space style={{ display: "flex" }}>
                  <Button
                    onClick={handleAlertToneClick}
                    disabled={notificationAlertTone ? false : true}
                  >
                    {playAlertTone === "Play" ? (
                      <CaretRightOutlined />
                    ) : (
                      <PauseOutlined />
                    )}
                  </Button>
                  <Select
                    defaultValue={state.currentUser.user.alertTone}
                    placeholder="Select alert tone"
                    allowClear
                    onChange={(e) => {
                      const currentAlertTone = e;
                      setNotificationAlertTone(currentAlertTone);
                    }}
                    style={{ width: "260px" }}
                  >
                    {customNotificationTone.map(
                      (notificationAlertTone, index) => {
                        return (
                          <Option key={index} value={notificationAlertTone.url}>
                            {notificationAlertTone.label}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Space>
                <Text type="secondary">
                  The selected tone will be played when a new live agent message
                  is arrived
                </Text>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="messageTone"
                label="Message Tone"
                valuePropName="value"
              >
                <Space style={{ display: "flex" }}>
                  <Button
                    onClick={handleMessageToneClick}
                    disabled={notificationMessageTone ? false : true}
                  >
                    {playMessageTone === "Play" ? (
                      <CaretRightOutlined />
                    ) : (
                      <PauseOutlined />
                    )}
                  </Button>
                  <Select
                    defaultValue={state.currentUser.user.messageTone}
                    placeholder="Select message tone"
                    allowClear
                    onChange={(e) => {
                      const currentMessageTone = e;
                      setNotificationMessageTone(currentMessageTone);
                    }}
                    style={{ width: "260px" }}
                  >
                    {customNotificationTone.map(
                      (notificationMessageTone, index) => {
                        return (
                          <Option
                            key={index}
                            value={notificationMessageTone.url}
                          >
                            {notificationMessageTone.label}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Space>
                <Text type="secondary">
                  The selected tone will be played when user message and bot
                  message is arrived.
                </Text>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={24}>
              <Space>
                <Form.Item>
                  <Button
                    onClick={() => {
                      dispatchReducer({
                        type: "CLOSE_FORM_PANEL",
                        payload: {
                          component: "user-profile-setting-form-panel",
                        },
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default CurrentUserForm;
