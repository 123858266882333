import { useEffect } from "react";
import { useGlobalContext } from "../../../../contexts/globalContext";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Typography,
  Divider,
  Space,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FullPageLoader } from "../../../../shared-components/LoadingIndicator";
import { Translation } from "../../../../translation/translation";

const { Text, Title } = Typography;

const AppRegistrationForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  // Get translated text
  const appRegistrationForm =
    Translation().accountSettings.api.appRegistration.form;
  const [form] = Form.useForm();

  let appRegistration = state.currentAccount.account.app_registration;

  const handleFormSubmit = (appRegistrationFormValues) => {
    dispatchMiddleware({
      type: "ADD_EDIT_APP_REGISTRATION",
      payload: {
        appRegistrationValues: appRegistrationFormValues,
        id: appRegistration?.id,
        component: "app-registration-form",
      },
    });
  };

  useEffect(() => {
    if (state.form?.["app-registration-form"]?.mode === "edit") {
      form.setFieldsValue({
        appName: appRegistration.appName,
        clientSecretName: appRegistration.clientSecretName,
        redirectUris: appRegistration?.redirectUris,
      });
    } else {
      form.setFieldsValue({
        redirectUris: [""],
      });
    }
  }, [state.form?.["app-registration-form"]?.mode]);

  return state.apiCallStatus["app-registration-form"] &&
    state.apiCallStatus["app-registration-form"].loading ? (
    <FullPageLoader
      component="app-registration-form"
      loadingMessage="Submitting... Please wait..."
    />
  ) : (
    <Form
      layout="vertical"
      autoComplete="off"
      onFinish={handleFormSubmit}
      form={form}
    >
      <Title level={5}>
        {state.form?.["app-registration-form"]?.mode === "edit"
          ? state.formPanel["app-registration-form"].title
          : "Register New Application"}
      </Title>

      <Divider
        style={{
          margin: "16px 0",
        }}
      />

      <Row gutter={10}>
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{appRegistrationForm.appNameFormItem.title}</Text>
            <Text>{appRegistrationForm.appNameFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="appName"
                label="App Name"
                rules={[
                  {
                    required: true,
                    message: appRegistrationForm.appNameFormItem.required,
                  },
                  {
                    min: 3,
                    message: appRegistrationForm.appNameFormItem.minimum,
                  },
                  {
                    max: 100,
                    message: appRegistrationForm.appNameFormItem.maximum,
                  },
                ]}
              >
                <Input
                  placeholder={appRegistrationForm.appNameFormItem.placeholder}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {state.form?.["app-registration-form"]?.mode !== "edit" && (
          <Col span={6}>
            <Space direction="vertical">
              <Text strong>
                {appRegistrationForm.clientSecretNameFormItem.title}
              </Text>
              <Text>
                {appRegistrationForm.clientSecretNameFormItem.description}
              </Text>
            </Space>
          </Col>
        )}

        {state.form?.["app-registration-form"]?.mode !== "edit" && (
          <Col span={15}>
            <Row>
              <Col offset={2} span={21}>
                <Form.Item
                  name="clientSecretName"
                  label={appRegistrationForm.clientSecretNameFormItem.title}
                  rules={[
                    {
                      required: true,
                      message:
                        appRegistrationForm.clientSecretNameFormItem.required,
                    },
                    {
                      min: 5,
                      message:
                        appRegistrationForm.clientSecretNameFormItem.minimum,
                    },
                    {
                      max: 100,
                      message:
                        appRegistrationForm.clientSecretNameFormItem.maximum,
                    },
                  ]}
                >
                  <Input placeholder="Enter the secret name" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}

        {state.form?.["app-registration-form"]?.mode !== "edit" && <Divider />}

        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{appRegistrationForm.redirectUriFormItem.title}</Text>
            <Text>{appRegistrationForm.redirectUriFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.List name="redirectUris">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row>
                        <Col span={23}>
                          <Form.Item
                            {...field}
                            key={index}
                            label={
                              index === 0 &&
                              appRegistrationForm.redirectUriFormItem.title
                            }
                            name={field.name}
                            rules={[
                              {
                                min: 5,
                                message:
                                  appRegistrationForm.redirectUriFormItem
                                    .minimum,
                              },
                              {
                                max: 200,
                                message:
                                  appRegistrationForm.redirectUriFormItem
                                    .maximum,
                              },
                              {
                                pattern: /^(https):\/\/[^\s$.?#].[^\s]*$/gm,
                                message:
                                  appRegistrationForm.redirectUriFormItem
                                    .invalid,
                              },
                            ]}
                            style={{
                              margin: 5,
                            }}
                          >
                            <Input
                              placeholder={
                                appRegistrationForm.redirectUriFormItem
                                  .placeholder
                              }
                            />
                          </Form.Item>
                        </Col>

                        {index !== 0 ? (
                          <DeleteOutlined
                            style={{
                              color: "#F05742",
                              margin: "13px 0 0 10px",
                            }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Row>
                    ))}

                    <Button
                      onClick={() => add()}
                      type="link"
                      size="small"
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Add URI
                    </Button>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Col>

        <Divider />

        <Col span={24}>
          <Form.Item>
            <Space size="middle">
              <Button
                onClick={() => {
                  dispatchReducer({
                    type: "CLOSE_FORM_PANEL",
                    payload: {
                      component: "app-registration-form",
                    },
                  });

                  dispatchReducer({
                    type: "SET_FORM_MODE",
                    payload: {
                      component: "app-registration-form",
                      mode: "",
                      title: "",
                    },
                  });
                }}
              >
                {appRegistrationForm.cancel.buttonText}
              </Button>
              <Button type="primary" htmlType="submit">
                {appRegistrationForm.submit.buttonText}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AppRegistrationForm;
