import React from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Form, Col, Row, Input, Button, Space, Typography } from "antd";
import { SmileOutlined, MehOutlined, FrownOutlined } from "@ant-design/icons";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";

const { Text } = Typography;

const FeedbackForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [emotion, setEmotion] = React.useState(1);
  const [form] = Form.useForm();

  const handleFeedbackFormSubmit = (e) => {
    const feedbackFormData = {
      FeedBack: e.feedback,
      Experience: emotion,
      ClientName: state.currentAccount.account.general.company,
      UserName: state.currentUser.user.displayName,
      ContactCustomer: "No",
    };

    dispatchMiddleware({
      type: "MWD_CREATE_FEEDBACK_FORM",
      payload: {
        component: "customer-feedback-form",
        feedbackForm: feedbackFormData,
      },
    });
  };

  const resetFormField = () => {
    form.resetFields();
    dispatchReducer({
      type: "CLOSE_FORM_PANEL",
      payload: {
        component: "feedback-form-panel",
      },
    });
  };

  return (
    <>
      {state.apiCallStatus["customer-feedback-form"] &&
      state.apiCallStatus["customer-feedback-form"].loading ? (
        <FullPageLoader
          component="customer-feedback-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <Text>Are you satisfied?</Text>
            </Col>

            <Col span={24}>
              <Space style={{ padding: "8px" }}>
                {emotion === 1 ? (
                  <SmileOutlined
                    style={{ fontSize: 32, color: "green" }}
                    onClick={() => setEmotion(1)}
                  />
                ) : (
                  <SmileOutlined
                    onClick={() => setEmotion(1)}
                    style={{ fontSize: 32, color: "#C0C0C0" }}
                  />
                )}
              </Space>

              <Space style={{ padding: "8px" }}>
                {emotion === 2 ? (
                  <MehOutlined
                    onClick={() => setEmotion(2)}
                    style={{ fontSize: 32, color: "orange" }}
                  />
                ) : (
                  <MehOutlined
                    onClick={() => setEmotion(2)}
                    style={{ fontSize: 32, color: "#C0C0C0" }}
                  />
                )}
              </Space>

              <Space style={{ padding: "8px" }}>
                {emotion === 3 ? (
                  <FrownOutlined
                    onClick={() => setEmotion(3)}
                    style={{ fontSize: 32, color: "red" }}
                  />
                ) : (
                  <FrownOutlined
                    onClick={() => setEmotion(3)}
                    style={{ fontSize: 32, color: "#C0C0C0" }}
                  />
                )}
              </Space>
            </Col>
          </Row>

          <Form
            layout="vertical"
            form={form}
            onFinish={handleFeedbackFormSubmit}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="feedback"
                  label="Please give us your valuable feedback"
                  rules={[
                    {
                      required: true,
                      message: "Please tell us about your experience",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Please tell us about your experience"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item>
                  <Space>
                    <Button onClick={resetFormField}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default FeedbackForm;
