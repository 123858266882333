import { useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Input,
  Form,
  Row,
  Col,
  Switch,
  Button,
  Divider,
  Typography,
  Checkbox,
  Tooltip,
  Tag,
  AutoComplete,
  Tabs,
  Image,
  Space,
} from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import {
  channels,
  contactSources,
  channelList,
  mandatoryContactProperties,
  systemContactProperties,
} from "../../constants/enums";
import openNotificationWithIcon from "../../utils/notification";

const ContactManagerForm = ({
  currentConversationChannel,
  selectedContact,
  addToExistContact,
}) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const conversationUserState = state.conversations.conversationUserState;

  //For add contact from client response
  let selectedResponse = state.clientResponses.responses?.data.find(
    (response) =>
      response._id === state.filters.actionRowKey["client-responses-table"]
  );

  //To get the current date and time in ISO format
  const currentDate = new Date().toISOString().replace("Z", "+00:00");

  //Add contact from conversation, client response
  let formFieldName;
  let displayName;
  let conversationId;
  let mobile;
  let whatsappId;
  let email;

  if (currentConversationChannel) {
    const {
      formFieldName: ffName,
      name,
      conversation_id,
      channel,
    } = currentConversationChannel;

    formFieldName = ffName ?? "";
    displayName = name;
    conversationId = conversation_id;
    mobile =
      channel === "WHATSAPP"
        ? conversation_id
        : currentConversationChannel.mobile || "";
    whatsappId = mobile;
  } else if (selectedResponse) {
    const {
      channel,
      conversationId: cId,
      userPhoneNumber,
      userEmail,
      userProfileName,
    } = selectedResponse;
    const channelItem = channelList?.find((c) => c._id === channel);
    formFieldName = channelItem?.formFieldName ?? "";
    displayName = userProfileName;
    conversationId = cId;

    if (channel === "WHATSAPP") {
      mobile = cId.startsWith("+") ? cId : "+" + cId;
    } else if (userPhoneNumber && userPhoneNumber !== "") {
      mobile = userPhoneNumber.toString().startsWith("+")
        ? userPhoneNumber
        : "+" + userPhoneNumber;
    }

    whatsappId = mobile;
    email = userEmail;
  }

  const formFields = {
    displayName,
    [formFieldName]: conversationId,
    mobile,
    whatsappId,
    email,
    ...conversationUserState,
  };

  form.setFieldsValue(formFields);

  //Add to exist contact
  const updateSelectedContact = () => {
    if (!selectedContact) return;

    if (currentConversationChannel) {
      const { formFieldName, conversation_id, channel, location } =
        currentConversationChannel;

      if (formFieldName && conversation_id) {
        selectedContact[formFieldName] = conversation_id;
      }

      if (channel === "WHATSAPP" && whatsappId) {
        selectedContact.mobile = whatsappId;
      }

      if (location) {
        selectedContact.lastConversationLocation = location;
      }
    }

    if (selectedResponse) {
      const { conversationId, userEmail } = selectedResponse;

      const { formFieldName } =
        channelList?.find(
          (channel) => channel._id === selectedResponse?.channel
        ) || {};

      if (formFieldName && conversationId) {
        selectedContact[formFieldName] = conversationId;
        if (formFieldName === "whatsappId") {
          selectedContact.mobile = conversationId;
        }
      }

      if (userEmail) {
        selectedContact.email = userEmail;
      }
    }

    dispatchReducer({
      type: "RD_SET_ACTION_ROW_KEY",
      payload: {
        component: "contact-manager-table",
        actionRowKey: selectedContact._id,
      },
    });
  };

  useEffect(() => {
    updateSelectedContact();
  }, [selectedContact]);

  //To get which contact is to be edited
  //Selected contact from the add to exist contact
  const recordToBeEdited =
    selectedContact ??
    state.contactManager.contacts?.find(
      (contact) =>
        contact._id === state.filters.actionRowKey["contact-manager-table"]
    );

  if (state.form["contact-manager-form"].mode === "edit" || selectedContact) {
    form.setFieldsValue(recordToBeEdited);
  }

  const handleFormSubmit = (formDetails) => {
    const formContactData = formDetails;

    //For storing Created, Update info
    if (state.form["contact-manager-form"].mode === "edit") {
      if (formContactData.isUnsubscribed) {
        formContactData.unsubscribedDate = currentDate;
      }
    } else {
      formContactData.createdDate = currentDate;

      formContactData.createdSource = currentConversationChannel
        ? contactSources.contactFromConversation
        : selectedResponse
        ? contactSources.contactFromResponse
        : contactSources.manualAddContact;

      formContactData.subscribedDate = currentDate;
      //Store the conversation information
      if (currentConversationChannel) {
        formContactData.lastConversationChannel =
          currentConversationChannel.channel;
        formContactData.ConversationReferenceId =
          currentConversationChannel._id;
        if (currentConversationChannel.location) {
          formContactData.lastConversationLocation =
            currentConversationChannel.location;
        }
      } else if (formContactData.isUnsubscribed) {
        formContactData.unsubscribedDate = currentDate;
      }
    }

    dispatchMiddleware({
      type: "MWD_ADD_EDIT_CONTACT",
      payload: { contact: formContactData, component: "contact-manager-form" },
    });
  };

  const validateForm = async () => {
    const values = await form.validateFields();
    const mobile = form.getFieldValue("mobile");
    const whatsappId = form.getFieldValue("whatsappId");
    const telegramId = form.getFieldValue("telegramId");
    const facebookId = form.getFieldValue("facebookId");
    const instagramId = form.getFieldValue("instagramId");
    const appleID = form.getFieldValue("appleID");
    const googleBusinessMessagesId = form.getFieldValue(
      "googleBusinessMessagesId"
    );
    const webChatID = form.getFieldValue("webChatID");
    const viberId = form.getFieldValue("viberId");
    const msTeamsId = form.getFieldValue("msTeamsId");

    if (
      mobile ||
      whatsappId ||
      telegramId ||
      facebookId ||
      instagramId ||
      appleID ||
      googleBusinessMessagesId ||
      webChatID ||
      viberId ||
      msTeamsId
    ) {
      handleFormSubmit(values);
    } else {
      openNotificationWithIcon(
        "error",
        "Please provide at least one social media profile"
      );
    }
  };

  const resetFormField = () => {
    form.resetFields();
    dispatchReducer({
      type: "CLOSE_FORM_PANEL",
      payload: {
        component: "contact-manager-form-panel",
      },
    });
    dispatchReducer({
      type: "SET_FORM_MODE",
      payload: {
        component: "contact-manager-form",
        mode: "",
      },
    });
    dispatchReducer({
      type: "RD_CLEAR_ACTION_ROW_KEY",
      payload: { component: "contact-manager-table" },
    });
  };

  // Tab 1 - Basic Details
  const BasicDetails = () => {
    const [whatsAppSameAsMobile, setWhatsAppSameAsMobile] = useState(true);
    const [inputTagVisible, setInputTagVisible] = useState(false);
    const [inputTagValue, setInputTagValue] = useState("");
    const [tags, setTags] = useState([]);

    form.setFieldsValue({
      tags: tags.join(", "),
    });

    //To set tags in the form
    useEffect(() => {
      if (
        state.form["contact-manager-form"].mode === "edit" ||
        selectedContact
      ) {
        if (recordToBeEdited.tags) {
          setTags(recordToBeEdited.tags.split(", "));
        }
      }
    }, []);

    return (
      <Row
        gutter={24}
        style={{
          // Add to exist contact contains dropdown contents
          maxHeight: addToExistContact ? "460px" : "520px",
          overflow: "auto",
        }}
      >
        <Col span={12}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { whitespace: true },
              {
                max: 35,
                message: "First name should not more then 35 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the first name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                max: 25,
                message: "Last name should not more then 25 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the last name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="displayName"
            label="Display Name"
            rules={[
              {
                required: true,
                message: "Please enter display name",
              },
              {
                max: 30,
                message: "Display name should not more then 30 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the display name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="email"
            label="Email ID"
            rules={[
              {
                type: "email",
                message: "Email should be a valid email address",
              },
              {
                max: 50,
                message: "Email id should not more then 50 characters",
              },
            ]}
            hasFeedback
          >
            <Input type="email" placeholder="Enter the email id" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="mobile"
            label={
              <span>
                Mobile Number&nbsp;
                <Tooltip title="Please enter mobile number with country code">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                pattern: /[+][1-9]\d+/,
                message: "Please enter valid country code",
              },
              { whitespace: true },
              {
                max: 30,
                message: "Mobile number should not more then 30 characters",
              },
            ]}
            hasFeedback
            valuePropName="value"
          >
            <PhoneInput
              className="phone-number-input-box"
              onChange={(e) => {
                const mobileNumber = e;
                if (whatsAppSameAsMobile) {
                  form.setFieldsValue({
                    whatsappId: mobileNumber,
                  });
                }
              }}
              placeholder="Enter the mobile number"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              {whatsAppSameAsMobile ? (
                <Form.Item
                  name="whatsappId"
                  label={channels["WHATSAPP"].displayName.concat(" Number")}
                  // hasFeedback
                  valuePropName="value"
                  style={{ marginBottom: "0px" }}
                  rules={[
                    {
                      max: 30,
                      message:
                        "WhatsApp number should not more then 30 characters",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              ) : (
                <Form.Item
                  name="whatsappId"
                  label={channels["WHATSAPP"].displayName.concat(" Number")}
                  rules={[
                    {
                      pattern: /[+][1-9]\d+/,
                      message: "Please enter valid country code",
                    },
                    { whitespace: true },
                    {
                      max: 30,
                      message:
                        "WhatsApp number should not more then 30 characters",
                    },
                  ]}
                  hasFeedback
                  valuePropName="value"
                  style={{ marginBottom: "0px" }}
                >
                  <PhoneInput
                    placeholder="Enter whatsapp number"
                    className="phone-number-input-box"
                    rules={[
                      {
                        max: 30,
                        message:
                          "WhatsApp number should not more then 30 characters",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={24}>
              <Form.Item
                name="isWhatsAppSameAsMobile"
                valuePropName="checked"
                initialValue="true"
              >
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setWhatsAppSameAsMobile(true);
                      form.setFieldsValue({
                        whatsappId: form.getFieldValue("whatsappId"),
                      });
                    } else {
                      setWhatsAppSameAsMobile(false);
                      form.setFieldsValue({
                        whatsappId: "",
                      });
                    }
                  }}
                >
                  Same as Mobile Number
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[
              {
                max: 50,
                message: "Company name should not more then 50 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the company name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address1"
            label="Address 1"
            rules={[
              {
                max: 60,
                message: "Address 1 should not more then 60 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the address 1" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address2"
            label="Address 2"
            rules={[
              {
                max: 60,
                message: "Address 2 should not more then 60 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the address 2" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                pattern: /^[a-zA-Z ]*$/,
                message: "Please enter valid city",
              },
              {
                max: 40,
                message: "City should not more then 40 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the city" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                pattern: /^[a-zA-Z ]*$/,
                message: "Please enter valid state",
              },
              {
                max: 40,
                message: "State should not more then 40 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the state" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="postCode"
            label="Post Code"
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: "Please enter valid post code",
              },

              {
                max: 30,
                message: "Post code should not more then 30 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the post code" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                pattern: /^[a-zA-Z ]*$/,
                message: "Please enter valid country",
              },
              {
                max: 40,
                message: "Country should not more then 40 characters",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter the country" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="isUnsubscribed"
            valuePropName="checked"
            label="Unsubscribed"
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="tags" label="Tags">
            <>
              <div style={{ maxHeight: 90, overflow: "auto" }}>
                {tags?.map((tag, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={() => {
                      const handleTagClose = (tagText) => {
                        const updatedTags = tags.filter(
                          (tag) => tag !== tagText
                        );

                        setTags(updatedTags);
                        setInputTagValue("");
                      };
                      handleTagClose(tag);
                    }}
                    style={{ marginBottom: 5 }}
                  >
                    {tag}
                  </Tag>
                ))}
              </div>

              {inputTagVisible && (
                <AutoComplete
                  autoFocus
                  size="small"
                  options={[
                    ...new Set(
                      state.contactManager.contacts
                        .sort((a, b) => a.timestamp - b.timestamp)
                        .flatMap((contact) =>
                          contact.tags ? contact.tags.split(", ") : []
                        )
                        .filter((tag) => tag !== "")
                    ),
                  ].map((tag, index) => {
                    return { key: index, label: tag, value: tag };
                  })}
                  onChange={(value) => {
                    setInputTagValue(value);
                  }}
                  onSelect={(value) => {
                    if (!tags.includes(value)) {
                      setTags([...tags, value]);
                    }
                    setInputTagVisible(false);
                  }}
                  onKeyDown={(e) => {
                    //check if enter key is pressed
                    if (e.keyCode === 13) {
                      if (
                        !tags.includes(e.target.value) &&
                        e.target.value !== "" &&
                        e.target.value.length <= 50
                      ) {
                        setTags([...tags, e.target.value]);
                        setInputTagVisible(false);
                      }
                    }
                  }}
                  style={{ width: 110 }}
                  filterOption={true}
                  dropdownMatchSelectWidth={false}
                />
              )}

              {!inputTagVisible && tags.length <= 30 && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setInputTagVisible(true);
                  }}
                >
                  <PlusOutlined />
                </Button>
              )}

              {inputTagValue.length >= 50 && (
                <Col span={24}>
                  <Typography.Text type="danger">
                    Tags should not more than 50 characters
                  </Typography.Text>
                </Col>
              )}

              {tags.length >= 30 && (
                <Col span={24}>
                  <Typography.Text type="danger">
                    You can add only 30 tags
                  </Typography.Text>
                </Col>
              )}
            </>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  //Tab 2 - Social Media Details
  const SocialMediaDetails = () => {
    return (
      <div
        style={{
          height: addToExistContact ? "460px" : "523px",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="telegramId"
              label={
                <div>
                  <Image
                    src={channels.TELEGRAM.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.TELEGRAM.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 32,
                  message: "Telegram id should not more then 32 characters",
                },
              ]}
            >
              <Input placeholder="Enter the telegram id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="facebookId"
              label={
                <div>
                  <Image
                    src={channels.FACEBOOK.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.FACEBOOK.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 50,
                  message: "Facebook id should not more then 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter the facebook id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="instagramId"
              label={
                <div>
                  <Image
                    src={channels.INSTAGRAM.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.INSTAGRAM.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 30,
                  message: "Instagram id should not more then 30 characters",
                },
              ]}
            >
              <Input placeholder="Enter the instagram id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="appleID"
              label={
                <div>
                  <Image
                    src={channels.AMB.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.AMB.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 170,
                  message: "Apple id should not more then 170 characters",
                },
              ]}
            >
              <Input placeholder="Enter the apple id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="googleBusinessMessagesId"
              label={
                <div>
                  <Image
                    src={channels.GBM.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.GBM.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 40,
                  message:
                    "Google business messages id should not more then 40 characters",
                },
              ]}
            >
              <Input placeholder="Enter the google business messages id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="webChatID"
              label={
                <div>
                  <Image
                    src={channels.WEBCHAT.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.WEBCHAT.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 260,
                  message: "Web chat id should not more then 260 characters",
                },
              ]}
            >
              <Input placeholder="Enter the web chat id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="viberId"
              label={
                <div>
                  <Image
                    src={channels.VIBER.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.VIBER.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 255,
                  message: "Viber id should not more then 255 characters",
                },
              ]}
            >
              <Input placeholder="Enter the viber id" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="msTeamsId"
              label={
                <div>
                  <Image
                    src={channels.MSTEAMS.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {channels.MSTEAMS.displayName.concat(" ID")}
                  </span>
                </div>
              }
              rules={[
                {
                  max: 255,
                  message: "Teams id should not more then 255 characters",
                },
              ]}
            >
              <Input placeholder="Enter the teams id" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  //Tab 3 - Custom Fields
  const CustomFields = () => {
    const [label, setLabel] = useState("");
    const [customFormItems, setCustomFormItems] = useState([]);
    const [mouseHoveredFormItem, setMouseHoveredFormItem] = useState();

    const { Text } = Typography;

    //To set custom form fields
    useEffect(() => {
      let standardContactFormFields = mandatoryContactProperties.concat(
        systemContactProperties
      );
      if (state.form["contact-manager-form"].mode === "edit") {
        setCustomFormItems(
          Object.entries(recordToBeEdited)
            .filter(([key]) => !standardContactFormFields.includes(key))
            .map(([key]) => ({ key, label: key }))
        );
      }
    }, []);

    return (
      <>
        <Text>
          You can add additional data to your contacts with custom fields.
          Simply provide a name in the text below and hit enter to create a
          custom field
        </Text>

        <Divider />

        <div
          style={{
            height: addToExistContact ? "368px" : "430px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Row gutter={24}>
            {customFormItems?.map((formItem, index) => (
              <Col span={12} key={index}>
                <Form.Item
                  name={formItem.key}
                  label={
                    <div style={{ width: 500 }}>
                      <Text>{formItem.label}</Text>
                      <DeleteOutlined
                        style={{
                          color: "#F05742",
                          visibility:
                            mouseHoveredFormItem === formItem.key
                              ? "visible"
                              : "hidden",
                          position: "absolute",
                          right: 0,
                        }}
                        onClick={() =>
                          setCustomFormItems(
                            customFormItems.filter(
                              (item) => item.key !== formItem.key
                            )
                          )
                        }
                      />
                    </div>
                  }
                  rules={[
                    {
                      max: 100,
                      message:
                        formItem.label + " should not more then 100 characters",
                    },
                  ]}
                  onMouseEnter={() => {
                    setMouseHoveredFormItem(formItem.key);
                  }}
                  onMouseLeave={() => setMouseHoveredFormItem(null)}
                  hasFeedback
                >
                  <Input
                    key={formItem.key}
                    placeholder={"Enter the " + formItem.label}
                  />
                </Form.Item>
              </Col>
            ))}
            <Col span={12}>
              <>
                <Input
                  autoFocus
                  style={{
                    marginTop:
                      customFormItems.length !== 0 &&
                      customFormItems.length % 2 === 0
                        ? 0
                        : 30,
                  }}
                  placeholder="Create custom field"
                  value={label}
                  onChange={(e) => {
                    let fieldName = e.target.value;
                    setLabel(fieldName);
                  }}
                  onPressEnter={(e) => {
                    //prevent form submission
                    e.preventDefault();
                    const newFormItem = {
                      key: label,
                      label,
                    };
                    setCustomFormItems([...customFormItems, newFormItem]);
                    setLabel("");
                  }}
                  suffix={
                    label.length > 0 &&
                    label.length < 31 &&
                    // Validate label should not repeat
                    !customFormItems.some(
                      ({ label: formItemLabel }) =>
                        formItemLabel.toLowerCase() === label.toLowerCase()
                    ) &&
                    // Validate label should contain text only
                    /^[A-Za-z\s]+$/.test(label) ? (
                      <Tooltip title="Create custom field">
                        <CheckOutlined
                          style={{ color: "#F05742" }}
                          onClick={() => {
                            const newFormItem = {
                              key: label,
                              label,
                            };
                            setCustomFormItems([
                              ...customFormItems,
                              newFormItem,
                            ]);
                            setLabel("");
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <CheckOutlined style={{ color: "#C0C0C0" }} />
                    )
                  }
                />
                <Text type="danger">
                  {label.length === 0
                    ? ""
                    : label.length > 40
                    ? "Label must be less than 40 characters."
                    : customFormItems.some(
                        ({ label: formItemLabel }) =>
                          formItemLabel.toLowerCase() === label.toLowerCase()
                      )
                    ? "Label cannot be repeated."
                    : !/^[A-Za-z\s]+$/.test(label)
                    ? "Label can only contain letters."
                    : null}
                </Text>
              </>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const tabPane = [
    {
      key: "basicDetails",
      label: "Basic Details",
      children: <BasicDetails />,
    },
    {
      key: "socialMedia",
      label: "Social Media",
      children: <SocialMediaDetails />,
      forceRender: true,
    },
    {
      key: "customFields",
      label: "Custom Fields",
      children: <CustomFields />,
      forceRender: true,
    },
  ];

  return (
    <>
      {state.apiCallStatus["contact-manager-form"] &&
      state.apiCallStatus["contact-manager-form"].loading ? (
        <FullPageLoader
          component="contact-manager-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={validateForm}
          form={form}
        >
          <Tabs defaultActiveKey="basicDetails" items={tabPane} />
          <div
            style={{
              borderTop: "1px solid #e1dfdd",
            }}
          >
            <Row
              style={{
                position: "absolute",
                bottom: 5,
              }}
            >
              <Col span={24}>
                <Space>
                  <Form.Item>
                    <Button onClick={resetFormField}>Cancel</Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </>
  );
};

export default ContactManagerForm;
