import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useWhatsappFlowsMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    const botInAction = state.currentAccount.account.bots?.find(
      (bot) => bot.bot_id === state.application.botInAction
    );

    const wabaAccountId =
      botInAction?.bot_channel_info?.WHATSAPP?.waba_account_id;

    switch (action.type) {
      ////*******************************************////
      ////**************** WHATSAPP FLOWS *************////
      ////*******************************************////

      //Load WhatsApp flows list
      case "LOAD_WHATSAPP_FLOWS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.WHATSAPPFLOWS.concat(
              "?wabaAccountId=" + action.payload.wabaAccountId
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([whatsappFlows]) => {
            dispatchReducer({
              type: "SET_WHATSAPP_FLOWS",
              payload: {
                whatsappFlows,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load WhatsApp Flows Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Add / Edit WhatsApp flow
      case "MWD_ADD_EDIT_WHATSAPP_FLOW":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            ...(state.form?.["whatsapp-flows-form"].mode === "add"
              ? [
                  apiEndPoints.WHATSAPPFLOWS.concat(
                    "?wabaAccountId=" + wabaAccountId
                  ),
                ]
              : [
                  apiEndPoints.WHATSAPPFLOWS.concat(
                    "?flowId=",
                    state.filters.actionRowKey["whatsapp-flows-table"],
                    "&wabaAccountId=" + wabaAccountId
                  ),
                ]),
          ],
          options: {
            method:
              state.form?.["whatsapp-flows-form"].mode === "add"
                ? "POST"
                : "PUT",
            body: action.payload.flowValues,
          },
          onSuccess: ([whatsappFlow]) => {
            if (state.form?.["whatsapp-flows-form"].mode === "add") {
              dispatchReducer({
                type: "ADD_WHATSAPP_FLOW",
                payload: {
                  createdWhatsAppFlow: whatsappFlow,
                },
              });
              dispatchReducer({
                type: "SET_CURRENT_WHATSAPP_FLOW",
                payload: {
                  currentWhatsAppFlow: whatsappFlow,
                },
              });
              openNotificationWithIcon(
                "success",
                "WhatsApp Flow Created Successfully"
              );

              appInsights.trackEvent({
                name: "WhatsApp Flow Created",
                properties: {
                  environment: mode,
                  clientName: state.currentAccount.account?.general?.company,
                  clientId: state.currentAccount.account.client_id,
                },
              });
            } else if (state.form?.["whatsapp-flows-form"].mode === "edit") {
              dispatchReducer({
                type: "EDIT_WHATSAPP_FLOW",
                payload: {
                  updatedWhatsAppFlow: whatsappFlow,
                },
              });
              openNotificationWithIcon(
                "success",
                "WhatsApp Flow Updated Successfully"
              );
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "whatsapp-flows-form",
                  mode: "",
                },
              });
              dispatchReducer({
                type: "RD_CLEAR_ACTION_ROW_KEY",
                payload: { component: "whatsapp-flows-table" },
              });
            }
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add / Edit WhatsApp Flow Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Change WhatsApp flows status
      case "CHANGE_WHATSAPP_FLOW_STATUS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.WHATSAPPFLOWS.concat(
              `/${action.payload.flowStatus}?flowId=`,
              action.payload.flowId + "&wabaAccountId=" + wabaAccountId
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "CHANGE_WHATSAPP_FLOW_STATUS",
              payload: {
                flowId: action.payload.flowId,
                flowStatus: action.payload.flowStatus,
              },
            });

            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "whatsapp-flows-form-panel",
              },
            });

            openNotificationWithIcon(
              "success",
              action.payload.flowStatus === "PUBLISH"
                ? "WhatsApp Flow Published Successfully"
                : action.payload.flowStatus === "DEPRECATE"
                ? "WhatsApp Flow Depreacted Successfully"
                : ""
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Change WhatsApp Flow Status Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Load WhatsApp flows assets
      case "LOAD_WHATSAPP_FLOW_ASSETS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.WHATSAPPFLOWS.concat(
              "/whatsappflow?flowId=",
              action.payload.flowId + "&wabaAccountId=" + wabaAccountId
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([whatsappFlowsAssets]) => {
            dispatchReducer({
              type: "SET_WHATSAPP_FLOWS_ASSETS",
              payload: {
                whatsappFlowsAssets,
                flowId: action.payload.flowId,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load WhatsApp Flows Assets Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Load WhatsApp flows gallery
      case "LOAD_WHATSAPP_FLOWS_GALLERY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [apiEndPoints.WHATSAPPFLOWS.concat("/gallery")],
          options: {
            method: "GET",
          },
          onSuccess: ([whatsappFlowsGallery]) => {
            dispatchReducer({
              type: "SET_WHATSAPP_FLOWS_GALLERY",
              payload: {
                whatsappFlowsGallery,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load WhatsApp Flows Gallery Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Delete WhatsApp flow
      case "DELETE_SINGLE_FLOW":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.WHATSAPPFLOWS.concat(
              "?flowId=",
              action.payload.flowId + "&wabaAccountId=" + wabaAccountId
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "DELETE_SINGLE_FLOW",
              payload: {
                flowId: action.payload.flowId,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "WhatsApp Flow deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Flow Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
