import React from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Space,
  Tag,
  Divider,
  Button,
  Tooltip,
  Image,
  Carousel,
  Collapse,
  Upload,
} from "antd";
import { VscPreview } from "react-icons/vsc";
import {
  MdCall,
  MdOutlineFormatListBulleted,
  MdReply,
  MdCardGiftcard,
  MdContentCopy,
} from "react-icons/md";
import { HiOutlineExternalLink } from "react-icons/hi";
import { formatText } from "../../../utils/utility";
import { UploadOutlined } from "@ant-design/icons";
import { Translation } from "../../../translation/translation";
import { wabaProvider, maxAllowedMediaSize } from "../../../constants/enums";
import openNotificationWithIcon from "../../../utils/notification";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";

const { Text } = Typography;

const MessageTemplatepreviewPanel = ({
  templateHeader,
  templateFooter,
  templateButtons,
  buttons,
  templateType,
  templateCarouselCards,
  setTemplateCarouselCards,
  headerPlaceHolders,
  bodyPlaceHolders,
  urlPlaceHolders,
  headerMapping,
  bodyMapping,
  urlMapping,
  handleReplaceHeaderPlaceHolder,
  handleReplaceBodyPlaceHolder,
  handleReplaceUrlPlaceHolder,
  preview,
  setPreview,
  handleSubmitTemplate,
  isDisableTemplateCreation,
  setShowPreview,
}) => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const previewPanelTranslation =
    Translation().messageTemplates?.formPanel?.preview;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const wabaProviderName =
    wabaProvider[botInAction.bot_channel_info?.WHATSAPP?.waba_provider]
      ?.providerReference.providerName;

  return (
    <Row gutter={[20, 24]}>
      <Col span={24}>
        <Card size="small" className="card-rounded-corner">
          <Row gutter={[20, 24]}>
            <Col span={1}>
              <VscPreview color="#F05742" size={20} />
            </Col>
            <Col span={23}>
              <Text strong>{previewPanelTranslation.title}</Text>
            </Col>
          </Row>

          <Row
            style={{
              marginTop: 20,
            }}
            gutter={[20, 24]}
          >
            {/* Placeholder example info text */}
            {(headerPlaceHolders?.length ||
              bodyPlaceHolders?.length ||
              urlPlaceHolders?.length ||
              (templateHeader?.format &&
                templateHeader?.format !== "TEXT")) && (
              <Col span={24}>
                <Card
                  size="small"
                  className="card-rounded-corner"
                  style={{
                    border: "1px solid #ffc505",
                    color: "#ff9805",
                    backgroundColor: "#fffbe6",
                    fontSize: 13,
                  }}
                >
                  The example values will be sent to Meta for template approval.
                  It will help to validate the template and speed up the
                  approval process. Please provide meaningful value to the
                  placeholder for approval purpose.
                </Card>
              </Col>
            )}

            {/* Value Mapping Section (LEFT HAND SIDE - 50% of the section) */}
            <Col span={14}>
              {headerPlaceHolders?.length ||
              bodyPlaceHolders?.length ||
              urlPlaceHolders?.length ||
              templateHeader?.format ||
              templateType === "carousel" ? (
                <Row gutter={[0, 16]}>
                  {/* Template Header - Value Mapping */}
                  {templateHeader && (
                    <Col span={24}>
                      <Card
                        title={
                          previewPanelTranslation.valueMapping.templateHeader
                            .title
                        }
                        size="small"
                        headStyle={{
                          backgroundColor: "#F4F7F9",
                          textAlign: "center",
                        }}
                      >
                        {headerPlaceHolders ? (
                          <Row gutter={[0, 8]}>
                            <Col span={24}>
                              <Text>
                                {
                                  previewPanelTranslation.valueMapping
                                    .templateHeader.description
                                }
                              </Text>
                            </Col>

                            {headerPlaceHolders.map(
                              (headerPlaceHolder, index) => (
                                <React.Fragment key={index}>
                                  <Col span={2} className="center-alignment">
                                    <Space>
                                      {"{{" + headerPlaceHolder + "}}"}
                                    </Space>
                                  </Col>
                                  <Col span={1} className="center-alignment">
                                    <Space>:</Space>
                                  </Col>
                                  <Col span={21}>
                                    <Input
                                      onChange={(e) =>
                                        handleReplaceHeaderPlaceHolder(
                                          headerPlaceHolder +
                                            "~*!*$*~" +
                                            e.target.value
                                        )
                                      }
                                      showCount
                                      maxLength={50}
                                      value={headerMapping?.[headerPlaceHolder]}
                                    />
                                  </Col>
                                </React.Fragment>
                              )
                            )}
                          </Row>
                        ) : templateHeader.format === "TEXT" ? (
                          <Text strong>{preview.header}</Text>
                        ) : templateHeader.format === "IMAGE" ? (
                          wabaProviderName === "INEXTLABS-CLOUD" ? (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Text>
                                Upload an example image for the header.
                              </Text>

                              <Upload
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(info) => {
                                  if (
                                    info.file.size > maxAllowedMediaSize.image
                                  ) {
                                    openNotificationWithIcon(
                                      "error",
                                      "The maximum size of image should be 5 MB"
                                    );
                                  } else {
                                    dispatchMiddleware({
                                      type: "UPLOAD_HEADER_MEDIA",
                                      payload: {
                                        file: info.file,
                                        component: "upload-header-media",
                                      },
                                    });

                                    setPreview({
                                      ...preview,
                                      headerURL: URL.createObjectURL(info.file),
                                    });
                                  }
                                }}
                                showUploadList={false}
                                maxCount={1}
                                accept=".jpg, .jpeg, .png"
                              >
                                <Space size="large">
                                  <Button icon={<UploadOutlined />}>
                                    Choose Image
                                  </Button>

                                  {state.apiCallStatus["upload-header-media"] &&
                                    state.apiCallStatus["upload-header-media"]
                                      .loading && (
                                      <ComponentLoader
                                        component={"upload-header-media"}
                                        loadingMessage=""
                                        errorMessage=""
                                      />
                                    )}
                                </Space>
                              </Upload>
                            </Space>
                          ) : (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Text>
                                {
                                  previewPanelTranslation.valueMapping
                                    .templateHeader.image.description
                                }
                              </Text>

                              <Input
                                onChange={(e) =>
                                  setPreview({
                                    ...preview,
                                    headerURL: e.target.value,
                                  })
                                }
                                showCount
                                maxLength={1024}
                                value={preview?.headerURL}
                              />

                              {
                                //check valid url
                                preview.headerURL &&
                                  !/^(https:\/\/[^\s$.?#].[^\s]*)$/gm.test(
                                    preview.headerURL
                                  ) && (
                                    <Text type="danger">
                                      {
                                        previewPanelTranslation.valueMapping
                                          .templateHeader.image.invalid
                                      }
                                    </Text>
                                  )
                              }
                            </Space>
                          )
                        ) : templateHeader.format === "VIDEO" ? (
                          wabaProviderName === "INEXTLABS-CLOUD" ? (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Text>
                                Upload an example video for the header.
                              </Text>

                              <Upload
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(info) => {
                                  if (
                                    info.file.size > maxAllowedMediaSize.video
                                  ) {
                                    openNotificationWithIcon(
                                      "error",
                                      "The maximum size of video should be 16MB"
                                    );
                                  } else {
                                    dispatchMiddleware({
                                      type: "UPLOAD_HEADER_MEDIA",
                                      payload: {
                                        file: info.file,
                                        component: "upload-header-media",
                                      },
                                    });

                                    setPreview({
                                      ...preview,
                                      headerURL: URL.createObjectURL(info.file),
                                    });
                                  }
                                }}
                                showUploadList={false}
                                maxCount={1}
                                accept=".mp4, .3gpp"
                              >
                                <Space size="large">
                                  <Button icon={<UploadOutlined />}>
                                    Choose Video
                                  </Button>

                                  {state.apiCallStatus["upload-header-media"] &&
                                    state.apiCallStatus["upload-header-media"]
                                      .loading && (
                                      <ComponentLoader
                                        component={"upload-header-media"}
                                        loadingMessage=""
                                        errorMessage=""
                                      />
                                    )}
                                </Space>
                              </Upload>
                            </Space>
                          ) : (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Text>
                                {
                                  previewPanelTranslation.valueMapping
                                    .templateHeader.video.description
                                }
                              </Text>

                              <Input
                                onChange={(e) =>
                                  setPreview({
                                    ...preview,
                                    headerURL: e.target.value,
                                  })
                                }
                                showCount
                                maxLength={1024}
                                value={preview?.headerURL}
                              />

                              {
                                //check valid url
                                preview.headerURL &&
                                  !/^(https:\/\/[^\s$.?#].[^\s]*)$/gm.test(
                                    preview.headerURL
                                  ) && (
                                    <Text type="danger">
                                      {
                                        previewPanelTranslation.valueMapping
                                          .templateHeader.video.invalid
                                      }
                                    </Text>
                                  )
                              }
                            </Space>
                          )
                        ) : (
                          templateHeader.format === "DOCUMENT" &&
                          (wabaProviderName === "INEXTLABS-CLOUD" ? (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Text>
                                Upload an example document for the header.
                              </Text>

                              <Upload
                                beforeUpload={() => {
                                  return false;
                                }}
                                onChange={(info) => {
                                  if (
                                    info.file.size >
                                    maxAllowedMediaSize.document
                                  ) {
                                    openNotificationWithIcon(
                                      "error",
                                      "The maximum size of document should be 100MB"
                                    );
                                  } else {
                                    dispatchMiddleware({
                                      type: "UPLOAD_HEADER_MEDIA",
                                      payload: {
                                        file: info.file,
                                        component: "upload-header-media",
                                      },
                                    });
                                    setPreview({
                                      ...preview,
                                      headerURL: URL.createObjectURL(info.file),
                                    });
                                  }
                                }}
                                showUploadList={false}
                                maxCount={1}
                                accept=".pdf, .docx. doc"
                              >
                                <Space size="large">
                                  <Button icon={<UploadOutlined />}>
                                    Choose Document
                                  </Button>

                                  {state.apiCallStatus["upload-header-media"] &&
                                    state.apiCallStatus["upload-header-media"]
                                      .loading && (
                                      <ComponentLoader
                                        component={"upload-header-media"}
                                        loadingMessage=""
                                        errorMessage=""
                                      />
                                    )}
                                </Space>
                              </Upload>
                            </Space>
                          ) : (
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Text>
                                {
                                  previewPanelTranslation.valueMapping
                                    .templateHeader.document.description
                                }
                              </Text>

                              <Input
                                onChange={(e) =>
                                  setPreview({
                                    ...preview,
                                    headerURL: e.target.value,
                                  })
                                }
                                showCount
                                maxLength={1024}
                                value={preview?.headerURL}
                              />

                              {
                                //check valid url
                                preview.headerURL &&
                                  !/^(https:\/\/[^\s$.?#].[^\s]*)$/gm.test(
                                    preview.headerURL
                                  ) && (
                                    <Text type="danger">
                                      {
                                        previewPanelTranslation.valueMapping
                                          .templateHeader.document.invalid
                                      }
                                    </Text>
                                  )
                              }
                            </Space>
                          ))
                        )}
                      </Card>
                    </Col>
                  )}

                  {/* Template Body - Value Mapping */}
                  {bodyPlaceHolders && (
                    <Col span={24}>
                      <Card
                        title={
                          previewPanelTranslation.valueMapping.templateBody
                            .title
                        }
                        size="small"
                        headStyle={{
                          backgroundColor: "#F4F7F9",
                          textAlign: "center",
                        }}
                      >
                        <Row gutter={[0, 8]}>
                          <Col span={24}>
                            <Text>
                              {
                                previewPanelTranslation.valueMapping
                                  .templateBody.description
                              }
                            </Text>
                          </Col>

                          {[...new Set(bodyPlaceHolders)].map(
                            (bodyPlaceHolder, index) => (
                              <React.Fragment key={index}>
                                <Col span={2} className="center-alignment">
                                  <Space>{"{{" + bodyPlaceHolder + "}}"}</Space>
                                </Col>
                                <Col span={1} className="center-alignment">
                                  <Space>:</Space>
                                </Col>
                                <Col span={21}>
                                  <Input
                                    onChange={(e) =>
                                      handleReplaceBodyPlaceHolder(
                                        bodyPlaceHolder +
                                          "~*!*$*~" +
                                          e.target.value
                                      )
                                    }
                                    showCount
                                    maxLength={50}
                                    value={bodyMapping?.[bodyPlaceHolder]}
                                  />
                                </Col>
                              </React.Fragment>
                            )
                          )}
                        </Row>
                      </Card>
                    </Col>
                  )}

                  {/* Template Footer */}
                  {templateFooter && (
                    <Col span={24}>
                      <Card
                        title={
                          previewPanelTranslation.valueMapping.templateFooter
                            .title
                        }
                        size="small"
                        headStyle={{
                          backgroundColor: "#F4F7F9",
                          textAlign: "center",
                        }}
                      >
                        <Text italic>{templateFooter.text}</Text>
                      </Card>
                    </Col>
                  )}

                  {/* Template Buttons */}
                  {urlPlaceHolders?.length && (
                    <Col span={24}>
                      <Card
                        title={
                          previewPanelTranslation.valueMapping.templateButton
                            .title
                        }
                        size="small"
                        headStyle={{
                          backgroundColor: "#F4F7F9",
                          textAlign: "center",
                        }}
                      >
                        <Row gutter={[0, 8]}>
                          <Col span={24}>
                            <Text>
                              {
                                previewPanelTranslation.valueMapping
                                  .templateButton.description
                              }
                            </Text>
                          </Col>
                          {urlPlaceHolders.map((urlPlaceHolder, index) => (
                            <React.Fragment key={index}>
                              <Col span={2} className="center-alignment">
                                <Space>{"{{" + urlPlaceHolder + "}}"}</Space>
                              </Col>
                              <Col span={1} className="center-alignment">
                                <Space>:</Space>
                              </Col>
                              <Col span={21}>
                                <Input
                                  onChange={(e) =>
                                    handleReplaceUrlPlaceHolder(
                                      urlPlaceHolder +
                                        "~*!*$*~" +
                                        e.target.value
                                    )
                                  }
                                  showCount
                                  maxLength={50}
                                  value={urlMapping?.[urlPlaceHolder]}
                                />
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      </Card>
                    </Col>
                  )}

                  {/* Carousal Cards */}
                  {templateType === "carousel" && (
                    <Col span={24}>
                      <Card
                        title={
                          previewPanelTranslation.valueMapping.carousel.title
                        }
                        size="small"
                        headStyle={{
                          backgroundColor: "#F4F7F9",
                          textAlign: "center",
                        }}
                      >
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                        >
                          {
                            previewPanelTranslation.valueMapping.carousel.card
                              .header.exampleInfoText
                          }
                          {templateCarouselCards.map((carouselCard, index) => {
                            return (
                              <Collapse
                                key={index}
                                size="small"
                                defaultActiveKey={[
                                  "0",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                ]}
                                className="template-carosal-card-form-item"
                              >
                                <Collapse.Panel
                                  header={
                                    previewPanelTranslation.valueMapping
                                      .carousel.card.title +
                                    " " +
                                    (index + 1)
                                  }
                                  key={index}
                                >
                                  <Input
                                    onChange={(e) => {
                                      const updatedCarouselCard = {
                                        ...carouselCard,
                                      };
                                      if (
                                        updatedCarouselCard.components[0]
                                          .type === "HEADER"
                                      ) {
                                        updatedCarouselCard.components[0].example =
                                          {
                                            header_handle: [e.target.value],
                                          };
                                      }

                                      setTemplateCarouselCards(
                                        templateCarouselCards.map((card, i) =>
                                          i === index
                                            ? updatedCarouselCard
                                            : card
                                        )
                                      );

                                      setPreview({
                                        ...preview,
                                        carousel: templateCarouselCards,
                                      });

                                      setTemplateCarouselCards(
                                        templateCarouselCards.map((card, i) =>
                                          i === index
                                            ? updatedCarouselCard
                                            : card
                                        )
                                      );
                                    }}
                                    placeholder={
                                      previewPanelTranslation.valueMapping
                                        .carousel.card.header.placeholder
                                    }
                                    value={
                                      preview.carousel[index]?.components[0]
                                        ?.example?.header_handle[0]
                                    }
                                    showCount
                                    maxLength={1024}
                                  />

                                  {
                                    //check valid url
                                    preview.carousel[index]?.components[0]
                                      ?.example?.header_handle[0] &&
                                      !/^(https:\/\/[^\s$.?#].[^\s]*)$/gm.test(
                                        preview.carousel[index]?.components[0]
                                          ?.example?.header_handle[0]
                                      ) && (
                                        <Text
                                          type="danger"
                                          style={{
                                            fontSize: 12,
                                            marginTop: 5,
                                          }}
                                        >
                                          {
                                            previewPanelTranslation.valueMapping
                                              .carousel.card.header.invalid
                                          }
                                        </Text>
                                      )
                                  }
                                </Collapse.Panel>
                              </Collapse>
                            );
                          })}
                        </Space>
                      </Card>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row gutter={[20, 24]}>
                  <Col span={24}>
                    <Text type="secondary">
                      {
                        previewPanelTranslation.valueMapping.templateBody
                          .noPlaceholders
                      }
                    </Text>
                  </Col>

                  <Col span={24}>
                    <Space direction="vertical" size="large">
                      <Text type="secondary">
                        {
                          previewPanelTranslation.valueMapping.templateBody
                            .infoToUsePlaceholder
                        }
                      </Text>
                      <Text type="secondary">
                        {
                          previewPanelTranslation.valueMapping.templateBody
                            .exampleForPlaceholder
                        }
                      </Text>
                    </Space>
                  </Col>
                </Row>
              )}
            </Col>

            {/* Preview Section (RIGHT HAND SIDE - 50% of the section) */}
            <Col span={10}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Space
                    style={{
                      backgroundImage: "url(/images/whatsapp-bg.png)",
                      padding: 15,
                      borderRadius: 15,
                    }}
                  >
                    <Row gutter={[0, 8]}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Tag color="green">
                          {previewPanelTranslation.messagePreview.title}
                        </Tag>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 8]}>
                          <Col
                            span={22}
                            offset={1}
                            className="templates-whatsapp-message-preview-container"
                          >
                            <Space direction="vertical">
                              {templateHeader &&
                                templateHeader.format === "TEXT" && (
                                  <Text strong>{preview.header}</Text>
                                )}

                              {templateHeader &&
                                templateHeader.format === "IMAGE" && (
                                  <Image
                                    src={
                                      (wabaProviderName === "INEXTLABS-CLOUD"
                                        ? state.messageTemplates
                                            .uploadedMedia?.[0]?.mediaId
                                          ? preview.headerURL
                                          : "/images/image-placeholder.png"
                                        : preview.headerURL) ||
                                      "/images/image-placeholder.png"
                                    }
                                    width="100%"
                                    preview={false}
                                  />
                                )}
                              {templateHeader &&
                                templateHeader.format === "VIDEO" && (
                                  <video
                                    width="100%"
                                    controls
                                    src={
                                      preview.headerURL ||
                                      "/images/video-placeholder.png"
                                    }
                                  />
                                )}
                              {templateHeader &&
                                templateHeader.format === "DOCUMENT" && (
                                  <Image
                                    width="100%"
                                    controls
                                    src="/images/document-placeholder.png"
                                    preview={false}
                                  />
                                )}

                              {preview?.limitedTimeOfferTemplate && (
                                <Space
                                  style={{
                                    backgroundColor: "#f5f5f5",
                                    width: "100%",
                                    padding: 5,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      borderRadius: "70%",
                                      padding: 7,
                                      backgroundColor: "#d2ede4",
                                    }}
                                  >
                                    <MdCardGiftcard
                                      size={20}
                                      style={{
                                        color: "#16b490",
                                        marginBottom: -3,
                                      }}
                                    />
                                  </div>

                                  <Space
                                    direction="vertical"
                                    style={{ width: "100%" }}
                                    size={0}
                                  >
                                    <Text
                                      strong
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      {
                                        preview.limitedTimeOfferTemplate
                                          ?.limited_time_offer?.text
                                      }
                                    </Text>

                                    {preview.limitedTimeOfferTemplate
                                      ?.limited_time_offer?.has_expiration && (
                                      <Text
                                        style={{
                                          fontSize: 12,
                                        }}
                                        type="secondary"
                                      >
                                        Expiration timer goes here
                                      </Text>
                                    )}

                                    {templateButtons?.buttons[0]?.type ===
                                      "copy_code" && (
                                      <Text
                                        type="secondary"
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        Offer code goes here
                                      </Text>
                                    )}
                                  </Space>
                                </Space>
                              )}

                              <Space>{formatText(preview?.body)}</Space>

                              {templateFooter && (
                                <Text italic>{templateFooter.text}</Text>
                              )}
                            </Space>

                            <Space
                              style={{
                                color: "#909090",
                                justifyContent: "right",
                                fontSize: 10,
                                width: "100%",
                              }}
                            >
                              10:10 AM
                            </Space>
                          </Col>

                          {/* Buttons */}
                          {templateButtons?.buttons
                            ?.filter((_, index) => index < 2)
                            .map((button, index) => {
                              return (
                                <Col span={22} key={index} offset={1}>
                                  <Space
                                    key={index}
                                    className="whatsapp-button"
                                    onClick={() =>
                                      button.type === "URL" &&
                                      window.open(
                                        preview.url ||
                                          buttons?.createdButtons?.find(
                                            (button) => button.type === "url"
                                          )?.url
                                      )
                                    }
                                    style={
                                      button.type === "URL" && {
                                        cursor: "pointer",
                                      }
                                    }
                                  >
                                    {button.type === "PHONE_NUMBER" && (
                                      <MdCall
                                        size={15}
                                        style={{
                                          marginBottom: -3,
                                        }}
                                      />
                                    )}

                                    {button.type === "URL" && (
                                      <HiOutlineExternalLink
                                        size={17}
                                        style={{
                                          marginBottom: -2,
                                        }}
                                      />
                                    )}

                                    {button.type === "copy_code" && (
                                      <MdContentCopy
                                        size={16}
                                        style={{
                                          marginBottom: -2,
                                        }}
                                      />
                                    )}

                                    {button.type === "copy_code"
                                      ? "Copy offer code"
                                      : button.text}
                                  </Space>
                                </Col>
                              );
                            })}

                          {templateButtons?.buttons?.length > 2 && (
                            <Tooltip title="By clicking this button, user can see all the buttons in their WhatsApp.">
                              <Col span={22} offset={1}>
                                <Space
                                  className="whatsapp-button"
                                  style={{ cursor: "pointer", width: "100%" }}
                                >
                                  <MdOutlineFormatListBulleted
                                    size={15}
                                    style={{
                                      marginBottom: -3,
                                    }}
                                  />
                                  See all options
                                </Space>
                              </Col>
                            </Tooltip>
                          )}

                          {templateType === "carousel" && (
                            <Col span={22} offset={1}>
                              <Carousel
                                dotPosition="top"
                                autoplay
                                style={{
                                  width: bodyPlaceHolders ? 234 : 241,
                                  borderRadius: 15,
                                }}
                              >
                                {templateCarouselCards.map(
                                  (carouselCard, index) => {
                                    return (
                                      <Space
                                        key={index}
                                        direction="vertical"
                                        style={{
                                          width: "100%",
                                        }}
                                        size="large"
                                        className="carousel-template-card"
                                      >
                                        {carouselCard.components[0].type ===
                                          "HEADER" &&
                                          carouselCard.components[0].format ===
                                            "IMAGE" && (
                                            <Image
                                              src={
                                                preview.carousel[index]
                                                  ?.components[0]?.example
                                                  ?.header_handle[0] ||
                                                "/images/image-placeholder.png"
                                              }
                                              style={{
                                                borderRadius: 15,
                                                padding: 5,
                                              }}
                                              width="100%"
                                              preview={false}
                                            />
                                          )}

                                        {carouselCard.components[1].type ===
                                          "BODY" && (
                                          <Space
                                            style={{
                                              fontSize: 13,
                                              padding: "5px 6px",
                                            }}
                                          >
                                            {formatText(
                                              carouselCard.components[1].text
                                            )}
                                          </Space>
                                        )}

                                        {carouselCard.components[2].type ===
                                          "BUTTONS" && (
                                          <Space
                                            direction="vertical"
                                            style={{
                                              width: "100%",
                                              justifyContent: "center",
                                              padding: "5px 5px",
                                            }}
                                            size={0}
                                          >
                                            {carouselCard.components[2].buttons.map(
                                              (button, buttonIndex) => {
                                                return (
                                                  <Space
                                                    key={buttonIndex}
                                                    style={{
                                                      width: "100%",
                                                      cursor: "pointer",
                                                      justifyContent: "center",
                                                      padding: "5px 5px",
                                                      background: "#ffffff",
                                                      color: "#3d8af7",
                                                      borderTop:
                                                        "1px solid #f0f0f0",
                                                      fontSize: 13,
                                                    }}
                                                  >
                                                    {button.type === "URL" && (
                                                      <HiOutlineExternalLink
                                                        size={17}
                                                        style={{
                                                          marginBottom: -5,
                                                        }}
                                                      />
                                                    )}

                                                    {button.type ===
                                                      "PHONE_NUMBER" && (
                                                      <MdCall
                                                        size={15}
                                                        style={{
                                                          marginBottom: -3,
                                                        }}
                                                      />
                                                    )}

                                                    {button.type ===
                                                      "QUICK_REPLY" && (
                                                      <MdReply
                                                        size={15}
                                                        style={{
                                                          marginBottom: -6,
                                                        }}
                                                      />
                                                    )}
                                                    <Space
                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {button.text}
                                                    </Space>
                                                  </Space>
                                                );
                                              }
                                            )}
                                          </Space>
                                        )}
                                      </Space>
                                    );
                                  }
                                )}
                              </Carousel>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      {/* Action Buttons */}
      <Col
        span={24}
        style={{
          textAlign: "right",
        }}
      >
        <Divider />
        <Space size="middle">
          <Button
            onClick={() => {
              setShowPreview(false);
            }}
          >
            {previewPanelTranslation.footer.backToEditor.buttonText}
          </Button>

          <Tooltip
            placement="topRight"
            title={
              isDisableTemplateCreation()
                ? previewPanelTranslation.footer.submitTemplate.warningText
                : previewPanelTranslation.footer.submitTemplate.infoText
            }
          >
            <Button
              type="primary"
              onClick={handleSubmitTemplate}
              disabled={isDisableTemplateCreation()}
            >
              {previewPanelTranslation.footer.submitTemplate.buttonText}
            </Button>
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};

export default MessageTemplatepreviewPanel;
