import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useSupportTicketstMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////****************** Support ****************////
      ////*******************************************////

      //Load Customer Support Details
      case "MWD_LOAD_SUPPORT_TICKETS_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.SUPPORT_TICKETS.concat(
              "ByClientId?cf_client_id=",
              state.currentAccount.account.client_id
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([tickets]) => {
            dispatchReducer({
              type: "RD_SET_SUPPORT_TICKETS",
              payload: { tickets: tickets.results },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Support Tickets Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load Customer Support Conversations
      case "MWD_LOAD_SUPPORT_TICKET_INFO_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.SUPPORT_TICKETS.concat(
              "?id=",
              state.filters.actionRowKey["support-tickets-table"]
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([ticketInfo]) => {
            dispatchReducer({
              type: "RD_SET_SUPPORT_TICKET_INFO",
              payload: { ticketInfo },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Support Ticket Info Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Create Support Tickets
      case "MWD_CREATE_SUPPORT_TICKET":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [apiEndPoints.SUPPORT_TICKETS],
          options: {
            method: "POST",
            body: action.payload.supportTicket,
            contentType: "auto",
          },

          onSuccess: ([ticket]) => {
            dispatchReducer({
              type: "RD_CREATE_SUPPORT_TICKET",
              payload: { ticket },
            });
            openNotificationWithIcon("success", "Ticket created successfully");
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "support-ticket-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "support-ticket-form",
                mode: "",
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Create Support Ticket Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Reply Support Tickets
      case "MWD_REPLY_SUPPORT_TICKET":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.SUPPORT_TICKETS.concat(
              "/reply?id=",
              state.filters.actionRowKey["support-tickets-table"]
            ),
          ],
          options: {
            method: "POST",
            body: action.payload.replyTicket,
            contentType: "auto",
          },

          onSuccess: ([replyTicket]) => {
            dispatchReducer({
              type: "RD_REPLY_SUPPORT_TICKET",
              payload: { replyTicket },
            });
            openNotificationWithIcon(
              "success",
              "Ticket reply submitted successfully"
            );
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "support-ticket-info-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "support-ticket-info-form",
                mode: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "support-tickets-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Reply support Ticket Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
