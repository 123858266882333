import { useGlobalContext } from "../../../contexts/globalContext";
import { Drawer, Modal } from "antd";
import MessageTemplateForm from "./MessageTemplateForm";

const { confirm } = Modal;

const MessageTemplateFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Drawer
      title={state.formPanel?.["message-template-form-panel"]?.title}
      width={800}
      onClose={() => {
        confirm({
          title: "Are you sure you want to close the form?",
          content:
            "When clicked the OK button, the unsaved form inputs will be lost",
          onOk() {
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "message-template-form-panel",
              },
            });

            dispatchReducer({
              type: "SET_HEADER_MEDIA_ID",
              payload: {
                uploadedMedia: null,
              },
            });
          },
          onCancel() {},
        });
      }}
      destroyOnClose={true}
      open={state.formPanel?.["message-template-form-panel"]?.visible}
      bodyStyle={{ backgroundColor: "#F4F7F9" }}
      maskClosable={false}
    >
      <MessageTemplateForm />
    </Drawer>
  );
};

export default MessageTemplateFormPanel;
