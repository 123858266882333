import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Button,
  Row,
  Col,
  Card,
  Image,
  Divider,
  Typography,
  Space,
} from "antd";
import { IoIosInformationCircleOutline, IoMdSwap } from "react-icons/io";
import { LiaBalanceScaleSolid } from "react-icons/lia";
import { TbTrash } from "react-icons/tb";
import { AiOutlineTool } from "react-icons/ai";
import { Translation } from "../../../translation/translation";

const { Text } = Typography;

const BotKnowledgeBestPractices = ({ next }) => {
  const [, dispatchReducer] = useGlobalContext();

  const translation =
    Translation().botKnowledgeArea.botKnowledgebase.formPanel.bestPractices;

  return (
    <Row gutter={[14, 16]}>
      <Col
        span={24}
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <Image
          src="/images/bot-knowledgebase-icons/best-practices.png"
          preview={false}
          width="300px"
          height="200px"
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Col>

      <Col span={12}>
        <Card
          size="small"
          className="card-rounded-corner"
          title={
            <Space>
              <AiOutlineTool
                size={18}
                style={{
                  color: "#F05742",
                }}
              />
              <Text strong>{translation.bestPractice1.title}</Text>
            </Space>
          }
          extra={<IoIosInformationCircleOutline size={19} />}
        >
          <Space direction="vertical">
            <Text>{translation.bestPractice1.description}</Text>
          </Space>
        </Card>
      </Col>

      <Col span={12}>
        <Card
          size="small"
          className="card-rounded-corner"
          title={
            <Space>
              <LiaBalanceScaleSolid
                size={19}
                style={{
                  color: "#F05742",
                }}
              />
              <Text strong> {translation.bestPractice2.title}</Text>
            </Space>
          }
          extra={<IoIosInformationCircleOutline size={19} />}
        >
          <Space direction="vertical">
            <Text>{translation.bestPractice2.description}</Text>
          </Space>
        </Card>
      </Col>

      <Col span={12}>
        <Card
          size="small"
          className="card-rounded-corner"
          title={
            <Space>
              <TbTrash
                size={18}
                style={{
                  color: "#F05742",
                }}
              />
              <Text strong> {translation.bestPractice3.title}</Text>
            </Space>
          }
          extra={<IoIosInformationCircleOutline size={19} />}
        >
          <Space direction="vertical">
            <Text>{translation.bestPractice3.description}</Text>
          </Space>
        </Card>
      </Col>

      <Col span={12}>
        <Card
          size="small"
          className="card-rounded-corner"
          title={
            <Space>
              <IoMdSwap
                size={20}
                style={{
                  color: "#F05742",
                  marginTop: 3,
                }}
              />
              <Text strong> {translation.bestPractice4.title}</Text>
            </Space>
          }
          extra={<IoIosInformationCircleOutline size={19} />}
        >
          <Space direction="vertical">
            <Text>{translation.bestPractice4.description}</Text>
          </Space>
        </Card>
      </Col>

      <Col
        span={24}
        style={{
          textAlign: "right",
        }}
      >
        <Divider />
        <Button
          type="primary"
          onClick={() => {
            dispatchReducer({
              type: "SET_CURRENT_STEP",
              payload: 1,
            });
          }}
        >
          {translation.next.buttonText}
        </Button>
      </Col>
    </Row>
  );
};

export default BotKnowledgeBestPractices;
