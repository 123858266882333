import { useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Select,
  Divider,
  Tooltip,
  Space,
} from "antd";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  getUserDateTime,
  calculateScheduledTime,
  convertTimeToUTC,
  convertUTCToUserTime,
} from "../../utils/utility";
import {
  lalamoveServiceTypes,
  countriesCurrency,
  delayOptions,
  lalamoveStatus,
} from "../../constants/enums";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import { Translation } from "../../translation/translation";

const { Text } = Typography;

const DeliveryServicesPanel = ({ selectedResponse }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [showQuotationForm, setShowQuotationForm] = useState(false);

  const translation =
    Translation().clientResponses.infoPanelV2.deliveryServices.lalamove;

  const lalamoveConfiguration =
    state.deliveryServices?.lalamoveConfiguration || {};
  const pickupTime = convertTimeToUTC(
    calculateScheduledTime(
      delayOptions?.find((delayOption) => {
        return delayOption.key === lalamoveConfiguration.scheduleAt;
      })?.value
    )
  );

  const handleGetQuotation = (values) => {
    const formDetails = {
      data: {
        scheduleAt: pickupTime,
        serviceType: values.serviceType,
        language: lalamoveConfiguration.language,
        stops: [
          {
            address: values.pickupAddress,
          },
          {
            address: values.deliveryAddress,
          },
        ],
        isRouteOptimized: true,
      },
    };

    dispatchMiddleware({
      type: "GET_LALAMOVE_QUOTATION",
      payload: {
        deliveryDetails: formDetails,
        market: lalamoveConfiguration.market,
        component: "delivery-services-lalamove-panel",
      },
    });
  };

  const handleCreateOrder = () => {
    const orderDetails = {
      data: {
        isPODEnabled: true,
        quotationId: selectedResponse?.delivery_services?.lalamove.quotationId,
        sender: {
          stopId:
            selectedResponse?.delivery_services?.lalamove?.stops[0]?.stopId,
          name: lalamoveConfiguration.address[0]?.name,
          phone: lalamoveConfiguration.address?.find((address) => {
            return (
              address.name + " " + address.address ===
              selectedResponse?.delivery_services?.lalamove?.stops[0]?.address
            );
          })?.phoneNumber,
        },
        recipients: [
          {
            stopId:
              selectedResponse?.delivery_services?.lalamove?.stops[1]?.stopId,
            name: selectedResponse?.userProfileName,
            phone: (selectedResponse?.userPhoneNumber?.startsWith("+")
              ? selectedResponse?.userPhoneNumber
              : "+" + selectedResponse?.userPhoneNumber
            ).replace(" ", ""),
          },
        ],
        isRecipientSMSEnabled: true,
      },
    };

    dispatchMiddleware({
      type: "CREATE_LALAMOVE_ORDER",
      payload: {
        orderDetails,
        market: lalamoveConfiguration.market,
        component: "delivery-services-lalamove-panel",
      },
    });
  };

  useEffect(() => {
    if (showQuotationForm) {
      form.setFieldsValue({
        deliveryAddress: `${selectedResponse?.userProfileName || ""}, ${
          selectedResponse?.deliveryAddress || ""
        }`,
        ...lalamoveConfiguration,
      });
    }
  }, [selectedResponse, showQuotationForm]);

  switch ("lalamove") {
    case "lalamove":
      return state.apiCallStatus?.["delivery-services-lalamove-panel"]
        ?.loading ? (
        <FullPageLoader
          component="delivery-services-lalamove-panel"
          loadingMessage={translation.deliveryDetailsForm.loadingMessage}
        />
      ) : lalamoveConfiguration.status &&
        selectedResponse?.delivery_services?.lalamove?.orderId ? (
        selectedResponse?.delivery_services?.lalamove?.status ? (
          <Card size="small">
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Text strong>{translation.orderDetails.title}</Text>
              </Col>

              <Col span={24}>
                <Card
                  size="small"
                  title={translation.orderDetails.pickupAddress.title}
                >
                  <Text>
                    {
                      selectedResponse?.delivery_services?.lalamove?.stops[0]
                        ?.address
                    }
                  </Text>
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  size="small"
                  title={translation.orderDetails.deliveryAddress.title}
                >
                  <Text>
                    {
                      selectedResponse?.delivery_services?.lalamove?.stops[1]
                        ?.address
                    }
                  </Text>
                </Card>
              </Col>

              {selectedResponse?.delivery_services?.lalamove?.orderId && (
                <Col span={24}>
                  <Row>
                    <Col span={7}>{translation.orderDetails.orderId.title}</Col>
                    <Col span={12}>
                      <Text>
                        {selectedResponse?.delivery_services?.lalamove?.orderId}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}

              {selectedResponse?.delivery_services?.lalamove?.status && (
                <Col span={24}>
                  <Row>
                    <Col span={7}>{translation.orderDetails.status.title}</Col>
                    <Col span={12}>
                      <Text strong>
                        {
                          lalamoveStatus.find((status) => {
                            return (
                              status.key ===
                              selectedResponse?.delivery_services?.lalamove
                                ?.status
                            );
                          })?.value
                        }
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col span={24}>
                <Row>
                  <Col span={7}>
                    {translation.quotationDetails.quotationId.title}
                  </Col>
                  <Col span={12}>
                    <Text>
                      {
                        selectedResponse?.delivery_services?.lalamove
                          ?.quotationId
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col span={7}>
                    {translation.orderDetails.totalPrice.title}
                  </Col>
                  <Col span={12}>
                    <Text>
                      {countriesCurrency.find((currency) => {
                        return (
                          currency.currencyCode ===
                          selectedResponse?.delivery_services?.lalamove
                            ?.priceBreakdown?.currency
                        );
                      })?.currencySymbol ||
                        selectedResponse?.delivery_services?.lalamove
                          ?.priceBreakdown?.currency}
                      {"  "}
                      {
                        selectedResponse?.delivery_services?.lalamove
                          ?.priceBreakdown?.total
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col span={7}>{translation.orderDetails.distance.title}</Col>
                  <Col span={12}>
                    <Text>
                      {
                        selectedResponse?.delivery_services?.lalamove?.distance
                          ?.value
                      }{" "}
                      {
                        selectedResponse?.delivery_services?.lalamove?.distance
                          ?.unit
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Divider
                style={{
                  margin: "10px 0",
                }}
              />

              {selectedResponse?.delivery_services?.lalamove?.status?.toUpperCase() ===
              "EXPIRED" ? (
                <Space direction="vertical" size="middle">
                  <Space>
                    <AiOutlineExclamationCircle
                      size={18}
                      style={{
                        color: "#faad14",
                        marginTop: "1px",
                      }}
                    />
                    <Text>{translation.createNewQuotation.description}</Text>
                  </Space>
                  <Button
                    danger
                    onClick={() => {
                      dispatchReducer({
                        type: "RESET_LALAMOVE_ORDER_DETAILS",
                      });
                      setShowQuotationForm(true);
                    }}
                  >
                    {translation.createNewQuotation.buttonText}
                  </Button>
                </Space>
              ) : (
                <Button
                  danger
                  onClick={() => {
                    dispatchMiddleware({
                      type: "GET_LALAMOVE_ORDER_DETAILS",
                      payload: {
                        orderId:
                          selectedResponse?.delivery_services?.lalamove
                            ?.orderId,
                        market: lalamoveConfiguration.market,
                        component: "delivery-services-lalamove-panel",
                      },
                    });
                  }}
                >
                  {translation.orderDetails.checkOrderStatus.buttonText}
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Button
            danger
            onClick={() => {
              dispatchMiddleware({
                type: "GET_LALAMOVE_ORDER_DETAILS",
                payload: {
                  orderId:
                    selectedResponse?.delivery_services?.lalamove?.orderId,
                  market: lalamoveConfiguration.market,
                  component: "delivery-services-lalamove-panel",
                },
              });
            }}
          >
            {translation.orderDetails.checkOrderStatus.buttonText}
          </Button>
        )
      ) : lalamoveConfiguration.status &&
        selectedResponse?.delivery_services?.lalamove?.quotationId ? (
        selectedResponse?.delivery_services?.lalamove?.distance ? (
          <Card size="small">
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Text strong>{translation.quotationDetails.title}</Text>
              </Col>

              <Col span={24}>
                <Card
                  size="small"
                  title={translation.orderDetails.pickupAddress.title}
                >
                  <Text>
                    {
                      selectedResponse?.delivery_services?.lalamove?.stops[0]
                        ?.address
                    }
                  </Text>
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  size="small"
                  title={translation.orderDetails.deliveryAddress.title}
                >
                  <Text>
                    {
                      selectedResponse?.delivery_services?.lalamove?.stops[1]
                        ?.address
                    }
                  </Text>
                </Card>
              </Col>

              <Col span={24}>
                <Row>
                  <Col span={7}>
                    {translation.quotationDetails.quotationId.title}
                  </Col>
                  <Col span={12}>
                    <Text>
                      {
                        selectedResponse?.delivery_services?.lalamove
                          ?.quotationId
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>

              {selectedResponse?.delivery_services?.lalamove?.scheduleAt && (
                <Col span={24}>
                  <Row>
                    <Col span={7}>
                      {translation.orderDetails.scheduleAt.title}
                    </Col>
                    <Col span={12}>
                      <Text>
                        {convertUTCToUserTime(
                          selectedResponse?.delivery_services?.lalamove
                            ?.scheduleAt,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}

              {selectedResponse?.delivery_services?.lalamove?.expiresAt && (
                <Col span={24}>
                  <Row>
                    <Col span={7}>
                      {translation.orderDetails.expiresAt.title}
                    </Col>
                    <Col span={12}>
                      <Text>
                        {convertUTCToUserTime(
                          selectedResponse?.delivery_services?.lalamove
                            ?.expiresAt,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}

              {selectedResponse?.delivery_services?.lalamove?.serviceType && (
                <Col span={24}>
                  <Row>
                    <Col span={7}>
                      {translation.orderDetails.serviceType.title}
                    </Col>
                    <Col span={12}>
                      <Text>
                        {
                          lalamoveServiceTypes.find((serviceType) => {
                            return (
                              serviceType.key ===
                              selectedResponse?.delivery_services?.lalamove
                                ?.serviceType
                            );
                          })?.value
                        }
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col span={24}>
                <Row>
                  <Col span={7}>
                    {translation.orderDetails.totalPrice.title}
                  </Col>
                  <Col span={12}>
                    <Text>
                      {countriesCurrency.find((currency) => {
                        return (
                          currency.currencyCode ===
                          selectedResponse?.delivery_services?.lalamove
                            ?.priceBreakdown?.currency
                        );
                      })?.currencySymbol ||
                        selectedResponse?.delivery_services?.lalamove
                          ?.priceBreakdown?.currency}
                      {"  "}
                      {
                        selectedResponse?.delivery_services?.lalamove
                          ?.priceBreakdown?.total
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col span={7}>{translation.orderDetails.distance.title}</Col>
                  <Col span={12}>
                    <Text>
                      {
                        selectedResponse?.delivery_services?.lalamove?.distance
                          ?.value
                      }{" "}
                      {
                        selectedResponse?.delivery_services?.lalamove?.distance
                          ?.unit
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Divider
                style={{
                  margin: "10px 0",
                }}
              />

              {convertUTCToUserTime(
                selectedResponse?.delivery_services?.lalamove?.expiresAt,
                state.currentUser.user.timeZone,
                state.currentUser.user.dateFormat
              ) >
              getUserDateTime(
                new Date().toISOString(),
                state.currentUser.user.timeZone,
                state.currentUser.user.dateFormat
              ) ? (
                <Button type="primary" onClick={() => handleCreateOrder()}>
                  {translation.orderDetails.orderNow.buttonText}
                </Button>
              ) : (
                <Space direction="vertical" size="middle">
                  <Space>
                    <AiOutlineExclamationCircle
                      size={18}
                      style={{
                        color: "#faad14",
                        marginTop: "1px",
                      }}
                    />
                    <Text>{translation.createNewQuotation.description}</Text>
                  </Space>

                  <Button
                    danger
                    onClick={() => {
                      dispatchReducer({
                        type: "RESET_LALAMOVE_ORDER_DETAILS",
                      });
                      setShowQuotationForm(true);
                    }}
                  >
                    {translation.createNewQuotation.buttonText}
                  </Button>
                </Space>
              )}
            </Row>
          </Card>
        ) : (
          <Button
            danger
            onClick={() => {
              dispatchMiddleware({
                type: "GET_LALAMOVE_QUOTATION_DETAILS",
                payload: {
                  quotationId:
                    selectedResponse?.delivery_services?.lalamove?.quotationId,
                  market: lalamoveConfiguration.market,
                  component: "delivery-services-lalamove-panel",
                },
              });
            }}
          >
            {translation.checkQuotationDetails.buttonText}
          </Button>
        )
      ) : lalamoveConfiguration.status && showQuotationForm ? (
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleGetQuotation}
          form={form}
        >
          <Card
            title={<Text strong>{translation.deliveryDetailsForm.title}</Text>}
            size="small"
            style={{
              width: "100%",

              border: "1px solid #e8e8e8",
            }}
          >
            <Row gutter={[10, 15]}>
              {/* Pickup Address */}
              <Col span={12}>
                <Form.Item
                  name="pickupAddress"
                  label={
                    translation.deliveryDetailsForm.pickupAddressFormItem.label
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        translation.deliveryDetailsForm.pickupAddressFormItem
                          .required,
                    },
                  ]}
                  hasFeedback
                  className="delivery-details-form-item"
                >
                  <Select
                    showSearch
                    placeholder={
                      translation.deliveryDetailsForm.pickupAddressFormItem
                        .placeholder
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {lalamoveConfiguration.address?.map((address, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={address.name + " " + address.address}
                        >
                          {address.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* Service Type */}
              <Col span={12}>
                <Form.Item
                  name="serviceType"
                  label={
                    translation.deliveryDetailsForm.serviceTypeFormItem.label
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        translation.deliveryDetailsForm.serviceTypeFormItem
                          .required,
                    },
                  ]}
                  hasFeedback
                  valuePropName="value"
                  className="delivery-details-form-item"
                >
                  <Select
                    showSearch
                    placeholder={
                      translation.deliveryDetailsForm.serviceTypeFormItem
                        .placeholder
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {lalamoveServiceTypes?.map((serviceType, index) => {
                      return (
                        <Select.Option value={serviceType.key} key={index}>
                          {serviceType.value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* Delivery Address*/}
              <Col span={24}>
                <Form.Item
                  name="deliveryAddress"
                  label={
                    translation.deliveryDetailsForm.deliveryAddressFormItem
                      .label
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        translation.deliveryDetailsForm.deliveryAddressFormItem
                          .required,
                    },
                  ]}
                  hasFeedback
                  className="delivery-details-form-item"
                >
                  <Input.TextArea
                    placeholder={
                      translation.deliveryDetailsForm.deliveryAddressFormItem
                        .placeholder
                    }
                    autoSize={{ minRows: 2 }}
                    maxLength={1024}
                  />
                </Form.Item>
              </Col>

              {/* Pickup Time */}
              <Col span={24}>
                <Row>
                  <Col span={7}>
                    {translation.deliveryDetailsForm.pickupTime.title}
                  </Col>
                  <Col span={12}>
                    <Text>
                      {getUserDateTime(
                        pickupTime,
                        state.currentUser.user.timeZone,
                        state.currentUser.user.dateFormat
                      )}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Divider
                style={{
                  margin: "10px 0",
                }}
              />
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {translation.deliveryDetailsForm.getQuotation.buttonText}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      ) : (
        <Tooltip
          title={
            !lalamoveConfiguration.status &&
            Translation().clientResponses.infoPanelV2.deliveryServices
              .notConfigured
          }
        >
          <Button
            danger
            onClick={() => {
              setShowQuotationForm(true);
            }}
            disabled={!lalamoveConfiguration.status}
          >
            {translation.bookDelivery.buttonText}
          </Button>
        </Tooltip>
      );

    default:
      return null;
  }
};

export default DeliveryServicesPanel;
