import React from "react";
import CurrentUserForm from "./CurrentUserForm";
import { Drawer } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";

const CurrentUserFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <>
      <Drawer
        width={720}
        onClose={() => {
          dispatchReducer({
            type: "CLOSE_FORM_PANEL",
            payload: {
              component: "user-profile-setting-form-panel",
            },
          });
        }}
        title={
          state.formPanel["user-profile-setting-form-panel"] &&
          state.formPanel["user-profile-setting-form-panel"].title
        }
        open={
          state.formPanel["user-profile-setting-form-panel"] &&
          state.formPanel["user-profile-setting-form-panel"].visible
        }
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose={true}
      >
        <CurrentUserForm />
      </Drawer>
    </>
  );
};

export default CurrentUserFormPanel;
