import React from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Button, Row, Col, Modal, Typography, Card, Form } from "antd";
import {
  defaultActionValues,
  wooCommerceService,
} from "../../../constants/enums";
import { encryptKey } from "../../../utils/utility";
import { URIs } from "../../../constants/env";

const { Title, Text } = Typography;
const { confirm } = Modal;

const SubmitWebhook = ({
  webhooksData,
  triggerWebhooksData,
  setCurrentPosition,
  setWebhooksData,
  setTriggerWebhooksData,
  setAutoEmailTrigger, // Reset other state variables as well
  setExternal,
  setCreateContactFromResponse,
  setSelectedWebhookType, // Set the default value for webhook type
}) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const handleSubmitWebhookForm = () => {
    const updatedHeaders = webhooksData.headers?.reduce(
      (headersObj, { headerType, headerValue }) => ({
        ...headersObj,
        [headerType]: headerValue,
      })
    );

    const triggerPostData = JSON.parse(triggerWebhooksData?.postData || "{}");
    delete triggerWebhooksData.postData;

    const autoAssignedValue = triggerWebhooksData?.autoAssignTags || [];
    delete triggerWebhooksData.autoAssignTags;

    let modifiedWebhookForm;
    if (webhooksData.autoEmailTrigger === true) {
      modifiedWebhookForm = {
        ...webhooksData,
        webhookName: "Auto-email-trigger",
        serviceProvider: `Auto-email-trigger-${state.currentAccount.account.client_id}`,
        triggerCriteria: {
          criteriaValue: "All",
          module: "Responses",
          criteriaName: "partitionKey",
        },
        email: triggerWebhooksData,
        createdDate: Date.now(),
        createdBy: state.currentUser.user.displayName,
      };

      // Remove the "external" field when not needed
      delete modifiedWebhookForm.external;
    } else if (webhooksData?.whatsappNotification === true) {
      modifiedWebhookForm = {
        ...webhooksData,
        webhookName: `WhatsApp ${triggerWebhooksData?.criteriaValue} Notification`,
        serviceProvider: "Internal",
        createdDate: Date.now(),
        createdBy: state.currentUser.user.displayName,
        postData: triggerPostData,
        module:
          triggerWebhooksData?.module === "responses"
            ? "Responses"
            : triggerWebhooksData?.module,
        category: triggerWebhooksData?.criteriaValue,
        recipients: triggerWebhooksData?.recipients,
        triggerCriteria: {
          criteriaName: "partitionKey",
          module: triggerWebhooksData?.module,
          criteriaValue: triggerWebhooksData?.criteriaValue,
        },
        WABA_API_KEY: encryptKey({
          wabaApikey: botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey,
          clientId: state.currentAccount.account.client_id,
          AES_KEY: URIs.AES_KEY,
        }),
        WABA_PROVIDER: botInAction?.bot_channel_info?.WHATSAPP?.waba_provider,
        preview: triggerWebhooksData?.previewData,
      };
    } else {
      const { serviceProvider, ...otherData } = webhooksData;

      const customMappingPostData =
        Object.keys(triggerPostData).length > 0
          ? triggerPostData
          : defaultActionValues.contact;
      const webhookName = wooCommerceService?.find((service) =>
        webhooksData?.endPoint?.toUpperCase().includes(service)
      );
      const moduleValue =
        webhooksData?.integrationPlatform === "WOOCOMMERCE"
          ? "Responses"
          : webhooksData?.module;
      const categoryValue =
        webhooksData?.integrationPlatform === "WOOCOMMERCE"
          ? "Order"
          : webhooksData?.category;

      modifiedWebhookForm = {
        ...otherData,
        webhookName: `${
          !webhooksData.webhookName &&
          webhooksData?.method.toUpperCase() === "POST"
            ? "Create"
            : !webhooksData.webhookName &&
              webhooksData?.method.toUpperCase() === "PUT"
            ? "Update"
            : !webhooksData.webhookName &&
              webhooksData?.method.toUpperCase() === "GET"
            ? "Retrieve"
            : !webhooksData.webhookName &&
              webhooksData?.method.toUpperCase() === "DELETE"
            ? "Delete"
            : ""
        } ${
          webhooksData.webhookName ??
          webhookName.toLowerCase() ??
          webhooksData?.integrationPlatform.toLowerCase()
        }`,
        serviceProvider: `${
          webhooksData.webhookName ?? webhooksData.integrationPlatform
        }`,
        headers: updatedHeaders,
        postData:
          webhooksData.createContactFromResponse === true
            ? triggerPostData
            : customMappingPostData,
        autoAssignTags: autoAssignedValue,
        module: moduleValue,
        category: categoryValue,
        triggerCriteria:
          webhooksData.createContactFromResponse === true
            ? {
                criteriaValue: "All",
                module: "Responses",
                criteriaName: "partitionKey",
              }
            : {
                ...triggerWebhooksData,
                criteriaName: "partitionKey",
                module: moduleValue,
                criteriaValue: categoryValue,
              },
        createdDate: Date.now(),
        createdBy: state.currentUser.user.displayName,
      };

      // Remove the "email" field when not needed
      delete modifiedWebhookForm.email;

      // Remove the "external" field when not needed
      delete modifiedWebhookForm.external;
    }

    dispatchMiddleware({
      type: "MWD_ADD_EDIT_WEBHOOKS",
      payload: {
        webhook: modifiedWebhookForm,
        component: "webhooks-form-panel",
        module: JSON.stringify(webhooksData?.module),
      },
    });

    dispatchReducer({
      type: "SET_FORM_MODE",
      payload: {
        component: "webhooks-form",
        mode: "",
        title: "",
      },
    });
    setCurrentPosition(0);
    setWebhooksData(null);
    setTriggerWebhooksData(null);
    setAutoEmailTrigger(false); // Reset other state variables as well
    setExternal(false);
    setCreateContactFromResponse(false);
    setSelectedWebhookType("external"); // Set the default value for webhook type
  };

  const resetFormFields = () => {
    confirm({
      title: "Are you sure you want to close the webhook?",
      content: "When clicked the OK button, the unsaved inputs will be lost",
      onOk() {
        setCurrentPosition(0);
        setWebhooksData(null);
        setTriggerWebhooksData(null);
        setAutoEmailTrigger(false); // Reset other state variables as well
        setExternal(false);
        setCreateContactFromResponse(false);
        setSelectedWebhookType("external"); // Set the default value for webhook type

        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "webhooks-form-panel",
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card>
          <Form
            autoComplete="off"
            form={form}
            onFinish={handleSubmitWebhookForm}
          >
            <Title level={5}>Confirm Submission?</Title>
            <Text>
              When clicked the OK button, the webhook will be created.
            </Text>
            <Row style={{ marginTop: "10px" }}>
              <Button type="primary" htmlType="submit">
                OK
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  resetFormFields();
                }}
              >
                Cancel
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </>
  );
};

export default SubmitWebhook;
