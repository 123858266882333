import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  Tabs,
  Avatar,
  Row,
  Col,
  Typography,
  Card,
  Space,
  Tag,
  Image,
  Tooltip,
  Empty,
  Modal,
} from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  channels,
  mandatoryContactProperties,
  systemContactProperties,
} from "../../constants/enums";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { BiBroadcast } from "react-icons/bi";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import parsePhoneNumber from "libphonenumber-js";
import { appInsights } from "../../AppInsights";
import { mode } from "../../constants/env";

const { Text } = Typography;
const { confirm } = Modal;

const ContactManagerInfoPanel = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();

  const selectedContact = state.contactManager.contacts.find(
    (contact) =>
      contact._id === state.filters.actionRowKey["contact-manager-table"]
  );

  const BasicDetails = () => {
    return (
      <Row gutter={[0, 16]}>
        {selectedContact.firstName && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>First Name</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.firstName}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.lastName && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Last Name</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.lastName}</Text>
            </Col>
          </Col>
        )}

        <Col span={12}>
          <Col span={24}>
            <Text strong>Display Name</Text>
          </Col>
          <Col span={24}>
            <Text>{selectedContact.displayName}</Text>
          </Col>
        </Col>

        {selectedContact.email && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Email ID</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.email}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.mobile && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Mobile Number</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.mobile}</Text>
            </Col>
          </Col>
        )}

        {state.currentAccount.account.modules.broadcast.enabled &&
          selectedContact.whatsappId && (
            <Col span={12}>
              <Col span={24}>
                <Space>
                  <Image
                    src={channels.WHATSAPP.logo}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <Text strong>
                    {channels["WHATSAPP"].displayName.concat(" Number")}
                  </Text>

                  {!selectedContact.isUnsubscribed && (
                    <Tooltip
                      title={
                        !state.currentUser.permission.BROADCAST_CREATE
                          ? "You do not have required permission"
                          : parsePhoneNumber(
                              selectedContact.whatsappId
                            )?.isValid()
                          ? "Create a new broadcast"
                          : "Invalid phone number"
                      }
                    >
                      {state.currentUser.permission.BROADCAST_CREATE &&
                      // Check if the phone number is valid
                      parsePhoneNumber(
                        selectedContact.whatsappId
                      )?.isValid() ? (
                        <BiBroadcast
                          style={{
                            color: "#F05742",
                            fontSize: 19,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // Navigate to broadcast view
                            navigate("/broadcast-manager");
                            sessionStorage.setItem(
                              "selectedMenu",
                              "/broadcast-manager"
                            );

                            dispatchReducer({
                              type: "OPEN_FORM_PANEL",
                              payload: {
                                component: "broadcast-form-panel",
                                title: "Create New Broadcast",
                              },
                            });

                            const phoneNumber = parsePhoneNumber(
                              selectedContact.whatsappId
                            );

                            // Split the phone number and country code
                            if (phoneNumber) {
                              dispatchReducer({
                                type: "SET_SINGLE_CONTACT_TO_POPULATE",
                                payload: {
                                  contact: {
                                    displayName: selectedContact.displayName,
                                    countryCode: phoneNumber.countryCallingCode,
                                    phoneNumberWithoutCountryCode:
                                      phoneNumber.nationalNumber,
                                  },
                                },
                              });
                            }

                            dispatchReducer({
                              type: "CLOSE_INFO_PANEL",
                              payload: {
                                component: "contact-manager-info-panel",
                              },
                            });

                            appInsights.trackEvent({
                              name: "Contacts - Create Broadcast",
                              properties: {
                                environment: mode,
                                clientId:
                                  state.currentAccount.account.client_id,
                              },
                            });
                          }}
                        />
                      ) : (
                        <BiBroadcast
                          style={{
                            color: "#C0C0C0",
                            fontSize: 19,
                          }}
                        />
                      )}
                    </Tooltip>
                  )}
                </Space>
              </Col>
              <Col span={24}>
                <Text>{selectedContact.whatsappId}</Text>
              </Col>
            </Col>
          )}

        {selectedContact.companyName && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Company</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.companyName}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.address1 && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Address 1</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.address1}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.address2 && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Address 2</Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.address2}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.city && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>City </Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.city}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.state && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>State </Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.state}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.postCode && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Post Code </Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.postCode}</Text>
            </Col>
          </Col>
        )}

        {selectedContact.country && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Country </Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.country}</Text>
            </Col>
          </Col>
        )}

        <Col span={12}>
          <Col
            span={24}
            style={{
              marginBottom: 5,
            }}
          >
            <Text strong>Subscribed </Text>
          </Col>
          <Col span={24}>
            {selectedContact.isUnsubscribed ? (
              <Tag color="red">No</Tag>
            ) : (
              <Tag color="success">Yes</Tag>
            )}
          </Col>
        </Col>

        {selectedContact.tags && (
          <Col span={12}>
            <Col
              span={24}
              style={{
                marginBottom: 5,
              }}
            >
              <Text strong>Tags </Text>
            </Col>
            <Col span={24}>
              {selectedContact.tags.split(",").map((tag, index) => {
                return (
                  <Tag
                    color="blue"
                    key={index}
                    type="round"
                    style={{ borderRadius: "4px", marginBottom: "6px" }}
                  >
                    {tag.trim()}
                  </Tag>
                );
              })}
            </Col>
          </Col>
        )}

        {selectedContact.createdSource && (
          <Col span={12}>
            <Col span={24}>
              <Text strong>Created Source </Text>
            </Col>
            <Col span={24}>
              <Text>{selectedContact.createdSource}</Text>
            </Col>
          </Col>
        )}
      </Row>
    );
  };

  const SocialMediaDetails = () => {
    return (
      <Row gutter={[0, 20]}>
        {selectedContact.whatsappId && (
          <>
            <Col span={3} style={{ alignItems: "center" }}>
              <Image
                src={channels.WHATSAPP.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.whatsappId}>
                <Text ellipsis>{selectedContact.whatsappId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.googleBusinessMessagesId && (
          <>
            <Col span={3} style={{ alignItems: "center" }}>
              <Image
                src={channels.GBM.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.googleBusinessMessagesId}>
                <Text ellipsis>{selectedContact.googleBusinessMessagesId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.telegramId && (
          <>
            <Col span={3}>
              <Image
                src={channels.TELEGRAM.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.telegramId}>
                <Text ellipsis>{selectedContact.telegramId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.appleID && (
          <>
            <Col span={3}>
              <Image
                src={channels.AMB.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.appleID}>
                <Text ellipsis>{selectedContact.appleID}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.webChatID && (
          <>
            <Col span={3}>
              <Image
                src={channels.WEB.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>

            <Col span={18}>
              <Tooltip title={selectedContact.webChatID}>
                <Text ellipsis>{selectedContact.webChatID}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.instagramId && (
          <>
            <Col span={3}>
              <Image
                src={channels.INSTAGRAM.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.instagramId}>
                <Text ellipsis>{selectedContact.instagramId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.viberId && (
          <>
            <Col span={3}>
              <Image
                src={channels.VIBER.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.viberId}>
                <Text ellipsis>{selectedContact.viberId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.facebookId && (
          <>
            <Col span={3}>
              <Image
                src={channels.FACEBOOK.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.facebookId}>
                <Text ellipsis>{selectedContact.facebookId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {selectedContact.msTeamsId && (
          <>
            <Col span={3}>
              <Image
                src={channels.MSTEAMS.logo}
                width={20}
                height={20}
                preview={false}
              />
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <Tooltip title={selectedContact.msTeamsId}>
                <Text ellipsis>{selectedContact.msTeamsId}</Text>
              </Tooltip>
            </Col>
          </>
        )}

        {!(
          selectedContact.whatsappId ||
          selectedContact.googleBusinessMessagesId ||
          selectedContact.telegramId ||
          selectedContact.appleID ||
          selectedContact.webChatID ||
          selectedContact.instagramId ||
          selectedContact.viberId ||
          selectedContact.facebookId ||
          selectedContact.msTeamsId
        ) && (
          <Row>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginLeft: 180 }}
            />
          </Row>
        )}
      </Row>
    );
  };

  const CustomFields = () => {
    const [customFormItems, setCustomFormItems] = useState([]);

    useEffect(() => {
      let standardContactFormFields = mandatoryContactProperties.concat(
        systemContactProperties
      );
      setCustomFormItems(
        Object.entries(selectedContact)
          .filter(([key]) => !standardContactFormFields.includes(key))
          .map(([key, value]) => ({ label: key, value }))
      );
    }, []);

    return (
      <>
        {customFormItems.length > 0 ? (
          <Row gutter={[16, 16]}>
            {customFormItems.map((formItem, index) => (
              <Col span={12} key={index}>
                <Col span={24}>
                  <Text strong>{formItem.label}</Text>
                </Col>
                <Col span={24}>
                  <Text>{formItem.value}</Text>
                </Col>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginLeft: 180 }}
            />
          </Row>
        )}
      </>
    );
  };

  const tabPane = [
    {
      key: "basicDetails",
      label: "Basic Details",
      children: <BasicDetails />,
    },
    {
      key: "socialMedia",
      label: "Social Media",
      children: <SocialMediaDetails />,
    },

    {
      key: "customFields",
      label: "Custom Fields",
      children: <CustomFields />,
    },
  ];

  return (
    <Drawer
      title="Contact Info"
      placement="right"
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "contact-manager-info-panel",
          },
        });

        dispatchReducer({
          type: "RD_CLEAR_ACTION_ROW_KEY",
          payload: { component: "contact-manager-table" },
        });
      }}
      open={
        state.infoPanel["contact-manager-info-panel"] &&
        state.infoPanel["contact-manager-info-panel"].visible
      }
      width={550}
      destroyOnClose={true}
    >
      {selectedContact && (
        <>
          <Card>
            <Space
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Space size={20}>
                <Avatar size={60} src={""} shape="square">
                  {selectedContact.displayName[0]}
                </Avatar>
                <Text strong>{selectedContact.displayName}</Text>
              </Space>
              <Space size={20}>
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission.CONTACT_EDIT
                      ? "Edit Contact"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.CONTACT_EDIT ? (
                    <EditOutlined
                      style={{ color: "#F05742" }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_ACTION_ROW_KEY",
                          payload: {
                            component: "contact-manager-table",
                            actionRowKey: selectedContact._id,
                          },
                        });
                        dispatchReducer({
                          type: "OPEN_FORM_PANEL",
                          payload: {
                            component: "contact-manager-form-panel",
                            title: "Edit Contact",
                          },
                        });
                        dispatchReducer({
                          type: "SET_FORM_MODE",
                          payload: {
                            component: "contact-manager-form",
                            mode: "edit",
                          },
                        });
                      }}
                    />
                  ) : (
                    <EditOutlined style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
                {state.apiCallStatus[
                  "contact-manager-" + selectedContact._id + "-delete"
                ]?.loading ? (
                  <ComponentLoader
                    component={
                      "contact-manager-" + selectedContact._id + "-delete"
                    }
                    loadingMessage=""
                    errorMessage=""
                  />
                ) : (
                  <Tooltip
                    placement="topRight"
                    title={
                      state.currentUser.permission.CONTACT_DELETE
                        ? "Delete Contact"
                        : "You do not have required permission"
                    }
                  >
                    {state.currentUser.permission.CONTACT_DELETE ? (
                      <DeleteOutlined
                        style={{ color: "#F05742" }}
                        onClick={() => {
                          dispatchReducer({
                            type: "RD_SET_ACTION_ROW_KEY",
                            payload: {
                              component: "contact-manager-table",
                              actionRowKey: selectedContact._id,
                            },
                          });
                          confirm({
                            title:
                              "Are you sure you want to delete the contact?",
                            content:
                              "When clicked the OK button, the contact will be deleted permanently",
                            onOk() {
                              dispatchMiddleware({
                                type: "MWD_DELETE_SINGLE_CONTACT",
                                payload: {
                                  component:
                                    "contact-manager-" +
                                    selectedContact._id +
                                    "-delete",
                                  actionRowKey: selectedContact._id,
                                },
                              });
                            },
                            onCancel() {},
                          });
                        }}
                      />
                    ) : (
                      <DeleteOutlined style={{ color: "#C0C0C0" }} />
                    )}
                  </Tooltip>
                )}
              </Space>
            </Space>
          </Card>

          <Card
            style={{
              marginTop: 10,
            }}
          >
            <Tabs defaultActiveKey="basicDetails" items={tabPane} />
          </Card>
        </>
      )}
    </Drawer>
  );
};

export default ContactManagerInfoPanel;
