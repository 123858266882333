import React, { useEffect } from "react";
import { Select } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";

const { Option } = Select;

const ResponsesDropdown = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useEffect(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_RESPONSE_PROPERTIES",
      payload: {
        component: "response-dropdown",
      },
    });
  }, [state.responseSettings.currentResponseCategory]);

  const responseCategories = state.responseSettings.responseCategories;

  return (
    <Select
      allowClear
      value={state.responseSettings.currentResponseCategory}
      defaultValue={responseCategories?.[0]}
      style={{
        width: 290,
        display: "flex",
        marginLeft: "auto",
        marginRight: 40,
      }}
      size="medium"
      onSelect={(currentResponseCategory) => {
        dispatchReducer({
          type: "RD_SET_RESPONSE_SETTINGS_CURRENT_RESPONSE_CATEGORY",
          payload: {
            currentResponseCategory,
          },
        });
      }}
    >
      {responseCategories?.map((responseCategory, index) => {
        return (
          <Option value={responseCategory} key={index}>
            {responseCategory}
          </Option>
        );
      })}
    </Select>
  );
};

export default ResponsesDropdown;
