const applicationReducer = (state, action) => {
  switch (action.type) {
    case "SET_APPLICATION_ROLES":
      return {
        ...state,
        application: {
          ...state.application,
          applicationRoles: action.applicationRoles,
        },
      };

    case "RD_SET_BOT_IN_ACTION":
      const botInAction = localStorage.getItem("botInAction")
        ? JSON.parse(localStorage.getItem("botInAction"))
        : {};
      if (
        (action.payload.event === "applicationOnLoad" &&
          // Handle when localstorage bot is not present in account bots
          !action.payload.account.bots.find(
            (bot) => bot.bot_id === botInAction[action.payload.clientId]
          )) ||
        !botInAction[action.payload.clientId] ||
        action.payload.event === "globalBotOnChange"
      ) {
        botInAction[action.payload.clientId] = action.payload.botInAction;
        localStorage.setItem("botInAction", JSON.stringify(botInAction));
      }

      return {
        ...state,
        application: {
          ...state.application,
          botInAction: botInAction[action.payload.clientId],
        },
      };

    case "RD_SET_ENUMS":
      return {
        ...state,
        application: {
          ...state.application,
          enums: action.payload.enums,
        },
      };

    case "SET_LANGUAGE":
      localStorage.setItem("selectedLanguage", action.payload.language);
      return {
        ...state,
        application: {
          ...state.application,
          selectedLanguage: action.payload.language,
        },
      };

    default:
      return state;
  }
};

export default applicationReducer;
