import { useGlobalContext } from "../../../contexts/globalContext";
import { Modal, Row, Col, Space, Typography, Card } from "antd";

const { Text } = Typography;

const BotKnowledgebaseTemplate = ({ handleSelectTemplate }) => {
  const [state, dispatchReducer] = useGlobalContext();

  const business = [
    {
      id: "restaurant",
      title: "Restaurant",
      description:
        "Template for restaurant-related businesses like restaurants, cafes, hotels, etc.",
      contents: [
        {
          title: "About us",
          content: "",
        },
        {
          title: "Buffet",
          content: "",
        },
        {
          title: "Career",
          content: "",
        },
        {
          title: "Catering",
          content: "",
        },
        {
          title: "Combo Packages",
          content: "",
        },
        {
          title: "Contact",
          content: "",
        },
        {
          title: "Cuisines",
          content: "",
        },
        {
          title: "Delivery charges",
          content: "",
        },
        {
          title: "Delivery partner",
          content: "",
        },
        {
          title: "Delivery",
          content: "",
        },
        {
          title: "Dine-in",
          content: "",
        },
        {
          title: "Entertainment (live music)",
          content: "",
        },
        {
          title: "Estimated cost for 2 pax",
          content: "",
        },
        {
          title: "Halal",
          content: "",
        },
        {
          title: "Jain food",
          content: "",
        },
        {
          title: "Location",
          content: "",
        },
        {
          title: "Menu",
          content: "",
        },
        {
          title: "Parking facility",
          content: "",
        },
        {
          title: "Party halls/Banquets",
          content: "",
        },
        {
          title: "Payment options",
          content: "",
        },
        {
          title: "Pickup & delivery timings",
          content: "",
        },
        {
          title: "Place order",
          content: "",
        },
        {
          title: "Pre-order availability",
          content: "",
        },
        {
          title: "Promotions",
          content: "",
        },
        {
          title: "Table reservation",
          content: "",
        },
        {
          title: "Takeaway",
          content: "",
        },
        {
          title: "Tax",
          content: "",
        },
        {
          title: "Track order",
          content: "",
        },
        {
          title: "Vegan food",
          content: "",
        },
        {
          title: "Working hours",
          content: "",
        },
      ],
    },
    {
      id: "healthcare",
      title: "Healthcare",
      description:
        "Template for healthcare-related businesses like hospitals, clinics, medical centers, etc.",
      contents: [
        {
          title: "About us",
          content: "",
        },
        {
          title: "Accessibility for Disabled Patients",
          content: "",
        },
        {
          title: "Appointment Reminders",
          content: "",
        },
        {
          title: "Appointment Scheduling",
          content: "",
        },
        {
          title: "Cancel Appointment",
          content: "",
        },
        {
          title: "Check-up Reminders",
          content: "",
        },
        {
          title: "Contacts",
          content: "",
        },
        {
          title: "Emergency Contact Numbers",
          content: "",
        },
        {
          title: "Emergency Contact",
          content: "",
        },
        {
          title: "Feedback and Complaints (How to Provide Feedback)",
          content: "",
        },
        {
          title: "Fees and Billing",
          content: "",
        },
        {
          title: "Insurance Information",
          content: "",
        },
        {
          title: "Locations",
          content: "",
        },
        {
          title: "Medical Staff Information",
          content: "",
        },
        {
          title: "Operating Hours",
          content: "",
        },
        {
          title: "Parking Facilities",
          content: "",
        },
        {
          title: "Payment Options",
          content: "",
        },
        {
          title: "Pharmacy Services",
          content: "",
        },
        {
          title: "Reschedule Appointment",
          content: "",
        },
        {
          title: "Services and Their Costs",
          content: "",
        },
        {
          title: "Services Offered",
          content: "",
        },
        {
          title: "Timings",
          content: "",
        },
        {
          title: "Waiting Area Amenities",
          content: "",
        },
      ],
    },
    {
      id: "bank",
      title: "Bank",
      description:
        "Template for bank-related businesses like banks, credit unions, investment firms, etc.",
      contents: [
        {
          title: "Account Information",
          content: "",
        },
        {
          title: "Online Banking",
          content: "",
        },
        {
          title: "Payments and Transfers",
          content: "",
        },
        {
          title: "Debit/Credit Cards",
          content: "",
        },
        {
          title: "Loans and Mortgages",
          content: "",
        },
        {
          title: "Security and Fraud",
          content: "",
        },
        {
          title: "Branch and ATM Locations",
          content: "",
        },
        {
          title: "Account Closure and Account Opening",
          content: "",
        },
        {
          title: "Mobile Banking",
          content: "",
        },
        {
          title: "Interest Rates and Fees",
          content: "",
        },
        {
          title: "Customer Support",
          content: "",
        },
        {
          title: "General Banking Policies",
          content: "",
        },
        {
          title: "Mobile Wallet and Contactless Payments",
          content: "",
        },
        {
          title: "ATM and Debit Card Usage Abroad",
          content: "",
        },
        {
          title: "Account Upgrades and Features",
          content: "",
        },
        {
          title: "Tax and Regulatory Information",
          content: "",
        },
      ],
    },
    {
      id: "retail",
      title: "Retail",
      description:
        "Template for retail-related businesses like retail stores, supermarkets, etc.",
      contents: [
        {
          title: "Ordering and Purchasing",
          content: "",
        },
        {
          title: "Shipping and Delivery",
          content: "",
        },
        {
          title: "Returns and Exchanges",
          content: "",
        },
        {
          title: "Product Information",
          content: "",
        },
        {
          title: "Account Management",
          content: "",
        },
        {
          title: "Customer Support",
          content: "",
        },
        {
          title: "Privacy and Security",
          content: "",
        },
        {
          title: "Promotions and Loyalty Programs",
          content: "",
        },
        {
          title: "Store Locations and Hours",
          content: "",
        },
        {
          title: "Website Navigation and Technical Issues",
          content: "",
        },
        {
          title: "COVID-19 Policies and Safety Measures",
          content: "",
        },
        {
          title: "Gift Cards and Gift Giving",
          content: "",
        },
      ],
    },
    {
      id: "custom",
      title: "Custom",
      description:
        "If you not find your business type, you can create custom template for your business. It's up to you.",
      contents: [
        {
          title: "About us",
          content: "",
        },
      ],
    },
  ];

  return (
    <Modal
      title={<Text strong>Knowledgebase Templates</Text>}
      open={state.infoPanel?.["knowledgebase-templates-info-panel"]?.visible}
      footer={null}
      onCancel={() =>
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "knowledgebase-templates-info-panel",
          },
        })
      }
      width={850}
      bodyStyle={{
        backgroundColor: "#F4F7F9",
      }}
    >
      <Row
        gutter={[16, 16]}
        style={{
          marginTop: -10,
        }}
      >
        <Col span={24}>
          <Text>
            Knowledgebase templates come with ready-made information tailored
            for your business. Easily create your knowledgebase by choosing from
            these templates and customize them to fit your specific needs.
          </Text>
        </Col>

        {business.map(({ id, title, description, contents }, index) => {
          return (
            <Col span={8} key={index}>
              <Card
                size="small"
                className="card-rounded-corner"
                hoverable
                title={
                  <Text
                    strong
                    style={{
                      color: "#fff",
                    }}
                  >
                    {title}
                  </Text>
                }
                headStyle={{
                  textAlign: "center",
                  backgroundColor: "#F05742",
                  borderRadius: 5,
                }}
                onClick={() =>
                  handleSelectTemplate({
                    id,
                    title,
                    description,
                    contents,
                  })
                }
              >
                <Space direction="vertical">
                  <Text>{description}</Text>
                  {/* <Link>
                  Add to my knowledgebase
                  </Link> */}
                </Space>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

export default BotKnowledgebaseTemplate;
