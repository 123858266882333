import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Space, Tooltip, Image } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { wabaProvider, templateStatusIcon } from "../../../constants/enums";

const MessageTemplatesTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const { confirm } = Modal;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const dataColumns = [
    {
      title: "TEMPLATE NAME",
      dataIndex: "name",
      key: "name",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => {
        let valueA = a.name == null ? "" : a.name;
        let valueB = b.name == null ? "" : b.name;
        return valueA.localeCompare(valueB);
      },
      render: (_, record) => {
        return `${record.name} (${record.language})`;
      },
    },
    {
      title: "TEMPLATE CATEGORY",
      dataIndex: "category",
      key: "category",
      ellipsis: true,
      width: 130,
      sorter: (a, b) => {
        let valueA = a.category == null ? "" : a.category;
        let valueB = b.category == null ? "" : b.category;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: "TEMPLATE MESSAGE",
      dataIndex: "components",
      key: "templateMessage",
      ellipsis: false,
      render: (_, record) => {
        const templateMessage = record.components.find(
          (component) => component.type === "BODY"
        );
        return templateMessage.text;
      },
      width: 540,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: 100,
      align: "center",
      render: (templateStatus) => {
        let upperCaseStatus = templateStatus.toUpperCase();
        let iconSource, tooltipText;

        switch (upperCaseStatus) {
          case "APPROVED":
            iconSource = templateStatusIcon.approved;
            tooltipText = "Approved";
            break;
          case "SUBMITTED":
            iconSource = templateStatusIcon.submitted;
            tooltipText = "Submitted";
            break;
          case "PENDING":
            iconSource = templateStatusIcon.pending;
            tooltipText = "Pending";
            break;
          case "REJECTED":
            iconSource = templateStatusIcon.rejected;
            tooltipText = "Rejected";
            break;
          default:
            // Handle unknown status values gracefully
            return null;
        }

        return (
          <Tooltip title={tooltipText}>
            <Image src={iconSource} preview={false} width={17} />
          </Tooltip>
        );
      },
      filters: [
        {
          text: "Submitted",
          value: "submitted",
        },
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Approved",
          value: "approved",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      width: 80,
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            {state.apiCallStatus[
              "message-template-" + record.name + "-delete"
            ] &&
            state.apiCallStatus["message-template-" + record.name + "-delete"]
              .loading ? (
              <ComponentLoader
                component={"message-template-" + record.name + "-delete"}
                loadingMessage=""
                errorMessage=""
              />
            ) : (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.MESSAGE_TEMPLATES_DELETE
                    ? "Delete Template"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission.MESSAGE_TEMPLATES_DELETE ? (
                  <DeleteOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "message-templates-table",
                          actionRowKey: record.name,
                        },
                      });
                      confirm({
                        title:
                          "Are you sure you want to delete the message template",
                        content:
                          "When clicked the OK button, the message template will be deleted permanently",
                        onOk() {
                          dispatchMiddleware({
                            type: "DELETE_MESSAGE_TEMPLATE",
                            payload: {
                              component:
                                "message-template-" + record.name + "-delete",
                              templateName: record.name,
                              wabaApikey:
                                botInAction?.bot_channel_info?.WHATSAPP
                                  ?.waba_apikey,
                              wabaProvider:
                                wabaProvider[
                                  botInAction.bot_channel_info?.WHATSAPP
                                    ?.waba_provider
                                ]?.providerReference.providerName,
                              botId: state.application.botInAction,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                ) : (
                  <DeleteOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return { dataColumns };
};

export default MessageTemplatesTableColumns;
