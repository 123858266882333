import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import AppContainer from "./AppContainer";
import "./AppLayout.less";
import { GlobalContextProvider } from "../contexts/globalContext";
import { MsalProvider } from "@azure/msal-react";

const AppLayout = ({ instance }) => {
  // Redirect to new endpoint when user access old endpoint
  useEffect(() => {
    const { hostname } = window.location;
    let redirectUrl;

    switch (hostname) {
      case "inextlabs-portal-v2-staging-as.azurewebsites.net":
        redirectUrl = "https://staging.inextlabs.ai";
        break;
      case "inextlabsportalv2-pre-prod.azurewebsites.net":
        redirectUrl = "https://preprod.inextlabs.ai";
        break;
      case "inextlabsportalv2-prod.azurewebsites.net":
        redirectUrl = "https://portal.inextlabs.ai";
        break;
      default:
        return;
    }

    window.location.href = redirectUrl;
  }, []);

  return (
    <MsalProvider instance={instance}>
      <GlobalContextProvider>
        <Layout style={{ minHeight: "100vh" }}>
          <BrowserRouter>
            <AppContainer />
          </BrowserRouter>
        </Layout>
      </GlobalContextProvider>
    </MsalProvider>
  );
};

export default AppLayout;
