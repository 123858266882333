import React from "react";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";
import AccessDenied from "../../shared-components/AccessDenied";
import { useGlobalContext } from "../../contexts/globalContext";
import { Layout } from "antd";
import ConversationContacts from "./ConversationContacts";
import ConversationTranscripts from "./ConversationTranscripts";
import ConversationHeader from "./ConversationHeader";
import ConversationFooter from "./ConversationFooter";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import ConversationInfoPanel from "./ConversationInfoPanel";
import ConversationNotesInfoPanel from "./ConversationNotesInfoPanel";
import { useWindowSize } from "../../shared-components/PageReSize";
import "./Conversations.less";

const Conversation = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();

  useConnectMiddleware(() => {
    // Check Notify only customers
    if (
      !state.currentAccount.account.bots.every(
        ({ bot_version }) => bot_version === "V0.1"
      )
    ) {
      state.currentUser.permission.CONVERSATIONS_VIEW &&
        //No need to trigger all conversations if the user clicks on the conversation icon in response
        !state.conversations.conversationFilter &&
        dispatchMiddleware({
          type: "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
          payload: {
            component: "conversations-layout",
            pageSize: Math.round(height / 60),
            pageNumber: state.conversations.contactsWithTranscripts
              ? Math.floor(
                  state.conversations.contactsWithTranscripts.length / 12
                ) + 1
              : 1,
            delta: state.conversations.contactsWithTranscripts ? true : false,
          },
        });

      !state.conversations.liveAgentSettingsAcrossBots &&
        dispatchMiddleware({
          type: "LOAD_LIVE_AGENT_SETTINGS_ACROSS_BOTS",
          payload: {
            component: "live-agent-setting",
          },
        });

      dispatchMiddleware({
        type: "LOAD_UNREAD_MESSAGES_COUNT",
        payload: {
          component: "conversations-badge",
        },
      });
    }
  });

  return !state.currentAccount.account.modules.conversation.enabled ||
    // Check Notify only customers
    state.currentAccount.account.bots.every(
      (bot) => bot.bot_version === "V0.1"
    ) ? (
    <Layout.Content
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModuleNotSubscribed module="conversations" />
    </Layout.Content>
  ) : !state.currentUser.permission.CONVERSATIONS_VIEW ? (
    <AccessDenied />
  ) : state.conversations.contactsWithTranscripts ? (
    <Layout.Content
      style={{
        height: height - 100, //To fix the grid height
        display: "grid",
        gridTemplateColumns:
          state.infoPanel["conversation-info-panel"]?.visible === true ||
          state.infoPanel["conversation-notes-info-panel"]?.visible === true
            ? "2fr 4fr 2fr"
            : "2fr 5fr",
        border: "1px solid #E9E9E9",
      }}
    >
      <Layout.Content
        style={{
          height: "100%",
          borderRight: "1px solid #E9E9E9",
        }}
      >
        <ConversationContacts />
      </Layout.Content>
      <Layout.Content
        style={{
          height: "100%",
          borderRight: "1px solid #E9E9E9",
        }}
      >
        <ConversationHeader />
        <ConversationTranscripts />
        <ConversationFooter />
      </Layout.Content>
      {state.infoPanel["conversation-info-panel"]?.visible === true && (
        <Layout.Content
          style={{
            height: "100%",
            borderRight: "1px solid #E9E9E9",
          }}
        >
          <ConversationInfoPanel />
        </Layout.Content>
      )}

      {state.infoPanel["conversation-notes-info-panel"]?.visible === true && (
        <Layout.Content
          style={{
            height: "100%",
            borderRight: "1px solid #E9E9E9",
          }}
        >
          <ConversationNotesInfoPanel />
        </Layout.Content>
      )}
    </Layout.Content>
  ) : (
    <FullPageLoader
      component="conversations-layout"
      loadingMessage="Loading... Please wait..."
    />
  );
};

export default Conversation;
