import React, { useState } from "react";
import { Col, Layout, Row } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import MessageTemplatesTable from "./MessageTemplatesTable";
import MessageTemplatesHeader from "./MessageTemplatesHeader";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import AccessDenied from "../../../shared-components/AccessDenied";
import GetStarted from "../../../shared-components/GetStarted";
import { wabaProvider } from "../../../constants/enums";
import MessageTemplateFormPanel from "./MessageTemplateFormPanel";
import BroadcastPricingCalculator from "../../broadcast-manager/BroadcastPricingCalculator";

const MessageTemplates = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [showMessageTemplateScreen, setShowMessageTemplateScreen] =
    useState(false);

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const wabaProviderName =
    wabaProvider[botInAction.bot_channel_info?.WHATSAPP?.waba_provider]
      ?.providerReference.providerName;

  const selectedBotTemplates = state.messageTemplates.templates?.find(
    (template) => template.botId === state.application.botInAction
  );

  useConnectMiddleware(
    () => {
      if (!selectedBotTemplates) {
        dispatchMiddleware({
          type: "MWD_LOAD_MESSAGE_TEMPLATE",
          payload: {
            wabaApikey: botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey,
            botId: state.application.botInAction,
            wabaProvider: wabaProviderName,
            component: "message-templates-table",
          },
        });
      }
      dispatchMiddleware({
        type: "MWD_LOAD_BROADCAST_SETTINGS",
        payload: {
          component: "broadcast-settings",
        },
      });

      setShowMessageTemplateScreen(false);
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return state.currentUser.permission.MESSAGE_TEMPLATES_VIEW ? (
    botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey ||
    wabaProviderName === "INEXTLABS-CLOUD" ||
    showMessageTemplateScreen ? (
      selectedBotTemplates || showMessageTemplateScreen ? (
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <MessageTemplatesHeader wabaProviderName={wabaProviderName} />
          </Col>
          <Col span={24}>
            <MessageTemplatesTable />
          </Col>
          <MessageTemplateFormPanel />
          <BroadcastPricingCalculator />
        </Row>
      ) : (
        <FullPageLoader
          component="message-templates-table"
          loadingMessage="Loading... Please wait..."
        />
      )
    ) : (
      <Layout.Content
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "60px",
        }}
      >
        <GetStarted
          source="message-templates"
          setShowMessageTemplateScreen={setShowMessageTemplateScreen}
        />
      </Layout.Content>
    )
  ) : (
    <AccessDenied />
  );
};

export default MessageTemplates;
