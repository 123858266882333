import React from "react";
import { Table, Tooltip, Row, Col, Typography, Image, Empty } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { channels } from "../../../constants/enums";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { getUserDateTime } from "../../../utils/utility";

const { Text } = Typography;

const NoneIntentMessageCountTable = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchMiddleware({
        type: "MWD_LOAD_NONE_INTENT_MESSAGE_COUNT",
        payload: {
          component: "analytics-none-intent-message-chart",
          analyticsFilterParams: {
            dateFilter: state.analytics.filterByDateForCharts,
            channelFilter: state.analytics.filterByChannelForCharts,
          },
        },
      });
    },
    {
      date: state.analytics.filterByDateForCharts,
      channel: state.analytics.filterByChannelForCharts,
      botInAction: state.application.botInAction,
    }
  );

  const noneIntentMessagesColumn = [
    {
      title: "MESSSAGE",
      dataIndex: "_id",
      key: "_id",
      width: 220,
      ellipsis: true,
    },
    {
      title: "CHANNEL",
      dataIndex: "channel",
      key: "channel",
      width: 150,
      ellipsis: true,
      align: "center",
      render: (_, record) => {
        const channel = record.channel.toUpperCase();
        return (
          channels[channel] && (
            <Image src={channels[channel].logo} width={23} preview={false} />
          )
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      ellipsis: true,
      sorter: (a, b) => {
        let valueA = a.date == null ? "" : a.date;
        let valueB = b.date == null ? "" : b.date;
        return valueA.localeCompare(valueB);
      },
      render: (_, record) => {
        return getUserDateTime(
          record.date,
          state.currentUser.user.timeZone,
          state.currentUser.user.dateFormat
        );
      },
    },
  ];

  return (
    <>
      {state.analytics.conversationCharts.noneIntentMessage ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> None Intent Message </Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This section shows the date of the inquiry, the channel via which the none intent was asked, 
                      and the none intent requested by clients and the date.."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.conversationCharts.noneIntentMessage.length ? (
            <Table
              dataSource={state.analytics.conversationCharts.noneIntentMessage}
              columns={noneIntentMessagesColumn}
              pagination={{ pageSize: 5 }}
              style={{
                padding: "0px 30px",
                height: "300px",
                overflowY: "auto",
              }}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-none-intent-message-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default NoneIntentMessageCountTable;
