import { Col, Row, Tabs, Space, Layout, Empty } from "antd";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useGlobalContext } from "../../contexts/globalContext";
import useConnectMiddleware from "../../connect/connectMiddleware";
import ClientResponsesList from "./ClientResponsesList";
import ClientResponsesMenu from "./ClientResponsesMenu";
import {
  FullPageLoader,
  TabLoader,
} from "../../shared-components/LoadingIndicator";
import AccessDenied from "../../shared-components/AccessDenied";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";

const ClientResponses = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      state.currentUser.permission.RESPONSES_VIEW &&
        dispatchReducer({ type: "RD_CLEAR_RESPONSES" });
      dispatchReducer({ type: "RD_CLEAR_RESPONSE_CATEGORIES" });
      dispatchReducer({ type: "RD_CLEAR_CURRENT_RESPONSE_CATEGORY" });
      dispatchMiddleware({
        type: "MWD_LOAD_RESPONSE_CATEGORIES",
        payload: { component: "client-responses-table" },
      });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  const currentResponseCategory = state.clientResponses.currentResponseCategory;
  let responseCategoriesCollapsed =
    state.clientResponses.responseCategoriesCollapsed;

  return (
    <>
      {!state.currentAccount.account.modules.responses ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModuleNotSubscribed module="client-responses" />
        </Layout.Content>
      ) : !state.currentUser.permission.RESPONSES_VIEW ? (
        <AccessDenied />
      ) : state.clientResponses.responseCategories ? (
        <>
          {state.clientResponses.responseCategories.length !== 0 ? (
            <Tabs
              type="card"
              items={[
                {
                  key: "1",
                  label: (
                    <Space size={15}>
                      <HiOutlineLightBulb size={18} style={{ margin: -4 }} />
                      Responses
                    </Space>
                  ),
                  children: (
                    <Row>
                      <Col span={!responseCategoriesCollapsed ? 4 : 1}>
                        <ClientResponsesMenu />
                      </Col>
                      {currentResponseCategory && (
                        <Col span={!responseCategoriesCollapsed ? 20 : 23}>
                          <ClientResponsesList />
                        </Col>
                      )}
                    </Row>
                  ),
                },
              ]}
              tabBarExtraContent={
                <TabLoader
                  component="client-response-table"
                  loadingMessage="Synchronizing responses..."
                  errorMessage="Error synchronizing responses"
                />
              }
            />
          ) : (
            <Tabs
              type="card"
              items={[
                {
                  key: "1",
                  label: (
                    <Space size={15}>
                      <HiOutlineLightBulb size={18} style={{ margin: -4 }} />
                      Responses
                    </Space>
                  ),
                  children: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No responses found"
                      style={{
                        height: "100%",
                      }}
                    />
                  ),
                },
              ]}
            />
          )}
        </>
      ) : (
        <FullPageLoader
          component="client-responses-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default ClientResponses;
