import React, { useState } from "react";
import { Table } from "antd";
import useCannedResponsesTableColumn from "./CannedResponsesTableColumn";
import { useGlobalContext } from "../../../contexts/globalContext";

const CannedResponsesTable = ({
  type,
  setShowCannedResponses,
  setSelectedMessage,
  selectedMessage,
}) => {
  const [state] = useGlobalContext();
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);

  const { cannedResponsesDateColumn, cannedResponsesActionColumn } =
    useCannedResponsesTableColumn({
      type,
      setShowCannedResponses,
      setSelectedMessage,
      hoveredRowIndex,
      selectedMessage,
    });

  const searchText =
    state.filters.searchText["canned-responses-search-text-box"] &&
    state.filters.searchText["canned-responses-search-text-box"];

  let filteredData = state.cannedResponses?.liveAgentCannedResponses?.filter(
    (cannedResponse) =>
      cannedResponse.folderName ===
      state.cannedResponses?.currentCannedResponseFolder
  );

  let filteredDataById = filteredData.filter(
    (cannedResponseById) =>
      cannedResponseById.availableFor === "GLOBAL" ||
      cannedResponseById.userID === state.currentUser.user.userID
  );

  if (searchText) {
    filteredDataById = filteredDataById.filter((cannedResponse) => {
      return (
        cannedResponse.responseTitle
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        cannedResponse.responseMessage
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <>
      <Table
        dataSource={filteredDataById}
        columns={
          type === "cannedResponse"
            ? cannedResponsesDateColumn.filter((column) => {
                return (
                  column.key !== "language" && column.key !== "availableFor"
                );
              })
            : cannedResponsesDateColumn.concat(cannedResponsesActionColumn)
        }
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        showSorterTooltip={false}
        style={{ cursor: "pointer" }}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            if (selectedMessage) {
              let concatedMessages = selectedMessage.concat(
                record.responseMessage
              );
              setSelectedMessage(concatedMessages);
            } else {
              setSelectedMessage(record.responseMessage);
            }
            setShowCannedResponses(false);
          },
          onMouseEnter: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "#f5f5f5";
              }
            );
            setHoveredRowIndex(rowIndex); // update state when mouse enters row
          },
          onMouseLeave: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "";
              }
            );
            setHoveredRowIndex(-1); // update state when mouse leaves row
          },
        })}
      />
    </>
  );
};

export default CannedResponsesTable;
