import { useGlobalContext } from "../../../contexts/globalContext";
import { Button, Space, Input, Divider, Row, Col } from "antd";
import { PlusOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";

const ConversationFlowHeader = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Row>
      <Col span={18}>
        <Space>
          <Input
            disabled={state.conversationFlow.flows.length === 0}
            prefix={<SearchOutlined />}
            placeholder="Search by name"
            value={
              state.filters.searchText["conversation-flow-search-text-box"] &&
              state.filters.searchText["conversation-flow-search-text-box"]
            }
            style={{ width: 400 }}
            onChange={(e) => {
              const currValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "conversation-flow-search-text-box",
                  searchText: currValue,
                },
              });
            }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
          />

          <Divider type="vertical" />
        </Space>
      </Col>

      <Col span={6} style={{ textAlign: "right" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatchReducer({
              type: "OPEN_FORM_PANEL",
              payload: {
                component: "conversation-flow-form-panel",
                title: "Add New ",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "conversation-flow-form",
                mode: "add",
                title: "Add New ",
              },
            });
          }}
        >
          Add New
        </Button>
      </Col>
    </Row>
  );
};

export default ConversationFlowHeader;
