import { Row, Col, Space, Typography } from "antd";
import { TinyArea } from "@ant-design/plots";
import { useGlobalContext } from "../../contexts/globalContext";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { mode } from "../../constants/env";

const { Text, Title } = Typography;

const ConversationByTodayCount = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({
        type: "RD_SET_CONVERSATION_BY_TODAY_COUNT",
        payload: {
          conversationByToday: undefined,
        },
      });
      dispatchMiddleware({
        type: "MWD_LOAD_CONVERSATION_BY_TODAY_COUNT",
        payload: { component: "conversation-by-today-count" },
      });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  let conversationByToday = state.dashBoard.conversationByToday;

  // For demo account only showing dummy data
  if (
    mode === "STAGING" &&
    state.currentAccount.account.database === "inextlabs-demo"
  ) {
    conversationByToday = [
      5, 0, 20, 0, 0, 0, 10, 0, 0, 0, 60, 0, 3, 0, 10, 0, 1, 10, 5, 0, 0, 3, 0,
      0, 0, 5, 1, 0, 3, 8,
    ];
  }

  const config = {
    data: conversationByToday,
    height: 50,
    autoFit: true,
    smooth: true,
    line: {
      color: "#0050b3",
    },
    areaStyle: {
      fill: "#bae7ff",
    },
  };

  return (
    <>
      {conversationByToday ? (
        <Row>
          <Col span={24}>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ textAlign: "center" }}>
                TODAY'S CONVERSATION
              </Title>
              <Title level={1} style={{ textAlign: "center" }}>
                {conversationByToday.reduce(
                  (previousResponseCount, currentResponseCount) =>
                    previousResponseCount + currentResponseCount,
                  0
                )}
              </Title>
            </Space>
          </Col>
          <Col span={24}>
            <TinyArea {...config} />
          </Col>
          <Col span={24}>
            <Text type="secondary" italic>
              last 24 hours
            </Text>
          </Col>
        </Row>
      ) : (
        <ComponentLoader
          component="conversation-by-today-count"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ConversationByTodayCount;
