import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  useEffect(() => {
    setWindowSize([window.innerHeight, window.innerWidth]);
  }, []);

  return windowSize;
};
