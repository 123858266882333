import { Layout, Space, Image, Row, Col, Typography } from "antd";
import Bots from "./Bots";
import NotificationCenter from "./NotificationCenter";
import FeedbackFormPanel from "./FeedbackFormPanel";
import HelpCenter from "./HelpCenter";
import AccountSettings from "./AccountSettings";
import AccountAvatar from "./AccountAvatar";
import LanguageSelector from "./LanguageSelector";
import CurrentUserInfo from "../../pages/current-user/CurrentUserInfo";
import { useGlobalContext } from "../../contexts/globalContext";
import { mode } from "../../constants/env";

const { Header } = Layout;
const { Text } = Typography;

const AppHeader = () => {
  const [state] = useGlobalContext();

  return (
    <>
      {Object.keys(state.currentUser.user).length > 0 && (
        <Header style={{ background: "#F0F2F5", padding: 0 }}>
          <Row>
            <Col span={9}>
              <Space className="menu-logo">
                <Image src="/inextlabs-logo.png" preview={false} />
              </Space>
            </Col>
            <Col span={3}>{mode !== "PROD" && <Text strong>{mode}</Text>}</Col>
            <Col span={5}>
              <Bots />
            </Col>
            <Col span={1} style={{ textAlign: "center" }}>
              <NotificationCenter />
            </Col>
            <Col span={1} style={{ textAlign: "center" }}>
              <FeedbackFormPanel />
            </Col>
            <Col span={1} style={{ textAlign: "center" }}>
              <HelpCenter />
            </Col>

            <Col span={1} style={{ textAlign: "center" }}>
              <LanguageSelector />
            </Col>

            <Col span={1} style={{ textAlign: "center" }}>
              <AccountSettings />
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              <AccountAvatar />
            </Col>
          </Row>

          <CurrentUserInfo />
        </Header>
      )}
    </>
  );
};

export default AppHeader;
