import { useGlobalContext } from "../../contexts/globalContext";
import { Drawer, Tag, Typography, Row, Tooltip } from "antd";
import BroadcastManagerForm from "./BroadcastManagerForm";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { wabaProvider } from "../../constants/enums";
import { ImUser } from "react-icons/im";

const { Text } = Typography;

const BroadcastManagerFormPanel = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const broadcastDefaultBot =
    state.currentAccount.account.bots.find(
      (bot) => bot.bot_id === state.broadcastSettings?.broadcastDefaultBot
      // If no default bot is set, then select the first bot
    ) ||
    state.currentAccount.account.bots.find((bot) =>
      bot.bot_channels.includes("WHATSAPP")
    ) ||
    {};

  const wabaProviderName =
    wabaProvider[broadcastDefaultBot.bot_channel_info?.WHATSAPP?.waba_provider]
      ?.providerReference.providerName;

  const wabaApikey =
    broadcastDefaultBot?.bot_channel_info?.WHATSAPP?.waba_apikey;

  const wabaIntegratedOnboarding =
    broadcastDefaultBot?.bot_channel_info?.WHATSAPP?.waba_integrated_onboarding;

  useConnectMiddleware(() => {
    wabaProviderName !== "INEXTLABS-CLOUD" &&
      wabaIntegratedOnboarding &&
      dispatchMiddleware({
        type: "MWD_LOAD_MESSAGE_TEMPLATE",
        payload: {
          wabaApikey,
          botId: broadcastDefaultBot.bot_id,
          wabaProvider: wabaProviderName,
          component: "message-templates-table",
        },
      });

    !state.broadcastManager.broadcastPriceList &&
      dispatchMiddleware({
        type: "LOAD_BROADCAST_PRICE_LIST",
        payload: {
          component: "broadcast-pricing-calculator-form-panel",
        },
      });

    if (broadcastDefaultBot?.bot_channels.includes("WHATSAPP")) {
      // Reset the old whatsapp usage data
      dispatchReducer({
        type: "RD_CLEAR_WHATSAPP_USAGE_DATA",
      });

      // Load current whatsapp usage data
      wabaProviderName !== "INEXTLABS-CLOUD" &&
        wabaIntegratedOnboarding &&
        dispatchMiddleware({
          type: "MWD_LOAD_WHATSAPP_USAGE_DATA",
          payload: {
            botId: broadcastDefaultBot.bot_id,
            clientId:
              broadcastDefaultBot?.bot_channel_info?.WHATSAPP?.waba_client_id,
            channelId:
              broadcastDefaultBot?.bot_channel_info?.WHATSAPP
                ?.waba_channel_id?.[0],
            currentMonth: new Date().getMonth(),
            component: "bot-settings-whatsapp-button",
          },
        });
    }
  });

  return (
    <Drawer
      title={
        <Row align="middle">
          <Text>{state.formPanel["broadcast-form-panel"]?.title}</Text>

          {state.broadcastManager.singleContactToPopulate?.displayName && (
            <Tooltip title="Display Name">
              <Tag
                color="#f4f7f9"
                style={{
                  borderRadius: "6px",
                  border: "1px solid #898a89",
                  padding: "4px 13px",
                  position: "fixed",
                  right: 32,
                }}
              >
                <Row align="middle">
                  <ImUser
                    style={{
                      color: "#F05742",
                      fontSize: 15,
                    }}
                  />
                  <Text
                    strong
                    style={{
                      fontSize: "13px",
                      marginLeft: "7px",
                    }}
                  >
                    {state.broadcastManager.singleContactToPopulate.displayName}
                  </Text>
                </Row>
              </Tag>
            </Tooltip>
          )}
        </Row>
      }
      width={900}
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "broadcast-form-panel",
          },
        });
        // Clear populated contacts if multipleContactsToPopulate or singleContactToPopulate exists
        (state.broadcastManager.multipleContactsToPopulate ||
          state.broadcastManager.singleContactToPopulate) &&
          dispatchReducer({
            type: "CLEAR_POPULATED_CONTACTS",
          });

        // Clear selected row keys for client response table if multipleContactsToPopulate exists
        state.broadcastManager.multipleContactsToPopulate &&
          dispatchReducer({
            type: "RD_CLEAR_SELECTED_ROW_KEYS",
            payload: {
              component: "client-response-table-selected-row",
            },
          });

        // confirm({
        //   title: "Are you sure you want to close the form?",
        //   content:
        //     "When clicked the OK button, the unsaved form inputs will be lost",
        //   async onOk() {
        //     dispatchReducer({ type: "CLOSE_BROADCAST_MANAGER_FORM_PANEL" });
        //   },
        //   onCancel() {},
        // });
      }}
      destroyOnClose={true}
      open={
        state.formPanel["broadcast-form-panel"] &&
        state.formPanel["broadcast-form-panel"].visible
      }
      bodyStyle={{ backgroundColor: "#F4F7F9" }}
      maskClosable={false}
    >
      <BroadcastManagerForm />
    </Drawer>
  );
};

export default BroadcastManagerFormPanel;
