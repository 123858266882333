import { Col, Row, Space, Image, Typography, Button } from "antd";

const { Text } = Typography;

const ConversationINSTAGRAM = ({ transcript }) => {
  return (
    <Space size={10} style={{ alignItems: "start" }}>
      {transcript.content && (
        <>
          {transcript.content.text && (
            <Row>
              <Col span={24}>
                <Space
                  size={10}
                  direction="vertical"
                  style={{
                    width: 300,
                    border: "1px solid #C0C0C0",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <Text>{transcript.content.text}</Text>
                  {transcript.content.quick_replies &&
                    transcript.content.quick_replies.map((button) => {
                      return (
                        <Button style={{ borderRadius: 5 }}>
                          {button.title}
                        </Button>
                      );
                    })}
                </Space>
              </Col>
            </Row>
          )}

          {transcript.content.attachment &&
            transcript.content.attachment.payload.elements?.map((element) => {
              return (
                <Row>
                  <Col span={24}>
                    <Space
                      size={10}
                      direction="vertical"
                      style={{
                        width: 300,
                        border: "1px solid #C0C0C0",
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <Image preview={false} src={element.image_url} />
                      <Text strong>{element.title}</Text>
                      <Text type="secondary">{element.subtitle}</Text>
                      {element.buttons &&
                        element.buttons.map((button) => {
                          return (
                            <Button style={{ borderRadius: 5 }}>
                              {button.title}
                            </Button>
                          );
                        })}
                    </Space>
                  </Col>
                </Row>
              );
            })}
        </>
      )}
    </Space>
  );
};

export default ConversationINSTAGRAM;
