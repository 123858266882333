import { useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

export const useConnectApi = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  const callApi = async ({ urls, options, onSuccess, onError }) => {
    setPending(true);
    if (account && inProgress === "none") {
      return instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: account,
        })
        .then((response) => {
          callApiWithToken(
            response.accessToken,
            urls,
            options.method,
            options.body,
            options.header,
            options.contentType,
            options.isTokenRequired
          )
            .then((response) => {
              onSuccess(response);
              setData(response);
              setError(null);
              setPending(false);
            })
            .catch((error) => {
              onError(error);
              setData(null);
              setError(error);
              setPending(false);
            });
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              instance
                .acquireTokenRedirect({
                  scopes: loginRequest.scopes,
                })
                .then((response) => {
                  callApiWithToken(
                    response.accessToken,
                    urls,
                    options.method,
                    options.body,
                    options.header,
                    options.contentType,
                    options.isTokenRequired
                  )
                    .then((response) => {
                      onSuccess(response);
                      setData(response);
                      setError(null);
                      setPending(false);
                    })
                    .catch((error) => {
                      onError(error);
                      setData(null);
                      setError(error);
                      setPending(false);
                    });
                })
                .catch((error) => {
                  onError(error);
                  setData(null);
                  setError(error);
                  setPending(false);
                });
            }
          }
        });
    }
  };

  const callApiWithToken = async (
    accessToken,
    urls,
    method,
    body,
    header,
    contentType,
    isTokenRequired = true
  ) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    let bodyContent = "";
    //Header sent by the middleware will be first appended to Headers.
    if (header) {
      Object.keys(header).forEach((key, index) => {
        headers.append(key, header[key]);
      });
    }

    //If there is any specific contentType sent by the middleware, the Content-Type would be that. If not, Content-Type would be application/json.
    if (contentType && contentType === "auto") {
      bodyContent = body;
    } else if (contentType && contentType !== "auto") {
      bodyContent = body;
      headers.append("Content-Type", contentType);
    } else {
      headers.append("Content-Type", "application/json");
      bodyContent = JSON.stringify(body);
    }

    //Additional general Headers are finally added.
    isTokenRequired && headers.append("Authorization", bearer);

    headers.append("Access-Control-Allow-Origin", true);

    const options = {
      method,
      headers,
      body: bodyContent,
    };

    return Promise.all(
      urls.map((url) => {
        return fetch(url, options);
      })
    )
      .then((responses) => {
        return Promise.all(
          responses.map(async (response) => {
            if (!response.ok) {
              const errorResponse = await response.json();
              throw errorResponse.error;
            }

            if (
              response.headers.get("content-type") ===
                "application/json; charset=utf-8" ||
              response.headers.get("content-type") ===
                "text/plain; charset=utf-8" ||
              response.headers.get("content-type") === "application/json"
            ) {
              return await response.json();
            } else if (
              response.headers.get("content-type") === "application/ms-excel"
            ) {
              return await response.blob();
            }
          })
        );
      })
      .then((data) => {
        return data;
      });
  };

  return { callApi, data, error, pending };
};
