import React from "react";
import { Row, Col, Button, Space, Image, Typography, Divider } from "antd";
import { Translation } from "../translation/translation";
import { URIs } from "../constants/env";

const { Title, Paragraph } = Typography;

const CustomButton = ({ module, buttonText }) => (
  <Space>
    <Button
      type="primary"
      onClick={async () => {
        window.open(
          "mailto:support@inextlabs.com?subject=" +
            module +
            " Activation Request&body=Hi,\n\nI would like to activate " +
            module +
            " knowledge feature to my bot"
        );
      }}
    >
      {buttonText?.activateNow || "Activate Now"}
    </Button>

    <Button
      onClick={async () => {
        window.open(
          module === "WhatsApp flows"
            ? "https://youtu.be/doHGaeGqJA4?si=2bZ8kjC8pv7_EaJf"
            : URIs.HELP_AND_SUPPORT_DOCUMENTATION
        );
      }}
    >
      {buttonText?.knowMore || "Know More"}
    </Button>
  </Space>
);

const ModuleNotSubscribed = ({ module }) => {
  // Get translated text
  const botKnowledgebaseModuleNotSubscribed =
    Translation().botKnowledgeArea.botKnowledgebase.moduleNotSubscribed;

  switch (module) {
    case "conversations":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={300}
              preview={false}
              src="/images/module-not-found/conversations.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Conversations
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  Conversations module provides an unified inbox showing all
                  interactions that has occured between the bot and the user,
                  across channels and across bots.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      Get a complete history of conversations with your
                      customers
                    </li>
                    <li>
                      Get detailed messages taken place in the conversations
                    </li>
                    <li>
                      Start a manual conversation with the customer using the
                      Live Agent mode
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="conversations" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "dashboard":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/dashboard.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Dashboard
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  For every iNextLabs user admin the dashboard is the home
                  screen. The iNextLabs admin dashboard is of uber-importance to
                  monitor, understand and maintain the progress of your business
                  communications.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      The dashboard provides you with a broad overview of the
                      activity performed by the bot and the responses you have
                      received from your customers across channels.
                    </li>

                    <li>Latest News and updates from iNextLabs</li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="dashboard" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "client-responses":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/responses.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Responses
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  Responses are all the important conversations or information
                  that is shared by the users during the conversation. For
                  instance, you might be interested more in conversations that
                  involved appointment and payments, and less interested in
                  conversation on general inquiries.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      Responses are grouped by categories which are represented
                      by a vertical tab.
                    </li>
                    <li>
                      iNextLabs allows businesses to choose what responses
                      should be captured.
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="client-responses" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "analytics":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/analytics.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Analytics
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  The Analytics module displays key metrics of your bots, at
                  real-time so you can track the efficiency and performance of
                  your bots and interactions with the users. Understanding the
                  metrics is key to keep improving your bots.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      Show number of conversations by Channel, Date, etc.,
                    </li>
                    <li>
                      Total responses received by channel, action item, date and
                      time
                    </li>
                    <li>
                      No of conversations that included humans, by channel, date
                      and time
                    </li>
                    <li>Know what questions your users asked the bot</li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="analytics" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "qna":
      return (
        <Row gutter={8} justify="center" style={{ marginTop: "40px" }}>
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/qna.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Question and Answers
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  The power of AI to answer Frequently Asked Questions (FAQ)
                  from your customers. You can enhance the knowledge of your bot
                  to pick question and answer from your own content, such as
                  FAQs or product manuals.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      You can build your Question answering module by importing
                      structured and semi-structured content.
                    </li>
                    <li>
                      You can add alternate forms of the question that people
                      may ask.
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="qna" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "bot-knowledgebase":
      return (
        <Row gutter={8} justify="center" style={{ marginTop: "40px" }}>
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/bot-knowledge.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  {botKnowledgebaseModuleNotSubscribed.title}
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  {botKnowledgebaseModuleNotSubscribed.description}
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      {botKnowledgebaseModuleNotSubscribed.benefit1.description}
                    </li>
                    <li>
                      {botKnowledgebaseModuleNotSubscribed.benefit2.description}
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton
                    module="bot-knowledgebase"
                    buttonText={{
                      activateNow:
                        botKnowledgebaseModuleNotSubscribed.buttons.activateNow,
                      knowMore:
                        botKnowledgebaseModuleNotSubscribed.buttons.knowMore,
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "broadcast":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/broadcast.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Broadcast
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  The Broadcast module of iNextLabs portal allows businesses to
                  send notifications on channels.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      a platform from Meta (Facebook) that allows medium and
                      large businesses to communicate with their customers at
                      scale using APIs
                    </li>
                    <li>
                      A platform that upgrades SMS with branding, rich media,
                      interactivity and analytics
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="broadcast" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "activities":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/broadcast.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Bot Activity
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  Bot activity messages can be used to set user expectations and
                  provide transparency about what the bot is doing.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      This can help to improve the user experience and reduce
                      frustration.
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="activities" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "api":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/api.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  API
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  Our standard modules such as Broadcast, Contacts, Responses,
                  etc., are exposed through our APIs. Currently, we support REST
                  API.You can integrate these modules with your existing systems
                  by using the APIs.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      An API key is a unique identifier used for authentication
                      of a user to an API. API keys are often used for tracking
                      and controlling how the APIs are used.
                    </li>
                    <li>
                      Our APIs are protected with a Subscription key. A valid
                      subscription key in HTTP requests must be provided when
                      calling the APIs. Without a key, the API call will be
                      rejected.
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="api" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "content-moderation":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              width={350}
              preview={false}
              src="/images/module-not-found/api.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  Content Moderation
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  content moderation is essential for maintaining a safe and
                  positive online environment.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      Content moderation helps protect users from exposure to
                      harmful, offensive, or inappropriate content.
                    </li>
                    <li>Remove errors caused by typos and spelling mistakes</li>
                    <li>
                      This maintains the trust of users and prevents
                      reputational damage.
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="content-moderation" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "whatsapp-flows":
      return (
        <Row
          gutter={8}
          justify="center"
          style={{
            marginTop: "40px",
          }}
        >
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/whatsapp-flows.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3} style={{ margin: 0 }}>
                  WhatsApp Flows
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  The WhatsApp Flows module allows you to create and manage
                  flows for your WhatsApp bot.
                </Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      A platform from Meta (WhatsApp) that allows medium and
                      large businesses to collect information from their
                      customers at scale using WhatsApp Flows
                    </li>
                    <li>
                      Businesses can build their own flows to collect
                      information from their customers
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <CustomButton module="WhatsApp flows" />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    default:
  }
};

export default ModuleNotSubscribed;
