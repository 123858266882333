import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";
import { apiEndPoints } from "../constants/enums";
import { useConnectApi } from "../connect/connectApi";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";

export const useCannedResponsesMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////********** LOAD CANNED RESPONSES **********////
      ////*******************************************////

      //Load canned responses data
      case "MWD_LOAD_CANNED_RESPONSES_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [apiEndPoints.CANNED_RESPONSES],
          options: {
            method: "GET",
          },
          onSuccess: ([cannedResponse]) => {
            dispatchReducer({
              type: "RD_SET_CANNED_RESPONSES",
              payload: {
                liveAgentCannedResponses: cannedResponse.data,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load canned responses data error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });

        break;

      //Load canned responses folder list
      case "MWD_LOAD_CANNED_RESPONSES_FOLDER_LIST":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.CANNED_RESPONSES_BY_FOLDER_NAME.concat(
              "?userID=",
              state.currentUser.user.userID
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([cannedResponsesFolderList]) => {
            dispatchReducer({
              type: "RD_SET_CANNED_RESPONSES_FOLDER_LIST",
              payload: {
                cannedResponsesFolderList: cannedResponsesFolderList,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load canned responses folder list error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });

        break;

      //Add / Edit canned responses
      case "MWD_ADD_EDIT_CANNED_RESPONSE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            ...(state.form["canned-responses-form"].mode === "add"
              ? [apiEndPoints.CANNED_RESPONSES]
              : [
                  apiEndPoints.CANNED_RESPONSES.concat(
                    "?_id=",
                    state.filters.actionRowKey["canned-responses-table"]
                  ),
                ]),
          ],
          options: {
            method:
              state.form["canned-responses-form"].mode === "add"
                ? "POST"
                : "PATCH",
            body: action.payload.cannedResponse,
          },
          onSuccess: ([cannedResponse]) => {
            if (state.form["canned-responses-form"].mode === "add") {
              dispatchReducer({
                type: "RD_ADD_CANNED_RESPONSES",
                payload: {
                  cannedResponse: cannedResponse,
                },
              });
              let folderList = state.cannedResponses?.cannedResponsesFolderList;

              let filteredFolder = folderList.find(
                (folder) => folder._id === cannedResponse.folderName
              );

              if (filteredFolder) {
                filteredFolder.count++;
              } else {
                folderList.push({
                  _id: cannedResponse.folderName,
                  count: 1,
                });
              }

              openNotificationWithIcon(
                "success",
                "Canned response added successfully"
              );
            } else if (state.form["canned-responses-form"].mode === "edit") {
              dispatchReducer({
                type: "RD_EDIT_CANNED_RESPONSES",
                payload: {
                  cannedResponse: cannedResponse,
                },
              });

              let cannedResponseList =
                state.cannedResponses.liveAgentCannedResponses.find(
                  (response) => response._id === cannedResponse._id
                ).folderName;

              let folderList =
                state.cannedResponses?.cannedResponsesFolderList.find(
                  (folder) => folder._id === cannedResponseList
                );

              let newFolder =
                state.cannedResponses?.cannedResponsesFolderList.find(
                  (folder) => folder._id === cannedResponse.folderName
                );

              if (folderList) {
                folderList.count--;
              }

              if (newFolder) {
                newFolder.count++;
              } else {
                // Create new folder object with count 1
                const newFolder = {
                  _id: cannedResponse.folderName,
                  count: 1,
                };
                state.cannedResponses?.cannedResponsesFolderList.push(
                  newFolder
                );
              }

              if (folderList && folderList.count === 0) {
                state.cannedResponses.cannedResponsesFolderList =
                  state.cannedResponses.cannedResponsesFolderList.filter(
                    (folder) => folder._id !== cannedResponseList
                  );
              }

              openNotificationWithIcon(
                "success",
                "Canned response modified successfully"
              );
            }
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "canned-responses-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "canned-responses-form",
                mode: "",
                title: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "canned-responses-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Create Canned Response",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete canned responses
      case "MWD_DELETE_CANNED_RESPONSE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.CANNED_RESPONSES.concat(
              "?_ids=",
              action.payload.actionRowKey
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_DELETE_CANNED_RESPONSES",
              payload: {
                actionRowKey: action.payload.actionRowKey,
              },
            });

            let folderList = state.cannedResponses?.cannedResponsesFolderList;
            let responsesArray = state.cannedResponses.liveAgentCannedResponses;

            const responseToDelete = responsesArray.find(
              (response) => response._id === action.payload.actionRowKey
            );

            if (responseToDelete) {
              // Find the corresponding folder and decrement the count
              const folderToUpdate = folderList.find(
                (folder) => folder._id === responseToDelete.folderName
              );

              if (folderToUpdate) {
                folderToUpdate.count--;

                // If the count is zero, remove the folder from folderList
                if (folderToUpdate.count === 0) {
                  folderList = folderList.filter(
                    (folder) => folder._id !== folderToUpdate._id
                  );
                }
              }
            }
            state.cannedResponses.cannedResponsesFolderList = folderList;

            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "canned-responses-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Canned response deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Canned Response Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
    }
  };
};
