import { useGlobalContext } from "../../../contexts/globalContext";
import { Input, Form, Row, Col, Button, Divider, Space } from "antd";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";

const { TextArea } = Input;

const AutomatedRepliesForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [form] = Form.useForm();

  if (state.form["automated-replies-form"].mode === "edit") {
    const recordToBeEdited =
      state.automatedReplies.broadcastAutomatedReplies.find(
        (automatedReply) =>
          automatedReply._id ===
          state.filters.actionRowKey["automated-replies-table"]
      );

    form.setFieldsValue({
      replyName: recordToBeEdited.replyName,
      replyMessageValue: recordToBeEdited.replyMessageValue,
    });
  }

  const handleFormSubmit = (e) => {
    const automatedRepliesForm = {
      replyName: e.replyName,
      replyMessageValue: e.replyMessageValue,
    };

    dispatchMiddleware({
      type: "MWD_ADD_EDIT_AUTOMATED_REPLY",
      payload: {
        automatedReplies: automatedRepliesForm,
        component: "automated-replies-form",
      },
    });
  };

  const resetFormField = () => {
    dispatchReducer({
      type: "CLOSE_FORM_PANEL",
      payload: {
        component: "automated-replies-form-panel",
      },
    });
    dispatchReducer({
      type: "SET_FORM_MODE",
      payload: {
        component: "automated-replies-form",
        mode: "",
        title: "",
      },
    });
    dispatchReducer({
      type: "RD_CLEAR_ACTION_ROW_KEY",
      payload: { component: "automated-replies-table" },
    });
  };

  return (
    <>
      {state.apiCallStatus["automated-replies-form"] &&
      state.apiCallStatus["automated-replies-form"].loading ? (
        <FullPageLoader
          component="automated-replies-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleFormSubmit}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="replyName"
                label="Reply name"
                rules={[
                  {
                    required: true,
                    message: "Please enter automated reply name",
                  },
                  { whitespace: true },
                  {
                    max: 80,
                    message:
                      "Automated reply name should not be more then 80 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the automated reply name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="replyMessageValue"
                label="Reply message"
                rules={[
                  {
                    required: true,
                    message: "Please enter automated replay message",
                  },
                  { whitespace: true },

                  {
                    max: 4096,
                    message:
                      "Automated reply message should not be more then 4096 characters",
                  },
                ]}
                hasFeedback
              >
                <TextArea
                  style={{
                    resize: "none",
                  }}
                  showCount
                  maxLength={4096}
                  placeholder="Enter the automated reply message"
                  rows="5"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={12}>
              <Space>
                <Form.Item>
                  <Button onClick={resetFormField}>Cancel</Button>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default AutomatedRepliesForm;
