import { Layout, Menu, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../contexts/globalContext";
import { botProfilePictures } from "../../constants/enums";
import { channels } from "../../constants/enums";
import { mode } from "../../constants/env";
const { Sider } = Layout;

const BotSettingsMenu = () => {
  const [state] = useGlobalContext();

  const theme = "light";
  const navigate = useNavigate();

  const botSettingsMenuItems = [
    {
      key: "/bot-settings/general",
      icon: (
        <Image
          src={
            botProfilePictures[
              state.currentAccount.account.bots.find(
                (bot) => bot.bot_id === state.application.botInAction
              ).bot_icon
            ]
          }
          preview={false}
          width={30}
          style={{ marginLeft: -12, marginTop: -5 }}
        />
      ),
      label: "General",
    },
    {
      key: "/bot-settings/whatsapp",
      icon: (
        <Image
          src={channels["WHATSAPP"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["WHATSAPP"].displayName,
    },
    {
      key: "/bot-settings/gbm",
      icon: (
        <Image
          src={channels["GBM"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["GBM"].displayName,
    },
    {
      key: "/bot-settings/amb",
      icon: (
        <Image
          src={channels["AMB"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["AMB"].displayName,
    },
    {
      key: "/bot-settings/instagram",
      icon: (
        <Image
          src={channels["INSTAGRAM"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["INSTAGRAM"].displayName,
    },
    {
      key: "/bot-settings/facebook",
      icon: (
        <Image
          src={channels["FACEBOOK"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["FACEBOOK"].displayName,
    },
    {
      key: "/bot-settings/directline",
      icon: (
        <Image
          src={channels["WEB"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["WEB"].displayName,
    },
    {
      key: "/bot-settings/telegram",
      icon: (
        <Image
          src={channels["TELEGRAM"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["TELEGRAM"].displayName,
    },
    {
      key: "/bot-settings/teams",
      icon: (
        <Image
          src={channels["MSTEAMS"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["MSTEAMS"].displayName,
    },
    {
      key: "/bot-settings/line",
      icon: (
        <Image
          src={channels["LINE"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["LINE"].displayName,
    },
    {
      key: "/bot-settings/alexa",
      icon: (
        <Image
          src={channels["ALEXA"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["ALEXA"].displayName,
    },
    {
      key: "/bot-settings/slack",
      icon: (
        <Image
          src={channels["SLACK"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["SLACK"].displayName,
    },
    (mode === "STAGING" || mode === "DEV") && {
      key: "/bot-settings/gbp",
      icon: (
        <Image
          src={channels["GBM"].logo}
          preview={false}
          width={25}
          style={{ marginLeft: -10, marginTop: -5 }}
        />
      ),
      label: channels["GBP"].displayName,
    },
  ];

  return (
    <Sider collapsed={false} width="256" theme={theme}>
      <Menu
        items={botSettingsMenuItems}
        mode="inline"
        theme={theme}
        style={{ padding: "15px 0 0 0" }}
        onClick={({ key }) => {
          navigate(key);
        }}
        defaultSelectedKeys={window.location.pathname}
      />
    </Sider>
  );
};

export default BotSettingsMenu;
