import { Row, Col, Space, Typography } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { mode } from "../../constants/env";

const { Title } = Typography;

const WaitingForLiveAgentCount = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({
        type: "RD_SET_WAITING_FOR_LIVE_AGENT_COUNT",
        payload: {
          liveAgentWaitingCount: undefined,
        },
      });
      dispatchMiddleware({
        type: "MWD_LOAD_WAITING_FOR_LIVE_AGENT_COUNT",
        payload: { component: "waiting-for-live-agent-count" },
      });
    },
    {
      botInAction: state.application.botInAction,
    }
  );
  let WaitingForLiveAgentCount = state.dashBoard?.waitingForLiveAgent;

  // For demo account only showing dummy data
  if (
    mode === "STAGING" &&
    state.currentAccount.account.database === "inextlabs-demo"
  ) {
    WaitingForLiveAgentCount = [5, 7, 9];
  }

  const totalCount = WaitingForLiveAgentCount?.reduce(
    (previousResponseCount, currentResponseCount) =>
      previousResponseCount + currentResponseCount,
    0
  );

  return (
    <>
      {WaitingForLiveAgentCount ? (
        <Row>
          <Col span={24}>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ textAlign: "center" }}>
                WAITING FOR LIVE AGENT
              </Title>
              <Title level={1} style={{ textAlign: "center" }}>
                {totalCount}
              </Title>
            </Space>
          </Col>
          <Col span={24}></Col>
        </Row>
      ) : (
        <ComponentLoader
          component="waiting-for-live-agent-count"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default WaitingForLiveAgentCount;
