import { Pie } from "@ant-design/plots";
import { Typography, Row, Col, Tooltip, Empty } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";

const { Text } = Typography;

const UnrecognizedIntentsByStatusChart = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchMiddleware({
        type: "LOAD_ANALYTICS_UNRECOGNIZED_INTENTS_STATUS",
        payload: {
          component: "analytics-unrecognized-intents-by-status-chart",
          analyticsFilterParams: {
            dateFilter: state.analytics.filterByDateForCharts,
            channelFilter: state.analytics.filterByChannelForCharts,
          },
        },
      });
    },
    {
      date: state.analytics.filterByDateForCharts,
      channel: state.analytics.filterByChannelForCharts,
      botInAction: state.application.botInAction,
    }
  );

  const color = [
    "#FF69B4",
    "#BA55D3",
    "#CD5C5C",
    "#FFA500",
    "#40E0D0",
    "#1E90FF",
    "#FF6347",
    "#7B68EE",
    "#00FA9A",
    "#0000CD",
    "#FF00FF",
    "#800000",
  ];

  const config = {
    color: color,
    appendPadding: 10,
    data: state.analytics.knowledgeAreaCharts.unrecognizedIntentsByStatus,
    angleField: "count",
    colorField: "_id",
    innerRadius: 0.5,
    label: {
      type: "inner",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      {state.analytics.knowledgeAreaCharts.unrecognizedIntentsByStatus ? (
        <>
          <Row>
            <Col span="23">
              <Text strong>Unanswered Questions By Status</Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This is a statistical view of the status of the unanswered questions."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.knowledgeAreaCharts.unrecognizedIntentsByStatus
            .length ? (
            <Pie {...config} style={{ height: "200px" }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-unrecognized-intents-by-status-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default UnrecognizedIntentsByStatusChart;
