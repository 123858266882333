import {
  Form,
  Select,
  Radio,
  Input,
  Row,
  Col,
  Button,
  Divider,
  Typography,
  AutoComplete,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  httpFormats,
  httpMethods,
  authenticationTypes,
  headerTypes,
  webhookTypes,
  platformWebhooks,
} from "../../../constants/enums";
import {
  RocketOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { URIs } from "../../../constants/env";
import { MdInfoOutline } from "react-icons/md";
import { TiInfoLarge } from "react-icons/ti";

const { Option } = Select;
const { Text, Link } = Typography;

const WebhooksForm = ({
  onFinish,
  webhooksData,
  selectedWebhookType,
  setSelectedWebhookType,
  autoEmailTrigger,
  setAutoEmailTrigger,
  external,
  setExternal,
  createContactFromResponse,
  whatsappNotification,
  setWhatsappNotification,
  setCreateContactFromResponse,
}) => {
  const [form] = Form.useForm();
  const [state] = useGlobalContext();

  const options = headerTypes;

  const [headerOptions, setHeaderOptions] = useState(options);
  const [showBearerToken, setShowBearerToken] = useState(false);
  const [showBasicAuthentication, setShowBasicAuthentication] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const [selectedValue, setSelectedValue] = useState("NONE");
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    if (webhooksData?.whatsappNotification) {
      form.setFieldsValue({
        authType: "NONE",
        webhookType: "whatsappNotification",
      });
    } else if (webhooksData?.autoEmailTrigger) {
      form.setFieldsValue({
        authType: "NONE",
        webhookType: "autoEmailTrigger",
      });
    } else if (webhooksData?.createContactFromResponse) {
      form.setFieldsValue({
        authType: "NONE",
        webhookType: "createContactFromResponse",
      });
    } else {
      form.setFieldsValue({ authType: "NONE", webhookType: "external" });
    }

    if (state.form["webhooks-form"].mode === "edit") {
      const recordToBeEdited = state.webhooks.webhooksList.find(
        (webhook) =>
          webhook._id === state.filters.actionRowKey["webhooks-table"]
      );

      const headersFields = Object.keys(recordToBeEdited?.headers || {}).map(
        (key, index) => {
          return {
            key: index,
            name: [index],
            headerType: key,
            headerValue: recordToBeEdited?.headers[key],
          };
        }
      );

      form.setFieldsValue({
        webhookName: recordToBeEdited.webhookName,
        serviceProvider: recordToBeEdited.serviceProvider,
        endPoint: recordToBeEdited.endPoint,
        method: recordToBeEdited.method,
        format: recordToBeEdited.format,
        authType: recordToBeEdited?.whatsAppNotification
          ? "NONE"
          : recordToBeEdited.authType,
        bearerToken: recordToBeEdited.bearerToken,
        tokenAPIKey: recordToBeEdited.tokenAPIKey,
        tokenAPIKeyName: recordToBeEdited.tokenAPIKeyName,
        authUserName: recordToBeEdited.authUserName,
        authUserPassword: recordToBeEdited.authUserPassword,
        headers: headersFields,
        recipients: recordToBeEdited.recipients,
        template: recordToBeEdited.postData?.template?.name,
      });
      if (recordToBeEdited.createContactFromResponse === true) {
        form.setFieldsValue({ webhookType: "createContactFromResponse" });
      } else if (recordToBeEdited.autoEmailTrigger === true) {
        form.setFieldsValue({ webhookType: "autoEmailTrigger" });
      } else if (recordToBeEdited?.whatsappNotification === true) {
        form.setFieldsValue({ webhookType: "whatsappNotification" });
      } else {
        form.setFieldsValue({ webhookType: "external" });
        if (recordToBeEdited?.integrationPlatform === "WOOCOMMERCE") {
          setPlatform("WOOCOMMERCE");
          form.setFieldsValue({ integrationPlatform: "WOOCOMMERCE" });
        }
      }

      setSelectedValue(recordToBeEdited.authType);
      setSelectedWebhookType(form.getFieldValue("webhookType"));
    }
  }, []);

  useEffect(() => {
    // Logic for selectedValue
    switch (selectedValue) {
      case "NONE":
        setShowBasicAuthentication(false);
        setShowBearerToken(false);
        setShowApiKey(false);
        break;
      case "BEARER":
        setShowBasicAuthentication(false);
        setShowBearerToken(true);
        setShowApiKey(false);
        break;
      case "BASIC":
        setShowBasicAuthentication(true);
        setShowBearerToken(false);
        setShowApiKey(false);
        break;
      case "API-KEY":
        setShowBasicAuthentication(false);
        setShowBearerToken(false);
        setShowApiKey(true);
        break;
      default:
        setShowBasicAuthentication(false);
        setShowBearerToken(false);
        setShowApiKey(false);
        break;
    }

    // Logic for selectedWebhookType
    switch (selectedWebhookType) {
      case "external":
        setAutoEmailTrigger(false);
        setExternal(true);
        setCreateContactFromResponse(false);
        setWhatsappNotification(false);
        break;
      case "createContactFromResponse":
        setAutoEmailTrigger(false);
        setExternal(false);
        setCreateContactFromResponse(true);
        setWhatsappNotification(false);
        break;
      case "autoEmailTrigger":
        setAutoEmailTrigger(true);
        setExternal(false);
        setCreateContactFromResponse(false);
        setWhatsappNotification(false);
        break;
      case "whatsappNotification":
        setWhatsappNotification(true);
        setAutoEmailTrigger(false);
        setExternal(false);
        setCreateContactFromResponse(false);
        break;
      default:
        setAutoEmailTrigger(false);
        setExternal(false);
        setCreateContactFromResponse(false);
        setWhatsappNotification(false);
        break;
    }
  }, [selectedValue, selectedWebhookType]);

  const handleChangeAuthType = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSearch = (value) => {
    const filteredOptions = options.filter(
      (headerOption) =>
        headerOption.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setHeaderOptions(filteredOptions.length > 0 ? filteredOptions : []);
  };

  const validateHeaderField = (fields, field, key, errorMsg) => ({
    validator: (_, value) =>
      new Promise((resolve, reject) => {
        const duplicateExists = fields.some(
          (fieldItem) =>
            fieldItem.name !== field.name &&
            form.getFieldValue(["headers", fieldItem.name, key]) === value
        );
        if (duplicateExists) {
          reject(new Error(errorMsg));
        } else {
          resolve();
        }
      }),
  });

  const handleChangeWebhookType = (e) => {
    setSelectedWebhookType(e.target.value);
  };

  const handleChangeWebhookPlatform = (value) => {
    setPlatform(value);
    setSelectedValue("BASIC");
    form.setFieldsValue({ authType: "BASIC" });
    form.setFieldsValue({ integrationPlatform: value });
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={webhooksData}
      autoComplete="off"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Webhook Type"
            name="webhookType"
            rules={[
              {
                required: true,
                message: "Please select the webhook type",
              },
            ]}
          >
            <Radio.Group
              onChange={handleChangeWebhookType}
              defaultValue={selectedWebhookType}
              value={selectedWebhookType}
            >
              {webhookTypes.map((webhookType) => (
                <Tooltip
                  title={
                    webhookType.value === "createContactFromResponse" &&
                    state.webhooks.webhooksList.some(
                      (webhook) => webhook.createContactFromResponse === true
                    )
                      ? "Contact webhook already created"
                      : null
                  }
                >
                  <Radio
                    key={webhookType.value}
                    value={webhookType.value}
                    disabled={
                      webhookType.value === "createContactFromResponse" &&
                      state.webhooks.webhooksList.some(
                        (webhook) => webhook.createContactFromResponse === true
                      )
                    }
                  >
                    {webhookType.label}
                  </Radio>
                </Tooltip>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>

        {external && (
          <Col span={24}>
            <Form.Item
              label="Integration Platform"
              name="integrationPlatform"
              rules={[
                {
                  required: true,
                  message: "Please select the integration platform",
                },
              ]}
            >
              <Select
                value={platform}
                onChange={handleChangeWebhookPlatform}
                placeholder="Select the platform to integrate"
                style={{ border: "1px solid #d9d9d9" }}
              >
                {platformWebhooks.map((platformWebhook, index) => (
                  <Option key={index} value={platformWebhook.value}>
                    {platformWebhook.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {external && ["WOOCOMMERCE"]?.includes(platform) && (
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <Space style={{ display: "flex", alignItems: "center" }}>
              <MdInfoOutline
                color="#F05742"
                fontSize="18"
                style={{ display: "flex", alignItems: "center" }}
              />
              <Typography style={{ fontWeight: 500, color: "#F05742" }}>
                Integrate WooCommerce webhook!
              </Typography>
            </Space>
            <Typography style={{ fontSize: "12px", padding: "7px 0px" }}>
              Please provide the following information to complete the
              Woocommerce integration. You will need to setup and generate API
              key first on Woocommerce. You may refer to the following guide for
              more information
              <br />
              <Link
                href={`${URIs.WOOCOMMERCE_REST_DOCUMENTATION}/woocommerce-rest-api-docs/#authentication`}
                target="_blank"
              >
                WooCommerce REST API docs
              </Link>
              .
            </Typography>
          </Col>
        )}

        {(createContactFromResponse || external) &&
          ["OTHERS", null]?.includes(platform) && (
            <Col span={24}>
              <Form.Item
                label="Webhook Name"
                name="webhookName"
                rules={[
                  {
                    required: true,
                    message: "Please enter webhook name",
                  },
                  { whitespace: true },
                  {
                    max: 80,
                    message: "Webhook name should not exceed 80 characters",
                  },
                ]}
              >
                <Input placeholder="e.g., webhook" />
              </Form.Item>
            </Col>
          )}

        {external && ["WOOCOMMERCE", "OTHERS", null]?.includes(platform) && (
          <>
            <Col span={24}>
              <Form.Item
                label={
                  platform === "WOOCOMMERCE" ? (
                    <>
                      Endpoint{" "}
                      <Tooltip title="The Request URL will be the endpoint you are posting to, e.g. http://www.example.com/wp-json/wc/v3/orders.">
                        <TiInfoLarge color="#F05742" />
                      </Tooltip>
                    </>
                  ) : (
                    "Endpoint"
                  )
                }
                name="endPoint"
                rules={[
                  {
                    required: true,
                    message: "Please enter the endpoint URL",
                  },
                  { whitespace: true },
                  {
                    type: "url",
                    message: "Please enter a valid URL.",
                  },
                ]}
              >
                <Input placeholder="e.g., https://" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Method"
                name="method"
                rules={[
                  {
                    required: true,
                    message: "Please select the HTTP request method",
                  },
                ]}
              >
                <Select
                  style={{ border: "1px solid #d9d9d9" }}
                  placeholder="Select method"
                >
                  {httpMethods.map((method, index) => {
                    return (
                      <Option key={index} value={method.value}>
                        {method.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            {!["WOOCOMMERCE"].includes(platform) && (
              <Col span={24}>
                <Form.Item
                  label="Format"
                  name="format"
                  rules={[
                    {
                      required: true,
                      message: "Please select the format",
                    },
                  ]}
                >
                  <Select placeholder="Select format">
                    {httpFormats.map((format) => {
                      return (
                        <Option value={format.value}>{format.label}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {!["WOOCOMMERCE"].includes(platform) && (
              <Col span={24}>
                <Form.Item name="headers" label="Headers">
                  <Form.List name="headers">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row gutter={8} key={field.key}>
                            <Col span={12}>
                              <Form.Item
                                {...field}
                                name={[field.name, "headerType"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter the header type",
                                  },
                                  { whitespace: true },
                                  {
                                    max: 4096,
                                    message:
                                      "Header type should not exceed 4096 characters",
                                  },
                                  validateHeaderField(
                                    fields,
                                    field,
                                    "headerType",
                                    "Header type must be unique"
                                  ),
                                ]}
                              >
                                <AutoComplete
                                  onSearch={handleSearch}
                                  placeholder="Header Type"
                                >
                                  {headerOptions.map((headerOption, index) => {
                                    return (
                                      <AutoComplete.Option
                                        key={index}
                                        value={headerOption.label}
                                      >
                                        {headerOption.label}
                                      </AutoComplete.Option>
                                    );
                                  })}
                                </AutoComplete>
                              </Form.Item>
                            </Col>

                            <Col span={11}>
                              <Form.Item
                                {...field}
                                name={[field.name, "headerValue"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter the header value",
                                  },
                                  { whitespace: true },
                                  {
                                    max: 4096,
                                    message:
                                      "Header value should not exceed 4096 characters",
                                  },
                                  validateHeaderField(
                                    fields,
                                    field,
                                    "headerValue",
                                    "Header value must be unique"
                                  ),
                                ]}
                              >
                                <Input placeholder="Header Value" />
                              </Form.Item>
                            </Col>

                            <Col span={1}>
                              <MinusCircleOutlined
                                onClick={() => remove(index)}
                                style={{
                                  marginTop: "10px",
                                  color: "#F05742",
                                  fontSize: "16px",
                                }}
                              />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Header
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                label="Token Type"
                name="authType"
                rules={[
                  {
                    required: true,
                    message: "Please select the authentication type",
                  },
                ]}
              >
                <Radio.Group
                  onChange={handleChangeAuthType}
                  defaultValue={selectedValue}
                  value={selectedValue}
                >
                  {authenticationTypes.map((authType) => {
                    return (
                      <Radio
                        disabled={
                          platform === "WOOCOMMERCE" &&
                          authType.value !== "BASIC"
                        }
                        value={authType.value}
                      >
                        {authType.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              {showBearerToken && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Token"
                      name="bearerToken"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the bearer token",
                        },
                      ]}
                    >
                      <Input placeholder="Enter bearer token" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {showBasicAuthentication && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        platform === "WOOCOMMERCE" ? (
                          <>
                            Consumer Key{" "}
                            <Tooltip title="Consumer key is required">
                              <TiInfoLarge color="#F05742" />
                            </Tooltip>
                          </>
                        ) : (
                          "User Name"
                        )
                      }
                      name="authUserName"
                      rules={[
                        {
                          required: true,
                          message:
                            platform === "WOOCOMMERCE"
                              ? "Please enter the consumer key"
                              : "Please enter the user name",
                        },
                      ]}
                    >
                      <Input
                        placeholder={
                          platform === "WOOCOMMERCE"
                            ? "Enter the consumer key"
                            : "Enter user name"
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={
                        platform === "WOOCOMMERCE" ? (
                          <>
                            Consumer Secret{" "}
                            <Tooltip title="Consumer secret is required">
                              <TiInfoLarge color="#F05742" />
                            </Tooltip>
                          </>
                        ) : (
                          "Password"
                        )
                      }
                      name="authUserPassword"
                      rules={[
                        {
                          required: true,
                          message:
                            platform === "WOOCOMMERCE"
                              ? "Please enter the consumer secret"
                              : "Please enter the password",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={
                          platform === "WOOCOMMERCE"
                            ? "Enter the consumer secret"
                            : "Enter user password"
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {showApiKey && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Key"
                      name="tokenAPIKeyName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the API key name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter API key name" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Value"
                      name="tokenAPIKey"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the API key",
                        },
                      ]}
                    >
                      <Input placeholder="Enter API key" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </>
        )}

        {autoEmailTrigger && (
          <Col span={24} style={{ margin: "30px 30px 30px 30px" }}>
            <Space>
              By selecting "Email Notification", you will receive email alerts
              whenever a new response is received. Please proceed to the next
              page to register your email ID along with the subject and content.
            </Space>
          </Col>
        )}
        {whatsappNotification && (
          <Col span={24} style={{ padding: "30px 20px 30px 20px" }}>
            <Space>
              By selecting "WhatsApp notifications", you will receive alerts on
              WhatsApp for events such as order placements, feedback received,
              or appointment bookings. Please proceed to the next page to select
              recipients and choose a template.
            </Space>
          </Col>
        )}
        {external && !["WOOCOMMERCE", "OTHERS", null]?.includes(platform) && (
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <Space style={{ display: "flex", alignItems: "center" }}>
              <MdInfoOutline
                color="#F05742"
                fontSize="18"
                style={{ display: "flex", alignItems: "center" }}
              />
              <Typography style={{ fontWeight: 500, color: "#F05742" }}>
                Do you want to enable the {platform?.toLowerCase()} service?
              </Typography>
            </Space>
            <Typography style={{ fontSize: "12px", padding: "7px 0px" }}>
              Reach out to our iNextLabs support team to request the activation
              of the {platform?.toLowerCase()} service{" "}
              <Link href={`mailto:support@inextlabs.com`}>send request</Link>
            </Typography>
          </Col>
        )}

        <Col
          span={24}
          justify="center"
          align="middle"
          style={{ marginTop: 50 }}
        >
          <Text> Need help getting started?</Text>
          <RocketOutlined style={{ marginLeft: 5, color: "#fc846f" }} />
          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <Link
                href={URIs.DOCUMENTATIONS + "/docs/Overview"}
                target="_blank"
              >
                View webhooks guide
                <QuestionCircleOutlined
                  style={{ color: "#fc846f", marginLeft: 5 }}
                />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24} style={{ position: "absolute", bottom: 0, right: 20 }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default WebhooksForm;
