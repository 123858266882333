import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Tooltip, Modal } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";

const { confirm } = Modal;

const useAutomatedRepliesTableColumn = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const automatedReplyDateColumn = [
    {
      title: "REPLY NAME",
      dataIndex: "replyName",
      key: "replyName",
      ellipsis: true,
      width: 400,
      sorter: (a, b) => {
        let valueA = a.replyName == null ? "" : a.replyName;
        let valueB = b.replyName == null ? "" : b.replyName;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: "REPLY MESSAGE",
      dataIndex: "replyMessageValue",
      key: "replyMessageValue",
      width: 700,
      sorter: (a, b) => {
        let valueA = a.replyMessageValue == null ? "" : a.replyMessageValue;
        let valueB = b.replyMessageValue == null ? "" : b.replyMessageValue;
        return valueA.localeCompare(valueB);
      },
    },
  ];

  const automatedReplyActionColumn = [
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Space size={24}>
              <Tooltip placement="top" title={"Edit Automated Reply"}>
                <EditOutlined
                  style={{ color: "#F05742" }}
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_ACTION_ROW_KEY",
                      payload: {
                        component: "automated-replies-table",
                        actionRowKey: record._id,
                      },
                    });
                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "automated-replies-form-panel",
                        title: "Edit Automated Reply",
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "automated-replies-form",
                        mode: "edit",
                      },
                    });
                  }}
                />
              </Tooltip>

              {state.apiCallStatus[
                "automated-reply-" + record._id + "-delete"
              ] &&
              state.apiCallStatus["automated-reply-" + record._id + "-delete"]
                .loading ? (
                <ComponentLoader
                  component={"automated-reply-" + record._id + "-delete"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip placement="topRight" title={"Delete Automated Reply"}>
                  <DeleteOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "automated-replies-table",
                          actionRowKey: record._id,
                        },
                      });
                      confirm({
                        title:
                          "Are you sure you want to delete the automated reply?",
                        content:
                          "When clicked the OK button, the automated reply will be deleted permanently",
                        onOk() {
                          dispatchMiddleware({
                            type: "MWD_DELETE_AUTOMATED_REPLY",
                            payload: {
                              component:
                                "automated-reply-" + record._id + "-delete",
                              actionRowKey: record._id,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return { automatedReplyDateColumn, automatedReplyActionColumn };
};

export default useAutomatedRepliesTableColumn;
