import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { appInsights } from "../AppInsights";
import { encryptKey } from "../utils/utility";
import { mode, URIs } from "../constants/env";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";

export const useMessageTemplatesMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    const wabaId = state.currentAccount.account.bots?.find(
      (bot) => bot.bot_id === state.application.botInAction
    )?.bot_channel_info.WHATSAPP?.waba_id;

    switch (action.type) {
      ////*******************************************////
      ////************** Message Template ***********////
      ////*******************************************////

      //Load Message Template Data
      case "MWD_LOAD_MESSAGE_TEMPLATE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [
            apiEndPoints.TEMPLATE.concat(
              "?wabaProvider=",
              action.payload.wabaProvider
            ),
          ],
          options: {
            method: "GET",
            header:
              action.payload.wabaProvider.toUpperCase() === "INEXTLABS-CLOUD"
                ? {
                    "waba-id": wabaId,
                  }
                : {
                    "waba-api-key": encryptKey({
                      wabaApikey: action.payload.wabaApikey,
                      clientId: state.currentAccount.account.client_id,
                      AES_KEY: URIs.AES_KEY,
                    }),
                  },
          },
          onSuccess: ([templates]) => {
            const latestTemplates = {
              botId: action.payload.botId,
              waba_templates: (templates.waba_templates || templates.data).map(
                (template) => ({
                  ...template,
                  botId: action.payload.botId,
                })
              ),
            };

            dispatchReducer({
              type: "SET_MESSAGE_TEMPLATES",
              payload: {
                latestTemplates,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: JSON.stringify(error),
              properties: {
                title: "Load Message Templates Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      // Upload header media
      // For meta broadcast we need to upload header media
      case "UPLOAD_HEADER_MEDIA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        const media = new FormData();
        media.append("files", action.payload.file);

        await callApi({
          urls: [apiEndPoints.TEMPLATE.concat("/uploadMedia")],
          options: {
            method: "POST",
            contentType: "auto",
            body: media,
          },
          onSuccess: ([uploadedMedia]) => {
            dispatchReducer({
              type: "SET_HEADER_MEDIA_ID",
              payload: {
                uploadedMedia,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: JSON.stringify(error),
              properties: {
                title: "Upload Header Media Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      // Create template
      case "CREATE_TEMPLATE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.TEMPLATE.concat(
              "?wabaProvider=",
              action.payload.wabaProvider
            ),
          ],
          options: {
            method: "POST",
            header:
              action.payload.wabaProvider.toUpperCase() === "INEXTLABS-CLOUD"
                ? {
                    "waba-id": wabaId,
                  }
                : {
                    "waba-api-key": encryptKey({
                      wabaApikey: action.payload.wabaApikey,
                      clientId: state.currentAccount.account.client_id,
                      AES_KEY: URIs.AES_KEY,
                    }),
                  },
            body: action.payload.template,
          },
          onSuccess: ([createdTemplate]) => {
            dispatchReducer({
              type: "ADD_TEMPLATE",
              payload: {
                createdTemplate:
                  action.payload.wabaProvider.toUpperCase() ===
                  "INEXTLABS-CLOUD"
                    ? {
                        ...(action.payload.template || {}),
                        ...(createdTemplate || {}),
                      }
                    : createdTemplate,
                botId: state.application.botInAction,
              },
            });
            openNotificationWithIcon(
              "success",
              "Template submitted successfully"
            );

            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "message-template-form-panel",
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });

            dispatchReducer({
              type: "SET_HEADER_MEDIA_ID",
              payload: {
                uploadedMedia: null,
              },
            });

            appInsights.trackEvent({
              name: `Create Message Template`,
              properties: {
                environment: mode,
                clientName: state.currentAccount.account?.general?.company,
                clientId: state.currentAccount.account.client_id,
                templateType: action.payload.templateType,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: `Create Message Template Error (${action.payload.template?.name})`,
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete message template
      case "DELETE_MESSAGE_TEMPLATE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.TEMPLATE.concat(
              "?templateName=",
              action.payload.templateName
            ).concat("&wabaProvider=", action.payload.wabaProvider),
          ],
          options: {
            method: "DELETE",
            header:
              action.payload.wabaProvider.toUpperCase() === "INEXTLABS-CLOUD"
                ? {
                    "waba-id": wabaId,
                  }
                : {
                    "waba-api-key": encryptKey({
                      wabaApikey: action.payload.wabaApikey,
                      clientId: state.currentAccount.account.client_id,
                      AES_KEY: URIs.AES_KEY,
                    }),
                  },
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "message-templates-table" },
            });
            dispatchReducer({
              type: "DELETE_TEMPLATE",
              payload: {
                templateName: action.payload.templateName,
                botId: action.payload.botId,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Message template deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Message template Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
