import { Table } from "antd";
import useActivityLogsTableColumns from "./ActivityLogsTableColumns";
import { useWindowSize } from "../../../shared-components/PageReSize";

const ActivityLogsTable = () => {
  const { dataColumns, actionColumns } = useActivityLogsTableColumns();
  const [height] = useWindowSize();

  const activityLogs = [
    {
      _id: "6",
      activityDate: "24/08/2022 04:12:00 PM",
      channel: "WHATSAPP",
      activityType: "CONVERSATION",
      isPlanned: true,
      conversationFlow: "customer_feedback",
    },
    {
      _id: "5",
      activityDate: "24/08/2022 04:05:00 PM",
      channel: "WHATSAPP",
      activityType: "BROADCAST",
      isPlanned: false,
      recipientCount: 5,
      broadcastTemplate: "delay_in_delivery",
    },
    {
      _id: "4",
      activityDate: "24/08/2022 04:02:00 PM",
      channel: "WHATSAPP",
      activityType: "CONVERSATION",
      isPlanned: false,
      conversationFlow: "request_demo",
    },
    {
      _id: "3",
      activityDate: "24/08/2022 12:44:00 PM",
      channel: "WHATSAPP",
      activityType: "CONVERSATION",
      isPlanned: false,
      conversationFlow: "product_enquiry",
    },
    {
      _id: "2",
      activityDate: "24/08/2022 12:41:00 PM",
      channel: "WHATSAPP",
      activityType: "BROADCAST",
      isPlanned: true,
      recipientCount: 300,
      broadcastTemplate: "monthly_invoice",
    },
    {
      _id: "1",
      activityDate: "24/08/2022 11:25:00 AM",
      channel: "WHATSAPP",
      activityType: "CONVERSATION",
      isPlanned: false,
      conversationFlow: "payment_collection",
    },
  ];

  return (
    <>
      <Table
        columns={dataColumns.concat(actionColumns)}
        dataSource={activityLogs}
        rowKey="_id"
        showSorterTooltip={false}
        pagination={{
          pageSize: Math.round((height - 250) / 45),
          showTotal: () => "Total : " + activityLogs.length,
        }}
      />
    </>
  );
};

export default ActivityLogsTable;
