import {
  Row,
  Col,
  Space,
  Typography,
  Result,
  Image,
  Button,
  Layout,
} from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { channels } from "../../constants/enums";
import BotSettingsMenu from "./BotSettingsMenu";

const { Text } = Typography;

const BotTELEGRAM = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {botToBeEdited.bot_channel_info.TELEGRAM ? (
            <Row gutter={[0, 24]}>
              <Col span={24}>Telegram Details goes here</Col>
            </Row>
          ) : (
            <Result
              icon={
                <Image
                  src={channels["TELEGRAM"].logo}
                  preview={false}
                  width={75}
                />
              }
              title={channels["TELEGRAM"].displayName}
              extra={
                <Space direction="vertical" size={20}>
                  <Text type="secondary">
                    Telegram lets your customer connect with your business
                    faster. Increase your brand reputation today with Telegram.
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_SEND_REQUEST",
                        payload: {
                          channel: "TELEGRAM",
                        },
                      });
                    }}
                  >
                    Request to enable {channels["TELEGRAM"].displayName}
                  </Button>
                </Space>
              }
            />
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotTELEGRAM;
