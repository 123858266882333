import { Drawer } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import AutomatedRepliesForm from "./AutomatedRepliesForm";

const AutomatedRepliesFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Drawer
      width={550}
      destroyOnClose={true}
      title={
        state.formPanel["automated-replies-form-panel"] &&
        state.formPanel["automated-replies-form-panel"].title
      }
      open={
        state.formPanel["automated-replies-form-panel"] &&
        state.formPanel["automated-replies-form-panel"].visible
      }
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "automated-replies-form-panel",
          },
        });
        dispatchReducer({
          type: "SET_FORM_MODE",
          payload: {
            component: "automated-replies-form",
            mode: "",
            title: "",
          },
        });
        dispatchReducer({
          type: "RD_CLEAR_ACTION_ROW_KEY",
          payload: { component: "automated-replies-table" },
        });
      }}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <AutomatedRepliesForm />
    </Drawer>
  );
};

export default AutomatedRepliesFormPanel;
