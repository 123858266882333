const questionsAndAnswersReducer = (state, action) => {
  switch (action.type) {
    case "SET_QUESTIONS_AND_ANSWERS":
      return {
        ...state,
        questionsAndAnswers: {
          ...state.questionsandanswers,
          ...{ qna: action.qna },
        },
      };

    case "RD_ADD_QUESTIONS_AND_ANSWER":
      return {
        ...state,
        questionsAndAnswers: {
          ...state.questionsAndAnswers,
          ...{
            qna: [...state.questionsAndAnswers.qna, action.payload.qna],
          },
        },
      };

    case "RD_UPDATE_QUESTIONS_AND_ANSWER":
      return {
        ...state,
        questionsAndAnswers: {
          ...state.questionsAndAnswers,
          ...{
            qna: state.questionsAndAnswers.qna.map((qna) =>
              qna.id === action.payload.id
                ? Object.assign({}, qna, action.payload.qna)
                : qna
            ),
          },
        },
      };

    case "RD_DELETE_QUESTIONS_AND_ANSWER":
      return {
        ...state,
        questionsAndAnswers: {
          ...state.questionsAndAnswers,
          ...{
            qna: state.questionsAndAnswers.qna.filter(
              (qna) => action.payload.id !== qna.id
            ),
          },
        },
      };

    case "RD_CLEAR_QUESTIONS_AND_ANSWER":
      return {
        ...state,
        questionsAndAnswers: {
          ...state.questionsAndAnswers,
          ...{
            qna: undefined,
          },
        },
      };
    default:
      return state;
  }
};

export default questionsAndAnswersReducer;
