import moment from "moment";

const conversationsReducer = (state, action) => {
  switch (action.type) {
    case "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS":
      if (action.payload.contactsWithTranscripts.data.length > 0) {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            contactsWithTranscripts: state.conversations.contactsWithTranscripts
              ? [
                  ...state.conversations.contactsWithTranscripts,
                  ...action.payload.contactsWithTranscripts.data,
                ].reduce((accumulator, currentContactWithTranscript) => {
                  const existingContactIndex = accumulator.findIndex(
                    (existingContactWithTranscript) =>
                      existingContactWithTranscript._id ===
                      currentContactWithTranscript._id
                  );

                  if (existingContactIndex > -1) {
                    // Merge messages for existing contact
                    accumulator[existingContactIndex].messages = [
                      ...accumulator[existingContactIndex].messages,
                      ...currentContactWithTranscript.messages,
                    ];

                    // Update other properties of the existing contact with the latest values except messages
                    const keys = Object.keys(currentContactWithTranscript);
                    keys.forEach((key) => {
                      if (key !== "messages") {
                        accumulator[existingContactIndex][key] =
                          currentContactWithTranscript[key];
                      }
                    });
                  } else {
                    // Add new contact to the accumulator
                    accumulator.push(currentContactWithTranscript);
                  }
                  return accumulator;
                }, [])
              : action.payload.contactsWithTranscripts.data,
            currentConversation:
              action.payload.contactsWithTranscripts.data.find(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id ===
                  state.conversations.currentConversation._id
              )
                ? {
                    ...state.conversations.currentConversation,
                    ...(action.payload.contactsWithTranscripts.data.find(
                      (latestContactWithTranscripts) =>
                        latestContactWithTranscripts._id ===
                        state.conversations.currentConversation._id
                    ) || {}),
                    messages: [
                      ...state.conversations.currentConversation.messages,
                      ...(action.payload.contactsWithTranscripts.data.find(
                        (contactWithTranscripts) =>
                          contactWithTranscripts._id ===
                          state.conversations.currentConversation._id
                      )?.messages || []),
                    ],
                  }
                : state.conversations.currentConversation,
            contactsCountInDatabase:
              action.payload.contactsWithTranscripts.totalRecords,
            lastUpdatedAt: moment().utc().format("yyyy-MM-DD[T]HH:mm:ss"),
          },
        };
      } else {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            lastUpdatedAt: moment().utc().format("yyyy-MM-DD[T]HH:mm:ss"),
          },
        };
      }

    case "RD_UPDATE_CONVERSATIONS_TRANSCRIPTS":
      if (action.payload.data.length) {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            ...{
              contactsWithTranscripts:
                state.conversations.contactsWithTranscripts?.map(
                  (contactWithTranscripts) =>
                    contactWithTranscripts._id === action.payload.contactId
                      ? {
                          ...contactWithTranscripts,
                          ...{
                            messages: [
                              ...action.payload.data,
                              ...contactWithTranscripts.messages,
                            ],
                            total_messages_count: action.payload.totalRecords,
                          },
                        }
                      : contactWithTranscripts
                ),
              currentConversation:
                state.conversations.currentConversation._id ===
                action.payload.contactId
                  ? {
                      ...state.conversations.currentConversation,
                      ...{
                        messages: [
                          ...action.payload.data,
                          ...state.conversations.currentConversation.messages,
                        ],
                      },
                    }
                  : state.conversations.currentConversation,
              newMessagePosition: "top",
            },
          },
        };
      }
      break;

    case "RD_SET_CURRENT_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          currentConversation: action.payload.currentConversation,
          newMessagePosition: undefined,
        },
      };

    case "RD_CONVERSATIONS_AGENT_JOIN":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          liveConversations: state.conversations.liveConversations.concat(
            //live conversations from the initial load API
            action.payload.liveConversations ?? [],
            //Contact id from the join conversation
            action.payload.contactId ? [action.payload.contactId] : []
          ),
        },
      };

    case "RD_CONVERSATIONS_AGENT_LEAVE":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            liveConversations: state.conversations.liveConversations.filter(
              (contactId) => action.payload.contactId !== contactId
            ),
          },
        },
      };

    case "RD_CONVERSATIONS_UNREAD":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            unreadConversations: [
              // Remove duplicates
              ...new Set([
                ...state.conversations.unreadConversations,
                action.payload.contactId,
              ]),
            ],
          },
        },
      };

    case "RD_CONVERSATIONS_READ":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            unreadConversations: state.conversations.unreadConversations.filter(
              (contactId) => action.payload.contactId !== contactId
            ),
          },
        },
      };

    case "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS":
      if (action.payload.data.length) {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            ...{
              contactsWithTranscripts:
                state.conversations.contactsWithTranscripts?.map(
                  (contactWithTranscripts) =>
                    contactWithTranscripts._id === action.payload.contactId
                      ? {
                          ...contactWithTranscripts,
                          ...{
                            messages: [
                              ...contactWithTranscripts.messages,
                              // Remove duplicates
                              ...action.payload.data.filter(
                                (newMessage) =>
                                  !contactWithTranscripts.messages.some(
                                    (existingMessage) =>
                                      existingMessage.date === newMessage.date
                                  )
                              ),
                            ],
                            total_messages_count:
                              contactWithTranscripts.total_messages_count +
                              action.payload.data.length,
                            ...(action.payload.data[0].is_from_user
                              ? {
                                  unreadMessagesCount:
                                    contactWithTranscripts.unreadMessagesCount +
                                    [
                                      ...action.payload.data.filter(
                                        (newMessage) =>
                                          !contactWithTranscripts.messages.some(
                                            (existingMessage) =>
                                              existingMessage.date ===
                                              newMessage.date
                                          )
                                      ),
                                    ].length,
                                }
                              : {}),

                            last_modified: moment().utc().format(),
                          },
                        }
                      : contactWithTranscripts
                ),
              currentConversation:
                state.conversations.currentConversation._id ===
                action.payload.contactId
                  ? {
                      ...state.conversations.currentConversation,
                      ...{
                        messages: [
                          ...state.conversations.currentConversation.messages,
                          // Remove duplicates
                          ...action.payload.data.filter(
                            (newMessage) =>
                              !state.conversations.currentConversation.messages.some(
                                (existingMessage) =>
                                  existingMessage.date === newMessage.date
                              )
                          ),
                        ],
                        ...(action.payload.data[0].is_from_user
                          ? {
                              unreadMessagesCount:
                                state.conversations.currentConversation
                                  .unreadMessagesCount +
                                [
                                  ...action.payload.data.filter(
                                    (newMessage) =>
                                      !state.conversations.currentConversation.messages.some(
                                        (existingMessage) =>
                                          existingMessage.date ===
                                          newMessage.date
                                      )
                                  ),
                                ].length,
                            }
                          : {}),
                      },
                    }
                  : state.conversations.currentConversation,
              lastUpdatedAt: moment().utc().format("yyyy-MM-DD[T]HH:mm:ss"),
              unreadMessagesCount: action.payload.data[0].is_from_user
                ? (state.conversations.unreadMessagesCount || 0) +
                  state.conversations.contactsWithTranscripts
                    ?.map((contactWithTranscripts) =>
                      contactWithTranscripts._id === action.payload.contactId
                        ? [
                            ...action.payload.data.filter(
                              (newMessage) =>
                                !contactWithTranscripts.messages.some(
                                  (existingMessage) =>
                                    existingMessage.date === newMessage.date
                                )
                            ),
                          ].length
                        : 0
                    )
                    .reduce(
                      (accumulator, newMessageCount) =>
                        accumulator + newMessageCount,
                      0
                    )
                : state.conversations.unreadMessagesCount,
              newMessagePosition: "bottom",
            },
          },
        };
      }
      break;

    case "RD_REMOVE_CONVERSATIONS_TRANSCRIPTS":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload._id
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          messages: contactWithTranscripts.messages.filter(
                            (message) =>
                              message.component !== action.payload.component
                          ),
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id === action.payload._id
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      messages:
                        state.conversations.currentConversation.messages.filter(
                          (message) =>
                            message.component !== action.payload.component
                        ),
                    },
                  }
                : state.conversations.currentConversation,
          },
        },
      };

    case "SET_WAITING_FOR_LIVEAGENT":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          waitingForLiveAgent: state.conversations.waitingForLiveAgent.concat(
            //waiting for live agent from the initial load API
            action.payload.waitingForLiveAgent ?? [],
            //Contact id from the web socket
            action.payload.contactId ? [action.payload.contactId] : []
          ),
        },
      };

    case "SET_CO_AGENT_LIVE_CONVERSATIONS":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          coAgentLiveConversations: action.payload.coAgentLiveConversations,
        },
      };

    case "UPDATE_WAITING_FOR_LIVEAGENT":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            waitingForLiveAgent: state.conversations.waitingForLiveAgent.filter(
              (contactId) => action.payload.contactId !== contactId
            ),
          },
        },
      };

    case "RD_SET_CONVERSATION_FILTER":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          conversationFilter: action.payload.conversationFilter,
        },
      };

    case "RD_SET_ON_HOLD_CONVERSATIONS":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            onHoldConversations: action.payload.onHoldConversations,
          },
        },
      };

    case "UPDATE_CONVERSATIONS_TRANSCRIPTS_DELIVERY_STATUS":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload.contactId
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          messages: contactWithTranscripts.messages?.map(
                            (message) =>
                              message.directlineMessageId ===
                              action.payload.directlineMessageId
                                ? {
                                    ...message,
                                    ...{
                                      deliveryStatus: [
                                        ...(message.deliveryStatus || []),
                                        action.payload.deliveryStatus,
                                      ],
                                    },
                                  }
                                : message
                          ),
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id ===
              action.payload.contactId
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      messages:
                        state.conversations.currentConversation.messages?.map(
                          (message) =>
                            message.directlineMessageId ===
                            action.payload.directlineMessageId
                              ? {
                                  ...message,
                                  ...{
                                    deliveryStatus: [
                                      ...(message.deliveryStatus || []),
                                      action.payload.deliveryStatus,
                                    ],
                                  },
                                }
                              : message
                        ),
                    },
                  }
                : state.conversations.currentConversation,
          },
        },
      };

    case "RD_CLEAR_CURRENT_CONVERSATION":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          currentConversation: {},
        },
      };

    case "RD_CLEAR_ON_HOLD_CONVERSATION":
      const updatedOnHoldConversations =
        state.conversations.onHoldConversations.filter(
          (contactId) => action.payload.contactId !== contactId
        );

      // Store the updated state in the local storage
      localStorage.setItem(
        "onHoldConversations",
        JSON.stringify(updatedOnHoldConversations)
      );

      return {
        ...state,
        conversations: {
          ...state.conversations,
          onHoldConversations: updatedOnHoldConversations,
        },
      };

    case "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS": {
      return {
        ...state,
        conversations: {
          ...state.conversations,
          contactsWithTranscripts: undefined,
        },
      };
    }

    // Notes related actions
    case "LOAD_LIVE_AGENT_NOTES":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload.contactId
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          agent_notes: {
                            ...contactWithTranscripts.agent_notes,
                            ...{
                              data:
                                action.payload.actionType !== "search"
                                  ? [
                                      ...contactWithTranscripts.agent_notes
                                        .data,
                                      ...action.payload.notes.data,
                                    ]
                                  : action.payload.notes.data,
                            },
                          },
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id ===
              action.payload.contactId
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      agent_notes: {
                        ...state.conversations.currentConversation.agent_notes,
                        ...{
                          data:
                            action.payload.actionType !== "search"
                              ? [
                                  ...state.conversations.currentConversation
                                    .agent_notes.data,
                                  ...action.payload.notes.data,
                                ]
                              : action.payload.notes.data,
                        },
                      },
                    },
                  }
                : state.conversations.currentConversation,
          },
        },
      };

    case "ADD_LIVE_AGENT_NOTE":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload.contactId
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          agent_notes: {
                            ...contactWithTranscripts.agent_notes,
                            ...{
                              data: [
                                action.payload.note,
                                ...(contactWithTranscripts.agent_notes.data ||
                                  []),
                              ],
                              totalRecords:
                                contactWithTranscripts.agent_notes
                                  .totalRecords + 1,
                            },
                          },
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id ===
              action.payload.contactId
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      agent_notes: {
                        ...state.conversations.currentConversation?.agent_notes,
                        ...{
                          data: [
                            action.payload.note,
                            ...(state.conversations.currentConversation
                              .agent_notes.data || []),
                          ],
                          totalRecords:
                            state.conversations.currentConversation.agent_notes
                              .totalRecords + 1,
                        },
                      },
                    },
                  }
                : state.conversations.currentConversation,
          },
        },
      };

    case "EDIT_LIVE_AGENT_NOTE":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload.contactId
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          agent_notes: {
                            ...contactWithTranscripts.agent_notes,
                            ...{
                              data: contactWithTranscripts.agent_notes.data?.map(
                                (note) =>
                                  note._id === action.payload.note._id
                                    ? Object.assign(
                                        {},
                                        note,
                                        action.payload.note
                                      )
                                    : note
                              ),
                            },
                          },
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id ===
              action.payload.contactId
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      agent_notes: {
                        ...state.conversations.currentConversation?.agent_notes,
                        ...{
                          data: state.conversations.currentConversation.agent_notes.data?.map(
                            (note) =>
                              note._id === action.payload.note._id
                                ? Object.assign({}, note, action.payload.note)
                                : note
                          ),
                        },
                      },
                    },
                  }
                : state.conversations.currentConversation,
          },
        },
      };

    case "DELETE_LIVE_AGENT_NOTE":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload.contactId
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          agent_notes: {
                            ...contactWithTranscripts.agent_notes,
                            ...{
                              data: contactWithTranscripts.agent_notes.data?.filter(
                                (note) => note._id !== action.payload.noteId
                              ),
                              totalRecords:
                                contactWithTranscripts.agent_notes
                                  .totalRecords - 1,
                            },
                          },
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id ===
              action.payload.contactId
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      agent_notes: {
                        ...state.conversations.currentConversation?.agent_notes,
                        ...{
                          data: state.conversations.currentConversation.agent_notes.data?.filter(
                            (note) => note._id !== action.payload.noteId
                          ),
                          totalRecords:
                            state.conversations.currentConversation.agent_notes
                              .totalRecords - 1,
                        },
                      },
                    },
                  }
                : state.conversations.currentConversation,
          },
        },
      };

    case "SET_AI_ASSISTANT_HELP_TEXT":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            ai: {
              ...state.conversations.ai,
              ...{
                aiAssistantHelpText: action.payload.aiAssistantHelpText,
              },
            },
          },
        },
      };

    case "CLEAR_AI_ASSISTANT_HELP_TEXT":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            ai: {
              ...state.conversations.ai,
              ...{
                aiAssistantHelpText: "",
              },
            },
          },
        },
      };

    case "SET_CONVERSATION_SUMMARY":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            ai: {
              ...state.conversations.ai,
              ...{
                conversationSummary: action.payload.conversationSummary,
              },
            },
          },
        },
      };

    case "CLEAR_CONVERSATION_SUMMARY":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            ai: {
              ...state.conversations.ai,
              ...{
                conversationSummary: undefined,
              },
            },
          },
        },
      };

    case "SET_LIVE_AGENT_SETTINGS_ACROSS_BOTS":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            liveAgentSettingsAcrossBots:
              action.payload.liveAgentSettingsAcrossBots,
          },
        },
      };

    case "UPDATE_LIVE_AGENT_SETTINGS_ACROSS_BOTS":
      const updatedLiveAgentResponse = action.payload.updatedLiveAgentResponse;
      const { botId } = updatedLiveAgentResponse;

      return {
        ...state,
        conversations: {
          ...state.conversations,
          liveAgentSettingsAcrossBots: {
            ...state.conversations.liveAgentSettingsAcrossBots,
            bots: state.conversations.liveAgentSettingsAcrossBots?.bots
              ?.map((liveAgentBasedOnBot) =>
                liveAgentBasedOnBot.botId === botId
                  ? { ...liveAgentBasedOnBot, ...updatedLiveAgentResponse }
                  : liveAgentBasedOnBot
              )
              .concat(
                state.conversations.liveAgentSettingsAcrossBots?.bots?.some(
                  (liveAgentBasedOnBot) => liveAgentBasedOnBot.botId === botId
                )
                  ? []
                  : [updatedLiveAgentResponse]
              ),
          },
        },
      };

    case "SET_CONVERSATION_USER_STATE":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            conversationUserState: action.payload.conversationUserState,
          },
        },
      };

    case "SET_UNREAD_MESSAGE_COUNT":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            unreadMessagesCount: action.payload.unreadMessagesCount.count,
          },
        },
      };

    case "CLEAR_UNREAD_MESSAGES_COUNT":
      return {
        ...state,
        conversations: {
          ...state.conversations,
          ...{
            contactsWithTranscripts:
              state.conversations.contactsWithTranscripts?.map(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload.contactId
                    ? {
                        ...contactWithTranscripts,
                        ...{
                          messages: contactWithTranscripts.messages.map(
                            (message) => ({
                              ...message,
                              isRead: true,
                            })
                          ),
                          unreadMessagesCount: 0,
                        },
                      }
                    : contactWithTranscripts
              ),
            currentConversation:
              state.conversations.currentConversation._id ===
              action.payload.contactId
                ? {
                    ...state.conversations.currentConversation,
                    ...{
                      messages:
                        state.conversations.currentConversation.messages?.map(
                          (message) => ({
                            ...message,
                            isRead: true,
                          })
                        ),
                      unreadMessagesCount: 0,
                    },
                  }
                : state.conversations.currentConversation,
            unreadMessagesCount:
              state.conversations.unreadMessagesCount -
              action.payload.unreadMessagesCount,
          },
        },
      };

    default:
      return state;
  }
};

export default conversationsReducer;
