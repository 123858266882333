import { useGlobalContext } from "../../../contexts/globalContext";
import { Space, Tag, Tooltip, Modal, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { whatsappFlowsCategories } from "../../../constants/enums";
import { Translation } from "../../../translation/translation";

const { confirm } = Modal;
const { Text } = Typography;

const WhatsAppFlowsTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const translation = Translation().botKnowledgeArea.whatsappFlows.table;

  const dataColumns = [
    {
      title: translation.name.title,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        let valueA = a.name == null ? "" : a.name;
        let valueB = b.name == null ? "" : b.name;
        return valueA.localeCompare(valueB);
      },
      render: (name) => {
        return <Space>{name ? name : "-"}</Space>;
      },
      ellipsis: true,
      fixed: "left",
      width: 140,
    },
    {
      title: translation.categories.title,
      dataIndex: "categories",
      key: "categories",
      sorter: (a, b) => {
        let valueA = a.categories == null ? "" : a.categories;
        let valueB = b.categories == null ? "" : b.categories;
        return valueA.localeCompare(valueB);
      },
      render: (categories) => {
        return (
          <Space>
            {categories
              ? categories.map((category, index) => {
                  return (
                    <Text key={index} style={{ display: "block" }}>
                      {whatsappFlowsCategories.find(
                        ({ key }) => key === category
                      ).value || category}
                      {index !== categories.length - 1 && ", "}
                    </Text>
                  );
                })
              : "-"}
          </Space>
        );
      },
      ellipsis: true,
      fixed: "left",
      width: 170,
    },
    {
      title: translation.status.title,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        let valueA = a.status == null ? "" : a.status;
        let valueB = b.status == null ? "" : b.status;
        return valueA.localeCompare(valueB);
      },
      render: (status, { id }) => {
        return status ? (
          <Tag
            color={status.toLowerCase() === "published" ? "green" : "orange"}
            key={id}
          >
            {status[0] + status.slice(1).toLowerCase()}
          </Tag>
        ) : (
          "-"
        );
      },
      ellipsis: true,
      fixed: "left",
      width: 150,
    },
  ];

  const actionColumns = [
    {
      title: translation.action.title,
      dataIndex: "action",
      key: "action",
      width: 100,
      align: "center",
      onCell: () => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },

      render: (_, record) => {
        return (
          <Space size={24}>
            <Tooltip
              placement="topRight"
              title={
                !state.currentUser.permission.WHATSAPP_FLOWS_EDIT &&
                translation.noAccess
              }
            >
              {state.currentUser.permission.WHATSAPP_FLOWS_EDIT &&
              record.status.toLowerCase() !== "deprecated" ? (
                <EditOutlined
                  style={{ color: "#F05742" }}
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_ACTION_ROW_KEY",
                      payload: {
                        component: "whatsapp-flows-table",
                        actionRowKey: record.id,
                      },
                    });
                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "whatsapp-flows-form-panel",
                        title: translation.action.edit.title,
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "whatsapp-flows-form",
                        mode: "edit",
                      },
                    });
                    !record.assets &&
                      dispatchMiddleware({
                        type: "LOAD_WHATSAPP_FLOW_ASSETS",
                        payload: {
                          flowId: record.id,
                          component: "whatsapp-flows-assets",
                        },
                      });

                    dispatchReducer({
                      type: "SET_CURRENT_WHATSAPP_FLOW",
                      payload: {
                        currentWhatsAppFlow: record,
                      },
                    });
                  }}
                />
              ) : (
                <Tooltip
                  placement="topLeft"
                  title={
                    record.status.toLowerCase() === "deprecated" &&
                    "Deprecated flows cannot be edited"
                  }
                >
                  <EditOutlined style={{ color: "#C0C0C0" }} />
                </Tooltip>
              )}
            </Tooltip>

            {state.apiCallStatus["whatsapp-flow" + record.id + "-delete"] &&
            state.apiCallStatus["whatsapp-flow" + record.id + "-delete"]
              .loading ? (
              <ComponentLoader
                component={"whatsapp-flow" + record.id + "-delete"}
                loadingMessage=""
                errorMessage=""
              />
            ) : (
              <Tooltip
                placement="topRight"
                title={
                  !state.currentUser.permission.WHATSAPP_FLOWS_DELETE &&
                  translation.noAccess
                }
              >
                {state.currentUser.permission.WHATSAPP_FLOWS_DELETE &&
                record.status.toLowerCase() !== "published" &&
                record.status.toLowerCase() !== "deprecated" ? (
                  <DeleteOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      confirm({
                        title: translation.action.delete.title,
                        content: translation.action.delete.warningText,
                        onOk() {
                          dispatchMiddleware({
                            type: "DELETE_SINGLE_FLOW",
                            payload: {
                              component:
                                "whatsapp-flow" + record.id + "-delete",
                              flowId: record.id,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                ) : (
                  <Tooltip
                    placement="topLeft"
                    title={
                      (record.status.toLowerCase() === "published" &&
                        "Published flows cannot be deleted") ||
                      (record.status.toLowerCase() === "deprecated" &&
                        "Deprecated flows cannot be deleted")
                    }
                  >
                    <DeleteOutlined style={{ color: "#C0C0C0" }} />
                  </Tooltip>
                )}
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return {
    dataColumns,
    actionColumns,
  };
};

export default WhatsAppFlowsTableColumns;
