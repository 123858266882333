import { Row, Col, Space } from "antd";

const PlannedActivities = () => {
  return (
    <>
      <Row>
        <Col>
          <Space></Space>
        </Col>
      </Row>
    </>
  );
};

export default PlannedActivities;
