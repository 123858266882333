import { Resizable } from "react-resizable";
import { useGlobalContext } from "../contexts/globalContext";
import "./ResizeableTableColumn.less";

const ResizeableTableColumn = (props) => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const { onResize, width, module, ...restProps } = props;

  // <--- Info: --->
  // Include this component into the table
  // Change table columns to updatedColumns when resizing the column
  // Else this component will not work

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
            if (module === "contacts") {
              dispatchMiddleware({
                type: "UPDATE_CONTACT_SETTINGS",
                payload: {
                  contactSettings: state.application.enums?.contacts?.data.map(
                    (enumValue) => ({
                      ...enumValue,
                      module: "contacts",
                    })
                  ),
                  component: "contact-manager-table-column",
                  successMessage: "Table column width updated successfully",
                },
              });
            } else {
              dispatchMiddleware({
                type: "SET_TABLE_COLUMN_WIDTH",
                payload: {
                  enumValues: state.application.enums?.responses?.data?.map(
                    (enumValue) => ({
                      ...enumValue,
                      module: "responses",
                    })
                  ),
                  component: "response-table-column",
                },
              });
            }
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableTableColumn;
