import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Layout, Space, Row, Col, Button, Typography } from "antd";
import moment from "moment";
import ConversationBubble from "./ConversationBubble";
import ConversationEventNotification from "./ConversationEventNotification";
import InfiniteScroll from "react-infinite-scroll-component";
import { TabLoader } from "../../shared-components/LoadingIndicator";
import { FiArrowDownCircle } from "react-icons/fi";
import { useWindowSize } from "../../shared-components/PageReSize";

const { Text } = Typography;

const ConversationTranscripts = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const timelineRef = useRef(null);
  const [scrollTopBeforeNext, setScrollTopBeforeNext] = useState();
  const [isMessageFromUser, setIsNewMessageFromUser] = useState(false);
  const [height] = useWindowSize();

  useEffect(() => {
    if (state.conversations.currentConversation.messages) {
      switch (state.conversations.newMessagePosition) {
        case "top":
          timelineRef.current.scrollTo(0, scrollTopBeforeNext);
          break;

        case "bottom":
          if (
            state.conversations.currentConversation.messages[
              state.conversations.currentConversation.messages.length - 1
            ].is_agent
          ) {
            timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
          } else if (
            state.conversations.currentConversation.messages[
              state.conversations.currentConversation.messages.length - 1
            ].is_from_user
          ) {
            if (scrollTopBeforeNext < -50) {
              setIsNewMessageFromUser(true);
            } else {
              timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
              setIsNewMessageFromUser(false);
            }
          }
          break;

        default:
          timelineRef.current.scrollTo(0, scrollTopBeforeNext);
      }
    }
  }, [state.conversations.currentConversation.messages]);

  useEffect(() => {
    if (state.conversations.currentConversation.messages) {
      timelineRef.current.scrollTo(
        0,
        // If conversation triggered from response - show scroll position top, else show scroll position bottom
        (state.conversations.conversationFilter ? "-" : "") +
          timelineRef.current.scrollHeight
      );
    }
  }, [state.conversations.currentConversation._id]);

  useEffect(() => {
    if (scrollTopBeforeNext > -40) {
      setIsNewMessageFromUser(false);
    }
  }, [scrollTopBeforeNext]);

  let dateInView = moment("01/01/2001");
  let dateInViewChanged = true;

  return (
    <Row>
      <Col span={24}>
        <Layout.Content
          style={{
            backgroundImage: "url(/images/whatsapp-bg-1.png)",
          }}
        >
          {state.conversations.currentConversation.messages && (
            <>
              <div
                id={
                  "transcripts-" + state.conversations.currentConversation._id
                }
                style={{
                  overflow: "auto",
                  height: height - 235,
                  padding: "10px 50px",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                ref={timelineRef}
                onScroll={(e) => {
                  setScrollTopBeforeNext(e.target.scrollTop);
                }}
              >
                <InfiniteScroll
                  dataLength={
                    state.conversations.currentConversation.messages.length
                  }
                  next={() => {
                    dispatchMiddleware({
                      type: "MWD_UPDATE_CONVERSATIONS_TRANSCRIPTS",
                      payload: {
                        component:
                          "conversations-transcripts-" +
                          state.conversations.currentConversation._id +
                          "-grid",
                        contactId: state.conversations.currentConversation._id,
                        pageSize: 10,
                        pageNumber: Math.round(
                          state.conversations.currentConversation.messages
                            .length /
                            10 +
                            1
                        ),
                      },
                    });
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    overflowX: "hidden",
                  }}
                  inverse={true}
                  hasMore={
                    state.conversations.currentConversation.messages.length <
                    state.conversations.currentConversation.total_messages_count
                  }
                  loader={
                    <TabLoader
                      component={
                        "conversations-transcripts-" +
                        state.conversations.currentConversation._id +
                        "-grid"
                      }
                      loadingMessage="Loading transcripts..."
                      errorMessage="Error loading transcripts"
                    />
                  }
                  // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                  scrollableTarget={
                    "transcripts-" + state.conversations.currentConversation._id
                  }
                >
                  <Row
                    gutter={[0, 24]}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    {state.conversations.currentConversation.messages
                      // .sort((x, y) => new Date(x.date) - new Date(y.date))
                      .map((transcript) => {
                        const dateInViewFormatted =
                          moment(dateInView).format("MM/DD/yyyy");
                        const dateInLoopFormatted = moment(
                          transcript.date
                        ).format("MM/DD/yyyy");

                        if (dateInViewFormatted !== dateInLoopFormatted) {
                          dateInView = dateInLoopFormatted;
                          dateInViewChanged = true;
                        } else {
                          dateInViewChanged = false;
                        }

                        return (
                          <>
                            {dateInViewChanged && (
                              <Col
                                span={24}
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1000,
                                }}
                              >
                                <Space
                                  style={{
                                    width: "100%",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: "#FFFFFF",
                                      borderRadius: 8,
                                      padding: 8,
                                    }}
                                  >
                                    <Text strong>
                                      {moment(transcript.date).format(
                                        "DD/MM/yyyy"
                                      )}
                                    </Text>
                                  </span>
                                </Space>
                              </Col>
                            )}
                            <Col span={24}>
                              {!transcript.event ? (
                                <ConversationBubble
                                  transcript={transcript}
                                  channel={state.conversations.currentConversation.channel.toUpperCase()}
                                />
                              ) : (
                                transcript.event && (
                                  <ConversationEventNotification
                                    transcript={transcript}
                                  />
                                )
                              )}
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                </InfiniteScroll>
              </div>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "right",
                  position: "absolute",
                  bottom: 5,
                  right: 10,
                }}
              >
                {isMessageFromUser && (
                  <Button
                    icon={
                      <FiArrowDownCircle
                        size={18}
                        style={{ marginBottom: -4, marginRight: 5 }}
                      />
                    }
                    // color="#108ee9"
                    style={{
                      backgroundColor: "#596D79",
                      color: "#FFFFFF",
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      timelineRef.current.scrollTo(
                        0,
                        timelineRef.current.scrollHeight
                      );
                      setIsNewMessageFromUser(false);
                    }}
                  >
                    new messages
                  </Button>
                )}
              </Space>
            </>
          )}
        </Layout.Content>
      </Col>
    </Row>
  );
};

export default ConversationTranscripts;
