import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
// import { useWindowSize } from "../../../shared-components/PageReSize";
import BotKnowledgebaseTableColumns from "./BotKnowledgebaseTableColumns";

const BotKnowledgebaseTable = ({ botInAction }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  // const [height] = useWindowSize();
  const { botKnowledgebaseDataColumns, botKnowledgebaseActionColumns } =
    BotKnowledgebaseTableColumns();

  const searchText =
    state.filters.searchText["bot-knowledgebase-search-text-box"] &&
    state.filters.searchText["bot-knowledgebase-search-text-box"];

  let filteredBotKnowledgebase = state.botKnowledgebase.data;

  if (searchText) {
    filteredBotKnowledgebase = state.botKnowledgebase.data.filter(
      ({ kb_name }) => {
        return kb_name.toLowerCase().includes(searchText.toLowerCase());
      }
    );
  }

  return (
    <Table
      columns={botKnowledgebaseDataColumns.concat(
        botKnowledgebaseActionColumns
      )}
      dataSource={filteredBotKnowledgebase}
      loading={
        state.apiCallStatus["knowledgebase-table"] &&
        state.apiCallStatus["knowledgebase-table"].loading
      }
      onChange={(sorter, filters) => {
        dispatchReducer({
          type: "RD_SET_SORTED_INFO",
          payload: {
            component: "knowledgebase-table",
            sortedInfo: sorter,
          },
        });
        dispatchReducer({
          type: "RD_SET_FILTERED_INFO",
          payload: {
            component: "knowledgebase-table",
            filteredInfo: filters,
          },
        });
      }}
      rowKey="kb_id"
      style={{
        borderRight: state.infoPanel?.["knowledgebase-test-info-panel"]?.visible
          ? "1px solid #e8e8e8"
          : "none",
      }}
      pagination={filteredBotKnowledgebase?.length > 7}
      onRow={(selectedBotKnowledgebase) => {
        return {
          onClick: () => {
            dispatchMiddleware({
              type: "LOAD_SINGLE_BOT_KNOWLEDGEBASE_DETAILS",
              payload: {
                botInAction,
                actionRowKey: selectedBotKnowledgebase.kb_id,
                title: selectedBotKnowledgebase.kb_name,
                component: "bot-knowledgebase-info-panel",
              },
            });

            dispatchReducer({
              type: "OPEN_INFO_PANEL",
              payload: {
                component: "bot-knowledgebase-info-panel",
                title: selectedBotKnowledgebase.kb_name + " KB Details",
              },
            });

            dispatchReducer({
              type: "RD_SET_ACTION_ROW_KEY",
              payload: {
                component: "bot-knowledgebase-info-panel",
                actionRowKey: selectedBotKnowledgebase.kb_id,
              },
            });
          },
          onMouseEnter: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "#f5f5f5";
              }
            );
          },
          onMouseLeave: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "";
              }
            );
          },
          style: { cursor: "pointer" },
        };
      }}
    />
  );
};

export default BotKnowledgebaseTable;
