import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useCurrentUserMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////***********************************************////
      ////***************** CURRENT USER ****************////
      ////***********************************************////

      case "MWD_UPDATE_CURRENT_USER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ACCOUNT_USERS.concat(
              "?userID=",
              state.currentUser.user.userID,
              "&isFromProfileSetting=",
              true
            ),
          ],

          options: {
            method: "PUT",
            body: action.payload.user,
          },
          onSuccess: ([user]) => {
            dispatchReducer({ type: "SET_CURRENT_USER", user });
            openNotificationWithIcon(
              "success",
              "User profile updated successfully"
            );
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "user-profile-setting-form-panel",
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Current User Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });

        break;
    }
  };
};
