import { useGlobalContext } from "../../../contexts/globalContext";
import { Tag, Space, Modal, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";

const { confirm } = Modal;

const useAccountUsersTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const dataColumns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortOrder:
        state.filters.sortedInfo["account-users-table"] &&
        state.filters.sortedInfo["account-users-table"].columnKey ===
          "firstName"
          ? state.filters.sortedInfo["account-users-table"].order
          : null,
      ellipsis: true,
      fixed: "left",
      width: 100,
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortOrder:
        state.filters.sortedInfo["account-users-table"] &&
        state.filters.sortedInfo["account-users-table"].columnKey === "lastName"
          ? state.filters.sortedInfo["account-users-table"].order
          : null,
      ellipsis: true,
      fixed: "left",
      width: 100,
    },
    {
      title: "ROLES",
      dataIndex: "role",
      key: "role",
      render: (role) => (
        <Space size={0}>
          {role.map((userRoleId) => {
            const applicationRole = state.application.applicationRoles.find(
              (applicationRole) => applicationRole._id === userRoleId
            );
            return (
              <Tag color="blue" key={userRoleId}>
                {applicationRole.name}
              </Tag>
            );
          })}
        </Space>
      ),
      filters: state.application.applicationRoles.map((applicationRole) => {
        return {
          text: <span>{applicationRole.name}</span>,
          value: applicationRole._id,
        };
      }),
      filteredValue:
        (state.filters.filteredInfo["account-user-table"] &&
          state.filters.filteredInfo["account-user-table"].role) ||
        null,
      onFilter: (value, record) =>
        record.role.find((userRoleId) => userRoleId === value),
      width: 300,
    },
    {
      title: "DISPLAY NAME",
      dataIndex: "displayName",
      key: "displayName",
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      sortOrder:
        state.filters.sortedInfo["account-users-table"] &&
        state.filters.sortedInfo["account-users-table"].columnKey ===
          "displayName"
          ? state.filters.sortedInfo["account-users-table"].order
          : null,
      ellipsis: true,
      width: 150,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder:
        state.filters.sortedInfo["account-users-table"] &&
        state.filters.sortedInfo["account-users-table"].columnKey === "email"
          ? state.filters.sortedInfo["account-users-table"].order
          : null,
      ellipsis: true,
      width: 200,
    },
  ];

  const actionColumns = [
    {
      title: "ACTION",
      key: "action",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <Space size={24}>
            <Tooltip
              placement="topRight"
              title={
                state.currentUser.permission.ACCOUNT_USER_EDIT
                  ? "Edit User"
                  : "You do not have required permission"
              }
            >
              {state.currentUser.permission.ACCOUNT_USER_EDIT ? (
                <EditOutlined
                  style={{ color: "#F05742" }}
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_ACTION_ROW_KEY",
                      payload: {
                        component: "account-user-table",
                        actionRowKey: record.userID,
                      },
                    });

                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "account-user-form-panel",
                        title: "Edit User",
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "account-user-form",
                        mode: "edit",
                        title: "Edit User",
                      },
                    });
                  }}
                />
              ) : (
                <EditOutlined style={{ color: "#C0C0C0" }} />
              )}
            </Tooltip>

            {record.email !== state.currentUser.user.email ? (
              <>
                {state.apiCallStatus[
                  "account-user-" + record.userID + "-delete"
                ] &&
                state.apiCallStatus["account-user-" + record.userID + "-delete"]
                  .loading ? (
                  <ComponentLoader
                    component={"account-user-" + record.userID + "-delete"}
                    loadingMessage=""
                    errorMessage=""
                  />
                ) : (
                  <Tooltip
                    placement="topRight"
                    title={
                      state.currentUser.permission.ACCOUNT_USER_DELETE
                        ? "Delete User"
                        : "You do not have required permission"
                    }
                  >
                    {state.currentUser.permission.ACCOUNT_USER_DELETE ? (
                      <DeleteOutlined
                        style={{ color: "#F05742" }}
                        onClick={() => {
                          dispatchReducer({
                            type: "RD_SET_ACTION_ROW_KEY",
                            payload: {
                              component: "account-user-table",
                              actionRowKey: record.userID,
                            },
                          });
                          confirm({
                            title: "Are you sure you want to delete the user?",
                            content:
                              "When clicked the OK button, the user will be deleted permanently",
                            onOk() {
                              dispatchMiddleware({
                                type: "MWD_DELETE_SINGLE_ACCOUNT_USER",
                                payload: {
                                  component:
                                    "account-user-" + record.userID + "-delete",
                                  actionRowKey: record.userID,
                                },
                              });
                            },
                            onCancel() {},
                          });
                        }}
                      />
                    ) : (
                      <DeleteOutlined style={{ color: "#C0C0C0" }} />
                    )}
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip
                placement="topRight"
                title="You cannot delete current user"
              >
                <DeleteOutlined style={{ color: "#C0C0C0" }} />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return { dataColumns, actionColumns };
};

export default useAccountUsersTableColumns;
