import { Form, Row, Col, Input, Button, Space, Divider, Select } from "antd";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import { useGlobalContext } from "../../../contexts/globalContext";
import { unrecognizedIntentsStatus } from "../../../constants/enums";

const { Option } = Select;
const { TextArea } = Input;

const UnrecognizedIntentsForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  //Load users
  let users = state.accountUsers.users.map((user) => user._id);

  if (state.form["unrecognized-intents-form"].mode === "edit") {
    const recordToBeEdited =
      state.unrecognizedIntents?.data?.find(
        (message) =>
          message._id ===
          state.filters.actionRowKey["unrecognized-intents-table"]
      ) || {};

    form.setFieldsValue(recordToBeEdited);
  }

  const handleFormSubmit = (formUnrecognizedIntentProperties) => {
    dispatchMiddleware({
      type: "EDIT_UNRECOGNIZED_INTENT_PROPERTIES",
      payload: {
        formUnrecognizedIntentProperties,
        component: "unrecognized-intents-form",
      },
    });
  };

  const resetFormField = () => {
    form.resetFields();
    dispatchReducer({
      type: "CLOSE_FORM_PANEL",
      payload: {
        component: "unrecognized-intents-form-panel",
      },
    });
    dispatchReducer({
      type: "SET_FORM_MODE",
      payload: {
        component: "unrecognized-intents-form",
        mode: "",
      },
    });
  };

  return (
    <>
      {state.apiCallStatus["unrecognized-intents-form"] &&
      state.apiCallStatus["unrecognized-intents-form"].loading ? (
        <FullPageLoader
          component="unrecognized-intents-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="phrase" label="Phrase">
                <TextArea
                  disabled
                  autoSize={{ minRows: 1, maxRows: 10 }}
                  style={{ resize: "none" }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="assignedTo" label="Assigned To" hasFeedback>
                <Select showSearch placeholder="Select assined to">
                  {users.map((assignedTo, index) => {
                    return (
                      <Option key={index} value={assignedTo}>
                        {assignedTo}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="status" label="Status">
                <Select showSearch placeholder="Select status">
                  {unrecognizedIntentsStatus.map((status) => {
                    return <Option value={status.key}>{status.key}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="notes"
                label="Notes"
                rules={[
                  {
                    max: 1024,
                    message: "Notes should not be more then 1024 characters",
                  },
                  {
                    whitespace: true,
                  },
                ]}
                hasFeedback
              >
                <TextArea
                  placeholder="Enter notes"
                  showCount
                  maxLength={1024}
                  style={{ resize: "none" }}
                  autoSize={{ minRows: 4, maxRows: 10 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col>
              <Space>
                <Form.Item>
                  <Button onClick={resetFormField}>Cancel</Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default UnrecognizedIntentsForm;
