import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { useWindowSize } from "../../../shared-components/PageReSize";
import useAutomatedRepliesTableColumn from "./AutomatedRepliesTableColumn";

const AutomatedRepliesTable = () => {
  const [state] = useGlobalContext();

  const [height] = useWindowSize();
  const { automatedReplyDateColumn, automatedReplyActionColumn } =
    useAutomatedRepliesTableColumn();

  const searchText =
    state.filters.searchText["automated-reply-search-text-box"] &&
    state.filters.searchText["automated-reply-search-text-box"];

  let filteredAutomatedReply = state.automatedReplies.broadcastAutomatedReplies;

  if (searchText) {
    filteredAutomatedReply = filteredAutomatedReply.filter((automatedReply) => {
      return automatedReply.replyName
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }

  return (
    <>
      <Table
        columns={automatedReplyDateColumn.concat(automatedReplyActionColumn)}
        dataSource={filteredAutomatedReply}
        showSorterTooltip={false}
        pagination={{
          pageSize: Math.round((height - 250) / 45),
          showTotal: () => "Total : " + filteredAutomatedReply.length,
        }}
      />
    </>
  );
};

export default AutomatedRepliesTable;
