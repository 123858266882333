import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import useConversationFlowTableColumns from "./ConversationFlowTableColumn";
import { useWindowSize } from "../../../shared-components/PageReSize";

const ConversationFlowTable = () => {
  const [state] = useGlobalContext();

  const { conversationFlowDataColumns, conversationFlowActionColumns } =
    useConversationFlowTableColumns();
  const [height] = useWindowSize();

  const searchText =
    state.filters.searchText["conversation-flow-search-text-box"] &&
    state.filters.searchText["conversation-flow-search-text-box"];

  let filteredConversationFlow = state.conversationFlow.flows;

  if (searchText) {
    filteredConversationFlow = filteredConversationFlow.filter((flow) => {
      return flow.conversation_flow_name
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }

  return (
    <Table
      columns={conversationFlowDataColumns.concat(
        conversationFlowActionColumns
      )}
      rowKey="_id"
      showSorterTooltip={false}
      dataSource={filteredConversationFlow}
      pagination={{
        pageSize: Math.round((height - 250) / 45),
        showTotal: () => "Total : " + filteredConversationFlow.length,
      }}
    />
  );
};

export default ConversationFlowTable;
