const responseReducer = (state, action) => {
  switch (action.type) {
    case "SET_CLIENT_RESPONSES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ responses: action.responses },
        },
      };

    case "RD_CLEAR_RESPONSES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ responses: undefined },
        },
      };

    case "RD_SET_RESPONSE_CATEGORIES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ responseCategories: action.payload.sortedResponseCategories },
        },
      };

    case "RD_SET_CURRENT_RESPONSE_CATEGORY":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            currentResponseCategory: action.payload.currentResponseCategory,
          },
        },
      };

    case "SET_CURRENT_RESPONSE":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ currentResponse: action.payload.currentResponse },
        },
      };

    case "CLEAR_CURRENT_RESPONSE":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ currentResponse: undefined },
        },
      };

    case "RD_CLEAR_RESPONSE_CATEGORIES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ responseCategories: undefined },
        },
      };

    case "RD_CLEAR_CURRENT_RESPONSE_CATEGORY":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{ currentResponseCategory: undefined },
        },
      };

    case "DELETE_SINGLE_RESPONSE":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: state.clientResponses.responses.data.filter(
                  (response) => action.actionRowKey !== response._id
                ),
              },
            },
          },
        },
      };

    case "RD_DELETE_MULTIPLE_RESPONSES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: state.clientResponses.responses.data.filter(
                  (response) =>
                    !action.payload.selectedRowKeys.includes(response._id)
                ),
              },
            },
          },
        },
      };

    case "SET_CLIENT_RESPONSE_ASSIGNEE":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: action.payload.responses,
              },
            },
          },
        },
      };

    case "SET_CLIENT_RESPONSE_STATUS":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: action.payload.responses,
              },
            },
          },
        },
      };

    case "RD_SET_RESPONSE_CUSTOM_COLUMN_DROPDOWN_VALUES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responseCustomColumnDropdownValues: action.payload.optionValues,
          },
        },
      };

    case "RD_CLEAR_RESPONSE_CUSTOM_COLUMN_DROPDOWN_VALUES":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responseCustomColumnDropdownValues: undefined,
          },
        },
      };

    case "RD_SET_RESPONSE_CATEGORIES_COLLAPSED":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responseCategoriesCollapsed:
              action.payload.responseCategoriesCollapsed,
          },
        },
      };

    case "SET_RESPONSES_TIMELINE":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responsesTimeline: action.payload.responsesTimeline,
          },
        },
      };

    case "CLEAR_RESPONSES_TIMELINE":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responsesTimeline: undefined,
          },
        },
      };

    case "SET_LALAMOVE_QUOTATION_DETAILS":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: state.clientResponses.responses.data?.map((response) => {
                  if (
                    response._id === state.clientResponses.currentResponse._id
                  ) {
                    return {
                      ...response,
                      ...{
                        delivery_services: {
                          lalamove: action.payload.quotationDetails,
                        },
                      },
                    };
                  } else {
                    return response;
                  }
                }),
              },
            },
          },
          ...{
            currentResponse: {
              ...state.clientResponses.currentResponse,
              ...{
                delivery_services: {
                  lalamove: action.payload.quotationDetails,
                },
              },
            },
          },
        },
      };

    case "SET_LALAMOVE_ORDER_DETAILS":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: state.clientResponses.responses.data?.map((response) => {
                  if (
                    response._id === state.clientResponses.currentResponse._id
                  ) {
                    return {
                      ...response,
                      ...{
                        delivery_services: {
                          lalamove: {
                            ...response.delivery_services.lalamove,
                            ...action.payload.orderDetails,
                          },
                        },
                      },
                    };
                  } else {
                    return response;
                  }
                }),
              },
            },
          },
          ...{
            currentResponse: {
              ...state.clientResponses.currentResponse,
              ...{
                delivery_services: {
                  lalamove: {
                    ...state.clientResponses.currentResponse.delivery_services
                      .lalamove,
                    ...action.payload.orderDetails,
                  },
                },
              },
            },
          },
        },
      };

    case "RESET_LALAMOVE_ORDER_DETAILS":
      return {
        ...state,
        clientResponses: {
          ...state.clientResponses,
          ...{
            responses: {
              ...state.clientResponses.responses,
              ...{
                data: state.clientResponses.responses.data?.map((response) => {
                  if (
                    response._id === state.clientResponses.currentResponse._id
                  ) {
                    return {
                      ...response,
                      ...{
                        delivery_services: {
                          lalamove: undefined,
                        },
                      },
                    };
                  } else {
                    return response;
                  }
                }),
              },
            },
          },
          ...{
            currentResponse: {
              ...state.clientResponses.currentResponse,
              ...{
                delivery_services: {
                  lalamove: undefined,
                },
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default responseReducer;
