import { useGlobalContext } from "../../../contexts/globalContext";
import { Drawer, Typography, Steps, Space, Popover } from "antd";
import { TbBulb, TbCircleCheck } from "react-icons/tb";
import { BiImport } from "react-icons/bi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import BotKnowledgebaseForm from "./BotKnowledgebaseForm";
import BotKnowledgeBestPractices from "./BotKnowledgeBestPractices";
import BotKnowledgeBaseSubmit from "./BotKnowledgeBaseSubmit";
import { Translation } from "../../../translation/translation";

const { Text } = Typography;

const BotKnowledgebaseFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const translation = Translation().botKnowledgeArea.botKnowledgebase.formPanel;

  const steps = [
    {
      title: translation.bestPractices.title,
      content: <BotKnowledgeBestPractices />,
      icon: <TbBulb />,
    },
    {
      title: translation.uploadFileForm.title,
      content: <BotKnowledgebaseForm />,
      icon: <BiImport />,
    },
    {
      title: translation.review.title,
      content: <BotKnowledgeBaseSubmit />,
      icon: <TbCircleCheck />,
      disabled: !state.botKnowledgebase?.uploadedFile,
    },
  ];

  return (
    <Drawer
      destroyOnClose={true}
      title={
        state.formPanel["knowledgebase-form-panel"] &&
        state.formPanel["knowledgebase-form-panel"].title
      }
      open={
        state.formPanel["knowledgebase-form-panel"] &&
        state.formPanel["knowledgebase-form-panel"].visible
      }
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "knowledgebase-form-panel",
          },
        });
        dispatchReducer({
          type: "CLEAR_KNOWLEDGEBASE_UPLOADED_FILE",
        });
        dispatchReducer({
          type: "SET_CURRENT_STEP",
          payload: 0,
        });
        state.botKnowledgebase?.uploadType &&
          dispatchReducer({
            type: "SET_UPLOAD_TYPE",
            payload: {
              uploadType: undefined,
            },
          });
      }}
      placement="right"
      width={700}
      bodyStyle={{ backgroundColor: "#F4F7F9" }}
    >
      <Steps
        current={state.botKnowledgebase.currentStep || 0}
        items={steps.map((step, index) => ({
          ...step,
          title:
            index === steps.length - 1 &&
            !state.botKnowledgebase?.uploadedFile ? (
              <Popover
                content={
                  <Space>
                    <AiOutlineExclamationCircle
                      size={18}
                      style={{
                        color: "#faad14",
                        marginTop: 4,
                      }}
                    />
                    <Text>{translation.review.warningText}</Text>
                  </Space>
                }
                placement="topLeft"
                trigger="hover"
              >
                {step.title}
              </Popover>
            ) : (
              step.title
            ),
        }))}
        onChange={(current) => {
          dispatchReducer({
            type: "SET_CURRENT_STEP",
            payload: current,
          });
        }}
      />
      <div
        style={{
          marginTop: "15px",
        }}
      >
        {steps[state.botKnowledgebase.currentStep || 0].content}
      </div>
    </Drawer>
  );
};

export default BotKnowledgebaseFormPanel;
