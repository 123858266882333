import { useState } from "react";
import { Table } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import useClientResponsesTableColumns from "./ClientResponsesTableColumns";
import { useWindowSize } from "../../shared-components/PageReSize";
import { convertObjectToString } from "../../utils/utility";
import ResizeableTableColumn from "../../shared-components/ResizeableTableColumn";

const ClientResponsesTable = ({ isFeedbackChartVisible }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();
  const [pageSize, setPageSize] = useState();

  const { dataColumns, actionColumns } = useClientResponsesTableColumns();

  const [columns, setColumns] = useState(dataColumns.concat(actionColumns));

  const currentResponseCategory = state.clientResponses.currentResponseCategory;

  let clientResponses = state.clientResponses.responses.data;
  let enums = state.application.enums?.responses?.data || [];

  const selectedRowKeys =
    state.filters.selectedRowKeys["client-response-table-selected-row"];

  // Convert object/array from client response to string
  // To display details in table it should not be object/array
  const convertToString = (clientResponses) =>
    clientResponses?.map((clientResponse) =>
      Object.entries(clientResponse).reduce((acc, [key, value]) => {
        acc[key] =
          Array.isArray(value) || typeof value === "object"
            ? `Show more`
            : value;
        return acc;
      }, {})
    );

  clientResponses = convertToString(clientResponses);

  // Resize table column
  const handleColumnResize =
    (index) =>
    (_, { size }) => {
      const updatedColumn = [...columns];
      updatedColumn[index] = {
        ...updatedColumn[index],
        width: size.width,
      };
      setColumns(updatedColumn);
    };

  const updatedColumns = columns?.map((col, index) => ({
    ...col,
    ...{ filteredValue: dataColumns[index]?.filteredValue },
    onHeaderCell: (column) => ({
      width: column.width,
      module: "responses",
      onResize: handleColumnResize(index),
    }),
  }));

  // Update the column width to enum
  enums?.map((enumProperty) => {
    updatedColumns.forEach(({ key, width }) => {
      if (key === enumProperty._id) {
        enumProperty.width = width;
      }
    });
    return enumProperty;
  });

  return (
    <Table
      columns={updatedColumns}
      dataSource={clientResponses}
      loading={
        state.apiCallStatus["client-response-table"] &&
        state.apiCallStatus["client-response-table"].loading
      }
      onChange={(pagination, filters, sorter) => {
        dispatchReducer({
          type: "RD_SET_PAGINATION",
          payload: {
            component: "client-response-table",
            pagination,
          },
        });

        let filteredValue = {
          ...state.filters.filteredInfo["client-response-table"],
          ...filters,
        };
        //convert object to string
        filteredValue = convertObjectToString(filteredValue);

        dispatchMiddleware({
          type: "MWD_LOAD_RESPONSES_DATA",
          payload: {
            component: "client-response-table",
            currentResponseCategory,
            searchvalue: state.filters.searchText?.[
              "client-responses-search-text-box"
            ]
              ? state.filters.searchText[
                  "client-responses-search-text-box"
                ].concat("&search=true")
              : "",
            filteredValue,
            ...sorter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
          },
        });
        dispatchReducer({
          type: "RD_SET_FILTERED_INFO",
          payload: {
            component: "client-response-table",
            filteredInfo: {
              ...state.filters.filteredInfo["client-response-table"],
              ...filters,
            },
          },
        });
      }}
      rowKey="_id"
      showSorterTooltip={false}
      rowSelection={{
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          dispatchReducer({
            type: "RD_SET_SELECTED_ROW_KEYS",
            payload: {
              component: "client-response-table-selected-row",
              selectedRowKeys,
            },
          });
        },
      }}
      pagination={{
        current: state.filters.pagination?.["client-response-table"]
          ? state.filters.pagination["client-response-table"].current
          : 1,
        total: state.clientResponses.responses.totalRecords,
        showSizeChanger:
          state.clientResponses.responses.totalRecords > 20 ? true : false,
        pageSize:
          pageSize ??
          Math.round((height - (isFeedbackChartVisible ? 535 : 250)) / 45),
        pageSizeOptions: ["20", "30", "50", "100"],
        onShowSizeChange: (_, size) => {
          setPageSize(size);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      onRow={(selectedResponse) => {
        return {
          onClick: () => {
            dispatchReducer({
              type: "OPEN_INFO_PANEL",
              payload: {
                component: "client-response-info-panel",
              },
            });

            dispatchReducer({
              type: "RD_SET_ACTION_ROW_KEY",
              payload: {
                component: "client-responses-table",
                actionRowKey: selectedResponse._id,
              },
            });

            dispatchReducer({
              type: "SET_CURRENT_RESPONSE",
              payload: {
                currentResponse: selectedResponse,
              },
            });
          },
          onMouseEnter: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "#f5f5f5";
              }
            );
          },
          onMouseLeave: (e) => {
            Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
              (cell) => {
                cell.style.backgroundColor = "";
              }
            );
          },
          style: { cursor: "pointer" },
        };
      }}
      components={{
        header: {
          cell: ResizeableTableColumn,
        },
      }}
    />
  );
};
export default ClientResponsesTable;
