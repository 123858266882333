const accountBillingReducer = (state, action) => {
  switch (action.type) {
    case "SET_SUBSCRIPTION_DATA":
      return {
        ...state,
        accountBilling: {
          ...state.accountBilling,
          subscription: action.payload.subscription,
        },
      };

    case "SET_BUYADDONS_CURRENT_STEP":
      return {
        ...state,
        accountBilling: {
          ...state.accountBilling,
          subscription: {
            ...state.accountBilling.subscription,
            buyAddonsCurrentStep: action.payload.current,
          },
        },
      };

    case "SET_ADDONS":
      return {
        ...state,
        accountBilling: {
          ...state.accountBilling,
          subscription: {
            ...state.accountBilling.subscription,
            newAddons: action.payload.addonDetails,
          },
        },
      };

    default:
      return state;
  }
};

export default accountBillingReducer;
