import { Space, Row, Col, Tabs, Layout } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useGlobalContext } from "../../../contexts/globalContext";
import ModuleNotSubscribed from "../../../shared-components/ModuleNotSubscribed";
import { TabLoader } from "../../../shared-components/LoadingIndicator";
import { SafetyCertificateOutlined } from "@ant-design/icons";

const ContentModeration = () => {
  const [state] = useGlobalContext();
  const navigate = useNavigate();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {["STANDARD", "STARTER", "NOTIFY"].includes(
        botToBeEdited.subscription_package
      ) ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModuleNotSubscribed module="content-moderation" />
        </Layout.Content>
      ) : (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <SafetyCertificateOutlined size={18} style={{ margin: -4 }} />
                  Content moderation
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={24}>Content Moderation</Col>
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["content-moderation"] &&
            state.apiCallStatus["content-moderation"].loading && (
              <TabLoader
                component="content-moderation"
                loadingMessage="Synchronizing content-moderation..."
                errorMessage="Error synchronizing content moderation"
              />
            )
          }
        />
      )}
    </>
  );
};

export default ContentModeration;
