import React from "react";
import { Pie } from "@ant-design/plots";
import { Col, Row, Space, Tooltip, Typography } from "antd";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const TicketsTimeTakenChart = () => {
  const ticketData = [
    { ticketId: "T001", timeTaken: 2 },
    { ticketId: "T002", timeTaken: 1 },
    { ticketId: "T003", timeTaken: 2 },
    { ticketId: "T004", timeTaken: 1 },
    { ticketId: "T005", timeTaken: 1 },
    { ticketId: "T006", timeTaken: 3 },
    { ticketId: "T007", timeTaken: 2 },
    { ticketId: "T008", timeTaken: 1 },
    { ticketId: "T009", timeTaken: 1 },
    { ticketId: "T010", timeTaken: 1 },
  ];

  const color = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
    "#FF5722",
    "#FF9800",
  ];

  const config = {
    color: color,
    appendPadding: 10,
    data: ticketData,
    angleField: "timeTaken",
    colorField: "ticketId",
    innerRadius: 0.5,
    label: {
      type: "inner",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      <Row>
        <Col span={23}>
          <Text strong>Tickets Completed Time</Text>
        </Col>
        <Col span={1}>
          <Space>
            <Tooltip
              style={{ marginTop: 5 }}
              placement="topRight"
              title="This statistic shows the time taken to process the tickets"
            >
              <IoIosInformationCircleOutline size="1.5em" />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Pie {...config} style={{ height: "200px" }} />
    </>
  );
};

export default TicketsTimeTakenChart;
