import React, { useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Space,
  Row,
  Col,
  Image,
  Typography,
  List,
  Badge,
  Tooltip,
  Avatar,
} from "antd";
import {
  channels,
  botProfilePictures,
  conversationStatus,
} from "../../constants/enums";
import { getUserDateTimeMoment } from "../../utils/utility";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { TabLoader } from "../../shared-components/LoadingIndicator";
import { useWindowSize } from "../../shared-components/PageReSize";

const { Text } = Typography;

const ConversationContacts = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();

  useEffect(() => {
    if (Object.keys(state.conversations.currentConversation).length === 0) {
      dispatchReducer({
        type: "RD_SET_CURRENT_CONVERSATION",
        payload: {
          currentConversation: state.conversations.contactsWithTranscripts[0],
        },
      });
    }
  }, [state.conversations.lastUpdatedAt]);

  useEffect(() => {
    if (state.conversations.currentConversation.unreadMessagesCount) {
      setTimeout(() => {
        dispatchMiddleware({
          type: "CLEAR_UNREAD_MESSAGES_COUNT",
          payload: {
            contactId: state.conversations.currentConversation._id,
            unreadMessagesCount:
              state.conversations.currentConversation.unreadMessagesCount,
            component:
              "conversations-contact-" +
              state.conversations.currentConversation._id +
              "-list",
          },
        });
      }, 3000);
    }
  }, [state.conversations.currentConversation.unreadMessagesCount]);

  return (
    <>
      <Row
        style={{
          backgroundColor: "#FAFBFA",
          height: 61,
          borderBottom: "1px solid #E9E9E9",
        }}
      ></Row>
      <Row>
        <Col span={24}>
          <div
            id="scrollableDiv"
            style={{
              height: height - 162,
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={state.conversations.contactsWithTranscripts.length}
              next={() => {
                dispatchMiddleware({
                  type: "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                  payload: {
                    component: "conversations-contact-list",
                    pageSize: Math.round(height / 60),
                    pageNumber:
                      Math.floor(
                        state.conversations.contactsWithTranscripts.length /
                          Math.round(height / 60)
                      ) + 1,
                    delta: false,
                  },
                });
              }}
              hasMore={
                state.conversations.contactsWithTranscripts.length <
                state.conversations.contactsCountInDatabase
              }
              loader={
                <TabLoader
                  component="conversations-contact-list"
                  loadingMessage="Loading contacts..."
                  errorMessage="Error loading contacts"
                />
              }
              // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                size="small"
                style={{ height: "100%" }}
                dataSource={state.conversations.contactsWithTranscripts.sort(
                  (x, y) =>
                    new Date(y.last_modified) - new Date(x.last_modified)
                )}
                renderItem={(contact) => {
                  let lastMessagePreview;

                  if (contact.messages && contact.messages.length > 0) {
                    if (contact.messages[contact.messages.length - 1].event) {
                      lastMessagePreview =
                        contact.messages[contact.messages.length - 1]
                          .description;
                    } else {
                      if (
                        contact.messages[contact.messages.length - 1]
                          .message === "[interactive]" ||
                        contact.messages[contact.messages.length - 1]
                          .message ===
                          "[application/vnd.microsoft.card.hero]" ||
                        contact.messages[contact.messages.length - 1]
                          .message ===
                          "[application/vnd.microsoft.card.adaptive]" ||
                        contact.messages[contact.messages.length - 1]
                          .message ===
                          "[application/vnd.microsoft.card.thumbnail]"
                      ) {
                        lastMessagePreview = "[card]";
                      } else {
                        if (
                          contact.messages[contact.messages.length - 1]
                            .message &&
                          contact.messages[contact.messages.length - 1].message
                            .length > 40
                        ) {
                          lastMessagePreview = contact.messages[
                            contact.messages.length - 1
                          ].message.substr(0, 40);

                          lastMessagePreview = lastMessagePreview.substr(
                            0,
                            Math.min(
                              lastMessagePreview.length,
                              lastMessagePreview.lastIndexOf(" ")
                            )
                          );
                        } else {
                          lastMessagePreview =
                            contact.messages[contact.messages.length - 1]
                              .message;
                        }
                      }
                    }
                  }

                  let personWhoMessagedLast;
                  if (
                    contact.messages[contact.messages.length - 1]
                      .is_from_user === false &&
                    contact.messages[contact.messages.length - 1].is_agent ===
                      false
                  ) {
                    personWhoMessagedLast = "Bot";
                  } else if (
                    contact.messages[contact.messages.length - 1].is_agent ===
                    true
                  ) {
                    if (
                      contact.messages[contact.messages.length - 1].agent_id ===
                      state.currentUser.user.userID
                    ) {
                      personWhoMessagedLast = "You";
                    } else {
                      const user = state.accountUsers.users.find(
                        (user) =>
                          user.userID ===
                          contact.messages[contact.messages.length - 1].agent_id
                      );
                      personWhoMessagedLast = user?.displayName;
                    }
                  }

                  let dateTimeToBeDisplayed;

                  const userDateTimeMoment = getUserDateTimeMoment(
                    contact.last_modified,
                    state.currentUser.user.timeZone
                  );

                  const userSpecificDate =
                    userDateTimeMoment.format("DD/MM/yyyy");
                  const todayDate = moment().format("DD/MM/yyyy");

                  if (
                    moment(todayDate, "DD/MM/yyyy").diff(
                      moment(userSpecificDate, "DD/MM/yyyy"),
                      "days"
                    ) === 0
                  ) {
                    dateTimeToBeDisplayed =
                      moment(userDateTimeMoment).format("hh:mm a");
                  } else if (
                    moment(todayDate, "DD/MM/yyyy").diff(
                      moment(userSpecificDate, "DD/MM/yyyy"),
                      "days"
                    ) === 1
                  ) {
                    dateTimeToBeDisplayed = "Yesterday";
                  } else if (
                    moment(todayDate, "DD/MM/yyyy").diff(
                      moment(userSpecificDate, "DD/MM/yyyy"),
                      "days"
                    ) > 1 &&
                    moment(todayDate, "DD/MM/yyyy").diff(
                      moment(userSpecificDate, "DD/MM/yyyy"),
                      "days"
                    ) < 7
                  ) {
                    dateTimeToBeDisplayed =
                      moment(userDateTimeMoment).format("dddd");
                  } else {
                    dateTimeToBeDisplayed =
                      moment(userDateTimeMoment).format("DD/MM/yyyy");
                  }

                  return (
                    <List.Item
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          contact._id ===
                          state.conversations.currentConversation._id
                            ? "#fafbfa"
                            : " inherit",
                        borderLeft:
                          state.conversations.liveConversations.includes(
                            contact._id
                          ) &&
                          state.conversations.onHoldConversations.includes(
                            contact._id
                          )
                            ? conversationStatus.onHoldConversations.style
                            : state.conversations.liveConversations.includes(
                                contact._id
                              )
                            ? conversationStatus.liveConversations.style
                            : state.conversations.waitingForLiveAgent.includes(
                                contact._id
                              )
                            ? conversationStatus.waitingForLiveAgent.style
                            : state.conversations.coAgentLiveConversations.includes(
                                contact._id
                              )
                            ? conversationStatus.coAgentLiveConversations.style
                            : "",
                      }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_CURRENT_CONVERSATION",
                          payload: {
                            currentConversation: contact,
                          },
                        });

                        dispatchReducer({
                          type: "CLEAR_CONVERSATION_SUMMARY",
                        });
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          state.conversations.liveConversations.includes(
                            contact._id
                          ) &&
                          state.conversations.onHoldConversations.includes(
                            contact._id
                          )
                            ? conversationStatus.onHoldConversations.text
                            : state.conversations.liveConversations.includes(
                                contact._id
                              )
                            ? conversationStatus.liveConversations.text
                            : state.conversations.waitingForLiveAgent.includes(
                                contact._id
                              )
                            ? conversationStatus.waitingForLiveAgent.text
                            : state.conversations.coAgentLiveConversations.includes(
                                contact._id
                              )
                            ? conversationStatus.coAgentLiveConversations.text
                            : ""
                        }
                      >
                        <Space size={10} style={{ width: "100%" }}>
                          <Avatar
                            src={
                              <Image
                                preview={false}
                                src={
                                  contact.channel &&
                                  channels[contact.channel.toUpperCase()] &&
                                  channels[contact.channel.toUpperCase()].logo
                                }
                              />
                            }
                          />
                          <Space direction="vertical" size={0}>
                            <Space
                              style={{
                                width: 300,
                                justifyContent: "space-between",
                              }}
                            >
                              <Space>
                                <Text strong>
                                  {contact.name === "." || !contact.name
                                    ? "User"
                                    : contact.name}
                                </Text>

                                {contact.unreadMessagesCount !== 0 && (
                                  <Badge
                                    count={
                                      contact.unreadMessagesCount > 99
                                        ? "99+"
                                        : contact.unreadMessagesCount
                                    }
                                    size="default"
                                  />
                                )}
                              </Space>

                              <Space span={3}>
                                <Text
                                  type="secondary"
                                  strong={contact.unreadMessagesCount !== 0}
                                  style={{
                                    fontSize: "13px",
                                    color:
                                      contact.unreadMessagesCount !== 0 &&
                                      "#F05742",
                                  }}
                                >
                                  {dateTimeToBeDisplayed}
                                </Text>
                              </Space>
                            </Space>

                            <Space>
                              <Text
                                type="secondary"
                                strong={contact.unreadMessagesCount !== 0}
                                style={{ fontSize: "13px" }}
                              >
                                <Space
                                  style={{
                                    width: 300,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Space>
                                    {personWhoMessagedLast
                                      ? personWhoMessagedLast +
                                        ": " +
                                        lastMessagePreview
                                      : lastMessagePreview}
                                  </Space>

                                  <Space>
                                    <Tooltip
                                      title={
                                        state.currentAccount.account.bots.find(
                                          (bot) => bot.bot_id === contact.bot_id
                                        )?.bot_name
                                      }
                                    >
                                      <Avatar
                                        src={
                                          botProfilePictures[
                                            state.currentAccount.account.bots.find(
                                              (bot) =>
                                                bot.bot_id === contact.bot_id
                                            )?.bot_icon
                                          ]
                                        }
                                        size="small"
                                      />
                                    </Tooltip>
                                  </Space>
                                </Space>
                              </Text>
                            </Space>
                          </Space>
                        </Space>
                      </Tooltip>
                    </List.Item>
                  );
                }}
              />
            </InfiniteScroll>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ConversationContacts;
