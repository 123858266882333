import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { useWindowSize } from "../shared-components/PageReSize";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";
import { getUserDateTime } from "../utils/utility";

export const useClientResponsesMiddleware = () => {
  const { callApi } = useConnectApi();
  const [height] = useWindowSize();

  return async (state, action, dispatchReducer) => {
    // Check page size
    let pageSize =
      state.filters.pagination?.["client-response-table"]?.pageSize ??
      Math.round((height - 250) / 45);
    switch (action.type) {
      ////*******************************************////
      ////************** Client Responses ***********////
      ////*******************************************////
      //Load Response categories
      case "MWD_LOAD_RESPONSE_CATEGORIES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        const defaultBotID = (
          state.currentAccount.account.bots.find((bot) => bot.bot_default) ||
          state.currentAccount.account.bots[0]
        )?.bot_id;
        //First get action points
        await callApi({
          urls: [
            apiEndPoints.RESPONSE_CATEGORIES.concat(
              "?bot_id=",
              state.application.botInAction,
              "&default_bot_id=",
              defaultBotID
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responseCategories]) => {
            // Sort response categories
            const sortedResponseCategories = responseCategories.sort();
            dispatchReducer({
              type: "RD_SET_RESPONSE_CATEGORIES",
              payload: {
                sortedResponseCategories,
              },
            });

            dispatchReducer({
              type: "RD_SET_RESPONSE_SETTINGS_CURRENT_RESPONSE_CATEGORY",
              payload: {
                currentResponseCategory: sortedResponseCategories[0],
              },
            });

            dispatchReducer({
              type: "RD_SET_RESPONSE_SETTINGS_RESPONSE_CATEGORIES",
              payload: {
                sortedResponseCategories,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load Response Categories Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: {
                component: action.payload.component,
                error,
              },
            });
          },
        });
        break;

      //Load Responses Details
      case "MWD_LOAD_RESPONSES_DATA":
        // Check page size from table pagination
        pageSize = action.payload.pageSize ?? pageSize;
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        const defaultBotId = (
          state.currentAccount.account.bots.find((bot) => bot.bot_default) ||
          state.currentAccount.account.bots[0]
        )?.bot_id;
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat(
              "?bot_id=",
              state.application.botInAction,
              "&category=",
              action.payload.currentResponseCategory,
              action.payload.pageNumber
                ? "&pagenumber=" + action.payload.pageNumber
                : "",
              //For export responses, we need to get all the responses
              action.payload.eventType !== "exportResponses"
                ? "&pagesize=" + pageSize
                : "",
              action.payload.filteredValue
                ? "&" + action.payload.filteredValue
                : "",
              action.payload.order
                ? action.payload.columnKey
                  ? "&sortkey=" + action.payload.columnKey
                  : ""
                : "",
              action.payload.order
                ? action.payload.order === "ascend"
                  ? "&sorttype=1"
                  : "&sorttype=-1"
                : "",
              action.payload.searchvalue
                ? "&searchvalue=" + action.payload.searchvalue
                : "",
              "&default_bot=",
              defaultBotId === state.application.botInAction,
              "&default_bot_id=",
              defaultBotId
            ),
            ...(!state.accountUsers.users ? [apiEndPoints.ACCOUNT_USERS] : []),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responses, users]) => {
            //export responses
            if (action.payload.eventType === "exportResponses") {
              let clientResponses = responses.data;

              const expectedResponseTitle = [];
              const clientResponsesColumns =
                action.payload.clientResponsesColumns;

              //Remove comma from clientResponse values
              clientResponses = JSON.stringify(clientResponses);

              clientResponses = clientResponses?.replace(
                /(?=,(?!"))(,(?!{))/g,
                ""
              );

              clientResponses = JSON.parse(clientResponses);

              clientResponsesColumns.forEach((column) => {
                // Check if the value is an object and needs to be split
                if (
                  !Array.isArray(column.value) &&
                  typeof column.value === "object" &&
                  column.value !== null
                ) {
                  // Flatten the nested object by creating a separate entry for each key-value pair
                  Object.entries(column.value).forEach(([key, value]) => {
                    expectedResponseTitle.push({
                      key: key,
                      title:
                        key &&
                        key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, function (str) {
                            return str.toUpperCase();
                          }),
                      value: value || key,
                      display: column.display || true,
                    });
                  });
                } else {
                  // Push the original column if it's not a nested object
                  expectedResponseTitle.push(column);
                }
              });

              const expectedResponseFormat = clientResponses.map((order) =>
                Object.entries(order).reduce((acc, [key, value]) => {
                  if (
                    typeof value === "object" &&
                    value !== null &&
                    !Array.isArray(value)
                  ) {
                    // Spread nested object properties into the main object
                    acc = { ...acc, ...value };
                  } else if (Array.isArray(value)) {
                    acc[key] = `"${value
                      .map((item) => item.toString())
                      .join(",")}"`;
                  } else {
                    acc[key] = value;
                  }
                  return acc;
                }, {})
              );

              //Combain CSV Title with values
              const commaSeparatedString = [
                expectedResponseTitle.map(({ title }) => title).join(","),
                expectedResponseFormat
                  .map((response) => {
                    return {
                      ...response,
                      leadDate: getUserDateTime(
                        response.responseDate || response.leadDate,
                        state.currentUser.user.timeZone,
                        state.currentUser.user.dateFormat
                      ),
                    };
                  })

                  .map((response) =>
                    expectedResponseTitle
                      .map(({ key, value }) => {
                        if (
                          !Array.isArray(value) &&
                          typeof value === "object" &&
                          value !== null
                        ) {
                          return value[key] || "";
                        } else {
                          return response[key] || "";
                        }
                      })
                      .join(",")
                  )
                  .join("\n"),
              ].join("\n");

              //Create URL for export
              const a = document.createElement("a");
              a.href = URL.createObjectURL(
                new Blob([commaSeparatedString], {
                  type: "text/csv",
                })
              );
              a.setAttribute("download", "responses.csv");
              document.body.appendChild(a);
              a.click();
            } else {
              dispatchReducer({
                type: "SET_CLIENT_RESPONSES",
                responses: responses,
              });
              users &&
                dispatchReducer({
                  type: "SET_ACCOUNT_USERS",
                  users: users.data,
                });
            }

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load Responses Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Set Status to Client Response
      case "MWD_SET_CLIENT_RESPONSE_STATUS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat(
              "?_id=",
              action.payload.actionRowKey,
              "&pagenumber=" + action.payload.pagination,
              "&pagesize=" + pageSize,
              "&bot_id=",
              state.application.botInAction,
              "&category=",
              state.clientResponses.currentResponseCategory,
              action.payload.filteredValue
                ? "&" + action.payload.filteredValue
                : ""
            ),
          ],
          options: {
            method: "PATCH",
            body: [{ key: "status", value: action.payload.status }],
          },
          onSuccess: ([responses]) => {
            dispatchReducer({
              type: "SET_CLIENT_RESPONSE_STATUS",
              payload: {
                responses,
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_SELECTED_ROW_KEYS",
              payload: {
                component: "client-response-table-selected-row",
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon("success", "Status updated successfully");
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Set Status to Client Response Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Set assignee to the Client Response
      case "MWD_SET_CLIENT_RESPONSE_ASSIGNEE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat(
              "?_id=",
              action.payload.actionRowKey,
              "&pagenumber=" + action.payload.pagination,
              "&pagesize=" + pageSize,
              "&bot_id=",
              state.application.botInAction,
              "&category=",
              state.clientResponses.currentResponseCategory,
              action.payload.filteredValue
                ? "&" + action.payload.filteredValue
                : ""
            ),
          ],
          options: {
            method: "PATCH",
            body: [{ key: "assigned", value: action.payload.assignedTo }],
          },
          onSuccess: ([responses]) => {
            dispatchReducer({
              type: "SET_CLIENT_RESPONSE_ASSIGNEE",
              payload: {
                responses,
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_SELECTED_ROW_KEYS",
              payload: {
                component: "client-response-table-selected-row",
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Response assigned successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Assign Response Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Delete Response Detail
      case "MWD_DELETE_SINGLE_RESPONSE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat("?_ids=", action.payload.actionRowKey),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "client-responses-table" },
            });
            dispatchReducer({
              type: "DELETE_SINGLE_RESPONSE",
              actionRowKey: action.payload.actionRowKey,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Response deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Response Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Delete multiple responses
      case "MWD_DELETE_MULTIPLE_RESPONSES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat(
              "?_ids=",
              action.payload.selectedRowKeys
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_CLEAR_SELECTED_ROW_KEYS",
              payload: { component: "client-response-table-selected-row" },
            });
            dispatchReducer({
              type: "RD_DELETE_MULTIPLE_RESPONSES",
              payload: {
                selectedRowKeys: action.payload.selectedRowKeys,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Responses deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Multiple Responses Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Load response custom column dropdown values
      case "MWD_LOAD_RESPONSE_CUSTOM_COLUMN_DROPDOWN_VALUES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat(
              `/getresponsevaluebypropertyname?bot_id=${state.application.botInAction}&propertyName=${action.payload.selectedResponseColumn}&category=${state.clientResponses.currentResponseCategory}`
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([optionValues]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSE_CUSTOM_COLUMN_DROPDOWN_VALUES",
              payload: {
                optionValues: optionValues?.map((value) => ({
                  label: value,
                  value,
                })),
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Handle table width change
      case "SET_TABLE_COLUMN_WIDTH":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.ENUMS],
          options: {
            method: "POST",
            body: action.payload.enumValues,
          },
          onSuccess: ([enums]) => {
            dispatchReducer({
              type: "RD_SET_ENUMS",
              payload: {
                enums: enums.data,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Response Width Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Load Response Timeline
      case "LOAD_RESPONSES_TIMELINE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        //First get action points
        await callApi({
          urls: [
            apiEndPoints.RESPONSE.concat(
              "/timeline?conversationId=",
              state.clientResponses.currentResponse.conversationId.replace(
                // Remove the + sign from the conversation id
                /^\+/,
                ""
              ),
              "&bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responsesTimeline]) => {
            responsesTimeline = responsesTimeline?.data;
            // Sort the timeline in descending order
            responsesTimeline?.reverse();
            // Add a dummy dot to the timeline UI
            responsesTimeline?.unshift({
              dot: <></>,
            });
            dispatchReducer({
              type: "SET_RESPONSES_TIMELINE",
              payload: {
                responsesTimeline,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Response Timeline Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: {
                component: action.payload.component,
                error,
              },
            });
          },
        });
        break;
    }
  };
};
