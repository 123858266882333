import React from "react";
import { Space, Row, Col, Image, Typography, Button, Modal } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { channels } from "../../constants/enums";
import { useGlobalContext } from "../../contexts/globalContext";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { wabaProvider } from "../../constants/enums";

const { Title } = Typography;
const { confirm } = Modal;

const ConversationHeader = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const handleRequestFeedback = () => {
    const botDetails = state.currentAccount.account.bots.filter(
      (bot) => bot.bot_id === state.conversations.currentConversation.bot_id
    );
    // Request Feedback Template
    let requestFeedbackTemplate = (
      state.conversations.liveAgentSettingsAcrossBots?.bots?.find(
        (bot) => bot.botId === state.conversations.currentConversation.bot_id
      ) || state.conversations.liveAgentSettingsAcrossBots
    )?.requestFeedbackTemplate;

    const requestFeedbackBroadcastPayload = {
      bot: botDetails[0],
      broadcastContent: {
        campaignName: "Request Feedback",
        channel: state.conversations.currentConversation.channel,
        recipient: `+${state.conversations.currentConversation.conversation_id}`,
        template: {
          logToTranscripts: true,
          templateMessage: "Request Feedback (Template Message)",
          namespace: botDetails[0].bot_channel_info.WHATSAPP.waba_namespace,
          language: {
            policy: "deterministic",
            code: requestFeedbackTemplate?.language,
          },
          name: requestFeedbackTemplate?.name,
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: state.conversations.currentConversation.name,
                },
              ],
            },
            {
              type: "button",
              sub_type: "quick_reply",
              index: "0",
              parameters: [
                {
                  type: "payload",
                },
              ],
            },
          ],
        },
      },
      channelConfigurationInfo: {
        "api-provider":
          wabaProvider[botDetails[0].bot_channel_info.WHATSAPP.waba_provider]
            .providerReference.providerName,
        "waba-key": botDetails[0].bot_channel_info.WHATSAPP.waba_apikey,
      },
      mediaContent: {
        hasMedia: false,
        mediaType: "",
        mediaData: {},
      },
      recipientType: "single",
      submitType: "start-broadcast",
      successMessage: "Feedback request sent successfully",
      component:
        "request-feedback-" +
        state.conversations.currentConversation._id +
        "-button",
    };

    dispatchMiddleware({
      type: "START_BROADCAST",
      payload: {
        ...requestFeedbackBroadcastPayload,
      },
    });
  };

  return (
    <Row
      align="middle"
      style={{
        backgroundColor: "#FAFBFA",
        height: 60,
      }}
    >
      <Col span={16}>
        <Space
          size={15}
          style={{ paddingLeft: 15, cursor: "pointer" }}
          onClick={() => {
            dispatchMiddleware({
              type: "MWD_LOAD_CONTACT_MANAGER_DATA",
              payload: { component: "contact-manager-table" },
            });

            dispatchReducer({
              type: "OPEN_INFO_PANEL",
              payload: {
                component: "conversation-info-panel",
              },
            });
          }}
        >
          <Avatar
            size="large"
            src={
              <Image
                preview={false}
                src={
                  state.conversations.currentConversation.channel &&
                  channels[
                    state.conversations.currentConversation.channel.toUpperCase()
                  ] &&
                  channels[
                    state.conversations.currentConversation.channel.toUpperCase()
                  ].logo
                }
              />
            }
          />
          <Row>
            <Col span={24}>
              <Title style={{ margin: 0 }} level={5}>
                {state.conversations.currentConversation.name}
              </Title>
            </Col>
          </Row>
        </Space>
      </Col>
      {state.conversations.liveConversations.includes(
        state.conversations.currentConversation._id
      ) ? (
        <Col span={8}>
          <Space
            style={{ width: "100%", justifyContent: "right", paddingRight: 15 }}
          >
            {state.apiCallStatus[
              "agent-leave-" +
                state.conversations.currentConversation._id +
                "-button"
            ] &&
            state.apiCallStatus[
              "agent-leave-" +
                state.conversations.currentConversation._id +
                "-button"
            ].loading ? (
              <ComponentLoader
                component={
                  "agent-leave-" +
                  state.conversations.currentConversation._id +
                  "-button"
                }
                loadingMessage="Leaving conversation..."
                errorMessage=""
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to leave the conversation?",
                    content:
                      "When you click the OK button, you will exit the conversation",
                    onOk() {
                      dispatchMiddleware({
                        type: "MWD_CONVERSATIONS_AGENT_LEAVE",
                        payload: {
                          component:
                            "agent-leave-" +
                            state.conversations.currentConversation._id +
                            "-button",
                          botId: state.conversations.currentConversation.bot_id,
                          conversationId:
                            state.conversations.currentConversation
                              .conversation_id,
                        },
                      });
                    },
                    onCancel() {},
                  });
                }}
              >
                Leave Conversation
              </Button>
            )}
          </Space>
        </Col>
      ) : (
        state.conversations.currentConversation.channel?.toUpperCase() ===
          "WHATSAPP" && (
          <Col span={8}>
            <Space
              style={{
                width: "100%",
                justifyContent: "right",
                paddingRight: 15,
              }}
            >
              {state.apiCallStatus[
                "request-feedback-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ]?.loading ? (
                <ComponentLoader
                  component={
                    "request-feedback-" +
                    state.conversations.currentConversation._id +
                    "-button"
                  }
                  loadingMessage="Requesting feedback..."
                  errorMessage=""
                />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    confirm({
                      title: "Are you sure you want to  Request Feedback?",
                      content:
                        "When you click the OK button, a request for feedback will be sent",
                      onOk() {
                        handleRequestFeedback();
                      },
                      onCancel() {},
                    });
                  }}
                >
                  Request Feedback
                </Button>
              )}
            </Space>
          </Col>
        )
      )}
    </Row>
  );
};

export default ConversationHeader;
