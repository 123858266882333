import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Button,
  Form,
  TimePicker,
  Checkbox,
  Tag,
  Select,
  Switch,
  Empty,
} from "antd";
import moment from "moment";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import {
  subscriptionPackage,
  timeZone,
  liveAgentAvailability,
} from "../../../constants/enums";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

const LiveAgentForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [availability, setAvailability] = useState(
    state.liveAgentSettings.availability || liveAgentAvailability
  );

  let initiateConversationTemplate =
    state.liveAgentSettings.initiateConversationTemplate;
  let requestFeedbackTemplate = state.liveAgentSettings.requestFeedbackTemplate;

  let filteredTemplates =
    state.messageTemplates.templates?.find(
      (template) => template.botId === state.application.botInAction
    )?.waba_templates || [];

  //Validate the live agent templates
  const templates = filteredTemplates.reduce(
    (acc, template) => {
      if (
        Array.isArray(template.components) &&
        !template.components.some(
          (component) =>
            component.type === "HEADER" || component.type === "FOOTER"
        ) &&
        template.components.some(
          (component) =>
            component.type === "BODY" &&
            component.text?.includes("{{1}}") &&
            component.text.match(/[^{}]+(?=})/g).length === 1
        )
      ) {
        const isLiveAgentTemplate = template.components.some(
          (component) =>
            component.buttons?.length === 1 &&
            component.buttons?.some(
              (button) =>
                button.type.includes("QUICK_REPLY") &&
                component.type === "BUTTONS"
            )
        );

        const isRequestFeedbackTemplate = template.components.some(
          (component) =>
            component.buttons?.length === 1 &&
            component.buttons?.some(
              (button) =>
                button.type.includes("QUICK_REPLY") &&
                component.type === "BUTTONS"
            )
        );
        if (isLiveAgentTemplate) {
          acc.initiateConversationTemplates.push(template);
        }
        if (isRequestFeedbackTemplate) {
          acc.requestFeedbackTemplates.push(template);
        }
      }
      return acc;
    },

    { initiateConversationTemplates: [], requestFeedbackTemplates: [] }
  );

  let { initiateConversationTemplates, requestFeedbackTemplates } = templates;
  //Remove duplicate initiateConversationTemplates
  initiateConversationTemplates = initiateConversationTemplates?.filter(
    (template, index, initiateConversationTemplates) =>
      index ===
      initiateConversationTemplates.findIndex(
        (currentTemplate) => currentTemplate.name === template.name
      )
  );
  //Remove duplicate requestFeedbackTemplates
  requestFeedbackTemplates = requestFeedbackTemplates?.filter(
    (template, index, requestFeedbackTemplates) =>
      index ===
      requestFeedbackTemplates.findIndex(
        (currentTemplate) => currentTemplate.name === template.name
      )
  );

  const updatePropertyValue = (day, propertyName, propertyValue) => {
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        [propertyName]: propertyValue,
      },
    });
  };

  //Set the live agent configurations
  useEffect(() => {
    const liveAgentSettings = state.liveAgentSettings;

    if (availability) {
      const formValues = {};

      Object.entries(availability).forEach(([timeSlot, slotData]) => {
        const { isHoliday, open, closing } = slotData;

        formValues[`isChecked${timeSlot}`] = !isHoliday;
        formValues[`fromTime${timeSlot}`] = moment(open, "HH:mm:ss");
        formValues[`toTime${timeSlot}`] = moment(closing, "HH:mm:ss");
      });

      form.setFieldsValue(formValues);
    }

    form.setFieldsValue({
      timeZone: liveAgentSettings?.liveagent_available_timezone,
      initiateConversationTemplate:
        liveAgentSettings?.initiateConversationTemplate?.name,
      requestFeedbackTemplate: liveAgentSettings?.requestFeedbackTemplate?.name,
      liveAgentProfanity: liveAgentSettings?.liveAgentProfanity,
    });
  }, []);

  const handleSubmit = (e) => {
    dispatchMiddleware({
      type: "MWD_UPDATE_LIVE_AGENT_SETTINGS",
      payload: {
        updateLiveAgentSettings: {
          _id: state.liveAgentSettings._id,
          availability,
          liveagent_available_timezone: e.timeZone,
          initiateConversationTemplate,
          requestFeedbackTemplate,
          liveAgentProfanity: e.liveAgentProfanity,
        },
        component: "live-agent-form",
      },
    });
  };

  const filteredBot = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <>
      {state.apiCallStatus["live-agent-form"] &&
      state.apiCallStatus["live-agent-form"].loading ? (
        <FullPageLoader
          component="live-agent-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleSubmit}
        >
          <Row>
            <Col span={6}>
              <Space direction="vertical">
                <Text strong>Live Agents Availability</Text>

                <Row>
                  <Tag
                    color="#fff8c5"
                    style={{ borderRadius: "6px", padding: "3px 8px" }}
                  >
                    <Text italic>Feature available in Bot Version 2.0</Text>
                  </Tag>
                </Row>

                <Text>
                  Configure the timeslots in which the agents will be available
                  to respond to the incoming "Live Agent Request"
                </Text>
              </Space>
            </Col>

            <Col span={10}>
              <Row gutter={[0, 0]}>
                <Col offset={2} span={21}>
                  <Form.Item
                    name="timeZone"
                    label="Timezone"
                    valuePropName="value"
                  >
                    <Select
                      showSearch
                      style={{ width: "98%" }}
                      placeholder="Select timezone"
                    >
                      {timeZone.map((selectedTimeZone, index) => {
                        return (
                          <Option key={index} value={selectedTimeZone.value}>
                            {selectedTimeZone.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                {Object.keys(availability).map((timeSlot, index) => {
                  return (
                    <>
                      <Col offset={2} span={2}>
                        <Form.Item
                          key={index}
                          name={"isChecked" + timeSlot}
                          valuePropName="checked"
                        >
                          <Checkbox
                            disabled={
                              !state.currentUser.permission
                                .ACCOUNT_LIVE_AGENT_EDIT
                            }
                            onChange={(e) => {
                              updatePropertyValue(
                                timeSlot,
                                "isHoliday",
                                !e.target.checked
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Space>{timeSlot}</Space>
                      </Col>

                      <Col span={7}>
                        <Form.Item name={"fromTime" + timeSlot}>
                          <TimePicker
                            disabled={
                              availability[timeSlot].isHoliday ||
                              !state.currentUser.permission
                                .ACCOUNT_LIVE_AGENT_EDIT
                            }
                            placeholder="Start time"
                            use12Hours
                            format="h:mm a"
                            onSelect={(value) => {
                              updatePropertyValue(
                                timeSlot,
                                "open",
                                value.format("HH:mm:ss")
                              );
                              form.setFieldsValue({
                                ["fromTime" + timeSlot]: moment(value),
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={2}>to</Col>

                      <Col span={7}>
                        <Form.Item name={"toTime" + timeSlot}>
                          <TimePicker
                            disabled={
                              availability[timeSlot].isHoliday ||
                              !state.currentUser.permission
                                .ACCOUNT_LIVE_AGENT_EDIT
                            }
                            placeholder="End time"
                            use12Hours
                            format="h:mm a"
                            onSelect={(value) => {
                              updatePropertyValue(
                                timeSlot,
                                "closing",
                                value.format("HH:mm:ss")
                              );
                              form.setFieldsValue({
                                ["toTime" + timeSlot]: moment(value),
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Col>
          </Row>

          <Divider />

          {filteredBot?.bot_channel_info?.WHATSAPP
            ?.waba_integrated_onboarding && (
            <>
              <Row>
                <Col span={6}>
                  <Space direction="vertical">
                    <Text strong>Live Agent Templates</Text>
                    <Text>
                      Configure the template messages for initiating
                      conversations and requesting feedback from users on
                      WhatsApp
                    </Text>
                  </Space>
                </Col>

                <Col span={10}>
                  <Row gutter={[0, 0]}>
                    <Col offset={2} span={21}>
                      <Form.Item
                        name="initiateConversationTemplate"
                        label="Initiate Conversation Template"
                        valuePropName="value"
                        rules={[
                          {
                            required: true,
                            message: "Please select valid template",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "98%" }}
                          placeholder="Select initiate conversation template"
                          defaultValue={initiateConversationTemplate?.name}
                          notFoundContent={
                            !state.messageTemplates.templates ||
                            !state.messageTemplates.templates?.filter(
                              (template) =>
                                template.botId === state.application.botInAction
                            ).length ? (
                              <Space
                                style={{
                                  width: "100%",
                                  height: 50,
                                }}
                              >
                                <TabLoader
                                  component="message-templates-table"
                                  loadingMessage="Loading... Please wait..."
                                  errorMessage="Error loading templates"
                                />
                              </Space>
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No templates found"
                              />
                            )
                          }
                          onSelect={(key) => {
                            const selectedTemplate =
                              initiateConversationTemplates?.find(
                                (template) => template.name === key
                              );

                            dispatchReducer({
                              type: "RD_SET_INITIATE_CONVERSATION_TEMPLATE",
                              payload: {
                                initiateConversationTemplate: selectedTemplate,
                              },
                            });
                          }}
                        >
                          {initiateConversationTemplates?.map((template) => (
                            <Option key={template.name} value={template.name}>
                              {template.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={10} offset={6}>
                  <Row>
                    <Col offset={2} span={21}>
                      <Form.Item
                        name="requestFeedbackTemplate"
                        label="Request Feedback Template"
                        valuePropName="value"
                        rules={[
                          {
                            required: true,
                            message: "Please select valid template",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "98%" }}
                          placeholder="Select request feedback template"
                          defaultValue={requestFeedbackTemplate?.name}
                          notFoundContent={
                            !state.messageTemplates.templates ||
                            !state.messageTemplates.templates?.filter(
                              (template) =>
                                template.botId === state.application.botInAction
                            ).length ? (
                              <Space
                                style={{
                                  width: "100%",
                                  height: 50,
                                }}
                              >
                                <TabLoader
                                  component="message-templates-table"
                                  loadingMessage="Loading... Please wait..."
                                  errorMessage="Error loading templates"
                                />
                              </Space>
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No templates found"
                              />
                            )
                          }
                          onSelect={(key) => {
                            const selectedTemplate =
                              requestFeedbackTemplates?.find(
                                (template) => template.name === key
                              );

                            dispatchReducer({
                              type: "RD_SET_REQUEST_FEEDBACK_TEMPLATE",
                              payload: {
                                requestFeedbackTemplate: selectedTemplate,
                              },
                            });
                          }}
                        >
                          {requestFeedbackTemplates?.map((template) => {
                            return (
                              <Option key={template.name} value={template.name}>
                                {template.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider />
            </>
          )}

          {(filteredBot.subscription_package === subscriptionPackage.STANDARD ||
            filteredBot.subscription_package ===
              subscriptionPackage.STARTER) && (
            <Row>
              <Col span={6}>
                <Space direction="vertical">
                  <Text strong>Live Agent Profanity</Text>
                  <Text>
                    Content moderation is essential for businesses to protect
                    their brand reputation, maintain customer trust, and create
                    a positive online user experience
                  </Text>
                </Space>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item name="liveAgentProfanity" valuePropName="checked">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Divider />
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Space size="small">
                  <Button
                    disabled={
                      !state.currentUser.permission.ACCOUNT_LIVE_AGENT_EDIT
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !state.currentUser.permission.ACCOUNT_LIVE_AGENT_EDIT
                    }
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default LiveAgentForm;
