const currentUserReducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        currentUser: {
          ...state.currentUser,
          ...{ user: action.user },
        },
      };

    case "UPDATE_CURRENT_USER_PERMISSION":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...{
            permission: {
              ...state.currentUser.permission,
              ...action.permission,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default currentUserReducer;
