const customerSupportReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_SUPPORT_TICKETS":
      return {
        ...state,
        supportTickets: {
          ...state.supportTickets,
          ...{ tickets: action.payload.tickets },
        },
      };

    case "RD_SET_SUPPORT_TICKET_INFO":
      return {
        ...state,
        supportTickets: {
          ...state.supportTickets,
          ...{ ticketInfo: action.payload.ticketInfo },
        },
      };

    case "RD_CREATE_SUPPORT_TICKET":
      return {
        ...state,
        supportTickets: {
          ...state.supportTickets,
          ...{
            tickets: [action.payload.ticket, ...state.supportTickets.tickets],
          },
        },
      };

    case "RD_REPLY_SUPPORT_TICKET":
      return {
        ...state,
        supportTickets: {
          ...state.supportTickets,
          ...{
            ticketInfo: [
              action.payload.replyTicket,
              ...state.supportTickets.tickets,
            ],
          },
        },
      };

    case "CLEAR_TICKET_INFO":
      return {
        ...state,
        supportTickets: {
          ...state.supportTickets,
          ...{ ticketInfo: {} },
        },
      };

    default:
      return state;
  }
};

export default customerSupportReducer;
