import {
  Row,
  Col,
  Space,
  Typography,
  Result,
  Image,
  Button,
  Layout,
} from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { channels } from "../../constants/enums";
import BotSettingsMenu from "./BotSettingsMenu";

const { Text } = Typography;

const BotLINE = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {botToBeEdited.bot_channel_info.TEAMS ? (
            <Row gutter={[0, 24]}>
              <Col span={24}>Microsoft Teams Details goes here</Col>
            </Row>
          ) : (
            <Result
              icon={
                <Image src={channels["LINE"].logo} preview={false} width={75} />
              }
              style={{ width: 700 }}
              title={channels["LINE"].displayName}
              extra={
                <Space direction="vertical" size={20}>
                  <Text type="secondary">
                    A service which allows businesses to contact users while
                    blending in to their daily lives just like using LINE to
                    communicate with friends and family.
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_SEND_REQUEST",
                        payload: {
                          channel: "LINE",
                        },
                      });
                    }}
                  >
                    Request to enable {channels["LINE"].displayName}
                  </Button>
                </Space>
              }
            />
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotLINE;
