import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Steps,
  Tooltip,
  Typography,
} from "antd";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { FiExternalLink } from "react-icons/fi";
import { TbBrandOauth } from "react-icons/tb";
import { URIs } from "../../../../constants/env";

const { Text } = Typography;

const OAuthRefreshToken = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const current = 0;

  let appRegistrationValues =
    state.currentAccount.account.app_registration || {};

  const steps = [
    {
      title: "Authorization Code",
      content: <></>,
      icon: <TbBrandOauth size="20" />,
    },
  ];

  const handleClick = (href, e) => {
    const url = href;
    const width = 600;
    const height = 500;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const popup = window.open(
      url,
      "_blank",
      `width=${width},height=${height},left=${left},top=${top}`
    );
    if (url.includes("?code=")) {
      const code = url.split("?code=")[1];
      popup.close();
    }
  };

  return (
    <Modal
      title={
        <Text strong>
          {state.formPanel["oauth-refresh-token-form-panel"] &&
            state.formPanel["oauth-refresh-token-form-panel"].title}
        </Text>
      }
      open={
        state.formPanel["oauth-refresh-token-form-panel"] &&
        state.formPanel["oauth-refresh-token-form-panel"].visible
      }
      footer={null}
      onCancel={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "oauth-refresh-token-form-panel",
          },
        });
      }}
      width={550}
    >
      <Row gutter={[0, 10]}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          span={24}
        >
          <Steps
            size="small"
            direction="horizontal"
            current={current}
            items={steps}
          />
        </Col>
        {current === 0 && (
          <Col span={24}>
            <Space
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Text style={{ fontSize: "12px" }}>
                Generate authorization code is required to get the refresh token
                and access token. You may refer to the following guide for more
                information
                <br /> <a href="#">view app-registration guide</a>.
              </Text>

              <Text style={{ fontSize: "12px" }}>
                Generate authorization code -{" "}
                <Tooltip title="Link to generate the authorization code">
                  <button
                    style={{
                      textDecoration: "none",
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                      color: "#F05742",
                    }}
                    onClick={(e) =>
                      handleClick(
                        `https://inextlabsidentity.b2clogin.com/inextlabsidentity.onmicrosoft.com/b2c_1_signin/oauth2/v2.0/authorize?client_id=${appRegistrationValues.AD_B2C_CLIENT_ID}&response_type=code&scope=https://inextlabsidentity.onmicrosoft.comnode-api/iNextIdentityNodeAPI.Read%20offline_access%20openid&redirect_uri=${URIs.API_NODE}/app/registration/redirect/${state.currentAccount.account.client_id}/`,
                        e
                      )
                    }
                  >
                    Click Here{" "}
                    <FiExternalLink
                      style={{ marginBottom: "-2px" }}
                      fontSize="16px"
                      color="#F05742"
                    />
                  </button>
                </Tooltip>
              </Text>
            </Space>
          </Col>
        )}
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          {current === 0 && (
            <Button
              style={{ height: "30px", border: "1px solid red", width: "80px" }}
              type="primary"
              htmlType="submit"
              danger
              size="small"
              onClick={() => {
                dispatchReducer({
                  type: "CLOSE_FORM_PANEL",
                  payload: {
                    component: "oauth-refresh-token-form-panel",
                  },
                });
              }}
            >
              Close{" "}
            </Button>
          )}
        </Col>
      </Row>
    </Modal>
  );
};
export default OAuthRefreshToken;
