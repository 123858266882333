import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Space,
  Image,
  Typography,
  Divider,
  Card,
  Input,
  Layout,
  Tooltip,
  Tabs,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { TbApps } from "react-icons/tb";
import { useGlobalContext } from "../../../contexts/globalContext";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { MdContentCopy } from "react-icons/md";
import openNotificationWithIcon from "../../../utils/notification";
import { URIs } from "../../../constants/env";
import {
  ComponentLoader,
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { appInsights } from "../../../AppInsights";
import AccessDenied from "../../../shared-components/AccessDenied";
import ModuleNotSubscribed from "../../../shared-components/ModuleNotSubscribed";
import { Translation } from "../../../translation/translation";
import AppRegistration from "./app-registration/AppRegistration";

import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const AccountAPI = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  // Get translated text
  const api = Translation().accountSettings.api;
  const navigate = useNavigate();

  useConnectMiddleware(() => {
    state.currentAccount.account.modules.api &&
      state.currentUser.permission.ACCOUNT_API_VIEW &&
      dispatchMiddleware({
        type: "MWD_ACCOUNT_API_GET_API_KEY",
        payload: { component: "get-api-key" },
      });
  });

  const [masked, setMasked] = useState(true);

  let maskedContent = "";
  state.currentAccount.apiKey &&
    [...state.currentAccount.apiKey].forEach(
      () => (maskedContent = maskedContent.concat("*"))
    );

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      <Tabs
        type="card"
        items={[
          {
            key: "1",
            label: (
              <Space size={15}>
                <ApiOutlined size={18} style={{ margin: -4 }} />
                API
              </Space>
            ),
            children: state.currentAccount.account.modules.api ? (
              state.currentUser.permission.ACCOUNT_API_VIEW ? (
                state.currentAccount.apiKey ? (
                  <Layout.Content
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 450,
                    }}
                  >
                    <Row gutter={[0, 10]} justify="center">
                      <Col span={18}>
                        <Card type="inner" bordered={false}>
                          <Row gutter={15}>
                            <Col
                              span={15}
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Row gutter={[0, 16]}>
                                <Col span={24}>
                                  <Title level={3} style={{ margin: 0 }}>
                                    API Key
                                  </Title>
                                </Col>
                                <Col span={24}>
                                  <Paragraph>
                                    Use your API key to integrate iNextLabs with
                                    your existing system and access the features
                                    that provide in the portal.
                                  </Paragraph>
                                  <Paragraph>
                                    To consume the published APIs must include a
                                    valid subscription key in HTTP requests when
                                    calling the APIs.
                                  </Paragraph>
                                  <Paragraph>
                                    Each key is specific to an account, not an
                                    individual user, and only one key is allowed
                                    at a time.
                                  </Paragraph>

                                  <Tooltip
                                    placement="top"
                                    title={
                                      state.currentUser.permission
                                        .ACCOUNT_API_DOCUMENTATION_VIEW
                                        ? "Access our API Documentation"
                                        : "You do not have required permission"
                                    }
                                  >
                                    <Paragraph>
                                      {state.currentUser.permission
                                        .ACCOUNT_API_DOCUMENTATION_VIEW ? (
                                        <Link
                                          to={URIs.API_DOCUMENTATION}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Access our API Documentation
                                        </Link>
                                      ) : (
                                        <Link
                                          style={{
                                            color: "#C0C0C0",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          Access our API Documentation
                                        </Link>
                                      )}
                                    </Paragraph>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={1}>
                              <Divider
                                type="vertical"
                                style={{ height: "100%" }}
                              />
                            </Col>
                            <Col span={8}>
                              <Image
                                width={300}
                                preview={false}
                                src="/images/account-api.png"
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={18}>
                        <Card type="inner" bordered={true}>
                          <Space
                            style={{
                              width: "100%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {state.currentAccount.apiKey ? (
                              <>
                                <Space size={24}>
                                  <Text strong>Active API Key:</Text>
                                  {masked ? (
                                    <>
                                      <Input
                                        value={maskedContent}
                                        disabled
                                        style={{ width: 300 }}
                                      />
                                      {state.currentUser.permission
                                        .ACCOUNT_API_KEY_VIEW && (
                                        <Tooltip
                                          placement="top"
                                          title="Show API Key"
                                        >
                                          <EyeOutlined
                                            onClick={() => setMasked(false)}
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Input
                                        disabled
                                        value={state.currentAccount.apiKey}
                                        style={{ width: 300 }}
                                      />
                                      {state.currentUser.permission
                                        .ACCOUNT_API_KEY_VIEW && (
                                        <Tooltip
                                          placement="top"
                                          title="Mask API Key"
                                        >
                                          <EyeInvisibleOutlined
                                            onClick={() => setMasked(true)}
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                  <Tooltip
                                    placement="top"
                                    title={
                                      state.currentUser.permission
                                        .ACCOUNT_API_KEY_COPY
                                        ? "Copy API Key to clipboard"
                                        : "You do not have required permission"
                                    }
                                  >
                                    {state.currentUser.permission
                                      .ACCOUNT_API_KEY_COPY ? (
                                      <MdContentCopy
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: -2,
                                          marginLeft: -3,
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            state.currentAccount.apiKey
                                          );
                                          openNotificationWithIcon(
                                            "success",
                                            "API Key copied to clipboard"
                                          );
                                        }}
                                      />
                                    ) : (
                                      <MdContentCopy
                                        style={{
                                          color: "#C0C0C0",
                                          marginBottom: -2,
                                          marginLeft: -3,
                                        }}
                                      />
                                    )}
                                  </Tooltip>
                                </Space>

                                <Divider
                                  type="vertical"
                                  style={{ height: 30 }}
                                />

                                {state.apiCallStatus["regenerate-api-key"] &&
                                state.apiCallStatus["regenerate-api-key"]
                                  .loading ? (
                                  <ComponentLoader
                                    component={"regenerate-api-key"}
                                    loadingMessage="Re-generating..."
                                    errorMessage=""
                                  />
                                ) : (
                                  <>
                                    {state.currentAccount.apiKey && (
                                      <Tooltip
                                        placement="top"
                                        title={
                                          state.currentUser.permission
                                            .ACCOUNT_API_REGENERATE_API_KEY
                                            ? "Re-generate API Key"
                                            : "You do not have required permission"
                                        }
                                      >
                                        <Button
                                          type="primary"
                                          disabled={
                                            !state.currentUser.permission
                                              .ACCOUNT_API_REGENERATE_API_KEY
                                          }
                                          onClick={() => {
                                            appInsights.trackEvent({
                                              name: "Re-generate API Key",
                                              properties: {
                                                clientId:
                                                  state.currentAccount.account
                                                    .client_id,
                                              },
                                            });
                                            dispatchMiddleware({
                                              type: "MWD_ACCOUNT_API_REGENERATE_API_KEY",
                                              payload: {
                                                component: "regenerate-api-key",
                                                client_id:
                                                  state.currentAccount.account
                                                    .client_id,
                                              },
                                            });
                                          }}
                                        >
                                          Re-generate API Key
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {state.apiCallStatus["generate-api-key"] &&
                                state.apiCallStatus["generate-api-key"]
                                  .loading ? (
                                  <ComponentLoader
                                    component={"generate-api-key"}
                                    loadingMessage="Generating..."
                                    errorMessage=""
                                  />
                                ) : (
                                  <>
                                    {!state.currentAccount.apiKey && (
                                      <Tooltip
                                        placement="top"
                                        title={
                                          state.currentUser.permission
                                            .ACCOUNT_API_GENERATE_API_KEY
                                            ? "Generate API Key"
                                            : "You do not have required permission"
                                        }
                                      >
                                        <Button
                                          type="primary"
                                          disabled={
                                            !state.currentUser.permission
                                              .ACCOUNT_API_GENERATE_API_KEY
                                          }
                                          onClick={() => {
                                            appInsights.trackEvent({
                                              name: "Generate API Key",
                                              properties: {
                                                clientId:
                                                  state.currentAccount.account
                                                    .client_id,
                                              },
                                            });
                                            dispatchMiddleware({
                                              type: "MWD_ACCOUNT_API_GENERATE_API_KEY",
                                              payload: {
                                                component: "generate-api-key",
                                                client_id:
                                                  state.currentAccount.account
                                                    .client_id,
                                              },
                                            });
                                          }}
                                        >
                                          Generate API Key
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Space>
                        </Card>
                      </Col>
                    </Row>
                  </Layout.Content>
                ) : (
                  <FullPageLoader
                    component="get-api-key"
                    loadingMessage="Loading... Please wait..."
                  />
                )
              ) : (
                <AccessDenied />
              )
            ) : (
              <Layout.Content
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ModuleNotSubscribed module="api" />
              </Layout.Content>
            ),
          },
          {
            key: "2",
            label: (
              <Space size={15}>
                <TbApps size={18} style={{ margin: -4 }} />
                {api.appRegistration.title}
              </Space>
            ),
            children: <AppRegistration />,
          },
        ]}
        tabBarExtraContent={
          state.apiCallStatus["get-api-key"] &&
          state.apiCallStatus["get-api-key"].loading && (
            <TabLoader
              component="get-api-key"
              loadingMessage="Synchronizing API key..."
              errorMessage="Error synchronizing API key"
            />
          )
        }
      />
    </>
  );
};

export default AccountAPI;
