import { Drawer } from "antd";
import UnrecognizedIntentsForm from "./UnrecognizedIntentsForm";
import { useGlobalContext } from "../../../contexts/globalContext";

const UnrecognizedIntentsFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Drawer
      title={state.formPanel["unrecognized-intents-form-panel"]?.title}
      width={550}
      destroyOnClose={true}
      open={state.formPanel["unrecognized-intents-form-panel"]?.visible}
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "unrecognized-intents-form-panel",
          },
        });
        dispatchReducer({
          type: "SET_FORM_MODE",
          payload: {
            component: "unrecognized-intents-form",
            mode: "",
          },
        });
      }}
    >
      <UnrecognizedIntentsForm />
    </Drawer>
  );
};

export default UnrecognizedIntentsFormPanel;
