import { useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import { Tabs, Space } from "antd";
import { HiOutlineTemplate } from "react-icons/hi";
import { AlertOutlined } from "@ant-design/icons";
import { TiFlowSwitch } from "react-icons/ti";
import { TbDatabaseSearch } from "react-icons/tb";
import { LiaToolsSolid } from "react-icons/lia";
import MessageTemplates from "./message-templates/MessageTemplates";
import { TabLoader } from "../../shared-components/LoadingIndicator";
import AccessDenied from "../../shared-components/AccessDenied";
import UnrecognizedIntents from "./unrecognized-intents/UnrecognizedIntents";
import { mode } from "../../constants/env";
import BotKnowledgebase from "./bot-knowledgebase/BotKnowledgebase";
import WhatsAppFlows from "./whatsapp-flows/WhatsAppFlows";
import BotConfiguration from "./bot-configuration/BotConfiguration";

const KnowledgeArea = (props) => {
  const [state] = useGlobalContext();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);

  const tabPane = [
    {
      key: "1",
      label: (
        <Space size={15}>
          <HiOutlineTemplate size={18} style={{ margin: -4 }} />
          Message Templates
        </Space>
      ),
      children: <MessageTemplates />,
    },
    {
      key: "2",
      label: (
        <Space size={15}>
          <AlertOutlined style={{ fontSize: "18px", margin: -4 }} />
          {/* Changed Unrecognized Intents to Unanswered Questions
          Need to update it in frontend, backend, bot side and database
           */}
          Unanswered Questions
        </Space>
      ),
      children: <UnrecognizedIntents />,
    },
    {
      key: "3",
      label: (
        <Space size={15}>
          <TbDatabaseSearch style={{ fontSize: "18px", margin: -4 }} />
          Bot Knowledgebase
        </Space>
      ),
      children: <BotKnowledgebase />,
    },
    {
      key: "4",
      label: (
        <Space size={15}>
          <TiFlowSwitch style={{ fontSize: "18px", margin: -4 }} />
          WhatsApp Flows
        </Space>
      ),
      children: <WhatsAppFlows />,
    },
    ["DEV", "STAGING"].includes(mode) && {
      key: "5",
      label: (
        <Space size={15}>
          <LiaToolsSolid style={{ fontSize: "18px", margin: -4 }} />
          Bot Editor
        </Space>
      ),
      children: <BotConfiguration />,
    },
  ];

  const handleTabChange = (key) => {
    switch (key) {
      case "1":
        navigate("/knowledge-area/message-templates");
        break;
      case "2":
        navigate("/knowlege-area/unrecognized-intents");
        break;
      case "3":
        navigate("/knowlege-area/bot-knowledgebase");
        break;
      case "4":
        navigate("/knowlege-area/whatsapp-flows");
        break;
      case "5":
        navigate("/knowlege-area/bot-configuration");
        break;
      default:
        navigate("/knowledge-area/message-templates");
    }
  };

  useEffect(() => {
    setActiveTab(props.tab.toString());
  }, [props]);

  return !state.currentUser.permission.BOT_KNOWLEDGE_AREA_VIEW ? (
    <AccessDenied />
  ) : (
    <Tabs
      items={tabPane}
      onChange={handleTabChange}
      destroyInactiveTabPane={true}
      type="card"
      activeKey={activeTab}
      tabBarExtraContent={
        <Space size={20}>
          {activeTab === "1" &&
            state.apiCallStatus["message-templates-table"] &&
            state.apiCallStatus["message-templates-table"].loading && (
              <TabLoader
                component="message-templates-table"
                loadingMessage="Synchronizing templates..."
                errorMessage="Error synchronizing templates"
              />
            )}

          {activeTab === "2" &&
            state.apiCallStatus["unrecognized-intents-table"] &&
            state.apiCallStatus["unrecognized-intents-table"].loading && (
              <TabLoader
                component="unrecognized-intents-table"
                loadingMessage="Synchronizing unanswered questions..."
                errorMessage="Error synchronizing unanswered questions"
              />
            )}

          {activeTab === "3" &&
            state.apiCallStatus["knowledgebase-table"] &&
            state.apiCallStatus["knowledgebase-table"].loading && (
              <TabLoader
                component="knowledgebase-table"
                loadingMessage="Synchronizing knowledgebase..."
                errorMessage="Error synchronizing knowledgebase"
              />
            )}

          {activeTab === "4" &&
            state.apiCallStatus["whatsapp-flows-table"] &&
            state.apiCallStatus["whatsapp-flows-table"].loading && (
              <TabLoader
                component="whatsapp-flows-table"
                loadingMessage="Synchronizing whatsapp flows..."
                errorMessage="Error synchronizing whatsapp flows"
              />
            )}
        </Space>
      }
    />
  );
};

export default KnowledgeArea;
