import React, { useEffect, useState, useRef } from "react";
import { Bars } from "react-loader-spinner";
import { useGlobalContext } from "../contexts/globalContext";
import { Space, Layout, Typography, Spin, Button } from "antd";
import { VscError } from "react-icons/vsc";
import { TiDelete } from "react-icons/ti";
import { TbRobot } from "react-icons/tb";
import { useMsal } from "@azure/msal-react";

const { Text } = Typography;

export const FullPageLoader = ({
  component,
  loadingMessage,
  showLogoutLink,
}) => {
  const [state] = useGlobalContext();
  const { instance } = useMsal();

  return (
    <>
      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].loading && (
          <Layout.Content
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Bars
              heigth="35"
              width="35"
              color="#F05742"
              ariaLabel="loading-indicator"
            />
            <Space style={{ color: "#F05742" }}>{loadingMessage}</Space>
          </Layout.Content>
        )}

      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].error && (
          <>
            <Layout.Content
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <VscError size="3.5em" color="#F05742" />
              <Space style={{ color: "#F05742", marginTop: 20 }}>Oops!</Space>
              <Space style={{ color: "#F05742" }}>
                Something went wrong. Please check back after sometime.
              </Space>
              <Space style={{ color: "#F05742" }}>
                We apologies for the inconvenience caused.
              </Space>
              {showLogoutLink && (
                <Space style={{ color: "#F05742", marginTop: 100 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      sessionStorage.removeItem("selectedMenu");
                      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                    }}
                  >
                    Try login as another user
                  </Button>
                </Space>
              )}
            </Layout.Content>
          </>
        )}
    </>
  );
};

export const TabLoader = ({ component, loadingMessage, errorMessage }) => {
  const [state] = useGlobalContext();

  return (
    <>
      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].loading && (
          <Space
            style={{ width: "100%", justifyContent: "center", padding: 10 }}
          >
            <Spin size="small" />
            <Text strong="true">{loadingMessage}</Text>
          </Space>
        )}

      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].error && (
          <>
            <Space
              style={{ width: "100%", justifyContent: "center", padding: 10 }}
            >
              <TiDelete size={24} style={{ margin: -7, color: "red" }} />
              <Text strong="true">{errorMessage}</Text>
            </Space>
          </>
        )}
    </>
  );
};

export const ComponentLoader = ({ component, loadingMessage }) => {
  const [state] = useGlobalContext();

  return (
    <>
      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].loading && (
          <Space>
            <Spin size="small" />
            {loadingMessage && <Text strong="true">{loadingMessage}</Text>}
          </Space>
        )}
    </>
  );
};

export const AiLoader = ({ component, loadingMessage, errorMessage }) => {
  const [state] = useGlobalContext();
  const [dotDotText, setDotDotText] = useState("");
  const [rotation, setRotation] = useState(0);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const rotationTime = 50; // 0.05 seconds
    const dotsUpdateTime = 500; // 0.5 seconds

    const updateRotation = () => {
      setRotation((prevRotation) => {
        const newRotation = prevRotation + 1;

        // Reset rotation to 0 after a full clockwise rotation (360 degrees)
        return newRotation % 360;
      });

      timeoutIdRef.current = setTimeout(updateRotation, rotationTime);
    };

    const updateText = () => {
      setDotDotText((prevText) =>
        prevText.length >= 3 ? "." : prevText + "."
      );
      timeoutIdRef.current = setTimeout(updateText, dotsUpdateTime);
    };

    // Start updating rotation and text
    updateRotation();
    updateText();

    // Cleanup function to clear timeouts when the component unmounts
    return () => clearTimeout(timeoutIdRef.current);
  }, []); // This effect runs only on mount

  // Check if the API response has been received
  const apiResponseReceived =
    state.apiCallStatus[component] && !state.apiCallStatus[component].loading;

  useEffect(() => {
    // Stop updating rotation and text when API response is received
    if (apiResponseReceived) {
      clearTimeout(timeoutIdRef.current);
      setRotation(0);
    }
  }, [apiResponseReceived]);

  return (
    <>
      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].loading && (
          <Space>
            {rotation >= 357 && rotation <= 359 ? (
              <TbRobot
                size={23}
                style={{
                  color: "#F05742",
                  marginTop: "7px",
                }}
              />
            ) : (
              <TbRobot
                size={23}
                style={{
                  color: "#F05742",
                  marginTop: "7px",
                  transform: `rotate(${rotation}deg)`,
                  transition: "transform 0.05s linear, all 0.05s linear",
                }}
              />
            )}

            <Text strong>
              {loadingMessage}
              <Text
                style={{
                  fontSize: 20,
                }}
              >
                {dotDotText}
              </Text>
            </Text>
          </Space>
        )}

      {state.apiCallStatus[component] &&
        state.apiCallStatus[component].error && (
          <>
            <Space
              style={{ width: "100%", justifyContent: "center", padding: 10 }}
            >
              <TiDelete size={24} style={{ margin: -7, color: "red" }} />
              <Text strong="true">{errorMessage}</Text>
            </Space>
          </>
        )}
    </>
  );
};
