import { useGlobalContext } from "../../../contexts/globalContext";
import { Drawer } from "antd";
import AccountUsersForm from "./AccountUsersForm";

const AccountUsersFormPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Drawer
      width={720}
      destroyOnClose={true}
      title={
        state.formPanel["account-user-form-panel"] &&
        state.formPanel["account-user-form-panel"].title
      }
      open={
        state.formPanel["account-user-form-panel"] &&
        state.formPanel["account-user-form-panel"].visible
      }
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "account-user-form-panel",
          },
        });
        dispatchReducer({
          type: "SET_FORM_MODE",
          payload: {
            component: "account-user-form",
            mode: "",
            title: "",
          },
        });
        dispatchReducer({
          type: "RD_CLEAR_ACTION_ROW_KEY",
          payload: { component: "account-user-table" },
        });
      }}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <AccountUsersForm />
    </Drawer>
  );
};

export default AccountUsersFormPanel;
