import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import useContactManagerTableColumns from "./ContactManagerTableColumns";
import { useWindowSize } from "../../shared-components/PageReSize";
import ResizeableTableColumn from "../../shared-components/ResizeableTableColumn";

const ContactManagerTable = ({ type }) => {
  const [state, dispatchReducer] = useGlobalContext();
  const [pageSize, setPageSize] = useState();

  const { contactManagerDataColumns, contactManagerActionColumns } =
    useContactManagerTableColumns();
  const [height] = useWindowSize();

  const [columns, setColumns] = useState(
    contactManagerDataColumns.concat(contactManagerActionColumns)
  );
  let enums = state.application.enums?.contacts?.data || [];

  const searchText =
    state.filters.searchText["contact-manager-search-text-box"] &&
    state.filters.searchText["contact-manager-search-text-box"];

  // Filter contacts based on the filter type
  const contactFilter = state.contactManagerSettings.contactFilter || {
    type: "none",
  };

  let filteredContacts = state.contactManager.contacts.filter((contact) => {
    const contactValue = contact[contactFilter?.value?.columnName] || "";
    const currentUserValue =
      state.accountUsers.users?.find(
        (user) => user._id === state.currentUser.user._id
      )?.[contactFilter?.value?.columnValue] || "";

    return state.currentUser.user.role.includes(
      state.application.applicationRoles.find(
        (role) => role.name?.toLowerCase() === "admin"
      )?._id
    )
      ? true
      : contactFilter.type === "none"
      ? true
      : contactFilter.type === "createdBy"
      ? contact.createdBy === state.currentUser.user._id
      : contactFilter.type === "byColumnName/Value" &&
        contactFilter.value?.filterType === "equals"
      ? contactValue === currentUserValue
      : contactFilter.value?.filterType === "notEquals"
      ? contactValue !== currentUserValue
      : contactFilter.value?.filterType === "contains" &&
        contactValue?.includes(currentUserValue);
  });

  // Search contacts based on the search text
  if (searchText) {
    filteredContacts = filteredContacts.filter((contact) => {
      return (
        // dynamic search across all columns
        Object.keys(contact).some((key) => {
          return (
            contact[key] &&
            contact[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      );
    });
  }

  // Resize table column
  const handleColumnResize =
    (index) =>
    (_, { size }) => {
      const updatedColumn = [...columns];
      updatedColumn[index] = {
        ...updatedColumn[index],
        width: size.width,
      };
      setColumns(updatedColumn);
    };

  const updatedColumns = columns.map((col, index) => ({
    ...col,
    ...{ filteredValue: contactManagerDataColumns[index]?.filteredValue },
    onHeaderCell: (column) => ({
      // round the column width to the nearest integer
      width: Math.round(column.width),
      onResize: handleColumnResize(index),
      module: "contacts",
    }),
  }));

  // Update the column width to enum
  enums.map((enumProperty) => {
    updatedColumns.forEach(({ key, width }) => {
      if (key === enumProperty._id) {
        enumProperty.width = width;
      }
    });
    return enumProperty;
  });

  //Clear the selected row keys when switching tabs
  useEffect(() => {
    return () => {
      dispatchReducer({
        type: "RD_CLEAR_SELECTED_ROW_KEYS",
        payload: { component: "contact-manager-table" },
      });
    };
  }, []);

  return (
    <Table
      dataSource={filteredContacts}
      // Updated columns used for resizing
      columns={
        type && type === "standard" ? updatedColumns : contactManagerDataColumns
      }
      loading={
        state.apiCallStatus["client-response-table"] &&
        state.apiCallStatus["client-response-table"].loading
      }
      onChange={(sorter, filters) => {
        dispatchReducer({
          type: "RD_SET_SORTED_INFO",
          payload: {
            component: "contact-manager-table",
            sortedInfo: sorter,
          },
        });
        dispatchReducer({
          type: "RD_SET_FILTERED_INFO",
          payload: {
            component: "contact-manager-table",
            filteredInfo: filters,
          },
        });
      }}
      rowKey="_id"
      showSorterTooltip={false}
      rowSelection={{
        selectedRowKeys:
          state.filters.selectedRowKeys["contact-manager-table"] &&
          state.filters.selectedRowKeys["contact-manager-table"],

        getCheckboxProps: (record) => {
          return {
            disabled: type && type === "filter" && record.isUnsubscribed,
          };
        },
        onChange: (selected) => {
          dispatchReducer({
            type: "RD_SET_SELECTED_ROW_KEYS",
            payload: {
              component: "contact-manager-table",
              selectedRowKeys: selected,
            },
          });
        },
      }}
      pagination={
        type && type === "standard"
          ? {
              pageSize: pageSize ?? Math.round((height - 276) / 45),
              showTotal: () => "Total : " + filteredContacts.length,
              showSizeChanger: filteredContacts.length > 20 ? true : false,
              pageSizeOptions: ["10", "20", "50", "100"],
              onShowSizeChange: (_, size) => {
                setPageSize(size);
              },
            }
          : false
      }
      onRow={(selectedContact) => {
        if (type && type === "standard") {
          return {
            onClick: () => {
              dispatchReducer({
                type: "OPEN_INFO_PANEL",
                payload: {
                  component: "contact-manager-info-panel",
                },
              });

              dispatchReducer({
                type: "RD_SET_ACTION_ROW_KEY",
                payload: {
                  component: "contact-manager-table",
                  actionRowKey: selectedContact._id,
                },
              });
            },
            onMouseEnter: (e) => {
              Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
                (cell) => {
                  cell.style.backgroundColor = "#f5f5f5";
                }
              );
            },
            onMouseLeave: (e) => {
              Array.from(e.currentTarget.getElementsByTagName("td")).forEach(
                (cell) => {
                  cell.style.backgroundColor = "";
                }
              );
            },
            style: { cursor: "pointer" },
          };
        }
      }}
      components={{
        header: {
          cell: ResizeableTableColumn,
        },
      }}
    />
  );
};

export default ContactManagerTable;
