import React from "react";
import { Typography, Card, Row, Col, Image } from "antd";
import { QuestionCircleOutlined, RocketOutlined } from "@ant-design/icons";
import { URIs } from "../../constants/env";
import { userIcons } from "../../constants/enums";
import { useGlobalContext } from "../../contexts/globalContext";

const { Text, Link, Title } = Typography;

const AssociateContacts = ({ setAddToExistContact, setAddToContact }) => {
  const [, dispatchReducer] = useGlobalContext();

  return (
    <>
      <Row style={{ marginTop: 10 }} gutter={[8, 20]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Title level={4}> What would you like to do?</Title>
        </Col>
        <Col span={12}>
          <Card
            hoverable
            style={{
              border: "2px solid #f3eeee",
              borderRadius: "5px",
              height: 230,
              width: 300,
            }}
            onClick={() => setAddToContact(true)}
          >
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Image
                  src={userIcons.addUser}
                  preview={false}
                  height={75}
                  width={75}
                />
              </Col>
              <Col span={24} style={{ textAlign: "center", marginTop: 10 }}>
                <Text strong>Create New Contact</Text>
              </Col>
              <Col span={24} style={{ marginTop: 12, textAlign: "center" }}>
                <Text>
                  Add a new contact in your address book and associate this user
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            hoverable
            style={{
              border: "2px solid #f3eeee",
              borderRadius: "5px",
              height: 230,
              width: 300,
            }}
            onClick={() => {
              setAddToExistContact(true);
              //PUT method called in ContactManagerForm
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "contact-manager-form",
                  mode: "edit",
                },
              });
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Image
                  src={userIcons.updateUser}
                  preview={false}
                  height={75}
                  width={75}
                />
              </Col>
              <Col span={24} style={{ textAlign: "center", marginTop: 10 }}>
                <Text strong>Add to Existing Contact</Text>
              </Col>
              <Col span={24} style={{ marginTop: 12, textAlign: "center" }}>
                <Text>
                  Associate this user to existing contact in the address book
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col
          span={24}
          justify="center"
          align="middle"
          style={{ marginTop: 250 }}
        >
          <Text> Need help getting started?</Text>
          <RocketOutlined style={{ marginLeft: 5, color: "#fc846f" }} />
        </Col>

        <Col span={24} style={{ textAlign: "center" }}>
          <Link
            href={URIs.DOCUMENTATIONS + "/docs/contacts-overview"}
            target="_blank"
          >
            View contact guide
            <QuestionCircleOutlined
              style={{ color: "#fc846f", marginLeft: 5 }}
            />
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default AssociateContacts;
