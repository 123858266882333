const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_MODE":
      return {
        ...state,
        form: {
          ...state.form,
          ...{
            [action.payload.component]: {
              mode: action.payload.mode,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default formReducer;
