import { useNavigate } from "react-router-dom";
import { Row, Col, Space } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const AccountSettings = () => {
  const navigate = useNavigate();
  const selectedMenu = sessionStorage.getItem("selectedMenu");
  return (
    <Row>
      <Col span={3}>
        <Space size={15}>
          <SettingOutlined
            style={{
              fontSize: 20,
              color: selectedMenu === "account-settings" ? "#F05742" : "#000",
              margin: "5px 0px 0px 5px",
              padding: 15,
            }}
            onClick={() => {
              navigate("/account-settings");
              sessionStorage.setItem("selectedMenu", "account-settings");
            }}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default AccountSettings;
