import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Col, Space, Input, Button, Tooltip } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { appInsights } from "../../../AppInsights";

const CannedResponsesHeader = ({ type }) => {
  const [state, dispatchReducer] = useGlobalContext();
  return (
    <Row>
      <Col span={18}>
        <Space>
          <Input
            disabled={
              state.cannedResponses.liveAgentCannedResponses.length === 0
            }
            value={
              state.filters.searchText["canned-responses-search-text-box"] &&
              state.filters.searchText["canned-responses-search-text-box"]
            }
            prefix={<SearchOutlined />}
            placeholder="Search by responses title & message"
            style={{ width: 400 }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "canned-responses-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
          />
        </Space>
      </Col>

      {type !== "cannedResponse" && (
        <Col span={6} style={{ textAlign: "right" }}>
          <Tooltip
            placement="topRight"
            title={
              state.currentUser.permission.CANNED_RESPONSES_CREATE
                ? "Add Canned Response"
                : "You do not have required permission"
            }
          >
            <Button
              type="primary"
              disabled={!state.currentUser.permission.CANNED_RESPONSES_CREATE}
              icon={<PlusOutlined />}
              onClick={() => {
                appInsights.trackEvent({
                  name: "Add Canned Response",
                  properties: {
                    clientId: state.currentAccount.account.client_id,
                  },
                });
                dispatchReducer({
                  type: "OPEN_FORM_PANEL",
                  payload: {
                    component: "canned-responses-form-panel",
                    title: "Add Canned Response",
                  },
                });
                dispatchReducer({
                  type: "SET_FORM_MODE",
                  payload: {
                    component: "canned-responses-form",
                    mode: "add",
                    title: "Add Canned Response",
                  },
                });
              }}
            >
              Add Canned Response
            </Button>
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

export default CannedResponsesHeader;
