import { useNavigate } from "react-router-dom";
import { Row, Col, Tabs, Space } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import AccountContactsForm from "./AccountContactsForm";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { AiOutlineArrowLeft, AiOutlineContacts } from "react-icons/ai";
import "./AccountContacts.less";

const AccountContacts = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_CONTACT_MANAGER_DATA",
      payload: {
        enums: state.application.enums?.contacts?.data,
        component: "contact-settings",
      },
    });

    !state.accountUsers.users &&
      dispatchMiddleware({
        type: "MWD_LOAD_ACCOUNT_USERS_DATA",
        payload: { component: "account-users-table" },
      });
  });

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      <Tabs
        type="card"
        items={[
          {
            key: "1",
            label: (
              <Space size={15}>
                <AiOutlineContacts size={18} style={{ margin: -4 }} />
                Contacts
              </Space>
            ),
            children: state.contactManagerSettings.tableColumns ? (
              <Row>
                <Col span={24}>
                  <AccountContactsForm />
                </Col>
              </Row>
            ) : (
              <FullPageLoader
                component="contact-settings"
                loadingMessage="Loading... Please wait..."
              />
            ),
          },
        ]}
        tabBarExtraContent={
          state.apiCallStatus["broadcast-settings"]?.loading && (
            <TabLoader
              component="broadcast-settings"
              loadingMessage="Synchronizing broadcast..."
              errorMessage="Error synchronizing broadcast"
            />
          )
        }
      />
    </>
  );
};

export default AccountContacts;
