import { Row, Col, Image, Typography } from "antd";

const { Text } = Typography;

const AccessDenied = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Row>
        <Col span={24}>
          <Image
            width={600}
            height={470}
            preview={false}
            src="/images/access-denied/access-denied.png"
          />
        </Col>

        <Col span={24}>
          <Text>
            You don’t have permissions to access the module. Please contact
          </Text>
        </Col>
        <Col span={24}>
          <Text>an administrator to get permissions</Text>
        </Col>
      </Row>
    </div>
  );
};

export default AccessDenied;
