import { Row, Col, Space, Typography } from "antd";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { useGlobalContext } from "../../contexts/globalContext";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { TinyArea } from "@ant-design/plots";
import { mode } from "../../constants/env";

const { Text, Title } = Typography;

const ConversationTotalCount = ({ period }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({
        type: "RD_SET_CONVERSATION_BY_DATE",
        payload: {
          conversationByDate: undefined,
        },
      });
      dispatchMiddleware({
        type: "MWD_LOAD_CONVERSATION_BY_DATE_DATA",
        payload: { component: "dashboard-conversation-by-date-chart" },
      });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  let conversationByDate = state.dashBoard.conversationByDate;

  // For demo account only showing dummy data
  if (
    mode === "STAGING" &&
    state.currentAccount.account.database === "inextlabs-demo"
  ) {
    conversationByDate = [
      0, 0, 70, 0, 0, 0, 10, 0, 150, 0, 60, 0, 0, 0, 120, 0, 1, 1, 85, 0, 0, 3,
      0, 0, 0, 97, 1, 0, 90, 3,
    ];
  }

  const config = {
    data: conversationByDate,
    height: 50,
    autoFit: true,
    smooth: true,
    line: {
      color: "#391085",
    },
    areaStyle: {
      fill: "#efdbff",
    },
  };

  return (
    <>
      {conversationByDate ? (
        <Row>
          <Col span={24}>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ textAlign: "center" }}>
                {["STANDARD", "STARTER", "ADVANTAGE"].includes(
                  botToBeEdited.subscription_package
                )
                  ? "TOTAL CONVERSATIONS"
                  : "CONVERSATIONS"}
              </Title>
              <Title level={1} style={{ textAlign: "center" }}>
                {conversationByDate?.reduce(
                  (previousMessageCount, currentMessageCount) =>
                    previousMessageCount + currentMessageCount,
                  0
                )}
              </Title>
            </Space>
          </Col>
          <Col span={24}>
            <TinyArea {...config} />
          </Col>
          <Col span={24}>
            <Text type="secondary" italic>
              last {period} days
            </Text>
          </Col>
        </Row>
      ) : (
        <ComponentLoader
          component="dashboard-conversation-by-date-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ConversationTotalCount;
