const accountUsersReducers = (state, action) => {
  switch (action.type) {
    case "SET_ACCOUNT_USERS":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            users: action.users,
          },
        },
      };

    case "ADD_ACCOUNT_USER":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{ users: [...state.accountUsers.users, action.user] },
        },
      };

    case "EDIT_ACCOUNT_USER":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            users: state.accountUsers.users.map((user) =>
              user.userID === action.user.userID
                ? Object.assign({}, user, action.user)
                : user
            ),
          },
        },
      };

    case "DELETE_SINGLE_ACCOUNT_USER":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            users: state.accountUsers.users.filter(
              (user) => action.actionRowKey !== user.userID
            ),
          },
        },
      };

    case "SET_ACCOUNT_USERS_FILTERED_INFO":
     return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            filters: {
              ...state.accountUsers.filters,
              ...{ filteredInfo: action.filteredInfo },
            },
          },
        },
      };
    
    case "SET_ACCOUNT_USERS_SORTED_INFO":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            filters: {
              ...state.accountUsers.filters,
                ...{ sortedInfo: action.sortedInfo },
            },
          },
        },
      };

    case "SET_ACCOUNT_USERS_ACTION_ROW_KEY":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            filters: {
              ...state.accountUsers.filters,
              ...{ actionRowKey: action.actionRowKey },
            },
          },
        },
      };

    case "CLEAR_ACCOUNT_USERS_ROW_KEYS":
      return {
        ...state,
        accountUsers: {
          ...state.accountUsers,
          ...{
            filters: {
              ...state.accountUsers.filters,
              ...{ actionRowKey: "", selectedRowKeys: [] },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default accountUsersReducers;
