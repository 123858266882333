import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Input,
  Form,
  Row,
  Col,
  Switch,
  Button,
  Divider,
  Space,
  Modal,
  Typography,
} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";

const { confirm } = Modal;

const AccountUsersForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  if (state.form["account-user-form"].mode === "edit") {
    const recordToBeEdited = state.accountUsers.users.find(
      (user) => user.userID === state.filters.actionRowKey["account-user-table"]
    );

    let formFieldsToBeEdited = {
      firstName: recordToBeEdited.firstName,
      lastName: recordToBeEdited.lastName,
      displayName: recordToBeEdited.displayName,
      email: recordToBeEdited.email,
    };

    state.application.applicationRoles.forEach((applicationRole) => {
      const userRoleId = recordToBeEdited.role.find(
        (userRoleId) => applicationRole._id === userRoleId
      );
      if (userRoleId) {
        formFieldsToBeEdited = Object.assign({}, formFieldsToBeEdited, {
          [userRoleId]: "checked",
        });
      }
    });

    form.setFieldsValue(formFieldsToBeEdited);
  }

  const handleFormSubmit = (e) => {
    let role = [];
    state.application.applicationRoles.map(
      (applicationRole) =>
        e[applicationRole._id] && role.push(applicationRole._id)
    );

    const formUserData = {
      firstName: e.firstName,
      lastName: e.lastName,
      displayName: e.displayName,
      email: e.email,
      role,
      timeZone: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
      dateFormat: "YYYY/MMMM/DD HH:mm:ss",
    };

    dispatchMiddleware({
      type: "MWD_ADD_EDIT_ACCOUNT_USER",
      payload: {
        user: formUserData,
        component: "account-user-form",
      },
    });

    const tags = [];
    state.application.applicationRoles.map(
      (applicationRole) =>
        e[applicationRole._id] && tags.push(applicationRole.name)
    );
    dispatchMiddleware({
      type: "SET_NOTIFICATION",
      payload: {
        newNotification: {
          userID: state.currentUser.user.userID,
          name: state.currentUser.user.displayName,
          email: state.currentUser.user.email,
          moduleType: "USERS",
          notificationTitle: `New user added !!!`,
          valuesChanged: {},
          isRead: false,
          createdAt: new Date(),
          notificationMessage: `User "${e.firstName}" (${e.email}) has been added by ${state.currentUser.user.displayName} (${state.currentUser.user.email})`,
          tags,
        },
      },
    });
  };

  const resetFormField = () => {
    dispatchReducer({
      type: "CLOSE_FORM_PANEL",
      payload: {
        component: "account-user-form-panel",
      },
    });
    dispatchReducer({
      type: "SET_FORM_MODE",
      payload: {
        component: "account-user-form",
        mode: "",
        title: "",
      },
    });
    dispatchReducer({
      type: "RD_CLEAR_ACTION_ROW_KEY",
      payload: { component: "account-user-table" },
    });
  };

  return (
    <>
      {state.apiCallStatus["account-user-form"] &&
      state.apiCallStatus["account-user-form"].loading ? (
        <FullPageLoader
          component="account-user-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleFormSubmit}
          form={form}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                  { whitespace: true },
                  {
                    min: 3,
                    message: "First name should be more then 3 characters",
                  },
                  {
                    max: 35,
                    message: "First name should not be more then 20 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the first name" />
              </Form.Item>

              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                  { whitespace: true },
                  {
                    min: 1,
                    message: "Last name should be more then 1 characters",
                  },
                  {
                    max: 25,
                    message: "Last name should not be more then 10 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the last name" />
              </Form.Item>

              <Form.Item
                name="displayName"
                label="Display Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter display name",
                  },
                  { whitespace: true },
                  {
                    min: 3,
                    message: "Display name should be more then 3 characters",
                  },
                  {
                    max: 30,
                    message:
                      "Display name should not be more then 20 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the last name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    type: "email",
                    message: "Email should be a valid email address",
                  },
                  {
                    max: 50,
                    message: "Email id should not more then 50 characters",
                  },
                ]}
                hasFeedback
              >
                <Input
                  disabled={state.form["account-user-form"].mode === "edit"}
                  placeholder="Enter the email"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Space style={{ paddingBottom: 10 }}>User Role</Space>
              <Space direction="vertical" size={20}>
                {state.application.applicationRoles.map(
                  (applicationRole, index) => {
                    return (
                      <Space
                        key={index}
                        size={15}
                        style={{ alignItems: "start" }}
                      >
                        <Space>
                          <Form.Item
                            key={index}
                            valuePropName="checked"
                            name={applicationRole._id}
                          >
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Space>
                        <Space direction="vertical" size={0}>
                          <Space>{applicationRole.name}</Space>
                          <Space style={{ fontStyle: "italic", fontSize: 12 }}>
                            {applicationRole.description}
                          </Space>
                        </Space>
                      </Space>
                    );
                  }
                )}
              </Space>
            </Col>
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={12}>
              <Space>
                <Form.Item>
                  <Button onClick={resetFormField}>Cancel</Button>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>

            <Col span={12} style={{ alignItems: "right" }}>
              {state.form["account-user-form"].mode === "edit" &&
                state.currentUser.permission.ACCOUNT_USER_DELETE &&
                form.getFieldValue("userID") !==
                  state.currentUser.user.userID && (
                  <Form.Item>
                    <Typography.Link
                      onClick={() => {
                        confirm({
                          title: "Are you sure you want to delete the user?",
                          content:
                            "When clicked the OK button, the user will be deleted permanently",
                          onOk() {
                            dispatchMiddleware({
                              type: "MWD_DELETE_SINGLE_ACCOUNT_USER",
                              payload: {
                                component:
                                  "account-user-" +
                                  state.filters.actionRowKey[
                                    "account-user-table"
                                  ] +
                                  "-delete",
                                actionRowKey:
                                  state.filters.actionRowKey[
                                    "account-user-table"
                                  ],
                              },
                            });

                            resetFormField();
                          },
                          onCancel() {},
                        });
                      }}
                      style={{ float: "right" }}
                    >
                      Delete this user
                    </Typography.Link>
                  </Form.Item>
                )}
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default AccountUsersForm;
