import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Space,
  Steps,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import { AiOutlineSelect } from "react-icons/ai";
import { BiCustomize } from "react-icons/bi";
import { MdOutlineDownloadDone } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import openNotificationWithIcon from "../../../utils/notification";

const { Text } = Typography;

////////////////       select template         //////////////

const SelectTemplate = ({ onSelectTemplate }) => {
  const templates = [
    {
      name: "blue-breeze",
      image1: "/images/webchat-templates/bluebreeze1.png",
      image2: "/images/webchat-templates/bluebreeze2.png",
    },
    {
      name: "green-stone",
      image1: "/images/webchat-templates/greenstone1.png",
      image2: "/images/webchat-templates/greenstone2.png",
    },
    {
      name: "neva",
      image1: "/images/webchat-templates/neva1.png",
      image2: "/images/webchat-templates/neva2.png",
    },
  ];

  return (
    <>
      <Row gutter={16}>
        {templates.map((template) => (
          <Col span={8} key={template.image1}>
            <Card>
              <Carousel>
                <div>
                  <Image src={template.image1} />
                </div>
                <div>
                  <Image src={template.image2} />
                </div>
              </Carousel>
            </Card>
          </Col>
        ))}
        <Col span={24}>
          <Radio.Group
            style={{ display: "flex", justifyContent: "space-around" }}
            onChange={(e) => onSelectTemplate(e.target.value)}
          >
            {templates.map((template) => (
              <Radio key={template.name} value={template.name}>
                {template.name}
              </Radio>
            ))}
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

//////////////        template customize      /////////////////

const CustomizeTemplateForm = ({ selectedTemplate, current, setCurrent }) => {
  const [imageUrl, setImageUrl] = useState({
    botAvatar: undefined,
    floatingIcon: undefined,
  });

  const [formEnabled, setFormEnabled] = useState(true);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleImageChange = (type) => (info) => {
    if (info.file) {
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl((prevImageUrl) => ({ ...prevImageUrl, [type]: url }));
      });
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          onFinish={(values) => {
            console.log(values);
            setCurrent(current + 1);
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="bot_name"
                label={<Text strong>Bot Name</Text>}
                rules={[
                  {
                    required: true,
                    message: "Please enter bot name!",
                  },
                ]}
              >
                <Input placeholder="Ex: Jeni" defaultValue="Blue Breeze" />
              </Form.Item>
              <Form.Item
                label={<Text strong>Brand Color</Text>}
                name="brand_color"
                rules={[
                  {
                    required: true,
                    message: "Please enter brand color!",
                  },
                ]}
              >
                <Input placeholder="Ex: #000000" defaultValue="#382e7b" />
              </Form.Item>
              <Form.Item
                label={<Text strong>Secret Key</Text>}
                name="secret_key"
                rules={[
                  {
                    required: true,
                    message: "Please enter secret key!",
                  },
                ]}
              >
                <Input placeholder="Please enter secret key" />
              </Form.Item>
              <Form.Item
                label={<Text strong>Privacy Policy URL</Text>}
                name="privacy_policy_url"
                rules={[
                  {
                    required: true,
                    message: "Please enter privacy policy url!",
                  },
                ]}
              >
                <Input placeholder="Please enter privacy policy url" />
              </Form.Item>

              <Form.Item
                name="form"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Space direction="horizontal">
                  <Text strong>Register Form</Text>
                  <Alert
                    message="Enable the registration form for capturing user details."
                    type="warning"
                    showIcon
                  />
                </Space>
                <Switch
                  defaultChecked
                  onChange={(checked) => {
                    setFormEnabled(checked);
                  }}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                />
              </Form.Item>
              {formEnabled && (
                <>
                  <Form.Item
                    label={<Text strong>Form Requirements</Text>}
                    name="form_requirements"
                    style={{ display: "flex" }}
                  >
                    <Checkbox.Group
                      options={["Name", "Phone", "Email"]}
                      initialValues={["Name", "Phone"]} 
                    />
                  </Form.Item>
                  {(selectedTemplate === "blue-breeze" ||
                    selectedTemplate === "green-stone") && (
                    <>
                      <Form.Item
                        label={<Text strong>Form Welcome Description</Text>}
                        name="welcome_description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter welcome description!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder=""
                          defaultValue="Join the Chat Revolution - Let's Get Started!"
                        />
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Col>
            <Col span={12}>
              <Space direction="vertical" align="center">
                {["botAvatar", "floatingIcon"].map((type) => (
                  <Form.Item key={type} name={type.toLowerCase()}>
                    <Space direction="vertical">
                      <Text>
                        {type === "botAvatar" ? "Bot Avatar" : "Floating Icon"}
                      </Text>
                      {imageUrl[type] ? (
                        <Image
                          src={imageUrl[type]}
                          width="125px"
                          style={{
                            border: "1px solid #EFEFEE",
                            padding: 20,
                          }}
                          preview={false}
                        />
                      ) : (
                        <Image
                          src={
                            type === "botAvatar"
                              ? "https://cdn-icons-png.flaticon.com/128/6997/6997674.png"
                              : "https://cdn-icons-png.flaticon.com/128/2598/2598861.png"
                          }
                          width="125px"
                          style={{
                            border: "1px solid #EFEFEE",
                            padding: 20,
                          }}
                          preview={false}
                        />
                      )}
                      <Space>
                        <Upload
                          name="avatar"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={handleImageChange(type)}
                        >
                          <Text
                            style={{
                              textDecoration: "underline",
                              color: "#F05742",
                              cursor: "pointer",
                            }}
                          >
                            {type === "botAvatar"
                              ? "Change avatar"
                              : "Change icon"}
                          </Text>
                        </Upload>
                      </Space>
                    </Space>
                  </Form.Item>
                ))}
              </Space>
            </Col>
          </Row>
          {current === 1 && (
            <Space
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button onClick={() => setCurrent(current - 1)}>Previous</Button>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Space>
          )}
        </Form>
      </Col>
    </Row>
  );
};

////////////success page//////////////

const CompletionPage = () => {
  const scriptText =
    '<script src="https://examplestorage.blob.core.windows.net/webchat/webchat.js"></script>';
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space direction="vertical" size={20} align={"middle"}>
        <Image preview={false} width={300} src="/images/completed.png" />
        <Text strong>Your web chat feature is now ready!</Text>
        <Card>
          <Text>{scriptText}</Text>
          <MdContentCopy
            style={{
              cursor: "pointer",
              marginLeft: 10,
            }}
            onClick={() => {
              navigator.clipboard.writeText(scriptText);
              openNotificationWithIcon("success", "Script copied to clipboard");
            }}
          />
        </Card>

        <Button type="primary">Go Webchat</Button>
      </Space>
    </div>
  );
};

const steps = [
  {
    title: "Select Template",
    content: ({ onSelectTemplate }) => (
      <SelectTemplate onSelectTemplate={onSelectTemplate} />
    ),
    icon: <AiOutlineSelect />,
  },
  {
    title: "Customize Template",
    content: ({ selectedTemplate, current, setCurrent }) => (
      <CustomizeTemplateForm
        selectedTemplate={selectedTemplate}
        current={current}
        setCurrent={setCurrent}
      />
    ),
    icon: <BiCustomize />,
  },
  {
    title: "Ready to Launch",
    content: <CompletionPage />,
    icon: <MdOutlineDownloadDone />,
  },
];

const BotWEBCustomize = () => {
  const [current, setCurrent] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const onSelectTemplate = (template) => {
    setSelectedTemplate(template);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));
  const contentStyle = {
    textAlign: "center",
    marginTop: 16,
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>
        {typeof steps[current].content === "function"
          ? steps[current].content({
              selectedTemplate,
              onSelectTemplate,
              current,
              setCurrent,
            })
          : steps[current].content}
      </div>
      <div
        style={{
          marginTop: 24,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {current === 0 && (
          <Button
            type="primary"
            onClick={() => setCurrent(current + 1)}
            disabled={selectedTemplate === null}
          >
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default BotWEBCustomize;
