import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Space, Tooltip, Modal } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";

const { confirm } = Modal;

const useConversationFlowTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const conversationFlowDataColumns = [
    {
      title: "CONVERSATION FLOW NAME",
      dataIndex: "conversation_flow_name",
      key: "conversation_flow_name",
      ellipsis: true,
      width: 300,
      sorter: (a, b) => {
        let valueA =
          a.conversation_flow_name == null ? "" : a.conversation_flow_name;
        let valueB =
          b.conversation_flow_name == null ? "" : b.conversation_flow_name;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: "CONVERSATION FLOW DESCRIPTION",
      dataIndex: "conversation_flow_description",
      key: "conversation_flow_description",
      width: 500,
      ellipsis: true,
      sorter: (a, b) => {
        let valueA =
          a.conversation_flow_description == null
            ? ""
            : a.conversation_flow_description;
        let valueB =
          b.conversation_flow_description == null
            ? ""
            : b.conversation_flow_description;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: "CONVERSATION FLOW INTENT PHRASE",
      dataIndex: "conversation_flow_intent_phrase",
      key: "conversation_flow_intent_phrase",
      width: 300,
      ellipsis: true,
      sorter: (a, b) => {
        let valueA =
          a.conversation_flow_intent_phrase == null
            ? ""
            : a.conversation_flow_intent_phrase;
        let valueB =
          b.conversation_flow_intent_phrase == null
            ? ""
            : b.conversation_flow_intent_phrase;
        return valueA.localeCompare(valueB);
      },
    },
  ];

  const conversationFlowActionColumns = [
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: 150,
      align: "center",

      render: (_, record) => {
        return (
          <>
            <Space size={24}>
              <Tooltip placement="top" title="Edit Conversation flow">
                <EditOutlined
                  style={{ color: "#F05742" }}
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_ACTION_ROW_KEY",
                      payload: {
                        component: "conversation-flow-table",
                        actionRowKey: record._id,
                      },
                    });
                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "conversation-flow-form-panel",
                        title: "Edit Conversation Flow",
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "conversation-flow-form",
                        mode: "edit",
                      },
                    });
                  }}
                />
              </Tooltip>

              {state.apiCallStatus[
                "conversation-flow-" + record._id + "-delete"
              ] &&
              state.apiCallStatus["conversation-flow-" + record._id + "-delete"]
                .loading ? (
                <ComponentLoader
                  component={"conversation-flow-" + record._id + "-delete"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip placement="top" title="Delete Conversation Flow">
                  <DeleteOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "conversation-flow-table",
                          actionRowKey: record._id,
                        },
                      });
                      confirm({
                        title:
                          "Are you sure you want to delete the conversation flow?",
                        content:
                          "When clicked the OK button, the conversation flow will be deleted permanently",
                        onOk() {
                          dispatchMiddleware({
                            type: "MWD_DELETE_CONVERSATION_FLOW",
                            payload: {
                              component:
                                "conversation-flow-" + record._id + "-delete",
                              actionRowKey: record._id,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return {
    conversationFlowDataColumns,
    conversationFlowActionColumns,
  };
};

export default useConversationFlowTableColumns;
