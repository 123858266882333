import { Row, Col, Button, Space, Image, Typography, Divider } from "antd";
import { URIs } from "../constants/env";

const { Title, Paragraph } = Typography;

const GetStarted = ({
  source,
  setShowBroadcastScreen,
  setShowContactScreen,
  setShowMessageTemplateScreen,
  setShowQuestionAndAnswerScreen,
  setShowIntentAndPhrasesScreen,
  setShowSupportTicketScreen,
  setShowAutomatedReplyScreen,
  setShowAccountUserScreen,
  setShowCannedResponsesScreen,
  setShowWebhookScreen,
}) => {
  switch (source) {
    case "broadcasts":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/broadcast.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Broadcast</Title>
              </Col>
              <Col span={24}>
                <Paragraph style={{ fontStyle: "italic" }}>
                  Extend and optimize your customer outreach with Broadcast.
                </Paragraph>
                <Paragraph>
                  <ul>
                    With Broadcast, you can
                    <li>
                      Send messages to customers on modern channels like
                      WhatsApp, Telegram, Viber and so on
                    </li>
                    <li>
                      Send interactive messages that include rich media, quick
                      reply and call-to-action buttons
                    </li>
                    <li>
                      Track delivery and read receipts to ensure messages have
                      been reached and read by the recipients
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowBroadcastScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS +
                          "/docs/Broadcast/broadcast-overview"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "contacts":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/contacts.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Contacts</Title>
              </Col>
              <Col span={24}>
                <Paragraph>Manage your contacts and their details</Paragraph>
                <Paragraph>
                  <ul>
                    With Contacts, you can:
                    <li>Add and manage contacts</li>
                    <li>Manage contact tags</li>
                    <li>Get insights about customer conversations</li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowContactScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS + "/docs/contacts/contacts-overview"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "message-templates":
      return (
        <Row gutter={8} justify="center" style={{ marginTop: "40px" }}>
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/message-templates.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Message Template</Title>
              </Col>
              <Col span={24}>
                <Paragraph>Create and manage message templates</Paragraph>
                <Paragraph>
                  <ul>
                    A template message is used by business to start a
                    conversation with a customer.
                    <li>
                      Template messages must be approved by Facebook before they
                      can be used.
                    </li>
                    <li>
                      Template Message can be one of the following categories:
                      Marketing, Transactional, or One-time password (OTP).
                    </li>
                    <li>
                      Customer must have opted-in to receive messages from your
                      business
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowMessageTemplateScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS +
                          "/docs/Broadcast/managing-templates"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "question-and-answers":
      return (
        <Row gutter={8} justify="center" style={{ marginTop: "40px" }}>
          <Col span={6}>
            <Image preview={false} src="/images/module-not-found/qna.png" />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Question and Answers</Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  Help your customers find answers to their questions
                </Paragraph>
                <Paragraph>
                  <ul>
                    With Questions and Answers, you can
                    <li>
                      Build custom knowledge bases to create a natural
                      conversational layer over your data
                    </li>
                    <li>
                      Provide most appropriate answer for any question input
                    </li>
                    <li>
                      Add new question and answers on the fly, test and improve
                      your KBs performance
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowQuestionAndAnswerScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS + "/docs/bot%20knowledgebase/qna"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "intents-and-phrases":
      return (
        <Row gutter={8} justify="center" style={{ marginTop: "40px" }}>
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/intent&phrases.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Intents and Phrases</Title>
              </Col>
              <Col span={24}>
                <Paragraph>
                  Train your bots the language your customers speak
                </Paragraph>
                <Paragraph>
                  <ul>
                    With Intents and Phrases, you can:
                    <li>Train the phrases that your customers use</li>
                    <li>Customize how Bot should interpret user phrases</li>
                    <li>
                      Test if the bots understand the language of your customer
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowIntentAndPhrasesScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS +
                          "/docs/bot%20knowledgebase/intents_and_phrases"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "support-tickets":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image preview={false} src="/images/module-not-found/support.png" />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Support Ticket</Title>
              </Col>
              <Col span={24}>
                <Paragraph>Manage your support tickets</Paragraph>
                <Paragraph>
                  <ul>
                    <li>
                      If you encounter any issues with the product, you can
                      create a support ticket and our support team will get back
                      to you as soon as possible.
                    </li>
                    <li>
                      Provide us with details of the issue you are facing
                      including screenshots and we will get back to you as soon
                      as possible.
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowSupportTicketScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(URIs.DOCUMENTATIONS + "/docs/support");
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "automated-replies":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/automated-replies.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Automated Replies</Title>
              </Col>
              <Col span={24}>
                <Paragraph style={{ fontStyle: "italic" }}>
                  Create a response once and use it for all your broadcasts that
                  includes Quick reply buttons.
                </Paragraph>
                <Paragraph>
                  <ul>
                    With Automated replies, you can
                    <li>Save time and effort</li>
                    <li>
                      Maintain a consistent tone and message across all your
                      broadcasts.
                    </li>
                    <li>Remove errors caused by typos and spelling mistakes</li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowAutomatedReplyScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS +
                          "/docs/account-settings/automated-replies"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "webhooks":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              preview={false}
              src="/images/module-not-found/webhooks.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Webhook</Title>
              </Col>
              <Col span={24}>
                <Paragraph>Connect your business systems to Bot</Paragraph>
                <Paragraph>
                  <ul>
                    With Webhook, you can:
                    <li>Connect your business systems to Bot</li>
                    <li>Send and receive key information from the bot</li>
                    <li>
                      Automatically notified when a customer interacts with the
                      bot
                    </li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowWebhookScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS + "/docs/account-settings/webhooks"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "account-users":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image preview={false} src="/images/module-not-found/user.png" />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Account Users</Title>
              </Col>
              <Col span={24}>
                <Paragraph style={{ fontStyle: "italic" }}>
                  Manage user accounts and their permissions
                </Paragraph>
                <Paragraph>
                  <ul>
                    Administrators of an account:
                    <li>Add and manage users to the account</li>
                    <li>
                      Specify roles and permissions to access all or certain
                      part of the portal
                    </li>
                    <li>Delete users who no longer need access</li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowAccountUserScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(URIs.DOCUMENTATIONS + "/docs/Overview");
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    case "canned-responses":
      return (
        <Row gutter={8} justify="center">
          <Col span={6}>
            <Image
              height={300}
              preview={false}
              src="/images/module-not-found/canned-responses.png"
            />
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title level={3}>Canned Response</Title>
              </Col>
              <Col span={24}>
                <Paragraph>Save Time and be more productive</Paragraph>
                <Paragraph>
                  <ul>
                    With Canned Responses, you can:
                    <li>Save time and effort</li>
                    <li>
                      Maintain a consistent tone and message across all your
                      client conversations.
                    </li>
                    <li>Remove errors caused by typos and spelling mistakes</li>
                  </ul>
                </Paragraph>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowCannedResponsesScreen(true);
                    }}
                  >
                    Get Started
                  </Button>

                  <Button
                    onClick={async () => {
                      window.open(
                        URIs.DOCUMENTATIONS +
                          "/docs/account-settings/canned-responses"
                      );
                    }}
                  >
                    Know More
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      );

    default:
  }
};

export default GetStarted;
