import { Space, List, Typography, Collapse, Badge } from "antd";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../contexts/globalContext";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const DashboardActionRequired = ({ whatsAppPendingConfigurationBots }) => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <Space style={{ color: "red", marginTop: 4 }}>
          {isActive ? <CaretDownOutlined /> : <CaretRightOutlined />}
        </Space>
      )}
      style={{ border: "1px solid red", borderRadius: "4px" }}
    >
      <Panel
        header={
          <Badge
            count={whatsAppPendingConfigurationBots.length}
            offset={[20, 12]}
            color="#ff0000"
          >
            <Title level={5} style={{ color: "red", marginBottom: "0" }}>
              ACTION REQUIRED
              <Space
                style={{
                  fontSize: 18,
                }}
              >
                !
              </Space>
            </Title>
          </Badge>
        }
        key="1"
      >
        <List>
          {whatsAppPendingConfigurationBots.map((bot) => (
            <List.Item key={bot.bot_id}>
              <Space>
                <Text strong>{bot.bot_name}</Text>
                {bot.bot_channel_info.WHATSAPP.waba_phone_number && (
                  <Text>
                    ({bot.bot_channel_info.WHATSAPP.waba_phone_number})
                  </Text>
                )}
                <Text>is pending WhatsApp Configuration.</Text>
                <Link
                  to="/bot-settings/whatsapp"
                  onClick={() => {
                    sessionStorage.setItem(
                      "selectedMenu",
                      "/bot-settings/general"
                    );
                    dispatchReducer({
                      type: "RD_SET_BOT_IN_ACTION",
                      payload: {
                        botInAction: bot.bot_id,
                        clientId: state.currentAccount.account.client_id,
                        event: "globalBotOnChange",
                      },
                    });
                  }}
                >
                  Configure WhatsApp
                </Link>
              </Space>
            </List.Item>
          ))}
        </List>
      </Panel>
    </Collapse>
  );
};

export default DashboardActionRequired;
