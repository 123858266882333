import { useGlobalContext } from "../../../contexts/globalContext";
import { Row, Col } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import ConversationFlowHeader from "./ConversationFlowHeader";
import ConversationFlowTable from "./ConversationFlowTable";
import ConversationFlowFormPanel from "./ConversationFlowFormPanel";

const ConversationFlow = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_CONVERSATION_FLOW",
      payload: { component: "conversation-flow-table" },
    });
  });

  return (
    <>
      {state.conversationFlow.flows ? (
        <>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <ConversationFlowHeader />
            </Col>
            <Col span={24}>
              <ConversationFlowTable />
            </Col>
          </Row>
          <ConversationFlowFormPanel />
        </>
      ) : (
        <FullPageLoader
          component="conversation-flow-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default ConversationFlow;
