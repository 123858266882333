import React from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Dropdown, Image, Space } from "antd";
import { languageSelector } from "../../constants/enums";
import { mode } from "../../constants/env";

const items = languageSelector
  .slice(
    0,
    //In Pre-prod, Prod show english language only
    ["PREPROD", "PROD"].includes(mode) ? -1 : languageSelector.length
  )
  .map((item) => {
    return {
      key: item.value,
      label: (
        <Space>
          <Image src={item.icon} width={20} height={20} preview={false} />
          {item.label}
        </Space>
      ),
    };
  });

const LanguageSelector = () => {
  const [state, dispatchReducer] = useGlobalContext();

  const handleMenuClick = (e) => {
    dispatchReducer({
      type: "SET_LANGUAGE",
      payload: {
        language: e.key,
      },
    });
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
    >
      <Image
        src={
          languageSelector.find(
            (language) => language.value === state.application.selectedLanguage
          )?.icon
        }
        width={27}
        height={26}
        preview={false}
        style={{
          cursor: "pointer",
          marginBottom: 7,
        }}
      />
    </Dropdown>
  );
};

export default LanguageSelector;
