import { useGlobalContext } from "../../contexts/globalContext";
import { Card, Typography, Row, Col, Space, Divider, Tag, Layout } from "antd";
import DashboardLatestUpdate from "./DashboardLatestUpdate";
import DashboardActionRequired from "./DashboardActionRequired";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";
import WaitingForLiveAgentCount from "./WaitingForLiveagentCount";
import ConversationByTodayCount from "./ConversationByTodayCount";
import BroadcastsCount from "./BroadcastsCount";
import ClientResponsesTotalCount from "./ClientResponsesTotalCount";
import MessageSentCount from "./MessageSentCount";
import ConversationTotalCount from "./ConversationTotalCount";
import { mode } from "../../constants/env";

const { Text } = Typography;

const Dashboard = () => {
  const [state] = useGlobalContext();

  let whatsAppPendingConfigurationBots = {};
  if (state.currentAccount.account.bots) {
    whatsAppPendingConfigurationBots = state.currentAccount.account.bots.filter(
      (bot) => {
        return (
          bot.bot_channel_info.WHATSAPP &&
          !bot.bot_channel_info.WHATSAPP.waba_integrated_onboarding
        );
      }
    );
  }

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  return (
    <>
      {!state.currentAccount.account.modules.dashboard ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModuleNotSubscribed module="dashboard" />
        </Layout.Content>
      ) : (
        <Row gutter={[24, 24]}>
          <Col span="24">
            <Card type="inner" bordered={false}>
              <Space>
                <Text>
                  Welcome,
                  <Text strong>{state.currentUser.user.displayName}</Text>
                </Text>
                <Divider type="vertical" style={{ height: 25 }} />
                <Tag
                  color={
                    state.currentAccount.account.account_status.toLowerCase()
                      ? "green"
                      : "red"
                  }
                >
                  {state.currentAccount.account.account_status}
                </Tag>
                {state.currentAccount.account.account_tier.toLowerCase() !==
                  "paid" && (
                  <Tag>{state.currentAccount.account.account_tier}</Tag>
                )}
              </Space>
            </Card>
          </Col>

          <Col span="24">
            {["NOTIFY"].includes(botToBeEdited.subscription_package) && (
              <Row gutter={[16, 16]}>
                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <BroadcastsCount period="30" />
                  </Card>
                </Col>

                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <MessageSentCount period="30" />
                  </Card>
                </Col>

                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <ClientResponsesTotalCount period="30" />
                  </Card>
                </Col>

                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <ConversationTotalCount period="30" />
                  </Card>
                </Col>
              </Row>
            )}
            {["STANDARD", "STARTER", "ADVANTAGE"].includes(
              botToBeEdited.subscription_package
            ) && (
              <Row gutter={[16, 16]}>
                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <ConversationTotalCount period="30" />
                  </Card>
                </Col>

                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <ClientResponsesTotalCount period="30" />
                  </Card>
                </Col>

                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <ConversationByTodayCount period="30" />
                  </Card>
                </Col>

                <Col span="6">
                  <Card type="inner" style={{ height: 200 }}>
                    <WaitingForLiveAgentCount period="30" />
                  </Card>
                </Col>
              </Row>
            )}
          </Col>

          {whatsAppPendingConfigurationBots.length > 0 && (
            <Col span="24">
              <DashboardActionRequired
                whatsAppPendingConfigurationBots={
                  whatsAppPendingConfigurationBots
                }
              />
            </Col>
          )}

          <Col span={24}>
            <Card
              type="inner"
              style={{
                backgroundColor:
                  mode === "DEV" && state.dashBoard?.latestNews
                    ? "#f2f6f9"
                    : "",
              }}
            >
              <DashboardLatestUpdate />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Dashboard;
