import { Row, Col } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import UnrecognizedIntentsHeader from "./UnrecognizedIntentsHeader";
import UnrecognizedIntentsTable from "./UnrecognizedIntentsTable";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import UnrecognizedIntentsFormPanel from "./UnrecognizedIntentsFormPanel";
import AccessDenied from "../../../shared-components/AccessDenied";
import ClientResponsesViewConversationPanel from "../../client-responses/ClientResponsesViewConversationPanel";

const UnrecognizedIntents = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({ type: "CLEAR_UNRECOGNIZED_INTENTS" });
      state.currentUser.permission.UNRECOGNIZED_INTENTS_VIEW &&
        dispatchMiddleware({
          type: "LOAD_UNRECOGNIZED_INTENTS",
          payload: { pageNumber: 1, component: "unrecognized-intents-table" },
        });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return state.currentUser.permission.UNRECOGNIZED_INTENTS_VIEW ? (
    state.unrecognizedIntents ? (
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <UnrecognizedIntentsHeader />
        </Col>
        <Col span={24}>
          <UnrecognizedIntentsTable />
        </Col>
        <UnrecognizedIntentsFormPanel />
        <ClientResponsesViewConversationPanel />
      </Row>
    ) : (
      <FullPageLoader
        component="unrecognized-intents-table"
        loadingMessage="Loading... Please wait..."
      />
    )
  ) : (
    <AccessDenied />
  );
};

export default UnrecognizedIntents;
