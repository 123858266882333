import { Row, Col, Input, Button, Tooltip, Divider, Space } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { RiFilterOffLine } from "react-icons/ri";
import { useGlobalContext } from "../../contexts/globalContext";

const TicketsHeader = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Row>
      <Col span={8}>
        <Space>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search by ticket number, subject or description"
            value={
              state.filters.searchText["tickets-search-text-box"] &&
              state.filters.searchText["tickets-search-text-box"]
            }
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "tickets-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
            style={{ width: 400 }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
          />

          <Divider type="vertical" />

          <Tooltip placement="top" title="Clear filter(s)">
            <Button icon={<RiFilterOffLine style={{ margin: -2 }} />} />
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};

export default TicketsHeader;
