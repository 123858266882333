import React from "react";
import { Space } from "antd";

const ConversationEventNotification = ({ transcript }) => {
  return (
    <Space
      style={{
        width: "100%",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          backgroundColor: "#EAF7FE",
          fontSize: 12,
          borderRadius: 10,
          border: "1px solid #97D0FB",
          color: "#285ECE",
          padding: "5px 15px",
        }}
      >
        {transcript.description}
      </span>
    </Space>
  );
};

export default ConversationEventNotification;
