import { useState, useEffect } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Form,
  Row,
  Col,
  Space,
  Divider,
  Typography,
  Input,
  Select,
  Button,
  Switch,
  Tooltip,
} from "antd";
import {
  lalamoveLanguages,
  lalamoveServiceTypes,
  delayOptions,
  lalamoveMarkets,
} from "../../../constants/enums";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Translation } from "../../../translation/translation";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import openNotificationWithIcon from "../../../utils/notification";
import "./DeliveryServices.less";

const { Text } = Typography;
const { TextArea } = Input;

const Lalamove = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  // Get translated text
  const lalamoveForm =
    Translation().accountSettings.deliveryServices.lalamove.form;

  const [isEnable, setIsEnable] = useState(true);

  const lalamoveDetails = state.deliveryServices.lalamoveConfiguration || {};

  const handleFormSubmit = (values) => {
    const { status } = values;
    values.status = !!status;

    dispatchMiddleware({
      type: "SET_LALAMOVE_CONFIGURATION",
      payload: {
        lalamoveConfiguration: values,
        component: "lalamove-form",
      },
    });
  };

  useEffect(() => {
    setIsEnable(lalamoveDetails.status);
    form.setFieldsValue({
      ...lalamoveDetails,
      address: lalamoveDetails.address || [{ name: "", address: "" }],
    });
  }, []);

  return state.apiCallStatus?.["lalamove-form"]?.loading ? (
    <FullPageLoader
      component="lalamove-form"
      loadingMessage="Submitting... Please wait..."
    />
  ) : (
    <Form
      layout="vertical"
      autoComplete="off"
      onFinish={handleFormSubmit}
      form={form}
      onFinishFailed={() => {
        openNotificationWithIcon(
          "error",
          "Form Submission Failed! Please check the form for errors."
        );
      }}
    >
      <Row gutter={10}>
        {/* Status */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.isEnableFormItem.title}</Text>
            <Text>{lalamoveForm.isEnableFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="status"
                label={lalamoveForm.isEnableFormItem.label}
              >
                <Switch
                  checked={isEnable}
                  onChange={(checked) => {
                    setIsEnable(checked);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* API Key */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.apiKeyFormItem.title}</Text>
            <Text>{lalamoveForm.apiKeyFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="apiKey"
                label={lalamoveForm.apiKeyFormItem.label}
                rules={[
                  {
                    required: true,
                    message: lalamoveForm.apiKeyFormItem.required,
                  },
                  {
                    min: 3,
                    message: lalamoveForm.apiKeyFormItem.minimum,
                  },
                  {
                    max: 100,
                    message: lalamoveForm.apiKeyFormItem.maximum,
                  },
                ]}
              >
                <Input
                  disabled={!isEnable}
                  placeholder={lalamoveForm.apiKeyFormItem.placeholder}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* APP Secret */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.appSecretFormItem.title}</Text>
            <Text>{lalamoveForm.appSecretFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="appSecret"
                label={lalamoveForm.appSecretFormItem.label}
                rules={[
                  {
                    required: true,
                    message: lalamoveForm.appSecretFormItem.required,
                  },
                  {
                    min: 3,
                    message: lalamoveForm.appSecretFormItem.minimum,
                  },
                  {
                    max: 100,
                    message: lalamoveForm.appSecretFormItem.maximum,
                  },
                ]}
              >
                <Input.Password
                  disabled={!isEnable}
                  placeholder={lalamoveForm.appSecretFormItem.placeholder}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* Language Selection */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.languageSelectionFormItem.title}</Text>
            <Text>{lalamoveForm.languageSelectionFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="language"
                label={lalamoveForm.languageSelectionFormItem.label}
                rules={[
                  {
                    required: true,
                    message: lalamoveForm.languageSelectionFormItem.required,
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={!isEnable}
                  placeholder={
                    lalamoveForm.languageSelectionFormItem.placeholder
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {lalamoveLanguages.map((language, index) => {
                    return (
                      <Select.Option value={language.key} key={index}>
                        {language.value + " (" + language.key + ")"}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* Schedule At */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.scheduleAtFormItem.title}</Text>
            <Text>{lalamoveForm.scheduleAtFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="scheduleAt"
                label={lalamoveForm.scheduleAtFormItem.label}
                rules={[
                  {
                    required: true,
                    message: lalamoveForm.scheduleAtFormItem.required,
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={!isEnable}
                  placeholder={lalamoveForm.scheduleAtFormItem.placeholder}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {delayOptions.map((delayOption, index) => {
                    return (
                      <Select.Option value={delayOption.key} key={index}>
                        {delayOption.key}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* Address */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.pickupAddressFormItem.title}</Text>
            <Text>{lalamoveForm.pickupAddressFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.List name="address">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={10} key={key}>
                        <Col span={11}>
                          <Space
                            direction="vertical"
                            style={{
                              width: "100%",
                            }}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    lalamoveForm.pickupAddressFormItem.name
                                      .required,
                                },
                                {
                                  min: 3,
                                  message:
                                    lalamoveForm.pickupAddressFormItem.name
                                      .minimum,
                                },
                                {
                                  max: 100,
                                  message:
                                    lalamoveForm.pickupAddressFormItem.name
                                      .maximum,
                                },
                              ]}
                              className="pickup-address-form-item"
                            >
                              <Input
                                disabled={!isEnable}
                                placeholder={
                                  lalamoveForm.pickupAddressFormItem.name
                                    .placeholder
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "phoneNumber"]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    lalamoveForm.pickupAddressFormItem
                                      .phoneNumber.required,
                                },
                                {
                                  min: 3,
                                  message:
                                    lalamoveForm.pickupAddressFormItem
                                      .phoneNumber.minimum,
                                },
                                {
                                  max: 20,
                                  message:
                                    lalamoveForm.pickupAddressFormItem
                                      .phoneNumber.maximum,
                                },
                              ]}
                            >
                              <Input
                                disabled={!isEnable}
                                placeholder={
                                  lalamoveForm.pickupAddressFormItem.phoneNumber
                                    .placeholder
                                }
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "address"]}
                            rules={[
                              {
                                required: true,
                                message:
                                  lalamoveForm.pickupAddressFormItem.address
                                    .required,
                              },
                              {
                                min: 3,
                                message:
                                  lalamoveForm.pickupAddressFormItem.address
                                    .minimum,
                              },
                              {
                                max: 100,
                                message:
                                  lalamoveForm.pickupAddressFormItem.address
                                    .maximum,
                              },
                            ]}
                          >
                            <TextArea
                              disabled={!isEnable}
                              placeholder={
                                lalamoveForm.pickupAddressFormItem.address
                                  .placeholder
                              }
                              autoSize={{ minRows: 3 }}
                              showCount
                              maxLength={1024}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          {
                            //show button only have more than one item
                            fields.length > 1 &&
                              (isEnable ? (
                                <DeleteOutlined
                                  style={{
                                    color: "#F05742",
                                    margin: "13px 0 0 10px",
                                  }}
                                  onClick={() => remove(name)}
                                />
                              ) : (
                                <DeleteOutlined
                                  style={{
                                    color: "#C0C0C0",
                                    margin: "13px 0 0 10px",
                                  }}
                                />
                              ))
                          }

                          {
                            //show button only on last item
                            fields.length - 1 === parseInt(name) && (
                              <Form.Item>
                                <Button
                                  disabled={!isEnable}
                                  danger
                                  size="small"
                                  style={{
                                    color: "#F05742",
                                    margin: "13px 0 0 6px",
                                  }}
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                />
                              </Form.Item>
                            )
                          }
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* Market */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.marketSelectionFormItem.title}</Text>
            <Text>{lalamoveForm.marketSelectionFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="market"
                label={lalamoveForm.marketSelectionFormItem.label}
                rules={[
                  {
                    required: true,
                    message: lalamoveForm.marketSelectionFormItem.required,
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={!isEnable}
                  placeholder={lalamoveForm.marketSelectionFormItem.placeholder}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {lalamoveMarkets.map((market, index) => {
                    return (
                      <Select.Option value={market.key} key={index}>
                        {market.value + " (" + market.key + ")"}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        {/* Service Type */}
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{lalamoveForm.serviceTypeFormItem.title}</Text>
            <Text>{lalamoveForm.serviceTypeFormItem.description}</Text>
          </Space>
        </Col>
        <Col span={15}>
          <Row>
            <Col offset={2} span={21}>
              <Form.Item
                name="serviceType"
                label={lalamoveForm.serviceTypeFormItem.label}
                rules={[
                  {
                    required: true,
                    message: lalamoveForm.serviceTypeFormItem.required,
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={!isEnable}
                  placeholder={lalamoveForm.serviceTypeFormItem.placeholder}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {lalamoveServiceTypes.map((serviceType, index) => {
                    return (
                      <Select.Option value={serviceType.key} key={index}>
                        {serviceType.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />

        <Col span={24}>
          <Form.Item>
            <Space>
              <Button>{lalamoveForm.cancel.buttonText}</Button>

              <Tooltip
                title={
                  !state.currentUser.permission
                    .ACCOUNT_LALAMOVE_CONFIGURATION_EDIT
                    ? lalamoveForm.noAccess
                    : ""
                }
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !state.currentUser.permission
                      .ACCOUNT_LALAMOVE_CONFIGURATION_EDIT
                  }
                >
                  {lalamoveForm.save.buttonText}
                </Button>
              </Tooltip>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Lalamove;
