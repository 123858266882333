import { useState, useEffect } from "react";
import { Table } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import useBroadcastManagerTableColumns from "./BroadcastManagerTableColumns";
import { convertObjectToString } from "../../utils/utility";
import { useWindowSize } from "../../shared-components/PageReSize";

const BroadcastManagerTable = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const { dataColumns, actionColumns } = useBroadcastManagerTableColumns();
  const [height] = useWindowSize();
  const [pageSize, setPageSize] = useState();

  let filteredBroadcasts = state.broadcastManager.broadcasts.data;

  useEffect(() => {
    dispatchReducer({
      type: "RD_SET_SEARCH_TEXT",
      payload: {
        component: "broadcast-manager-search-text-box",
        searchText: null,
      },
    });
    dispatchReducer({
      type: "RD_SET_PAGINATION",
      payload: {
        component: "broadcast-manager-table",
        pagination: null,
      },
    });
    dispatchReducer({
      type: "RD_SET_FILTERED_INFO",
      payload: {
        component: "broadcast-manager-table",
        filteredInfo: null,
      },
    });
    dispatchReducer({
      type: "RD_SET_SORTED_INFO",
      payload: {
        component: "broadcast-manager-table",
        sortedInfo: null,
      },
    });
  }, [state.application.botInAction]);

  return (
    <Table
      columns={dataColumns.concat(actionColumns)}
      dataSource={filteredBroadcasts}
      loading={
        state.apiCallStatus["broadcast-manager-table"] &&
        state.apiCallStatus["broadcast-manager-table"].loading
      }
      onChange={(pagination, filters, sorter) => {
        dispatchReducer({
          type: "RD_SET_PAGINATION",
          payload: {
            component: "broadcast-manager-table",
            pagination,
          },
        });
        dispatchReducer({
          type: "RD_SET_FILTERED_INFO",
          payload: {
            component: "broadcast-manager-table",
            filteredInfo: filters,
          },
        });
        dispatchReducer({
          type: "RD_SET_SORTED_INFO",
          payload: {
            component: "broadcast-manager-table",
            sortedInfo: sorter,
          },
        });

        dispatchMiddleware({
          type: "LOAD_BROADCASTS_DATA",
          payload: {
            component: "broadcast-manager-table",
            searchText:
              state.filters.searchText?.["broadcast-manager-search-text-box"],
            //convert object to string
            filteredValue: convertObjectToString(filters || {}),
            ...sorter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
          },
        });
      }}
      pagination={{
        // Set default page number
        current: state.filters.pagination?.["broadcast-manager-table"]
          ? state.filters.pagination["broadcast-manager-table"].current
          : 1,
        total: state.broadcastManager.broadcasts.totalRecords,
        showSizeChanger:
          state.broadcastManager.broadcasts.totalRecords > 20 ? true : false,
        pageSize: pageSize ?? Math.round((height - 250) / 55),
        pageSizeOptions: ["20", "30", "50", "100"],
        onShowSizeChange: (_, size) => {
          setPageSize(size);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      rowKey="sentDate"
      showSorterTooltip={false}
    />
  );
};

export default BroadcastManagerTable;
