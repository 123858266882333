import { useState, useEffect } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Tag,
  Button,
  Form,
  Input,
  Checkbox,
  Dropdown,
  Image,
  Modal,
  Popconfirm,
} from "antd";
import { HiOutlineLightBulb } from "react-icons/hi";
import { LuGalleryHorizontalEnd, LuThumbsUp, LuTrophy } from "react-icons/lu";
import { DownOutlined } from "@ant-design/icons";
import {
  MdOutlineNoteAlt,
  MdLocalDining,
  MdOutlineCalendarMonth,
} from "react-icons/md";
import { TiFlowSwitch } from "react-icons/ti";
import { AiOutlineArrowLeft, AiOutlineExclamationCircle } from "react-icons/ai";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { PiBookOpenTextBold } from "react-icons/pi";
import { TbCategory } from "react-icons/tb";
import { VscPreview } from "react-icons/vsc";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { whatsappFlowsCategories } from "../../../constants/enums";
import openNotificationWithIcon from "../../../utils/notification";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import { URIs } from "../../../constants/env";
import "./WhatsAppFlows.less";

const { Text } = Typography;
const { confirm } = Modal;

const WhatsAppFlowsForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [showFlowGalery, setShowFlowGalery] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isFlowIsModified, setIsFlowIsModified] = useState(false);

  const whatsappFlowsGallery = state.whatsappFlows.whatsappFlowsGallery;

  const handleShowFlowGalery = (flowCategory) => {
    setShowFlowGalery(false);

    let selectedFlow =
      whatsappFlowsGallery?.find((flow) => flow.name === flowCategory) || {};

    // Remove 'name' and '_id' fields from selectedFlow
    const { name, _id, ...selectedFlowWithoutNameAndId } = selectedFlow;

    form.setFieldsValue({
      name: selectedFlow.name,
      flowJSON: JSON.stringify(selectedFlowWithoutNameAndId, null, 3),
    });
  };

  const handleBackToFlowGalery = () => {
    setShowFlowGalery(true);
    form.resetFields();
    state.whatsappFlows.currentWhatsAppFlow &&
      dispatchReducer({
        type: "SET_CURRENT_WHATSAPP_FLOW",
        payload: {
          currentWhatsAppFlow: null,
        },
      });
    categories.length > 0 && setCategories([]);
    isFlowIsModified && setIsFlowIsModified(false);
  };

  const FlowGalery = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space direction="vertical">
            <Space>
              <LuGalleryHorizontalEnd
                size={18}
                style={{
                  color: "#F05742",
                }}
              />
              <Text strong>Flows Gallery</Text>
            </Space>

            <Text>
              Select a flow from the gallery for your WhatsApp number and
              customize it to your needs.
            </Text>
          </Space>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <HiOutlineLightBulb
                  size={24}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Lead Generation</Text>
                  <Text type="secondary">All Businesses</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Lead Generation Flow")}
          >
            <Space direction="vertical">
              <Text>
                Use this form to capture basic details of customers like First
                and Last Name, Email, etc.
              </Text>
              <Text>
                You need to add your own terms and conditions and privacy
                policy.
              </Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Lead Generation</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <MdOutlineNoteAlt
                  size={24}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Course Sign-up</Text>
                  <Text type="secondary">Education, Fitness centers</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Sign up Flow")}
          >
            <Space direction="vertical">
              <Text>
                Let your customers ask about courses, trials, consulting, and
                sign up hassle-free!
              </Text>
              <Text>
                Simplify sign-up experience for your users and boost sales.
              </Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Sign up</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <PiBookOpenTextBold
                  size={23}
                  style={{
                    color: "#F05742",
                    marginBottom: 2,
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Browse Catalogue</Text>
                  <Text type="secondary">Retail, Restaurants</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Browse Catalogue Flow")}
          >
            <Space direction="vertical">
              <Text>
                Enhance customer experience with this efficient order form for
                seamless purchases.
              </Text>
              <Text>Provide a hassle-free ordering experience!</Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Customer Support</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <LuThumbsUp
                  size={22}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Feedback</Text>
                  <Text type="secondary">All Businesses</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Feedback Flow")}
          >
            <Space direction="vertical">
              <Text>
                Engage your audience, gather valuable opinions, and enhance your
                services with ease
              </Text>
              <Text>
                Simplify feedback collection and gain effortless insights.
              </Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Survey</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <MdOutlineCalendarMonth
                  size={24}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Appointments</Text>
                  <Text type="secondary">All Businesses</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Appointments Flow")}
          >
            <Space direction="vertical">
              <Text>
                Let your customers schedule, cancel, or reschedule with ease
                using this form.
              </Text>
              <Text>Managing appointments can't be more convenient!</Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Appointment Booking</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <LuTrophy
                  size={22}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Loyalty Points</Text>
                  <Text type="secondary">All Businesses</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Loyalty Points Flow")}
          >
            <Space direction="vertical">
              <Text>
                Use this form for customers to join loyalty programs, easily
                keeping track of points.
              </Text>
              <Text>
                Boost customer loyalty with a user-friendly, efficient
                experience.
              </Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Survey</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <LiaBusinessTimeSolid
                  size={24}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Facilities Booking</Text>
                  <Text type="secondary">Real Estate, Clubs</Text>
                </Space>
              </Space>
            }
            onClick={() => handleShowFlowGalery("Facilities Booking Flow")}
          >
            <Space direction="vertical">
              <Text>
                Empower customers, visitors, and members with a convenient
                booking form for effortless facility reservations.
              </Text>
              <Text>Simplify operations, boost customer satisfaction.</Text>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Appointment Booking</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={8}>
          <Card
            size="small"
            className="card-rounded-corner"
            hoverable
            title={
              <Space size="middle">
                <MdOutlineDashboardCustomize
                  size={24}
                  style={{
                    color: "#F05742",
                  }}
                />
                <Space direction="vertical" size={2}>
                  <Text strong>Custom Flow</Text>
                  <Text type="secondary">Create your own flow</Text>
                </Space>
              </Space>
            }
            onClick={() => {
              window.open(URIs.WABA.concat("/wa/manage/flows"));
            }}
          >
            <Space direction="vertical">
              <Text>
                Create your own flow based on your business needs. It can be
                anything like - Customer Support, Survey, etc. It is up to you.
              </Text>

              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tag>Custom Flow</Tag>

                <Button danger size="small">
                  Add
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    );
  };

  const handleFormSubmit = (values) => {
    let flowValues = {
      name: values.name,
      categories: values.categories,
      assets: JSON.parse(values.flowJSON),
    };
    dispatchMiddleware({
      type: "MWD_ADD_EDIT_WHATSAPP_FLOW",
      payload: {
        flowValues,
        component: "whatsapp-flows-form",
      },
    });
  };

  useEffect(() => {
    const flowToBeEdited = state.whatsappFlows?.flows?.find(
      (flow) => flow.id === state.filters.actionRowKey["whatsapp-flows-table"]
    );

    flowToBeEdited &&
      form.setFieldsValue({
        name: flowToBeEdited?.name,
        categories: flowToBeEdited?.categories,
        flowJSON: JSON.stringify(flowToBeEdited?.assets, null, 3),
      });
    flowToBeEdited && setCategories(flowToBeEdited?.categories || []);
    isFlowIsModified && setIsFlowIsModified(false);
  }, [state.whatsappFlows?.currentWhatsAppFlow]);

  return state.form?.["whatsapp-flows-form"].mode === "add" &&
    showFlowGalery ? (
    <FlowGalery />
  ) : state.apiCallStatus["whatsapp-flows-form"] &&
    state.apiCallStatus["whatsapp-flows-form"].loading ? (
    <FullPageLoader
      component="whatsapp-flows-form"
      loadingMessage="Submitting... Please wait..."
    />
  ) : state.apiCallStatus["whatsapp-flows-assets"] &&
    state.apiCallStatus["whatsapp-flows-assets"].loading ? (
    <FullPageLoader
      component="whatsapp-flows-assets"
      loadingMessage="Loading... Please wait..."
    />
  ) : (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={handleFormSubmit}
      onFinishFailed={() => {
        openNotificationWithIcon(
          "error",
          "Form Submission Failed! Please check the form for errors"
        );
      }}
      onFieldsChange={() => {
        state.whatsappFlows.currentWhatsAppFlow && setIsFlowIsModified(true);
      }}
    >
      <Popconfirm
        title="Are you sure you want to go back to Gallery?"
        placement="topRight"
        icon={
          <AiOutlineExclamationCircle
            size={18}
            style={{
              color: "#faad14",
              marginTop: "1px",
            }}
          />
        }
        onConfirm={handleBackToFlowGalery}
        okText="Yes"
        cancelText="No"
      >
        {state.form?.["whatsapp-flows-form"].mode === "add" && (
          <Space size={15} style={{ cursor: "pointer", marginBottom: 10 }}>
            <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
            Go back to Gallery
          </Space>
        )}
      </Popconfirm>

      <Row gutter={[15, 15]}>
        {/*Flow Name*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <TiFlowSwitch color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>Flow Name</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Provide a name for your new flow"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter your flow name",
                    },
                    {
                      max: 35,
                      message:
                        "Please enter a flow name less than 35 characters",
                    },
                  ]}
                  hasFeedback
                  className="kb-form-item"
                >
                  <Input
                    placeholder="Example: Lead Generation Flow"
                    style={{ width: 500 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Flow Category*/}
        <Col span={24}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <TbCategory color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text strong>Flow Category</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="categories"
                  label="Select a categories for your new flow"
                  rules={[
                    {
                      required: true,
                      message: "Please select a category for your flow",
                    },
                  ]}
                  hasFeedback
                  className="kb-form-item"
                >
                  <Dropdown
                    trigger="click"
                    dropdownRender={() => (
                      <Row
                        gutter={[0, 7]}
                        style={{
                          padding: "5px 10px",
                          background: "white",
                          marginTop: 5,
                          borderRadius: 5,
                          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        }}
                      >
                        <Col span={24}>
                          <Checkbox.Group
                            onChange={(selectedCategories) => {
                              setCategories(selectedCategories);
                              form.setFieldsValue({
                                categories: selectedCategories,
                              });
                              state.whatsappFlows.currentWhatsAppFlow &&
                                setIsFlowIsModified(true);
                            }}
                            defaultValue={categories}
                          >
                            <Space direction="vertical">
                              {whatsappFlowsCategories.map((category) => (
                                <Checkbox
                                  key={category.key}
                                  value={category.key}
                                >
                                  {category.value}
                                </Checkbox>
                              ))}
                            </Space>
                          </Checkbox.Group>
                        </Col>
                      </Row>
                    )}
                  >
                    <Button
                      style={{
                        textAlign: "left",
                        width: 500,
                      }}
                    >
                      {categories?.length > 0
                        ? `${categories.length} category(s) selected`
                        : "Select categories"}
                      <DownOutlined
                        style={{
                          float: "right",
                          marginTop: 7,
                        }}
                      />
                    </Button>
                  </Dropdown>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Flow Editor*/}
        <Col span={15}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <MdOutlineNoteAlt color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text
                  strong
                  style={{
                    marginLeft: 11,
                  }}
                >
                  Editor
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="flowJSON"
                  label="Create your flow using the editor below"
                  rules={[
                    {
                      validator: (_, value) => {
                        try {
                          JSON.parse(value);
                          return Promise.resolve();
                        } catch (error) {
                          return Promise.reject("Please enter valid JSON.");
                        }
                      },
                    },
                  ]}
                  hasFeedback
                  className="flow-json-text-area"
                >
                  <Input.TextArea
                    autoSize={{
                      minRows: 10,
                    }}
                    placeholder="Provide your flow JSON here"
                    style={{
                      maxHeight: 500,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/*Flow Preview*/}
        <Col span={9}>
          <Card size="small" className="card-rounded-corner">
            <Row>
              <Col span={1}>
                <VscPreview color="#F05742" size={20} />
              </Col>
              <Col span={23}>
                <Text
                  strong
                  style={{
                    marginLeft: 20,
                  }}
                >
                  Preview
                </Text>
              </Col>

              <Col span={24}>
                Preview your flow by clicking the image below after creation.
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  marginTop: 10,
                }}
              >
                <Image
                  src="/images/whatsapp-flows/whatsapp-flows-preview.png"
                  preview={false}
                  style={{
                    cursor: state.whatsappFlows.currentWhatsAppFlow?.preview
                      ?.preview_url
                      ? "pointer"
                      : "not-allowed",
                  }}
                  onClick={() => {
                    state.whatsappFlows.currentWhatsAppFlow?.preview
                      ?.preview_url &&
                      window.open(
                        state.whatsappFlows.currentWhatsAppFlow?.preview
                          ?.preview_url,
                        "_blank"
                      );
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Space>
            <Form.Item>
              <Button
                danger={
                  state.whatsappFlows.currentWhatsAppFlow?.status?.toLowerCase() ===
                  "published"
                }
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to close the flow?",
                    content:
                      "When clicked the OK button, the unsaved changes will be lost",
                    onOk() {
                      dispatchReducer({
                        type: "CLOSE_FORM_PANEL",
                        payload: {
                          component: "whatsapp-flows-form-panel",
                        },
                      });
                      state.whatsappFlows.currentWhatsAppFlow &&
                        dispatchReducer({
                          type: "SET_CURRENT_WHATSAPP_FLOW",
                          payload: {
                            currentWhatsAppFlow: null,
                          },
                        });
                    },
                    onCancel() {},
                  });
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            {state.whatsappFlows.currentWhatsAppFlow?.status?.toLowerCase() ===
              "draft" && !isFlowIsModified ? (
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatchMiddleware({
                      type: "CHANGE_WHATSAPP_FLOW_STATUS",
                      payload: {
                        flowId: state.whatsappFlows.currentWhatsAppFlow?.id,
                        flowStatus: "publish",
                        component: "whatsapp-flows-form",
                      },
                    });
                  }}
                >
                  Publish
                </Button>
              </Form.Item>
            ) : (
              state.whatsappFlows.currentWhatsAppFlow?.status?.toLowerCase() !==
                "published" && (
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              )
            )}

            {state.whatsappFlows.currentWhatsAppFlow?.status?.toLowerCase() ===
              "published" && (
              <Form.Item>
                <Button
                  onClick={() => {
                    confirm({
                      title: "Are you sure you want to deprecate the flow?",
                      content:
                        "When clicked the OK button, the flow will be deprecated permanently",
                      onOk() {
                        dispatchMiddleware({
                          type: "CHANGE_WHATSAPP_FLOW_STATUS",
                          payload: {
                            flowId: state.whatsappFlows.currentWhatsAppFlow?.id,
                            flowStatus: "deprecate",
                            component: "whatsapp-flows-form",
                          },
                        });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  Deprecate
                </Button>
              </Form.Item>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default WhatsAppFlowsForm;
