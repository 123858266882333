import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useAutomatedRepliesMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************* AUTOMATED REPLY *************////
      ////*******************************************////

      //Load automated reply data
      case "MWD_LOAD_AUTOMATED_REPLY_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [apiEndPoints.AUTOMATED_REPLIES],
          options: {
            method: "GET",
          },
          onSuccess: ([actionValue]) => {
            dispatchReducer({
              type: "RD_SET_AUTOMATED_REPLY",
              payload: { automatedValue: actionValue.data },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Automated Reply Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Add / Edit automated reply
      case "MWD_ADD_EDIT_AUTOMATED_REPLY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            ...(state.form["automated-replies-form"].mode === "add"
              ? [apiEndPoints.AUTOMATED_REPLIES]
              : [
                  apiEndPoints.AUTOMATED_REPLIES.concat(
                    "?id=",
                    state.filters.actionRowKey["automated-replies-table"]
                  ),
                ]),
          ],
          options: {
            method:
              state.form["automated-replies-form"].mode === "add"
                ? "POST"
                : "PUT",
            body: action.payload.automatedReplies,
          },
          onSuccess: ([automatedReplies]) => {
            if (state.form["automated-replies-form"].mode === "add") {
              dispatchReducer({
                type: "RD_ADD_AUTOMATED_REPLY",
                payload: {
                  automatedValue: automatedReplies,
                },
              });
              openNotificationWithIcon(
                "success",
                "Automated reply added successfully"
              );
            } else if (state.form["automated-replies-form"].mode === "edit") {
              dispatchReducer({
                type: "RD_EDIT_AUTOMATED_REPLY",
                payload: {
                  automatedValue: automatedReplies,
                },
              });
              openNotificationWithIcon(
                "success",
                "Automated reply modified successfully"
              );
            }
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "automated-replies-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "automated-replies-form",
                mode: "",
                title: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "automated-replies-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add/Edit Automated Reply Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete automated reply
      case "MWD_DELETE_AUTOMATED_REPLY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.AUTOMATED_REPLIES.concat(
              "?id=",
              action.payload.actionRowKey
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_DELETE_AUTOMATED_REPLY",
              payload: {
                actionRowKey: action.payload.actionRowKey,
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "automated-replies-table" },
            });
            openNotificationWithIcon(
              "success",
              "Automated reply deleted successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Automated Reply Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
