import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";

export const useAnalyticsMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////***************** ANALYTICS ***************////
      ////*******************************************////
      //Load responses by channel data
      case "MWD_LOAD_ANALYTICS_RESPONSES_BY_CHANNEL_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSES_BY_CHANNEL.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&leadDate=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responsesByChannel]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSES_BY_CHANNEL",
              payload: {
                responsesByChannel,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_ANALYTICS_CONVERSATION_COUNT_BY_CHANNEL_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_BY_CHANNEL.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&last_modified=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([conversationsByChannel]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_CHANNEL_DATA",
              payload: { conversationsByChannel },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_ANALYTICS_CONVERSATION_COUNT_BY_BROWSER_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_BY_BROWSER.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&last_modified=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([conversationsByBrowser]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_BROWSER_DATA",
              payload: { conversationsByBrowser },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_ANALYTICS_CONVERSATION_COUNT_BY_DATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_BY_DATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&last_modified=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([conversationsByDate]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_DATE_DATA",
              payload: { conversationsByDate },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_ANALYTICS_MESSAGE_COUNT_BY_CHANNEL_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.MESSAGE_BY_CHANNEL.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&messages/date=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([messagesByChannel]) => {
            dispatchReducer({
              type: "RD_SET_MESSAGE_CHANNEL_DATA",
              payload: { messagesByChannel },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_ANALYTICS_MESSAGE_COUNT_BY_DATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.MESSAGE_BY_DATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&messages/date=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([messagesByDate]) => {
            dispatchReducer({
              type: "RD_SET_MESSAGE_DATE_DATA",
              payload: { messagesByDate },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load responses by action data
      case "MWD_LOAD_ANALYTICS_RESPONSES_BY_ACTION_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSES_BY_ACTION.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&leadDate=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responsesByAction]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSES_By_ACTION",
              payload: {
                responsesByAction,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load responses by assigned data
      case "MWD_LOAD_ANALYTICS_RESPONSES_BY_ASSIGNED_PERSON_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSES_BY_ASSIGNED_PERSON.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&leadDate=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responsesByAssignedPerson]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSES_By_ASSIGNED_PERSON",
              payload: {
                responsesByAssignedPerson,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load responses by date
      case "MWD_LOAD_ANALYTICS_RESPONSES_BY_DATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.RESPONSES_BY_DATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&leadDate=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responsesByDate]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSES_BY_DATE",
              payload: {
                responsesByDate,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast message by date
      case "MWD_LOAD_ANALYTICS_BROADCAST_MESSAGE_BY_DATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.BROADCAST_MESSAGE_BY_DATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&sent=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastMessageByDate]) => {
            dispatchReducer({
              type: "RD_SET_BROADCAST_MESSAGE_BY_DATE",
              payload: {
                broadcastMessageByDate,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast message by template
      case "MWD_LOAD_ANALYTICS_BROADCAST_MESSAGE_BY_TEMPLATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.BROADCAST_MESSAGE_BY_TEMPLATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&sent=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastMessageByTemplate]) => {
            dispatchReducer({
              type: "RD_SET_BROADCAST_MESSAGE_BY_TEMPLATE",
              payload: {
                broadcastMessageByTemplate,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast message by channel
      case "MWD_LOAD_ANALYTICS_BROADCAST_MESSAGE_BY_CHANNEL_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.BROADCAST_MESSAGE_BY_CHANNEL.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&sent=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastMessageByChannel]) => {
            dispatchReducer({
              type: "RD_SET_BROADCAST_MESSAGE_BY_CHANNEL",
              payload: {
                broadcastMessageByChannel,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load message count by channel
      case "MWD_LOAD_ANALYTIC_LIVE_AGENT_COUNT_BY_CHANNEL_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.MESSAGE_COUNT_BY_CHANNEL.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&messages/date=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([liveagentByChannel]) => {
            dispatchReducer({
              type: "RD_SET_LIVE_AGENT_BY_CHANNEL",
              payload: {
                liveagentByChannel,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load responses by intent count
      case "MWD_LOAD_ANALYTICS_INTENTS_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.INTENT_COUNT.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&messages/date=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([intents]) => {
            dispatchReducer({
              type: "RD_SET_INTENTS_COUNT",
              payload: {
                intents,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast by template
      case "MWD_LOAD_ANALYTICS_BROADCAST_BY_TEMPLATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.BROADCAST_BY_TEMPLATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&timestamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastByTemplate]) => {
            dispatchReducer({
              type: "RD_SET_BROADCAST_BY_TEMPLATE",
              payload: {
                broadcastByTemplate,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast by channel
      case "MWD_LOAD_ANALYTICS_BROADCAST_BY_CHANNEL_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.BROADCAST_BY_CHANNEL.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&timestamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastByChannel]) => {
            dispatchReducer({
              type: "RD_SET_BROADCAST_BY_CHANNEL",
              payload: {
                broadcastByChannel,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast by date
      case "MWD_LOAD_ANALYTICS_BROADCAST_BY_DATE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.BROADCAST_BY_DATE.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&timestamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastByDate]) => {
            dispatchReducer({
              type: "RD_SET_BROADCAST_BY_DATE",
              payload: {
                broadcastByDate,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_CONVERSATION_COUNT_BY_LOCATION_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_BY_LOCATION.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&messages/date=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([conversationByLocation]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_LOCATION_DATA",
              payload: {
                conversationByLocation: conversationByLocation,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_NONE_INTENT_MESSAGE_COUNT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.NONE_INTENT_MESSAGE_COUNT.concat(
              action.payload.analyticsFilterParams.dateFilter !== undefined
                ? `?bot_id=${state.application.botInAction}&messages/date=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter !== undefined
                ? `${
                    action.payload.analyticsFilterParams.dateFilter ===
                    undefined
                      ? `?`
                      : `&`
                  }channel=${
                    action.payload.analyticsFilterParams.channelFilter
                  }`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([noneIntentMessage]) => {
            dispatchReducer({
              type: "RD_SET_NONE_INTENT_DATA",
              payload: {
                noneIntentMessage: noneIntentMessage,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load contact by created source
      case "MWD_LOAD_ANALYTICS_CONTACT_BY_CREATED_SOURCE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.ANALYTICS_CONTACTS.concat(
              `?bot_id=${
                state.application.botInAction
              }&propertyName=createdSource${
                action.payload.analyticsFilterParams.dateFilter !== undefined
                  ? `&timestamp=${action.payload.analyticsFilterParams.dateFilter}`
                  : ""
              }&channel=${action.payload.analyticsFilterParams.channelFilter}`
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([contactByCreatedSource]) => {
            dispatchReducer({
              type: "RD_SET_CONTACT_BY_CREATED_SOURCE_DATA",
              payload: {
                contactByCreatedSource,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load contact subscription status
      case "MWD_LOAD_ANALYTICS_CONTACT_SUBSCRIPTION_STATUS_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ANALYTICS_CONTACTS.concat(
              `?bot_id=${
                state.application.botInAction
              }&propertyName=isUnsubscribed${
                action.payload.analyticsFilterParams.dateFilter !== undefined
                  ? `&timestamp=${action.payload.analyticsFilterParams.dateFilter}`
                  : ""
              }&channel=${action.payload.analyticsFilterParams.channelFilter}`
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([contactSubscriptionStatus]) => {
            dispatchReducer({
              type: "RD_SET_CONTACT_SUBSCRIPTION_STATUS_DATA",
              payload: {
                contactSubscriptionStatus,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load contact by country
      case "MWD_LOAD_ANALYTICS_CONTACT_COUNT_BY_COUNTRY":
        let contactsByCountry = [
          {
            _id: "india",
            count: 8,
          },
          {
            _id: "Singapore",
            count: 6,
          },
          {
            _id: "Japan",
            count: 5,
          },
          {
            _id: "Australia",
            count: 4,
          },
          {
            _id: "United States of America",
            count: 3,
          },
          {
            _id: "United Kingdom",
            count: 2,
          },
          {
            _id: "Canada",
            count: 1,
          },
        ];
        dispatchReducer({
          type: "RD_SET_CONTACT_COUNT_BY_COUNTRY",
          payload: {
            contactByCountry: contactsByCountry,
          },
        });

        break;

      //Load active and inactive users count
      case "MWD_LOAD_ANALYTICS_ACTIVE_AND_INACTIVE_USERS_COUNT":
        const data = [
          {
            status: "Active users",
            month: "Jan",
            count: 10,
          },
          {
            status: "Inactive users",
            month: "Jan",
            count: 15,
          },
          {
            status: "Active users",
            month: "feb",
            count: 30,
          },
          {
            status: "Inactive users",
            month: "feb",
            count: 5,
          },
          {
            status: "Active users",
            month: "mar",
            count: 1,
          },
          {
            status: "Inactive users",
            month: "mar",
            count: 5,
          },
        ];
        dispatchReducer({
          type: "RD_SET_ACTIVE_AND_INACTIVE_USERS_COUNT",
          payload: {
            activeAndInactiveUsersCount: data,
          },
        });
        break;

      ////*******************************************////
      ////***************** UNRECOGNIZED INTENTS ***************////
      ////*******************************************////

      //Load unrecognized intents by assignee
      case "LOAD_ANALYTICS_UNRECOGNIZED_INTENTS_BY_ASSIGNEE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ANALYTICS_UNRECOGNIZED_INTENTS.concat(
              "/unrecognizedIntentsByAssignee?botId=",
              state.application.botInAction,
              action.payload.analyticsFilterParams.dateFilter
                ? `&timeStamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter?.length
                ? `&channel=${action.payload.analyticsFilterParams.channelFilter}`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([unrecognizedIntentsByAssignee]) => {
            dispatchReducer({
              type: "SET_UNRECOGNIZED_INTENTS_BY_ASSIGNEE",
              payload: {
                unrecognizedIntentsByAssignee,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load unrecognized intents by channel
      case "LOAD_ANALYTICS_UNRECOGNIZED_INTENTS_BY_CHANNEL":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ANALYTICS_UNRECOGNIZED_INTENTS.concat(
              "/unrecognizedIntentsByChannel?botId=",
              state.application.botInAction,
              action.payload.analyticsFilterParams.dateFilter
                ? `&timeStamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ``,
              action.payload.analyticsFilterParams.channelFilter?.length
                ? `&channel=${action.payload.analyticsFilterParams.channelFilter}`
                : ``
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([unrecognizedIntentsByChannel]) => {
            dispatchReducer({
              type: "SET_UNRECOGNIZED_INTENTS_BY_CHANNEL",
              payload: {
                unrecognizedIntentsByChannel,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Load unrecognized intents by status
      case "LOAD_ANALYTICS_UNRECOGNIZED_INTENTS_STATUS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ANALYTICS_UNRECOGNIZED_INTENTS.concat(
              "/unrecognizedIntentsByStatus?botId=",
              state.application.botInAction,
              action.payload.analyticsFilterParams.channelFilter?.length
                ? `&channel=${action.payload.analyticsFilterParams.channelFilter}`
                : "",
              action.payload.analyticsFilterParams.dateFilter
                ? `&timeStamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ""
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([unrecognizedIntentsByStatus]) => {
            dispatchReducer({
              type: "SET_UNRECOGNIZED_INTENTS_BY_STATUS",
              payload: {
                unrecognizedIntentsByStatus,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Load unrecognized intents count by date
      case "LOAD_UNRECOGNIZED_INTENTS_COUNT_BY_DATE":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ANALYTICS_UNRECOGNIZED_INTENTS.concat(
              "/unrecognizedIntentsBydate?botId=",
              state.application.botInAction,
              action.payload.analyticsFilterParams.channelFilter?.length
                ? `&channel=${action.payload.analyticsFilterParams.channelFilter}`
                : "",
              action.payload.analyticsFilterParams.dateFilter
                ? `&timeStamp=${action.payload.analyticsFilterParams.dateFilter}`
                : ""
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([unrecognizedIntentsByDate]) => {
            dispatchReducer({
              type: "SET_UNRECOGNIZED_INTENTS_BY_DATE",
              payload: {
                unrecognizedIntentsByDate,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
