import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const useConnectMiddleware = (middlewareDispatch, dependency) => {
  const { instance, accounts, inProgress } = useMsal();
  // const account = useAccount(accounts[0] || {});

  useEffect(() => {
    middlewareDispatch();
  }, [
    instance,
    accounts,
    inProgress,
    dependency?.date,
    dependency?.channel,
    dependency?.botInAction,
    dependency?.currentResponseCategory,
  ]);
};

export default useConnectMiddleware;
