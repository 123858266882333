import React from "react";
import { useNavigate } from "react-router-dom";
// import { useGlobalContext } from "../../contexts/globalContext";
import { Typography, Card, Row, Space, Col, Tabs } from "antd";
import {
  SettingOutlined,
  WalletOutlined,
  TeamOutlined,
  ApiOutlined,
  RobotOutlined,
  CopyOutlined,
  SafetyCertificateOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { AiOutlineContacts } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { BulbOutlined } from "@ant-design/icons";
import { BiBroadcast } from "react-icons/bi";

const { Text, Link } = Typography;

const AccountSettings = () => {
  // const [state] = useGlobalContext();
  const navigate = useNavigate();

  return (
    <Tabs
      type="card"
      items={[
        {
          key: "1",
          label: (
            <Space size={15}>
              <SettingOutlined size={18} style={{ margin: -4 }} />
              Settings
            </Space>
          ),
          children: (
            <>
              <Text>
                Your home for settings to manage your account, API, and more.
              </Text>

              <Row style={{ marginTop: 15 }}>
                <Col span={18}>
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/general");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <SettingOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 5px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text
                              style={{
                                marginLeft: 10,
                              }}
                              strong
                            >
                              General
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Account information, Contacts and Subscription
                            details
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>General</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/billing");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <WalletOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Billing
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Keep your billing details organized
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link> Billing</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/api");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <ApiOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              API
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            API to start integration between your system and our
                            platform
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link> API</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/account-users");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <TeamOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Users
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Manage users, roles, and permissions for your
                            organization.
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Users</Link>
                        </Row>
                      </Card>
                    </Col>

                    {/* {mode !== "PROD" && (
                        <Col span={8}>
                          <Card
                            hoverable
                            style={{
                              border: "2px solid #f3eeee",
                              borderRadius: "5px",
                              height: 180,
                            }}
                            onClick={() => {
                              navigate("/account-settings/notification");
                            }}
                          >
                            <Row>
                              <Col span={2}>
                                <Space size={15}>
                                  <NotificationOutlined
                                    style={{
                                      fontSize: 18,
                                      color: "#F05742",
                                      margin: "0px 0px 0px 5px",
                                    }}
                                  />
                                </Space>
                              </Col>
                              <Col span={22}>
                                <Text style={{ marginLeft: 10 }} strong>
                                  System Notifications
                                </Text>
                              </Col>
                            </Row>
                            <Row>
                              <Text style={{ marginTop: 10 }}>
                                Configure when and how you want to be notified
                              </Text>
                            </Row>
                            <Row
                              style={{
                                position: "absolute",
                                bottom: 15,
                              }}
                            >
                              <Link>System Notifications</Link>
                            </Row>
                          </Card>
                        </Col>
                      )} */}

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/responses");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <BulbOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Responses
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Manage the responses that your bot sends
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Responses</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/broadcast");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <BiBroadcast
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Broadcast
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Manage the default settings for the broadcast module
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Broadcast</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/live-agent");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <MdSupportAgent
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Live Agent
                            </Text>
                          </Col>
                        </Row>

                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Configure the availability of your Live Agent
                          </Text>
                        </Row>

                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Live Agent</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/automated-replies");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <RobotOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Automated Replies
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Configure the automated replies for your broadcast
                            templates
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Automated Replies</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/contacts");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <AiOutlineContacts
                              style={{
                                fontSize: 20,
                                color: "#F05742",
                                margin: "0px 0px 0px 7px",
                              }}
                            />
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 13 }} strong>
                              Contacts
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Manage the default settings for the contacts module
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Contacts</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/canned-responses");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <CopyOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Canned Response
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Respond to customer inquiries quickly and
                            consistently with predetermined answers
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link> Canned Response</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/content-moderate");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <SafetyCertificateOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Content Moderate
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Moderate live agent responses to remove offensive,
                            inappropriate and harmful content
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Content Moderate</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/webhooks");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <CloudUploadOutlined
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Webhooks
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Push real-time notifications to your application
                            when you receive response or a conversation
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Webhooks</Link>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={8}>
                      <Card
                        hoverable
                        style={{
                          border: "2px solid #f3eeee",
                          borderRadius: "5px",
                          height: 180,
                        }}
                        onClick={() => {
                          navigate("/account-settings/delivery-services");
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Space size={15}>
                              <MdOutlineLocalShipping
                                style={{
                                  fontSize: 18,
                                  color: "#F05742",
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Space>
                          </Col>
                          <Col span={22}>
                            <Text style={{ marginLeft: 10 }} strong>
                              Delivery Services
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Text style={{ marginTop: 10 }}>
                            Configure your order delivery by setting up the
                            delivery services
                          </Text>
                        </Row>
                        <Row
                          style={{
                            position: "absolute",
                            bottom: 15,
                          }}
                        >
                          <Link>Delivery Services</Link>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ),
        },
      ]}
    />
  );
};

export default AccountSettings;
