export const useBotConfigurationMiddleware = () => {
  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////**************** BOT CONFIGURATION *************////
      ////*******************************************////

      //Load Bot Configuration
      case "LOAD_BOT_CONFIGURATION":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        const botConfiguration = {
          dialogs: [
            {
              dialog_id: "welcome-dialog",
              dialog_description: "Welcome Dialog",
              intent_and_entities: {
                intent_name: "default welcome intent",
                expected_entities: [],
              },
              steps: [
                {
                  step_id: "welcome-user",
                  allow_interruption: false,
                  actions: [
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "interactive-card",
                        card_detail: {
                          image:
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGcxvjwfgZncv7wRlIZB0iKdQ7ZWqe15CAOi6Vkh2b&s",
                          message:
                            "Hello {{user.user_name}}, welcome to The World of Harsha the Luxury Tiles. \n\n What are you looking for",
                          buttons: [
                            { name: "Purchase Tiles", type: "callback" },
                            {
                              name: "Be a Distributor/Dealer",
                              type: "callback",
                            },
                            { name: "Live Agent", type: "callback" },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
            {
              dialog_id: "purchase-tiles",
              dialog_description: "Purchase Tiles",
              intent_and_entities: {
                intent_name: "purchase tiles",
                expected_entities: [],
              },
              steps: [
                {
                  step_id: "phone-number-step",
                  allow_interruptions: false,
                  skip_step_if_value_exists: {
                    data_belongs_to: "user",
                    property: "phone_number",
                  },
                  actions: [
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "text",
                        text: "Please enter your phone number",
                        store_user_input: {
                          data_belongs_to: "user",
                          property: "phone_number",
                        },
                        validator: "phone_number_validator",
                      },
                    },
                  ],
                },
                {
                  step_id: "alternate-phone-number-step",
                  allow_interruptions: false,
                  skip_step_if_value_exists: {
                    data_belongs_to: "user",
                    property: "alternate_phone_number",
                  },
                  actions: [
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "text",
                        text: "Enter an alternate number to contact you.",
                        store_user_input: {
                          data_belongs_to: "user",
                          property: "alternate_phone_number",
                        },
                        validator: "phone_number_validator",
                      },
                    },
                  ],
                },
                {
                  step_id: "site-step",
                  allow_interruptions: false,
                  skip_step_if_value_exists: {},
                  actions: [
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "text",
                        text: "Enter the site location you are looking the products for.",
                        store_user_input: {
                          data_belongs_to: "conversation",
                          property: "site_location",
                        },
                      },
                    },
                  ],
                },
                {
                  step_id: "thanks-step",
                  allow_interruption: false,
                  skip_step_if_value_exists: {},
                  actions: [
                    {
                      type: "log-to-response",
                      response: [
                        {
                          key: "partitionKey",
                          response_value: {
                            type: "static",
                            value: "Purchase Enquiry",
                          },
                        },
                        {
                          key: "phoneNumber",
                          response_value: {
                            type: "dynamic",
                            value: {
                              data_belongs_to: "user",
                              property: "phone_number",
                            },
                          },
                        },
                        {
                          key: "alternatePhoneNumber",
                          response_value: {
                            type: "dynamic",
                            value: {
                              data_belongs_to: "user",
                              property: "alternate_phone_number",
                            },
                          },
                        },
                        {
                          key: "site_location",
                          response_value: {
                            type: "dynamic",
                            value: {
                              data_belongs_to: "conversation",
                              property: "site_location",
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "interactive-card",
                        card_details: {
                          message:
                            "We thank you for showing interest on our product and providing the required information. We will contact you shortly.",
                          buttons: [
                            {
                              name: "Main Menu",
                              type: "callback",
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
            {
              dialog_id: "main-menu",
              dialog_description: "Main Menu",
              intent_and_entities: {
                intent_name: "main menu",
                expected_entities: [],
              },
              steps: [
                {
                  step_id: "welcome-step",
                  actions: [
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "interactive-card",
                        card_detail: {
                          message:
                            "Here are the main menu for you, What are you looking for?",
                          buttons: [
                            {
                              name: "Purchase Tiles",
                              type: "callback",
                            },
                            {
                              name: "Be a Distributor/Dealer",
                              type: "callback",
                            },
                            {
                              name: "Live Agent",
                              type: "callback",
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
            {
              dialog_id: "be-a-distributor-dealer",
              dialog_description: "Be a distributor/dealer",
              intent_and_entities: {
                intent_name: "be a distributor/dealer",
                expected_entities: [],
              },
              steps: [
                {
                  step_id: "thanks-step",
                  allow_interruption: false,
                  skip_step_if_value_exists: true,
                  actions: [
                    {
                      type: "log-to-response",
                      response: [
                        {
                          key: "partitionKey",
                          response_value: {
                            type: "static",
                            value: "Distributor/Dealer Enquiry",
                          },
                        },
                        {
                          key: "phoneNumber",
                          response_value: {
                            type: "dynamic",
                            value: {
                              data_belongs_to: "user",
                              property: "phone_number",
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: "prompt-user",
                      prompt: {
                        prompt_type: "interactive-card",
                        card_detail: {
                          message:
                            "Great to hear that you're interested in becoming one of our dealers! \n\n\n\n Please get in touch with Mr Sridhar @ +91 9655553927 or mail to marketing@harshatiles.in and we would contact you at the earliest.",
                          buttons: [
                            {
                              name: "Main Menu",
                              type: "callback",
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        };

        dispatchReducer({
          type: "SET_BOT_CONFIGURATION",
          payload: {
            botConfiguration,
          },
        });

      // callApi({
      //   urls: [apiEndPoints.BOT_CONFIGURATION],
      //   options: {
      //     method: "GET",
      //   },
      //   onSuccess: ([botConfiguration]) => {
      //   dispatchReducer({
      //     type: "SET_BOT_CONFIGURATION",
      //     payload: {
      //       botConfiguration,
      //     },
      //   });
      //     dispatchReducer({
      //       type: "SUCCESS_API_TRANSACTION",
      //       payload: { component: action.payload.component },
      //     });
      //   },
      //   onError: (error) => {
      //     appInsights.trackException({
      //       exception: error.developer_message?.description,
      //       properties: {
      //         title: "Load Bot Configuration Error",
      //         clientId: state.currentAccount.account.client_id,
      //         environment: mode,
      //       },
      //     });
      //     openErrorNotificationWithDeveloperMessage("error", error);
      //     dispatchReducer({
      //       type: "ERROR_API_TRANSACTION",
      //       payload: { component: action.payload.component, error },
      //     });
      //   },
      // });
      // break;
    }
  };
};
