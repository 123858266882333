import React, { useState, useEffect } from "react";
import {
  Space,
  Row,
  Col,
  Form,
  List,
  Input,
  Divider,
  Button,
  Dropdown,
  Tooltip,
  Select,
  Card,
  Popconfirm,
} from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RxDragHandleDots2 } from "react-icons/rx";
import {
  DownOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import {
  CustomParagraph,
  CustomTitle,
} from "../../../shared-components/CustomTyphography";

const { Option } = Select;

const AccountContactsForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [form] = Form.useForm();

  const [showContactProperties, setShowContactProperties] = useState(false);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [dragableColumns, setDragableColumns] = useState(null);

  const tableColumns = state.contactManagerSettings.tableColumns || [];
  const contactProperties =
    state.contactManagerSettings.contactProperties.filter(
      //remove table columns that are already in the list
      (contactProperty) =>
        !tableColumns.some(
          (tableColumn) => tableColumn.key === contactProperty.key
        )
    );

  const handleFormSubmit = (e) => {
    //Table columns is just used for display label
    const { contactFilter, columnName, filterType, columnValue, ...rest } = e;

    const enumValues = Object.keys(rest)
      .map((key) => {
        const [enumKey] = key.split("~*!*$*~display_name");
        return {
          _id: enumKey,
          key: enumKey,
          display_name: e[key] || enumKey,
          display_order:
            dragableColumns.findIndex(
              (tableColumn) => tableColumn.key === enumKey
            ) + 1,
          module: "contacts",
        };
      })
      .sort((a, b) => a.display_order - b.display_order);
    enumValues.push({
      _id: "contactFilter",
      key: "contactFilter",
      module: "contacts",
      type: contactFilter,
      value:
        contactFilter === "byColumnName/Value"
          ? {
              columnName,
              filterType,
              columnValue,
            }
          : contactFilter,
    });

    dispatchMiddleware({
      type: "UPDATE_CONTACT_SETTINGS",
      payload: {
        contactSettings: enumValues,
        component: "contacts-settings-form",
        successMessage: "Contact settings updated successfully",
      },
    });
  };

  useEffect(() => {
    const initialValues = tableColumns.reduce((fields, column) => {
      fields[column.key + "~*!*$*~display_name"] =
        column.display_name || column.key;
      return fields;
    }, {});

    // Set initial values for all fields including filters
    initialValues.contactFilter =
      state.contactManagerSettings.contactFilter?.type || "none";

    form.setFieldsValue(initialValues);

    if (
      state.contactManagerSettings.contactFilter?.type === "byColumnName/Value"
    ) {
      setShowAdvancedFilter(true);
      form.setFieldsValue({
        columnName:
          state.contactManagerSettings.contactFilter?.value.columnName,
        filterType:
          state.contactManagerSettings.contactFilter?.value.filterType,
        columnValue:
          state.contactManagerSettings.contactFilter?.value.columnValue,
      });
    }

    setDragableColumns(tableColumns);
  }, []);

  return state.apiCallStatus["contacts-settings-form"] &&
    state.apiCallStatus["contacts-settings-form"].loading ? (
    <FullPageLoader
      component="contacts-settings-form"
      loadingMessage="Submitting... Please wait..."
    />
  ) : (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <Row gutter={[20, 16]}>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <CustomTitle title="Custom Columns" />
            <CustomParagraph paragraph="These are the columns that will be displayed in the contacts table. You can select which columns to display and arrange them in order you prefer." />
          </Space>
        </Col>

        <Col span={16} offset={1}>
          {/* Form is used to display the label only */}
          <Form.Item
            label="Table Columns"
            style={{
              width: "60%",
            }}
          >
            <DragDropContext
              onDragEnd={(result) => {
                const { source, destination, type } = result;

                if (!destination) return;
                if (
                  source.droppableId === destination.droppableId &&
                  source.index === destination.index
                )
                  return;

                if (type === "group") {
                  const sourceIndex = source.index;
                  const destinationIndex = destination.index;

                  // While drag
                  const [removeColumn] = tableColumns.splice(sourceIndex, 1);
                  // While drop
                  tableColumns.splice(destinationIndex, 0, removeColumn);
                  // Add index +1 for display order
                  const rearrangedTableColumn = tableColumns.map(
                    (contact, index) => ({
                      ...contact,
                      display_order: index + 1,
                    })
                  );

                  setDragableColumns(rearrangedTableColumn);
                }
              }}
            >
              <Droppable droppableId="list" type="group">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {tableColumns.map((column, columnIndex) => (
                      <Draggable
                        draggableId={column.key}
                        index={columnIndex}
                        key={column.key}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              style={{
                                borderRadius: "5px",
                                padding: "5px",
                                backgroundColor: "#f5f5f5",
                                marginBottom: "7px",
                                height: "50px",
                              }}
                            >
                              <Row
                                align="middle"
                                key={column.key}
                                gutter={[0, 16]}
                                style={{
                                  marginTop: 3,
                                }}
                              >
                                <Col span={2}>
                                  <RxDragHandleDots2
                                    size={23}
                                    style={{
                                      color: "rgb(145 147 149)",
                                      marginBottom: -5,
                                    }}
                                  />
                                </Col>

                                <Col span={20}>
                                  <Form.Item
                                    name={column.key + "~*!*$*~display_name"}
                                    rules={[
                                      {
                                        max: 100,
                                        message:
                                          "Display name should not exceed 100 characters",
                                      },
                                    ]}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Input
                                      defaultValue={
                                        column.display_name || column.key
                                      }
                                      suffix={
                                        <Dropdown
                                          trigger={["click"]}
                                          open={
                                            showContactProperties
                                              ? column.key ===
                                                showContactProperties?.key
                                              : false
                                          }
                                          onOpenChange={(visible) => {
                                            setShowContactProperties(
                                              visible ? column : false
                                            );
                                          }}
                                          dropdownRender={() => (
                                            <div
                                              style={{
                                                borderRadius: 5,
                                                boxShadow:
                                                  "0 4px 8px 0 rgba(0,0,0,0.2)",
                                                background: "white",
                                                padding: 12,
                                                overflow: "auto",
                                                overflowX: "hidden",
                                                maxHeight: 400,
                                              }}
                                            >
                                              <Space direction="vertical">
                                                <Input
                                                  placeholder="Search by column name"
                                                  onChange={(e) => {
                                                    const currentValue =
                                                      e.target.value;
                                                    dispatchReducer({
                                                      type: "RD_SET_SEARCH_TEXT",
                                                      payload: {
                                                        component:
                                                          "contacts-column-search-text-box",
                                                        searchText:
                                                          currentValue,
                                                      },
                                                    });
                                                  }}
                                                  autoFocus
                                                  value={
                                                    state.filters.searchText?.[
                                                      "contacts-column-search-text-box"
                                                    ]
                                                  }
                                                  prefix={<SearchOutlined />}
                                                  allowClear={{
                                                    clearIcon: (
                                                      <CloseOutlined />
                                                    ),
                                                  }}
                                                />
                                                <hr
                                                  style={{
                                                    border: "1px solid #f0f0f0",
                                                  }}
                                                />

                                                <List
                                                  dataSource={
                                                    state.filters.searchText?.[
                                                      "contacts-column-search-text-box"
                                                    ]
                                                      ? contactProperties
                                                          .filter(
                                                            (contactProperty) =>
                                                              contactProperty.key
                                                                .toLowerCase()
                                                                .includes(
                                                                  state.filters.searchText?.[
                                                                    "contacts-column-search-text-box"
                                                                  ].toLowerCase()
                                                                )
                                                          )
                                                          // remove table columns that are already in the list
                                                          .filter(
                                                            (contactProperty) =>
                                                              !tableColumns.some(
                                                                (tableColumn) =>
                                                                  tableColumn.key ===
                                                                  contactProperty.key
                                                              )
                                                          )
                                                      : contactProperties
                                                          .filter(
                                                            (contactProperty) =>
                                                              !tableColumns.some(
                                                                (tableColumn) =>
                                                                  tableColumn.key ===
                                                                  contactProperty.key
                                                              )
                                                          )
                                                          .sort((a, b) =>
                                                            a.key.localeCompare(
                                                              b.key || ""
                                                            )
                                                          )
                                                  }
                                                  renderItem={(
                                                    contactProperty
                                                  ) => (
                                                    <List.Item
                                                      style={{
                                                        cursor: "pointer",
                                                        padding: "8px 0px",
                                                      }}
                                                      onClick={() => {
                                                        dispatchReducer({
                                                          type: "SET_SELECTED_CONTACT_PROPERTY",
                                                          payload: {
                                                            tableColumns:
                                                              // based on the index of the column
                                                              tableColumns.map(
                                                                (
                                                                  property,
                                                                  index
                                                                ) =>
                                                                  index ===
                                                                  columnIndex
                                                                    ? contactProperty
                                                                    : property
                                                              ),
                                                          },
                                                        });

                                                        form.setFieldsValue({
                                                          [column.key +
                                                          "~*!*$*~display_name"]:
                                                            contactProperty.key,
                                                        });

                                                        setDragableColumns(
                                                          tableColumns.map(
                                                            (property, index) =>
                                                              index ===
                                                              columnIndex
                                                                ? contactProperty
                                                                : property
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      {contactProperty.key}
                                                    </List.Item>
                                                  )}
                                                />
                                              </Space>
                                            </div>
                                          )}
                                        >
                                          <DownOutlined
                                            style={{
                                              color: "rgb(145 147 149)",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Dropdown>
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <Col
                                  span={2}
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {state.currentUser.permission
                                    .ACCOUNT_CONTACTS_DELETE ? (
                                    <Tooltip
                                      title={
                                        tableColumns.length < 6
                                          ? "Minimum of 5 columns required"
                                          : "Remove"
                                      }
                                      placement="topRight"
                                    >
                                      <Popconfirm
                                        title="Are you sure you want to delete this column?"
                                        placement="topRight"
                                        icon={
                                          <AiOutlineExclamationCircle
                                            size={18}
                                            style={{
                                              color: "#faad14",
                                              marginTop: "1px",
                                            }}
                                          />
                                        }
                                        onConfirm={() => {
                                          dispatchMiddleware({
                                            type: "DELETE_CONTACT_PROPERTY",
                                            payload: {
                                              contactProperty: column,
                                            },
                                          });

                                          setDragableColumns(
                                            tableColumns.filter(
                                              (property) =>
                                                property.key !== column.key
                                            )
                                          );
                                        }}
                                        cancelButtonProps={{
                                          style: {
                                            color: "white",
                                            backgroundColor: "#f05742",
                                          },
                                        }}
                                        okType="default"
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <DeleteOutlined
                                          style={{
                                            color:
                                              state.contactManagerSettings
                                                .tableColumns.length < 6
                                                ? "#C0C0C0"
                                                : "#F05742",
                                            cursor:
                                              state.contactManagerSettings
                                                .tableColumns.length < 6
                                                ? "not-allowed"
                                                : "pointer",
                                          }}
                                        />
                                      </Popconfirm>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={
                                        "You do not have required permission"
                                      }
                                      placement="topRight"
                                    >
                                      <DeleteOutlined
                                        style={{
                                          color: "#C0C0C0",
                                          cursor: "not-allowed",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Col>

                                {
                                  // show plus icon if it is the last item and the length is less than 7
                                  tableColumns.length - 1 === columnIndex &&
                                    tableColumns.length < 7 && (
                                      <span
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <PlusOutlined
                                          style={{
                                            color: "#F05742",
                                            cursor: "pointer",
                                            float: "right",
                                            marginRight: "10px",
                                            marginTop: "1px",
                                          }}
                                          onClick={() => {
                                            // last item should have value before adding new item
                                            if (
                                              state.contactManagerSettings
                                                .tableColumns[
                                                state.contactManagerSettings
                                                  .tableColumns.length - 1
                                              ].key !== ""
                                            ) {
                                              dispatchReducer({
                                                type: "RD_ADD_CONTACT_PROPERTY",
                                              });
                                            }
                                          }}
                                        />
                                      </span>
                                    )
                                }
                              </Row>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Space direction="vertical" size={0}>
            <CustomTitle title="Filter Contacts" />
            <CustomParagraph paragraph="You can set filter for personalizing the contacts for the users. Based on the filter, the user will be able to see the contacts." />
          </Space>
        </Col>

        <Col span={9} offset={1}>
          <Form.Item
            name="contactFilter"
            label="Filter Options"
            rules={[
              {
                required: true,
                message: "Please select a filter",
              },
            ]}
          >
            <Select
              onChange={(value) => {
                if (value === "byColumnName/Value") {
                  setShowAdvancedFilter(true);
                } else {
                  setShowAdvancedFilter(false);
                }
              }}
            >
              <Option value="none">None</Option>
              <Option value="createdBy">Created By</Option>
              <Option value="byColumnName/Value">By Column Name/Value</Option>
            </Select>
          </Form.Item>

          {showAdvancedFilter && (
            <Row gutter={[20, 16]}>
              <Col span={7}>
                <Form.Item
                  name="columnName"
                  label="Column Name"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a column name",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select a column name"
                    showSearch
                    optionFilterProp="children"
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {contactProperties
                      .concat(tableColumns)
                      .map((contactProperty, index) => (
                        <Option value={contactProperty.key} key={index}>
                          {contactProperty.display_name || contactProperty.key}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  label="Type"
                  name="filterType"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a filter type",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select a filter type"
                  >
                    <Option value="equals">Equals</Option>
                    <Option value="notEquals">Not Equals</Option>
                    <Option value="contains">Contains</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="User Column Name"
                  name="columnValue"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select a filter value",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select a filter value"
                    showSearch
                    optionFilterProp="children"
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {state.accountUsers.users?.[0] &&
                      Object.keys(state.accountUsers.users?.[0])
                        .filter(
                          (columnName) =>
                            [
                              "_id",
                              "created_at",
                              "colorCode",
                              "userID",
                              "alertTone",
                            ].indexOf(columnName) === -1
                        )
                        .map((columnName, index) => (
                          <Option value={columnName} key={index}>
                            {columnName
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, function (str) {
                                return str.toUpperCase();
                              })}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col span={8}>
          <Card size="small" className="filter-note-card">
            <CustomTitle title="NOTE:" />
            <ul
              style={{
                paddingLeft: 25,
                marginBottom: 0,
              }}
            >
              <li>
                <CustomParagraph
                  paragraph={
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <b>None:</b> No filter will be applied. All contacts will
                      be displayed.
                    </p>
                  }
                />
              </li>
              <li>
                <CustomParagraph
                  paragraph={
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <b>Created By:</b> Contacts will be filtered based on the
                      user who created the contact.
                    </p>
                  }
                />
              </li>
              <li>
                <CustomParagraph
                  paragraph={
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <b>By Column Name/Value:</b> Contacts will be filtered
                      based on the contact field value with logged in user field
                      value.
                    </p>
                  }
                />
              </li>
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Divider />
          <Space>
            <Button>Cancel</Button>

            <Tooltip
              title={
                !state.currentUser.permission.ACCOUNT_CONTACTS_EDIT
                  ? "You do not have required permission to save changes"
                  : ""
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={!state.currentUser.permission.ACCOUNT_CONTACTS_EDIT}
              >
                Save
              </Button>
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default AccountContactsForm;
