import { useGlobalContext } from "../../contexts/globalContext";
import { TinyArea } from "@ant-design/plots";
import { Typography, Row, Col, Space } from "antd";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";

const { Text, Title } = Typography;

const BroadcastsCount = ({ period }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({
        type: "SET_BROADCASTS_COUNT",
        payload: {
          broadcastsCount: undefined,
        },
      });
      dispatchMiddleware({
        type: "LOAD_BROADCASTS_COUNT",
        payload: { component: "dashboard-broadcasts-count-chart" },
      });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  let broadcastsCount = state.dashBoard.broadcastsCount;

  let dataTotal = broadcastsCount?.reduce(
    (previousResponseCount, currentResponseCount) =>
      previousResponseCount + currentResponseCount,
    0
  );

  const config = {
    data: broadcastsCount,
    height: 50,
    autoFit: true,
    smooth: true,
    line: {
      color: "#a8071a",
    },
    areaStyle: {
      fill: "#ffccc7",
    },
  };

  return (
    <>
      {broadcastsCount ? (
        <Row>
          <Col span={24}>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ textAlign: "center" }}>
                BROADCASTS
              </Title>
              <Title level={1} style={{ textAlign: "center" }}>
                {dataTotal}
              </Title>
            </Space>
          </Col>
          <Col span={24}>
            <TinyArea {...config} />
          </Col>
          <Col span={24}>
            <Text type="secondary" italic>
              last {period} days
            </Text>
          </Col>
        </Row>
      ) : (
        <ComponentLoader
          component="dashboard-broadcasts-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default BroadcastsCount;
