const whatsappFlowsReducer = (state, action) => {
  switch (action.type) {
    case "SET_WHATSAPP_FLOWS":
      return {
        ...state,
        whatsappFlows: action.payload.whatsappFlows,
      };

    case "SET_WHATSAPP_FLOWS_GALLERY":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          whatsappFlowsGallery: action.payload.whatsappFlowsGallery,
        },
      };

    case "ADD_WHATSAPP_FLOW":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          flows: [
            action.payload.createdWhatsAppFlow,
            ...state.whatsappFlows.flows,
          ],
        },
      };

    case "EDIT_WHATSAPP_FLOW":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          flows: state.whatsappFlows.flows.map((flow) => {
            if (flow.id === action.payload.updatedWhatsAppFlow.id) {
              return {
                ...flow,
                ...action.payload.updatedWhatsAppFlow,
              };
            }
            return flow;
          }),
        },
      };

    case "SET_WHATSAPP_FLOWS_ASSETS":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          flows: state.whatsappFlows.flows.map((flow) => {
            if (flow.id === action.payload.flowId) {
              return {
                ...flow,
                assets:
                  action.payload.whatsappFlowsAssets?.assets?.[0]?.flowJson,
                preview: action.payload.whatsappFlowsAssets?.preview,
              };
            }
            return flow;
          }),
          currentWhatsAppFlow: {
            ...(state.whatsappFlows.flows.find(
              (flow) => flow.id === action.payload.flowId
            ) || {}),
            assets: action.payload.whatsappFlowsAssets?.assets?.[0]?.flowJson,
            preview: action.payload.whatsappFlowsAssets?.preview,
          },
        },
      };

    case "SET_CURRENT_WHATSAPP_FLOW":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          currentWhatsAppFlow: action.payload.currentWhatsAppFlow,
        },
      };

    case "CLEAR_CURRENT_WHATSAPP_FLOW":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          currentWhatsAppFlow: null,
        },
      };

    case "CHANGE_WHATSAPP_FLOW_STATUS":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          flows: state.whatsappFlows.flows.map((flow) => {
            if (flow.id === action.payload.flowId) {
              return {
                ...flow,
                status:
                  action.payload.flowStatus.toLowerCase() === "publish"
                    ? "Published"
                    : action.payload.flowStatus.toLowerCase() === "deprecate"
                    ? "Deprecated"
                    : "",
              };
            }
            return flow;
          }),
        },
      };

    case "DELETE_SINGLE_FLOW":
      return {
        ...state,
        whatsappFlows: {
          ...state.whatsappFlows,
          flows: state.whatsappFlows.flows.filter(
            (flow) => flow.id !== action.payload.flowId
          ),
        },
      };

    case "CLEAR_WHATSAPP_FLOWS":
      return {
        ...state,
        whatsappFlows: null,
      };

    default:
      return state;
  }
};

export default whatsappFlowsReducer;
