import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useIntentsAndPhrasesMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////***************************************************////
      ////**************** INTENTS AND PHRASES *************////
      ////***************************************************////
      //Load INTENTS AND PHRASES data
      case "MWD_LOAD_INTENTS_AND_PHRASES_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.INTENTS_AND_PHRASES.concat(
              "?botid=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
          },
          onSuccess: ([inp]) => {
            dispatchReducer({
              type: "RD_SET_INTENTS_AND_PHRASES",
              inp,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Intents and Phrases Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Update INTENTS AND PHRASES
      case "MWD_UPDATE_INTENTS_AND_PHRASES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.INTENTS_AND_PHRASES.concat(
              "?botid=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
            body: action.payload.inp,
          },
          onSuccess: ([response]) => {
            dispatchReducer({
              type: "RD_UPDATE_INTENTS_AND_PHRASES",
              payload: {
                inp: response,
              },
            });
            openNotificationWithIcon("success", "Phrases updated successfully");
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Phrases Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //TEST INTENTS
      case "MWD_TEST_INTENTS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.TEST_INTENTS.concat(
              "?botid=",
              state.application.botInAction
            ).concat("&text=", action.payload.phrase),
          ],
          options: {
            method: "GET",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
          },
          onSuccess: ([testResult]) => {
            dispatchReducer({
              type: "RD_SET_TESTED_INTENT",
              testResult,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Test Intent Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
