import React, { useState } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Tabs, Space, Layout } from "antd";
import AccountUsersHeader from "./AccountUsersHeader";
import AccountUsersTable from "./AccountUsersTable";
import AccountUsersFormPanel from "./AccountUsersFormPanel";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { TeamOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AccessDenied from "../../../shared-components/AccessDenied";
import GetStarted from "../../../shared-components/GetStarted";

const AccountUsers = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const [showAccountUserScreen, setShowAccountUserScreen] = useState(false);

  useConnectMiddleware(() => {
    state.currentUser.permission.ACCOUNT_USERS_VIEW &&
      dispatchMiddleware({
        type: "MWD_LOAD_ACCOUNT_USERS_DATA",
        payload: { component: "account-users-table" },
      });
  });

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {!state.currentUser.permission.ACCOUNT_USERS_VIEW ? (
        <AccessDenied />
      ) : state.accountUsers.users &&
        state.accountUsers.users.length === 0 &&
        !showAccountUserScreen ? (
        <Layout.Content
          style={{
            height: "97%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GetStarted
            source="account-users"
            setShowAccountUserScreen={setShowAccountUserScreen}
          />
        </Layout.Content>
      ) : state.accountUsers.users || showAccountUserScreen ? (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <TeamOutlined size={18} style={{ margin: -4 }} />
                  Users
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <AccountUsersHeader />
                  </Col>
                  <Col span={24}>
                    <AccountUsersTable />
                  </Col>
                  <AccountUsersFormPanel />
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["account-users-table"] &&
            state.apiCallStatus["account-users-table"].loading && (
              <TabLoader
                component="account-users-table"
                loadingMessage="Synchronizing users..."
                errorMessage="Error synchronizing users"
              />
            )
          }
        />
      ) : (
        <FullPageLoader
          component="account-users-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default AccountUsers;
