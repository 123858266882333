import React from "react";
import { Typography } from "antd";

const { Text, Link } = Typography;

export const CustomTitle = ({ title }) => {
  return (
    <Text
      strong
      style={{
        fontSize: 15,
      }}
    >
      {title}
    </Text>
  );
};

export const CustomParagraph = ({ paragraph, urlLabel, urlValue }) => {
  return (
    <Text
      style={{
        fontSize: 13,
      }}
    >
      {paragraph}{" "}
      {urlLabel && urlValue && (
        <Link href={urlValue} target="_blank">
          {urlLabel}
        </Link>
      )}
    </Text>
  );
};
