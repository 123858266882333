import { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Space,
  Button,
  Popconfirm,
  Tooltip,
} from "antd";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { TiArrowSync } from "react-icons/ti";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import openNotificationWithIcon from "../../../../utils/notification";
import { encryptKey, getUserDateTime } from "../../../../utils/utility";
import { Translation } from "../../../../translation/translation";
import { ComponentLoader } from "../../../../shared-components/LoadingIndicator";
import AccessDenied from "../../../../shared-components/AccessDenied";
import AppRegistrationForm from "./AppRegistrationForm";
import OAuthRefreshToken from "./OAuthRefreshToken";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { URIs } from "../../../../constants/env";

const { Text, Title } = Typography;

const AppRegistration = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  // Get translated text
  const appRegistration = Translation().accountSettings.api.appRegistration;
  let appRegistrationValues =
    state.currentAccount.account.app_registration || {};

  const [maskSecretKey, setMaskSecretKey] = useState(true);
  const [showFullRefreshToken, setShowFullRefreshToken] = useState(false);
  const [showFullAccessToken, setShowFullAccessToken] = useState(false);

  const handleShowToken = (tokenType = "refresh_token") => {
    if (tokenType.toLowerCase() === "refresh_token") {
      setShowFullRefreshToken(!showFullRefreshToken);
    } else {
      setShowFullAccessToken(!showFullAccessToken);
    }
  };

  const copyToClipboard = (token, type = "REFRESH") => {
    navigator.clipboard.writeText(token);
    openNotificationWithIcon(
      "success",
      `${type === "REFRESH" ? "Refresh" : "Access"} token copied to clipboard`
    );
  };

  function isFutureDate(inputDateString) {
    let inputDate = new Date(inputDateString);
    let currentDate = new Date();

    if (inputDate > currentDate) {
      return true;
    } else {
      return false;
    }
  }

  return state.currentUser.permission.ACCOUNT_APP_REGISTRATION_VIEW ? (
    Object.keys(appRegistrationValues)?.length &&
    !state.formPanel?.["app-registration-form"]?.visible ? (
      <Card size="small">
        <Row gutter={[0, 5]}>
          <OAuthRefreshToken />

          <Col span={24}>
            <Space
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={5}>{appRegistration.appDetails}</Title>
              <Space size="large">
                <Tooltip
                  title={
                    state.currentUser.permission.ACCOUNT_APP_REGISTRATION_EDIT
                      ? ""
                      : appRegistration.noAccess
                  }
                  placement="topRight"
                >
                  <Button
                    danger
                    size="small"
                    disabled={
                      !state.currentUser.permission
                        .ACCOUNT_APP_REGISTRATION_EDIT
                    }
                    onClick={() => {
                      dispatchReducer({
                        type: "OPEN_FORM_PANEL",
                        payload: {
                          component: "app-registration-form",
                          title: "Edit App Details",
                        },
                      });

                      dispatchReducer({
                        type: "SET_FORM_MODE",
                        payload: {
                          component: "app-registration-form",
                          mode: "edit",
                          title: "Edit App Details",
                        },
                      });
                    }}
                  >
                    {appRegistration.editButton}
                  </Button>
                </Tooltip>

                {state.currentUser.permission
                  .ACCOUNT_APP_REGISTRATION_DELETE ? (
                  state.apiCallStatus?.["app-delete"]?.loading ? (
                    <ComponentLoader
                      component="app-delete"
                      loadingMessage=""
                      errorMessage=""
                    />
                  ) : (
                    <Popconfirm
                      title="Are you sure you want to delete this app?"
                      placement="topRight"
                      icon={
                        <AiOutlineExclamationCircle
                          size={18}
                          style={{
                            color: "#faad14",
                            marginTop: "1px",
                          }}
                        />
                      }
                      onConfirm={() => {
                        dispatchMiddleware({
                          type: "DELETE_APP",
                          payload: {
                            appRegistration: appRegistrationValues,
                            component: "app-delete",
                          },
                        });
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" size="small">
                        {appRegistration.deleteButton}{" "}
                      </Button>
                    </Popconfirm>
                  )
                ) : (
                  <Tooltip
                    title={appRegistration.noAccess}
                    placement="topRight"
                  >
                    <Button type="primary" size="small" disabled>
                      {appRegistration.deleteButton}
                    </Button>
                  </Tooltip>
                )}
              </Space>
            </Space>
          </Col>
          <Col span={24}>
            <Card type="inner" size="small">
              <Space direction="vertical">
                <Space>
                  <Text strong>{appRegistration.appName.title}</Text>
                  <Text type="secondary">{appRegistrationValues.appName}</Text>
                </Space>
                <Text style={{ fontSize: "12px" }}>
                  {appRegistration.appName.description}
                </Text>
              </Space>
            </Card>
          </Col>

          <Col span={24}>
            <Card type="inner" size="small">
              <Space direction="vertical">
                <Space>
                  <Text strong>{appRegistration.clientId.title}</Text>
                  <>
                    <Text type="secondary">
                      {appRegistrationValues.AD_B2C_CLIENT_ID}
                    </Text>
                    <MdContentCopy
                      style={{ cursor: "pointer", marginLeft: 10 }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          appRegistrationValues.AD_B2C_CLIENT_ID
                        );
                        openNotificationWithIcon(
                          "success",
                          "Client Id copied to clipboard"
                        );
                      }}
                    />
                  </>
                </Space>
                <Text style={{ fontSize: "12px" }}>
                  {appRegistration.clientId.description}
                </Text>
              </Space>
            </Card>
          </Col>

          <Col span={24}>
            <Card type="inner" size="small">
              <Space direction="vertical">
                <Space>
                  <Text strong>{appRegistration.clientSecret.title}</Text>
                  {appRegistrationValues.AD_B2C_CLIENT_SECRET ? (
                    <>
                      <Text type="secondary">
                        {maskSecretKey
                          ? "*".repeat(
                              appRegistrationValues.AD_B2C_CLIENT_SECRET.length
                            )
                          : appRegistrationValues.AD_B2C_CLIENT_SECRET}
                      </Text>
                      {maskSecretKey ? (
                        <EyeOutlined
                          style={{ marginLeft: 10 }}
                          onClick={() => setMaskSecretKey(false)}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ marginLeft: 10 }}
                          onClick={() => setMaskSecretKey(true)}
                        />
                      )}
                      <MdContentCopy
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        onClick={() => {
                          const secretKey =
                            appRegistrationValues.AD_B2C_CLIENT_SECRET;
                          navigator.clipboard.writeText(secretKey);
                          openNotificationWithIcon(
                            "success",
                            "Secret key copied to clipboard"
                          );
                        }}
                      />

                      {state.currentUser.permission
                        .ACCOUNT_APP_REGISTRATION_REGENERATE_SECRET ? (
                        state.apiCallStatus?.["app-secret-regenerate"]
                          ?.loading ? (
                          <ComponentLoader
                            component="app-secret-regenerate"
                            loadingMessage=""
                            errorMessage=""
                          />
                        ) : (
                          <Popconfirm
                            title="Are you sure you want to regenerate the app secret?"
                            placement="topRight"
                            icon={
                              <AiOutlineExclamationCircle
                                size={18}
                                style={{
                                  color: "#faad14",
                                  marginTop: "1px",
                                }}
                              />
                            }
                            onConfirm={() => {
                              dispatchMiddleware({
                                type: "REGENERATE_APP_SECRET",
                                payload: {
                                  appRegistration: appRegistrationValues,
                                  component: "app-secret-regenerate",
                                },
                              });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <TiArrowSync
                              size={18}
                              style={{ cursor: "pointer", marginLeft: 10 }}
                            />
                          </Popconfirm>
                        )
                      ) : (
                        <TiArrowSync
                          size={18}
                          style={{ marginLeft: 10, color: "#C0C0C0" }}
                        />
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </Space>

                <Text style={{ fontSize: "12px" }}>
                  {appRegistration.clientSecret.description}
                </Text>

                <Space>
                  <Text type="secondary">
                    {getUserDateTime(
                      appRegistrationValues.createdAt,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}{" "}
                    -{" "}
                    {getUserDateTime(
                      appRegistrationValues.expiresAt,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                </Space>
              </Space>
            </Card>
          </Col>

          <Col span={24}>
            <Card type="inner" size="small">
              <Space direction="vertical">
                <Text strong>{appRegistration.redirectUri.title}</Text>

                <Text style={{ fontSize: "12px" }}>
                  {appRegistration.redirectUri.description1}
                  <br />
                  {appRegistration.redirectUri.description2}
                </Text>

                {appRegistrationValues?.redirectUris?.length ? (
                  appRegistrationValues.redirectUris.map((uri, index) => (
                    <Text key={index} type="secondary">
                      {uri}
                    </Text>
                  ))
                ) : (
                  <Text type="secondary">
                    {appRegistration.redirectUri.noRedirectUri}
                  </Text>
                )}
              </Space>
            </Card>
          </Col>
          <Col span={24}>
            <Card type="inner" size="small">
              <Space direction="vertical">
                <Text strong>Refresh Token: </Text>
                <Col span={14}>
                  <Text style={{ fontSize: "12px" }}>
                    Refresh tokens are long-lived credentials used to obtain new
                    access tokens when the current ones expire, ensuring
                    uninterrupted access to resources without user
                    re-authentication.
                  </Text>
                </Col>
                {appRegistrationValues?.refresh_token &&
                  isFutureDate(appRegistrationValues?.refresh_token_expiry) && (
                    <Col span={14}>
                      <Text style={{ fontSize: "12px", margin: "10px 0px" }}>
                        <IoMdInformationCircleOutline
                          size="15"
                          style={{ marginBottom: "-2px", color: "#ff4d4f" }}
                        />{" "}
                        The below refresh token will expire on {""}
                        {getUserDateTime(
                          appRegistrationValues.refresh_token_expiry,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>

                      <Space style={{ display: "flex" }}>
                        <Card
                          style={{
                            backgroundColor: "#f8f8f8",
                            borderRadius: "5px",
                            padding: "0px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "11px",
                              wordBreak: "break-all",
                            }}
                          >
                            {showFullRefreshToken
                              ? appRegistrationValues?.refresh_token
                              : `${appRegistrationValues?.refresh_token.substring(
                                  0,
                                  285
                                )}...`}
                            <Button
                              type="link"
                              size="small"
                              style={{ fontSize: "12px" }}
                              onClick={() => handleShowToken("refresh_token")}
                            >
                              {showFullRefreshToken ? "Show less" : "Show more"}
                            </Button>
                          </Text>
                        </Card>
                        <Button
                          style={{
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() =>
                            copyToClipboard(
                              appRegistrationValues?.refresh_token,
                              "REFRESH"
                            )
                          }
                          icon={<MdContentCopy />}
                        ></Button>
                      </Space>
                    </Col>
                  )}

                {appRegistrationValues?.access_token &&
                  isFutureDate(appRegistrationValues?.refresh_token_expiry) &&
                  isFutureDate(appRegistrationValues?.access_token_expiry) && (
                    <Col span={14}>
                      <Text style={{ fontSize: "12px", margin: "10px 0px" }}>
                        <IoMdInformationCircleOutline
                          size="15"
                          style={{ marginBottom: "-2px", color: "#ff4d4f" }}
                        />{" "}
                        The below access token will expire on{" "}
                        {getUserDateTime(
                          appRegistrationValues.access_token_expiry,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>
                      <Space style={{ display: "flex" }}>
                        <Card
                          style={{
                            backgroundColor: "#f8f8f8",
                            borderRadius: "5px",
                          }}
                        >
                          <Text
                            style={{ fontSize: "11px", wordBreak: "break-all" }}
                          >
                            {showFullAccessToken
                              ? appRegistrationValues?.access_token
                              : `${appRegistrationValues?.access_token.substring(
                                  0,
                                  285
                                )}...`}
                            <Button
                              type="link"
                              style={{ fontSize: "12px" }}
                              onClick={() => handleShowToken("access_token")}
                            >
                              {showFullAccessToken ? "Show less" : "Show more"}
                            </Button>
                          </Text>
                        </Card>
                        <Button
                          style={{
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() =>
                            copyToClipboard(
                              appRegistrationValues?.refresh_token,
                              "REFRESH"
                            )
                          }
                          icon={<MdContentCopy />}
                        ></Button>
                      </Space>
                    </Col>
                  )}

                {(!appRegistrationValues?.refresh_token ||
                  (appRegistrationValues?.refresh_token &&
                    !isFutureDate(
                      appRegistrationValues?.refresh_token_expiry
                    ))) && (
                  <Space>
                    <Button
                      type="primary"
                      loading={
                        state.apiCallStatus["app-registration-form"] &&
                        state.apiCallStatus["app-registration-form"]?.loading
                      }
                      onClick={() => {
                        dispatchReducer({
                          type: "OPEN_FORM_PANEL",
                          payload: {
                            component: "oauth-refresh-token-form-panel",
                            title: "Generate Code",
                          },
                        });
                      }}
                    >
                      Get Code
                    </Button>
                    <>
                      <TiArrowSync
                        onClick={() => {
                          dispatchMiddleware({
                            type: "LOAD_APP_REGISTRATION_DETAILS",
                            payload: {
                              component: "app-registration-form",
                            },
                          });
                        }}
                        size={18}
                        style={{
                          cursor: "pointer",
                          marginLeft: 5,
                          marginTop: 7,
                        }}
                      />
                    </>
                  </Space>
                )}
                {console.log(
                  "appRegistrationValues?.AD_B2C_CLIENT_SECRET",
                  appRegistrationValues?.AD_B2C_CLIENT_SECRET
                )}
                {((!isFutureDate(appRegistrationValues?.access_token_expiry) &&
                  isFutureDate(appRegistrationValues?.refresh_token_expiry)) ||
                  (isFutureDate(appRegistrationValues?.refresh_token_expiry) &&
                    !appRegistrationValues?.access_token &&
                    appRegistrationValues?.refresh_token)) && (
                  <Button
                    type="primary"
                    loading={
                      state.apiCallStatus["app-registration-form"] &&
                      state.apiCallStatus["app-registration-form"]?.loading
                    }
                    onClick={() => {
                      dispatchMiddleware({
                        type: "LOAD_APP_REGISTRATION_ACCESS_TOKEN",
                        payload: {
                          component: "app-registration-form",
                          appRegistration: {
                            clientSecret: encryptKey({
                              wabaApikey:
                                appRegistrationValues?.AD_B2C_CLIENT_SECRET,
                              clientId: state.currentAccount.account.client_id,
                              AES_KEY: URIs.AES_KEY,
                            }),
                            clientID: appRegistrationValues?.AD_B2C_CLIENT_ID,
                            refreshToken: appRegistrationValues?.refresh_token,
                          },
                        },
                      });
                    }}
                  >
                    Get Access Token
                  </Button>
                )}
              </Space>
            </Card>
          </Col>
        </Row>
      </Card>
    ) : (
      <AppRegistrationForm />
    )
  ) : (
    <AccessDenied />
  );
};

export default AppRegistration;
