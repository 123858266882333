import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Col, Space, Input, Button } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { appInsights } from "../../../AppInsights";

const AutomatedRepliesHeader = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Row>
      <Col span={18}>
        <Space>
          <Input
            disabled={
              state.automatedReplies.broadcastAutomatedReplies.length === 0
            }
            value={
              state.filters.searchText["automated-reply-search-text-box"] &&
              state.filters.searchText["automated-reply-search-text-box"]
            }
            prefix={<SearchOutlined />}
            placeholder="Search by reply name"
            style={{ width: 400 }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "automated-reply-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
          />
        </Space>
      </Col>

      <Col span={6} style={{ textAlign: "right" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            appInsights.trackEvent({
              name: "Add Automated Reply",
              properties: {
                clientId: state.currentAccount.account.client_id,
              },
            });
            dispatchReducer({
              type: "OPEN_FORM_PANEL",
              payload: {
                component: "automated-replies-form-panel",
                title: "Add Automated Reply",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "automated-replies-form",
                mode: "add",
                title: "Add Automated Reply",
              },
            });
          }}
        >
          Add Automated Reply
        </Button>
      </Col>
    </Row>
  );
};

export default AutomatedRepliesHeader;
