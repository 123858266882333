import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useDeliveryServicesMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      // Set LaLamove Configuration
      case "SET_LALAMOVE_CONFIGURATION":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.LALAMOVE.concat("/configuration")],
          options: {
            method: state.deliveryServices.lalamoveConfiguration
              ? "PUT"
              : "POST",
            body: action.payload.lalamoveConfiguration,
          },
          onSuccess: ([lalamoveConfiguration]) => {
            if (!state.deliveryServices.lalamoveConfiguration) {
              dispatchReducer({
                type: "SET_LALAMOVE_CONFIGURATION",
                payload: {
                  lalamoveConfiguration,
                },
              });
              openNotificationWithIcon(
                "success",
                "Lalamove details has been configured successfully!"
              );
            } else {
              dispatchReducer({
                type: "SET_LALAMOVE_CONFIGURATION",
                payload: {
                  lalamoveConfiguration,
                },
              });
              openNotificationWithIcon(
                "success",
                "Lalamove details has been updated successfully!"
              );
            }
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Set Lalamove Configuration Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      // Get LaLamove Quotation
      case "GET_LALAMOVE_QUOTATION":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.LALAMOVE.concat(
              "/quotation?market=",
              action.payload.market,
              "&_id=",
              state.clientResponses.currentResponse._id
            ),
          ],
          options: {
            method: "POST",
            body: action.payload.deliveryDetails,
          },
          onSuccess: ([quotationDetails]) => {
            dispatchReducer({
              type: "SET_LALAMOVE_QUOTATION_DETAILS",
              payload: {
                quotationDetails,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Create Lalamove Quotation Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      // Place LaLamove Order
      case "CREATE_LALAMOVE_ORDER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.LALAMOVE.concat(
              "/order?market=",
              action.payload.market,
              "&_id=",
              state.clientResponses.currentResponse._id
            ),
          ],
          options: {
            method: "POST",
            body: action.payload.orderDetails,
          },
          onSuccess: ([orderDetails]) => {
            dispatchReducer({
              type: "SET_LALAMOVE_ORDER_DETAILS",
              payload: {
                orderDetails,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Lalamove order has been created successfully!"
            );

            appInsights.trackEvent({
              name: "Create Lalamove Order",
              properties: {
                environment: mode,
                clientId: state.currentAccount.account.client_id,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Create Lalamove Order Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      // Get Lalamove Quotation Details
      case "GET_LALAMOVE_QUOTATION_DETAILS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.LALAMOVE.concat(
              "/quotation?market=",
              action.payload.market,
              "&quotationId=",
              action.payload.quotationId
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([quotationDetails]) => {
            dispatchReducer({
              type: "SET_LALAMOVE_QUOTATION_DETAILS",
              payload: {
                quotationDetails,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Get Lalamove Quotation Details Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      // Get Lalamove Order Details
      case "GET_LALAMOVE_ORDER_DETAILS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.LALAMOVE.concat(
              "/order?market=",
              action.payload.market,
              "&orderId=",
              action.payload.orderId
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([orderDetails]) => {
            dispatchReducer({
              type: "SET_LALAMOVE_ORDER_DETAILS",
              payload: {
                orderDetails,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Get Lalamove Order Details Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
    }
  };
};
