/* eslint-disable default-case */
import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode, URIs } from "../constants/env";
import { useWindowSize } from "../shared-components/PageReSize";
import { encryptKey } from "../utils/utility";
import Papa from "papaparse";

export const useBroadcastManagerMiddleware = () => {
  const { callApi } = useConnectApi();
  const [height] = useWindowSize();

  const broadcastAPICall = async (
    state,
    action,
    dispatchReducer,
    mediaResponse
  ) => {
    // For v4, mediaResponse is an array, so we need to get the first element
    if (
      action.payload.broadcastVersion === 4 &&
      !action.payload.broadcastContent.template.isCarousel
    ) {
      mediaResponse = mediaResponse?.[0];
    }

    let broadcastContent = {};
    //Download JSON file mediaResponse contain action value "json-download"
    //Start Broadcast mediaResponse contain mediaId
    if (action.payload.mediaContent.hasMedia && mediaResponse) {
      broadcastContent = {
        ...action.payload.broadcastContent,
        template: {
          ...action.payload.broadcastContent.template,
          // Remove unwanted payload from template
          isCarousel: undefined,
          components: !action.payload.broadcastContent.template.isCarousel
            ? [
                ...action.payload.broadcastContent.template.components,
                {
                  type: "header",
                  parameters:
                    action.payload.mediaContent.mediaType === "image"
                      ? [
                          {
                            type: action.payload.mediaContent.mediaType,
                            image: {
                              id:
                                mediaResponse.action === "json-download"
                                  ? "<< Insert Image Id >>"
                                  : mediaResponse.mediaID,
                            },
                          },
                        ]
                      : action.payload.mediaContent.mediaType === "video"
                      ? [
                          {
                            type: action.payload.mediaContent.mediaType,
                            video: {
                              id:
                                mediaResponse.action === "json-download"
                                  ? "<< Insert Video Id >>"
                                  : mediaResponse.mediaID,
                            },
                          },
                        ]
                      : action.payload.mediaContent.mediaType ===
                          "document" && [
                          {
                            type: action.payload.mediaContent.mediaType,
                            document: {
                              id:
                                mediaResponse.action === "json-download"
                                  ? "<< Insert Document Id >>"
                                  : mediaResponse.mediaID,
                              filename:
                                action.payload.mediaContent.mediaFileName,
                            },
                          },
                        ],
                },
              ]
            : action.payload.broadcastContent.template.components.map(
                (component) => {
                  return component.type === "CAROUSEL"
                    ? {
                        ...component,
                        cards: component.cards.map((card, cardIndex) => {
                          return {
                            ...card,
                            components: [
                              ...card.components,
                              {
                                type: "HEADER",
                                parameters: [
                                  {
                                    type: "IMAGE",
                                    image: {
                                      id:
                                        mediaResponse.action === "json-download"
                                          ? "<< Insert Image Id >>"
                                          : mediaResponse?.[cardIndex]?.mediaId,
                                    },
                                  },
                                ],
                              },
                            ],
                          };
                        }),
                      }
                    : component;
                }
              ),
        },
      };
    } else {
      broadcastContent = action.payload.broadcastContent;
    }
    if (action.payload.submitType === "json-download") {
      try {
        const blob = new Blob([JSON.stringify(broadcastContent)], {
          type: "text/json",
        });
        const a = document.createElement("a");
        a.download = "broadcast.json";
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();

        openNotificationWithIcon(
          "success",
          "Broadcast JSON file generated successfully"
        );

        appInsights.trackEvent({
          name: "Broadcast - JSON Downloaded",
          properties: {
            environment: mode,
            clientId: state.currentAccount.account.client_id,
          },
        });

        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "broadcast-form-panel",
          },
        });
      } catch (error) {
        openNotificationWithIcon("error", "Error generating broadcast JSON");
      }
    } else if (
      action.payload.submitType === "start-broadcast" &&
      action.payload.recipientType === "single"
    ) {
      // Log event for single recipient broadcast before calling API
      appInsights.trackEvent({
        name: "Start Broadcast - Single Recipient",
        properties: {
          environment: mode,
          clientId: state.currentAccount.account.client_id,
        },
      });
      await callApi({
        urls: [
          action.payload.broadcastVersion === 3
            ? apiEndPoints.POST_SINGLE_RECIPIENT_BROADCAST_V3
            : action.payload.broadcastVersion === 4
            ? apiEndPoints.POST_SINGLE_RECIPIENT_BROADCAST_V4
            : apiEndPoints.POST_SINGLE_RECIPIENT_BROADCAST,
        ],
        options: {
          method: "POST",
          body: broadcastContent,
          header: {
            ...action.payload.channelConfigurationInfo,
            // For v4 header property should be cammelCase
            ...(action.payload.broadcastVersion !== 4
              ? {
                  userid: state.currentUser.user.email,
                  clientid: state.currentAccount.account.client_id,
                  botid: action.payload.bot.bot_id,
                }
              : {
                  userId: state.currentUser.user.email,
                  clientId: state.currentAccount.account.client_id,
                  botId: action.payload.bot.bot_id,
                  category: action.payload.broadcastContent.template.category,
                }),
          },
        },
        onSuccess: ([response]) => {
          openNotificationWithIcon("success", action.payload.successMessage);
          dispatchReducer({
            type: "SUCCESS_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          dispatchReducer({
            type: "CLOSE_FORM_PANEL",
            payload: {
              component: "broadcast-form-panel",
            },
          });

          appInsights.trackEvent({
            name: "Created Broadcast - Single Recipient",
            properties: {
              environment: mode,
              clientId: state.currentAccount.account.client_id,
            },
          });
        },
        onError: (error) => {
          appInsights.trackException({
            exception: JSON.stringify(error),
            properties: {
              title: "Create Single Recipient Broadcast Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            },
          });
          if (action.payload.broadcastVersion === 4) {
            openNotificationWithIcon("error", error?.body?.error?.exception);
          } else {
            openNotificationWithIcon("error", error.description);
          }
          dispatchReducer({
            type: "ERROR_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
        },
      });
    } else if (
      action.payload.submitType === "start-broadcast" &&
      action.payload.recipientType === "multiple"
    ) {
      let formData = new FormData();
      formData.append(
        action.payload.broadcastVersion === 4 ? "csvFile" : "csvfile",
        action.payload.csvFile
      );
      formData.append("templateData", JSON.stringify(broadcastContent));

      // Log event for multiple recipient broadcast before calling API
      appInsights.trackEvent({
        name: "Start Broadcast - Multiple Recipient",
        properties: {
          environment: mode,
          clientId: state.currentAccount.account.client_id,
          clientName: state.currentAccount.account?.general?.company,
          recipientCount: action.payload.recipientCount,
        },
      });

      await callApi({
        urls: [
          action.payload.broadcastVersion === 3
            ? apiEndPoints.POST_MULTIPLE_RECIPIENT_BROADCAST_V3
            : action.payload.broadcastVersion === 4
            ? apiEndPoints.POST_MULTIPLE_RECIPIENT_BROADCAST_V4
            : apiEndPoints.POST_MULTIPLE_RECIPIENT_BROADCAST,
        ],
        options: {
          method: "POST",
          body: formData,
          header: {
            ...action.payload.channelConfigurationInfo,
            // For v4 header property should be cammelCase
            ...(action.payload.broadcastVersion !== 4
              ? {
                  userid: state.currentUser.user.email,
                  clientid: state.currentAccount.account.client_id,
                  botid: action.payload.bot.bot_id,
                }
              : {
                  userId: state.currentUser.user.email,
                  clientId: state.currentAccount.account.client_id,
                  botId: action.payload.bot.bot_id,
                  category: action.payload.broadcastContent.template.category,
                }),
          },
          contentType: "auto",
        },
        onSuccess: () => {
          openNotificationWithIcon("success", "Broadcast started successfully");
          dispatchReducer({
            type: "SUCCESS_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          dispatchReducer({
            type: "CLOSE_FORM_PANEL",
            payload: {
              component: "broadcast-form-panel",
            },
          });
          appInsights.trackEvent({
            name: "Created Broadcast - Multiple Recipient",
            properties: {
              environment: mode,
              clientId: state.currentAccount.account.client_id,
              clientName: state.currentAccount.account?.general?.company,
              recipientCount: action.payload.recipientCount,
            },
          });
        },
        onError: (error) => {
          appInsights.trackException({
            exception: JSON.stringify(error),
            properties: {
              title: "Create Multi Recipient Broadcast Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            },
          });
          if (action.payload.broadcastVersion === 4) {
            openNotificationWithIcon("error", error?.body?.error?.exception);
          } else {
            openNotificationWithIcon("error", error.description);
          }
          dispatchReducer({
            type: "ERROR_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
        },
      });
    }
  };

  return async (state, action, dispatchReducer) => {
    let pageSize = Math.round((height - 250) / 55);

    switch (action.type) {
      ////***********************************************////
      ////****************** BROADCAST ******************////
      ////***********************************************////

      //Load Broadcast list
      case "LOAD_BROADCASTS_DATA":
        const botInAction = state.currentAccount.account.bots.find(
          (bot) => bot.bot_id === state.application.botInAction
        );

        // This is used while fetching the broadcast list only
        const broadcastVersion =
          botInAction?.bot_channel_info?.WHATSAPP?.waba_broadcast_version;

        // Bot id
        const botId = "?bot_id=" + state.application.botInAction;
        // Page size is calculated based on the screen height
        pageSize = action.payload.pageSize || pageSize;
        pageSize = "&pagesize=" + pageSize;
        // Page number
        const pageNumber = "&pagenumber=" + action.payload.pageNumber;
        // Search text
        const { payload } = action;
        // Search text
        const searchText = payload.searchText
          ? `&searchText=${payload.searchText}`
          : "";
        // Filtered value
        const filteredValue = payload.filteredValue
          ? `&${payload.filteredValue}`
          : "";
        // Sort value
        const sortedValue =
          payload.order && payload.columnKey
            ? `&sortkey=${payload.columnKey}`
            : "";
        const sortType =
          payload.order === "ascend"
            ? "&sorttype=1"
            : payload.order === "descend"
            ? "&sorttype=-1"
            : "";

        let queryString =
          botId +
          pageSize +
          pageNumber +
          searchText +
          filteredValue +
          sortedValue +
          sortType;

        // if broadcast version is 4, then we need to replace the query string
        if (broadcastVersion === 4) {
          queryString = queryString.replace("bot_id", "botId");
          queryString = queryString.replace("sentDate", "startDate");
        }

        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            broadcastVersion !== 4
              ? apiEndPoints.BROADCAST.concat(queryString)
              : apiEndPoints.BROADCAST_V4.concat(queryString),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcasts]) => {
            dispatchReducer({
              type: "SET_BROADCASTS",
              broadcasts,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Broadcast Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Start Broadcast list
      case "START_BROADCAST":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        if (action.payload.mediaContent.hasMedia) {
          //For carousel template type, we are sending media as form data
          //For other template types, except v4 we are sending media as base64
          let media;
          if (action.payload.broadcastContent.template.isCarousel) {
            media = new FormData();
            action.payload.mediaContent.mediaData?.media?.forEach((image) => {
              media.append("files", image.file);
            });
            if (
              action.payload.channelConfigurationInfo.wabaProviderName ===
              "INEXTLABS-CLOUD"
            ) {
              media.append(
                "phoneNumberId",
                action.payload.channelConfigurationInfo[
                  action.payload.broadcastVersion === 4
                    ? "phoneNumberId"
                    : "phone-number-id"
                ]
              );
            } else {
              media.append(
                "wabaKey",
                encryptKey({
                  wabaApikey:
                    action.payload.channelConfigurationInfo[
                      action.payload.broadcastVersion === 4
                        ? "wabaKey"
                        : "waba-key"
                    ],
                  clientId: state.currentAccount.account.client_id,
                  AES_KEY: URIs.AES_KEY,
                })
              );
            }
          } else if (action.payload.broadcastVersion === 4) {
            // For v4, we are sending media as form data
            media = new FormData();
            media.append("files", action.payload.mediaContent.mediaData.media);
          } else {
            media = action.payload.mediaContent.mediaData;
          }

          await callApi({
            urls: [
              // For temporary we using app service for carousal media upload
              !action.payload.broadcastContent.template.isCarousel
                ? action.payload.broadcastVersion === 3
                  ? apiEndPoints.BROADCAST_MEDIA_V3
                  : action.payload.broadcastVersion === 4
                  ? apiEndPoints.BROADCAST_MEDIA_V4
                  : apiEndPoints.BROADCAST_MEDIA
                : apiEndPoints.BROADCAST.concat("/mediaUpload"),
            ],
            options: {
              method: "POST",
              contentType:
                action.payload.broadcastContent.template.isCarousel ||
                action.payload.broadcastVersion === 4
                  ? "auto"
                  : null,
              body: media,
              header: {
                ...action.payload.channelConfigurationInfo,
                ...{
                  userid: state.currentUser.user.email,
                  clientid: state.currentAccount.account.client_id,
                  botid: action.payload.bot.bot_id,
                },
              },
            },
            onSuccess: async ([mediaResponse]) => {
              broadcastAPICall(state, action, dispatchReducer, mediaResponse);
            },
            onError: (error) => {
              openNotificationWithIcon("error", error.description);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              appInsights.trackException({
                exception: JSON.stringify(error),
                properties: {
                  title: "Create Broadcast Media Id Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
            },
          });
        } else {
          broadcastAPICall(state, action, dispatchReducer);
        }
        break;

      //Download JSON file
      case "DOWNLOAD_JSON_FILE":
        if (action.payload.mediaContent.hasMedia) {
          const mediaResponse = {
            action: "json-download",
          };
          broadcastAPICall(state, action, dispatchReducer, mediaResponse);
        } else {
          broadcastAPICall(state, action, dispatchReducer);
        }
        break;

      //Delete Broadcast
      case "DELETE_BROADCAST":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            action.payload.broadcastVersion === 4
              ? apiEndPoints.BROADCAST_V4.concat(
                  "?_id=",
                  action.payload.actionRowKey
                )
              : apiEndPoints.BROADCAST.concat(
                  "?broadcastID=",
                  action.payload.actionRowKey
                ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "DELETE_SINGLE_BROADCAST",
              payload: {
                actionRowKey: action.payload.actionRowKey,
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "broadcast-manager-table" },
            });
            openNotificationWithIcon(
              "success",
              "Broadcast deleted successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Broadcast Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openNotificationWithIcon("error", error.description);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Download Broadcast Report
      case "MWD_DOWNLOAD_BROADCAST_MANAGER_DELIVERY_REPORT":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.BROADCAST.concat(
              "/report?broadcastID=",
              action.payload.actionRowKey
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([report]) => {
            try {
              // Check if "Failed Reason" is present in any broadcastReport and add it dynamically
              const hasFailedReason = report.some((broadcastData) =>
                broadcastData.hasOwnProperty("Failed Reason")
              );

              // Modify the data array to include "Failed Reason" only if present
              const modifiedReportData = report.map((broadcastData) => {
                const modifiedBroadcastDetails = { ...broadcastData }; // Create a shallow copy of the object
                if (hasFailedReason) {
                  modifiedBroadcastDetails["Failed Reason"] =
                    modifiedBroadcastDetails["Failed Reason"] || ""; // Add "Failed Reason" if not present
                }
                return modifiedBroadcastDetails;
              });
              // Convert the data array to CSV format using Papa Parse
              const csv = Papa.unparse(modifiedReportData, {
                escapeFormulae: true,
              });

              // Create a blob object from the CSV data
              const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
              // Create a temporary anchor element
              const link = document.createElement("a");
              // Set the attributes for the anchor element
              link.href = URL.createObjectURL(blob);
              link.download = `Broadcast-Report-${action.payload.broadcastName}.csv`;
              // Append the anchor element to the DOM and simulate a click event
              document.body.appendChild(link);
              link.click();
              // Clean up by removing the temporary anchor element
              document.body.removeChild(link);

              openNotificationWithIcon(
                "success",
                "Broadcast report downloaded successfully"
              );
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
              appInsights.trackEvent({
                name: "Broadcast Report Downloaded",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                },
              });
            } catch (error) {
              openNotificationWithIcon("error", error.description);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            }
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Download Broadcast Report Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openNotificationWithIcon("error", error.description);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Cancel Multiple Broadcast
      case "CANCEL_BROADCAST":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [action.payload.terminateBroadcast],
          options: {
            method: "POST",
          },
          onSuccess: () => {
            openNotificationWithIcon(
              "success",
              "Broadcast cancelled successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "broadcast-manager-table" },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Cancel Broadcast Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openNotificationWithIcon("error", error.description);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Load broadcast price list
      case "LOAD_BROADCAST_PRICE_LIST":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.WHATSAPP.concat("/priceList")],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastPriceList]) => {
            dispatchReducer({
              type: "SET_BROADCAST_PRICE_LIST",
              payload: {
                broadcastPriceList,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Broadcast Price List Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
