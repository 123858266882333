import React from "react";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";
import AccessDenied from "../../shared-components/AccessDenied";
import { useGlobalContext } from "../../contexts/globalContext";
import { Button, Layout, Empty } from "antd";
import ConversationContacts from "./ConversationContacts";
import ConversationTranscripts from "./ConversationTranscripts";
import ConversationHeader from "./ConversationHeader";
import ConversationFooter from "./ConversationFooter";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import ConversationInfoPanel from "./ConversationInfoPanel";
import { useWindowSize } from "../../shared-components/PageReSize";
import "./Conversations.less";

const Conversation = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();

  useConnectMiddleware(() => {
    // Check Notify only customers
    if (
      !state.currentAccount.account.bots.every(
        ({ bot_version }) => bot_version === "V0.1"
      )
    ) {
      state.currentUser.permission.CONVERSATIONS_VIEW &&
        //No need to trigger all conversations if the user clicks on the conversation icon in response
        !state.conversations.conversationFilter &&
        dispatchMiddleware({
          type: "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
          payload: {
            component: "conversations-layout",
            pageSize: Math.round(height / 60),
            pageNumber: state.conversations.contactsWithTranscripts
              ? Math.floor(
                  state.conversations.contactsWithTranscripts.length / 12
                ) + 1
              : 1,
            delta: state.conversations.contactsWithTranscripts ? true : false,
          },
        });

      !state.conversations.liveAgentSettingsAcrossBots &&
        dispatchMiddleware({
          type: "LOAD_LIVE_AGENT_SETTINGS_ACROSS_BOTS",
          payload: {
            component: "live-agent-setting",
          },
        });
    }
  });

  return !state.currentAccount.account.modules.conversation.enabled ||
    // Check Notify only customers
    state.currentAccount.account.bots.every(
      (bot) => bot.bot_version === "V0.1"
    ) ? (
    <Layout.Content
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModuleNotSubscribed module="conversations" />
    </Layout.Content>
  ) : !state.currentUser.permission.CONVERSATIONS_VIEW ? (
    <AccessDenied />
  ) : state.conversations.contactsWithTranscripts ? (
    <Layout.Content
      style={{
        height: height - 100, //To fix the grid height
        display: "grid",
        gridTemplateColumns:
          state.infoPanel["conversation-info-panel"]?.visible === true
            ? "2fr 4fr 2fr"
            : "2fr 5fr",
        border: "1px solid #E9E9E9",
      }}
    >
      <Layout.Content
        style={{
          height: "100%",
          borderRight: "1px solid #E9E9E9",
        }}
      >
        <ConversationContacts />
      </Layout.Content>
      <Layout.Content
        style={{
          height: "100%",
          borderRight: "1px solid #E9E9E9",
        }}
      >
        <ConversationHeader />
        <ConversationTranscripts />
        <ConversationFooter />
      </Layout.Content>
      {state.infoPanel["conversation-info-panel"]?.visible === true && (
        <Layout.Content
          style={{
            height: "100%",
            borderRight: "1px solid #E9E9E9",
          }}
        >
          <ConversationInfoPanel />
        </Layout.Content>
      )}
    </Layout.Content>
  ) : (
    <>
      <FullPageLoader
        component="conversations-layout"
        loadingMessage="Loading... Please wait..."
      />

      {/* No conversation scenario */}
      {/* If the user clicks the conversation icon on the responses page but that conversation has been deleted... */}
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "35%",
        }}
      >
        {!state.apiCallStatus?.["conversations-layout"]?.loading &&
          (state.conversations.conversationFilter ? (
            <>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Filtered conversation not found"
              />

              <Button
                danger
                style={{
                  marginLeft: 25,
                }}
                onClick={() => {
                  // Clear the filtered conversation and load the conversations
                  dispatchReducer({
                    type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
                  });

                  // Clear the current conversation
                  dispatchReducer({
                    type: "RD_CLEAR_CURRENT_CONVERSATION",
                  });

                  // Set the conversation response link to false
                  dispatchReducer({
                    type: "RD_SET_CONVERSATION_FILTER",
                    payload: {
                      conversationFilter: false,
                    },
                  });

                  // Load the conversations
                  dispatchMiddleware({
                    type: "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                    payload: {
                      component: "conversations-layout",
                      pageSize: Math.round(height / 60),
                      pageNumber: state.conversations.contactsWithTranscripts
                        ? Math.floor(
                            state.conversations.contactsWithTranscripts.length /
                              12
                          ) + 1
                        : 1,
                      delta: false,
                    },
                  });
                }}
              >
                Show all conversations
              </Button>
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No conversation found"
            />
          ))}
      </div>
    </>
  );
};

export default Conversation;
