import React, { useState, useRef } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { DirectLine } from "botframework-directlinejs";
import {
  status,
  liveAgentAttachment,
  mimeToFileType,
  fileIcon,
} from "../../constants/enums";
import { Translation } from "../../translation/translation";
import { getCookieValue, setCookie, formatFileSize } from "../../utils/utility";
import { appInsights } from "../../AppInsights";
import { mode } from "../../constants/env";
import openNotificationWithIcon from "../../utils/notification";
import {
  Dropdown,
  Row,
  Col,
  Typography,
  Space,
  Upload,
  Button,
  Image,
  Layout,
  Tooltip,
} from "antd";
import { MdAttachment } from "react-icons/md";
import { LiaFileVideo } from "react-icons/lia";
import { PiImageSquare } from "react-icons/pi";
import { DeleteOutlined } from "@ant-design/icons";
import { AiOutlineSend } from "react-icons/ai";
import { IoDocumentOutline } from "react-icons/io5";

const { Text } = Typography;
const { Content } = Layout;

const Attachments = ({ setUploadedFile, uploadedFile }) => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  const [showAttachments, setShowAttachments] = useState(false);
  const [uploadedFilebase64, setUploadedFileBase64] = useState();

  const componentRef = useRef(null);
  const translation = Translation().conversations.footer.attachments;

  const currentConversation = state.conversations.currentConversation;

  const directLine = new DirectLine({
    secret: state.currentAccount.account.bots.find(
      (bot) => bot.bot_id === currentConversation.bot_id
    )?.bot_liveagent_directline_key,
    conversationId: getCookieValue(
      `conversationId-${currentConversation?.bot_id}=`
    ),
  });

  // Check filetype
  let fileType = uploadedFile?.type.split("/")[0];
  // Convert file type to document if it is an application
  fileType === "application" && (fileType = "document");

  // Check if the uploaded file size is valid
  const isValidFileSize = uploadedFile?.size < liveAgentAttachment.maxSize;

  // Upload attachment
  const handleUploadAttachment = (e) => {
    const fileToLoad = e.file;
    setUploadedFile(fileToLoad);

    const fileReader = new FileReader();

    if (fileToLoad.size < liveAgentAttachment.maxSize) {
      fileReader.readAsDataURL(fileToLoad);
      fileReader.onload = (fileLoadedEvent) => {
        const srcData = fileLoadedEvent.target.result;
        setUploadedFileBase64(srcData);
      };
    }
  };

  // Agent send attachment
  const handleSendAttachment = () => {
    setShowAttachments(false);
    dispatchMiddleware({
      type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
      payload: {
        //When sending message, a (sending...) loader is handled by the component
        component:
          "agent-message-" +
          state.conversations.currentConversation._id +
          "-form",
        message: fileType === "document" ? uploadedFile : uploadedFilebase64,
        type: fileType,
        botId: state.conversations.currentConversation.bot_id,
        conversationId: state.conversations.currentConversation.conversation_id,
        isSending: true,
      },
    });
    directLine
      .postMessageWithAttachments({
        type: "message",
        attachments: [
          {
            contentType: uploadedFile.type,
            contentUrl: uploadedFilebase64,
            name: uploadedFile.name,
          },
        ],
        value: {
          eventType: "customEvent",
          eventProperties: {
            conversation_id:
              state.conversations.currentConversation.conversation_id,
            user_id: state.conversations.currentConversation.user_id,
          },
        },
        label: "agent",
        from: {
          id: state.currentUser.user.userID,
          name: state.currentUser.user.displayName,
          role: "agent",
        },
      })
      .subscribe(
        (id) => {
          console.log("Posted activity, assigned ID ", id);
          if (id !== "retry") {
            // Set the conversation ID in a cookie with a 24-hour expiration
            setCookie(
              "conversationId-" + currentConversation?.bot_id,
              id?.split("|")[0],
              1
            );
            // To fix the issue of message re-rendering, a filter based on the date has been implemented in the reducer.
            // In web chat, the sending and sent time appear to be the same. so, we delay sent response by 1 second.
            setTimeout(() => {
              dispatchMiddleware({
                type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
                payload: {
                  // Based on component, sending... loader will remove
                  component:
                    "agent-message-" +
                    state.conversations.currentConversation._id +
                    "-form",
                  message:
                    fileType === "document" ? uploadedFile : uploadedFilebase64,
                  type: fileType,
                  botId: state.conversations.currentConversation.bot_id,
                  conversationId:
                    state.conversations.currentConversation.conversation_id,
                  isSending: false,
                  directlineMessageId: id,
                  deliveryStatus:
                    state.conversations.currentConversation.channel?.toUpperCase() ===
                    "WHATSAPP"
                      ? "sent"
                      : undefined,
                },
              });
            }, 1000);
          } else {
            openNotificationWithIcon(
              "error",
              "Unable to send message. Please try again later"
            );
            // Handle the failed message from  transcript
            dispatchMiddleware({
              type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
              payload: {
                // Based on component, sending... loader will remove and show failed icon
                component:
                  "agent-message-" +
                  state.conversations.currentConversation._id +
                  "-form",
                message:
                  fileType === "document" ? uploadedFile : uploadedFilebase64,
                type: fileType,
                botId: state.conversations.currentConversation.bot_id,
                conversationId:
                  state.conversations.currentConversation.conversation_id,
                isSending: false,
                isSendFailed: true,
              },
            });
            appInsights.trackException({
              exception: id,
              properties: {
                title: "Conversation V2 Agent Send Attachment Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
          }
        },

        (error) => {
          openNotificationWithIcon("error", error.message);
          console.log("Error posting activity", error);

          // Handle the failed message from  transcript
          dispatchMiddleware({
            type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
            payload: {
              // Based on component, sending... loader will remove and show failed icon
              component:
                "agent-message-" +
                state.conversations.currentConversation._id +
                "-form",
              message:
                fileType === "document" ? uploadedFile : uploadedFilebase64,
              type: fileType,
              botId: state.conversations.currentConversation.bot_id,
              conversationId:
                state.conversations.currentConversation.conversation_id,
              isSending: false,
              isSendFailed: true,
            },
          });
        }
      );

    setUploadedFile(null);

    appInsights.trackEvent({
      name: "Attachment sent by agent",
      properties: {
        clientId: state.currentAccount.account.client_id,
        environment: mode,
      },
    });
  };

  // Supported media list
  const supportedMediaList = (
    <div
      style={{
        width: 120,
        borderRadius: 5,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        background: "white",
        padding: 12,
        marginBottom: 5,
      }}
    >
      <Space direction="vertical">
        <Upload
          accept=".jpeg, .png, .jpg"
          beforeUpload={(uploadedImage) => {
            return uploadedImage.size > liveAgentAttachment.maxSize && true;
          }}
          onChange={handleUploadAttachment}
          showUploadList={false}
          listType="picture"
        >
          <Space
            style={{
              color: "rgb(108 107 107)",
              cursor: "pointer",
            }}
          >
            <PiImageSquare
              size={21}
              style={{
                color: "rgb(108 107 107)",
              }}
            />
            <Text>Images</Text>
          </Space>
        </Upload>

        {["WHATSAPP"].includes(
          state.conversations.currentConversation.channel?.toUpperCase()
        ) && (
          <>
            <Upload
              accept=".mp4, .3gp, .mpeg"
              showUploadList={false}
              onChange={handleUploadAttachment}
              beforeUpload={(uploadedVideo) => {
                return uploadedVideo.size > liveAgentAttachment.maxSize && true;
              }}
            >
              <Space
                style={{
                  color: "rgb(108 107 107)",
                  cursor: "pointer",
                }}
              >
                <LiaFileVideo
                  size={22}
                  style={{
                    color: "rgb(108 107 107)",
                  }}
                />
                <Text>Videos</Text>
              </Space>
            </Upload>

            <Upload
              showUploadList={false}
              maxCount={1}
              accept=".pdf, .xlsx, .xls, .ppt, .pptx, .doc,.docx"
              onChange={handleUploadAttachment}
              beforeUpload={(uploadedDocument) => {
                return (
                  uploadedDocument.size > liveAgentAttachment.maxSize && true
                );
              }}
            >
              <Space
                style={{
                  color: "rgb(108 107 107)",
                  cursor: "pointer",
                }}
              >
                <IoDocumentOutline
                  size={21}
                  style={{
                    color: "rgb(108 107 107)",
                  }}
                />
                <Text>Document</Text>
              </Space>
            </Upload>
          </>
        )}
      </Space>
    </div>
  );

  // Preview image content
  const imagePreviewContent = (
    <>
      {isValidFileSize ? (
        <Row>
          <Col span={24}>
            <Image
              src={URL.createObjectURL(uploadedFile)}
              style={{
                height: 195,
              }}
              preview={false}
            />
          </Col>
          <Col
            span={24}
            style={{
              marginTop: 18,
            }}
          >
            <Text>{uploadedFile.name}</Text>
          </Col>
        </Row>
      ) : (
        <Row
          gutter={[0, 25]}
          style={{
            marginTop: 30,
          }}
        >
          <Col span={24}>
            <Image
              src={status.errorIcon}
              preview={false}
              height={70}
              width={70}
            />
          </Col>
          <Col span={24}>
            <Text type="danger">{translation.image.imageSizeError}</Text>
          </Col>
          <Col span={24}>
            <Upload
              accept=".jpeg, .png, .jpg"
              beforeUpload={(uploadedImage) => {
                return uploadedImage.size > liveAgentAttachment.maxSize && true;
              }}
              onChange={handleUploadAttachment}
              showUploadList={false}
              listType="picture"
            >
              <Button type="primary">{translation.image.tryOtherImage}</Button>
            </Upload>
          </Col>
        </Row>
      )}
    </>
  );

  // Preview video content
  const videoPreviewContent = (
    <>
      {isValidFileSize ? (
        <Row>
          <Col span={24}>
            <video width="100%" controls src={uploadedFilebase64} />
          </Col>
          <Col
            span={24}
            style={{
              marginTop: 18,
            }}
          >
            <Text>{uploadedFile.name}</Text>
          </Col>
        </Row>
      ) : (
        <Row
          gutter={[0, 25]}
          style={{
            marginTop: 30,
          }}
        >
          <Col span={24}>
            <Image
              src={status.errorIcon}
              preview={false}
              height={70}
              width={70}
            />
          </Col>
          <Col span={24}>
            <Text type="danger">{translation.video.videoSizeError}</Text>
          </Col>
          <Col span={24}>
            <Upload
              accept=".mp4, .3gp, .mpeg"
              onChange={handleUploadAttachment}
              beforeUpload={(uploadedVideo) => {
                return uploadedVideo.size > liveAgentAttachment.maxSize && true;
              }}
              showUploadList={false}
            >
              <Button type="primary">{translation.video.tryOtherVideo}</Button>
            </Upload>
          </Col>
        </Row>
      )}
    </>
  );

  // Document video content
  const documentPreviewContent = (
    <>
      {isValidFileSize ? (
        <Row gutter={[0, 13]}>
          <Col
            span={24}
            style={{
              marginTop: 40,
            }}
          >
            <Image
              src={
                fileIcon[
                  mimeToFileType[uploadedFile.type] ||
                    "/images/document-placeholder.png"
                ]
              }
              style={{
                cursor: "pointer",
              }}
              height={80}
              preview={false}
              onClick={() => {
                let link = document.createElement("a");
                link.href = URL.createObjectURL(uploadedFile);
                link.download = uploadedFile.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            />
          </Col>

          <Col span={24}>
            <Text ellipsis>{uploadedFile.name}</Text>
          </Col>
          <Col span={24}>
            <Text>{formatFileSize(uploadedFile.size)}</Text>
          </Col>
        </Row>
      ) : (
        <Row
          gutter={[0, 25]}
          style={{
            marginTop: 30,
          }}
        >
          <Col span={24}>
            <Image
              src={status.errorIcon}
              preview={false}
              height={70}
              width={70}
            />
          </Col>
          <Col span={24}>
            <Text type="danger">{translation.document.documentSizeError}</Text>
          </Col>
          <Col span={24}>
            <Upload
              accept=".pdf, .xlsx, .xls, .ppt, .pptx, .doc,.docx"
              onChange={handleUploadAttachment}
              beforeUpload={(uploadedDocument) => {
                return (
                  uploadedDocument.size > liveAgentAttachment.maxSize && true
                );
              }}
              showUploadList={false}
            >
              <Button type="primary">
                {translation.document.tryOtherDocument}
              </Button>
            </Upload>
          </Col>
        </Row>
      )}
    </>
  );

  const previewMedia = (
    <div
      style={{
        height: 370,
        width: 400,
        backgroundColor: "white",
        borderRadius: 5,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        padding: 15,
        marginBottom: 5,
      }}
      ref={componentRef}
    >
      <Layout
        style={{
          height: 340,
          backgroundColor: "white",
        }}
      >
        <Text strong>{translation.title}</Text>

        <Content
          style={{
            backgroundColor: "white",
            marginTop: 15,
            textAlign: "center",
          }}
        >
          {uploadedFile && (
            <>
              {fileType === "image" && imagePreviewContent}
              {fileType === "video" && videoPreviewContent}
              {fileType === "document" && documentPreviewContent}
            </>
          )}
        </Content>

        {isValidFileSize && (
          <Space
            style={{
              position: "absolute",
              bottom: 20,
              borderTop: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <Button
              icon={
                <DeleteOutlined
                  size={20}
                  style={{
                    color: "#F05742",
                  }}
                />
              }
              style={{
                borderRadius: 5,
                marginTop: 12,
              }}
              onClick={() => {
                setUploadedFile(null);
              }}
            />

            <Button
              type="primary"
              icon={
                <AiOutlineSend
                  size={20}
                  style={{
                    marginTop: 3,
                  }}
                />
              }
              style={{
                borderRadius: 5,
                marginTop: 12,
                marginLeft: 297,
              }}
              onClick={handleSendAttachment}
            />
          </Space>
        )}
      </Layout>
    </div>
  );

  return (
    <Dropdown
      trigger={["click"]}
      open={showAttachments}
      onOpenChange={(visible) => {
        setShowAttachments(visible);
        setUploadedFile(null);
      }}
      dropdownRender={() => (uploadedFile ? previewMedia : supportedMediaList)}
    >
      <Tooltip title={translation.infoText}>
        <MdAttachment
          size={23}
          style={{
            color: showAttachments ? "#F05742" : "rgb(108 107 107)",
            marginTop: "3px",
            cursor: "pointer",
            rotate: "125deg",
          }}
        />
      </Tooltip>
    </Dropdown>
  );
};

export default Attachments;
