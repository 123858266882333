import { chineseText } from "./chinese";
import { englishText } from "./english";
import { useGlobalContext } from "../contexts/globalContext";

export const Translation = () => {
  const [state] = useGlobalContext();

  switch (state.application.selectedLanguage) {
    case "EN":
      return englishText;
    case "CH":
      return chineseText;

    default:
      return englishText;
  }
};
