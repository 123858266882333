import { useState } from "react";
import {
  Button,
  Input,
  Col,
  Row,
  Checkbox,
  Dropdown,
  Typography,
  Empty,
  Tooltip,
} from "antd";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { CloseOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../contexts/globalContext";
import { dateRange } from "../../constants/enums";
import { convertObjectToString } from "../../utils/utility";
import { appInsights } from "../../AppInsights";

const { Text } = Typography;

const ClientResponsesFilterDropdown = ({
  clientResponseColumn,
  selectedResponseValues,
  setSelectedResponseValues,
}) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const currentResponseCategory = state.clientResponses.currentResponseCategory;

  const searchText =
    state.filters.searchText["client-response-filter-dropdown-search-text-box"];

  let filteredValue = state.filters.filteredInfo["client-response-table"];

  let responseCustomColumnDropdownValues =
    state.clientResponses.responseCustomColumnDropdownValues;

  if (searchText) {
    responseCustomColumnDropdownValues =
      responseCustomColumnDropdownValues.filter(({ value }) => {
        return value.toLowerCase().includes(searchText.toLowerCase());
      });
  }

  // Sort responsedropdown values
  responseCustomColumnDropdownValues = responseCustomColumnDropdownValues?.sort(
    (a, b) =>
      typeof a.label === "number" && typeof b.label === "number"
        ? a.label - b.label
        : a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );

  return (
    <Dropdown
      trigger="click"
      open={dropdownVisible} // use dropdownVisible state to control visibility
      onClick={() => {
        dispatchReducer({
          type: "RD_SET_SEARCH_TEXT",
          payload: {
            component: "client-response-filter-dropdown-search-text-box",
            searchText: undefined,
          },
        });
        clientResponseColumn.key === "leadDate"
          ? //Date Range contains today, last 7 days, last 30 days, last 60 days
            dispatchReducer({
              type: "RD_SET_RESPONSE_CUSTOM_COLUMN_DROPDOWN_VALUES",
              payload: {
                optionValues: dateRange,
              },
            })
          : dispatchMiddleware({
              type: "MWD_LOAD_RESPONSE_CUSTOM_COLUMN_DROPDOWN_VALUES",
              payload: {
                component: "client-response-filter-dropdown",
                selectedResponseColumn: clientResponseColumn.key,
              },
            });
      }}
      onOpenChange={(visible) => setDropdownVisible(visible)} // update state when dropdown visibility changes
      disabled={
        typeof clientResponseColumn.value === "object" ||
        state.clientResponses.responseCustomColumnDropdownValues?.some(
          ({ value }) => typeof value === "object"
        )
      }
      dropdownRender={() => (
        <div
          style={{
            maxHeight: 300,
            maxWidth: 195,
            borderRadius: 5,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            background: "white",
          }}
        >
          {state.apiCallStatus["client-response-filter-dropdown"] &&
          state.apiCallStatus["client-response-filter-dropdown"].loading ? (
            <ComponentLoader
              component="client-response-filter-dropdown"
              loadingMessage="Loading..."
              errorMessage=""
            />
          ) : (
            <div
              style={{
                background: "white",
                paddingTop: 15,
                margin: "0 9px",
              }}
            >
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Input
                    autoFocus
                    placeholder="Search"
                    size="small"
                    prefix={<SearchOutlined />}
                    allowClear={{ clearIcon: <CloseOutlined /> }}
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      dispatchReducer({
                        type: "RD_SET_SEARCH_TEXT",
                        payload: {
                          component:
                            "client-response-filter-dropdown-search-text-box",
                          searchText: currentValue,
                        },
                      });
                    }}
                  />
                </Col>
                <Col
                  span={24}
                  style={{
                    border: "1px solid #f0f2f5",
                    overflow: "auto",
                    maxHeight: 170,
                  }}
                >
                  {responseCustomColumnDropdownValues?.length !== 0 ? (
                    <Checkbox.Group
                      onChange={(selectedResponseValue) => {
                        setSelectedResponseValues({
                          ...selectedResponseValues,
                          [clientResponseColumn.key]:
                            selectedResponseValue.length > 0
                              ? selectedResponseValue
                              : null,
                        });
                      }}
                      value={
                        selectedResponseValues
                          ? selectedResponseValues[clientResponseColumn.key]
                          : ""
                      }
                    >
                      {responseCustomColumnDropdownValues?.map(
                        (responseColumnValue, index) => (
                          <Row key={index}>
                            <Checkbox value={responseColumnValue.value} />
                            <Text
                              ellipsis
                              style={{
                                marginLeft: 10,
                                marginBottom: 7,
                                width: 132,
                              }}
                            >
                              {responseColumnValue.label}
                            </Text>
                          </Row>
                        )
                      )}
                    </Checkbox.Group>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Col>

                {responseCustomColumnDropdownValues?.length !== 0 && (
                  <Col span={24}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 8,
                      }}
                    >
                      <Col>
                        <Tooltip
                          title={
                            !selectedResponseValues ||
                            !selectedResponseValues[clientResponseColumn.key]
                              ? "Select value to filter"
                              : "Reset filter"
                          }
                        >
                          <Button
                            size="small"
                            disabled={
                              !selectedResponseValues ||
                              !selectedResponseValues[clientResponseColumn.key]
                            }
                            onClick={() => {
                              setSelectedResponseValues({
                                ...selectedResponseValues,
                                [clientResponseColumn.key]: null,
                              });
                              filteredValue[clientResponseColumn.key] &&
                                dispatchMiddleware({
                                  type: "MWD_LOAD_RESPONSES_DATA",
                                  payload: {
                                    component: "client-response-table",
                                    currentResponseCategory,
                                    filteredValue: convertObjectToString({
                                      ...selectedResponseValues,
                                      [clientResponseColumn.key]: null,
                                    }),
                                    searchvalue: state.filters.searchText?.[
                                      "client-responses-search-text-box"
                                    ]
                                      ? state.filters.searchText[
                                          "client-responses-search-text-box"
                                        ].concat("&search=true")
                                      : "",
                                    pageNumber: 1,
                                  },
                                });

                              dispatchReducer({
                                type: "RD_SET_FILTERED_INFO",
                                payload: {
                                  component: "client-response-table",
                                  filteredInfo: {
                                    ...selectedResponseValues,
                                    [clientResponseColumn.key]: null,
                                  },
                                },
                              });
                              setDropdownVisible(false);
                            }}
                          >
                            Reset
                          </Button>
                        </Tooltip>
                      </Col>

                      <Col>
                        <Tooltip
                          title={
                            !selectedResponseValues ||
                            !selectedResponseValues[clientResponseColumn.key]
                              ? "Select value to filter"
                              : "Apply filter"
                          }
                        >
                          <Button
                            type="primary"
                            size="small"
                            disabled={
                              !selectedResponseValues ||
                              !selectedResponseValues[clientResponseColumn.key]
                            }
                            onClick={() => {
                              dispatchMiddleware({
                                type: "MWD_LOAD_RESPONSES_DATA",
                                payload: {
                                  component: "client-response-table",
                                  currentResponseCategory,
                                  filteredValue: convertObjectToString(
                                    selectedResponseValues
                                  ),
                                  selectedResponseValues,
                                  searchvalue: state.filters.searchText?.[
                                    "client-responses-search-text-box"
                                  ]
                                    ? state.filters.searchText[
                                        "client-responses-search-text-box"
                                      ].concat("&search=true")
                                    : "",
                                  pageNumber: 1,
                                },
                              });

                              dispatchReducer({
                                type: "RD_SET_FILTERED_INFO",
                                payload: {
                                  component: "client-response-table",
                                  filteredInfo: selectedResponseValues,
                                },
                              });
                              setDropdownVisible(false);

                              appInsights.trackEvent({
                                name: "Response Filter",
                                properties: {
                                  clientId:
                                    state.currentAccount.account.client_id,
                                },
                              });
                            }}
                          >
                            OK
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>
      )}
    >
      <Button
        style={{
          textAlign: "left",
          margin: "5px 10px 5px 0",
        }}
        onClick={() => {
          setDropdownVisible(true);
          setSelectedResponseValues({
            ...selectedResponseValues,
            ...filteredValue,
          });
        }}
      >
        <Text
          type={
            filteredValue && filteredValue[clientResponseColumn.key]
              ? ""
              : "secondary"
          }
          strong={filteredValue && filteredValue[clientResponseColumn.key]}
        >
          {filteredValue && filteredValue[clientResponseColumn.key]?.length
            ? String(filteredValue[clientResponseColumn.key][0])?.slice(0, 13) +
              (filteredValue[clientResponseColumn.key]?.length > 1
                ? `...(+${filteredValue[clientResponseColumn.key].length - 1})`
                : "...")
            : clientResponseColumn.title.length > 13
            ? `${String(clientResponseColumn.title).slice(0, 13)}...`
            : clientResponseColumn.title}
        </Text>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ClientResponsesFilterDropdown;
