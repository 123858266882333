import { Col, Row } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import CannedResponsesFormPanel from "./CannedResponsesFormPanel";
import CannedResponsesHeader from "./CannedResponsesHeader";
import CannedResponsesTable from "./CannedResponsesTable";

const CannedResponsesList = ({
  selectedFolder,
  type,
  setShowCannedResponses,
  setSelectedMessage,
  selectedMessage,
}) => {
  const [state] = useGlobalContext();

  return (
    <>
      {state.cannedResponses.liveAgentCannedResponses ? (
        <>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <CannedResponsesHeader type={type} />
            </Col>
            <Col span={24}>
              <CannedResponsesTable
                selectedFolder={selectedFolder}
                type={type}
                setShowCannedResponses={setShowCannedResponses}
                setSelectedMessage={setSelectedMessage}
                selectedMessage={selectedMessage}
              />
            </Col>
            <CannedResponsesFormPanel />
          </Row>
        </>
      ) : (
        <FullPageLoader
          component="canned-responses-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default CannedResponsesList;
