const filters = (state, action) => {
  switch (action.type) {
    case "RD_SET_SEARCH_TEXT":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            searchText: {
              ...state.filters.searchText,
              ...{
                [action.payload.component]: action.payload.searchText,
              },
            },
          },
        },
      };

    case "RD_SET_SELECTED_ROW_KEYS":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            selectedRowKeys: {
              ...state.filters.selectedRowKeys,
              ...{
                [action.payload.component]: action.payload.selectedRowKeys,
              },
            },
          },
        },
      };

    case "RD_CLEAR_SELECTED_ROW_KEYS":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            selectedRowKeys: {
              ...state.filters.selectedRowKeys,
              ...{
                [action.payload.component]: [],
              },
            },
          },
        },
      };

    case "RD_SET_SORTED_INFO":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            sortedInfo: {
              ...state.filters.sortedInfo,
              ...{ [action.payload.component]: action.payload.sortedInfo },
            },
          },
        },
      };

    case "RD_SET_FILTERED_INFO":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            filteredInfo: {
              ...state.filters.filteredInfo,
              ...{
                [action.payload.component]: action.payload.filteredInfo,
              },
            },
          },
        },
      };

    case "RD_SET_ACTION_ROW_KEY":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            actionRowKey: {
              ...state.filters.actionRowKey,
              ...{
                [action.payload.component]: action.payload.actionRowKey,
              },
            },
          },
        },
      };

    case "RD_CLEAR_ACTION_ROW_KEY":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            actionRowKey: {
              ...state.filters.actionRowKey,
              ...{
                [action.payload.component]: "",
              },
            },
          },
        },
      };

    case "RD_SET_PAGINATION":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            pagination: {
              ...state.filters.pagination,
              ...{
                [action.payload.component]: action.payload.pagination,
              },
            },
          },
        },
      };

    case "RD_CLEAR_PAGINATION":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            pagination: {
              ...state.filters.pagination,
              ...{
                [action.payload.component]: undefined,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default filters;
