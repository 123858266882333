import React from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Result, Button, Card, Space, Typography } from "antd";
import { useGlobalContext } from "../../../../contexts/globalContext";
import BuyAddOnForm from "./BuyAddOnForm";
import { FullPageLoader } from "../../../../shared-components/LoadingIndicator";
import { BsFillClockFill } from "react-icons/bs";

const { Text } = Typography;

const BuyAddOnFormPanel = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const subscriptionDetails = state.accountBilling.subscription || {};
  const whatsappUsage = state.currentAccount.whatsappUsage;
  const currentBalance =
    (whatsappUsage?.currentBalance || 0) >= 0
      ? whatsappUsage?.currentBalance || 0
      : 0;

  const handleCheckBalance = () => {
    dispatchMiddleware({
      type: "MWD_LOAD_WHATSAPP_USAGE_DATA",
      payload: {
        botId: botInAction?.bot_id,
        clientId: botInAction?.bot_channel_info?.WHATSAPP?.waba_client_id,
        channelId:
          botInAction?.bot_channel_info?.WHATSAPP?.waba_channel_id?.[0],
        currentMonth: new Date().getMonth(),
        component: "bot-settings-whatsapp-button",
      },
    });
  };

  const CurrentBalance = () => {
    return (
      whatsappUsage?.showCurrentBalance && (
        <Card
          size="small"
          className="card-rounded-corner"
          title={
            <Text
              strong
              style={{
                fontSize: "14.2px",
              }}
            >
              Current Balance
            </Text>
          }
          extra={
            <Text
              style={{
                float: "right",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatchReducer({
                  type: "CLOSE_CURRENT_BALANCE_DETAILS",
                });
              }}
            >
              X
            </Text>
          }
        >
          <Space size="small" direction="vertical">
            <Text
              strong
              style={{
                fontSize: "31px",
              }}
            >
              ${currentBalance.toFixed(2)}
            </Text>
            <Text
              type="secondary"
              style={{
                fontSize: "12px",
              }}
            >
              This is your available balance for broadcasting.
            </Text>
          </Space>
        </Card>
      )
    );
  };

  const SuccessMessage = () => {
    return subscriptionDetails.newAddons.mode === "card" ? (
      <Result
        status="success"
        title="Successfully Purchased WhatsApp Add-on"
        subTitle={`Invoice number: ${subscriptionDetails?.newAddons?.invoice_number}. Process your payment details may take upto 5mins.`}
        extra={[
          <Space size="middle" direction="vertical">
            <Space>
              <Button
                type="primary"
                key="buy"
                onClick={() => {
                  handleCheckBalance();
                }}
                loading={
                  state.apiCallStatus?.["bot-settings-whatsapp-button"]?.loading
                }
              >
                Check Balance
              </Button>
              <Button
                key="createNewBroadcast"
                onClick={() => {
                  // Navigate to broadcast manager
                  navigate("/broadcast-manager");
                  sessionStorage.setItem("selectedMenu", "/broadcast-manager");

                  dispatchReducer({
                    type: "OPEN_FORM_PANEL",
                    payload: {
                      component: "broadcast-form-panel",
                      title: "Create New Broadcast",
                    },
                  });

                  dispatchReducer({
                    type: "CLOSE_FORM_PANEL",
                    payload: {
                      component: "buy-addons-form-panel",
                    },
                  });
                }}
              >
                Create Broadcast
              </Button>
            </Space>

            <CurrentBalance />
          </Space>,
        ]}
      />
    ) : (
      <Result
        icon={
          <BsFillClockFill
            style={{
              color: "#52c41a",
              fontSize: 80,
            }}
          />
        }
        title="You are almost there!"
        subTitle={`
        WhatsApp Add-on purchase initiated successfully. Invoice number: ${subscriptionDetails?.newAddons?.invoice_number}. Check your email for the invoice and share payment details to activate the pack.`}
        extra={[
          <Space size="middle" direction="vertical">
            <Space>
              <Button
                key="contactSupport"
                type="primary"
                onClick={async () => {
                  window.open(
                    "mailto:support@inextlabs.com?subject=" +
                      "WhatsApp Add-on Payment Details - " +
                      subscriptionDetails?.newAddons?.invoice_number +
                      "&body=" +
                      "Hi Team, %0D%0A%0D%0A" +
                      "I have made the payment for the WhatsApp Add-on. Please find the payment details below and activate the pack as soon as possible. %0D%0A%0D%0A" +
                      "Thank you"
                  );
                }}
              >
                Submit Payment Proof
              </Button>

              <Button
                key="buy"
                onClick={() => {
                  handleCheckBalance();
                }}
                loading={
                  state.apiCallStatus?.["bot-settings-whatsapp-button"]?.loading
                }
              >
                Check Balance
              </Button>
            </Space>

            <CurrentBalance />
          </Space>,
        ]}
      />
    );
  };

  return (
    <Drawer
      title="Buy WhatsApp Add-on"
      open={
        state.formPanel["buy-addons-form-panel"] &&
        state.formPanel["buy-addons-form-panel"].visible
      }
      footer={null}
      destroyOnClose={true}
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "buy-addons-form-panel",
          },
        });

        dispatchReducer({
          type: "SET_BUYADDONS_CURRENT_STEP",
          payload: {
            current: 0,
          },
        });

        dispatchReducer({
          type: "SET_ADDONS",
          payload: {
            addonDetails: null,
          },
        });

        dispatchReducer({
          type: "CLOSE_CURRENT_BALANCE_DETAILS",
        });
      }}
      placement="right"
      width={650}
      bodyStyle={{ backgroundColor: "#F4F7F9" }}
    >
      {!subscriptionDetails.newAddons ? (
        state.apiCallStatus["buy-addons-form"] &&
        state.apiCallStatus["buy-addons-form"].loading ? (
          <FullPageLoader
            component="buy-addons-form"
            loadingMessage="Submitting... Please wait..."
          />
        ) : (
          <BuyAddOnForm />
        )
      ) : (
        <SuccessMessage />
      )}
    </Drawer>
  );
};

export default BuyAddOnFormPanel;
