import { useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import { Tabs, Space, Layout } from "antd";
import { BsList } from "react-icons/bs";
import { AiOutlineCalendar } from "react-icons/ai";
import { TiFlowSwitch } from "react-icons/ti";
import ActivityLogs from "./activity-logs/ActivityLogs";
import ConversationFlow from "./conversation-flow/ConversationFlow";
import AccessDenied from "../../shared-components/AccessDenied";
import PlannedActivities from "./planned-activity/PlannedActivities";
import ModuleNotSubscribed from "../../shared-components/ModuleNotSubscribed";

const ActivityArea = (props) => {
  const [state] = useGlobalContext();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);

  const tabPane = [
    {
      key: "1",
      label: (
        <Space size={15}>
          <BsList size={18} style={{ margin: -4 }} />
          Activity Logs
        </Space>
      ),
      children: !state.currentUser.permission.BOT_ACTIVITY_LOG_VIEW ? (
        <AccessDenied />
      ) : (
        <ActivityLogs />
      ),
    },
    {
      key: "2",
      label: (
        <Space size={15}>
          <AiOutlineCalendar size={18} style={{ margin: -4 }} />
          Planned Activities
        </Space>
      ),
      children: !state.currentUser.permission.PLANNED_ACTIVITIES_VIEW ? (
        <AccessDenied />
      ) : (
        <PlannedActivities />
      ),
    },
    {
      key: "3",
      label: (
        <Space size={15}>
          <TiFlowSwitch size={18} style={{ margin: -4 }} />
          Conversation Flows
        </Space>
      ),
      children: <ConversationFlow />,
    },
  ];

  const handleTabChange = (key) => {
    switch (key) {
      case "1":
        navigate("/activity-area/activity-logs");
        break;
      case "2":
        navigate("/activity-area/planned-activities");
        break;
      case "3":
        navigate("/activity-area/conversation-flows");
        break;
      default:
        navigate("/activity-area/activity-logs");
    }
  };

  useEffect(() => {
    setActiveTab(props.tab.toString());
  }, [props]);

  return (
    <>
      {!state.currentAccount.account.modules.activities ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModuleNotSubscribed module="activities" />
        </Layout.Content>
      ) : !state.currentUser.permission.BOT_ACTIVITY_AREA_VIEW ? (
        <AccessDenied />
      ) : (
        <Tabs
          items={tabPane}
          onChange={handleTabChange}
          destroyInactiveTabPane={true}
          type="card"
          activeKey={activeTab}
        />
      )}
    </>
  );
};

export default ActivityArea;
