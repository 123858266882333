import React from "react";
import { Row, Col, Card } from "antd";
import BroadcastMessageByChannelChart from "./BroadcastMessageByChannelChart";
import BroadcastMessageByTemplateChart from "./BroadcastMessageByTemplateChart";
import BroadcastMessageByDateChart from "./BroadcastMessageByDateChart";
import BroadcastByDateChart from "./BroadcastByDateChart";
import BroadcastByTemplateChart from "./BroadcastByTemplateChart";
import BroadcastByChannelChart from "./BroadcastByChannelChart";

const BroadcastCharts = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <BroadcastMessageByChannelChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <BroadcastMessageByTemplateChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <BroadcastMessageByDateChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <BroadcastByTemplateChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <BroadcastByChannelChart />
        </Card>
      </Col>

      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <BroadcastByDateChart />
        </Card>
      </Col>
    </Row>
  );
};

export default BroadcastCharts;
