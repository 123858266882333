import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { openErrorNotificationWithDeveloperMessage } from "../utils/notification";
import moment from "moment";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useConversationsMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    // Use middleware based on liveagent version
    if (
      parseInt(
        state.currentAccount.account.modules?.conversation.live_agent_version
      ) !== 2
    ) {
      switch (action.type) {
        ////************************************************////
        ////**************** CONVERSATIONS API *************////
        ////************************************************////
        //Update contacts list along with transcripts
        case "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                ...[
                  "?",
                  !action.payload.delta
                    ? `pagesize=${action.payload.pageSize}&pagenumber=${action.payload.pageNumber}`
                    : `date=~gte~${state.conversations.lastUpdatedAt}`,
                ]
              ),

              // Check if live agent is enabled
              // Get the live conversation
              ...(state.currentAccount.account.modules.conversation
                ?.pull_live_conversation_on_refresh &&
              !state.conversations.contactsWithTranscripts?.length
                ? [apiEndPoints.CONVERSATION_STATUS]
                : []),
              ...(!state.accountUsers.users
                ? [apiEndPoints.ACCOUNT_USERS]
                : []),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([
              contactsWithTranscripts,
              conversationStatus,
              users,
            ]) => {
              dispatchReducer({
                type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                payload: {
                  contactsWithTranscripts,
                },
              });

              // Check if live agent is enabled.
              if (
                state.currentAccount.account.modules.conversation
                  ?.pull_live_conversation_on_refresh &&
                !state.conversations.contactsWithTranscripts?.length
              ) {
                // Modify array of conversation status into object

                conversationStatus = conversationStatus?.reduce(
                  (conversationStatus, conversation) => {
                    // Current agent conversation status
                    if (
                      conversation.live_agent_id ===
                      state.currentUser.user.userID
                    ) {
                      if (conversation.live_agent_status === "JOINED") {
                        conversationStatus.liveConversations.push(
                          conversation.conversation_id
                        );
                      }
                    }
                    // Waiting for live agent
                    else if (conversation.live_agent_status === "WAITING") {
                      conversationStatus.waitingForLiveAgent.push(
                        conversation.conversation_id
                      );
                    }

                    // Co-agent agent conversation status
                    else if (conversation.live_agent_status === "JOINED") {
                      conversationStatus.coAgentLiveConversations.push(
                        conversation.conversation_id
                      );
                    }
                    return conversationStatus;
                  },
                  {
                    liveConversations: [],
                    waitingForLiveAgent: [],
                    coAgentLiveConversations: [],
                  }
                );
                dispatchReducer({
                  type: "RD_CONVERSATIONS_AGENT_JOIN",
                  payload: {
                    liveConversations: conversationStatus.liveConversations,
                  },
                });
                dispatchReducer({
                  type: "SET_WAITING_FOR_LIVEAGENT",
                  payload: {
                    waitingForLiveAgent: conversationStatus.waitingForLiveAgent,
                  },
                });
                dispatchReducer({
                  type: "SET_CO_AGENT_LIVE_CONVERSATIONS",
                  payload: {
                    coAgentLiveConversations:
                      conversationStatus.coAgentLiveConversations,
                  },
                });
              } else {
                // If conversationStatus is empty in onSuccess, update users to its value.
                !state.accountUsers.users &&
                  dispatchReducer({
                    type: "SET_ACCOUNT_USERS",
                    users: conversationStatus?.data,
                  });
              }
              // If users is empty, update users to its value.
              !state.accountUsers.users &&
                dispatchReducer({
                  type: "SET_ACCOUNT_USERS",
                  users: users?.data,
                });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Update Conversation Contacts With Transcripts Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        //Update transcript for a contact
        case "MWD_UPDATE_CONVERSATIONS_TRANSCRIPTS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                "?_id=",
                action.payload.contactId,
                "&pagesize=" + action.payload.pageSize,
                "&pagenumber=" + action.payload.pageNumber
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([transcripts]) => {
              dispatchReducer({
                type: "RD_UPDATE_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  ...transcripts,
                  ...{ contactId: action.payload.contactId },
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Update Conversation Transcript Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        // If conversation triggered from response - view conversation
        case "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                ...[
                  `?conversation_id=${action.payload.conversationId}&bot_id=${action.payload.botId}`,
                ]
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: async ([contactsWithTranscripts]) => {
              await callApi({
                urls: [
                  apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                    "?_id=",
                    action.payload.contactId,
                    "&date=~gte~" + action.payload.leadDate
                  ),
                ],
                options: {
                  method: "GET",
                },
                onSuccess: ([transcripts]) => {
                  // For avoiding the duplicate messages in the conversation
                  // If transcripts is empty, update contactsWithTranscripts to its value.
                  if (
                    transcripts.data.length &&
                    contactsWithTranscripts.data[0]
                  ) {
                    contactsWithTranscripts.data[0].messages = [];
                  }
                  dispatchReducer({
                    type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                    payload: {
                      contactsWithTranscripts,
                    },
                  });
                  dispatchReducer({
                    type: "RD_UPDATE_CONVERSATIONS_TRANSCRIPTS",
                    payload: {
                      ...transcripts,
                      ...{ contactId: action.payload.contactId },
                    },
                  });
                  dispatchReducer({
                    type: "SUCCESS_API_TRANSACTION",
                    payload: { component: action.payload.component },
                  });
                },
                onError: (error) => {
                  dispatchReducer({
                    type: "ERROR_API_TRANSACTION",
                    payload: { component: action.payload.component, error },
                  });
                },
              });
            },

            onError: () => {
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
          });
          break;

        //Agent joining the conversation
        case "MWD_CONVERSATIONS_AGENT_JOIN":
          console.log("Action ->", action);

          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });

          const conversationData =
            state.conversations.contactsWithTranscripts.find(
              (contactWithTranscripts) =>
                contactWithTranscripts._id ===
                action.payload.conversationId.concat("_", action.payload.botId)
            );

          console.log("Conversation Data ->", conversationData);

          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                "?_id=",
                action.payload.conversationId.concat("_", action.payload.botId),
                "&date=~gt~",
                conversationData.messages[conversationData.messages.length - 1]
                  .date
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: async ([transcripts]) => {
              dispatchReducer({
                type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                  data: transcripts.data,
                },
              });

              await callApi({
                urls: [
                  state.currentAccount.account.bots.find(
                    (bot) => bot.bot_id === action.payload.botId
                  ).bot_liveagent_api_endpoint,
                ],
                options: {
                  method: "POST",
                  body: {
                    message: "",
                    agentId: state.currentUser.user.userID,
                    agentName: state.currentUser.user.displayName,
                    conversationID: action.payload.conversationId,
                    messageType: "JOIN",
                    channel: state.conversations.currentConversation.channel,
                    avatar: "",
                    contentType: "",
                  },
                },
                onSuccess: ([response]) => {
                  appInsights.trackEvent({
                    name: "Conversations - Liveagent joined the conversation",
                    properties: {
                      environment: mode,
                      clientId: state.currentAccount.account.client_id,
                    },
                  });
                  dispatchReducer({
                    type: "RD_CONVERSATIONS_AGENT_JOIN",
                    payload: {
                      contactId: action.payload.conversationId.concat(
                        "_",
                        action.payload.botId
                      ),
                    },
                  });
                  dispatchReducer({
                    type: "UPDATE_WAITING_FOR_LIVEAGENT",
                    payload: {
                      contactId: action.payload.conversationId.concat(
                        "_",
                        action.payload.botId
                      ),
                    },
                  });
                  dispatchReducer({
                    type: "SUCCESS_API_TRANSACTION",
                    payload: { component: action.payload.component },
                  });
                },
                onError: (error) => {
                  appInsights.trackException({
                    exception: error,
                    properties: {
                      title: "Conversations Agent Join Error",
                      clientId: state.currentAccount.account.client_id,
                      environment: mode,
                    },
                  });
                  console.log(error);
                  dispatchReducer({
                    type: "ERROR_API_TRANSACTION",
                    payload: { component: action.payload.component, error },
                  });
                  openErrorNotificationWithDeveloperMessage(error);
                },
              });
            },
            onError: (error) => {},
          });
          break;

        //Agent leaving the conversation
        case "MWD_CONVERSATIONS_AGENT_LEAVE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });

          await callApi({
            urls: [
              state.currentAccount.account.bots.find(
                (bot) => bot.bot_id === action.payload.botId
              ).bot_liveagent_api_endpoint,
            ],
            options: {
              method: "POST",
              body: {
                message: "",
                agentId: state.currentUser.user.userID,
                agentName: state.currentUser.user.displayName,
                conversationID: action.payload.conversationId,
                messageType: "END",
                channel: state.conversations.currentConversation.channel,
                avatar: "",
                contentType: "",
              },
            },
            onSuccess: ([response]) => {
              appInsights.trackEvent({
                name: "Conversations - Liveagent ended the conversation",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                },
              });
              dispatchReducer({
                type: "RD_CONVERSATIONS_AGENT_LEAVE",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Conversations Agent Leave Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        //Agent sending messages to the user
        case "MWD_CONVERSATIONS_AGENT_MESSAGE":
          const currentMoment = moment().utc().format();
          dispatchReducer({
            type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
            payload: {
              contactId: action.payload.conversationId.concat(
                "_",
                action.payload.botId
              ),
              data: [
                {
                  date: currentMoment,
                  is_from_user: false,
                  type: "text",
                  message: action.payload.message,
                  is_agent: true,
                  agent_id: state.currentUser.user.userID,
                  isSending: true,
                  component: action.payload.component,
                },
              ],
            },
          });

          await callApi({
            urls: [
              state.currentAccount.account.bots.find(
                (bot) => bot.bot_id === action.payload.botId
              ).bot_liveagent_api_endpoint,
            ],
            options: {
              method: "POST",
              body: {
                message: action.payload.message,
                agentId: state.currentUser.user.userID,
                agentName: state.currentUser.user.displayName,
                conversationID: action.payload.conversationId,
                messageType: "MESSAGE",
                channel: state.conversations.currentConversation.channel,
                avatar: "",
                contentType: "",
              },
            },
            onSuccess: ([response]) => {
              const currentMoment = moment().utc().format();
              dispatchReducer({
                type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                  data: [
                    {
                      date: currentMoment,
                      is_from_user: false,
                      type: "text",
                      message: action.payload.message,
                      is_agent: true,
                      agent_id: state.currentUser.user.userID,
                      // id: "Message_" + currentMoment,
                    },
                  ],
                },
              });

              dispatchReducer({
                type: "RD_REMOVE_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  _id: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                  component: action.payload.component,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Conversation Agent Message Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        //Handling messages from socket
        //ALL ***ACTION PAYLOADS*** ARE FROM WEB SOCKET (NOT FROM MIDDLEWARE / COMPONENTS)
        case "MWD_CONVERSATIONS_FROM_WEB_SOCKET":
          dispatchReducer({
            type: "RD_CONVERSATIONS_UNREAD",
            payload: {
              contactId: action.payload._id,
            },
          });

          if (action.payload.message_type === "JOIN") {
            dispatchReducer({
              type: "SET_WAITING_FOR_LIVEAGENT",
              payload: {
                contactId:
                  action.payload.conversationID + "_" + action.payload.botId,
              },
            });
            await callApi({
              urls: [
                apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                  "?",
                  state.conversations.lastUpdatedAt
                ),
              ],
              options: {
                method: "GET",
              },
              onSuccess: ([contactsWithTranscripts]) => {
                dispatchReducer({
                  type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                  payload: {
                    contactsWithTranscripts,
                  },
                });
              },
              onError: (error) => {
                appInsights.trackException({
                  exception: error,
                  properties: {
                    title: "Join Conversation Web Socket Error",
                    clientId: state.currentAccount.account.client_id,
                    environment: mode,
                  },
                });
              },
            });
          } else if (action.payload.message_type === "END") {
            const conversationData =
              state.conversations.contactsWithTranscripts.find(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload._id
              );

            await callApi({
              urls: [
                apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                  "?_id=",
                  action.payload._id,
                  "&date=~gt~",
                  conversationData.messages[
                    conversationData.messages.length - 1
                  ].date
                ),
              ],
              options: {
                method: "GET",
              },
              onSuccess: async ([transcripts]) => {
                dispatchReducer({
                  type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
                  payload: {
                    contactId: action.payload._id,
                    data: transcripts.data,
                  },
                });

                dispatchReducer({
                  type: "RD_CONVERSATIONS_AGENT_LEAVE",
                  payload: {
                    contactId: action.payload._id,
                  },
                });
              },
              onError: (error) => {
                appInsights.trackException({
                  exception: error,
                  properties: {
                    title: "Conversation Agent Leave Web Socket Error",
                    clientId: state.currentAccount.account.client_id,
                    environment: mode,
                  },
                });
                dispatchReducer({
                  type: "ERROR_API_TRANSACTION",
                  payload: {
                    component:
                      "agent-leave-" +
                      state.conversations.currentConversation._id +
                      "-button",
                    error,
                  },
                });
                openErrorNotificationWithDeveloperMessage(error);
              },
            });
          } else if (action.payload.message_type === "MESSAGE") {
            dispatchReducer({
              type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
              payload: {
                contactId: action.payload._id,
                data: [
                  {
                    date: action.payload.timestamp,
                    is_from_user: true,
                    type: "text",
                    message: action.payload.message,
                    is_agent: false,
                  },
                ],
              },
            });
          }
          break;
      }
    }
  };
};
