import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Input,
  Form,
  Row,
  Col,
  Button,
  Divider,
  Space,
  Select,
  Radio,
} from "antd";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import { languageList } from "../../../constants/enums";
import { useEffect, useState } from "react";

const { TextArea } = Input;
const { Option } = Select;

const CannedResponsesForm = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [selectedRadioButton, setSelectedRadioButton] = useState("GLOBAL");
  const [showDropdown, setShowDropdown] = useState(true);
  const [showInput, setShowInput] = useState(false);

  const handleFolderSelect = () => {
    if (showInput) {
      setShowInput(false);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      setShowInput(true);
    }
    form.resetFields(["folderName"]);
  };

  useEffect(() => {
    form.setFieldsValue({ availableFor: "GLOBAL" });

    if (state.form["canned-responses-form"].mode === "edit") {
      const recordToBeEdited =
        state.cannedResponses.liveAgentCannedResponses.find(
          (response) =>
            response._id ===
            state.filters.actionRowKey["canned-responses-table"]
        );

      form.setFieldsValue({
        availableFor: recordToBeEdited.availableFor,
        folderName: recordToBeEdited.folderName,
        language: recordToBeEdited.language,
        responseMessage: recordToBeEdited.responseMessage,
        responseTitle: recordToBeEdited.responseTitle,
      });
      setSelectedRadioButton(recordToBeEdited.availableFor);
    }
  }, []);

  const handleRadioChange = (e) => {
    setSelectedRadioButton(e.target.value);
  };

  const handleCreateCannedResponse = (e) => {
    let cannedResponseForm = {
      responseTitle: e.responseTitle,
      responseMessage: e.responseMessage,
      language: e.language,
      folderName: e.folderName,
      availableFor: e.availableFor,
    };
    if (e.availableFor === "MYSELF") {
      cannedResponseForm.userID = state.currentUser.user.userID;
    }

    dispatchMiddleware({
      type: "MWD_ADD_EDIT_CANNED_RESPONSE",
      payload: {
        component: "canned-responses-form",
        cannedResponse: cannedResponseForm,
      },
    });
  };

  return (
    <>
      {state.apiCallStatus["canned-responses-form"] &&
      state.apiCallStatus["canned-responses-form"].loading ? (
        <FullPageLoader
          component="canned-responses-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleCreateCannedResponse}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="responseTitle"
                label="Response Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter response title",
                  },
                  { whitespace: true },
                  {
                    max: 50,
                    message:
                      "Response title should not be more then 50 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter the response title" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="responseMessage"
                label="Response Message"
                rules={[
                  {
                    required: true,
                    message: "Please enter response message",
                  },
                  { whitespace: true },
                  {
                    max: 1024,
                    message:
                      "Response message should not be more then 1024 characters",
                  },
                ]}
              >
                <TextArea
                  style={{
                    resize: "none",
                  }}
                  showCount
                  maxLength={1024}
                  placeholder="Enter response message"
                  rows="5"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="language"
                label="Language"
                rules={[
                  {
                    required: true,
                    message: "Please select language",
                  },
                ]}
              >
                <Select showSearch placeholder="Please select language">
                  {languageList.map((language) => (
                    <Option key={language.value} value={language.value}>
                      {language.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="availableFor"
                label="Available For"
                rules={[
                  {
                    required: true,
                    message: "Please select available for",
                  },
                ]}
              >
                <Radio.Group
                  defaultValue={selectedRadioButton}
                  value={selectedRadioButton}
                  onChange={handleRadioChange}
                >
                  <Radio value="MYSELF">Myself</Radio>
                  <Radio value="GLOBAL">Global</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              {showDropdown && (
                <Form.Item
                  name="folderName"
                  label="Folder Name"
                  rules={[
                    { required: true, message: "Please select a folder name." },
                  ]}
                >
                  <Select showSearch placeholder="Please select folder">
                    {state.cannedResponses.cannedResponsesFolderList.map(
                      (folderList) => {
                        return (
                          <Option key={folderList._id} value={folderList._id}>
                            {folderList._id}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              )}
              {showInput && (
                <Form.Item
                  name="folderName"
                  label="Folder Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the folder name.",
                    },
                    {
                      pattern: /^[a-zA-Z0-9 ]+$/,
                      message: "Special characters are not allowed",
                    },
                    {
                      max: 50,
                      message:
                        "Folder name must be less than or equal to 50 characters",
                    },
                  ]}
                >
                  <Input placeholder="Enter folder name" />
                </Form.Item>
              )}

              <Form.Item>
                <Button onClick={handleFolderSelect} type="dashed">
                  {showInput ? (
                    <>Select Existing Folder</>
                  ) : (
                    <>Create New Folder</>
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={12}>
              <Space>
                <Form.Item>
                  <Button>Cancel</Button>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default CannedResponsesForm;
