import { Button, Space, Input, Divider, Tooltip, Row, Col } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { RiFilterOffLine } from "react-icons/ri";

const ActivityLogsHeader = () => {
  return (
    <Row>
      <Col span={24}>
        <Space>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search by Activity Type"
            // value={state.broadcastManager.filters.searchText}
            style={{ width: 400 }}
            // onChange={(e) => {
            //   const currValue = e.target.value;
            //   dispatch({
            //     type: "SET_BROADCAST_MANAGER_SEARCH_TEXT",
            //     searchText: currValue,
            //   });
            // }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
          />

          <Divider type="vertical" />

          <Tooltip placement="top" title="Clear filter(s)">
            <Button
              disabled={
                true
                // !state.broadcastManager.filters.filteredInfo.sentDate &&
                // !state.broadcastManager.filters.filteredInfo.channel
              }
              icon={<RiFilterOffLine style={{ margin: -2 }} />}
              onClick={() => {
                // dispatch({
                //   type: "SET_BROADCAST_MANAGER_FILTERED_INFO",
                //   filteredInfo: {},
                // });
              }}
            />
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};

export default ActivityLogsHeader;
