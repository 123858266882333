import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { appInsights } from "../AppInsights";
import { openErrorNotificationWithDeveloperMessage } from "../utils/notification";
import { mode } from "../constants/env";

export const useAccountBillingMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////**************** ACCOUNT Billing *************////
      ////*******************************************////

      //Customer subscription details
      case "LOAD_SUBSCRIPTION_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.SUBSCRIPTION],
          options: {
            method: "GET",
          },
          onSuccess: ([subscriptionDetails]) => {
            dispatchReducer({
              type: "SET_SUBSCRIPTION_DATA",
              payload: {
                subscription: subscriptionDetails,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Subscription Details Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Buy Add-ons
      case "BUY_ADDONS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [apiEndPoints.WHATSAPP.concat("/package")],
          options: {
            method: "POST",
            body: action.payload.addonDetails,
          },
          onSuccess: async ([addonDetails]) => {
            dispatchReducer({
              type: "SET_ADDONS",
              payload: {
                addonDetails: action.payload.addonDetails.card_id
                  ? {
                      ...addonDetails,
                      mode: "card",
                    }
                  : addonDetails,
              },
            });

            await callApi({
              urls: [
                apiEndPoints.WHATSAPP_USAGE.concat(
                  `?waba_client_id=${
                    action.payload.addonDetails.waba_client_id || ""
                  }&from_month=${new Date().getMonth()}&bot_id=${
                    state.application.botInAction
                  }&channel_id=${action.payload.channelId || ""}`
                ),
              ],
              options: {
                method: "GET",
              },
              onSuccess: ([whatsappUsage]) => {
                dispatchReducer({
                  type: "RD_SET_WHATSAPP_USAGE_DATA",
                  payload: {
                    whatsappUsage,
                  },
                });

                dispatchReducer({
                  type: "SUCCESS_API_TRANSACTION",
                  payload: { component: action.payload.component },
                });
              },
              onError: (error) => {
                appInsights.trackException({
                  exception: error,
                  properties: {
                    title: "Load WhatsApp Usage Error",
                    clientId: state.currentAccount.account.client_id,
                    environment: mode,
                  },
                });
                dispatchReducer({
                  type: "ERROR_API_TRANSACTION",
                  payload: { component: action.payload.component, error },
                });
                openErrorNotificationWithDeveloperMessage(error);
              },
            });

            dispatchReducer({
              type: "CLOSE_CURRENT_BALANCE_DETAILS",
            });

            appInsights.trackEvent({
              name: "Broadcast - Add-ons Purchased",
              properties: {
                environment: mode,
                paymentMode: action.payload.addonDetails.card_id
                  ? "card"
                  : "bank",
                clientId: state.currentAccount.account.client_id,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: JSON.stringify(error),
              properties: {
                title: "Broadcast - Buy Add-ons Error",
                paymentMode: action.payload.addonDetails.card_id
                  ? "card"
                  : "bank",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
