import { useGlobalContext } from "../../../contexts/globalContext";
import { Row, Col } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import WhatsAppFlowsHeader from "./WhatsAppFlowsHeader";
import WhatsAppFlowsTable from "./WhatsAppFlowsTable";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import WhatsAppFlowsFormPanel from "./WhatsAppFlowsFormPanel";
import AccessDenied from "../../../shared-components/AccessDenied";
import ModuleNotSubscribed from "../../../shared-components/ModuleNotSubscribed";
import "./WhatsAppFlows.less";

const WhatsAppFlows = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const wabaAccountId =
    botInAction?.bot_channel_info?.WHATSAPP?.waba_account_id;

  useConnectMiddleware(
    () => {
      if (state.currentUser.permission.WHATSAPP_FLOWS_VIEW) {
        state.whatsappFlows &&
          dispatchReducer({ type: "CLEAR_WHATSAPP_FLOWS" });
        if (wabaAccountId) {
          dispatchMiddleware({
            type: "LOAD_WHATSAPP_FLOWS",
            payload: {
              component: "whatsapp-flows-table",
              botInAction,
              wabaAccountId,
            },
          });
        }
      }
    },

    {
      botInAction: state.application.botInAction,
    }
  );

  return state.currentUser.permission.WHATSAPP_FLOWS_VIEW ? (
    wabaAccountId ? (
      state.whatsappFlows ? (
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <WhatsAppFlowsHeader />
          </Col>
          <Col span={24}>
            <WhatsAppFlowsTable />
          </Col>
          <WhatsAppFlowsFormPanel />
        </Row>
      ) : (
        <FullPageLoader
          component="whatsapp-flows-table"
          loadingMessage="Loading... Please wait..."
        />
      )
    ) : (
      <ModuleNotSubscribed module="whatsapp-flows" />
    )
  ) : (
    <AccessDenied />
  );
};

export default WhatsAppFlows;
