import { useState } from "react";
import { Col, Row, Tabs, Space, Layout } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { AiOutlineContacts } from "react-icons/ai";
import ContactManagerHeader from "./ContactManagerHeader";
import ContactManagerTable from "./ContactManagerTable";
import ContactManagerFormPanel from "./ContactManagerFormPanel";
import useConnectMiddleware from "../../connect/connectMiddleware";
import ContactManagerInfoPanel from "./ContactManagerInfoPanel";
import {
  FullPageLoader,
  TabLoader,
} from "../../shared-components/LoadingIndicator";
import AccessDenied from "../../shared-components/AccessDenied";
import GetStarted from "../../shared-components/GetStarted";
import ImportContactsV2 from "./ImportContactsV2";
import "./ContactManager.less";

const ContactManager = ({ type = "standard" }) => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [showContactScreen, setShowContactScreen] = useState(false);

  const tableColumns = state.contactManagerSettings.tableColumns;
  const contacts = state.contactManager.contacts;

  useConnectMiddleware(() => {
    if (state.currentUser.permission.CONTACTS_VIEW) {
      dispatchMiddleware({
        type: "MWD_LOAD_CONTACT_MANAGER_DATA",
        payload: {
          component: "contact-manager-table",
          enums: state.application.enums?.contacts?.data,
        },
      });

      !state.accountUsers.users &&
        dispatchMiddleware({
          type: "MWD_LOAD_ACCOUNT_USERS_DATA",
          payload: { component: "account-users-table" },
        });
    }
  });

  return (
    <>
      {!state.currentUser.permission.CONTACTS_VIEW ? (
        <AccessDenied />
      ) : tableColumns &&
        contacts &&
        contacts.length === 0 &&
        !showContactScreen ? (
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GetStarted
            source="contacts"
            setShowContactScreen={setShowContactScreen}
          />
        </Layout.Content>
      ) : contacts || showContactScreen ? (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <AiOutlineContacts size={18} style={{ margin: -4 }} />
                  Contacts
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <ContactManagerHeader type={type} />
                  </Col>
                  <Col span={24}>
                    <ContactManagerTable type={type} />
                  </Col>

                  <ContactManagerFormPanel />
                  <ImportContactsV2 />
                  <ContactManagerInfoPanel />
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            <TabLoader
              component="contact-manager-table"
              loadingMessage="Synchronizing contact..."
              errorMessage="Error synchronizing contact"
            />
          }
        />
      ) : (
        <FullPageLoader
          component="contact-manager-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default ContactManager;
