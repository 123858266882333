import { useState } from "react";
import { Space, Typography, Button, Modal, Table, Tag } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import ContactManager from "../contact-manager/ContactManager";

const { Text } = Typography;

const SelectContact = ({
  importedDataSource,
  handleGetParsedData,
  handleCancelImport,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [state, dispatchReducer] = useGlobalContext();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const contactTableColumns = state.contactManagerSettings.tableColumns || [];
  const contactProperties = state.contactManagerSettings.contactProperties;

  const handleGetContactsData = () => {
    const columns = (
      contactProperties || Object.keys(state.contactManager.contacts[0])
    ).map((column) => {
      return {
        _id: column._id || column.key,
        key: "{" + (column.display_name || column._id || column.key) + "}",
        dataIndex: column.key,
        title: column.display_name || column._id || column.key,
        width: column.width || 100,
        ellipsis: true,
        align: column.key === "isUnsubscribed" ? "center" : "left",
      };
    });

    const data =
      state.filters.selectedRowKeys["contact-manager-table"] &&
      state.filters.selectedRowKeys["contact-manager-table"]?.map(
        (selectedRowKey) => {
          const contact = state.contactManager.contacts.find(
            (contact) => contact._id === selectedRowKey
          );

          if (!contact) return {};

          return Object.keys(contact).reduce((acc, key) => {
            const displayName = columns.find((col) => col._id === key)?.title;

            // if display name not found then remove the key from the object
            if (!displayName) return acc;
            acc[displayName] = contact[key];
            return acc;
          }, {});
        }
      );

    setColumns(columns);
    setData(data);
    setIsModalVisible(false);

    const keys = columns.map((column) => column.title);

    const commaSeparatedString = [
      keys.join(","),
      data.map((row) => keys.map((key) => row[key]).join(",")).join("\n"),
    ].join("\n");

    let csvData = new Blob([commaSeparatedString], { type: "text/csv" });
    const csvFile = new File([csvData], "contacts.csv");

    handleGetParsedData(columns, data, "contacts", csvFile);
  };

  return (
    <>
      {data.length > 0 && importedDataSource === "contacts" ? (
        <Space
          className="label-file-upload uploadComplete"
          direction="vertical"
          size={10}
        >
          <Text strong>
            {data.length + " "}
            record(s) fetched from Contacts
          </Text>

          <Space>
            <Button
              onClick={() => {
                setIsPreviewModalVisible(true);
              }}
            >
              Preview
            </Button>
            <Button
              onClick={() => {
                setData([]);
                dispatchReducer({
                  type: "RD_CLEAR_SELECTED_ROW_KEYS",
                  payload: { component: "contact-maanger-table" },
                });
                handleCancelImport();
              }}
            >
              Cancel
            </Button>
          </Space>
        </Space>
      ) : (
        <Space
          className="label-file-upload"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          <Space direction="vertical" size={0}>
            <Text>Click here to select contacts from</Text>
            <Text strong>Contact Manager</Text>
          </Space>
        </Space>
      )}
      <Modal
        title={"Contact List"}
        bodyStyle={{ height: 400, overflow: "auto" }}
        open={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        destroyOnClose="true"
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              dispatchReducer({
                type: "RD_CLEAR_SELECTED_ROW_KEYS",
                payload: { component: "contact-manager-table" },
              });
              setIsModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            disabled={
              state.filters.selectedRowKeys["contact-manager-table"]
                ? state.filters.selectedRowKeys["contact-manager-table"]
                    .length > 0
                  ? false
                  : true
                : true
            }
            type="primary"
            key="select"
            onClick={handleGetContactsData}
          >
            Select Contacts
          </Button>,
        ]}
        width="1250px"
      >
        <ContactManager type="filter" />
      </Modal>

      <Modal
        title={"Preview (" + data.length + " Records)"}
        cancelButtonProps={{ style: { display: "none" } }}
        open={isPreviewModalVisible}
        onOk={() => {
          setIsPreviewModalVisible(false);
        }}
        onCancel={() => {
          setIsPreviewModalVisible(false);
        }}
        width="1250px"
      >
        <div style={{ maxHeight: 400, overflow: "auto" }}>
          <Table
            dataSource={data}
            columns={contactTableColumns.map((contactColumn) => {
              return {
                title:
                  contactColumn.display_name?.toUpperCase() ||
                  contactColumn?.toUpperCase(),
                dataIndex: contactColumn.display_name || contactColumn.key,
                key: contactColumn.display_name || contactColumn.key,
                width: contactColumn.width || 100,
                ellipsis: true,
                align:
                  contactColumn.key === "isUnsubscribed" ? "center" : "left",
                sorter: ![
                  "tags",
                  "mobile",
                  "whatsappId",
                  "isUnsubscribed",
                ].includes(contactColumn.key)
                  ? (a, b) => {
                      let valueA = !a[contactColumn.key]
                        ? ""
                        : a[contactColumn.key];
                      let valueB = !b[contactColumn.key]
                        ? ""
                        : b[contactColumn.key];
                      return valueA.localeCompare(valueB);
                    }
                  : null,
                render: (text, record) => {
                  if (contactColumn.key === "tags" && record.tags) {
                    return (
                      <Space size="small">
                        {record.tags
                          ?.split(",")
                          .filter((tag) => tag.trim() !== "")
                          .map((tag) => (
                            <Tag
                              key={tag}
                              color="blue"
                              type="round"
                              style={{ borderRadius: "4px" }}
                            >
                              {tag}
                            </Tag>
                          ))}
                      </Space>
                    );
                  }
                  return text;
                },
              };
            })}
            pagination={{ position: ["none", "none"], defaultPageSize: 0 }}
            scroll={{ y: 240, x: "100vw" }}
            size="small"
            rowKey={Math.random()}
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectContact;
