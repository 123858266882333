import { useNavigate } from "react-router-dom";
import { Tabs, Space } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { TabLoader } from "../../../shared-components/LoadingIndicator";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./AccountBilling.less";
import { MdDataUsage } from "react-icons/md";
import { HiOutlineKey } from "react-icons/hi";
import AccountSubscription from "./account-subscription/AccountSubscription";
import AccountUsage from "./AccountUsage";
import { Translation } from "../../../translation/translation";
import { mode } from "../../../constants/env";

const AccountBilling = () => {
  const [state] = useGlobalContext();
  const navigate = useNavigate();

  // Get translated text
  const billingModule = Translation().accountSettings.billing;

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      <Tabs
        type="card"
        items={[
          {
            key: "1",
            label: (
              <Space size={15}>
                <HiOutlineKey size={19} style={{ margin: -4 }} />
                {billingModule.subscription.title}
              </Space>
            ),
            children: <AccountSubscription />,
          },
          ["DEV", "STAGING"].includes(mode) && {
            key: "2",
            label: (
              <Space size={15}>
                <MdDataUsage size={19} style={{ margin: -4 }} />
                Usage
              </Space>
            ),
            children: <AccountUsage />,
          },
        ]}
        tabBarExtraContent={
          state.apiCallStatus["subscription-list"] &&
          state.apiCallStatus["subscription-list"].loading && (
            <TabLoader
              component="subscription-list"
              loadingMessage="Synchronizing subscription..."
              errorMessage="Error synchronizing subscription"
            />
          )
        }
      />
    </>
  );
};

export default AccountBilling;
