import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../contexts/globalContext";

const AccountAvatar = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Avatar
      size={40}
      icon={<UserOutlined />}
      src={state.currentUser.user.profilePicture}
      style={{ border: "1px solid #c5c5c5", margin: "auto" }}
      onClick={() => {
        dispatchReducer({
          type: "OPEN_INFO_PANEL",
          payload: {
            component: "current-user-info-panel",
          },
        });
      }}
    />
  );
};

export default AccountAvatar;
