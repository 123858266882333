import React from "react";
import { Row, Col, Typography, Progress } from "antd";

const { Title, Text } = Typography;

const AccountUsage = () => {
  let billingData = {
    summarizeConversation: {
      period: "0923",
      tokenUsage: 800,
      maxTokenAllowed: 10000, // From account API
    },
    aiAssistant: {
      period: "0823",
      tokenUsage: 10000,
      maxTokenAllowed: 10000, // From account API
    },
  };

  return (
    <Row
      gutter={[10, 20]}
      style={{
        padding: 20,
        border: "1px solid #e8e8e8",
        borderRadius: 5,
      }}
    >
      {/* Billing screen */}
      <Col span={8}>
        <Title level={5}>Summarize conversation</Title>
        <Text type="secondary">Insight of this billing cycle</Text>
      </Col>
      <Col span={16}>
        <div className="custom-progress-container">
          <Progress percent={30} size={100} showInfo={false} status="" />
        </div>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            display: "block",
          }}
        >
          Token usage :{" "}
          <Text style={{ color: "#1890ff" }} strong>
            {billingData.summarizeConversation.tokenUsage}{" "}
          </Text>
          /10000
        </Text>
        <br />
      </Col>

      <Col span={8}>
        <Title level={5}>Ai Assistant</Title>
        <Text type="secondary">Insight of this billing cycle</Text>
      </Col>
      <Col span={16}>
        <div className="custom-progress-container">
          <Progress
            percent={100}
            size={100}
            showInfo={false}
            status="exception"
          />
        </div>
        <Text
          type="danger"
          style={{
            marginTop: 10,
          }}
        >
          Maximum usage reached. Please upgrade your plan to continue.
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            display: "block",
          }}
        >
          Token usage :{" "}
          <Text style={{ color: "#1890ff" }} strong>
            {billingData.aiAssistant.tokenUsage}{" "}
          </Text>
          /10000
        </Text>
      </Col>
      {/* {state.broadcastSettings ? (
      <Row>
        <Col span={24}>
          <BroadcastForm />
        </Col>
      </Row>
    ) : (
      <FullPageLoader
        component="broadcast-settings"
        loadingMessage="Loading... Please wait..."
      />
    )} */}
    </Row>
  );
};

export default AccountUsage;
