export const mode = "STAGING";

export const URIs = {
  PORTAL: "https://staging.inextlabs.ai",
  B2C_LOGIN: "https://staging.inextlabs.ai",
  B2C_POST_LOGOUT: "https://staging.inextlabs.ai",
  B2C_CHANGE_PASSWORD:
    "https://inextlabsidentity.b2clogin.com/inextlabsidentity.onmicrosoft.com/oauth2/v2.0/authorize",
  API_NODE: "https://inextlabs-services-node-staging-as.azurewebsites.net", //Node JS APIs
  AI_ASSISTANT:
    "https://portalv2-staging-oai.openai.azure.com/openai/deployments/gpt-35-turbo",
  AI_CONVERSATION_SUMMARY:
    "https://portalv2-staging-oai.openai.azure.com/openai/deployments/gpt-35-turbo",
  API_DOCUMENTATION: "https://inextlabs.stoplight.io/",
  DOCUMENTATIONS: "https://docs.inextlabs.ai",
  API_KEY_FUNCTION_APP: "https://generateapimsubscription.azurewebsites.net",
  BROADCAST_FUNCTION_APP:
    "https://inextbroadcast-staging.azurewebsites.net{apiEndPoint}?code=YTxH4yPD47XL-5Z-DscGi6huEU93UtpxzdwxNFrYB77tAzFuzWaUPQ==",
  BROADCAST_FUNCTION_APP_V3:
    "https://broadcastv3-staging-fa.azurewebsites.net{apiEndPoint}?code=Tagr8Sgy1ciIjNNRBvvgPy6NSqqUUBf1DeZWQ21x2n5SAzFuyt0Y9g==",
  BROADCAST_FUNCTION_APP_V4:
    "https://broadcastv4-staging-fa.azurewebsites.net{apiEndPoint}?code=dbYRMWq4Rb7Hr0bKPfc6vYhKqsS7sfsFpzwAA5h4c9EtAzFusfEZzQ==",
  HELP_AND_SUPPORT_DOCUMENTATION: "https://docs.inextlabs.ai/",
  BOT_KNOWLEDGEBASE_FUNCTION_APP:
    "https://qna-llm-v2-staging-dev.azurewebsites.net{apiEndPoint}?code=bKWdZvYJzN3m1Ic02tCtGQbUkWTSiTazxl-PhBGIj2GpAzFuIPQuBg==",
  DIALOG360: "https://hub.360dialog.com",
  WABA: "https://business.facebook.com",
  FEEDBACK_LOGIC_APP:
    "https://prod-57.southeastasia.logic.azure.com:443/workflows/e1aae40932fb411f905cd2027afdeac3/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SNhI5ghv6SwXcMhryOobYFO-YqpVn0LFcX8ecvQxKCY",
  GOOGLEMAP: "https://maps.google.com",
  CHANNEL_REQUEST:
    "https://prod-05.southeastasia.logic.azure.com:443/workflows/1f816b23af2d4b18a14b3e40aab0d954/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=d4cB-icKpIx4AqSdRiueKJL9EUPys09HWY6k_2qpXt8", //Logic App
  APP_INSIGHTS_INSTRUMENTATION_KEY: "ac07d68e-1e15-408f-b6e9-b872bd1ee7fa",
  AES_KEY: "iNextSaltKeyP@$$w0rd1234",
  WOOCOMMERCE_REST_DOCUMENTATION: "https://woocommerce.github.io",
  WEBSITE: "https://inextlabs.ai",
  FACEBOOK: "https://www.facebook.com",
};
