import { Space, Modal, Tag, Tooltip, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { IoArrowUndoOutline } from "react-icons/io5";
import { MdOutlinePublish } from "react-icons/md";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { getUserDateTime } from "../../../utils/utility";
import { Translation } from "../../../translation/translation";

const { confirm } = Modal;
const { Text } = Typography;

const BotKnowledgebaseTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const translation = Translation().botKnowledgeArea.botKnowledgebase.table;

  const botKnowledgebaseDataColumns = [
    {
      title: translation.timestamp.title,
      dataIndex: "created_at",
      key: "created_at",
      ellipsis: true,
      width: state.infoPanel?.["knowledgebase-test-info-panel"]?.visible
        ? 0
        : "20%",
      render: (_, record) => {
        return getUserDateTime(
          record.created_at,
          state.currentUser.user.timeZone,
          state.currentUser.user.dateFormat
        );
      },
      sorter: (a, b) => {
        let valueA = a.created_at == null ? "" : a.created_at;
        let valueB = b.created_at == null ? "" : b.created_at;
        return valueA.localeCompare(valueB);
      },
    },
    {
      title: translation.kbName.title,
      dataIndex: "kb_name",
      key: "kb_name",
      ellipsis: true,
      sorter: (a, b) => {
        let valueA = a.kb_name == null ? "" : a.kb_name;
        let valueB = b.kb_name == null ? "" : b.kb_name;
        return valueA.localeCompare(valueB);
      },
      render: (kb_name) => {
        return <Space>{kb_name ? kb_name : "-"}</Space>;
      },
      width: "22%",
    },
    {
      title: translation.version.title,
      dataIndex: "kb_version",
      key: "kb_version",
      ellipsis: false,
      sorter: (a, b) => {
        let valueA = a.kb_version == null ? "" : a.kb_version;
        let valueB = b.kb_version == null ? "" : b.kb_version;
        return valueA.localeCompare(valueB);
      },
      render: (kb_version) => {
        return <Space>{kb_version ? kb_version : "-"}</Space>;
      },
      width: "10%",
    },
    {
      title: translation.fileName.title,
      dataIndex: "file_name",
      key: "file_name",
      ellipsis: true,
      sorter: (a, b) => {
        let valueA = a.file_name == null ? "" : a.file_name;
        let valueB = b.file_name == null ? "" : b.file_name;
        return valueA.localeCompare(valueB);
      },
      render: (file_name) => {
        return (
          <Text ellipsis={true}>
            {file_name && file_name !== " " ? file_name : "-"}
          </Text>
        );
      },
      width: "28%",
    },
    {
      title: translation.status.title,
      dataIndex: "is_published",
      key: "is_published",
      ellipsis: false,
      filters: [
        {
          text: translation.status.filters.published,
          value: true,
        },
        {
          text: translation.status.filters.draft,
          value: false,
        },
      ],
      filteredValue:
        (state.filters.filteredInfo["knowledgebase-table"] &&
          state.filters.filteredInfo["knowledgebase-table"].is_published) ||
        null,
      onFilter: (value, record) => record.is_published === value,
      render: (is_published) => {
        return is_published ? (
          <Tag color="green">{translation.status.filters.published}</Tag>
        ) : (
          <Tag color="volcano">{translation.status.filters.draft}</Tag>
        );
      },
      width: "10%",
    },
  ];

  let botKnowledgebaseActionColumns = [
    {
      title: translation.action.title,
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "10%",
      onCell: () => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // This will avoid onRow being called
          },
        };
      },
      render: (_, record) => {
        return (
          <Space size="large">
            {!record.is_published ? (
              state.apiCallStatus?.["knowledgebase-" + record.kb_id + "-status"]
                ?.loading ? (
                <ComponentLoader
                  component={"knowledgebase-" + record.kb_id + "-status"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : state.currentUser.permission.BOT_KNOWLEDGEBASE_PUBLISH ? (
                <Tooltip title={translation.action.publish.infoText}>
                  <MdOutlinePublish
                    style={{ color: "#F05742", marginTop: 4 }}
                    size={19}
                    onClick={() => {
                      confirm({
                        title: translation.action.publish.title,
                        content: translation.action.publish.warningText,
                        onOk() {
                          dispatchMiddleware({
                            type: "CHANGE_BOT_KNOWLEDGEBASE_STATUS",
                            payload: {
                              component:
                                "knowledgebase-" + record.kb_id + "-status",
                              id: record.kb_id,
                              status: true,
                              botInAction:
                                state.currentAccount.account.bots.find(
                                  (bot) =>
                                    bot.bot_id === state.application.botInAction
                                ),
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={translation.noAccess} placement="topRight">
                  <MdOutlinePublish
                    style={{ color: "#C0C0C0", marginTop: 4 }}
                    size={19}
                  />
                </Tooltip>
              )
            ) : state.apiCallStatus?.[
                "knowledgebase-" + record.kb_id + "-status"
              ]?.loading ? (
              <ComponentLoader
                component={"knowledgebase-" + record.kb_id + "-status"}
                loadingMessage=""
                errorMessage=""
              />
            ) : state.currentUser.permission.BOT_KNOWLEDGEBASE_UNPUBLISH ? (
              <Tooltip title={translation.action.unpublish.infoText}>
                <IoArrowUndoOutline
                  style={{ color: "#F05742", marginTop: 4 }}
                  size={19}
                  onClick={() => {
                    confirm({
                      title: translation.action.unpublish.title,
                      content: translation.action.unpublish.warningText,
                      onOk() {
                        dispatchMiddleware({
                          type: "CHANGE_BOT_KNOWLEDGEBASE_STATUS",
                          payload: {
                            component:
                              "knowledgebase-" + record.kb_id + "-status",
                            id: record.kb_id,
                            status: false,
                            botInAction: state.currentAccount.account.bots.find(
                              (bot) =>
                                bot.bot_id === state.application.botInAction
                            ),
                          },
                        });
                      },
                      onCancel() {},
                    });
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={translation.noAccess} placement="topRight">
                <IoArrowUndoOutline
                  style={{ color: "#C0C0C0", marginTop: 4 }}
                  size={19}
                />
              </Tooltip>
            )}

            {state.apiCallStatus?.["knowledgebase-" + record.kb_id + "-delete"]
              ?.loading ? (
              <ComponentLoader
                component={"knowledgebase-" + record.kb_id + "-delete"}
                loadingMessage=""
                errorMessage=""
              />
            ) : state.currentUser.permission.BOT_KNOWLEDGEBASE_DELETE ? (
              <DeleteOutlined
                style={{ color: "#F05742" }}
                onClick={() => {
                  dispatchReducer({
                    type: "RD_SET_ACTION_ROW_KEY",
                    payload: {
                      component: "knowledgebase-table",
                      actionRowKey: record.kb_id,
                    },
                  });
                  confirm({
                    title: translation.action.delete.title,
                    content: translation.action.delete.warningText,
                    onOk() {
                      dispatchMiddleware({
                        type: "DELETE_KNOWLEDGEBASE",
                        payload: {
                          component:
                            "knowledgebase-" + record.kb_id + "-delete",
                          actionRowKey: record.kb_id,
                          botInAction: state.currentAccount.account.bots.find(
                            (bot) =>
                              bot.bot_id === state.application.botInAction
                          ),
                        },
                      });
                    },
                    onCancel() {},
                  });
                }}
              />
            ) : (
              <Tooltip title={translation.noAccess} placement="topRight">
                <DeleteOutlined style={{ color: "#C0C0C0" }} />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return { botKnowledgebaseDataColumns, botKnowledgebaseActionColumns };
};

export default BotKnowledgebaseTableColumns;
