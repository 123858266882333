import { Col, Row } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import ActivityLogsHeader from "./ActivityLogsHeader";
import ActivityLogsTable from "./ActivityLogsTable";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";

const ActivityLogs = () => {
  const [, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "LOAD_ACTIVITY_LOG_DATA",
      payload: { component: "activity-log-table" },
    });
  });

  return (
    <>
      {!undefined ? (
        <>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <ActivityLogsHeader />
            </Col>
            <Col span={24}>
              <ActivityLogsTable />
            </Col>
          </Row>
        </>
      ) : (
        <FullPageLoader
          component="activity-log-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default ActivityLogs;
