import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useWebhooksMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////**************** WEBHOOK LIST *************////
      ////*******************************************////

      //Load webhooks list
      case "MWD_LOAD_WEBHOOKS_LIST":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [apiEndPoints.WEBHOOKS],
          options: {
            method: "GET",
          },
          onSuccess: ([webhookResponse]) => {
            dispatchReducer({
              type: "RD_SET_WEBHOOKS",
              payload: {
                webhook: webhookResponse.data,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Webhook Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_LOAD_RESPONSE_VALUE_PROPERTIES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [apiEndPoints.RESPONSE_VALUE_BY_PROPERTY_NAME],
          options: {
            method: "GET",
          },
          onSuccess: ([response]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSE_VALUE_BY_PROPERTY_NAME",
              payload: {
                responseValueByPropertyName: response,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Responses Value By Property Name Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Add / Edit webhookS
      case "MWD_ADD_EDIT_WEBHOOKS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            ...(state.form["webhooks-form"].mode === "add"
              ? [apiEndPoints.WEBHOOKS]
              : [
                  apiEndPoints.WEBHOOKS.concat(
                    "?_id=",
                    state.filters.actionRowKey["webhooks-table"]
                  ),
                ]),
          ],
          options: {
            method: state.form["webhooks-form"].mode === "add" ? "POST" : "PUT",
            body: action.payload.webhook,
          },
          onSuccess: ([response]) => {
            if (state.form["webhooks-form"].mode === "add") {
              dispatchReducer({
                type: "RD_CREATE_WEBHOOKS",
                payload: {
                  webhook: response,
                },
              });
              openNotificationWithIcon(
                "success",
                "Webhook created successfully"
              );
              appInsights.trackEvent({
                name: "Webhook Created",
                properties: {
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                  module: action.payload.module,
                },
              });
            } else if (state.form["webhooks-form"].mode === "edit") {
              dispatchReducer({
                type: "RD_EDIT_WEBHOOKS",
                payload: {
                  webhook: response,
                },
              });
              openNotificationWithIcon(
                "success",
                "Webhook modified successfully"
              );
            }
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "webhooks-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "webhooks-form",
                mode: "",
                title: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "webhooks-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add/Edit Webhook Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete webhook
      case "MWD_DELETE_WEBHOOK":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.WEBHOOKS.concat("?_id=", action.payload.actionRowKey),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_DELETE_WEBHOOK",
              payload: {
                actionRowKey: action.payload.actionRowKey,
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "webhooks-table" },
            });
            openNotificationWithIcon("success", "Webhook deleted successfully");
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete webhook Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
