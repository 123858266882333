import React, { useEffect } from "react";
import { Menu, Layout } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { FolderOpenOutlined } from "@ant-design/icons";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";

const { Sider } = Layout;

const CannedResponsesMenu = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const theme = "light";

  useEffect(() => {
    if (
      state.cannedResponses?.cannedResponsesFolderList &&
      state.cannedResponses?.cannedResponsesFolderList.length > 0
    ) {
      dispatchReducer({
        type: "RD_SET_CURRENT_CANNED_RESPONSE_FOLDER",
        payload: {
          currentCannedResponseFolder:
            state.cannedResponses?.cannedResponsesFolderList[0]._id,
        },
      });
    }
  }, [state.cannedResponses?.cannedResponsesFolderList]);

  const items = state.cannedResponses?.cannedResponsesFolderList
    ?.sort((a, b) => a._id.localeCompare(b._id))
    ?.map(({ _id, count }) => ({
      key: _id,
      label: (
        <div
          onClick={() => {
            dispatchReducer({
              type: "RD_SET_CURRENT_CANNED_RESPONSE_FOLDER",
              payload: {
                currentCannedResponseFolder: _id,
              },
            });
          }}
        >
          {_id} ({count})
        </div>
      ),
      icon: (
        <FolderOpenOutlined
          style={{
            fontSize: 18,
            margin: "0px 5px 0px 5px",
          }}
        />
      ),
    }));

  return (
    <>
      {state.cannedResponses.cannedResponsesFolderList ? (
        <Sider
          collapsed={false}
          theme={theme}
          style={{ borderRight: "1px solid #f0f2f5" }}
        >
          <Menu
            mode="inline"
            theme={theme}
            items={items}
            style={{ padding: "15px 0 0 0" }}
            selectedKeys={[state.cannedResponses?.currentCannedResponseFolder]}
            defaultSelectedKeys={[
              state.cannedResponses?.cannedResponsesFolderList[0]?._id,
            ]}
          />
        </Sider>
      ) : (
        <FullPageLoader
          component="canned-responses-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default CannedResponsesMenu;
