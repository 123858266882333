import { Pie } from "@ant-design/plots";
import { Typography, Row, Col, Tooltip, Empty } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";

const { Text } = Typography;

const ResponsesByActionChart = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchMiddleware({
        type: "MWD_LOAD_ANALYTICS_RESPONSES_BY_ACTION_DATA",
        payload: {
          component: "analytics-responses-by-action-chart",
          analyticsFilterParams: {
            dateFilter: state.analytics.filterByDateForCharts,
            channelFilter: state.analytics.filterByChannelForCharts,
          },
        },
      });
    },
    {
      date: state.analytics.filterByDateForCharts,
      channel: state.analytics.filterByChannelForCharts,
      botInAction: state.application.botInAction,
    }
  );

  const color = [
    "#2498D1",
    "#BBBDE6",
    "#4045B2",
    "#21A97A",
    "#FF745A",
    "#007E99",
    "#FFA8A8",
    "#2391FF",
    "#FFC328",
    "#9DF5CA",
    "#42C090",
    "#61DDAA",
    "#19A576",
    "#008A5D",
    "#006F45",
    "#00562F",
    "#003E19",
    "#002800",
  ];

  const config = {
    color: color,
    appendPadding: 10,
    data: state.analytics.responsesCharts.responsesByAction,
    angleField: "count",
    colorField: "_id",
    innerRadius: 0.5,
    label: {
      type: "inner",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      {state.analytics.responsesCharts.responsesByAction ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> Responses By Action Point</Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This is a statistic of what the client acquired and is captured as an action point from their conversation in counts."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.responsesCharts.responsesByAction.length ? (
            <Pie {...config} style={{ height: "200px" }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-responses-by-action-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ResponsesByActionChart;
