import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useQuestionsAndAnswersMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////***************************************************////
      ////**************** QUESTIONS AND ANSWER *************////
      ////***************************************************////
      //Load QUESTIONS AND ANSWER data
      case "MWD_LOAD_QUESTIONS_AND_ANSWER_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.QUESTIONS_AND_ANSWER.concat(
              "?botid=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
          },
          onSuccess: ([qna]) => {
            dispatchReducer({
              type: "SET_QUESTIONS_AND_ANSWERS",
              qna,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Questions and Answer Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Add QUESTIONS AND ANSWER
      case "MWD_ADD_QUESTIONS_AND_ANSWER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.QUESTIONS_AND_ANSWER.concat(
              "?botid=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
            body: [action.payload.qna],
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_ADD_QUESTIONS_AND_ANSWER",
              payload: { qna: action.payload.qna },
            });
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "questions-and-answer-form-panel",
              },
            });
            openNotificationWithIcon(
              "success",
              "Questions and answer added successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add Questions and Answer Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Update QUESTIONS AND ANSWER
      case "MWD_UPDATE_QUESTIONS_AND_ANSWER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.QUESTIONS_AND_ANSWER.concat(
              "?botid=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
            body: [action.payload.qna],
          },

          onSuccess: () => {
            dispatchReducer({
              type: "RD_UPDATE_QUESTIONS_AND_ANSWER",
              payload: { id: action.payload.qna.id, qna: action.payload.qna },
            });
            openNotificationWithIcon(
              "success",
              "Questions and answer updated successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Questions and Answer Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Delete QUESTIONS AND ANSWER
      case "MWD_DELETE_QUESTIONS_AND_ANSWER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.QUESTIONS_AND_ANSWER.concat(
              "?botid=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            header: {
              clientID: state.currentAccount.account.client_id,
              userID: state.currentUser.user.userID,
            },
            body: [action.payload],
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_DELETE_QUESTIONS_AND_ANSWER",
              payload: { id: action.payload.id },
            });
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "questions-and-answer-form-panel",
              },
            });
            openNotificationWithIcon(
              "success",
              "Questions and answer deleted successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Questions and Answer Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
    }
  };
};
