const liveAgentSettingsReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_LIVE_AGENT_SETTINGS":
      return {
        ...state,
        liveAgentSettings: {
          _id: action.payload.liveAgentResponse._id,
          ...action.payload.liveAgentResponse.liveAgent,
        },
      };

    case "RD_CLEAR_LIVE_AGENT_SETTINGS":
      return {
        ...state,
        liveAgentSettings: null,
      };

    case "RD_UPDATE_LIVE_AGENT_SETTINGS":
      return {
        ...state,
        liveAgentSettings: action.payload.liveAgentResponse,
      };

    case "RD_SET_INITIATE_CONVERSATION_TEMPLATE":
      return {
        ...state,
        liveAgentSettings: {
          ...state.liveAgentSettings,
          ...{
            initiateConversationTemplate:
              action.payload.initiateConversationTemplate,
          },
        },
      };

    case "RD_SET_REQUEST_FEEDBACK_TEMPLATE":
      return {
        ...state,
        liveAgentSettings: {
          ...state.liveAgentSettings,
          ...{
            requestFeedbackTemplate: action.payload.requestFeedbackTemplate,
          },
        },
      };

    case "RD_SET_LIVE_AGENT_PROFANITY":
      return {
        ...state,
        liveAgentSettings: {
          ...state.liveAgentSettings,
          ...{
            liveAgentProfanity: action.payload.liveAgentProfanity,
          },
        },
      };

    default:
      return state;
  }
};

export default liveAgentSettingsReducer;
