import { Bar } from "@ant-design/plots";
import { Typography, Tooltip, Row, Col, Empty } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const ContactCountByCountry = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_ANALYTICS_CONTACT_COUNT_BY_COUNTRY",
      component: "analytics-contact-count-by-Country",
    });
  });

  const color = [
    "#025DF4",
    "#DB6BCF",
    "#2498D1",
    "#BBBDE6",
    "#4045B2",
    "#21A97A",
    "#FF745A",
    "#007E99",
    "#FFA8A8",
    "#2391FF",
    "#FFC328",
    "#A0DC2C",
    "#946DFF",
    "#626681",
    "#EB4185",
    "#CD8150",
    "#36BCCB",
    "#327039",
    "#803488",
    "#83BC99",
  ];

  const config = {
    color: color,
    data: state.analytics.contactCharts.contactByCountry,
    xField: "count",
    yField: "_id",
    seriesField: "_id",
    legend: {
      position: "right",
    },
  };

  return (
    <>
      {state.analytics.contactCharts.contactByCountry ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> Contacts by country</Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This statistic shows the count of contact by country."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.contactCharts.contactByCountry.length ? (
            <Bar {...config} style={{ height: "200px" }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-contact-count-by-Country"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ContactCountByCountry;
