import { useConnectApi } from "../connect/connectApi";
import openNotificationWithIcon from "../utils/notification";
import { apiEndPoints } from "../constants/enums";
import { openErrorNotificationWithDeveloperMessage } from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useContactManagerSettingsMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************* CONTACTS SETTINGS *************////
      ////*******************************************////

      // Update contact settings
      case "UPDATE_CONTACT_SETTINGS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [apiEndPoints.ENUMS],
          options: {
            method: "POST",
            body: action.payload.contactSettings,
          },
          onSuccess: ([enums]) => {
            dispatchReducer({
              type: "RD_SET_ENUMS",
              payload: {
                enums: enums.data,
              },
            });

            dispatchReducer({
              type: "SET_CONTACT_COLUMNS",
              payload: {
                tableColumns: action.payload.contactSettings.filter(
                  (contactProperty) =>
                    !contactProperty.key.includes("contactFilter")
                ),
              },
            });

            dispatchReducer({
              type: "SET_CONTACT_FILTER",
              payload: {
                contactFilter: action.payload.contactSettings.find(
                  (contactProperty) => contactProperty.key === "contactFilter"
                ),
              },
            });

            openNotificationWithIcon("success", action.payload.successMessage);

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Contact Settings Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Delete contact property
      case "DELETE_CONTACT_PROPERTY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.ENUMS.concat(
              "?_id=" + action.payload.contactProperty._id + "&module=CONTACTS"
            ),
          ],
          options: {
            method: "DELETE",
            body: action.payload.contactProperty,
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_REMOVE_CONTACT_PROPERTY",
              payload: {
                contactProperty: action.payload.contactProperty,
              },
            });

            openNotificationWithIcon("success", "Contact Property Deleted");

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Contact Property Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
