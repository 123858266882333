import { useNavigate } from "react-router-dom";
import { Row, Col, Tabs, Space } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import LiveAgentForm from "./LiveAgentForm";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { MdSupportAgent } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AccessDenied from "../../../shared-components/AccessDenied";
import { wabaProvider } from "../../../constants/enums";

const LiveAgent = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const selectedBotTemplates = state.messageTemplates.templates?.find(
    (template) => template.botId === state.application.botInAction
  );

  useConnectMiddleware(
    () => {
      state.liveAgentSettings &&
        dispatchReducer({
          type: "RD_CLEAR_LIVE_AGENT_SETTINGS",
        });

      state.currentUser.permission.ACCOUNT_LIVE_AGENT_VIEW &&
        dispatchMiddleware({
          type: "MWD_LOAD_LIVE_AGENT_SETTINGS",
          payload: {
            component: "live-agent-setting",
          },
        });
      // Load message templates
      if (
        botInAction?.bot_channel_info?.WHATSAPP?.waba_integrated_onboarding &&
        !selectedBotTemplates
      ) {
        dispatchMiddleware({
          type: "MWD_LOAD_MESSAGE_TEMPLATE",
          payload: {
            wabaApikey: botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey,
            botId: state.application.botInAction,
            wabaProvider:
              wabaProvider[
                botInAction.bot_channel_info?.WHATSAPP?.waba_provider
              ]?.providerReference.providerName,
            component: "message-templates-table",
          },
        });
      }
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {!state.currentUser.permission.ACCOUNT_LIVE_AGENT_VIEW ? (
        <AccessDenied />
      ) : (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <MdSupportAgent size={18} style={{ margin: -4 }} />
                  Live Agent
                </Space>
              ),
              children: state.liveAgentSettings ? (
                <Row>
                  <Col span={24}>
                    <LiveAgentForm />
                  </Col>
                </Row>
              ) : (
                <FullPageLoader
                  component="live-agent-setting"
                  loadingMessage="Loading... Please wait..."
                />
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["live-agent-setting"]?.loading && (
              <TabLoader
                component="live-agent-setting"
                loadingMessage="Synchronizing live agent..."
                errorMessage="Error synchronizing live agent"
              />
            )
          }
        />
      )}
    </>
  );
};

export default LiveAgent;
