import { Pie } from "@ant-design/plots";
import { Typography, Row, Col, Tooltip } from "antd";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const TicketsByAssigneeChart = () => {
  // Hardcoded data for tickets
  const ticketData = [
    { assignedBy: "ashokm@inextlabs.com", value: 2 },
    { assignedBy: "nandhakumarv@inextlabs.com", value: 2 },
    { assignedBy: "Unassigned", value: 6 },
  ];

  const color = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
    "#FF5722",
    "#FF9800",
  ];

  const config = {
    color: color,
    appendPadding: 10,
    angleField: "value", // Use 'value' field for angles
    colorField: "assignedBy",
    innerRadius: 0.5,
    label: {
      type: "inner",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      <Row>
        <Col span={23}>
          <Text strong>Tickets By Assignee</Text>
        </Col>
        <Col span={1}>
          <Tooltip
            placement="topRight"
            title="This is a statistical view of how many tickets are assigned to each agent."
          >
            <IoIosInformationCircleOutline size="1.5em" />
          </Tooltip>
        </Col>
      </Row>
      <Pie {...config} data={ticketData} style={{ height: "200px" }} />
    </>
  );
};

export default TicketsByAssigneeChart;
