import { useState, useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Row,
  Col,
  Button,
  Space,
  Divider,
  Checkbox,
  Tooltip,
  Switch,
  Popover,
  List,
  Typography,
  Tag,
  Card,
} from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { assignTags, modules, wabaProvider } from "../../../constants/enums";
import {
  defaultActionValues,
  defaultResponsesProperties,
} from "../../../constants/enums";
import {
  CloseOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formatText } from "../../../utils/utility";
import { MdInfoOutline } from "react-icons/md";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { DeleteOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;
const { Text, Title, Link } = Typography;

const CheckboxGroup = Checkbox.Group;

const WebhooksTrigger = ({
  onFinish,
  setCurrentPosition,
  currentPosition,
  webhooksData,
  triggerWebhooksData,
}) => {
  const navigate = useNavigate();

  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();
  const [checkedValues, setCheckedValues] = useState([]);
  const [standardMapping, setStandardMapping] = useState(false);
  const [
    isShowMapResponsesPropertiesPanel,
    setIsShowMapResponsesPropertiesPanel,
  ] = useState(false);
  const [responseProperty, setResponseProperty] = useState();
  const [bodyMapping, setBodyMapping] = useState(null);

  const [template, setTemplate] = useState();
  const [templateBody, setTemplateBody] = useState(null);
  const [bodyPlaceHolders, setBodyPlaceHolders] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [whatsAppNotificationTemplates, setWhatsAppNotificationTemplates] =
    useState();

  let botInAction = state.currentAccount.account.bots.find(
    (bot) =>
      bot.bot_id === (webhooksData?.bot_id ?? state.application.botInAction)
  );

  useEffect(() => {
    if (webhooksData?.whatsappNotification) {
      let whatsAppNotificationTemplates =
        (state.messageTemplates.templates?.length > 0 &&
          state.messageTemplates.templates?.find(
            ({ botId }) =>
              botId === (webhooksData?.bot_id ?? state.application.botInAction)
          )?.waba_templates) ||
        [];

      setWhatsAppNotificationTemplates(whatsAppNotificationTemplates);
    }
  }, [state.messageTemplates.templates]);

  useConnectMiddleware(() => {
    dispatchMiddleware({
      type: "MWD_LOAD_RESPONSE_VALUE_PROPERTIES",
      payload: { component: "webhooks-table" },
    });
    if (webhooksData?.whatsappNotification) {
      dispatchMiddleware({
        type: "MWD_LOAD_MESSAGE_TEMPLATE",
        payload: {
          wabaApikey: botInAction?.bot_channel_info?.WHATSAPP?.waba_apikey,
          botId: botInAction?.bot_id,
          wabaProvider:
            wabaProvider[botInAction.bot_channel_info?.WHATSAPP?.waba_provider]
              ?.providerReference.providerName,
          component: "message-templates-table",
        },
      });
    }
  });

  useEffect(() => {
    const recordToBeEdited =
      state.form["webhooks-form"].mode === "edit"
        ? state.webhooks.webhooksList.find(
            (webhook) =>
              webhook._id === state.filters.actionRowKey["webhooks-table"]
          )
        : null;
    if (template) {
      const selectedTemplateBody = template.components.find(
        (component) => component.type === "BODY"
      );

      setTemplateBody(selectedTemplateBody);

      if (recordToBeEdited) {
        setPreview(recordToBeEdited.preview);
      } else {
        setPreview(selectedTemplateBody.text);
      }
      setBodyPlaceHolders(selectedTemplateBody.text.match(/[^{}]+(?=})/g));
      form.setFieldsValue({
        recipients: [""],
      });
    }
  }, [template]);

  if (state.form["webhooks-form"].mode === "edit") {
    const recordToBeEdited = state.webhooks.webhooksList.find(
      (webhook) => webhook._id === state.filters.actionRowKey["webhooks-table"]
    );

    form.setFieldsValue({
      module: recordToBeEdited?.triggerCriteria?.module,
      criteriaValue: recordToBeEdited?.triggerCriteria?.criteriaValue,
      postData: JSON.stringify(recordToBeEdited?.postData, null, 2),
    });
  }

  const validateJSON = (rule, value, callback) => {
    if (value) {
      // Add a check to show validation error only when the textbox contains a value
      try {
        const postData = JSON.parse(value);
        if (typeof postData === "object" && postData !== null) {
          callback();
        } else {
          callback("Invalid JSON object");
        }
      } catch (error) {
        callback("Invalid JSON object");
      }
    } else {
      callback(); // Call the callback with no arguments to indicate a successful validation when the textbox is empty
    }
  };

  useEffect(() => {
    if (state.form["webhooks-form"].mode === "edit") {
      const recordToBeEdited = state.webhooks.webhooksList.find(
        (webhook) =>
          webhook._id === state.filters.actionRowKey["webhooks-table"]
      );
      if (recordToBeEdited) {
        form.setFieldsValue({
          autoAssignTags: recordToBeEdited.autoAssignTags || [],
          content: recordToBeEdited?.email?.content,
          recipientEmail: recordToBeEdited?.email?.recipientEmail,
          subject: recordToBeEdited?.email?.subject,
          recipients: recordToBeEdited.recipients,
          template: recordToBeEdited.postData?.template?.name,
        });
        setPreview(recordToBeEdited.preview);
      }
    }
  }, []);

  let placeholder = {
    key: "{{Value}}",
  };

  const handleChange = (checkedValues) => {
    setCheckedValues(checkedValues);
  };

  useEffect(() => {
    const recordToBeEdited =
      state.form["webhooks-form"].mode === "edit"
        ? state.webhooks.webhooksList.find(
            (webhook) =>
              webhook._id === state.filters.actionRowKey["webhooks-table"]
          )
        : null;
    if (recordToBeEdited) {
      if (webhooksData.createContactFromResponse) {
        form.setFieldsValue({
          module: "Response",
          criteriaValue: "All",
        });
      } else if (webhooksData?.integrationPlatform === "WOOCOMMERCE") {
        form.setFieldsValue({
          module: "Response",
          criteriaValue: "Order",
        });
      } else if (webhooksData?.whatsappNotification) {
        form.setFieldsValue({
          recipients: recordToBeEdited?.recipients,
        });
        setIsCategorySelected(true);
      } else {
        form.setFieldValue({
          module: recordToBeEdited?.triggerCriteria?.module,
          criteriaValue: recordToBeEdited?.triggerCriteria?.criteriaValue,
          postData: JSON.stringify(recordToBeEdited?.postData, null, 2),
          autoAssignTags: recordToBeEdited?.autoAssignTags || [],
        });

        if (!recordToBeEdited) {
          form.resetFields(["module", "criteriaValue"]);
        }
      }
    }
  }, []);

  let responseProperties = state.responseSettings.responseProperties || [];

  useEffect(() => {
    if (responseProperties[0]?.allproperties?.length > 0) {
      // Define the excluded properties outside of the component
      const EXCLUDED_PROPERTIES = [
        "_id",
        "clientId",
        "conversationId",
        "lalamove_quotation_id",
        "messageSid",
        "referenceId",
        "version",
      ];

      // Function to generate modified response property
      const generateModifiedProperty = (propertyName, name) => {
        return (
          !EXCLUDED_PROPERTIES.includes(propertyName) && {
            value: `{{${propertyName}}}`,
            name:
              name ||
              propertyName.charAt(0).toUpperCase() + propertyName.slice(1),
          }
        );
      };
      // Use the excluded properties in your component
      let filteredProperties = responseProperties[0]?.allproperties?.map(
        (propertyName) => {
          let modifiedResponseProperty;
          switch (propertyName) {
            case "category":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Category"
              );
              break;
            case "channel":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Channel"
              );
              break;
            case "deliveryAddress":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Delivery Address"
              );
              break;
            case "deliveryDate":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Delivery Date"
              );
              break;
            case "deliveryType":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Delivery Type"
              );
              break;
            case "delivery_services":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Delivery Service"
              );
              break;
            case "firstName":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "First Name"
              );
              break;
            case "lastName":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Last Name"
              );
              break;
            case "paymentMethod":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Payment Method"
              );
              break;
            case "responseDate":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Date"
              );
              break;
            case "shippingAddress":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Shipping Address"
              );
              break;
            case "status":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Status"
              );
              break;
            case "tags":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Tags"
              );
              break;
            case "userEmail":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Email"
              );
              break;
            case "userPhoneNumber":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Phone Number"
              );
              break;
            case "userProfileName":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Profile Name"
              );
              break;
            case "order":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Order Items"
              );
              break;
            case "price":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Total Amount"
              );
              break;
            case "partitionKey":
              modifiedResponseProperty = generateModifiedProperty(
                propertyName,
                "Category"
              );
              break;
            default:
              modifiedResponseProperty = generateModifiedProperty(propertyName);
              break;
          }
          return modifiedResponseProperty;
        }
      );

      filteredProperties = filteredProperties?.filter(
        (property) => property?.name
      );

      setResponseProperty(filteredProperties);
    }
  }, [state.responseSettings.responseProperties]);

  useEffect(() => {
    const recordToBeEdited =
      state.form["webhooks-form"].mode === "edit"
        ? state.webhooks.webhooksList.find(
            (webhook) =>
              webhook._id === state.filters.actionRowKey["webhooks-table"]
          )
        : null;
    if (recordToBeEdited) {
      dispatchMiddleware({
        type: "MWD_LOAD_RESPONSE_PROPERTIES",
        payload: {
          component: "client-responses-properties",
          currentResponseCategory: recordToBeEdited?.category,
        },
      });
      let selectedTemplate = whatsAppNotificationTemplates?.find(
        (template) =>
          template.name === recordToBeEdited.postData?.template?.name
      );
      form.setFieldsValue({
        recipients: recordToBeEdited.recipients,
        template: recordToBeEdited.preview?.template?.name,
      });

      setPreview(recordToBeEdited.preview);
      setTemplate(selectedTemplate);
    }
  }, [whatsAppNotificationTemplates]);

  // Custom validator function to check for valid email addresses
  const validateEmailList = (_, value) => {
    if (!value) return Promise.resolve();
    const emailList = value.split(",").map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    for (const email of emailList) {
      if (!emailRegex.test(email)) {
        return Promise.reject(
          "Please enter valid email addresses separated by commas"
        );
      }
    }
    return Promise.resolve();
  };

  const handleTemplateChange = (Template) => {
    let selectedTemplate = whatsAppNotificationTemplates?.find(
      (template) => template.name === Template
    );
    form.setFieldsValue({
      template: Template,
    });
    setTemplate(selectedTemplate);
  };

  const handleReplaceBodyPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];

    if (column.match(/[^{}]+(?=})/g)) {
      setBodyMapping({
        ...bodyMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)[0] + "}",
      });
    } else {
      setBodyMapping({ ...bodyMapping, [placeHolder]: column });
    }
  };

  useEffect(() => {
    if (bodyMapping && bodyPlaceHolders) {
      let previewMessage = templateBody.text;
      bodyPlaceHolders.forEach((bodyPlaceHolder) => {
        if (bodyMapping[bodyPlaceHolder]) {
          previewMessage = previewMessage.replace(
            "{{" + bodyPlaceHolder + "}}",
            bodyMapping[bodyPlaceHolder]
          );
        }
      });

      setPreview(previewMessage);
    }
  }, [bodyMapping, bodyPlaceHolders]);

  const handleFinish = (triggerData) => {
    let triggerWebhookData = triggerData;

    if (webhooksData?.whatsappNotification) {
      const parameters = Object.values(bodyMapping)?.map((placeholder) => ({
        type: "text",
        text: `{${placeholder}}`,
      }));

      const components = [
        {
          type: "BODY",
          parameters,
        },
      ];

      const postData = {
        template: {
          namespace: template?.namespace,
          name: template?.name,
          language: {
            policy: "deterministic",
            code: template?.language,
          },
          isCarousel: false,
          components,
        },
      };

      triggerWebhookData = {
        ...triggerData,
        postData: JSON.stringify(postData),
        previewData: preview,
      };
    }

    onFinish(triggerWebhookData);
  };
  useEffect(() => {
    const recordToBeEdited =
      state.form["webhooks-form"].mode === "edit"
        ? state.webhooks.webhooksList.find(
            (webhook) =>
              webhook._id === state.filters.actionRowKey["webhooks-table"]
          )
        : null;
    if (recordToBeEdited) {
      form.setFieldsValue({
        recipients: recordToBeEdited.recipients,
      });
    }
  }, [template]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(triggerData) => handleFinish(triggerData)}
      initialValues={triggerWebhooksData}
      autoComplete="off"
      style={{ width: "100%" }}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Space
            strong
            style={{
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Trigger Condition
          </Space>
        </Col>

        {((webhooksData.external === true &&
          webhooksData?.integrationPlatform !== "WOOCOMMERCE") ||
          webhooksData?.whatsappNotification) && (
          <Col span={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              label={
                <Text>
                  Module
                  <Tooltip title="Select the module for which you want to create a webhook.">
                    <MdInfoOutline
                      color="#727272"
                      style={{ fontSize: "16px", margin: "0px 0px -3px 2px" }}
                    />
                  </Tooltip>
                </Text>
              }
              name="module"
              rules={[
                {
                  required: true,
                  message: "Select module",
                },
              ]}
            >
              <Select
                placeholder="Select module"
                disabled={webhooksData.createContactFromResponse === true}
              >
                {modules.map((module) => {
                  return <Option value={module.value}>{module.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Divider style={{ margin: "0px" }} />

        {((webhooksData.external === true &&
          webhooksData?.integrationPlatform !== "WOOCOMMERCE") ||
          webhooksData?.whatsappNotification) && (
          <Col span={24}>
            <Form.Item
              style={{ marginBottom: "0px" }}
              label={
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Category
                  <Tooltip title="Which category would you like to trigger webhook?">
                    <MdInfoOutline
                      color="#727272"
                      style={{ fontSize: "16px", margin: "0px 0px -2px 2px" }}
                    />
                  </Tooltip>
                </Text>
              }
              name="criteriaValue"
              rules={[
                {
                  required: true,
                  message: "Select response category",
                },
              ]}
            >
              <Select
                onChange={(category) => {
                  dispatchMiddleware({
                    type: "MWD_LOAD_RESPONSE_PROPERTIES",
                    payload: {
                      component: "client-responses-properties",
                      currentResponseCategory: category,
                    },
                  });
                  setIsCategorySelected(true);
                }}
                style={{ width: "100%" }}
                placeholder="Select responses category"
                disabled={webhooksData.createContactFromResponse === true}
              >
                {state.webhooks.responseValueByPropertyName?.map((property) => {
                  return <Option value={property}>{property}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Divider style={{ margin: "0px" }} />

        {(webhooksData.external === true ||
          webhooksData.createContactFromResponse === true) && (
          <>
            <Col span="24">
              <Form.Item
                name="postData"
                label={
                  <>
                    <Space>
                      {webhooksData.createContactFromResponse === true
                        ? "Custom Mapping"
                        : "Action"}
                    </Space>
                    <Space style={{ marginLeft: "350px" }}>
                      {(webhooksData.createContactFromResponse === true ||
                        webhooksData.external === true) && (
                        <Row>
                          <Col span={19}>Show Standard Mapping</Col>
                          <Col span={3}>
                            <Tooltip title="Standard mapping cannot be edit">
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                onChange={(checked) => {
                                  setStandardMapping(checked);
                                }}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                    </Space>
                  </>
                }
                rules={[
                  {
                    validator: validateJSON,
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 10 }}
                  placeholder={JSON.stringify(placeholder, null, 2)}
                />
              </Form.Item>
            </Col>
          </>
        )}

        {standardMapping && (
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Input.TextArea
              defaultValue={JSON.stringify(
                webhooksData?.integrationPlatform === "WOOCOMMERCE"
                  ? defaultActionValues.responseWithOrderCategory
                  : defaultActionValues.contact,
                null,
                2
              )}
              rows={9}
              readOnly
              style={{ resize: "none" }}
            />
          </Col>
        )}

        {webhooksData.createContactFromResponse === true && (
          <Row>
            <Col span={24}>
              <Form.Item label="Assign Tags" name="autoAssignTags">
                <CheckboxGroup
                  options={assignTags}
                  value={checkedValues?.map((value) => value.toLowerCase())}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {webhooksData.autoEmailTrigger === true && (
          <>
            <Col span={24}>
              <Form.Item
                label={
                  <Space>
                    Recipient Email
                    <Tooltip
                      placement="right"
                      title="Please ensure that you input valid email addresses, and you can separate multiple recipients using commas for proper formatting."
                    >
                      <ExclamationCircleOutlined
                        style={{
                          color: "#F05742",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </Space>
                }
                name="recipientEmail"
                rules={[
                  {
                    required: true,
                    message: "Recipient email is required.",
                  },
                  {
                    validator: validateEmailList,
                  },
                ]}
              >
                <Input placeholder="e.g., john.doe@gmail.com" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Please provide a subject for your email.",
                  },
                  {
                    max: 100,
                    message: "Subject should not exceed 100 characters",
                  },
                ]}
              >
                <Input
                  placeholder="Subject line for your email"
                  maxLength={100}
                  showCount
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <Space>
                    Content
                    <Popover
                      title={
                        <Title
                          level={5}
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          Map responses properties
                        </Title>
                      }
                      placement="topLeft"
                      trigger="click"
                      open={isShowMapResponsesPropertiesPanel}
                      onOpenChange={(visible) => {
                        setIsShowMapResponsesPropertiesPanel(visible);
                      }}
                      content={
                        <List
                          dataSource={defaultResponsesProperties}
                          size="small"
                          renderItem={(property) => (
                            <List.Item
                              style={{
                                cursor: "pointer",
                                padding: "5px 0px",
                              }}
                              onClick={() => {
                                const cursorPosition =
                                  form.getFieldValue("cursorPosition");
                                const content = form.getFieldValue("content");

                                if (!content) {
                                  form.setFieldsValue({
                                    content: property.value,
                                  });
                                } else {
                                  form.setFieldsValue({
                                    content:
                                      //Based on the cursor position, add the property value

                                      content?.slice(0, cursorPosition) +
                                      property.value +
                                      content?.slice(cursorPosition),
                                  });
                                }
                                setIsShowMapResponsesPropertiesPanel(false);
                              }}
                            >
                              <Text strong>{property.value}</Text> :{" "}
                              <Text>{property.key}</Text>
                            </List.Item>
                          )}
                        />
                      }
                    >
                      <Link
                        type="link"
                        onClick={() =>
                          setIsShowMapResponsesPropertiesPanel(true)
                        }
                        style={{ marginLeft: "378px" }}
                      >
                        Show Standard Mapping
                      </Link>
                    </Popover>
                  </Space>
                }
                name="content"
                rules={[
                  {
                    required: true,
                    message: "Content is required.",
                  },
                  {
                    max: 1024,
                    message: "Content should not exceed 500 characters",
                  },
                ]}
              >
                <TextArea
                  placeholder="Write your content here..."
                  showCount
                  maxLength={1024}
                  style={{ resize: "none" }}
                  autoSize={{ minRows: 4, maxRows: 10 }}
                  onKeyUp={(e) => {
                    // To get the cursor position
                    const { selectionStart } = e.target;
                    form.setFieldsValue({
                      cursorPosition: selectionStart,
                    });
                  }}
                  onMouseUp={(e) => {
                    // To get the cursor position
                    const { selectionStart } = e.target;
                    form.setFieldsValue({
                      cursorPosition: selectionStart,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </>
        )}

        {isCategorySelected && webhooksData?.whatsappNotification && (
          <Row style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Col span={24}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                label={
                  <Text>
                    Template
                    <Tooltip title="Which message template would you like to receive?">
                      <HiOutlineQuestionMarkCircle
                        size={16}
                        style={{
                          margin: "0px 5px -3px 3px",
                          color: "#727272",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Text>
                }
                name="template"
                rules={[
                  {
                    required: true,
                    message: "Please select the template",
                  },
                ]}
              >
                <Text style={{ fontSize: "12px", margin: "5px 0px" }}>
                  <b>Note:</b> Only text templates are supported for this
                  webhooks
                </Text>
                <Space
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    onChange={handleTemplateChange}
                    value={template?.name}
                    placeholder="Select the template"
                    style={{
                      border: "1px solid #d9d9d9",
                      width: "390px",
                      fontSize: "13px",
                    }}
                  >
                    {whatsAppNotificationTemplates?.map((template, index) => (
                      <Option key={index} value={template.name}>
                        {template.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    onClick={() => {
                      navigate("/knowledge-area/message-templates");
                      sessionStorage.setItem(
                        "selectedMenu",
                        "/knowledge-area/message-templates"
                      );
                    }}
                    type="primary"
                    size="middle"
                  >
                    New Template
                  </Button>
                </Space>
              </Form.Item>{" "}
            </Col>
          </Row>
        )}
        {template && <Divider style={{ margin: "0px" }} />}

        {template && (
          <Row>
            <Col span={24} style={{ padding: "0px 0px 10px 0px" }}>
              {" "}
              Mapping{" "}
              <Tooltip title="Map the placeholders shown below">
                <HiOutlineQuestionMarkCircle
                  size={16}
                  style={{
                    margin: "0px 5px -3px 0px",
                    color: "#727272",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
            <Col span={24}> Map values to the placeholders in the template</Col>
          </Row>
        )}
        {template && (
          <Row style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Col span={14}>
              <Card
                title="MESSAGE BODY"
                size="small"
                headStyle={{
                  backgroundColor: "#F4F7F9",
                  textAlign: "center",
                  marginRight: "20px",
                }}
              >
                <Col span={24}>
                  <Text>Map the values for the placeholders below</Text>
                </Col>
                {bodyPlaceHolders?.map((placeholder, index) => (
                  <Form.Item
                    key={index}
                    style={{ marginBottom: "0px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please map the placeholders",
                      },
                    ]}
                    name={placeholder}
                  >
                    <Select
                      onChange={(val) =>
                        handleReplaceBodyPlaceHolder(
                          placeholder + "~*!*$*~" + val
                        )
                      }
                      placeholder={"{{" + placeholder + "}}"}
                      style={{
                        border: "1px solid #d9d9d9",
                        width: "250px",
                        margin: " 5px 0px",
                      }}
                    >
                      {responseProperty?.map((propertyName, index) => (
                        <Option key={index} value={propertyName.value}>
                          {propertyName.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}
              </Card>
            </Col>
            <Col span={10}>
              <Row gutter={[0, 16]}>
                <Space
                  style={{
                    backgroundImage: "url(/images/whatsapp-bg.png)",
                    padding: 10,
                    borderRadius: 15,
                  }}
                >
                  <Row gutter={[0, 8]}>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <Tag color="green">MESSAGE PREVIEW</Tag>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col
                          span={22}
                          offset={1}
                          style={{
                            width: "100%",
                            background: "#FFFFFF",
                            padding: 15,
                            borderRadius: 15,
                            fontSize: 12,
                          }}
                        >
                          <Space direction="vertical">
                            {preview && <Space>{formatText(preview)}</Space>}
                          </Space>
                          <Space
                            style={{
                              color: "#909090",
                              justifyContent: "right",
                              fontSize: 10,
                              width: "100%",
                            }}
                          >
                            10:10 AM
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Space>
              </Row>
            </Col>
          </Row>
        )}
      </Row>
      {webhooksData?.whatsappNotification && template && (
        <Divider style={{ margin: "0px" }} />
      )}

      {webhooksData?.whatsappNotification && template && (
        <Row>
          <Col span={24}>
            <Form.List name="recipients" initialValue={[""]}>
              {(fields, { add, remove }) => (
                <Space
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "self-start",
                  }}
                >
                  {fields.map((field, index) => (
                    <Row style={{ display: "flex" }}>
                      <Form.Item
                        {...field}
                        key={index}
                        label={index === 0 && "Recipient Phone Number"}
                        name={field.name}
                        style={{
                          margin: 5,
                          display: "flex",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter valid mobile number",
                          },
                        ]}
                      >
                        <PhoneInput
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              add();
                            }
                          }}
                          className="phone-number-input-box"
                          placeholder="Enter the mobile number"
                        />
                      </Form.Item>

                      {index !== 0 ? (
                        <DeleteOutlined
                          style={{
                            color: "#F05742",
                            margin: "13px 0 0 10px",
                          }}
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Row>
                  ))}
                  <Text>
                    <b> Note:</b> A maximum of 5 numbers are allowed for
                    configuration.
                  </Text>
                </Space>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: "30px" }}>
        <Col span={12} style={{ bottom: 0 }}>
          <Form.Item>
            <Button
              onClick={() => {
                setCurrentPosition(currentPosition - 1);
              }}
            >
              Previous
            </Button>
          </Form.Item>
        </Col>
        <Col
          span={12}
          style={{ bottom: 0, display: "flex", justifyContent: "flex-end" }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default WebhooksTrigger;
