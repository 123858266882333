import React from "react";
import { URIs } from "../../../../constants/env";
import useConnectMiddleware from "../../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../../contexts/globalContext";
import {
  Row,
  Col,
  Typography,
  Layout,
  Divider,
  Collapse,
  Space,
  Card,
  List,
  Badge,
  Image,
  Button,
  Tooltip,
} from "antd";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiOutlineCreditCard } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { FullPageLoader } from "../../../../shared-components/LoadingIndicator";
import { countriesCurrency, channels } from "../../../../constants/enums";
import { Translation } from "../../../../translation/translation";
import AccessDenied from "../../../../shared-components/AccessDenied";
import BuyAddOnFormPanel from "./BuyAddOnFormPanel";

const { Title, Text, Paragraph, Link } = Typography;
const { Sider, Content } = Layout;

const AccountSubscription = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  // Get translated text
  const subscriptionSideMenu =
    Translation().accountSettings.billing.subscription.sideMenu;
  const subscriptionDetailsPanel =
    Translation().accountSettings.billing.subscription.subscriptionDetails;
  const subscriptionNotAvailable =
    Translation().accountSettings.billing.subscription.notAvailable;

  const subscriptionId =
    state.currentAccount.account.subscription_info?.subscription_ids?.length;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  useConnectMiddleware(
    () => {
      state.currentUser.permission.ACCOUNT_SUBSCRIPTION_VIEW &&
        subscriptionId &&
        dispatchMiddleware({
          type: "LOAD_SUBSCRIPTION_DATA",
          payload: {
            component: "subscription-list",
          },
        });

      if (botInAction.bot_channels.includes("WHATSAPP")) {
        // Reset the old whatsapp usage data
        dispatchReducer({
          type: "RD_CLEAR_WHATSAPP_USAGE_DATA",
        });

        dispatchMiddleware({
          type: "MWD_LOAD_WHATSAPP_USAGE_DATA",
          payload: {
            botId: botInAction.bot_id,
            clientId: botInAction?.bot_channel_info?.WHATSAPP?.waba_client_id,
            channelId:
              botInAction?.bot_channel_info?.WHATSAPP?.waba_channel_id?.[0],
            currentMonth: new Date().getMonth(),
            component: "whatsapp-usage-button",
          },
        });
      }
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  const subscriptionDetails = state.accountBilling.subscription || {};

  return state.currentUser.permission.ACCOUNT_SUBSCRIPTION_VIEW ? (
    subscriptionId ? (
      state.accountBilling.subscription ? (
        <Layout>
          <Sider
            style={{
              background: "#ffffff",
              marginRight: "1px solid #e8e8e8",
            }}
            width={350}
          >
            <Card size="small">
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Space direction="vertical" size={3}>
                    <Space>
                      <HiOutlineOfficeBuilding
                        size={19}
                        style={{ margin: -4 }}
                      />
                      <Title level={5}>
                        {subscriptionDetails?.companyName
                          ? `${subscriptionDetails.companyName
                              .charAt(0)
                              .toUpperCase()}${subscriptionDetails.companyName
                              .slice(1)
                              .toLowerCase()}`
                          : ""}
                      </Title>
                    </Space>
                  </Space>
                </Col>

                <Col span={24}>
                  <Collapse
                    defaultActiveKey={["1"]}
                    size="small"
                    expandIconPosition="end"
                  >
                    <Collapse.Panel
                      header={
                        <Space>
                          <BiUserCircle
                            size={19}
                            style={{ margin: -4, color: "#F05742" }}
                          />
                          <Text strong>
                            {subscriptionSideMenu.contactDetails.title}
                          </Text>
                        </Space>
                      }
                      key="1"
                    >
                      <Text>
                        {subscriptionDetails?.billingAddress?.attention}
                      </Text>
                      <br />
                      <Text>{subscriptionDetails?.phoneNumber}</Text>
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={
                        <Space>
                          <MdLocationPin
                            size={19}
                            style={{ margin: -4, color: "#F05742" }}
                          />
                          <Text strong>
                            {subscriptionSideMenu.billingDetails.title}
                          </Text>
                        </Space>
                      }
                      key="2"
                    >
                      <Text>
                        {(subscriptionDetails?.billingAddress.street || "") +
                          " " +
                          (subscriptionDetails?.billingAddress.city || "")}
                      </Text>
                      <br />
                      <Text>
                        {(subscriptionDetails?.billingAddress.state || "") +
                          " " +
                          (subscriptionDetails?.billingAddress.country || "") +
                          " " +
                          (" - " + subscriptionDetails?.billingAddress.zip ||
                            "")}
                      </Text>
                    </Collapse.Panel>

                    <Collapse.Panel
                      header={
                        <Space>
                          <AiOutlineCreditCard
                            size={19}
                            style={{ margin: -4, color: "#F05742" }}
                          />
                          <Text strong>
                            {subscriptionSideMenu.paymentMethod.title}
                          </Text>
                        </Space>
                      }
                      key="3"
                    >
                      <Row
                        gutter={[0, 12]}
                        style={{
                          background:
                            "linear-gradient(to right, #f5f5f5, #ddd)",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          padding: "10px",
                        }}
                      >
                        <Col span={19}>
                          <Text
                            style={{
                              color: "#333",
                              letterSpacing: "2px",
                              marginBottom: "8px",
                            }}
                            strong
                          >
                            **** **** ****{" "}
                            {subscriptionDetails.paymentMethod.lastFourDigits}
                          </Text>
                        </Col>

                        <Col span={5}>
                          <Text
                            style={{
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                            }}
                          >
                            {subscriptionDetails.paymentMethod.cardType}
                          </Text>
                        </Col>

                        <Col
                          span={12}
                          style={{
                            textAlign: "right",
                            color: "#333",
                            letterSpacing: "2px",
                          }}
                        >
                          <Text>
                            {subscriptionDetails.paymentMethod.expiryMonth}/
                            {subscriptionDetails.paymentMethod.expiryYear}
                          </Text>
                        </Col>
                      </Row>

                      <Space
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <Text>{subscriptionSideMenu.paymentMethod.title}:</Text>
                        <Text
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "1px",
                          }}
                          strong
                        >
                          {subscriptionDetails.paymentMethod.paymentGateway}
                        </Text>
                      </Space>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
            </Card>
          </Sider>

          <Layout>
            <Content style={{ paddingLeft: 3, background: "#ffffff" }}>
              <Row gutter={[10, 12]}>
                <Col span={24}>
                  <Card size="small">
                    <Row gutter={[0, 10]}>
                      <Col span={24}>
                        <Title level={4}>
                          {subscriptionDetailsPanel.title}
                        </Title>
                      </Col>

                      <Col span={24}>
                        <List
                          size="small"
                          dataSource={subscriptionDetails.subscriptions}
                          renderItem={(subscription, index) => (
                            <List.Item
                              key={index}
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                padding: "10px",
                                marginBottom: 7,
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <Text
                                    strong
                                    style={{
                                      fontSize: 17,
                                    }}
                                  >
                                    {subscription.planCode}
                                  </Text>
                                }
                                description={
                                  <Row gutter={[0, 10]}>
                                    <Col span={12}>
                                      <Row gutter={[0, 4]}>
                                        <Col span={7}>
                                          <Text>
                                            {
                                              subscriptionDetailsPanel.subscriptionId
                                            }
                                            :
                                          </Text>
                                        </Col>
                                        <Col span={17}>
                                          <Text>{subscription.id}</Text>
                                        </Col>

                                        <Col span={7}>
                                          <Text>
                                            {subscriptionDetailsPanel.email}:
                                          </Text>
                                        </Col>
                                        <Col span={17}>
                                          <Text>{subscription.email}</Text>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col
                                      span={12}
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <Title level={4} strong>
                                        {countriesCurrency.find(
                                          (country) =>
                                            country.currencyCode ===
                                            subscription.currency
                                        )?.currencySymbol ||
                                          subscription.currency}{" "}
                                        {subscription.price &&
                                        Number(subscription.price)
                                          ? subscription.price?.toFixed(2)
                                          : "0.00"}
                                      </Title>

                                      <Badge
                                        status={
                                          subscription.status?.toLowerCase() ===
                                            "success" ||
                                          subscription.status?.toLowerCase() ===
                                            "live"
                                            ? "success"
                                            : "error"
                                        }
                                        text={
                                          <Text strong>
                                            {subscription?.status
                                              ? `${subscription.status
                                                  .charAt(0)
                                                  .toUpperCase()}${subscription.status
                                                  .slice(1)
                                                  .toLowerCase()}`
                                              : ""}
                                          </Text>
                                        }
                                        size="large"
                                      />
                                    </Col>

                                    <Col span={4}>
                                      <Text>
                                        {" "}
                                        {
                                          subscriptionDetailsPanel.subscriptionTerm
                                        }
                                        :
                                      </Text>
                                    </Col>
                                    <Col span={19}>
                                      <Text>
                                        {subscription.currentTermStartsAt +
                                          " to " +
                                          subscription.currentTermEndsAt}
                                      </Text>
                                    </Col>
                                  </Row>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col span={24}>
                  <Title level={4}>Add-ons </Title>
                </Col>

                <Col span={8}>
                  <Card size="small" className="card-rounded-corner">
                    <Space direction="vertical">
                      <Space>
                        <Image
                          src={channels.WHATSAPP.logo}
                          preview={false}
                          width={40}
                        />
                        <Text
                          style={{
                            fontSize: 17,
                          }}
                          strong
                        >
                          WhatsApp Broadcast Pack
                        </Text>
                        {/* <BiBroadcast size={20} style={{ color: "#F05742" }} /> */}
                      </Space>
                      <Text
                        type="secondary"
                        style={{
                          fontSize: 13,
                        }}
                      >
                        Buy this WhatsApp Broadcast Pack to send broadcast
                        messages to your customers.
                        <br />
                        Take your business to the next level with WhatsApp's
                        business features.
                      </Text>
                      <Tooltip
                        title={
                          !botInAction.bot_channels.includes("WHATSAPP")
                            ? "WhatsApp channel is not enabled for this bot"
                            : ""
                        }
                      >
                        <Button
                          type="primary"
                          style={{
                            borderRadius: 5,
                          }}
                          disabled={
                            !botInAction.bot_channels.includes("WHATSAPP")
                          }
                          onClick={() => {
                            dispatchReducer({
                              type: "OPEN_FORM_PANEL",
                              payload: {
                                component: "buy-addons-form-panel",
                                title: "",
                              },
                            });
                          }}
                        >
                          Buy Add-on
                        </Button>
                      </Tooltip>
                    </Space>
                  </Card>
                </Col>

                <Col span={8}>
                  <Card size="small" className="card-rounded-corner">
                    <Space direction="vertical">
                      <Space>
                        <Image
                          src="/images/liveagent.png"
                          preview={false}
                          width={38}
                        />
                        <Text
                          style={{
                            fontSize: 17,
                          }}
                          strong
                        >
                          Conversation Live Agent
                        </Text>
                      </Space>

                      <Text
                        type="secondary"
                        style={{
                          fontSize: 13,
                        }}
                      >
                        Buy conversation live agent add-on to connect with your
                        customers in real-time and provide them with instant
                        support.
                        <br />
                        Need help getting started?{" "}
                        <Link
                          href={
                            URIs.DOCUMENTATIONS +
                            "/docs/conversations/resuming_conversations.html"
                          }
                          target="_blank"
                        >
                          view live agent guide
                        </Link>
                      </Text>
                      <Tooltip title="Contact support team to add more live agents">
                        <Button
                          type="primary"
                          disabled
                          style={{
                            borderRadius: 5,
                          }}
                          onClick={async () => {
                            window.open(
                              "mailto:support@inextlabs.com?subject=" +
                                "Conversation Live Agent" +
                                "&body=" +
                                "Hi, I would like to buy Conversation Live Agent. Please contact me."
                            );
                          }}
                          target="_blank"
                        >
                          Buy Add-on
                        </Button>
                      </Tooltip>
                    </Space>
                  </Card>
                </Col>

                <Col span={8}>
                  <Card size="small" className="card-rounded-corner">
                    <Space direction="vertical">
                      <Space>
                        <Image
                          src="/images/channel-icons/channels.png"
                          preview={false}
                          width={38}
                        />
                        <Text
                          style={{
                            fontSize: 17,
                          }}
                          strong
                        >
                          Additional Channels
                        </Text>
                      </Space>

                      <Text
                        type="secondary"
                        style={{
                          fontSize: 13,
                        }}
                      >
                        Configure your bot across multiple channels to reach
                        your customers where they are and increase your customer
                        engagement.
                        <br />
                        Need help getting started?{" "}
                        <Link
                          href={
                            URIs.DOCUMENTATIONS +
                            "/docs/bot-settings/channels/facebook.html"
                          }
                          target="_blank"
                        >
                          view channel guide
                        </Link>
                      </Text>
                      <Tooltip title="Contact support team to configure additional channels">
                        <Button
                          type="primary"
                          disabled
                          style={{
                            borderRadius: 5,
                          }}
                          onClick={async () => {
                            window.open(
                              "mailto:support@inextlabs.com?subject=" +
                                "Conversation Live Agent" +
                                "&body=" +
                                "Hi, I would like to buy Conversation Live Agent. Please contact me."
                            );
                          }}
                          target="_blank"
                        >
                          Buy Add-on
                        </Button>
                      </Tooltip>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Content>
          </Layout>
          <BuyAddOnFormPanel />
        </Layout>
      ) : (
        <FullPageLoader
          component="subscription-list"
          loadingMessage="Loading... Please wait..."
        />
      )
    ) : (
      <Row
        gutter={8}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={5}>
          <Image
            src="/images/subscriptions/no-subscription.png"
            preview={false}
            width={300}
          />
        </Col>
        <Col span={1}>
          <Divider type="vertical" />
        </Col>

        <Col
          span={9}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Title level={3} style={{ margin: 0 }}>
                {subscriptionNotAvailable.title}
              </Title>
            </Col>
            <Col span={24}>
              <Paragraph>{subscriptionNotAvailable.info}</Paragraph>
              <Paragraph>
                <ul>
                  <li>
                    <Text strong>{subscriptionNotAvailable.reason1.title}</Text>
                    {subscriptionNotAvailable.reason1.description}
                  </li>
                  <li>
                    <Text strong>{subscriptionNotAvailable.reason2.title}</Text>
                    {subscriptionNotAvailable.reason2.description}
                  </li>
                  <li>
                    <Text strong>{subscriptionNotAvailable.reason3.title}</Text>
                    {subscriptionNotAvailable.reason3.description}
                  </li>
                </ul>
              </Paragraph>
            </Col>
            <Col span={24}>
              <Space>
                <span
                  dangerouslySetInnerHTML={{
                    __html: subscriptionNotAvailable.support.replace(
                      /support@inextlabs\.com/g,
                      '<a href="mailto:support@inextlabs.com">support@inextlabs.com</a>'
                    ),
                  }}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  ) : (
    <AccessDenied />
  );
};

export default AccountSubscription;
