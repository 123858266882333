const formPanel = (state, action) => {
  switch (action.type) {
    case "OPEN_FORM_PANEL":
      return {
        ...state,
        formPanel: {
          ...state.formPanel,
          ...{
            [action.payload.component]: {
              visible: true,
              title: action.payload.title,
            },
          },
        },
      };

    case "CLOSE_FORM_PANEL":
      return {
        ...state,
        formPanel: {
          ...state.formPanel,
          ...{
            [action.payload.component]: { visible: false, title: "" },
          },
        },
      };

    default:
      break;
  }
};

export default formPanel;
