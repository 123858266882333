import React from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { Space, Typography, Row, Col, Image, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { getUserDateTime } from "../../utils/utility";
import { botProfilePictures } from "../../constants/enums";
import ConversationINSTAGRAM from "./ConversationINSTAGRAM";
import ConversationWHATSAPP from "./ConversationWHATSAPP";
import ConversationWEB from "./ConversationWEB";
import ConversationGBM from "./ConversationGBM";

const { Text } = Typography;

const ConversationBubble = ({ transcript, channel }) => {
  const [state] = useGlobalContext();

  return (
    <>
      <Space
        style={{
          width: "100%",
          justifyContent: transcript.is_from_user ? "left" : "right",
        }}
      >
        <Row gutter={8}>
          <Col>
            <Space
              direction="vertical"
              size={0}
              style={{
                backgroundColor: transcript.is_from_user
                  ? "#FFFFFF"
                  : "#E0FCD6",
                padding: "5px 10px",
                borderRadius: 5,
                minWidth: 100,
                maxWidth: 550,
              }}
            >
              {transcript.type === "interactive" ||
              transcript.type === "richCard" ||
              transcript.type === "application/vnd.microsoft.card.adaptive" ||
              transcript.type === "application/vnd.microsoft.card.hero" ||
              transcript.type === "application/vnd.microsoft.card.thumbnail" ||
              transcript.type === "image" ? (
                channel === "INSTAGRAM" ? (
                  <div style={{ overflowX: "scroll" }}>
                    <Space>
                      <ConversationINSTAGRAM transcript={transcript} />
                    </Space>
                  </div>
                ) : channel === "WHATSAPP" ? (
                  <div style={{ overflowX: "scroll" }}>
                    <Space>
                      <ConversationWHATSAPP transcript={transcript} />
                    </Space>
                  </div>
                ) : channel === "FACEBOOK" ? (
                  <Space>Facebook Component goes here</Space>
                ) : channel === "WEB" ? (
                  <div style={{ overflow: "auto" }}>
                    <Space>
                      <ConversationWEB transcript={transcript} />
                    </Space>
                  </div>
                ) : channel === "GBM" ? (
                  <div style={{ overflowX: "scroll" }}>
                    <Space>
                      <ConversationGBM transcript={transcript} />
                    </Space>
                  </div>
                ) : (
                  channel === "AMB" && <Space>AMB Component goes here</Space>
                )
              ) : (
                <Space>{transcript.message}</Space>
              )}
              <Space
                style={{
                  width: "100%",
                  justifyContent: "right",
                }}
              >
                {transcript.isSending ? (
                  <Text style={{ fontSize: 10 }} type="secondary">
                    sending...
                  </Text>
                ) : (
                  <Text style={{ fontSize: 10 }} type="secondary">
                    {getUserDateTime(
                      transcript.date,
                      state.currentUser.user.timeZone,
                      "DD/MM/yyyy - hh:mm a"
                    )}
                  </Text>
                )}
              </Space>
            </Space>
          </Col>
          <Col>
            <Space>
              {!transcript.is_from_user && !transcript.is_agent && (
                <Avatar
                  size="large"
                  src={
                    <Image
                      preview={false}
                      src={
                        botProfilePictures[
                          state.currentAccount.account.bots.find(
                            (bot) =>
                              bot.bot_id ===
                              state.conversations.currentConversation.bot_id
                          )?.bot_icon
                        ]
                      }
                    />
                  }
                />
              )}

              {!transcript.is_from_user &&
                transcript.is_agent &&
                (transcript.agent_id &&
                state.accountUsers.users &&
                state.accountUsers.users.find(
                  (user) => user.userID === transcript.agent_id
                )?.profilePicture ? (
                  <Avatar
                    size="large"
                    src={
                      <Image
                        preview={false}
                        src={state.currentUser.user.profilePicture}
                      />
                    }
                  />
                ) : (
                  <Avatar
                    size="large"
                    icon={<UserOutlined />}
                    style={{ backgroundColor: "#B0B0B0" }}
                  />
                ))}
            </Space>
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default ConversationBubble;
