import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import { URIs } from "../../constants/env";
import {
  Space,
  Card,
  Select,
  Radio,
  Button,
  Input,
  Row,
  Col,
  Form,
  AutoComplete,
  Divider,
  Modal,
  Typography,
  Image,
  Upload,
  Tag,
  Tooltip,
  Empty,
  Carousel,
  Collapse,
  DatePicker,
} from "antd";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdFirstPage,
  MdLastPage,
  MdCampaign,
  MdMessage,
  MdPeopleAlt,
  MdPermPhoneMsg,
  MdOutlineFormatListBulleted,
  MdCall,
  MdCardGiftcard,
  MdContentCopy,
} from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { UploadOutlined } from "@ant-design/icons";
import { SiChatbot } from "react-icons/si";
import { BiImport } from "react-icons/bi";
import { SiSemaphoreci } from "react-icons/si";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { IoLogoUsd } from "react-icons/io";
import { HiOutlineTemplate, HiOutlineExternalLink } from "react-icons/hi";
import { MdReply } from "react-icons/md";
import CSVFileUpload from "./CSVFileUpload";
import Validation from "../../shared-components/Validation";
import SelectContact from "./SelectContacts";
import ConnectAPISource from "./ConnectAPISource";
import openNotificationWithIcon from "../../utils/notification";
import {
  formatText,
  calculateDateTimeToBeDisplayed,
} from "../../utils/utility";
import { Translation } from "../../translation/translation";
import {
  FullPageLoader,
  TabLoader,
  ComponentLoader,
} from "../../shared-components/LoadingIndicator";
import {
  countryDailingCodes,
  wabaProvider,
  maxAllowedMediaSize,
} from "../../constants/enums";
import ContactsFromClientResponses from "./ContactsFromClientResponses";
import BroadcastPricingCalculator from "./BroadcastPricingCalculator";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";

const { Option } = Select;
const { confirm } = Modal;

const BroadcastManagerForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // Get translated text
  const messageTemplatesTranslation = Translation().messageTemplates;

  const [channel] = useState("WHATSAPP");
  const [bot, setBot] = useState(null);

  const [template, setTemplate] = useState(null);
  const [templateHeader, setTemplateHeader] = useState(null);
  const [templateBody, setTemplateBody] = useState(null);
  const [templateCarousel, setTemplateCarousel] = useState(null);
  const [templateLimitedTimeOffer, setTemplateLimitedTimeOffer] =
    useState(null);
  const [templateFooter, setTemplateFooter] = useState(null);
  const [templateButtons, setTemplateButtons] = useState(null);

  const [preview, setPreview] = useState(null);

  const [recipientType, setRecipientType] = useState("single");
  const [importedColumns, setImportedColumns] = useState(null);
  const [importedData, setImportedData] = useState(null);
  const [importedDataSource, setImportedDataSource] = useState(null);
  const [csvFile, setCSVFile] = useState(null);
  const [recipientCountry, setRecipientCountry] = useState(null);

  //upload csv file
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [bodyPlaceHolders, setBodyPlaceHolders] = useState(null);
  const [urlPlaceHolders, setUrlPlaceHolders] = useState(null);

  const [bodyMapping, setBodyMapping] = useState(null);
  const [buttonMapping, setButtonMapping] = useState(null);
  const [urlMapping, setUrlMapping] = useState(null);
  const [urlWithPlaceHoldersPreview, setUrlWithPlaceHoldersPreview] =
    useState(null);
  const [recipientPhoneNumberMapping, setRecipientPhoneNumberMapping] =
    useState(null);

  const [image, setImage] = useState("/images/image-placeholder.png");
  const [video, setVideo] = useState(null);
  const [document, setDocument] = useState(null);
  const [mediaFileName, setMediaFileName] = useState(null);

  const [carouselCardImages, setCarouselCardImages] = useState([]);

  const [currentRecord, setCurrentRecord] = useState(0);
  const [broadcastSession, setBroadcastSession] = useState(0);

  const [submitType, setSubmitType] = useState(null);

  const { Text, Link } = Typography;

  // Broadcast from other modules
  const populateSingleContact = state.broadcastManager.singleContactToPopulate;
  const populateMultipleContacts =
    state.broadcastManager.multipleContactsToPopulate;

  const whatsappUsage = state.currentAccount.whatsappUsage;

  const handleBotSelection = (e) => {
    resetMapping();
    const selectedBot = state.currentAccount.account.bots.find(
      (currentBotInLoop) => currentBotInLoop.bot_id === e
    );
    // For simplification purpose only added wabaBotExists to first level of bot object
    selectedBot["wabaBotExists"] = selectedBot.bot_channel_info?.WHATSAPP
      ?.waba_bot_exists
      ? true
      : false;
    setBot(selectedBot);

    const wabaProviderName =
      wabaProvider[selectedBot.bot_channel_info.WHATSAPP?.waba_provider]
        ?.providerReference.providerName;

    // If templates are loaded for selected bot, then no need to load again
    if (
      !state.messageTemplates.templates?.find(
        (template) => template.botId === selectedBot?.bot_id
      )
    ) {
      dispatchMiddleware({
        type: "MWD_LOAD_MESSAGE_TEMPLATE",
        payload: {
          wabaApikey: selectedBot?.bot_channel_info?.WHATSAPP?.waba_apikey,
          botId: selectedBot?.bot_id,
          wabaProvider: wabaProviderName,
          component: "message-templates-table",
        },
      });
    }

    // After template selection, switched bot means, reset template
    // Based on undefined template, handled form hasFeedback
    if (template) {
      setTemplate(undefined);
      form.setFieldValue("template", undefined);
    }

    // Reset the whatsapp usage data
    dispatchReducer({
      type: "RD_CLEAR_WHATSAPP_USAGE_DATA",
    });

    // Load whatsapp usage data
    wabaProviderName !== "INEXTLABS-CLOUD" &&
      dispatchMiddleware({
        type: "MWD_LOAD_WHATSAPP_USAGE_DATA",
        payload: {
          botId: selectedBot?.bot_id,
          clientId: selectedBot?.bot_channel_info?.WHATSAPP?.waba_client_id,
          channelId:
            selectedBot?.bot_channel_info?.WHATSAPP?.waba_channel_id?.[0],
          currentMonth: new Date().getMonth(),
          component: "whatsapp-usage-button",
        },
      });
  };

  const handleTemplateSelection = (e) => {
    resetMapping();

    const selectedTemplate = state.messageTemplates.templates
      .find(({ botId }) => botId === bot.bot_id)
      ?.waba_templates.find(
        (template) => template.name + "_" + template.language === e
      );

    setTemplate(selectedTemplate);

    setTemplateHeader(
      selectedTemplate.components.find(
        (component) => component.type === "HEADER"
      )
    );

    const selectedTemplateBody = selectedTemplate.components.find(
      (component) => component.type === "BODY"
    );
    setTemplateBody(selectedTemplateBody);

    const selectedTemplateLimitedTimeOffer = selectedTemplate.components.find(
      (component) => component.type === "LIMITED_TIME_OFFER"
    );
    setTemplateLimitedTimeOffer(selectedTemplateLimitedTimeOffer);

    const selectedTemplateCarousel = selectedTemplate.components.find(
      (component) => component.type === "CAROUSEL"
    );
    setTemplateCarousel(selectedTemplateCarousel);

    setTemplateFooter(
      selectedTemplate.components.find(
        (component) => component.type === "FOOTER"
      )
    );
    const selectedTemplateButtons = selectedTemplate.components.find(
      (component) => component.type === "BUTTONS"
    );
    setTemplateButtons(selectedTemplateButtons);

    setPreview(selectedTemplateBody.text);
    setBodyPlaceHolders(selectedTemplateBody.text.match(/[^{}]+(?=})/g));

    const urlWithPlaceHolders = selectedTemplateButtons?.buttons
      .find(({ type }) => type === "URL")
      ?.url?.match(/[^{}]+(?=})/g);

    if (urlWithPlaceHolders) {
      setUrlPlaceHolders(urlWithPlaceHolders);
    } else {
      setUrlPlaceHolders(null);
    }

    if (selectedTemplateButtons?.buttons?.find(({ type }) => type === "FLOW")) {
      dispatchMiddleware({
        type: "LOAD_WHATSAPP_FLOWS",
        payload: {
          component: "whatsapp-flows-input-box",
          botInAction: bot,
          wabaAccountId: bot.bot_channel_info.WHATSAPP?.waba_account_id,
        },
      });
    }
  };

  const handleRecipientTypeChange = (e) => {
    resetMapping();
    setImportedColumns(null);
    setImportedData(null);
    setImportedDataSource(null);
    setRecipientType(e.target.value);
  };

  const handleReplaceBodyPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];
    if (column.match(/[^{}]+(?=})/g)) {
      setBodyMapping({
        ...bodyMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)?.[0] + "}",
      });
    } else {
      setBodyMapping({ ...bodyMapping, [placeHolder]: column });
    }
  };

  const handleReplaceButtonPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];
    if (column.match(/[^{}]+(?=})/g)) {
      setButtonMapping({
        ...buttonMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)?.[0] + "}",
      });
    } else {
      setButtonMapping({ ...buttonMapping, [placeHolder]: column });
    }
  };

  const handleReplaceUrlPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];

    if (column.match(/[^{}]+(?=})/g)) {
      setUrlMapping({
        ...urlMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)?.[0] + "}",
      });
    } else {
      setUrlMapping({ ...urlMapping, [placeHolder]: column });
    }
  };

  const handleReplaceRecipientPhoneNumberPlaceHolder = (e) => {
    const placeHolder = e.split("~*!*$*~")[0];
    const column = e.split("~*!*$*~")[1];
    if (column.match(/[^{}]+(?=})/g)) {
      setRecipientPhoneNumberMapping({
        ...recipientPhoneNumberMapping,
        [placeHolder]: "{" + column.match(/[^{}]+(?=})/g)?.[0] + "}",
      });
    } else {
      setRecipientPhoneNumberMapping({
        ...recipientPhoneNumberMapping,
        [placeHolder]: column,
      });
    }
  };

  // To receive CSV Parsed data & column from CSVFileUpload component & SelectContacts component
  const handleGetParsedData = (columns, data, dataSource, dataFile) => {
    resetMapping();
    setImportedColumns(columns);
    setImportedData(data);
    setImportedDataSource(dataSource);
    setCSVFile(dataFile);
  };

  const handleCancelImport = () => {
    dispatchReducer({
      type: "RD_CLEAR_SELECTED_ROW_KEYS",
      payload: { component: "contact-manager-table" },
    });
    resetMapping();
    setImportedColumns(null);
    setImportedData(null);
    setImportedDataSource(null);
  };

  const handleImageUpload = (e) => {
    const fileToLoad = e.file;
    setMediaFileName(e.file.name);
    const fileReader = new FileReader();

    fileReader.readAsDataURL(fileToLoad);
    fileReader.onload = (fileLoadedEvent) => {
      const srcData = fileLoadedEvent.target.result;
      setImage(
        // if version 4, then image format should be file
        bot?.bot_channel_info.WHATSAPP?.waba_broadcast_version === 4
          ? fileToLoad
          : srcData
      );
    };
  };

  const handleVideoUpload = (e) => {
    const fileToLoad = e.file;
    setMediaFileName(e.file.name);
    const fileReader = new FileReader();

    fileReader.readAsDataURL(fileToLoad);
    fileReader.onload = (fileLoadedEvent) => {
      const srcData = fileLoadedEvent.target.result;
      setVideo(
        // if version 4, then video format should be file
        bot?.bot_channel_info.WHATSAPP?.waba_broadcast_version === 4
          ? fileToLoad
          : srcData
      );
    };
  };

  const handleDocumentUpload = (e) => {
    const fileToLoad = e.file;
    setMediaFileName(e.file.name);
    const fileReader = new FileReader();

    fileReader.readAsDataURL(fileToLoad);
    fileReader.onload = (fileLoadedEvent) => {
      const srcData = fileLoadedEvent.target.result;
      setDocument(
        // if version 4, then document format should be file
        bot?.bot_channel_info.WHATSAPP?.waba_broadcast_version === 4
          ? fileToLoad
          : srcData
      );
    };
  };

  const resetMapping = () => {
    setBroadcastSession(Math.random);
    if (template) {
      setPreview(templateBody.text);
    }
    setImage("/images/image-placeholder.png");
    setVideo(null);
    setMediaFileName(null);
    setBodyMapping(null);
    setButtonMapping(null);
    setRecipientPhoneNumberMapping(null);
    setCurrentRecord(0);
    setUrlMapping(null);
    setUrlWithPlaceHoldersPreview(null);
    setRecipientCountry(null);
    setCarouselCardImages([]);
  };

  const resetFormFields = () => {
    form.resetFields();
    confirm({
      title: "Are you sure you want to close the broadcast?",
      content:
        "When clicked the OK button, the unsaved form inputs will be lost",
      async onOk() {
        dispatchReducer({
          type: "CLOSE_FORM_PANEL",
          payload: {
            component: "broadcast-form-panel",
          },
        });

        resetPopulatedContacts();
      },
      onCancel() {},
    });
  };

  const handleSubmitBroadcast = () => {
    if (!template || (recipientType === "multiple" && !importedData)) {
      openNotificationWithIcon("error", "Mapping is not complete");
    } else {
      let recipientPhoneNumberContent = "";
      const countryCode = form.getFieldValue("countryCode");
      if (recipientType === "single" && countryCode) {
        recipientPhoneNumberContent =
          form.getFieldValue("countryCode") + recipientPhoneNumberMapping.phone;
      } else {
        recipientPhoneNumberContent = recipientPhoneNumberMapping.phone;
      }

      const broadcastVersion = parseInt(
        bot?.bot_channel_info?.WHATSAPP?.waba_broadcast_version
      );

      let componentsContent = [];
      if (templateBody && bodyMapping) {
        componentsContent.push({
          type: "BODY",
          parameters: Object.keys(bodyMapping).map((key) => {
            return { type: "text", text: bodyMapping[key] };
          }),
        });
      }

      let mediaType = "";
      let mediaData = {};
      if (templateHeader) {
        //No need send any value if the header type is TEXT.
        //For Image, Video & Other Media, the mediaId will generated in the middleware and get inserted, based on the mediaData object below.
        //For broadcast version 4, the media should be sent as file
        if (
          broadcastVersion !== 4 &&
          (templateHeader.format === "IMAGE" ||
            templateHeader.format === "VIDEO" ||
            templateHeader.format === "DOCUMENT")
        ) {
          let mediaBase64 = "";
          if (templateHeader.format === "IMAGE") {
            mediaBase64 = image;
            mediaType = "image";
          } else if (templateHeader.format === "VIDEO") {
            mediaBase64 = video;
            mediaType = "video";
          } else if (templateHeader.format === "DOCUMENT") {
            mediaBase64 = document;
            mediaType = "document";
          }
          mediaData = {
            media: { base64: mediaBase64 },
          };
        } else if (
          broadcastVersion === 4 &&
          templateHeader.format &&
          (templateHeader.format === "IMAGE" ||
            templateHeader.format === "VIDEO" ||
            templateHeader.format === "DOCUMENT")
        ) {
          let mediaContent = "";
          if (templateHeader.format === "IMAGE") {
            mediaContent = image;
            mediaType = "image";
          } else if (templateHeader.format === "VIDEO") {
            mediaContent = video;
            mediaType = "video";
          } else if (templateHeader.format === "DOCUMENT") {
            mediaContent = document;
            mediaType = "document";
          }
          mediaData = {
            media: mediaContent,
          };
        }
      }

      //No need send any value if the footer type is TEXT.
      if (templateButtons) {
        templateButtons.buttons.forEach((button, index) => {
          let buttonComponentsContent = [];

          if (button.type === "QUICK_REPLY") {
            buttonComponentsContent.push({
              type: "payload",
              payload: form.getFieldValue("button" + index + broadcastSession),
            });
          }

          if (button.type === "CATALOG") {
            buttonComponentsContent.push({
              type: "action",
              action: {
                thumbnail_product_retailer_id: form.getFieldValue(
                  "button" + index + broadcastSession
                ),
              },
            });
          }

          if (button.type === "FLOW") {
            buttonComponentsContent.push({
              type: "action",
              action: {
                flow_action_data: {
                  screen: button.navigate_screen,
                },
              },
            });
          }

          //When the button type is URL with a placeholders, send the value of the text field
          if (urlPlaceHolders?.length > 0) {
            urlPlaceHolders.forEach(() => {
              if (button.type === "URL") {
                buttonComponentsContent.push({
                  type: "text",
                  text: form.getFieldValue("button" + index + broadcastSession),
                });
              }
            });
          }

          //No need send any value if the button type is URL without placeholders or PHONE_NUMBER.
          if (buttonComponentsContent.length > 0) {
            componentsContent.push({
              type: "button",
              sub_type: button.type.toLowerCase(),
              index: index.toString(),
              parameters: buttonComponentsContent,
            });
          }
        });
      }

      // Carousal card images content
      if (templateCarousel) {
        mediaData = {
          media: carouselCardImages,
        };

        let carouselCardComponentsContent = [];
        templateCarousel.cards.forEach((card, cardIndex) => {
          let cardComponentsContent = {
            card_index: cardIndex.toString(),
            components: [],
          };
          card.components.forEach((component) => {
            if (component.type === "BUTTONS") {
              component.buttons.forEach((button, buttonIndex) => {
                let buttonComponentsContent = [];
                if (button.type === "QUICK_REPLY") {
                  buttonComponentsContent.push({
                    type: "payload",
                    payload:
                      form.getFieldValue(
                        "carouselCardButton" +
                          cardIndex +
                          buttonIndex +
                          broadcastSession
                      ) || "",
                  });
                }
                if (buttonComponentsContent.length > 0) {
                  cardComponentsContent.components.push({
                    type: "BUTTON",
                    sub_type: button.type.toUpperCase(),
                    index: buttonIndex.toString(),
                    parameters: buttonComponentsContent,
                  });
                }
              });
            }
          });
          carouselCardComponentsContent.push(cardComponentsContent);
        });
        componentsContent.push({
          type: "CAROUSEL",
          cards: carouselCardComponentsContent,
        });
      }

      // Limit time offer content
      if (templateLimitedTimeOffer) {
        componentsContent.push({
          type: "limited_time_offer",
          parameters: [
            {
              type: "limited_time_offer",
              limited_time_offer: {
                expiration_time_ms: moment(
                  form.getFieldValue("limitedTimeOffer" + broadcastSession)
                ).valueOf(),
              },
            },
          ],
        });

        templateButtons.buttons?.[0]?.type?.toUpperCase() === "COPY_CODE" &&
          componentsContent.push({
            type: "button",
            sub_type: "copy_code",
            index: "0",
            parameters: [
              {
                type: "coupon_code",
                coupon_code: form.getFieldValue("button0" + broadcastSession),
              },
            ],
          });
      }

      const broadcastObject = {
        campaignName: form.getFieldValue("broadcastName"),
        channel,
        recipient: recipientPhoneNumberContent,
        template: {
          namespace: template.namespace,
          language: { policy: "deterministic", code: template.language },
          name: template.name,
          components: componentsContent,
          category: template.category,
          //For carousel template type, we are sending media as form data
          //For other template types, we are sending media as base64
          isCarousel: templateCarousel ? true : false,
        },
      };

      let channelConfigurationInfo = {};

      const wabaProviderName =
        wabaProvider[bot.bot_channel_info.WHATSAPP?.waba_provider]
          ?.providerReference.providerName;

      // For version 4, the channel configuration info should be camelCase
      if (channel === "WHATSAPP" && broadcastVersion !== 4) {
        // Common to both INEXTLABS-CLOUD and 360DIALOG
        channelConfigurationInfo["api-provider"] = wabaProviderName;

        if (wabaProviderName === "INEXTLABS-CLOUD") {
          channelConfigurationInfo["phone-number-id"] =
            bot.bot_channel_info.WHATSAPP?.waba_phone_number_id;
        } else {
          channelConfigurationInfo["waba-key"] =
            bot.bot_channel_info.WHATSAPP?.waba_apikey;
        }
      } else {
        // Common to both INEXTLABS-CLOUD and 360DIALOG
        channelConfigurationInfo["apiProvider"] = wabaProviderName;

        if (wabaProviderName === "INEXTLABS-CLOUD") {
          channelConfigurationInfo["phoneNumberId"] =
            bot.bot_channel_info.WHATSAPP?.waba_phone_number_id;
        } else {
          channelConfigurationInfo["wabaKey"] =
            bot.bot_channel_info.WHATSAPP?.waba_apikey;
        }
      }

      submitType === "json-download"
        ? dispatchMiddleware({
            type: "DOWNLOAD_JSON_FILE",
            payload: {
              broadcastContent: broadcastObject,
              mediaContent: {
                hasMedia: Object.keys(mediaData).length > 0,
                mediaType,
                mediaData,
                mediaFileName,
              },
              bot,
              recipientType,
              csvFile,
              channelConfigurationInfo,
              submitType,
            },
          })
        : confirm({
            title: "You are about to broadcast a message",
            content: (
              <Space direction="vertical">
                {`When clicked the OK button, the '${template.name} (${
                  template.language
                })' message will be sent to ${
                  recipientType === "multiple" ? importedData.length : "1"
                } recipient(s)`}

                {wabaProviderName !== "INEXTLABS-CLOUD" && (
                  <Text
                    type="danger"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Note: This is chargable based on reciepient count.
                  </Text>
                )}
              </Space>
            ),

            onOk() {
              dispatchMiddleware({
                type: "START_BROADCAST",
                payload: {
                  component: "broadcast-manager-form", //UI Component
                  broadcastContent: broadcastObject,
                  mediaContent: {
                    hasMedia: Object.keys(mediaData).length > 0,
                    mediaType,
                    mediaData,
                    mediaFileName,
                  },
                  bot,
                  recipientType,
                  csvFile,
                  channelConfigurationInfo,
                  submitType,
                  // Check the broadcast version
                  broadcastVersion,
                  successMessage: "Broadcast started successfully",
                  recipientCount:
                    recipientType === "multiple" ? importedData.length : 1, // For logging appinsights
                },
              });
              dispatchReducer({
                type: "RD_CLEAR_SELECTED_ROW_KEYS",
                payload: { component: "contact-manager-table" },
              });
              resetPopulatedContacts();
            },
          });
    }
  };

  const resetPopulatedContacts = () => {
    // Clear populated contacts if multipleContactsToPopulate or singleContactToPopulate exists
    (state.broadcastManager.multipleContactsToPopulate ||
      state.broadcastManager.singleContactToPopulate) &&
      dispatchReducer({
        type: "CLEAR_POPULATED_CONTACTS",
      });

    // Clear selected row keys for client response table if multipleContactsToPopulate exists
    state.broadcastManager.multipleContactsToPopulate &&
      dispatchReducer({
        type: "RD_CLEAR_SELECTED_ROW_KEYS",
        payload: {
          component: "client-response-table-selected-row",
        },
      });
  };

  // Price calculation for multiple recipient broadcast
  // Validate the recipient phone numbers
  const validRecipientsPhoneNumber = () => {
    const recipientPhoneNumbers = (importedData || [])
      .map(
        (data) =>
          data[
            recipientPhoneNumberMapping?.phone
              ?.replace("{", "")
              ?.replace("}", "")
          ]
      )
      .filter((phoneNumber) => phoneNumber);

    return recipientPhoneNumbers.filter((phoneNumber) => {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      return parsedPhoneNumber?.isValid();
    });
  };

  // Group the recipient phone numbers based on country code
  // Change the country code to country code label format (Ex: 91 to (+91 india))
  const groupCountryCodes = () => {
    const recipientPhoneNumberCountryCode = (validRecipientsPhoneNumber() || [])
      .map(
        (phoneNumber) => `+${parsePhoneNumber(phoneNumber)?.countryCallingCode}`
      )
      .map(
        (countryCode) =>
          countryDailingCodes.find((country) => country.value === countryCode)
            ?.label
      );

    return (recipientPhoneNumberCountryCode || []).reduce(
      (acc, currentCountryCode) => {
        const existingCountry = acc.find(
          ({ countryCode }) => countryCode === currentCountryCode
        );

        if (existingCountry) {
          existingCountry.recipientCount++;
        } else {
          acc.push({ countryCode: currentCountryCode, recipientCount: 1 });
        }

        return acc;
      },
      []
    );
  };

  // Validate the recipient country have price details or not
  const priceNotUpdatedRecipients = () => {
    const priceNotUpdatedRecipient = (groupCountryCodes() || []).filter(
      ({ countryCode }) => {
        const notMatchedCountry =
          !state.broadcastManager.broadcastPriceList.find(
            ({ country }) => country === countryCode
          );

        return notMatchedCountry ? countryCode : null;
      }
    );

    return priceNotUpdatedRecipient;
  };

  // Calculate the total price for multiple recipient broadcast
  const calculateMultiRecipientBroadcastPrice = () => {
    const priceBasedOnCountryCode = (groupCountryCodes() || []).map(
      ({ countryCode, recipientCount }) => {
        const matchedCountry = state.broadcastManager.broadcastPriceList.find(
          ({ country }) => country === countryCode
        );

        const price =
          matchedCountry?.[
            template.category === "MARKETING" ? "marketing" : "utility"
          ] * recipientCount;

        return { countryCode, recipientCount, price };
      }
    );

    const totalPrice = (priceBasedOnCountryCode || [])
      .reduce(
        (acc, currentCountry) => acc + parseFloat(currentCountry.price),
        0
      )
      .toFixed(4);

    return totalPrice;
  };

  useEffect(() => {
    if (bodyMapping && bodyPlaceHolders) {
      let previewMessage = templateBody.text;
      bodyPlaceHolders.forEach((bodyPlaceHolder) => {
        if (bodyMapping[bodyPlaceHolder]) {
          if (recipientType === "multiple") {
            if (
              importedData[currentRecord][
                bodyMapping[bodyPlaceHolder].match(/[^{}]+(?=})/g)
              ]
            )
              previewMessage = previewMessage.replace(
                "{{" + bodyPlaceHolder + "}}",
                importedData[currentRecord][
                  bodyMapping[bodyPlaceHolder].match(/[^{}]+(?=})/g)
                ]
              );
            else
              previewMessage = previewMessage.replace(
                "{{" + bodyPlaceHolder + "}}",
                bodyMapping[bodyPlaceHolder]
              );
          } else {
            previewMessage = previewMessage.replace(
              "{{" + bodyPlaceHolder + "}}",
              bodyMapping[bodyPlaceHolder]
            );
          }
        }
      });

      setPreview(previewMessage);
    } else if (urlMapping && urlPlaceHolders?.length) {
      let url = templateButtons?.buttons.find(
        ({ type }) => type === "URL"
      )?.url;

      urlPlaceHolders.forEach((urlPlaceHolder) => {
        if (urlMapping[urlPlaceHolder]) {
          url = url.replace(
            "{{" + urlPlaceHolder + "}}",
            urlMapping[urlPlaceHolder]
          );
        }
      });
      setUrlWithPlaceHoldersPreview(url);
    }
  }, [bodyMapping, currentRecord, bodyPlaceHolders, urlMapping]);

  // Populate the form values based on the broadcast settings
  useEffect(() => {
    const broadcastSettings = state.broadcastSettings;
    const broadcastDefaultBot =
      state.currentAccount.account.bots.find(
        (bot) => bot.bot_id === broadcastSettings?.broadcastDefaultBot
        // If no default bot is set, then select the first bot
      ) || state.currentAccount.account.bots[0];
    // For simplification purpose only added wabaBotExists to first level of bot object
    broadcastDefaultBot["wabaBotExists"] = broadcastDefaultBot.bot_channel_info
      ?.WHATSAPP?.waba_bot_exists
      ? true
      : false;
    setBot(broadcastDefaultBot);

    form.setFieldsValue({
      bot: broadcastDefaultBot?.bot_name,
      countryCode: !populateSingleContact
        ? broadcastSettings?.defaultCountryCode
        : "+" + populateSingleContact.countryCode,
    });
  }, []);

  useEffect(() => {
    // Populate the contact information, when the user clicks the create broadcast in the contact manager / responses info panel
    if (populateSingleContact) {
      form.setFieldsValue({
        countryCode: "+" + populateSingleContact.countryCode,
        [`phoneNumber${broadcastSession}`]:
          populateSingleContact.phoneNumberWithoutCountryCode,
      });
      setRecipientCountry(
        countryDailingCodes.find(
          (country) => country.value === "+" + populateSingleContact.countryCode
        )?.label
      );
      setRecipientPhoneNumberMapping({
        phone: populateSingleContact.phoneNumberWithoutCountryCode,
      });
    }
    // Populate the contact information, when the user clicks the create broadcast in the client responses header
    else if (populateMultipleContacts) {
      setRecipientType("multiple");
    }
  }, [broadcastSession]);

  return (
    <>
      {state.apiCallStatus["broadcast-manager-form"] &&
      state.apiCallStatus["broadcast-manager-form"].loading ? (
        <FullPageLoader
          component="broadcast-manager-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          requiredMark={false}
          onFinish={handleSubmitBroadcast}
          onFinishFailed={(error) => {
            openNotificationWithIcon(
              "error",
              "Please enter all the required fields"
            );
          }}
        >
          <Row gutter={[0, 24]}>
            {/*Broadcast Name*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Space
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Space size="middle">
                    <MdCampaign color="#F05742" size={22} />
                    <Text strong>BROADCAST NAME</Text>
                  </Space>

                  {state.currentAccount.account.subscription_info
                    ?.subscription_ids?.length &&
                    channel === "WHATSAPP" && (
                      <Space size="middle">
                        <Text strong>Current Balance:</Text>
                        {state.apiCallStatus?.["whatsapp-usage-button"]
                          ?.loading ? (
                          <ComponentLoader
                            component={"whatsapp-usage-button"}
                            loadingMessage=""
                            errorMessage=""
                          />
                        ) : (
                          <Text strong>
                            {whatsappUsage &&
                            whatsappUsage.currentBalance !== undefined ? (
                              <Tag
                                color={
                                  whatsappUsage.currentBalance < 10
                                    ? "red"
                                    : whatsappUsage.currentBalance < 100
                                    ? "orange"
                                    : "green"
                                }
                              >
                                ${whatsappUsage.currentBalance.toFixed(2)}
                              </Tag>
                            ) : (
                              "-"
                            )}
                          </Text>
                        )}
                      </Space>
                    )}
                </Space>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="broadcastName"
                      label="Provide a name for your broadcast"
                      rules={[
                        {
                          required: true,
                          message: "Please enter broadcast name",
                        },
                        { whitespace: true },
                        {
                          min: 5,
                          message:
                            "First name should be more then 5 characters",
                        },
                      ]}
                      hasFeedback
                      className="broadcast-form-item"
                    >
                      <Input
                        placeholder="Enter the broadcast name"
                        style={{ width: 500 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/*Channel*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Row>
                  <Col span={1}>
                    <TbDeviceMobileMessage color="#F05742" size={20} />
                  </Col>
                  <Col span={23}>
                    <Text strong>MESSAGE DELIVERY CHANNELS</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="channel"
                      label="Through which channel you would like the messages to be delivered?"
                      className="broadcast-form-item"
                      valuePropName="checked"
                    >
                      <Radio.Group value={channel}>
                        <Radio value="WHATSAPP">WhatsApp</Radio>
                        <Radio disabled value="RCS">
                          RCS
                        </Radio>
                        <Radio disabled value="SMS">
                          SMS
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/*Bot Selection*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Row>
                  <Col span={1}>
                    <SiChatbot color="#F05742" size={18} />
                  </Col>
                  <Col span={23}>
                    <Text strong>BOT</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Form.Item
                      name="bot"
                      label="Which bot should broadcast the message?"
                      rules={[
                        {
                          required: true,
                          message: "Please select a bot",
                        },
                      ]}
                      className="broadcast-form-item"
                      hasFeedback
                      valuePropName="value"
                    >
                      <Select
                        showSearch
                        style={{ width: 500 }}
                        onChange={handleBotSelection}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder="Select bot"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children?.includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {state.currentAccount.account.bots.map(
                          (bot, index) =>
                            bot.bot_channels?.includes(channel) && (
                              <Option value={bot.bot_id} key={index}>
                                {bot.bot_name}
                              </Option>
                            )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  {bot && (
                    <Col span={1}>
                      <Divider
                        type="vertical"
                        style={{ height: "100%" }}
                      ></Divider>
                    </Col>
                  )}
                  <Col span={7}>
                    {bot && channel === "WHATSAPP" && (
                      <>
                        <Row gutter={[0, 16]}>
                          <Col span={24}>
                            <Space direction="vertical" size={0}>
                              <Text strong>Phone Number:</Text>
                              <Text type="secondary">
                                {bot.bot_channel_info.WHATSAPP
                                  ?.waba_phone_number
                                  ? bot.bot_channel_info.WHATSAPP
                                      .waba_phone_number
                                  : "-"}
                              </Text>
                            </Space>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>

            {/*Template Selection*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Row>
                  <Col span={1}>
                    <MdMessage color="#F05742" size={20} />
                  </Col>
                  <Col span={15}>
                    <Text strong>MESSAGE</Text>
                  </Col>
                  <Col span={8}>
                    <Space>
                      <HiOutlineTemplate
                        style={{
                          color: "#F05742",
                          marginTop: 2,
                        }}
                        size={20}
                      />
                      <Text strong>NEW TEMPLATE</Text>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={15}>
                    {bot ? (
                      <Form.Item
                        name="template"
                        label="Which message you would like to broadcast?"
                        rules={[
                          {
                            required: true,
                            message: "Please select a template",
                          },
                        ]}
                        // After template selection, switched bot means, reset template
                        // Remove success icon
                        hasFeedback={template !== undefined}
                        className="broadcast-form-item"
                        valuePropName="value"
                      >
                        <Select
                          showSearch
                          style={{ width: 500 }}
                          onChange={handleTemplateSelection}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          placeholder="Select message template"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.includes(input)
                          }
                          notFoundContent={
                            !state.messageTemplates.templates ||
                            !state.messageTemplates.templates?.filter(
                              (template) => template.botId === bot.bot_id
                            ).length ? (
                              <Space
                                style={{
                                  width: "100%",
                                  height: 50,
                                }}
                              >
                                <TabLoader
                                  component="message-templates-table"
                                  loadingMessage="Loading... Please wait..."
                                  errorMessage="Error loading templates"
                                />
                              </Space>
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No templates found"
                              />
                            )
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {state.messageTemplates.templates
                            ?.find(({ botId }) => botId === bot.bot_id)
                            ?.waba_templates.map(
                              (template, index) =>
                                template.status?.toLowerCase() ===
                                  "approved" && (
                                  <Option
                                    value={
                                      template.name + "_" + template.language
                                    }
                                    key={index}
                                  >
                                    {`${template.name} (${
                                      template.language
                                    } | ${template.category.toLowerCase()})`}
                                  </Option>
                                )
                            )}
                        </Select>
                      </Form.Item>
                    ) : (
                      <Validation
                        validationMessage={[
                          "Select bot in 'BOT' section above",
                        ]}
                      />
                    )}
                  </Col>

                  <Col span={1}>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>

                  {/* Create Template Button */}
                  <Col span={8}>
                    <Space size="small" direction="vertical">
                      <Text>Create a new template using button below.</Text>
                      <Tooltip
                        placement="topRight"
                        title={
                          bot?.bot_channel_info.WHATSAPP &&
                          bot.bot_channel_info.WHATSAPP
                            .waba_integrated_onboarding
                            ? messageTemplatesTranslation.header.createTemplate
                                .infoText
                            : messageTemplatesTranslation.header.createTemplate
                                .pending
                        }
                      >
                        <Button
                          type="primary"
                          size="middle"
                          onClick={() => {
                            // Set bot in action based on selected bot
                            dispatchReducer({
                              type: "RD_SET_BOT_IN_ACTION",
                              payload: {
                                botInAction: bot?.bot_id,
                                clientId:
                                  state.currentAccount.account.client_id,
                                event: "globalBotOnChange",
                              },
                            });

                            // Navigate to message templates page
                            navigate("/knowledge-area/message-templates");
                            sessionStorage.setItem(
                              "selectedMenu",
                              "/knowledge-area/message-templates"
                            );

                            const wabaProviderName =
                              wabaProvider[
                                bot.bot_channel_info?.WHATSAPP?.waba_provider
                              ]?.providerReference.providerName;

                            // If provider is Dialog360 or Dialog360 Cloud, open the form panel
                            ["dialog360", "DIALOG360-CLOUD"].includes(
                              wabaProviderName
                            )
                              ? dispatchReducer({
                                  type: "OPEN_FORM_PANEL",
                                  payload: {
                                    component: "message-template-form-panel",
                                    title: "Create Template",
                                  },
                                })
                              : window.open(URIs.WABA.concat("/wa/manage/"));

                            dispatchReducer({
                              type: "CLOSE_FORM_PANEL",
                              payload: {
                                component: "broadcast-form-panel",
                              },
                            });
                          }}
                          disabled={
                            !bot?.bot_channel_info.WHATSAPP
                              ?.waba_integrated_onboarding
                          }
                        >
                          Create Template
                        </Button>
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/*Recipient Type - Single or Multiple*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Row>
                  <Col span={1}>
                    <MdPeopleAlt color="#F05742" size={20} />
                  </Col>
                  <Col span={15}>
                    <Text strong>RECIPIENT</Text>
                  </Col>
                  <Col span={8}>
                    <Space>
                      <IoLogoUsd
                        style={{
                          color: "#F05742",
                        }}
                        size={19}
                      />
                      <Text strong>MESSAGE PRICING</Text>
                    </Space>
                  </Col>
                </Row>
                <Row align="top">
                  <Col span={15}>
                    <Form.Item
                      name="recipientType"
                      label="Do you want to send to Single or Multiple recipient?"
                      className="broadcast-form-item"
                      valuePropName="checked"
                    >
                      <Radio.Group
                        onChange={handleRecipientTypeChange}
                        value={recipientType}
                      >
                        <Radio
                          value="single"
                          disabled={populateMultipleContacts}
                        >
                          Single Recipient
                        </Radio>
                        <Radio
                          value="multiple"
                          disabled={populateSingleContact}
                        >
                          Multiple Recipient
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={1}>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>

                  <Col span={8}>
                    <Space size={2} direction="vertical">
                      <Text>Note: Broadcast messages will cost extra.</Text>
                      <Button
                        type="primary"
                        size="middle"
                        onClick={() => {
                          dispatchReducer({
                            type: "OPEN_FORM_PANEL",
                            payload: {
                              component:
                                "broadcast-pricing-calculator-form-panel",
                              title: "Broadcast Pricing Estimator",
                            },
                          });

                          !state.broadcastManager.broadcastPriceList &&
                            dispatchMiddleware({
                              type: "LOAD_BROADCAST_PRICE_LIST",
                              payload: {
                                component:
                                  "broadcast-pricing-calculator-form-panel",
                              },
                            });
                        }}
                      >
                        Estimate Price
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/*Import data from CSV or Contact Manager - APPLICABLE ONLY FOR MULTIPLE RECIPIENTS*/}
            {recipientType === "multiple" && (
              <Col span={24}>
                <Card size="small" className="card-rounded-corner">
                  <Row>
                    <Col span={1}>
                      <BiImport color="#F05742" size={20} />
                    </Col>
                    <Col span={23}>
                      <Text strong>IMPORT DATA</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row gutter={[0, 8]}>
                        <Col span={24}>
                          {populateMultipleContacts
                            ? "Selected contacts from the responses for broadcasting."
                            : "How would you like to import data?"}
                        </Col>
                        {populateMultipleContacts ? (
                          <Col span={9}>
                            <ContactsFromClientResponses
                              handleGetParsedData={handleGetParsedData}
                            />
                          </Col>
                        ) : (
                          <>
                            <Col span={7}>
                              <CSVFileUpload
                                importedDataSource={importedDataSource}
                                handleGetParsedData={handleGetParsedData}
                                handleCancelImport={handleCancelImport}
                                setData={setData}
                                data={data}
                                setColumns={setColumns}
                                columns={columns}
                              />
                            </Col>
                            <Col span={1} className="center-alignment">
                              Or
                            </Col>
                            <Col span={8}>
                              <SelectContact
                                importedDataSource={importedDataSource}
                                handleGetParsedData={handleGetParsedData}
                                handleCancelImport={handleCancelImport}
                              />
                            </Col>
                            <Col span={1} className="center-alignment">
                              Or
                            </Col>
                            <Col span={7}>
                              <ConnectAPISource />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}

            {/*Template Value Mapping*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Row>
                  <Col span={1}>
                    <SiSemaphoreci color="#F05742" size={20} />
                  </Col>
                  <Col span={23}>
                    <Text strong>MAPPING</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row gutter={[24, 8]}>
                      <Col span={24}>
                        Map values to the placeholders in the template
                      </Col>

                      {/* This will be display only if the Template is selected. In case on Multiple Recipient, the data should have been imported to display the mapping options */}
                      {((template &&
                        recipientType === "multiple" &&
                        importedData) ||
                        (template && recipientType === "single")) && (
                        <>
                          {/* Value Mapping Section (LEFT HAND SIDE - 50% of the section) */}
                          <Col span={14}>
                            {/* Template Header - Value Mapping */}
                            <Row gutter={[0, 16]}>
                              {templateHeader && (
                                <Col span={24}>
                                  <Card
                                    title="MESSAGE HEADER"
                                    size="small"
                                    headStyle={{
                                      backgroundColor: "#F4F7F9",
                                      textAlign: "center",
                                    }}
                                  >
                                    {templateHeader.format.toUpperCase() ===
                                    "IMAGE" ? (
                                      <Row gutter={[0, 8]}>
                                        <Col span={24}>Upload an image</Col>
                                        <Col span={24}>
                                          <Form.Item
                                            name={
                                              "messageImage" + broadcastSession
                                            }
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please upload an image",
                                              },
                                            ]}
                                            hasFeedback
                                            className="broadcast-form-item"
                                            valuePropName="fileList"
                                            getValueFromEvent={(e) => {
                                              if (Array.isArray(e)) {
                                                return e;
                                              }
                                              return e && e.fileList;
                                            }}
                                          >
                                            <Upload
                                              beforeUpload={(uploadedImage) => {
                                                uploadedImage.size >
                                                  maxAllowedMediaSize.image &&
                                                  setImage("error");
                                                return (
                                                  uploadedImage.size >
                                                    maxAllowedMediaSize.image &&
                                                  true
                                                );
                                              }}
                                              showUploadList={false}
                                              listType="picture"
                                              maxCount={1}
                                              accept=".jpg, .jpeg, .png"
                                              onChange={handleImageUpload}
                                            >
                                              <Space>
                                                <Button
                                                  icon={<UploadOutlined />}
                                                >
                                                  Choose Image
                                                </Button>
                                                {image !== "error" ? (
                                                  mediaFileName && (
                                                    <Text type="secondary">
                                                      {mediaFileName}
                                                    </Text>
                                                  )
                                                ) : (
                                                  <Text type="danger">
                                                    The maximum size of image
                                                    should be 5 MB
                                                  </Text>
                                                )}
                                              </Space>
                                            </Upload>
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    ) : templateHeader.format.toUpperCase() ===
                                      "TEXT" ? (
                                      <Space>
                                        <b>{templateHeader.text}</b>
                                      </Space>
                                    ) : templateHeader.format.toUpperCase() ===
                                      "VIDEO" ? (
                                      <Row gutter={[0, 8]}>
                                        <Col span={24}>Upload a video</Col>
                                        <Col span={24}>
                                          <Form.Item
                                            name={
                                              "messageVideo" + broadcastSession
                                            }
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please upload a video",
                                              },
                                            ]}
                                            hasFeedback
                                            className="broadcast-form-item"
                                            valuePropName="fileList"
                                            getValueFromEvent={(e) => {
                                              if (Array.isArray(e)) {
                                                return e;
                                              }
                                              return e && e.fileList;
                                            }}
                                          >
                                            <Upload
                                              beforeUpload={(uploadedVideo) => {
                                                uploadedVideo.size >
                                                  maxAllowedMediaSize.video &&
                                                  setVideo("error");
                                                return (
                                                  uploadedVideo.size >
                                                    maxAllowedMediaSize.video &&
                                                  true
                                                );
                                              }}
                                              showUploadList={false}
                                              maxCount={1}
                                              accept=".mp4, .3gpp"
                                              onChange={handleVideoUpload}
                                            >
                                              <Space>
                                                <Button
                                                  icon={<UploadOutlined />}
                                                >
                                                  Choose Video
                                                </Button>
                                                {video !== "error" ? (
                                                  mediaFileName && (
                                                    <Text type="secondary">
                                                      {mediaFileName}
                                                    </Text>
                                                  )
                                                ) : (
                                                  <Text type="danger">
                                                    The maximum size of video
                                                    should be 16 MB
                                                  </Text>
                                                )}
                                              </Space>
                                            </Upload>
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    ) : (
                                      templateHeader.format.toUpperCase() ===
                                        "DOCUMENT" && (
                                        <Row gutter={[0, 8]}>
                                          <Col span={24}>Upload a document</Col>
                                          <Col span={24}>
                                            <Form.Item
                                              name={
                                                "messageDocument" +
                                                broadcastSession
                                              }
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please upload a document",
                                                },
                                              ]}
                                              hasFeedback
                                              className="broadcast-form-item"
                                              valuePropName="fileList"
                                              getValueFromEvent={(e) => {
                                                if (Array.isArray(e)) {
                                                  return e;
                                                }
                                                return e && e.fileList;
                                              }}
                                            >
                                              <Upload
                                                beforeUpload={(
                                                  uploadedDocument
                                                ) => {
                                                  uploadedDocument.size >
                                                    maxAllowedMediaSize.document &&
                                                    setDocument("error");
                                                  return (
                                                    uploadedDocument.size >
                                                      maxAllowedMediaSize.document &&
                                                    true
                                                  );
                                                }}
                                                showUploadList={false}
                                                maxCount={1}
                                                accept=".pdf, .docx. doc"
                                                onChange={handleDocumentUpload}
                                              >
                                                <Space>
                                                  <Button
                                                    icon={<UploadOutlined />}
                                                  >
                                                    Choose Document
                                                  </Button>
                                                  {document !== "error" ? (
                                                    mediaFileName && (
                                                      <Text type="secondary">
                                                        {mediaFileName}
                                                      </Text>
                                                    )
                                                  ) : (
                                                    <Text type="danger">
                                                      The maximum size of
                                                      document should be 100 MB
                                                    </Text>
                                                  )}
                                                </Space>
                                              </Upload>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  </Card>
                                </Col>
                              )}

                              {/* Template Limited Time Offer - Value Mapping */}
                              {templateLimitedTimeOffer?.limited_time_offer
                                ?.has_expiration && (
                                <Col span={24}>
                                  <Card
                                    title="LIMITED TIME OFFER"
                                    size="small"
                                    headStyle={{
                                      backgroundColor: "#F4F7F9",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Row gutter={[0, 8]}>
                                      <Col span={24}>
                                        <Text>
                                          This template contains a limited time
                                          offer expiration. Please select the
                                          date and time for the expiration of
                                          the offer.
                                        </Text>
                                      </Col>

                                      <Col span={24}>
                                        <Form.Item
                                          name={
                                            "limitedTimeOffer" +
                                            broadcastSession
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please select the date and time",
                                            },
                                          ]}
                                          hasFeedback
                                          className="broadcast-form-item"
                                        >
                                          <DatePicker
                                            showTime
                                            format={
                                              state.currentUser.user.dateFormat
                                            }
                                            placeholder="Select expiration date"
                                            style={{
                                              width: "60%",
                                              color: "black",
                                            }}
                                            onChange={(dateString) => {
                                              const dateValue =
                                                dateString.valueOf();

                                              setTemplateLimitedTimeOffer({
                                                ...templateLimitedTimeOffer,
                                                expirationDate: dateValue,
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              )}

                              {/* Template Body - Value Mapping */}
                              {bodyPlaceHolders && (
                                <Col span={24}>
                                  <Card
                                    title="MESSAGE BODY"
                                    size="small"
                                    headStyle={{
                                      backgroundColor: "#F4F7F9",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Row gutter={[0, 8]}>
                                      <Col span={24}>
                                        {recipientType === "multiple" ? (
                                          <Text>
                                            Map from column list or enter the
                                            values for the placeholders below
                                          </Text>
                                        ) : (
                                          <Text>
                                            Enter the values for the
                                            placeholders below
                                          </Text>
                                        )}
                                      </Col>
                                      {bodyPlaceHolders.map(
                                        (bodyPlaceHolder, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <Col
                                                span={2}
                                                className="center-alignment"
                                              >
                                                <Space>
                                                  {"{{" +
                                                    bodyPlaceHolder +
                                                    "}}"}
                                                </Space>
                                              </Col>
                                              <Col
                                                span={1}
                                                className="center-alignment"
                                              >
                                                <Space>:</Space>
                                              </Col>
                                              <Col span={21}>
                                                <Form.Item
                                                  name={
                                                    bodyPlaceHolder +
                                                    broadcastSession
                                                  }
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please map the field",
                                                    },
                                                  ]}
                                                  valuePropName="value"
                                                  hasFeedback
                                                  className="broadcast-form-item"
                                                >
                                                  <AutoComplete
                                                    style={{ width: 300 }}
                                                    onSearch={(val) =>
                                                      handleReplaceBodyPlaceHolder(
                                                        bodyPlaceHolder +
                                                          "~*!*$*~" +
                                                          val
                                                      )
                                                    }
                                                    onSelect={(val) =>
                                                      handleReplaceBodyPlaceHolder(
                                                        bodyPlaceHolder +
                                                          "~*!*$*~" +
                                                          val
                                                      )
                                                    }
                                                    getPopupContainer={(
                                                      trigger
                                                    ) => trigger.parentNode}
                                                    options={
                                                      recipientType ===
                                                        "multiple" &&
                                                      importedColumns.map(
                                                        (column) => {
                                                          return {
                                                            value: column.key,
                                                          };
                                                        }
                                                      )
                                                    }
                                                    filterOption={(
                                                      inputValue,
                                                      option
                                                    ) =>
                                                      option.value
                                                        .toUpperCase()
                                                        .indexOf(
                                                          inputValue.toUpperCase()
                                                        ) !== -1
                                                    }
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Card>
                                </Col>
                              )}

                              {/* Template Carousel - Value Mapping */}
                              {templateCarousel && (
                                <Col span={24}>
                                  <Card
                                    title="CAROUSEL CARDS"
                                    size="small"
                                    headStyle={{
                                      backgroundColor: "#F4F7F9",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Collapse
                                      defaultActiveKey={["0"]}
                                      size="small"
                                      expandIconPosition="start"
                                      accordion
                                      style={{
                                        width: "100%",
                                      }}
                                      className="broadcast-carosal-card"
                                    >
                                      {templateCarousel?.cards?.map(
                                        (card, cardIndex) => {
                                          const headerImage =
                                            carouselCardImages.find(
                                              (image) =>
                                                image.cardIndex === cardIndex
                                            )?.file;

                                          const headerImageName =
                                            headerImage?.name;

                                          return (
                                            <Collapse.Panel
                                              header={`Card ${cardIndex + 1}`}
                                              key={cardIndex}
                                            >
                                              <Row gutter={[0, 8]}>
                                                <Col span={24}>
                                                  <Card
                                                    title="CARD HEADER"
                                                    size="small"
                                                    headStyle={{
                                                      backgroundColor:
                                                        "#F4F7F9",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <Row gutter={[0, 8]}>
                                                      <Col span={24}>
                                                        Upload an image
                                                      </Col>

                                                      <Col span={24}>
                                                        <Form.Item
                                                          name={
                                                            "messageImage" +
                                                            cardIndex
                                                          }
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message:
                                                                "Please upload an image",
                                                            },
                                                          ]}
                                                          hasFeedback
                                                          className="broadcast-form-item"
                                                          valuePropName="fileList"
                                                          getValueFromEvent={(
                                                            e
                                                          ) => {
                                                            if (
                                                              Array.isArray(e)
                                                            ) {
                                                              return e;
                                                            }
                                                            return (
                                                              e && e.fileList
                                                            );
                                                          }}
                                                        >
                                                          <Upload
                                                            beforeUpload={() => {
                                                              return false;
                                                            }}
                                                            showUploadList={
                                                              false
                                                            }
                                                            listType="picture"
                                                            maxCount={1}
                                                            accept=".jpg, .jpeg, .png"
                                                            onChange={(e) => {
                                                              const uploadedFile =
                                                                {
                                                                  file:
                                                                    e.file
                                                                      .size <=
                                                                    maxAllowedMediaSize.image
                                                                      ? e.file
                                                                      : {},
                                                                  cardIndex:
                                                                    cardIndex,
                                                                };

                                                              setCarouselCardImages(
                                                                (
                                                                  prevImages
                                                                ) => {
                                                                  const updatedImages =
                                                                    prevImages.map(
                                                                      (
                                                                        image
                                                                      ) => {
                                                                        if (
                                                                          image.cardIndex ===
                                                                          cardIndex
                                                                        ) {
                                                                          return uploadedFile; // Update existing image
                                                                        }
                                                                        return image;
                                                                      }
                                                                    );

                                                                  // If the image doesn't exist, add it to the list
                                                                  if (
                                                                    !updatedImages.some(
                                                                      (image) =>
                                                                        image.cardIndex ===
                                                                        cardIndex
                                                                    )
                                                                  ) {
                                                                    updatedImages.push(
                                                                      uploadedFile
                                                                    );
                                                                  }

                                                                  return updatedImages;
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <Space>
                                                              <Button
                                                                icon={
                                                                  <UploadOutlined />
                                                                }
                                                              >
                                                                Choose Image
                                                              </Button>
                                                              {headerImage !==
                                                                undefined &&
                                                                (headerImage?.size &&
                                                                headerImage.size <=
                                                                  maxAllowedMediaSize.image ? (
                                                                  headerImageName && (
                                                                    <Text type="secondary">
                                                                      {
                                                                        headerImageName
                                                                      }
                                                                    </Text>
                                                                  )
                                                                ) : (
                                                                  <Text
                                                                    type="danger"
                                                                    style={{
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                  >
                                                                    The max size
                                                                    of image
                                                                    should be 5
                                                                    MB
                                                                  </Text>
                                                                ))}
                                                            </Space>
                                                          </Upload>
                                                        </Form.Item>
                                                      </Col>
                                                    </Row>
                                                  </Card>
                                                </Col>

                                                <Col span={24}>
                                                  <Card
                                                    title="CARD BUTTONS"
                                                    size="small"
                                                    headStyle={{
                                                      backgroundColor:
                                                        "#F4F7F9",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <Row gutter={[0, 8]}>
                                                      <Col span={24}>
                                                        {card.components[2]?.buttons?.some(
                                                          ({ type }) =>
                                                            type ===
                                                            "QUICK_REPLY"
                                                        ) &&
                                                          (bot.wabaBotExists ? (
                                                            <Text>
                                                              Specify any extra
                                                              button values
                                                              needed for the
                                                              bot. If you are
                                                              not sure, check
                                                              with support team.
                                                            </Text>
                                                          ) : (
                                                            <Text>
                                                              This phone number
                                                              does not have a
                                                              bot. You can type
                                                              a reply to send to
                                                              users when they
                                                              select the
                                                              buttons.
                                                            </Text>
                                                          ))}
                                                      </Col>
                                                      {card.components.map(
                                                        (component) => {
                                                          return (
                                                            component.type ===
                                                              "BUTTONS" &&
                                                            component.buttons.map(
                                                              (
                                                                button,
                                                                buttonIndex
                                                              ) => {
                                                                return (
                                                                  <Col
                                                                    span={24}
                                                                    key={
                                                                      buttonIndex
                                                                    }
                                                                  >
                                                                    <Card size="small">
                                                                      <Row
                                                                        gutter={[
                                                                          0, 8,
                                                                        ]}
                                                                        align="middle"
                                                                      >
                                                                        <Col
                                                                          span={
                                                                            6
                                                                          }
                                                                        >
                                                                          <Text
                                                                            strong
                                                                          >
                                                                            Button:
                                                                          </Text>
                                                                        </Col>
                                                                        <Col
                                                                          span={
                                                                            18
                                                                          }
                                                                        >
                                                                          <Space
                                                                            size={
                                                                              5
                                                                            }
                                                                          >
                                                                            {
                                                                              button.text
                                                                            }
                                                                          </Space>
                                                                        </Col>

                                                                        <Col
                                                                          span={
                                                                            6
                                                                          }
                                                                        >
                                                                          <Text
                                                                            strong
                                                                          >
                                                                            {button.type ===
                                                                            "PHONE_NUMBER"
                                                                              ? "Phone:"
                                                                              : button.type ===
                                                                                "URL"
                                                                              ? "URL:"
                                                                              : button.type ===
                                                                                  "QUICK_REPLY" &&
                                                                                (bot.wabaBotExists
                                                                                  ? "Payload:"
                                                                                  : "Reply:")}
                                                                          </Text>
                                                                        </Col>

                                                                        <Col
                                                                          span={
                                                                            18
                                                                          }
                                                                        >
                                                                          {button.type ===
                                                                          "URL" ? (
                                                                            <Input
                                                                              disabled
                                                                              value={
                                                                                button.url
                                                                              }
                                                                            />
                                                                          ) : button.type ===
                                                                            "PHONE_NUMBER" ? (
                                                                            <Input
                                                                              disabled
                                                                              value={
                                                                                button.phone_number
                                                                              }
                                                                            />
                                                                          ) : (
                                                                            <Form.Item
                                                                              name={
                                                                                "carouselCardButton" +
                                                                                cardIndex +
                                                                                buttonIndex +
                                                                                broadcastSession
                                                                              }
                                                                              valuePropName="value"
                                                                              hasFeedback
                                                                              className="broadcast-form-item"
                                                                              rules={
                                                                                !bot.wabaBotExists
                                                                                  ? [
                                                                                      {
                                                                                        required: true,
                                                                                        message:
                                                                                          "Please select a reply message for the button",
                                                                                      },
                                                                                    ]
                                                                                  : [
                                                                                      {
                                                                                        required: true,
                                                                                        message:
                                                                                          "Please enter product name for the button",
                                                                                      },
                                                                                    ]
                                                                              }
                                                                            >
                                                                              <AutoComplete
                                                                                style={{
                                                                                  width:
                                                                                    "100%",
                                                                                }}
                                                                                allowClear
                                                                                getPopupContainer={(
                                                                                  trigger
                                                                                ) =>
                                                                                  trigger.parentNode
                                                                                }
                                                                                options={
                                                                                  !bot?.wabaBotExists
                                                                                    ? state.automatedReplies.broadcastAutomatedReplies.map(
                                                                                        (
                                                                                          column
                                                                                        ) => {
                                                                                          return {
                                                                                            label:
                                                                                              column.replyMessageValue,
                                                                                            value:
                                                                                              column.replyMessageValue,
                                                                                          };
                                                                                        }
                                                                                      )
                                                                                    : null
                                                                                }
                                                                                filterOption={(
                                                                                  inputValue,
                                                                                  option
                                                                                ) =>
                                                                                  option.value
                                                                                    .toUpperCase()
                                                                                    .indexOf(
                                                                                      inputValue.toUpperCase()
                                                                                    ) !==
                                                                                  -1
                                                                                }
                                                                              />
                                                                            </Form.Item>
                                                                          )}
                                                                        </Col>
                                                                      </Row>
                                                                    </Card>
                                                                  </Col>
                                                                );
                                                              }
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </Row>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            </Collapse.Panel>
                                          );
                                        }
                                      )}
                                    </Collapse>
                                  </Card>
                                </Col>
                              )}

                              {/* Template Footer - Value Mapping */}
                              {templateFooter && (
                                <Col span={24}>
                                  <Card
                                    title="MESSAGE FOOTER"
                                    size="small"
                                    headStyle={{
                                      backgroundColor: "#F4F7F9",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Space>{templateFooter.text}</Space>
                                  </Card>
                                </Col>
                              )}

                              {/* Template Action Buttons - Value Mapping */}
                              {templateButtons && (
                                <Col span={24}>
                                  <Card
                                    title="MESSAGE BUTTONS"
                                    size="small"
                                    headStyle={{
                                      backgroundColor: "#F4F7F9",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Row gutter={[0, 8]}>
                                      <Col span={24}>
                                        {templateButtons.buttons.every(
                                          ({ type }) => type === "QUICK_REPLY"
                                        ) ? (
                                          <>
                                            {bot.wabaBotExists ? (
                                              <Text>
                                                Please specify if there any
                                                additional value that needs to
                                                be passed to the bot, for each
                                                button. If you are not sure,
                                                please check with the support
                                                team.
                                              </Text>
                                            ) : (
                                              <Text>
                                                This phone number does not have
                                                a Bot. You can type or select an
                                                automated reply to send to user
                                                when they select the buttons.
                                              </Text>
                                            )}
                                          </>
                                        ) : templateButtons.buttons.every(
                                            ({ type }) => type === "CATALOG"
                                          ) ? (
                                          <Text>
                                            This template links to a catalog.
                                            The item's thumbnail acts as the
                                            message's header image. Please
                                            provide a product ID that you wish
                                            to be displayed as the thumbnail.
                                            Without a product ID, the first
                                            item's product image will be used by
                                            default.{" "}
                                            <Link
                                              href={`${URIs.WABA}/commerce`}
                                              target="_blank"
                                            >
                                              Obtain Product ID
                                            </Link>
                                          </Text>
                                        ) : templateButtons.buttons[0]?.type?.toUpperCase() ===
                                          "COPY_CODE" ? (
                                          <Text>
                                            Enter the code to be copied to the
                                            user's clipboard.
                                          </Text>
                                        ) : null}
                                      </Col>

                                      {templateButtons.buttons.map(
                                        (button, index) => {
                                          // For LTO template without expiration scenario we are not getting the button text from meta.
                                          // So, we are setting the button text as "Copy offer code".
                                          if (
                                            button.type.toUpperCase() ===
                                            "COPY_CODE"
                                          ) {
                                            button.text =
                                              button.text || "Copy offer code";
                                          }

                                          return (
                                            <Card
                                              type="inner"
                                              style={{ width: "100%" }}
                                              key={index}
                                            >
                                              <Col span={24}>
                                                <Row
                                                  gutter={[0, 8]}
                                                  align="middle"
                                                >
                                                  <Col span={6}>
                                                    <Text strong>Button:</Text>
                                                  </Col>
                                                  <Col span={18}>
                                                    <Space size={5}>
                                                      {button.text}
                                                    </Space>
                                                  </Col>

                                                  <Col span={6}>
                                                    <Text strong>
                                                      {button.type ===
                                                      "PHONE_NUMBER"
                                                        ? "Phone:"
                                                        : button.type === "URL"
                                                        ? "URL:"
                                                        : button.type ===
                                                          "CATALOG"
                                                        ? "Product Id:"
                                                        : button.type?.toUpperCase() ===
                                                          "COPY_CODE"
                                                        ? "Code:"
                                                        : button.type === "FLOW"
                                                        ? "Flow Name:"
                                                        : button.type ===
                                                            "QUICK_REPLY" &&
                                                          (bot.wabaBotExists
                                                            ? "Payload:"
                                                            : "Reply:")}
                                                    </Text>
                                                  </Col>

                                                  <Col span={18}>
                                                    {button.type === "URL" ? (
                                                      <Input
                                                        disabled
                                                        value={
                                                          urlWithPlaceHoldersPreview ??
                                                          button.url
                                                        }
                                                      />
                                                    ) : button.type ===
                                                      "PHONE_NUMBER" ? (
                                                      <Input
                                                        disabled
                                                        value={
                                                          button.phone_number
                                                        }
                                                      />
                                                    ) : button.type ===
                                                      "FLOW" ? (
                                                      <Input
                                                        disabled
                                                        value={
                                                          state.whatsappFlows?.flows?.find(
                                                            (flow) =>
                                                              flow.id ===
                                                              button.flow_id
                                                          )?.name ||
                                                          button.flow_id
                                                        }
                                                      />
                                                    ) : (
                                                      <Form.Item
                                                        name={
                                                          "button" +
                                                          index +
                                                          broadcastSession
                                                        }
                                                        valuePropName="value"
                                                        hasFeedback
                                                        className="broadcast-form-item"
                                                        rules={
                                                          button.type.toUpperCase() ===
                                                          "COPY_CODE"
                                                            ? [
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "Please enter the offer code",
                                                                },
                                                              ]
                                                            : !bot.wabaBotExists
                                                            ? [
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "Please select a reply message for the button",
                                                                },
                                                                {
                                                                  max: 40,
                                                                  message:
                                                                    "Reply message should not more than 40 characters",
                                                                },
                                                              ]
                                                            : []
                                                        }
                                                      >
                                                        <AutoComplete
                                                          style={{
                                                            width: 225,
                                                          }}
                                                          onSearch={(val) =>
                                                            handleReplaceButtonPlaceHolder(
                                                              button.text +
                                                                "~*!*$*~" +
                                                                val
                                                            )
                                                          }
                                                          onSelect={(val) =>
                                                            handleReplaceButtonPlaceHolder(
                                                              button.text +
                                                                "~*!*$*~" +
                                                                val
                                                            )
                                                          }
                                                          allowClear
                                                          getPopupContainer={(
                                                            trigger
                                                          ) =>
                                                            trigger.parentNode
                                                          }
                                                          options={
                                                            button.type.toUpperCase() ===
                                                              "COPY_CODE" ||
                                                            button.type ===
                                                              "CATALOG"
                                                              ? null
                                                              : !bot?.wabaBotExists
                                                              ? state.automatedReplies.broadcastAutomatedReplies.map(
                                                                  (column) => {
                                                                    return {
                                                                      label:
                                                                        column.replyMessageValue,
                                                                      value:
                                                                        column.replyMessageValue,
                                                                    };
                                                                  }
                                                                )
                                                              : recipientType !==
                                                                  "single" &&
                                                                importedColumns.map(
                                                                  (column) => {
                                                                    return {
                                                                      value:
                                                                        column.key,
                                                                    };
                                                                  }
                                                                )
                                                          }
                                                          filterOption={(
                                                            inputValue,
                                                            option
                                                          ) =>
                                                            option.value
                                                              .toUpperCase()
                                                              .indexOf(
                                                                inputValue.toUpperCase()
                                                              ) !== -1
                                                          }
                                                          maxLength={
                                                            button.type.toUpperCase() ===
                                                            "COPY_CODE"
                                                              ? 15
                                                              : 40
                                                          }
                                                        />
                                                      </Form.Item>
                                                    )}
                                                  </Col>

                                                  {urlPlaceHolders?.length >
                                                    0 &&
                                                    button.type === "URL" && (
                                                      <>
                                                        <Col span={24}>
                                                          {bot.wabaBotExists ? (
                                                            <Text>
                                                              This template
                                                              contains URL with
                                                              a parameter.
                                                              Please specify or
                                                              choose the value
                                                              that should be
                                                              appended to the
                                                              URL.
                                                            </Text>
                                                          ) : (
                                                            <Text>
                                                              This template
                                                              contains URL with
                                                              a parameter.
                                                              Please specify the
                                                              value that should
                                                              be appended to the
                                                              URL.
                                                            </Text>
                                                          )}
                                                        </Col>

                                                        {urlPlaceHolders?.map(
                                                          (
                                                            urlPlaceHolder,
                                                            index
                                                          ) => {
                                                            return (
                                                              <React.Fragment
                                                                key={index}
                                                              >
                                                                <Col span={6}>
                                                                  <Text strong>
                                                                    {"{{" +
                                                                      urlPlaceHolder +
                                                                      "}}:"}
                                                                  </Text>
                                                                </Col>
                                                                <Col span={18}>
                                                                  <Form.Item
                                                                    name={
                                                                      "button" +
                                                                      index +
                                                                      broadcastSession
                                                                    }
                                                                    valuePropName="value"
                                                                    hasFeedback
                                                                    className="broadcast-form-item"
                                                                    rules={[
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                          "Please provide the value you want to pass it to the variable",
                                                                      },
                                                                    ]}
                                                                  >
                                                                    <AutoComplete
                                                                      style={{
                                                                        width: 290,
                                                                      }}
                                                                      onChange={(
                                                                        val
                                                                      ) =>
                                                                        handleReplaceUrlPlaceHolder(
                                                                          index +
                                                                            1 +
                                                                            "~*!*$*~" +
                                                                            val
                                                                        )
                                                                      }
                                                                      allowClear
                                                                    />
                                                                  </Form.Item>
                                                                </Col>
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    )}
                                                </Row>
                                              </Col>
                                            </Card>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Card>
                                </Col>
                              )}
                            </Row>
                          </Col>

                          {/* Preview Section (RIGHT HAND SIDE - 50% of the section) */}
                          <Col span={10}>
                            <Row gutter={[0, 16]}>
                              <Col span={24}>
                                <Space
                                  style={{
                                    backgroundImage:
                                      "url(/images/whatsapp-bg.png)",
                                    padding: 15,
                                    borderRadius: 15,
                                  }}
                                >
                                  <Row gutter={[0, 8]}>
                                    <Col
                                      span={24}
                                      style={{ textAlign: "center" }}
                                    >
                                      <Tag color="green">MESSAGE PREVIEW</Tag>
                                    </Col>
                                    <Col span={24}>
                                      <Row>
                                        <Col
                                          span={22}
                                          offset={1}
                                          style={{
                                            width: "100%",
                                            background: "#FFFFFF",
                                            padding: 15,
                                            borderRadius: 15,
                                            fontSize: 12,
                                          }}
                                        >
                                          <Space direction="vertical">
                                            {templateHeader &&
                                              templateHeader.format ===
                                                "TEXT" && (
                                                <Text strong>
                                                  {templateHeader.text}
                                                </Text>
                                              )}
                                            {templateHeader &&
                                              templateHeader.format ===
                                                "IMAGE" && (
                                                <Image
                                                  src={
                                                    // if broadcastVersion 4 means setting the image from the state
                                                    bot?.bot_channel_info
                                                      ?.WHATSAPP
                                                      ?.waba_broadcast_version ===
                                                      4 &&
                                                    typeof image === "object"
                                                      ? URL.createObjectURL(
                                                          image
                                                        )
                                                      : image
                                                  }
                                                  width="100%"
                                                  preview={false}
                                                />
                                              )}
                                            {templateHeader &&
                                              templateHeader.format ===
                                                "VIDEO" && (
                                                <video
                                                  width="100%"
                                                  controls
                                                  src={
                                                    bot?.bot_channel_info
                                                      ?.WHATSAPP
                                                      ?.waba_broadcast_version ===
                                                      4 && video
                                                      ? URL.createObjectURL(
                                                          video
                                                        )
                                                      : video
                                                  }
                                                />
                                              )}
                                            {templateHeader &&
                                              templateHeader.format ===
                                                "DOCUMENT" && (
                                                <Image
                                                  width="100%"
                                                  controls
                                                  src="/images/document-placeholder.png"
                                                />
                                              )}

                                            {templateLimitedTimeOffer && (
                                              <Space
                                                style={{
                                                  backgroundColor: "#f5f5f5",
                                                  width: "100%",
                                                  padding: 4,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    borderRadius: "70%",
                                                    padding: 7,
                                                    backgroundColor: "#d2ede4",
                                                  }}
                                                >
                                                  <MdCardGiftcard
                                                    size={20}
                                                    style={{
                                                      color: "#16b490",
                                                      marginBottom: -3,
                                                    }}
                                                  />
                                                </div>

                                                <Space
                                                  direction="vertical"
                                                  style={{ width: "100%" }}
                                                  size={0}
                                                >
                                                  <Text
                                                    strong
                                                    style={{
                                                      fontSize: 12,
                                                    }}
                                                  >
                                                    {
                                                      templateLimitedTimeOffer
                                                        ?.limited_time_offer
                                                        ?.text
                                                    }
                                                  </Text>

                                                  {templateLimitedTimeOffer
                                                    ?.limited_time_offer
                                                    ?.has_expiration && (
                                                    <Text
                                                      style={{
                                                        fontSize: 12,
                                                      }}
                                                      type="secondary"
                                                    >
                                                      {(templateLimitedTimeOffer?.expirationDate &&
                                                        "Ends in " +
                                                          calculateDateTimeToBeDisplayed(
                                                            moment(
                                                              templateLimitedTimeOffer?.expirationDate
                                                            ).format(
                                                              "YYYY-MM-DD HH:mm:ss"
                                                            ),
                                                            state.currentUser
                                                              .user.timeZone
                                                          )) ||
                                                        "Expiration timer goes here"}
                                                    </Text>
                                                  )}

                                                  {templateButtons?.buttons[0]?.type?.toUpperCase() ===
                                                    "COPY_CODE" &&
                                                    templateButtons?.buttons[0]
                                                      ?.example?.length && (
                                                      <Text
                                                        type="secondary"
                                                        style={{
                                                          fontSize: 12,
                                                        }}
                                                      >
                                                        {(buttonMapping?.[
                                                          "Copy offer code"
                                                        ] &&
                                                          "Code " +
                                                            buttonMapping[
                                                              "Copy offer code"
                                                            ]) ||
                                                          "Offer code goes here"}
                                                      </Text>
                                                    )}
                                                </Space>
                                              </Space>
                                            )}

                                            <Space>{formatText(preview)}</Space>

                                            {templateFooter && (
                                              <Space>
                                                {templateFooter.text}
                                              </Space>
                                            )}
                                          </Space>
                                          <Space
                                            style={{
                                              color: "#909090",
                                              justifyContent: "right",
                                              fontSize: 10,
                                              width: "100%",
                                            }}
                                          >
                                            10:10 AM
                                          </Space>
                                        </Col>
                                      </Row>
                                    </Col>

                                    {templateButtons && (
                                      <>
                                        {templateButtons.buttons
                                          ?.filter((_, index) => index < 2)

                                          .map((button, index) => {
                                            return (
                                              <Col
                                                span={22}
                                                key={index}
                                                offset={1}
                                              >
                                                <Space
                                                  key={index}
                                                  className="whatsapp-button"
                                                  onClick={() =>
                                                    button.type === "URL" &&
                                                    window.open(
                                                      urlWithPlaceHoldersPreview ??
                                                        button.url
                                                    )
                                                  }
                                                  style={
                                                    button.type === "URL" && {
                                                      cursor: "pointer",
                                                    }
                                                  }
                                                >
                                                  {button.type ===
                                                    "PHONE_NUMBER" && (
                                                    <MdCall
                                                      size={15}
                                                      style={{
                                                        marginBottom: -3,
                                                      }}
                                                    />
                                                  )}

                                                  {button.type === "URL" && (
                                                    <HiOutlineExternalLink
                                                      size={17}
                                                      style={{
                                                        marginBottom: -5,
                                                      }}
                                                    />
                                                  )}

                                                  {button.type.toUpperCase() ===
                                                    "COPY_CODE" && (
                                                    <MdContentCopy
                                                      size={16}
                                                      style={{
                                                        marginBottom: -2,
                                                      }}
                                                    />
                                                  )}

                                                  {button.type?.toUpperCase() ===
                                                  "COPY_CODE"
                                                    ? "Copy offer code"
                                                    : button.text}
                                                </Space>
                                              </Col>
                                            );
                                          })}

                                        {templateButtons?.buttons?.length >
                                          2 && (
                                          <Tooltip title="By clicking this button, user can see all the buttons in their WhatsApp.">
                                            <Col span={22} offset={1}>
                                              <Space
                                                className="whatsapp-button"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <MdOutlineFormatListBulleted
                                                  size={15}
                                                  style={{
                                                    marginBottom: -3,
                                                  }}
                                                />
                                                See all options
                                              </Space>
                                            </Col>
                                          </Tooltip>
                                        )}
                                      </>
                                    )}

                                    {templateCarousel && (
                                      <Col span={22} offset={1}>
                                        <Carousel
                                          dotPosition="top"
                                          autoplay
                                          style={{
                                            width: 270,
                                            padding: 0,
                                            borderRadius: 15,
                                          }}
                                        >
                                          {templateCarousel.cards.map(
                                            (card, index) => {
                                              return (
                                                <Row
                                                  className="carousel-template-card"
                                                  key={index}
                                                >
                                                  <Col span={24}>
                                                    {card?.components?.[0]
                                                      .type === "HEADER" &&
                                                      card?.components?.[0]
                                                        .format === "IMAGE" && (
                                                        <Image
                                                          src={
                                                            carouselCardImages.find(
                                                              (image) =>
                                                                image.cardIndex ===
                                                                index
                                                            )?.file &&
                                                            Object.keys(
                                                              carouselCardImages.find(
                                                                (image) =>
                                                                  image.cardIndex ===
                                                                  index
                                                              )?.file
                                                            ).length
                                                              ? URL.createObjectURL(
                                                                  carouselCardImages.find(
                                                                    (image) =>
                                                                      image.cardIndex ===
                                                                      index
                                                                  )?.file
                                                                )
                                                              : "/images/image-placeholder.png"
                                                          }
                                                          width="100%"
                                                          style={{
                                                            borderRadius: 15,
                                                            padding: 5,
                                                          }}
                                                          preview={false}
                                                        />
                                                      )}
                                                  </Col>

                                                  <Col span={24}>
                                                    {card.components?.[1]
                                                      .type === "BODY" && (
                                                      <Space
                                                        style={{
                                                          fontSize: 12,
                                                          padding: "5px 6px",
                                                        }}
                                                      >
                                                        {formatText(
                                                          card?.components?.[1]
                                                            ?.text
                                                        )}
                                                      </Space>
                                                    )}
                                                  </Col>

                                                  <Col span={24}>
                                                    {card.components?.[2]
                                                      .type === "BUTTONS" && (
                                                      <Space
                                                        direction="vertical"
                                                        style={{
                                                          width: "100%",
                                                          justifyContent:
                                                            "center",
                                                          padding: "5px 5px",
                                                        }}
                                                        size={0}
                                                      >
                                                        {card.components?.[2].buttons.map(
                                                          (
                                                            button,
                                                            buttonIndex
                                                          ) => {
                                                            return (
                                                              <Space
                                                                key={
                                                                  buttonIndex
                                                                }
                                                                style={{
                                                                  width: "100%",
                                                                  cursor:
                                                                    "pointer",
                                                                  justifyContent:
                                                                    "center",
                                                                  padding:
                                                                    "5px 5px",
                                                                  background:
                                                                    "#ffffff",
                                                                  color:
                                                                    "#3d8af7",
                                                                  borderTop:
                                                                    "1px solid #f0f0f0",
                                                                  fontSize: 13,
                                                                }}
                                                              >
                                                                {button.type ===
                                                                  "URL" && (
                                                                  <HiOutlineExternalLink
                                                                    size={17}
                                                                    style={{
                                                                      marginBottom:
                                                                        -5,
                                                                    }}
                                                                  />
                                                                )}

                                                                {button.type ===
                                                                  "PHONE_NUMBER" && (
                                                                  <MdCall
                                                                    size={15}
                                                                    style={{
                                                                      marginBottom:
                                                                        -3,
                                                                    }}
                                                                  />
                                                                )}

                                                                {button.type ===
                                                                  "QUICK_REPLY" && (
                                                                  <MdReply
                                                                    size={15}
                                                                    style={{
                                                                      marginBottom:
                                                                        -4,
                                                                    }}
                                                                  />
                                                                )}

                                                                <Space
                                                                  style={{
                                                                    marginTop: 5,
                                                                  }}
                                                                >
                                                                  {button.text}
                                                                </Space>
                                                              </Space>
                                                            );
                                                          }
                                                        )}
                                                      </Space>
                                                    )}
                                                  </Col>
                                                </Row>
                                              );
                                            }
                                          )}
                                        </Carousel>
                                      </Col>
                                    )}
                                  </Row>
                                </Space>
                              </Col>

                              {recipientType === "multiple" && (
                                <Col span={24} className="center-alignment">
                                  <Space>
                                    <Button
                                      type="primary"
                                      disabled={currentRecord === 0}
                                      onClick={() => {
                                        setCurrentRecord(0);
                                      }}
                                      icon={
                                        <MdFirstPage style={{ margin: -2 }} />
                                      }
                                    />
                                    <Button
                                      type="primary"
                                      disabled={currentRecord === 0}
                                      onClick={() => {
                                        setCurrentRecord(currentRecord - 1);
                                      }}
                                      icon={
                                        <MdNavigateBefore
                                          style={{ margin: -2 }}
                                        />
                                      }
                                    />
                                    {currentRecord + 1} of
                                    {importedData.length}
                                    <Button
                                      type="primary"
                                      disabled={
                                        currentRecord + 1 ===
                                        importedData.length
                                      }
                                      onClick={() => {
                                        setCurrentRecord(currentRecord + 1);
                                      }}
                                      icon={
                                        <MdNavigateNext
                                          style={{ margin: -2 }}
                                        />
                                      }
                                    />
                                    <Button
                                      type="primary"
                                      disabled={
                                        currentRecord + 1 ===
                                        importedData.length
                                      }
                                      onClick={() => {
                                        setCurrentRecord(
                                          importedData.length - 1
                                        );
                                      }}
                                      icon={
                                        <MdLastPage style={{ margin: -2 }} />
                                      }
                                    />
                                  </Space>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </>
                      )}

                      {!template &&
                        recipientType === "multiple" &&
                        !importedData && (
                          <Validation
                            validationMessage={[
                              "Select template in 'MESSAGE' section above",
                              "Also import data for multiple recipients in 'IMPORT DATA' section above",
                            ]}
                          />
                        )}
                      {template &&
                        recipientType === "multiple" &&
                        !importedData && (
                          <Validation
                            validationMessage={[
                              "Import data for multiple recipients in 'IMPORT DATA' section above",
                            ]}
                          />
                        )}
                      {!template &&
                        recipientType === "multiple" &&
                        importedData && (
                          <Validation
                            validationMessage={[
                              "Select template in 'MESSAGE' section above",
                            ]}
                          />
                        )}
                      {!template && recipientType === "single" && (
                        <Validation
                          validationMessage={[
                            "Select template in 'MESSAGE' section above",
                          ]}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/*Recipient Phone Number*/}
            <Col span={24}>
              <Card size="small" className="card-rounded-corner">
                <Row>
                  <Col span={1}>
                    <MdPermPhoneMsg color="#F05742" size={20} />
                  </Col>
                  <Col span={23}>
                    <Text strong>RECIPIENT PHONE NUMBER</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        {recipientType === "multiple" && (
                          <Space>
                            <Text>
                              Map recipient's phone number from column list.{" "}
                              <Text strong>
                                Please ensure that the phone number column in
                                CSV file has the country code starting with "+"
                              </Text>
                            </Text>
                          </Space>
                        )}
                        {recipientType === "single" && (
                          <Text>Enter the recipient phone number</Text>
                        )}
                      </Col>

                      {((template &&
                        recipientType === "multiple" &&
                        importedData) ||
                        (template && recipientType === "single")) && (
                        <Col span={24}>
                          <Row gutter={8}>
                            {recipientType === "single" && (
                              <Col span={6}>
                                <Form.Item
                                  name="countryCode"
                                  label="Country Code"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the country code",
                                    },
                                  ]}
                                  hasFeedback
                                  className="broadcast-form-item"
                                >
                                  <Select
                                    showSearch
                                    options={countryDailingCodes}
                                    style={{ width: 200 }}
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                    placeholder="Select country code"
                                    optionFilterProp="label"
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        ?.includes(input.toLowerCase())
                                    }
                                    onChange={(_, { label }) => {
                                      setRecipientCountry(label);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            <Col span={recipientType === "single" ? 18 : 24}>
                              {recipientType === "multiple" && (
                                <Row align="middle">
                                  <Col span={10}>
                                    <Form.Item
                                      name={"phoneNumber" + broadcastSession}
                                      label="Phone Number"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please map the phone number field",
                                        },
                                      ]}
                                      valuePropName="value"
                                      hasFeedback
                                      className="broadcast-form-item"
                                    >
                                      <Select
                                        showSearch
                                        style={{ width: 300 }}
                                        onChange={(val) =>
                                          handleReplaceRecipientPhoneNumberPlaceHolder(
                                            "phone~*!*$*~" + val
                                          )
                                        }
                                        getPopupContainer={(trigger) =>
                                          trigger.parentNode
                                        }
                                        placeholder="Select phone number field"
                                        optionFilterProp="children"
                                        filterOption={(inputValue, option) =>
                                          option.value
                                            .toUpperCase()
                                            .indexOf(
                                              inputValue.toUpperCase()
                                            ) !== -1
                                        }
                                        filterSort={(optionA, optionB) =>
                                          optionA.children
                                            .toLowerCase()
                                            .localeCompare(
                                              optionB.children.toLowerCase()
                                            )
                                        }
                                      >
                                        {importedColumns.map(
                                          (column, index) => {
                                            return (
                                              <Option
                                                value={column.key}
                                                key={index}
                                              >
                                                {column.key}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </Col>

                                  <Col span={13}>
                                    {recipientPhoneNumberMapping &&
                                      (calculateMultiRecipientBroadcastPrice() >
                                      0 ? (
                                        <Text
                                          style={{
                                            fontSize: 13,
                                          }}
                                        >
                                          Estimated price for sending 1
                                          {" " +
                                            template.category?.toLowerCase()}{" "}
                                          message to
                                          {"  " +
                                            (validRecipientsPhoneNumber() || [])
                                              .length +
                                            " "}
                                          valid recipients is:{" "}
                                          <Text strong>
                                            {"US$ " +
                                              calculateMultiRecipientBroadcastPrice()}
                                          </Text>
                                        </Text>
                                      ) : priceNotUpdatedRecipients().length >
                                        0 ? (
                                        <Text
                                          style={{
                                            fontSize: 13,
                                          }}
                                        >
                                          {`Price for ${priceNotUpdatedRecipients()
                                            .map(
                                              ({ countryCode }) => countryCode
                                            )
                                            .join(", ")} is not available. `}
                                          <Link
                                            onClick={async () => {
                                              window.open(
                                                "mailto:support@inextlabs.com?subject=" +
                                                  "Broadcast Manager - Recipient Country Price Request&body=Hi, \n\nI would like to request for the price of " +
                                                  priceNotUpdatedRecipients()
                                                    .map(
                                                      ({ countryCode }) =>
                                                        countryCode
                                                    )
                                                    .join(", ") +
                                                  " for Broadcast Manager."
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            Contact support
                                          </Link>{" "}
                                          team for more details.
                                        </Text>
                                      ) : (
                                        <Text
                                          type="danger"
                                          style={{
                                            fontSize: 13,
                                          }}
                                        >
                                          Please ensure that selected column has
                                          a valid phone number with country
                                          code. Ex: +6597799776
                                        </Text>
                                      ))}
                                  </Col>
                                </Row>
                              )}
                              {recipientType === "single" && (
                                <Row align="middle">
                                  <Col span={13}>
                                    <Form.Item
                                      name={"phoneNumber" + broadcastSession}
                                      label="Phone Number"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please map the phone number field",
                                        },
                                      ]}
                                      valuePropName="value"
                                      hasFeedback
                                      className="broadcast-form-item"
                                    >
                                      <Input
                                        style={{ width: 300 }}
                                        onChange={(e) => {
                                          handleReplaceRecipientPhoneNumberPlaceHolder(
                                            "phone~*!*$*~" + e.target.value
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={11}>
                                    {recipientCountry &&
                                      (state.broadcastManager.broadcastPriceList.find(
                                        ({ country }) =>
                                          country === recipientCountry
                                      ) ? (
                                        <Text
                                          style={{
                                            fontSize: 13,
                                          }}
                                        >
                                          Estimated price for sending 1{" "}
                                          {template.category?.toLowerCase()}{" "}
                                          message to {recipientCountry} is:{" "}
                                          <Text strong>
                                            {"US$ " +
                                              state.broadcastManager.broadcastPriceList
                                                .find(
                                                  ({ country }) =>
                                                    country === recipientCountry
                                                )
                                                [
                                                  template.category ===
                                                  "MARKETING"
                                                    ? "marketing"
                                                    : "utility"
                                                ].toFixed(4)}
                                          </Text>
                                        </Text>
                                      ) : (
                                        <Text
                                          style={{
                                            paddingTop: 5,
                                            fontSize: 13,
                                          }}
                                        >
                                          {`Price for ${recipientCountry} is not available. `}
                                          <Link
                                            onClick={async () => {
                                              window.open(
                                                "mailto:support@inextlabs.com?subject=" +
                                                  "Broadcast Manager - Recipient Country Price Request&body=Hi, \n\nI would like to request for the price of " +
                                                  recipientCountry +
                                                  " for Broadcast Manager."
                                              );
                                            }}
                                            target="_blank"
                                          >
                                            Contact support
                                          </Link>{" "}
                                          team for more details.
                                        </Text>
                                      ))}
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {!template &&
                        recipientType === "multiple" &&
                        !importedData && (
                          <Validation
                            validationMessage={[
                              "Select template in 'MESSAGE' section above",
                              "Also import data for multiple recipients in 'IMPORT DATA' section above",
                            ]}
                          />
                        )}
                      {template &&
                        recipientType === "multiple" &&
                        !importedData && (
                          <Validation
                            validationMessage={[
                              "Import data for multiple recipients in 'IMPORT DATA' section above",
                            ]}
                          />
                        )}
                      {!template &&
                        recipientType === "multiple" &&
                        importedData && (
                          <Validation
                            validationMessage={[
                              "Select template in 'MESSAGE' section above",
                            ]}
                          />
                        )}
                      {!template && recipientType === "single" && (
                        <Validation
                          validationMessage={[
                            "Select template in 'MESSAGE' section above",
                          ]}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Action Buttons */}
            <Col span={24}>
              <Divider />
              <Row>
                <Col span={12}>
                  <Space size="small">
                    <Form.Item>
                      <Button onClick={resetFormFields}>Cancel</Button>
                    </Form.Item>

                    <Form.Item>
                      <Tooltip
                        placement="top"
                        title={
                          state.currentUser.permission.BROADCAST_DOWNLOAD_JSON
                            ? "Download JSON File"
                            : "You do not have required permission"
                        }
                      >
                        <Button
                          htmlType="submit"
                          disabled={
                            !state.currentUser.permission
                              .BROADCAST_DOWNLOAD_JSON
                          }
                          onClick={() => setSubmitType("json-download")}
                        >
                          Download JSON File
                        </Button>
                      </Tooltip>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => setSubmitType("start-broadcast")}
                        disabled={
                          state.currentAccount.account.subscription_info
                            ?.subscription_ids?.length
                            ? whatsappUsage
                              ? recipientType === "single"
                                ? recipientCountry &&
                                  (state.broadcastManager.broadcastPriceList.find(
                                    ({ country }) =>
                                      country === recipientCountry
                                  )
                                    ? state.broadcastManager.broadcastPriceList
                                        .find(
                                          ({ country }) =>
                                            country === recipientCountry
                                        )
                                        [
                                          template.category === "MARKETING"
                                            ? "marketing"
                                            : "utility"
                                        ].toFixed(4) >
                                      whatsappUsage.currentBalance
                                    : true)
                                : priceNotUpdatedRecipients()?.length === 0
                                ? calculateMultiRecipientBroadcastPrice() >
                                  whatsappUsage.currentBalance
                                : true
                              : true
                            : false
                        }
                      >
                        Start Broadcast
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>

                {/* Info text for pre-paid broadcast */}
                {state.currentAccount.account.subscription_info
                  ?.subscription_ids?.length && (
                  <Col span={12}>
                    {whatsappUsage ? (
                      (calculateMultiRecipientBroadcastPrice() >
                        whatsappUsage.currentBalance ||
                        (recipientCountry &&
                          state.broadcastManager.broadcastPriceList.find(
                            ({ country }) => country === recipientCountry
                          ) &&
                          state.broadcastManager.broadcastPriceList
                            .find(({ country }) => country === recipientCountry)
                            [
                              template.category === "MARKETING"
                                ? "marketing"
                                : "utility"
                            ].toFixed(4) > whatsappUsage.currentBalance)) && (
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Space>
                            <IoIosInformationCircleOutline
                              color="#F05742"
                              style={{
                                fontSize: 20,
                                marginTop: 5,
                              }}
                            />

                            <Text style={{ fontSize: 13 }} type="danger">
                              Insufficient balance to broadcast.
                            </Text>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => {
                                // Navigate to billings page
                                navigate("/account-settings/billing");
                                sessionStorage.setItem(
                                  "selectedMenu",
                                  "/account-settings/billing"
                                );

                                // Set bot in action based on selected bot
                                dispatchReducer({
                                  type: "RD_SET_BOT_IN_ACTION",
                                  payload: {
                                    botInAction: bot?.bot_id,
                                    clientId:
                                      state.currentAccount.account.client_id,
                                    event: "globalBotOnChange",
                                  },
                                });
                              }}
                            >
                              Buy Add-on
                            </Button>
                          </Space>
                        </Space>
                      )
                    ) : (
                      <Space>
                        <IoIosInformationCircleOutline
                          color="#F05742"
                          style={{
                            fontSize: 20,
                            marginTop: 5,
                          }}
                        />

                        <Text
                          type="danger"
                          style={{
                            fontSize: 13,
                          }}
                        >
                          Error checking the current balance. Please try again
                          later.
                        </Text>
                      </Space>
                    )}

                    {((recipientCountry &&
                      !state.broadcastManager.broadcastPriceList.find(
                        ({ country }) => country === recipientCountry
                      )) ||
                      priceNotUpdatedRecipients()?.length !== 0) && (
                      <Space>
                        <IoIosInformationCircleOutline
                          color="#F05742"
                          style={{
                            fontSize: 20,
                            marginTop: 5,
                          }}
                        />

                        <Text
                          type="danger"
                          style={{
                            fontSize: 13,
                          }}
                        >
                          Price for your recipient's region is not available.
                          Contact the support team for more details.
                        </Text>
                      </Space>
                    )}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      )}
      <BroadcastPricingCalculator />
    </>
  );
};

export default BroadcastManagerForm;
