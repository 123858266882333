import React, { useState, useEffect } from "react";
import { Typography, Space } from "antd";

const { Text } = Typography;

export const TypingEffect = ({ message, tokenUsage, source, aiModal }) => {
  const [displayedMessage, setDisplayedMessage] = useState("");

  useEffect(() => {
    let timeoutId;

    const typeMessage = () => {
      for (let index = 0; index <= message.length; index++) {
        timeoutId = setTimeout(() => {
          setDisplayedMessage(message.slice(0, index));
        }, index * 15);
      }
    };

    if (message) {
      typeMessage();
    } else {
      setDisplayedMessage("");
    }

    return () => clearTimeout(timeoutId);
  }, [message]);

  return (
    <Space direction="vertical">
      <Text>{displayedMessage.replace("undefined", "")}</Text>

      {displayedMessage.length === message.length && (
        <Space direction="vertical" style={{ width: "100%" }} size={0}>
          <Space style={{ display: "flex", justifyContent: "space-between" }}>
            {source && (
              <Text
                style={{
                  fontSize: "13px",
                  fontStyle: "italic",
                  color: "#999999",
                }}
              >
                Source: {source}
              </Text>
            )}
            {tokenUsage && (
              <Text
                style={{
                  fontSize: "13px",
                  fontStyle: "italic",
                  color: "#999999",
                }}
              >
                Token: {tokenUsage}
              </Text>
            )}
          </Space>

          {/* {aiModal && (
            <Text
              style={{
                fontSize: "13px",
                fontStyle: "italic",
                color: "#999999",
              }}
            >
              AI Model:{" "}
              {aiModal
                ?.split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Text>
          )} */}
        </Space>
      )}
    </Space>
  );
};
