import React from "react";
import { Row, Col, Card } from "antd";
import LiveAgentByChannelChart from "./LiveAgentByChannelChart";
import ResponsesByChannelChart from "./ConversationCountByChannelChart";
import ConversationsByDateChart from "./ConversationCountByDateChart";
import MessagesByChannelChart from "./MessageCountByChannelChart";
import MessagesByDateChart from "./MessageCountByDateChart";
import IntentsChart from "../knowledge-area-charts/IntentsChart";
import ConversationsByBrowserChart from "./ConversationCountByBrowser";
import ConversationCountByLocation from "./ConversationCountByLocationTable";
import NoneIntentMessageCountTable from "./NoneIntentMessageCountTable";
import ActiveAndInactiveUsersCount from "./ActiveAndInactiveUsersCount";
import { mode } from "../../../constants/env";

const ConversationsCharts = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <ResponsesByChannelChart />
        </Card>
      </Col>
      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <ConversationsByDateChart />
        </Card>
      </Col>
      <Col span="8">
        <Card type="inner" style={{ height: 280 }}>
          <MessagesByChannelChart />
        </Card>
      </Col>
      <Col span="12">
        <Card type="inner" style={{ height: 350 }}>
          <MessagesByDateChart />
        </Card>
      </Col>
      <Col span="12">
        <Card type="inner" style={{ height: 350 }}>
          <IntentsChart />
        </Card>
      </Col>

      <Col span="12">
        <Card type="inner" style={{ height: 350 }}>
          <LiveAgentByChannelChart />
        </Card>
      </Col>
      <Col span="12">
        <Card type="inner" style={{ height: 350 }}>
          <ConversationCountByLocation />
        </Card>
      </Col>
      <Col span="12">
        <Card type="inner" style={{ height: 350 }}>
          <NoneIntentMessageCountTable />
        </Card>
      </Col>
      <Col span="8">
        <Card type="inner" style={{ height: 270 }}>
          <ConversationsByBrowserChart />
        </Card>
      </Col>
      {(mode === "STAGING" || mode === "DEV") && (
        <Col span="8">
          <Card type="inner" style={{ height: 270 }}>
            <ActiveAndInactiveUsersCount />
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default ConversationsCharts;
