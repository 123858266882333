export const chineseText = {
  contactManager: {
    header: {
      addContact: {
        infoText: "添加联系人",
        buttonText: "添加",
      },
      importContact: {
        infoText: "导入联系人",
        buttonText: "导入",
      },
      deleteContact: {
        infoText: "删除联系人",
        buttonText: "删除",
      },
      searchContact: {
        placeHolder: "按姓名、手机号或标签搜索",
      },
    },
    table: {
      editContact: {
        infoText: "编辑联系人",
      },
      deleteContact: {
        infoText: "删除联系人",
      },
    },
  },
  clientResponses: {
    header: {
      noAccess: "手机号或标签 搜索",
      responsesSettings: {
        infoText: "查看响应设置",
      },
      createBroadcast: {
        infoText: "按手机 号或标签搜索",
        warningText: "按姓名或标签 搜索",
        // Icon used
        buttonText: "",
      },
    },
    infoPanel: {
      noAccess: "手机号或标签 搜索",
      responseTimeline: {
        showInfoText: "按手机 号或标签搜索",
        hideInfoText: "按手机或标签 索",
      },
    },
    infoPanelV2: {
      noAccess: "您没有所需的权限",
      deliveryServices: {
        notConfigured: "未配置",
        lalamove: {
          bookDelivery: {
            infoText: "预定送货",
            buttonText: "预定送货",
          },
          checkQuotationDetails: {
            infoText: "查看报价详情",
            buttonText: "查看报价详情",
          },
          checkOrderStatus: {
            infoText: "查看订单状态",
            buttonText: "查看订单状态",
          },
          createNewQuotation: {
            infoText: "创建新报价",
            buttonText: "创建新报价",
            description: "您可以通过创建新报价来获取Lalamove送货服务的报价",
          },
          deliveryDetailsForm: {
            title: "交货详情",
            loadingMessage: "加载中... 请稍候...",
            pickupAddressFormItem: {
              label: "取货地址",
              placeholder: "选择取货地址",
              required: "请选择取货地址",
            },
            serviceTypeFormItem: {
              label: "服务类型",
              placeholder: "选择服务类型",
              required: "请选择服务类型",
            },
            deliveryAddressFormItem: {
              label: "交货地址",
              placeholder: "输入交货地址",
              required: "请输入交货地址",
            },
            pickupTime: {
              title: "取货时间",
            },
            getQuotation: {
              infoText: "获取报价",
              buttonText: "获取报价",
            },
          },
          quotationDetails: {
            title: "报价详情",
            quotationId: {
              title: "报价编号：",
            },
            orderNow: {
              infoText: "立即下单",
              buttonText: "立即下单",
            },
          },
          orderDetails: {
            title: "订单详情：",
            loadingMessage: "加载中... 请稍候...",
            status: {
              title: "订单状态：",
            },
            pickupAddress: {
              title: "取货地址：",
            },
            deliveryAddress: {
              title: "交货地址：",
            },
            orderId: {
              title: "订单编号：",
            },
            scheduleAt: {
              title: "计划于：",
            },
            expiresAt: {
              title: "过期于：",
            },
            serviceType: {
              title: "服务类型：",
            },
            totalPrice: {
              title: "总价格：",
            },
            distance: {
              title: "距离：",
            },
            orderNow: {
              infoText: "立即下单",
              buttonText: "立即下单",
            },
            checkOrderStatus: {
              infoText: "查看订单状态",
              buttonText: "查看订单状态",
            },
          },
        },
      },
    },
  },
  conversations: {
    footer: {
      noAccess: "手机 号或 标签 搜索",

      attachments: {
        infoText: "手机号 或标 签 搜索",

        title: "手机号或标签",
        image: {
          imageSizeError: "手机号 或标签 搜索",
          tryOtherImage: "手机 号或 标",
        },
        video: {
          videoSizeError: "手机号 或标签 搜索",
          tryOtherVideo: "手机号 搜索",
        },
        document: {
          documentSizeError: "手机号 或标签 搜索",
          tryOtherDocument: "手机号 或标签 搜索",
        },
      },
      conversationSummary: {
        infoText: "手机号手机号",
      },
      notes: {
        infoText: "手机号",
        noNotesFound: "手机号手机号",
        title: "手机号",
        deleteNote: {
          infoText: "手机号手机号",
          warningText: "手机 或标签 手机号",
        },
        editNote: {
          infoText: "手机号手机号",
          warningText: "手机 号或 号手机号",
        },
      },
    },
  },
  broadcastManager: {
    header: {
      noAccess: "手机号 或标签 搜索 手机号 或标签 搜索",
      broadcastSettings: {
        infoText: "手机号或标签 搜索",
      },
      broadcastPricingCalculator: {
        infoText: "价格计算器",
        title: "广播价格计算器",
        broadcastPricingForm: {
          recipientCountryFormItem: {
            label: "接收国家",
            placeholder: "例如：新加坡",
            required: "请选择一个国家",
          },
          messageTypeFormItem: {
            label: "消息类型",
            required: "请选择消息类型",
            marketingMessage: "营销消息",
            utilityMessage: "实用消息",
          },
          recipientCountFormItem: {
            label: "接收人数",
            required: "请输入接收人数",
            invalid: "请输入有效数字",
            maximum: "接收人数应小于100,000",
            placeholder: "例如：1000",
          },
          calculateFormItem: {
            buttonText: "计算",
          },
          totalPrice: {
            label: "总价 (美元)",
          },
        },
      },
    },
  },
  unrecognizedIntents: {
    header: {
      noAccess: "手机号 或标签 搜索 手机号 或标签 搜索",
      deleteUnrecognizedIntents: {
        infoText: "手机号 或标签",
        warningText: "手机号 或标签 搜索",
      },
    },
    table: {
      noAccess: "手机号 或标签 搜索",
      editUnrecognizedIntent: {
        infoText: "手机号 或标签 搜索 ",
      },
      deleteUnrecognizedIntent: {
        infoText: "手机号 搜索",
      },
      viewConversation: {
        infoText: "手机号 搜 索",
      },
    },
  },
  messageTemplates: {
    header: {
      noAccess: "您 没有所需 的权限",
      createTemplate: {
        infoText: "您没有 所需的 权限",
        buttonText: "您没有所 需的权限",
        pending: "您没有 所需的权限",
        title: "您没 有所需的权 限",
      },
      refreshTemplates: {
        infoText: "您没有所需的权限",
      },
    },
    formPanel: {
      form: {
        noAccess: "您没有所需的权限",
        enterAllFieldsWarning: "您没有所需的权限",
        templateNameFormItem: {
          title: "模板名称",
          label: "为您的模板提供一个名称",
          placeholder: "输入模板名称",
          required: "请输入模板名称",
          minimum: "模板名称应不少于5个字符",
          maximum: "模板名称应不超过50个字符",
          pattern: "只允许使用字母数字字符",
        },
        templateCategoryFormItem: {
          title: "模板类别",
          label: "为您的模板选择类别",
          placeholder: "选择类别",
          required: "请选择类别",
          marketing: "营销",
          utility: "实用工具",
          authentication: "身份验证",
        },
        templateChannelFormItem: {
          title: "渠道",
          label: "您将在哪个渠道使用此模板",
          placeholder: "选择渠道",
          required: "请选择渠道",
          whatsapp: "请选 择渠道",
          instagram: "请选择渠 道",
          facebook: "请 选择 渠道",
        },
        allowCategoryChangeFormItem: {
          title: "允许更改类别",
          label: "将来允许更改类别吗？",
          tooltipText:
            "启用类别更改允许 Meta 分配他们认为合适的任何类别。我们建议启用此功能，以避免因分类错误而拒绝模板。请注意，模板仍可能因与其分类无关的其他原因被拒绝。",
          yes: "是",
          no: "否",
        },
        templateLanguageFormItem: {
          title: "语言",
          label: "为您的模板选择语言",
          placeholder: "选择语言",
          required: "请选择语言",
        },
        templateTypeFormItem: {
          title: "模板类型",
          label: "为您的模板选择类型",
          placeholder: "选择类型",
          required: "请选择类型",
          textOnly: "标准（仅文本）",
          mediaInteractive: "多媒体与互动",
          carousel: "轮播",
        },
        templateBodyFormItem: {
          title: "消息正文",
          addVariable: "添加变量",
          placeholder: "在此处输入模板正文。例如：你好{{1}}！...",
          required: "请输入模板正文",
          minimum: "模板正文应至少为5个字符",
          maximum: "模板正文应少于1024个字符",
          addVariable: "添加变量",
        },
        quickReplyButtonFormItem: {
          title: "Quick Reply",
          placeholder: "在此处输入按钮标签。例如：预约",
          required: "请输入按钮标签",
          minimum: "按钮标签应至少为2个字符",
          maximum: "按钮标签应少于20个字符",
        },
        urlButtonLabelFormItem: {
          title: "按标签",
          placeholder: "按钮标签。例如：访问我们的网站",
          required: "请输入URL",
          maximum: "URL标签应少于20个字符",
          invalid: "请输入有效的URL标签",
        },
        urlButtonValueFormItem: {
          placeholder: "按钮重定向URL。例如：https://inextlabs.ai",
          required: "请输入URL",
          invalid: "请输入有效的URL",
          maximum: "URL应少于1024个字符",
        },
        phoneNumberButtonLabelFormItem: {
          title: "电话号码",
          placeholder: "电话号码按钮标签。例如：给我们打电话",
          required: "请输入电话号码标签",
          maximum: "电话号码标签应少于20个字符",
          invalid: "请输入有效的电话号码",
        },
        phoneNumberButtonValueFormItem: {
          placeholder: "在此处输入电话号码。例如：+6597799776",
          required: "请输入电话号码",
          invalid: "请输入有效的电话号码。例如：+6597799776",
        },
        templateContent: {
          title: "模板内容",
          description: "在此创建您的模板内容",
          textOnlyFormItem: {
            title: "消息正文",
            placeholder: "在此输入您的模板正文",
            required: "请输入模板正文",
            minimum: "模板正文应不少于5个字符",
            maximum: "模板正文应不超过1024个字符",
            addVariable: "添加变量",
          },
          mediaInteractiveFormItem: {
            buildingBlocks: {
              title: "构建模块",
              description: "选择消息模板构建模块。",
              header: "头部",
              body: "正文",
              bodyMandatoryTooltip: "正文是必填项",
              footer: "页脚",
              buttons: "按钮",
            },
            headerFormItem: {
              title: "头部",
              placeholder: "在此输入您的模板头部",
              required: "请输入头部",
              minimum: "头部应不少于5个字符",
              maximum: "头部应不超过60个字符",
              text: "文本",
              image: "图片",
              video: "视频",
              document: "文本",
            },
            bodyFormItem: {
              title: "正文",
              placeholder: "在此输入您的模板正文",
              required: "请输入正文",
              minimum: "正文应不少于5个字符",
              maximum: "正文应不超过1024个字符",
              addVariable: "添加变量",
            },
            footerFormItem: {
              title: "页脚",
              placeholder: "在此输入您的模板页脚",
              required: "请输入页脚",
              minimum: "页脚应不少于5个字符",
              maximum: "页脚应不超过60个字符",
              invalid: "请勿在页脚中使用占位符",
            },
            buttonsFormItem: {
              title: "按钮",
              maximum: "最多可以添加3个按钮",
              addButton: "添加按钮",
              infoText: "在此创建您的模板按钮",
              validGrouping: {
                exampleTitle: "示例：",
                infoText: "页脚应不超过60个字符",
              },
              invalidGrouping: {
                exampleTitle: "示例：",
                infoText: "页脚应不超过60个字符",
              },
              buttonType: {
                quickReply: "快速回复",
              },
              button: {
                title: "按钮",
                callToAction: {
                  url: {
                    title: "链接",
                  },
                  phoneNumber: {
                    title: "电话号码",
                  },
                },
              },
            },
          },
          carouselFormItem: {
            title: "轮播",
            description:
              "轮播模板是一组卡片，可用于在单个消息中展示多个产品、服务或其他任何项目，并具有水平滚动视图。",
            cards: {
              title: "卡片",
              addCard: "添加卡片",
              description:
                "每个卡片都是一个交互式组件，可以包含标题、正文和按钮。您最多可以添加10张卡片。",
              card: "卡片",
              header: {
                title: "标题",
                image: "图片",
                video: "视频",
              },
              body: {
                title: "正文",
                required: "请输入您的卡片正文！",
                minimum: "卡片正文应该超过5个字符",
                maximum: "卡片正文应该少于160个字符",
                invalid: "卡片正文中不允许使用占位符！",
                placeholder:
                  "在此处输入您的卡片正文。例如：美味的食物，优质的服务...",
              },
              buttons: {
                title: "按钮",
                addButton: "添加按钮",
                button: "按钮",
              },
            },
          },
        },

        formFooter: {
          noAccess: "模 板正文 应不超过 个字符",
          previewAndSubmit: {
            infoText: "模板正文 应不超过 个字符",
            buttonText: "模板正 文应不字符",
          },
          cancel: {
            infoText: "模板正",
            buttonText: "个字符",
          },
        },
      },
      preview: {
        title: "预览模板",
        exampleInfoText: "示例：嗨 嗨 已准备好取 已准备好取货",
        valueMapping: {
          templateHeader: {
            title: "消息头部",
            description: "在下面为占位符输入值",
            image: {
              description: "在下面为占位符输入值",
              invalid: "请勿在页脚中使用占位符",
            },
            video: {
              description: "在下面为占位符输入值",
              invalid: "请勿在页脚中使用占位符",
            },
            document: {
              description: "在下面为占位符输入值",
              invalid: "请勿在页脚中使用占位符",
            },
          },
          templateBody: {
            title: "消息正文",
            description: "在下面为占位符输入值",
            noPlaceholders: "您的文本中没有使用任何变量占位",
            infoToUsePlaceholder: "要添加变量，请返回编辑器并在给定位置插入",
            exampleForPlaceholder:
              "示例：嗨，{{1}}！您的订单{{2}} 已准备好取货",
          },
          templateFooter: {
            title: "消息页脚",
          },
          carousel: {
            title: "轮播",
            card: {
              title: "卡片",
              header: {
                exampleInfoText: "示例： 已准备 已准备好取货 好取货",
                placeholder: "Ex: https://inextlabs.ai/inextlabs-logo.png",
                invalid: "请勿在页脚中使用占位符",
              },
            },
          },
        },
        messagePreview: {
          title: "消息预览",
        },
        footer: {
          backToEditor: {
            infoText: "请返 回编辑器 并在给定位置插入",
            buttonText: "请返回 编辑器并在给定 位置插入",
          },
          submitTemplate: {
            warningText: "请返 回编辑 器并在给定位 置插入",
            infoText: "请返 回编辑 器并在",
            buttonText: "请返器并在给定位 置插入",
          },
        },
      },
    },
  },
  accountSettings: {
    billing: {
      title: "账单",
      subscription: {
        title: "订阅",
        sideMenu: {
          contactDetails: {
            title: "联系方式",
          },
          billingDetails: {
            title: "账单详情",
          },
          paymentMethod: {
            title: "付款方式",
            paymentGateway: {
              title: "支付网关",
            },
          },
        },
        subscriptionDetails: {
          title: "订阅",
          subscriptionId: "订阅ID",
          email: "电子邮件",
          subscriptionTerm: "订阅期限",
        },
        notAvailable: {
          info: "订阅信息页面是您管理我们服务订阅的中央枢纽。该页面为您提供了关于您当前订阅计划、账单详情以及更改订阅的选项的全面概述。如果您的订阅信息不可用，可能是由于以下原因：",
          reason1: {
            title: "企业计划：",
            description:
              "企业计划的客户可能会有定制的计费安排和与标准订阅界面分开管理的功能。",
          },
          reason2: {
            title: "通过合作伙伴账单：",
            description:
              "如果客户的订阅是通过合作伙伴或第三方实体账单的，他们的订阅详情可能无法直接在用户账户设置中访问。",
          },
          reason3: {
            title: "入职团队更新：",
            description:
              "在入职团队尚未更新订阅详情的情况下，客户可能需要等待他们的具体计划信息被整合到系统中，然后才能在他们的账户设置中看到。",
          },
          support:
            "请联系我们的支持团队，邮箱为support@inextlabs.com，以获取澄清或支持。",
        },
      },
    },
    api: {
      appRegistration: {
        noAccess: "您没有所需的权限",
        title: "应用注册",
        appDetails: "应用详情",
        appName: {
          title: "应用名称：",
          description: "这是您的应用程序的名称。您可以随时更改此名称。",
        },
        clientId: {
          title: "客户端ID：",
          description:
            "这是您的应用程序的唯一标识符。您需要此标识符来验证您的应用程序与我们的API。",
        },
        clientSecret: {
          title: "客户端密钥：",
          description:
            "这是您的应用程序的唯一密钥。请将其视为机密信息，不要分享。",
          expiresAt: "过期时间：",
          createdAt: "创建时间：",
        },
        redirectUri: {
          title: "重定向URI：",
          description1:
            "我们只允许特定的网站地址在您成功登录或注销后接收您的登录或注销信息。",
          description2:
            "登录服务器请求中提供的重定向URI必须与列出的URI之一匹配。",
          noRedirectUri: "未找到重定向URI",
        },
        editButton: "编辑",
        deleteButton: "删除",

        form: {
          noAccess: "您没有所需的权限",
          appNameFormItem: {
            title: "应用名称",
            label: "为您的应用程序提供一个名称",
            description:
              "为您的应用程序提供一个名称。此名称将显示在应用程序列表中，帮助您识别应用程序。",
            placeholder: "输入应用名称",
            required: "请输入应用名称",
            minimum: "应用名称应超过3个字符",
            maximum: "应用名称应少于100个字符",
          },
          clientSecretNameFormItem: {
            title: "密钥名称",
            label: "为您的密钥提供一个名称",
            description:
              "为您的密钥提供一个名称。请将其视为机密信息，不要分享。",
            placeholder: "输入密钥名称",
            required: "请输入密钥名称",
            minimum: "密钥名称应超过5个字符",
            maximum: "密钥名称应少于100个字符",
          },
          redirectUriFormItem: {
            title: "重定向URI",
            label: "为您的应用程序提供重定向URI",
            description:
              "重定向URI是授权服务器在成功授权应用程序后重定向的终点。",
            placeholder: "输入重定向URI",
            required: "请输入重定向URI",
            minimum: "重定向URI应超过5个字符",
            maximum: "重定向URI应少于200个字符",
            invalid: "请输入有效的重定向URI",
          },

          submit: {
            infoText: "提交",
            buttonText: "提交",
          },
          cancel: {
            infoText: "取消",
            buttonText: "取消",
          },
        },
      },
    },
    deliveryServices: {
      title: "送货服务",

      lalamove: {
        noAccess: "您没有所需的权限",
        title: "送货服务",
        goBackToSettings: "返回设置",
        form: {
          noAccess: "您没有所需的权限",
          apiKeyFormItem: {
            title: "API密钥",
            label: "API密钥",
            description: "配置您的Lalamove API密钥以启用送货服务。",
            placeholder: "输入您的API密钥",
            required: "请输入API密钥",
            minimum: "API密钥应至少为3个字符",
            maximum: "API密钥应少于100个字符",
          },
          appSecretFormItem: {
            title: "API秘钥",
            label: "API秘钥",
            description: "配置您的Lalamove API秘钥以启用送货服务。",
            placeholder: "输入您的API秘钥",
            required: "请输入API秘钥",
            minimum: "API秘钥应至少为3个字符",
            maximum: "API秘钥应少于100个字符",
          },
          pickupAddressFormItem: {
            title: "取货地址",
            label: "地址",
            description: "为Lalamove订单提供取货地址的配置。",
            name: {
              label: "分店名称",
              placeholder: "输入用户友好的名称，如分店名称或商店名称...",
              required: "请输入名称",
              minimum: "名称应至少为3个字符",
              maximum: "名称应少于100个字符",
            },
            phoneNumber: {
              label: "分店电话号码",
              placeholder: "输入用户友好的电话号码，如分店名称或商店名称...",
              required: "请输入电话号码",
              minimum: "电话号码应至少为3个字符",
              maximum: "电话号码应少于100个字符",
            },
            address: {
              label: "分店地址",
              placeholder: "输入用户友好的地址，如分店名称或商店名称...",
              required: "请输入取货地址",
              minimum: "取货地址应至少为3个字符",
              maximum: "取货地址应少于100个字符",
            },
          },
          languageSelectionFormItem: {
            title: "语言",
            label: "语言",
            description: "配置您的Lalamove送货的首选语言。",
            placeholder: "选择您的首选语言",
            required: "请输入语言选择",
            minimum: "语言选择应至少为3个字符",
            maximum: "语言选择应少于100个字符",
          },
          marketSelectionFormItem: {
            title: "市场",
            label: "市场",
            description: "配置您的Lalamove送货的首选市场。您可以选择多个市场。",
            placeholder: "选择您的首选市场。您可以选择多个市场。",
            required: "请输入市场选择",
          },
          isEnableFormItem: {
            title: "状态",
            label: "状态",
            description: "启用或禁用Lalamove送货。",
          },
          scheduleAtFormItem: {
            title: "预定时间",
            label: "预定时间",
            description: "为Lalamove订单提供取货的延迟时间配置。",
            placeholder: "选择您的首选延迟时间",
            required: "请输入预定时间",
          },
          serviceTypeFormItem: {
            title: "服务类型",
            label: "服务类型",
            description: "配置您的Lalamove送货的首选服务类型。",
            placeholder: "选择您的首选服务类型",
            required: "请输入服务类型",
          },
          save: {
            infoText: "保存",
            buttonText: "保存",
          },
          cancel: {
            infoText: "取消",
            buttonText: "取消",
          },
        },
      },
    },
  },
  botKnowledgeArea: {
    botKnowledgebase: {
      moduleNotSubscribed: {
        title: "机器人知识库",
        description:
          "通过一款先进的GPT动力机器人，释放您工作场所的潜力，旨在增强“自带数据”（BYOD）体验。利用人工智能的力量，简化运营，赋予您的员工更多权力，并提高现代、多设备工作场所的生产力。",
        benefit1: {
          description:
            "您可以通过从您的本地设备导入文件来构建您的机器人知识库。",
        },
        benefit2: {
          description:
            "用户贡献的知识使机器人能够有效处理复杂问题并应对独特挑战。",
        },
        buttons: {
          activateNow: "立即激活",
          knowMore: "了解更多",
        },
      },
      header: {
        noAccess: "您没有所需的权限",
        loadingMessage: "加载中... 请稍候...",
        addKnowledgebase: {
          infoText: "添加知识库",
          buttonText: "添加知识库",
          formPanelTitle: "添加知识库",
        },
        searchKnowledgebase: {
          placeholder: "按名称搜索",
        },
        testKnowledgebase: {
          infoText: "测试知识库",
          buttonText: "测试知识库",
          formPanelTitle: "测试知识库",
        },
      },
      formPanel: {
        bestPractices: {
          title: "最佳实践",
          bestPractice1: {
            title: "质量预处理",
            description:
              "优化文档处理，清理数据。这包括元数据去除、特殊字符处理和文本规范化，使模型能够专注于语言模式，减少数据干扰。",
          },
          bestPractice2: {
            title: "平衡标记化",
            description:
              "找到正确的标记化平衡。太详细的标记占用过多内存，而过于粗糙的标记会丢失重要上下文。尝试不同的方法以达到平衡。",
          },
          bestPractice3: {
            title: "去重",
            description:
              "去除重复和相似的文档，以提高模型训练效率。使用词干提取和词形还原等技术来减少词汇维度。",
          },
          bestPractice4: {
            title: "数据增强",
            description:
              "利用增强技术来丰富训练数据，如同义词替换、改写和文本变体，以提高模型的泛化能力和多功能性。",
          },
          next: {
            infoText: "下一步",
            buttonText: "下一步",
          },
        },
        uploadFileForm: {
          title: "上传文件",
          error: "表单提交失败！请检查表单错误",
          loadingMessage: "加载中... 请稍候...",
          knowledgebaseNameFormItem: {
            title: "知识库名称",
            label: "为您的知识库提供一个名称",
            required: "请输入知识库名称",
            minimum: "知识库名称应至少为3个字符",
            maximum: "知识库名称应不超过35个字符",
            placeholder: "示例：FAQ，目录，客户支持...",
          },
          knowledgebaseFileFormItem: {
            title: "知识库文件",
            label: "根据此文件，您的机器人将被训练来回答用户的查询",
            required: "请在此处上传您的文件",
            maximumSizeError: "文件大小应小于1MB",
            chooseAnotherFile: "选择另一个文件",
            infoText1: "单击此处上传您的",
            infoText2: "文档文件",
            note: {
              title: "注意：",
              description: "上传1MB以下的DOC，DOCX或PDF文件",
            },
          },
          knowledgebaseVersionFormItem: {
            title: "知识库版本",
            label: "版本有助于确定需要使用哪个知识库",
            required: "请输入知识库版本",
            maximum: "知识库版本应不超过5个字符",
            pattern: "知识库版本必须仅包含数字",
            placeholder: "示例：1.0，2.0，3.0...",
          },
          knowledgebaseTagsFormList: {
            title: "标签",
            label: "标签用于更好地组织知识库",
            keyFormItem: {
              placeholder: "示例：国家，产品...",

              maximum: "标签键必须少于35个字符",
              pattern: "标签键必须为文本",
            },
            valueFormItem: {
              placeholder: "示例：新加坡，餐厅...",
              maximum: "标签值必须少于35个字符",
              pattern: "标签值必须为文本",
            },
            previous: {
              infoText: "上一页",
              buttonText: "上一页",
            },
            save: {
              infoText: "保存 + 查看",
              buttonText: "保存 + 查看",
            },
          },
          previous: {
            infoText: "上一页",
            buttonText: "上一页",
          },
          save: {
            infoText: "保存 + 查看",
            buttonText: "保存 + 查看",
          },
        },
        review: {
          error: "提交失败！请检查表单错误",
          loadingMessage: "正在提交... 请稍等...",
          title: "审阅",
          warningText: "请审阅已处理的文件",
          label:
            "请审阅已处理的文件，确保所有数据正确。在提交之前进行必要的更改。",
          chunkedFileFormList: {
            sectionNameFormItem: {
              maximum: "部分名称必须少于50个字符",
              pattern: "部分名称必须为文本",
              placeholder: "请输入部分名称",
            },
            titleFormItem: {
              maximum: "标题必须少于50个字符",
              pattern: "标题必须为文本",
              placeholder: "请输入标题",
            },
            contentFormItem: {
              maximum: "内容必须少于6000个字符",
              placeholder: "请输入内容",
            },
            delete: {
              title: "确定要删除此部分吗？",
              yes: "部",
              no: "符",
            },
          },
          previous: {
            infoText: "上一页",
            buttonText: "上一页",
          },
          submit: {
            infoText: "提交",
            buttonText: "提交",
          },
        },
      },
      table: {
        noAccess: "您没有所需的权限",
        timestamp: {
          title: "时间戳",
          filters: {
            today: "今天",
            sevenDays: "过去7天",
            thirtyDays: "过去30天",
            sixtyDays: "过去60天",
          },
        },
        kbName: {
          title: "知识库名称",
        },
        version: {
          title: "版本",
        },
        fileName: {
          title: "文件名称",
        },
        status: {
          title: "状态",
          filters: {
            published: "已发布",
            draft: "草稿",
          },
        },
        action: {
          title: "操作",
          edit: {
            title: "编辑知识库",
          },
          publish: {
            infoText: "发布知识库",
            title: "发布知识库",
            warningText: "发布后，将无法再次编辑知识库。您确定要发布吗？",
            ok: "确定",
            cancel: "取消",
          },
          unpublish: {
            infoText: "取消发布知识库",
            title: "取消发布知识库",
            warningText:
              "取消发布后，将无法再次编辑知识库。您确定要取消发布吗？",
            ok: "确定",
            cancel: "取消",
          },
          delete: {
            title: "确定要删除知识库吗？",
            warningText: "点击“确定”按钮后，知识库将被永久删除",
            ok: "确定",
            cancel: "取消",
          },
        },
      },
      test: {
        title: "测试",
        loadingMessage: "测试... 请稍候... ",
        errorMessage: "测试失败！请检查表单错误",
        knowledgebaseTestFormItem: {
          required: "请输入一个问题",
          maximum: "问题应少于100个字符",
          placeholder: "输入一个问题以测试知识库",
        },
        testResult: {
          answer: "回答：",
          tokens: "标记：",
          infoText: "答案将显示在这里。",
        },
      },
      section: {
        noAccess: "您没有所需的权限",
        loadingMessage: "加载中... 请稍候...",
        header: {
          searchSectionInputBox: {
            placeholder: "按标题或内容搜索",
          },

          addSectionButton: {
            infoText: "添加章节",
            buttonText: "添加章节",
          },
        },
        form: {
          loadingMessage: "正在提交... 请稍候...",
          errorMessage: "出错了，请稍后再试。",
          sectionTitleFormItem: {
            required: "请输入章节标题",
            maximum: "章节标题应少于200个字符",
            placeholder: "为此章节提供标题。例如：如何创建新工单？",
          },
          sectionContentFormItem: {
            required: "请输入章节内容",
            maximum: "章节内容应少于4000个字符",
            placeholder: "为此章节提供内容。例如：创建新工单的步骤...",
          },
          saveButton: {
            infoText: "保存",
            buttonText: "保存",
          },
          cancelButton: {
            infoText: "取消",
            buttonText: "取消",
          },
          deleteButton: {
            title: "确定要删除此章节吗？",
            okText: "是",
            cancelText: "否",
          },
          empty: {
            description: "未找到内容",
          },
        },
      },
    },
    whatsappFlows: {
      header: {
        noAccess: "您没有所需的权限",
        createWhatsappFlow: {
          infoText: "创建新流程",
          buttonText: "创建新流程",
          formPanelTitle: "创建新流程",
        },
        searchWhatsappFlows: {
          placeholder: "按名称搜索",
        },
      },
      table: {
        noAccess: "您没有所需的权限",
        name: {
          title: "名称",
        },
        categories: {
          title: "类别",
        },
        status: {
          title: "状态",
        },
        action: {
          title: "操作",
          edit: {
            title: "编辑流程",
          },
          delete: {
            title: "确定要删除流程吗？",
            warningText: "点击“确定”按钮后，流程将被永久删除。此操作无法撤消。",
            ok: "确定",
            cancel: "取消",
          },
        },
      },
    },
  },
};
