const apiCallStatus = (state, action) => {
  switch (action.type) {
    case "START_API_TRANSACTION":
      return {
        ...state,
        apiCallStatus: {
          ...state.apiCallStatus,
          ...{
            [action.payload.component]: { loading: true, error: undefined },
          },
        },
      };

    case "SUCCESS_API_TRANSACTION":
      return {
        ...state,
        apiCallStatus: {
          ...state.apiCallStatus,
          ...{
            [action.payload.component]: { loading: false, error: undefined },
          },
        },
      };

    case "ERROR_API_TRANSACTION":
      return {
        ...state,
        apiCallStatus: {
          ...state.apiCallStatus,
          ...{
            [action.payload.component]: {
              loading: false,
              error: action.payload.error,
            },
          },
        },
      };

    default:
      break;
  }
};

export default apiCallStatus;
