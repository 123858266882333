import moment from "moment";
import crypto from "crypto";

export const getUserDateTime = (userDateTime, userTimeZone, userDateFormat) => {
  const userOffsetWithoutColon = userTimeZone
    .match(/[+-][0-9]?\d:\d\d/)[0]
    .replace(":", "");
  const direction = userOffsetWithoutColon.substring(0, 1);
  const hours = userOffsetWithoutColon.substring(1).padStart(4, "0");
  const utcOffset = direction + hours;

  return moment(userDateTime).utcOffset(utcOffset).format(userDateFormat);
};

export const getUserDateTimeMoment = (userDateTime, userTimeZone) => {
  const userOffsetWithoutColon = userTimeZone
    .match(/[+-][0-9]?\d:\d\d/)[0]
    .replace(":", "");
  const direction = userOffsetWithoutColon.substring(0, 1);
  const hours = userOffsetWithoutColon.substring(1).padStart(4, "0");
  const utcOffset = direction + hours;

  return moment(userDateTime).utcOffset(utcOffset);
};

export const calculateDateTimeToBeDisplayed = (timestamp, userTimeZone) => {
  const userDateTimeMoment = getUserDateTimeMoment(timestamp, userTimeZone);
  const userSpecificDate = userDateTimeMoment.format("DD/MM/yyyy");
  const todayDate = moment().format("DD/MM/yyyy");

  const dayDifference = moment(todayDate, "DD/MM/yyyy").diff(
    moment(userSpecificDate, "DD/MM/yyyy"),
    "days"
  );

  let dateTimeToBeDisplayed;

  if (dayDifference === 0) {
    dateTimeToBeDisplayed = `Today ${moment(userDateTimeMoment).format(
      "hh:mm a"
    )}`;
  } else if (dayDifference === 1) {
    dateTimeToBeDisplayed = `Yesterday ${moment(userDateTimeMoment).format(
      "hh:mm a"
    )}`;
  } else if (dayDifference < 0) {
    dateTimeToBeDisplayed = dayDifference * -1 + " days";
  } else {
    dateTimeToBeDisplayed =
      moment(userDateTimeMoment).format("DD/MM/yyyy h:mm a");
  }

  return dateTimeToBeDisplayed;
};

export const convertTimeToUTC = (timestamp) => {
  const inputTime = new Date(timestamp);
  const utcTime = new Date(inputTime.toUTCString());
  return utcTime.toISOString();
};

export const convertUTCToUserTime = (
  timestamp,
  userTimeZone,
  userDateFormat
) => {
  const userOffsetWithoutColon = userTimeZone
    .match(/[+-][0-9]?\d:\d\d/)[0]
    .replace(":", "");
  const direction = userOffsetWithoutColon.substring(0, 1);
  const hours = userOffsetWithoutColon.substring(1).padStart(4, "0");
  const utcOffset = direction + hours;

  return moment(timestamp).utcOffset(utcOffset).format(userDateFormat);
};

export const groupBy = (objectArray, property, dataType) => {
  return objectArray.reduce((acc, obj) => {
    let key;
    if (dataType === "date") {
      key = moment(obj[property]).format("YYYY-MM-DD");
    } else {
      key = obj[property];
    }

    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

//For filtering the data convert object to string
export const convertObjectToString = (obj) => {
  //convert arrays to strings with * as a separator
  //remove null values
  let filteredValue = Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key])) {
      acc[key] = obj[key].join("*");
    } else if (obj[key] !== null) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
  //convert object to string
  filteredValue = JSON.stringify(filteredValue);
  return (filteredValue = filteredValue
    .replace(/:/g, "=")
    .replace(/"/g, "")
    .replace(/{/g, "")
    .replace(/}/g, "")
    .replace(/,/g, "&")
    .replace(/\*/g, ","));
};

// Set conversationId in the cookies
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()};`;
  }
  document.cookie = `${name}=${value};${expires}path=/`;
};

// Get conversationId from the cookies
export const getCookieValue = (cookieName) => {
  const cookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith(`${cookieName}`));

  if (cookie) {
    let cookieValue = cookie.trim().substring(`${cookieName}`.length);
    return cookieValue;
  }
  return undefined;
};

// Format file size
export const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} Bytes`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

export const encryptKey = (clientDetails) => {
  const encryptionType = "aes-256-cbc";
  const encryptionEncoding = "base64";
  const bufferEncryption = "utf-8";

  let AesKey = clientDetails.AES_KEY;
  let AesIV;

  if (clientDetails.clientId.length === 8) {
    AesIV = `${clientDetails.clientId}${clientDetails.clientId}`;
    AesKey = AesKey + clientDetails.clientId;
  } else if (clientDetails.clientId.length >= 16) {
    AesIV = clientDetails.clientId.substring(0, 16);
    AesKey = AesKey + clientDetails.clientId.substring(0, 8);
  } else {
    AesIV = (
      clientDetails.clientId +
      clientDetails.clientId +
      clientDetails.clientId +
      clientDetails.clientId +
      clientDetails.clientId +
      clientDetails.clientId
    ).substring(0, 16);
    AesKey = AesKey + clientDetails.clientId.substring(0, 8);
  }

  const val = clientDetails.wabaApikey;
  const key = Buffer.from(AesKey, bufferEncryption);
  const iv = Buffer.from(AesIV, bufferEncryption);
  const cipher = crypto.createCipheriv(encryptionType, key, iv);

  let encrypted = cipher.update(val, bufferEncryption, encryptionEncoding);
  encrypted += cipher.final(encryptionEncoding);

  return encrypted;
};

// Extract initials from name
export const extractInitialsFromName = (fullName) => {
  if (fullName) {
    const [firstName, ...restNames] = fullName.split(" ");
    const lastName = restNames.length ? restNames[restNames.length - 1] : "";
    const initials = lastName ? `${firstName[0]}${lastName[0]}` : firstName[0];

    return initials.toUpperCase();
  }
  return "";
};

// Get random color
export const generateRandomBackgroundColor = () => {
  const colors = [
    "#3498db", // Blue
    "#e74c3c", // Red
    "#2ecc71", // Green
    "#f39c12", // Orange
    "#9b59b6", // Purple
    "#d35400", // Pumpkin
    "#c0392b", // Pomegranate
    "#16a085", // Green Sea
    "#2980b9", // Belize Hole
    "#f39c12", // Sun Flower
    "#3498db", // Peterriver
    "#e74c3c", // Alizarin
    "#2c3e50", // Midnight Blue
    "#2ecc71", // Emerald
    "#3498db", // Peter River
    "#9b59b6", // Amethyst
    "#34495e", // Wet Asphalt
    "#27ae60", // Nephritis
    "#8e44ad", // Wisteria
    "#1abc9c", // Turquoise
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

// Decode html
export const decodeHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.documentElement.textContent;
};

// Get text from html
export const extractTextFromHtml = (html) => {
  const withoutTags = html.replace(/<\/[^>]+>/g, " ");
  const doc = new DOMParser().parseFromString(withoutTags, "text/html");
  const textContent = doc.body.textContent;

  return textContent;
};

// Format text
export const formatText = (text) => {
  const boldRegex = /\*(.*?)\*/g;
  const italicRegex = /_(.*?)_/g;
  const strikethroughRegex = /~(.*?)~/g;
  const newLineRegex = /\n/g;

  const formattedText = text
    .replace(boldRegex, (_, content) => `<strong>${content}</strong>`)
    .replace(italicRegex, (_, content) => `<em>${content}</em>`)
    .replace(strikethroughRegex, (_, content) => `<del>${content}</del>`)
    // \n means new line
    .replace(newLineRegex, "<br />");

  return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
};

// Calculate scheduled time
export const calculateScheduledTime = (selectedDelay) => {
  if (!selectedDelay) {
    return null;
  }
  const currentTime = new Date().getTime();
  const scheduledTime = new Date(currentTime + selectedDelay * 1000);
  return scheduledTime.toISOString();
};
