import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  Row,
  Col,
  Typography,
  Input,
  Card,
  Space,
  Tooltip,
} from "antd";
import { TbRobot } from "react-icons/tb";
import { AiOutlineSend } from "react-icons/ai";
import { useGlobalContext } from "../../contexts/globalContext";
import { MdContentCopy, MdRefresh } from "react-icons/md";

const { Text } = Typography;
const { TextArea } = Input;

const AiAssistantPanel = ({ setSelectedMessage }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const spanRef = useRef(null);
  const inputRef = useRef(null);

  const [question, setQuestion] = useState("");
  const [isAiAssistantPanelVisible, setIsAiAssistantPanelVisible] =
    useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [textWithDelay, setTextWithDelay] = useState("");

  const isAiAssistEnabled =
    state.currentAccount.account.modules.conversation?.ai_assist;

  // Removing unnecessary text from the help text
  let helpText = state.conversations.ai.aiAssistantHelpText;

  helpText = helpText?.includes("Human:")
    ? helpText.substring(0, helpText.indexOf("Human:"))
    : helpText;

  helpText = helpText?.replace("<|im_end|>", "");

  // Setting the text with delay
  useEffect(() => {
    const typeDelay = 30;

    if (!helpText) {
      return;
    }
    const typeText = () => {
      for (let index = 0; index <= helpText.length; index++) {
        setTimeout(() => {
          setTextWithDelay(helpText.slice(0, index));
        }, index * typeDelay);
      }
      setIsTyping(false);
    };
    typeText();
  }, [helpText]);

  // Blinking the cursor
  useEffect(() => {
    const blinkInterval = setInterval(() => {
      if (spanRef.current && spanRef.current.style) {
        if (spanRef.current.style.visibility === "hidden") {
          spanRef.current.style.visibility = "visible";
        } else {
          spanRef.current.style.visibility = "hidden";
        }
      }
    }, 500);
    inputRef?.current?.focus();

    return () => {
      clearInterval(blinkInterval);
    };
  }, []);

  return (
    <Dropdown
      trigger={["click"]}
      open={isAiAssistantPanelVisible}
      onOpenChange={(visible) => {
        setIsAiAssistantPanelVisible(visible);
      }}
      dropdownRender={() => (
        <div
          style={{
            height: 455,
            width: 400,
            borderRadius: 5,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            background: "white",
            padding: 12,
            marginBottom: 10,
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <Row align="middle">
            <Text strong> AI Assistant</Text>
            <TbRobot
              size={17}
              style={{
                color: "#F05742",
                marginLeft: 5,
              }}
            />
          </Row>
          <Row>
            <Col span={24}>
              <Text
                italic
                type="secondary"
                style={{
                  fontSize: 12,
                }}
              >
                Need help? Find answers here.
              </Text>
            </Col>
            <Col
              span={24}
              style={{
                marginTop: 8,
              }}
            >
              <Row>
                <Col span={23}>
                  <TextArea
                    ref={inputRef}
                    value={question}
                    placeholder="Ask me anything..."
                    showCount
                    maxLength={150}
                    style={{
                      width: "98%",
                    }}
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      e.preventDefault();

                      if (isAiAssistEnabled && e.key === "Enter") {
                        setIsTyping(true);
                        setTextWithDelay("");
                        dispatchReducer({
                          type: "CLEAR_AI_ASSISTANT_HELP_TEXT",
                        });
                        dispatchMiddleware({
                          type: "LOAD_AI_ASSISTANT_HELP_TEXT",
                          payload: {
                            question: question.concat(
                              ", in 3 simple user friendly sentences"
                            ),
                            component: "ai-assistant-panel",
                          },
                        });
                      }
                    }}
                  />
                </Col>
                <Col span={1}>
                  {isAiAssistEnabled ? (
                    <AiOutlineSend
                      size={20}
                      style={{
                        color: "#F05742",
                        marginTop: "5px",
                        marginLeft: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsTyping(true);
                        setTextWithDelay("");
                        dispatchReducer({
                          type: "CLEAR_AI_ASSISTANT_HELP_TEXT",
                        });
                        dispatchMiddleware({
                          type: "LOAD_AI_ASSISTANT_HELP_TEXT",
                          payload: {
                            question: question.concat(
                              ", in 3 simple user friendly sentences"
                            ),
                            component: "ai-assistant-panel",
                          },
                        });
                      }}
                    />
                  ) : (
                    <Tooltip
                      placement="topRight"
                      title="AI Assistant feature not activated. Please contact iNextLabs"
                    >
                      <AiOutlineSend
                        size={20}
                        style={{
                          color: "#C0C0C0",
                          marginTop: "5px",
                          marginLeft: "2px",
                        }}
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              {helpText ? (
                <Card
                  style={{
                    marginTop: 10,
                    borderRadius: 5,
                    background: "white",
                    paddingTop: 10,
                  }}
                >
                  <Space
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 7,
                      cursor: "pointer",
                      color: "#F05742",
                    }}
                    size={15}
                  >
                    <Tooltip title="Regenerate">
                      <MdRefresh
                        size={18}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsTyping(true);
                          setTextWithDelay("");
                          dispatchReducer({
                            type: "CLEAR_AI_ASSISTANT_HELP_TEXT",
                          });
                          dispatchMiddleware({
                            type: "LOAD_AI_ASSISTANT_HELP_TEXT",
                            payload: {
                              question: question.concat(
                                ", any other way to say this in 3 simple user friendly sentences?"
                              ),
                              component: "ai-assistant-panel",
                            },
                          });
                        }}
                      />
                    </Tooltip>

                    <Tooltip title="Copy">
                      <MdContentCopy
                        onClick={() => {
                          navigator.clipboard.writeText(helpText);
                          setSelectedMessage(helpText);
                          setIsAiAssistantPanelVisible(false);
                        }}
                      />
                    </Tooltip>
                  </Space>

                  {textWithDelay && <Text>{textWithDelay}</Text>}

                  {isTyping && (
                    <span
                      ref={spanRef}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        animation: "blink 0.5s linear infinite",
                      }}
                    >
                      |
                    </span>
                  )}
                </Card>
              ) : (
                <Card
                  size="small"
                  style={{
                    marginTop: 5,
                    borderRadius: 5,
                  }}
                >
                  {isTyping ? (
                    <span
                      ref={spanRef}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        animation: "blink 0.5s linear infinite",
                      }}
                    >
                      |
                    </span>
                  ) : (
                    <>
                      <Space direction="vertical" size={2}>
                        <Text strong>Welcome to iNextLabs AI Assistant</Text>
                        <Text>
                          I'm an AI. I can generate text for you based on your
                          instructions.
                        </Text>
                      </Space>

                      <Space
                        style={{
                          marginTop: 7,
                        }}
                      >
                        <Text strong>Examples</Text>
                      </Space>
                      <Space direction="vertical" size={9}>
                        <Card
                          size="small"
                          style={{
                            cursor: "pointer",
                            borderRadius: 5,
                          }}
                          hoverable
                          onClick={() =>
                            setQuestion(
                              `Write an intro for me, I'm John a Live Agent in iNextLabs`
                            )
                          }
                        >
                          Write an intro for me, I'm John a Live Agent in
                          iNextLabs
                        </Card>

                        <Card
                          style={{
                            cursor: "pointer",
                            borderRadius: 5,
                          }}
                          size="small"
                          hoverable
                          onClick={() =>
                            setQuestion(
                              `Can you write a thank you note for customer getting in touch with me in casual tone?`
                            )
                          }
                        >
                          Can you write a thank you note for customer getting in
                          touch with me in casual tone?
                        </Card>
                      </Space>
                    </>
                  )}
                </Card>
              )}
            </Col>
          </Row>
        </div>
      )}
    >
      <Tooltip title="AI Assistant">
        <TbRobot
          size={23}
          style={{
            color: isAiAssistantPanelVisible ? "#F05742" : "rgb(108 107 107)",
            marginTop: "3px",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Dropdown>
  );
};

export default AiAssistantPanel;
