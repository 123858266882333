import { useGlobalContext } from "../../../contexts/globalContext";
import { Input, Form, Row, Col, Button, Divider, Space } from "antd";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";

const { TextArea } = Input;

const ConversationFlowForm = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  if (state.form["conversation-flow-form"].mode === "edit") {
    const recordToBeEdited = state.conversationFlow.flows.find(
      (flow) =>
        flow._id === state.filters.actionRowKey["conversation-flow-table"]
    );

    form.setFieldsValue({
      conversationFlowName: recordToBeEdited.conversation_flow_name,
      conversationFlowDescription:
        recordToBeEdited.conversation_flow_description,
      conversationFlowIntentPhrase:
        recordToBeEdited.conversation_flow_intent_phrase,
    });
  }

  const handleFormSubmit = (e) => {
    const formUserData = {
      conversation_flow_name: e.conversationFlowName,
      conversation_flow_description: e.conversationFlowDescription,
      conversation_flow_intent_phrase: e.conversationFlowIntentPhrase,
    };

    dispatchMiddleware({
      type: "MWD_ADD_EDIT_CONVERSATION_FLOW",
      payload: {
        flow: formUserData,
        component: "conversation-flow-form",
      },
    });
  };

  const resetFormField = () => {
    dispatchReducer({
      type: "CLOSE_FORM_PANEL",
      payload: {
        component: "conversation-flow-form-panel",
      },
    });
    dispatchReducer({
      type: "SET_FORM_MODE",
      payload: {
        component: "conversation-flow-form",
        mode: "",
        title: "",
      },
    });
    dispatchReducer({
      type: "RD_CLEAR_ACTION_ROW_KEY",
      payload: { component: "conversation-flow-table" },
    });
  };

  return (
    <>
      {state.apiCallStatus["conversation-flow-form"] &&
      state.apiCallStatus["conversation-flow-form"].loading ? (
        <FullPageLoader
          component="conversation-flow-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleFormSubmit}
          form={form}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="conversationFlowName"
                label="Conversation Flow Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter conversation flow name",
                  },
                  { whitespace: true },

                  {
                    max: 100,
                    message: "Name should not be more then 100 characters",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Enter conversation flow name" />
              </Form.Item>

              <Form.Item
                name="conversationFlowDescription"
                label="Conversation Flow Description"
                rules={[
                  {
                    required: true,
                    message: "Please enter conversation flow description",
                  },
                  { whitespace: true },
                  {
                    max: 500,
                    message:
                      "Description should not be more then 500 characters",
                  },
                ]}
                hasFeedback
              >
                <TextArea placeholder="Enter description" rows="5" />
              </Form.Item>

              <Form.Item
                name="conversationFlowIntentPhrase"
                label="Conversation Flow Intent Phrase"
                rules={[
                  {
                    required: true,
                    message: "Please enter conversation flow intent phrase",
                  },
                  { whitespace: true },
                  {
                    max: 500,
                    message:
                      "Description should not be more then 500 characters",
                  },
                ]}
                hasFeedback
              >
                <TextArea placeholder="Enter intent phrase" rows="5" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={12}>
              <Space>
                <Form.Item>
                  <Button onClick={resetFormField}>Cancel</Button>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ConversationFlowForm;
