import { Space, Image, Tooltip, Modal, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { BiConversation } from "react-icons/bi";
import { useGlobalContext } from "../../contexts/globalContext";
import { getUserDateTime } from "../../utils/utility";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import {
  channels,
  responseStatus,
  responseDropdownColumns,
} from "../../constants/enums";

const { confirm } = Modal;
const { Text } = Typography;

const useClientResponsesTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  let responseCategoriesCollapsed =
    state.clientResponses.responseCategoriesCollapsed;

  let enums = state.application.enums?.responses?.data || [];

  //Load users
  let users = state.accountUsers.users?.map((user) => user._id);
  //Add unassigned value beginning of users
  users.unshift("unassigned");

  //Create client response columns as JSON
  let clientResponsesColumns =
    state.responseSettings.responseProperties[0]?.allproperties?.map((key) => ({
      key,
      ellipsis: true,
    }));

  //Check column contains status, assigned to if no means to add column
  let filteredDropdownColumns = responseDropdownColumns.filter(
    (responseColumn) =>
      !clientResponsesColumns?.some(
        (clientResponse) => clientResponse.key === responseColumn
      )
  );
  filteredDropdownColumns = filteredDropdownColumns?.map((dropdownColumn) => ({
    title: dropdownColumn,
    key: dropdownColumn,
    ellipsis: true,
  }));
  clientResponsesColumns.push(...filteredDropdownColumns);

  //Merge responses and enums based on id

  clientResponsesColumns = clientResponsesColumns?.map(
    (clientResponseColumn) => {
      const matchedClientResponseColumn = enums.find(
        (enumKey) => enumKey._id === clientResponseColumn.key
      );
      if (matchedClientResponseColumn) {
        const { display_name, display, display_order, filter, width } =
          matchedClientResponseColumn;
        clientResponseColumn.title = display_name ?? clientResponseColumn.key;
        clientResponseColumn.display = display;
        clientResponseColumn.displayOrder = display_order
          ? parseInt(display_order)
          : null;
        clientResponseColumn.filter = filter;
        clientResponseColumn.width = width ? parseInt(width) : 100;
      } else {
        clientResponseColumn.title = clientResponseColumn.key;
      }
      return clientResponseColumn;
    }
  );

  //Filtering client response column based on display property
  clientResponsesColumns = clientResponsesColumns?.filter(
    (clientResponseColumn) =>
      clientResponseColumn.display !== false &&
      clientResponseColumn.display !== undefined
  );

  //Client responses columns Sort
  clientResponsesColumns = clientResponsesColumns?.sort((a, b) => {
    if (a.displayOrder && b.displayOrder) {
      return (a.displayOrder || 0) - (b.displayOrder || 0);
    } else {
      return (b.displayOrder || 0) - (a.displayOrder || 0);
    }
  });

  //Get first 6 columns
  clientResponsesColumns = clientResponsesColumns?.splice(
    0,
    responseCategoriesCollapsed ? 7 : 6
  );

  //This is the array that contains the keys of the client response columns that contains array/object
  let keysOfClientResponseColumnCointainsArray = [];
  //This is the array that contains the keys of the client response columns that contains non array/object
  let keysOfClientResponseColumnCointainsNonArray = [];

  state.clientResponses.responses?.data.forEach((column) => {
    Object.entries(column).forEach(([key, value]) => {
      if (Array.isArray(value) || typeof value === "object") {
        if (!keysOfClientResponseColumnCointainsArray?.includes(key)) {
          keysOfClientResponseColumnCointainsArray.push(key);
        }
      } else {
        if (!keysOfClientResponseColumnCointainsNonArray?.includes(key)) {
          keysOfClientResponseColumnCointainsNonArray.push(key);
        }
      }
    });
  });

  //Render client response columns
  const renderClientResponsesColumns = (
    columns,
    keysOfClientResponseColumnCointainsArray,
    keysOfClientResponseColumnCointainsNonArray
  ) => {
    return columns?.map((column) => {
      if (column.key === "channel") {
        return {
          ...column,
          render: (_, record) => {
            const channel = record.channel?.toUpperCase();
            return (
              channels[channel] && (
                <Image
                  style={{ marginLeft: 23 }}
                  src={channels[channel].logo}
                  width={23}
                  preview={false}
                />
              )
            );
          },
          filters: Object.keys(channels)
            ?.filter((channel) => channel !== "WEBCHAT" && channel !== "GBP") // Filter only the "WEBCHAT", "GBP" channel
            ?.map((channel) => {
              return {
                text: (
                  <Space>
                    <Image src={channels[channel].logo} width={15} />
                    {channels[channel].displayName}
                  </Space>
                ),
                value: channel,
              };
            }),

          filteredValue:
            (state.filters.filteredInfo["client-response-table"] &&
              state.filters.filteredInfo["client-response-table"].channel) ||
            null,
        };
      } else if (column.key === "responseDate") {
        return {
          ...column,
          sorter: (a, b) => {
            let valueA = a.responseDate == null ? "" : a.responseDate;
            let valueB = b.responseDate == null ? "" : b.responseDate;
            return valueA.localeCompare(valueB);
          },
          render: (_, record) => {
            return (
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Text>
                  {getUserDateTime(
                    record.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </div>
            );
          },
          filters: [
            {
              text: <Space> Today</Space>,
              value: "today",
            },
            {
              text: <Space>Last 7 days</Space>,
              value: "sevendays",
            },
            {
              text: <Space>Last 30 days</Space>,
              value: "thirtydays",
            },
            {
              text: <Space>Last 60 days</Space>,
              value: "sixtydays",
            },
          ],
          filteredValue:
            (state.filters.filteredInfo["client-response-table"] &&
              state.filters.filteredInfo["client-response-table"]
                .responseDate) ||
            null,
        };
      } else if (column.key === "leadDate") {
        return {
          ...column,
          sorter: (a, b) => {
            let valueA = a.leadDate == null ? "" : a.leadDate;
            let valueB = b.leadDate == null ? "" : b.leadDate;
            return valueA.localeCompare(valueB);
          },
          render: (_, record) => {
            return (
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Text>
                  {getUserDateTime(
                    record.leadDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </div>
            );
          },
          filters: [
            {
              text: <Space> Today</Space>,
              value: "today",
            },
            {
              text: <Space>Last 7 days</Space>,
              value: "sevendays",
            },
            {
              text: <Space>Last 30 days</Space>,
              value: "thirtydays",
            },
            {
              text: <Space>Last 60 days</Space>,
              value: "sixtydays",
            },
          ],
          filteredValue:
            (state.filters.filteredInfo["client-response-table"] &&
              state.filters.filteredInfo["client-response-table"].leadDate) ||
            null,
        };
      } else if (column.key === "userProfileName") {
        return {
          ...column,
          sorter: (a, b) => {
            let valueA = a.userProfileName == null ? "" : a.userProfileName;
            let valueB = b.userProfileName == null ? "" : b.userProfileName;
            return valueA.localeCompare(valueB);
          },
          render: ({ userProfileName }) => {
            return (
              <Text ellipsis={true}>
                {userProfileName ? userProfileName : "-"}
              </Text>
            );
          },
        };
      } else if (column.key === "userEmail") {
        return {
          ...column,
          sorter: (a, b) => {
            let valueA = a.userEmail == null ? "" : a.userEmail;
            let valueB = b.userEmail == null ? "" : b.userEmail;
            return valueA.localeCompare(valueB);
          },
          render: ({ userEmail }) => {
            return <Text ellipsis>{userEmail ? userEmail : "-"}</Text>;
          },
        };
      } else if (column.key === "broadcastName") {
        return {
          ...column,
          sorter: (a, b) => {
            let valueA = a.broadcastName == null ? "" : a.broadcastName;
            let valueB = b.broadcastName == null ? "" : b.broadcastName;
            return valueA.localeCompare(valueB);
          },
          render: ({ broadcastName }) => {
            return <Text ellipsis>{broadcastName ? broadcastName : "-"}</Text>;
          },
        };
      } else if (
        keysOfClientResponseColumnCointainsArray?.includes(column.key)
      ) {
        return {
          ...column,
          render: (_, record) => {
            return (
              <Space>
                {column.key ? (
                  <Text
                    style={{ color: "#F05742", cursor: "pointer" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "client-responses-table",
                          actionRowKey: record._id,
                        },
                      });

                      dispatchReducer({
                        type: "OPEN_INFO_PANEL",
                        payload: {
                          component: "client-response-info-panel",
                        },
                      });

                      dispatchMiddleware({
                        type: "MWD_LOAD_CONTACT_MANAGER_DATA",
                        payload: { component: "contact-manager-table" },
                      });
                    }}
                  >
                    Show more
                  </Text>
                ) : (
                  "-"
                )}
              </Space>
            );
          },
        };
      } else if (column.key === "assigned") {
        return {
          ...column,
          render: ({ assigned }) => {
            return <Text ellipsis>{assigned ? assigned : "-"}</Text>;
          },
          filters: users?.map((assigned) => {
            return {
              text: assigned,
              value: assigned,
            };
          }),
          filteredValue:
            (state.filters.filteredInfo["client-response-table"] &&
              state.filters.filteredInfo["client-response-table"].assigned) ||
            null,
        };
      } else if (column.key === "status") {
        return {
          ...column,
          render: ({ status }) => {
            return <Text ellipsis>{status ? status : "-"}</Text>;
          },
          filters: responseStatus?.map((status) => {
            return {
              text: status.key,
              value: status.value,
            };
          }),
          filteredValue:
            (state.filters.filteredInfo["client-response-table"] &&
              state.filters.filteredInfo["client-response-table"].status) ||
            null,
        };
      } else if (
        keysOfClientResponseColumnCointainsNonArray.includes(column.key) ||
        !keysOfClientResponseColumnCointainsNonArray.includes(column.key)
      ) {
        return {
          ...column,
          render: (_, record) => {
            return (
              <>
                {record[column.key] ? (
                  <Text ellipsis>
                    {typeof record[column.key] === "boolean"
                      ? `${record[column.key]}`
                      : record[column.key]}
                  </Text>
                ) : (
                  "-"
                )}
              </>
            );
          },
        };
      }

      return column;
    });
  };

  clientResponsesColumns = renderClientResponsesColumns(
    clientResponsesColumns,
    keysOfClientResponseColumnCointainsArray,
    keysOfClientResponseColumnCointainsNonArray
  );

  //Change the title of the columns to uppercase
  clientResponsesColumns?.forEach((column) => {
    column.title = column.title.toUpperCase();
  });

  let dataColumns = clientResponsesColumns;

  // Check current bot is notify bot or not
  const isNotifyBot =
    state.currentAccount.account.bots
      .find((bot) => bot.bot_id === state.application.botInAction)
      ?.bot_version?.toUpperCase() === "V0.1";

  const actionColumns = [
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      ellipsis: true,
      onCell: () => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },
      render: (_, record) => {
        return (
          <Space size={20}>
            {!isNotifyBot && (
              <Space>
                <Tooltip
                  placement="topLeft"
                  title={
                    state.currentUser.permission.RESPONSE_VIEW_CONVERSATION
                      ? "View Conversation"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.RESPONSE_VIEW_CONVERSATION ? (
                    <BiConversation
                      size="16px"
                      style={{
                        color: "#f67665",
                        cursor: "pointer",
                        marginTop: 2,
                      }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_CONVERSATION_FILTER",
                          payload: {
                            conversationFilter: true,
                          },
                        });

                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component:
                              "client-responses-view-conversation-info-panel",
                          },
                        });

                        // Clear existing conversation
                        if (state.conversations.contactsWithTranscripts) {
                          dispatchReducer({
                            type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
                          });
                          dispatchReducer({
                            type: "RD_CLEAR_CURRENT_CONVERSATION",
                          });
                        }

                        // Load selected conversation based on response
                        dispatchMiddleware({
                          type: "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS",
                          payload: {
                            conversationId: record.conversationId,
                            botId: record.botId ?? record.bot_id,
                            component: "conversations-layout",
                            contactId:
                              record.conversationId + "_" + record.botId,
                            leadDate: record.leadDate ?? record.responseDate,
                            category: record.category,
                          },
                        });
                      }}
                    />
                  ) : (
                    <BiConversation
                      style={{ color: "#C0C0C0", marginTop: 2 }}
                    />
                  )}
                </Tooltip>
              </Space>
            )}

            <Space>
              {state.apiCallStatus[
                "client-responses-" + record._id + "-delete"
              ] &&
              state.apiCallStatus["client-responses-" + record._id + "-delete"]
                .loading ? (
                <ComponentLoader
                  component={"client-responses-" + record._id + "-delete"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission.RESPONSE_DELETE
                      ? "Delete Response"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.RESPONSE_DELETE ? (
                    <DeleteOutlined
                      style={{ color: "#F05742" }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_ACTION_ROW_KEY",
                          payload: {
                            component: "client-responses-table",
                            actionRowKey: record._id,
                          },
                        });
                        confirm({
                          title:
                            "Are you sure you want to delete the response?",
                          content:
                            "When clicked the OK button, the response will be deleted permanently",
                          onOk() {
                            dispatchMiddleware({
                              type: "MWD_DELETE_SINGLE_RESPONSE",
                              payload: {
                                component:
                                  "client-responses-" + record._id + "-delete",
                                actionRowKey: record._id,
                              },
                            });
                          },
                          onCancel() {},
                        });
                      }}
                    />
                  ) : (
                    <DeleteOutlined style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
              )}
            </Space>
          </Space>
        );
      },
    },
  ];

  return { dataColumns, actionColumns };
};

export default useClientResponsesTableColumns;
