import { useEffect } from "react";
import { Layout, List, Input, Row, Col } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const ClientResponsesMenu = () => {
  const [state, dispatchReducer] = useGlobalContext();

  const theme = "light";

  let responseCategoriesList = state.clientResponses.responseCategories;
  const currentResponseCategory = state.clientResponses.currentResponseCategory;
  let responseCategoriesCollapsed =
    state.clientResponses.responseCategoriesCollapsed;

  const searchText =
    state.filters.searchText["client-responses-categories-search-text-box"];

  if (searchText) {
    responseCategoriesList = responseCategoriesList.filter((category) => {
      return category.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  const handleCollapseResponseCategories = () => {
    dispatchReducer({
      type: "RD_SET_RESPONSE_CATEGORIES_COLLAPSED",
      payload: {
        responseCategoriesCollapsed: !responseCategoriesCollapsed,
      },
    });
  };

  useEffect(() => {
    dispatchReducer({
      type: "RD_SET_CURRENT_RESPONSE_CATEGORY",
      payload: {
        currentResponseCategory: responseCategoriesList?.[0],
      },
    });
    return () => {
      dispatchReducer({ type: "RD_CLEAR_CURRENT_RESPONSE_CATEGORY" });
      dispatchReducer({
        type: "CLOSE_INFO_PANEL",
        payload: {
          component: "conversation-info-panel",
        },
      });
      dispatchReducer({
        type: "RD_SET_SEARCH_TEXT",
        payload: {
          component: "client-responses-categories-search-text-box",
          searchText: undefined,
        },
      });
      dispatchReducer({
        type: "RD_SET_RESPONSE_CATEGORIES_COLLAPSED",
        payload: {
          responseCategoriesCollapsed: false,
        },
      });
      dispatchReducer({
        type: "RD_SET_FILTERED_INFO",
        payload: {
          component: "client-response-table",
          filteredInfo: {},
        },
      });
      dispatchReducer({
        type: "SHOW_HIDE_FILTER_PANEL",
        payload: {
          component: "client-response-table",
          visible: false,
        },
      });
    };
  }, []);

  return (
    <>
      {responseCategoriesList ? (
        <>
          <Sider
            collapsed={responseCategoriesCollapsed}
            collapsedWidth="10"
            width="190"
            theme={theme}
            style={{
              borderRight: "1px solid #f0f2f5",
              cursor: responseCategoriesCollapsed ? "pointer" : "default",
            }}
            onClick={() =>
              responseCategoriesCollapsed && handleCollapseResponseCategories()
            }
          >
            {responseCategoriesCollapsed ? (
              <Row>
                <Col span={20}>
                  <DoubleRightOutlined
                    style={{
                      color: "#C0C0C0",
                      margin: "10px 0 0 5px",
                    }}
                    onClick={() => handleCollapseResponseCategories()}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={[4, 0]} style={{ marginBottom: 20 }}>
                <Col span={20}>
                  <Input
                    size="small"
                    prefix={<SearchOutlined />}
                    placeholder="Search"
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      dispatchReducer({
                        type: "RD_SET_SEARCH_TEXT",
                        payload: {
                          component:
                            "client-responses-categories-search-text-box",
                          searchText: currentValue,
                        },
                      });
                    }}
                    value={
                      state.filters.searchText[
                        "client-responses-categories-search-text-box"
                      ] || ""
                    }
                    allowClear={{
                      clearIcon: (
                        <CloseOutlined
                          onClick={() => {
                            dispatchReducer({
                              type: "RD_SET_SEARCH_TEXT",
                              payload: {
                                component:
                                  "client-responses-categories-search-text-box",
                                searchText: undefined,
                              },
                            });
                          }}
                        />
                      ),
                    }}
                  />
                </Col>
                <Col span={3}>
                  <DoubleLeftOutlined
                    style={{ margin: "4px 0 0 5px", color: "#C0C0C0" }}
                    onClick={() => {
                      handleCollapseResponseCategories();
                    }}
                  />
                </Col>
              </Row>
            )}

            {!responseCategoriesCollapsed && (
              <List
                style={{
                  overflow: "auto",
                  height: "630px",
                }}
                theme={theme}
                dataSource={responseCategoriesList}
                renderItem={(responseCategoryInLoop, index) => (
                  <List.Item
                    key={index}
                    style={{
                      color: "#000000",
                      cursor: "pointer",
                      fontWeight:
                        responseCategoryInLoop === currentResponseCategory &&
                        "bold",
                      backgroundColor:
                        responseCategoryInLoop === currentResponseCategory &&
                        "#FAFBFA",
                      paddingLeft: 10,
                    }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_CURRENT_RESPONSE_CATEGORY",
                        payload: {
                          currentResponseCategory: responseCategoryInLoop,
                        },
                      });
                      dispatchReducer({
                        type: "RD_SET_FILTERED_INFO",
                        payload: {
                          component: "client-response-table",
                          filteredInfo: {},
                        },
                      });
                      dispatchReducer({
                        type: "RD_SET_SEARCH_TEXT",
                        payload: {
                          component: "client-responses-search-text-box",
                          searchText: undefined,
                        },
                      });
                      dispatchReducer({
                        type: "RD_CLEAR_SELECTED_ROW_KEYS",
                        payload: {
                          component: "client-response-table-selected-row",
                        },
                      });
                      dispatchReducer({
                        type: "RD_CLEAR_PAGINATION",
                        payload: {
                          component: "client-response-table",
                        },
                      });
                    }}
                  >
                    {responseCategoryInLoop}
                  </List.Item>
                )}
              />
            )}
          </Sider>
        </>
      ) : (
        <FullPageLoader
          component="client-responses-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default ClientResponsesMenu;
