import React, { useState } from "react";
import {
  Drawer,
  Typography,
  Row,
  Col,
  Space,
  Input,
  Select,
  Divider,
  Button,
  Form,
  Card,
  Timeline,
} from "antd";
import { ticketPriorities, ticketStatus } from "../../constants/enums";
import { useGlobalContext } from "../../contexts/globalContext";
import { GoDotFill } from "react-icons/go";
import { IoCreateOutline } from "react-icons/io5";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { CgArrowsExchange } from "react-icons/cg";
import { getUserDateTime } from "../../utils/utility";
import "./Tickets.less";

const { Text, Paragraph, Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const TicketInfoPanel = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const [currentContent, setCurrentContent] = useState("basic");
  const [selectedStatusOption, setSelectedStatusOption] = useState("open");

  const selectedTicket = state.tickets.find(
    (ticket) =>
      ticket.ticketNumber === state.filters.actionRowKey["tickets-table"]
  );

  const handleTicketForm = (e) => {
    const ticketDetails = {
      subject: e.subject,
      description: e.description,
      assignedTo: e.assignedTo,
      status: e.status,
      priority: e.priority,
    };
  };

  const TicketHistory = () => (
    <Timeline mode="alternate" style={{ width: "100%" }}>
      {selectedTicket.history.map((historyDetails, index) => (
        <Timeline.Item key={index} dot=" ">
          {historyDetails.action === "created" && (
            <div className="history-container">
              <IoCreateOutline
                className={` ${
                  index % 2 === 0
                    ? "right-history-action-icons"
                    : "left-history-action-icons"
                }`}
              />
              <Typography.Text
                className={`${
                  index % 2 === 0 ? "left-history-arrow" : "right-history-arrow"
                }`}
              ></Typography.Text>

              <Card
                hoverable
                style={{
                  width: "100%",
                  marginTop: -30,
                  marginLeft: ` ${index % 2 === 0 ? "15px" : "30px"}`,
                }}
                size="small"
              >
                <Space direction="vertical">
                  <Text strong>Ticket Created</Text>

                  <Text>
                    Created By:{" "}
                    {historyDetails.performedBy.user
                      ? historyDetails.performedBy.user.name
                      : "-"}
                  </Text>

                  <Text
                    type="secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {getUserDateTime(
                      historyDetails.date,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                </Space>
              </Card>
            </div>
          )}

          {historyDetails.action === "assigned" && (
            <div className="history-container">
              <MdOutlineAssignmentTurnedIn
                className={` ${
                  index % 2 === 0
                    ? "right-history-action-icons"
                    : "left-history-action-icons"
                }`}
              />{" "}
              <Typography.Text
                className={`${
                  index % 2 === 0 ? "left-history-arrow" : "right-history-arrow"
                }`}
              ></Typography.Text>
              <Card
                hoverable
                style={{
                  marginTop: -30,
                  marginRight: ` ${index % 2 === 0 ? "15px" : "30px"}`,
                }}
                size="small"
              >
                <Space direction="vertical">
                  <Text strong>Ticket Assigned</Text>
                  <Text> Assigned By: {historyDetails.performedBy.name}</Text>
                  <Text>Assigned To: {historyDetails.assignedTo.name}</Text>
                  <Text
                    type="secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {getUserDateTime(
                      historyDetails.date,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                </Space>
              </Card>
            </div>
          )}

          {historyDetails.action === "statusChanged" && (
            <div className="history-container">
              <MdOutlinePublishedWithChanges
                className={` ${
                  index % 2 === 0
                    ? "right-history-action-icons"
                    : "left-history-action-icons"
                }`}
              />{" "}
              <Typography.Text
                className={`${
                  index % 2 === 0 ? "left-history-arrow" : "right-history-arrow"
                }`}
              ></Typography.Text>{" "}
              <Card
                hoverable
                style={{
                  marginTop: -30,
                  marginLeft: ` ${index % 2 === 0 ? "15px" : "-10px"}`,
                }}
                size="small"
              >
                <Space direction="vertical">
                  <Text strong>Ticket Status Changed</Text>

                  <Text>
                    Changed By: {" " + historyDetails.performedBy.name}
                  </Text>
                  <Text>
                    Changed To:{" "}
                    {historyDetails.status
                      .split(/(?=[A-Z])/)
                      .join(" ")
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </Text>

                  <Text
                    type="secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {getUserDateTime(
                      historyDetails.date,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                </Space>
              </Card>
            </div>
          )}

          {historyDetails.action === "subjectAndDescriptionChanged" && (
            <div className="history-container">
              <VscGitPullRequestGoToChanges
                className={` ${
                  index % 2 === 0
                    ? "right-history-action-icons"
                    : "left-history-action-icons"
                }`}
              />{" "}
              <Typography.Text
                className={`${
                  index % 2 === 0 ? "left-history-arrow" : "right-history-arrow"
                }`}
              ></Typography.Text>{" "}
              <Card
                hoverable
                style={{
                  marginTop: -30,
                  marginRight: ` ${index % 2 === 0 ? "15px" : "30px"}`,
                }}
                size="small"
              >
                <Space direction="vertical">
                  <Text strong>Subject and Description Changed</Text>
                  <Text>Changed By: {historyDetails.performedBy.name}</Text>
                  <Text
                    type="secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {getUserDateTime(
                      historyDetails.date,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                </Space>
              </Card>
            </div>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  );

  return (
    <Drawer
      title="Ticket Info"
      placement="right"
      width={720}
      style={{ padding: 0 }}
      destroyOnClose={true}
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "ticket-info-panel",
          },
        });
      }}
      open={
        state.infoPanel["ticket-info-panel"] &&
        state.infoPanel["ticket-info-panel"].visible
      }
    >
      {selectedTicket && (
        <Form
          layout="vertical"
          onFinish={handleTicketForm}
          autoComplete="off"
          requiredMark={false}
          initialValues={{
            description: selectedTicket?.description,
            subject: selectedTicket?.subject,
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Row gutter={[0, 16]} align="bottom">
              <Col span={2}>
                <Text
                  style={{
                    fontSize: 20,
                  }}
                >
                  {selectedTicket.ticketNumber}
                </Text>
              </Col>
              <Col span={22}>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please enter subject",
                    },
                    {
                      max: 100,
                      message: "Subject should not exceed 100 characters",
                    },
                  ]}
                  className="tickets-form-item"
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <Input placeholder="Enter Subject" size="middle" />
                </Form.Item>
              </Col>

              <Col span={15}>
                <Row align="middle">
                  <Col span={6}>
                    <Text>ASSIGNED TO</Text>
                  </Col>
                  <Col span={2}> :</Col>
                  <Col span={15}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select User"
                      style={{
                        width: 200,
                      }}
                    >
                      {(state.accountUsers.users || []).map((user) => (
                        <Option key={user._id} value={user._id}>
                          {user._id}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>

              <Col span={9}>
                <Row align="middle">
                  <Col span={6}>
                    <Text>STATUS</Text>
                  </Col>

                  <Col span={1}> :</Col>
                  <Col span={17}>
                    <Space
                      style={{
                        width: "100%",
                      }}
                    >
                      <GoDotFill
                        style={{
                          fontSize: 20,
                          color:
                            selectedStatusOption === "closed"
                              ? "green"
                              : selectedStatusOption === "open"
                              ? "red"
                              : selectedStatusOption === "pending"
                              ? "yellow"
                              : " #00FF00",
                          marginTop: 5,
                        }}
                      />
                      <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue="Open"
                        style={{
                          width: 120,
                        }}
                        onChange={(value) => setSelectedStatusOption(value)}
                      >
                        {Object.keys(ticketStatus).map((status) => {
                          return (
                            <Option key={status}>{ticketStatus[status]}</Option>
                          );
                        })}
                      </Select>
                    </Space>
                  </Col>
                </Row>
              </Col>

              <Col span={15}>
                <Row align="middle">
                  <Col span={6}>PRIORITY</Col>
                  <Col span={2}> :</Col>
                  <Col span={15}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue="High"
                      style={{ width: 140 }}
                    >
                      {Object.keys(ticketPriorities).map((priority) => {
                        return (
                          <Option key={priority}>
                            {ticketPriorities[priority]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Col>

              <Col span={9}>
                <Space
                  size={24}
                  style={{
                    float: "right",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      color: currentContent === "basic" ? "#f05742" : "black",
                      borderBottom:
                        currentContent === "basic"
                          ? "2px solid #f05742"
                          : "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setCurrentContent("basic")}
                  >
                    Details
                  </Text>

                  <Text
                    style={{
                      color: currentContent === "history" ? "#f05742" : "black",
                      borderBottom:
                        currentContent === "history"
                          ? "2px solid #f05742"
                          : "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setCurrentContent("history")}
                  >
                    History
                  </Text>
                </Space>
              </Col>

              <Divider
                style={{
                  marginTop: 0,
                }}
              />
            </Row>

            {currentContent === "basic" ? (
              <Row>
                <Col span={24}>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                      }}
                      strong
                    >
                      Description{" "}
                    </Text>

                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please enter description",
                        },
                      ]}
                    >
                      <TextArea
                        style={{ width: "100%" }}
                        rows={5}
                        maxLength={1000}
                        showCount
                      />
                    </Form.Item>
                  </Space>
                </Col>

                <Col span={24}>
                  <Divider
                    style={{
                      marginTop: 3,
                    }}
                  />

                  <Space>
                    <Button>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            ) : (
              <TicketHistory />
            )}
          </Space>
        </Form>
      )}
    </Drawer>
  );
};

export default TicketInfoPanel;
