import { Area } from "@ant-design/plots";
import { Typography, Tooltip, Row, Col, Empty } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const UnrecognizedIntentsCountByDateChart = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchMiddleware({
        type: "LOAD_UNRECOGNIZED_INTENTS_COUNT_BY_DATE",
        payload: {
          component: "analytics-unrecognized-intents-count-by-date-chart",
          analyticsFilterParams: {
            dateFilter: state.analytics.filterByDateForCharts,
            channelFilter: state.analytics.filterByChannelForCharts,
          },
        },
      });
    },
    {
      date: state.analytics.filterByDateForCharts,
      channel: state.analytics.filterByChannelForCharts,
      botInAction: state.application.botInAction,
    }
  );

  const config = {
    smooth: true,
    data: state.analytics.knowledgeAreaCharts.unrecognizedIntentsByDate,
    xField: "_id",
    yField: "count",
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    color: "#a8071a",
    slider: {
      start: 0.1,
      end:
        state.analytics.knowledgeAreaCharts.unrecognizedIntentsByDate &&
        state.analytics.knowledgeAreaCharts.unrecognizedIntentsByDate.length,
    },
  };

  return (
    <>
      {state.analytics.knowledgeAreaCharts.unrecognizedIntentsByDate ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> Unanswered Questions By Date</Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This statistic shows the count of unanswered questions by date."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.knowledgeAreaCharts.unrecognizedIntentsByDate
            .length ? (
            <Area {...config} style={{ height: "230px" }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-unrecognized-intents-count-by-date-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default UnrecognizedIntentsCountByDateChart;
