const currentAccountReducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_ACCOUNT":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...action.payload.account,
          },
        },
      };

    case "RD_ACCOUNT_API_SET_API_KEY":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{ apiKey: action.apiKey },
        },
      };

    case "RD_SET_PUBSUB_TOKEN_URL":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{ pubSubTokenUrl: action.payload.pubSubTokenUrl },
        },
      };

    case "RD_ACCOUNT_API_GENERATE_API_KEY":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{ apiKey: action.apiKey },
        },
      };

    case "RD_ACCOUNT_API_REGENERATE_API_KEY":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{ apiKey: action.apiKey.primarykey },
        },
      };

    case "RD_UPDATE_BOT_DETAILS":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...{
              botSetting: state.currentAccount.account.bots,
              ...action.payload.botSetting,
            },
          },
        },
      };

    case "RD_SET_WHATSAPP_USAGE_DATA":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{ whatsappUsage: action.payload.whatsappUsage },
        },
      };

    case "SHOW_CURRENT_BALANCE_DETAILS":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{
            whatsappUsage: {
              ...state.currentAccount.whatsappUsage,
              ...{ showCurrentBalance: true },
            },
          },
        },
      };

    case "CLOSE_CURRENT_BALANCE_DETAILS":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{
            whatsappUsage: {
              ...state.currentAccount.whatsappUsage,
              ...{ showCurrentBalance: false },
            },
          },
        },
      };

    case "RD_CLEAR_WHATSAPP_USAGE_DATA":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...{ whatsappUsage: undefined },
        },
      };

    case "SET_APP_REGISTRATION_DETAILS":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...{ app_registration: action.payload.appRegistrationValues },
          },
        },
      };

    case "EDIT_APP_REGISTRATION_DETAILS":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...{
              app_registration: {
                ...state.currentAccount.account.app_registration,
                ...action.payload.appRegistrationValues,
              },
            },
          },
        },
      };

    case "DELETE_APP":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...{ app_registration: null },
          },
        },
      };

    case "REGENERATE_APP_SECRET":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...{
              app_registration: {
                ...state.currentAccount.account.app_registration,
                ...action.payload.secretDetails,
              },
            },
          },
        },
      };

    case "SET_APP_REGISTRATION_ACCESS_TOKEN":
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            ...{
              app_registration: {
                ...state.currentAccount.account.app_registration,
                ...action.payload.accessTokenDetails,
                access_token_expiry:
                  action.payload.accessTokenDetails.access_token_expiry,
                refresh_token_expiry:
                  action.payload.accessTokenDetails.refresh_token_expiry,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default currentAccountReducer;
