import { Col, Row, Space, Image, Typography, Button, List } from "antd";

const { Text } = Typography;

const ConversationWHATSAPP = ({ transcript }) => {
  return (
    <Space size={10} style={{ alignItems: "start" }}>
      {transcript.content && (
        <Row>
          <Col span={24}>
            <Space
              size={10}
              direction="vertical"
              style={{
                width: 300,
                border: "1px solid #C0C0C0",
                padding: 10,
                borderRadius: 10,
              }}
            >
              {transcript.content.header && transcript.content.header.image && (
                <Image
                  preview={false}
                  src={transcript.content.header.image.link}
                />
              )}
              {transcript.content.header && transcript.content.header.text && (
                <Text strong>{transcript.content.header.text}</Text>
              )}
              {transcript.content.body && (
                <Text>{transcript.content.body.text}</Text>
              )}
              {transcript.content.action?.buttons &&
                transcript.content.action.buttons.map((button, index) => {
                  return (
                    <Button style={{ borderRadius: 5 }} key={index}>
                      {button.reply.title}
                    </Button>
                  );
                })}
              {transcript.content.action?.name === "flow" &&
                transcript.content.action?.parameters?.flow_cta && (
                  <Button style={{ borderRadius: 5 }}>
                    {transcript.content.action?.parameters?.flow_cta}
                  </Button>
                )}
            </Space>
          </Col>
        </Row>
      )}

      {/* For WhatsApp flows the message is sent as a stringified JSON object */}
      {transcript.is_from_user &&
        transcript.message?.[0] === "{" &&
        transcript.message[transcript.message.length - 1] === "}" && (
          <List
            dataSource={Object.entries(JSON.parse(transcript.message))
              .filter(([key]) => !["flow_token"].includes(key))
              .map(([key, value]) => ({
                key: key
                  .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase
                  .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize first letter of each word

                value,
              }))}
            renderItem={({ key, value }) => (
              <List.Item
                key={key}
                style={{
                  padding: 5,
                  margin: 0,
                }}
              >
                <Text strong>{key}:</Text>{" "}
                {Array.isArray(value) && typeof value[0] === "string"
                  ? value?.map((item, index) => (
                      <p
                        key={index}
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {index + 1 + ". " + item}
                      </p>
                    ))
                  : // If value is a array of objects, display it as a JSON string
                  Array.isArray(value) && typeof value[0] === "object"
                  ? JSON.stringify(value)
                  : value}
              </List.Item>
            )}
            style={{
              width: 300,
              border: "1px solid #C0C0C0",
              padding: 10,
              borderRadius: 10,
            }}
          />
        )}
    </Space>
  );
};

export default ConversationWHATSAPP;
