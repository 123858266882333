import React from "react";
import { Table, Tooltip, Row, Col, Typography, Empty } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const ConversationCountByLocation = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchMiddleware({
        type: "MWD_LOAD_CONVERSATION_COUNT_BY_LOCATION_DATA",
        payload: {
          component: "analytics-conversation-by-location-table-chart",
          analyticsFilterParams: {
            dateFilter: state.analytics.filterByDateForCharts,
            channelFilter: state.analytics.filterByChannelForCharts,
          },
        },
      });
    },
    {
      date: state.analytics.filterByDateForCharts,
      channel: state.analytics.filterByChannelForCharts,
      botInAction: state.application.botInAction,
    }
  );

  const conversationLocationColumn = [
    {
      title: "CITY",
      dataIndex: "city",
      key: "city",
      width: 350,
      ellipsis: true,
    },
    {
      title: "COUNTRY",
      dataIndex: "country",
      key: "country",
      width: 350,
      ellipsis: true,
    },
    {
      title: "COUNT",
      dataIndex: "count",
      key: "count",
      width: 80,
      sorter: (a, b) => {
        let previousValue = a.count === null ? "" : a.count;
        let currentValue = b.count === null ? "" : b.count;
        return previousValue - currentValue;
      },
    },
  ];

  return (
    <>
      {state.analytics.conversationCharts.conversationByLocation ? (
        <>
          <Row>
            <Col span="23">
              <Text strong> Conversation By Location </Text>
            </Col>
            <Col span="1">
              <Tooltip
                placement="topRight"
                title="This section shows the client's location."
              >
                <IoIosInformationCircleOutline size="1.5em" />
              </Tooltip>
            </Col>
          </Row>
          {state.analytics.conversationCharts.conversationByLocation.length ? (
            <Table
              dataSource={
                state.analytics.conversationCharts.conversationByLocation
              }
              // expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}

              columns={conversationLocationColumn}
              rowKey="message"
              pagination={{ pageSize: 5 }}
              style={{
                padding: "0px 30px",
                height: "340px",
                overflowY: "auto",
              }}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginTop: "70px" }}
            />
          )}
        </>
      ) : (
        <ComponentLoader
          component="analytics-conversation-by-location-table-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ConversationCountByLocation;
