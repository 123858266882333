const filterPanel = (state, action) => {
  switch (action.type) {
    case "SHOW_HIDE_FILTER_PANEL":
      return {
        ...state,
        filterPanel: {
          ...state.filterPanel,
          ...{
            [action.payload.component]: {
              visible: action.payload.visible,
            },
          },
        },
      };

    default:
      break;
  }
};

export default filterPanel;
