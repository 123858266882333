import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Tooltip, Modal, Image, Row, Col, Typography } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { languageList } from "../../../constants/enums";
import { cannedResponsesAvailableFor } from "../../../constants/enums";

const { confirm } = Modal;
const { Text } = Typography;

const useCannedResponsesTableColumn = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const cannedResponsesDateColumn = [
    {
      title: "RESPONSES TITLE",
      dataIndex: "responseTitle",
      key: "responseTitle",
      fixed: "left",
      sorter: (a, b) => {
        let valueA = a.responseTitle == null ? "" : a.responseTitle;
        let valueB = b.responseTitle == null ? "" : b.responseTitle;
        return valueA.localeCompare(valueB);
      },
      width: 150,
      ellipsis: true,
    },
    {
      title: "RESPONSES MESSAGE",
      dataIndex: "responseMessage",
      key: "responseMessage",
      fixed: "left",
      render: (_, record) => {
        return (
          <div>
            <Row>
              <Col span={22}>
                <Text ellipsis>{record.responseMessage}</Text>
              </Col>
            </Row>
          </div>
        );
      },

      sorter: (a, b) => {
        let valueA = a.responseMessage == null ? "" : a.responseMessage;
        let valueB = b.responseMessage == null ? "" : b.responseMessage;
        return valueA.localeCompare(valueB);
      },
      width: 300,
      ellipsis: true,
    },
    {
      title: "VISIBILITY",
      dataIndex: "availableFor",
      key: "availableFor",
      width: 120,
      ellipsis: true,
      fixed: "left",
      align: "center",
      render: (_, record) => {
        return (
          cannedResponsesAvailableFor[record.availableFor] && (
            <Image
              src={cannedResponsesAvailableFor[record.availableFor].logo}
              width={23}
              preview={false}
            />
          )
        );
      },
    },
    {
      title: "LANGUAGE",
      dataIndex: "language",
      key: "language",
      render: (_, record) => {
        const languageLabel = languageList.find(
          (language) => language.value === record.language
        )?.label;
        return <>{languageLabel}</>;
      },
      width: 120,
      ellipsis: true,
    },
  ];

  const cannedResponsesActionColumn = [
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: 80,
      render: (_, record) => {
        return (
          <>
            <Space size={24}>
              <Tooltip
                placement="top"
                title={
                  state.currentUser.permission.CANNED_RESPONSES_EDIT
                    ? "Edit Cannned Response"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission.CANNED_RESPONSES_EDIT ? (
                  <EditOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "canned-responses-table",
                          actionRowKey: record._id,
                        },
                      });
                      dispatchReducer({
                        type: "OPEN_FORM_PANEL",
                        payload: {
                          component: "canned-responses-form-panel",
                          title: "Edit Canned Responses",
                        },
                      });
                      dispatchReducer({
                        type: "SET_FORM_MODE",
                        payload: {
                          component: "canned-responses-form",
                          mode: "edit",
                        },
                      });
                    }}
                  />
                ) : (
                  <EditOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>

              {state.apiCallStatus[
                "canned-responses-" + record._id + "-delete"
              ] &&
              state.apiCallStatus["canned-responses-" + record._id + "-delete"]
                .loading ? (
                <ComponentLoader
                  component={"canned-responses-" + record._id + "-delete"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission.CANNED_RESPONSES_DELETE
                      ? "Delete Canned Response"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.CANNED_RESPONSES_DELETE ? (
                    <DeleteOutlined
                      style={{ color: "#F05742" }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_ACTION_ROW_KEY",
                          payload: {
                            component: "canned-responses-table",
                            actionRowKey: record._id,
                          },
                        });
                        confirm({
                          title:
                            "Are you sure you want to delete the canned response?",
                          content:
                            "When clicked the OK button, the canned response will be deleted permanently",
                          onOk() {
                            dispatchMiddleware({
                              type: "MWD_DELETE_CANNED_RESPONSE",
                              payload: {
                                component:
                                  "canned-responses-" + record._id + "-delete",
                                actionRowKey: record._id,
                              },
                            });
                          },
                          onCancel() {},
                        });
                      }}
                    />
                  ) : (
                    <DeleteOutlined style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return { cannedResponsesDateColumn, cannedResponsesActionColumn };
};

export default useCannedResponsesTableColumn;
