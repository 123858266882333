import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useLiveAgentSettingsMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************* LIVE AGENT SETTINGS *************////
      ////*******************************************////

      //Load live agent settings based on bots
      case "MWD_LOAD_LIVE_AGENT_SETTINGS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.LIVE_AGENT_SETTINGS.concat(
              "?botId=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([liveAgentResponse]) => {
            dispatchReducer({
              type: "RD_SET_LIVE_AGENT_SETTINGS",
              payload: {
                liveAgentResponse,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Agent settings Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Update live agent settings
      case "MWD_UPDATE_LIVE_AGENT_SETTINGS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.LIVE_AGENT_SETTINGS.concat(
              "?_id=",
              state.liveAgentSettings._id,
              "&botId=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            body: action.payload.updateLiveAgentSettings,
          },
          onSuccess: ([liveAgentResponse]) => {
            dispatchReducer({
              type: "RD_UPDATE_LIVE_AGENT_SETTINGS",
              payload: { liveAgentResponse: liveAgentResponse.liveAgent },
            });

            state.conversations.liveAgentSettingsAcrossBots &&
              dispatchReducer({
                type: "UPDATE_LIVE_AGENT_SETTINGS_ACROSS_BOTS",
                payload: {
                  updatedLiveAgentResponse: liveAgentResponse.liveAgent,
                },
              });

            openNotificationWithIcon(
              "success",
              "Live Agent settings have been successfully updated"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Agent Settings Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Load live agent settings across the bots
      case "LOAD_LIVE_AGENT_SETTINGS_ACROSS_BOTS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.LIVE_AGENT_SETTINGS.concat("?isConversation=true"),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([liveAgentSettingsAcrossBots]) => {
            dispatchReducer({
              type: "SET_LIVE_AGENT_SETTINGS_ACROSS_BOTS",
              payload: {
                liveAgentSettingsAcrossBots:
                  liveAgentSettingsAcrossBots.liveAgent,
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Agent Settings Across Bots Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
