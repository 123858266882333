import { useState } from "react";
import { Row, Col, Space, Tabs, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import WebhooksFormPanel from "./WebhooksFormPanel";
import WebhooksHeader from "./WebhooksHeader";
import WebhooksTable from "./WebhooksTable";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useGlobalContext } from "../../../contexts/globalContext";
import { CloudUploadOutlined } from "@ant-design/icons";
import AccessDenied from "../../../shared-components/AccessDenied";
import GetStarted from "../../../shared-components/GetStarted";

const Webhooks = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const [showWebhookScreen, setShowWebhookScreen] = useState(false);

  useConnectMiddleware(() => {
    state.currentUser.permission.WEBHOOKS_VIEW &&
      dispatchMiddleware({
        type: "MWD_LOAD_WEBHOOKS_LIST",
        payload: { component: "webhooks-table" },
      });
  });

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {!state.currentUser.permission.WEBHOOKS_VIEW ? (
        <AccessDenied />
      ) : state.webhooks.webhooksList &&
        state.webhooks.webhooksList.length === 0 &&
        !showWebhookScreen ? (
        <Layout.Content
          style={{
            height: "97%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GetStarted
            source="webhooks"
            setShowWebhookScreen={setShowWebhookScreen}
          />
        </Layout.Content>
      ) : state.webhooks.webhooksList || showWebhookScreen ? (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <CloudUploadOutlined size={18} style={{ margin: -4 }} />
                  Webhooks
                </Space>
              ),
              children: (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <WebhooksHeader />
                  </Col>
                  <Col span={24}>
                    <WebhooksTable />
                  </Col>
                  <WebhooksFormPanel />
                </Row>
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["webhooks-table"] &&
            state.apiCallStatus["webhooks-table"].loading && (
              <TabLoader
                component="webhooks-table"
                loadingMessage="Synchronizing webhooks..."
                errorMessage="Error synchronizing webhooks"
              />
            )
          }
        />
      ) : (
        <FullPageLoader
          component="webhooks-table"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default Webhooks;
