const responseSettingReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_RESPONSE_PROPERTIES":
      return {
        ...state,
        responseSettings: {
          ...state.responseSettings,
          ...{ responseProperties: action.payload.responseProperties },
        },
      };

    case "RD_SET_RESPONSE_SETTINGS_RESPONSE_CATEGORIES":
      return {
        ...state,
        responseSettings: {
          ...state.responseSettings,
          ...{ responseCategories: action.payload.sortedResponseCategories },
        },
      };

    case "RD_SET_RESPONSE_SETTINGS_CURRENT_RESPONSE_CATEGORY":
      return {
        ...state,
        responseSettings: {
          ...state.responseSettings,
          ...{
            currentResponseCategory: action.payload.currentResponseCategory,
          },
        },
      };

    default:
      return state;
  }
};

export default responseSettingReducer;
