import { useNavigate } from "react-router-dom";
import { Tabs, Space } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { MdOutlineLocalShipping } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AccessDenied from "../../../shared-components/AccessDenied";
import { Translation } from "../../../translation/translation";
import Lalamove from "./Lalamove";

const DeliveryServices = () => {
  const [state] = useGlobalContext();

  // Get translated text
  const lalamove = Translation().accountSettings.deliveryServices.lalamove;

  const navigate = useNavigate();

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        {lalamove.goBackToSettings}
      </Space>

      <Tabs
        type="card"
        items={[
          {
            key: "1",
            label: (
              <Space size={15}>
                <MdOutlineLocalShipping size={18} style={{ margin: -4 }} />
                {lalamove.title}
              </Space>
            ),
            children: state.currentUser.permission
              .ACCOUNT_LALAMOVE_CONFIGURATION_VIEW ? (
              <Lalamove />
            ) : (
              <AccessDenied />
            ),
          },
        ]}
      />
    </>
  );
};

export default DeliveryServices;
