import { Avatar, Typography, Select, Row, Col } from "antd";
import { botProfilePictures } from "../../constants/enums";
import { useGlobalContext } from "../../contexts/globalContext";

const { Text } = Typography;
const { Option } = Select;

const Bots = () => {
  const [state, dispatchReducer] = useGlobalContext();

  return (
    <Select
      placeholder="Select status"
      value={state.application.botInAction}
      style={{
        width: 250,
      }}
      size="large"
      onSelect={(botInAction) => {
        dispatchReducer({
          type: "RD_SET_BOT_IN_ACTION",
          payload: {
            botInAction,
            clientId: state.currentAccount.account.client_id,
            event: "globalBotOnChange",
          },
        });
      }}
    >
      {state.currentAccount.account.bots.length &&
        state.currentAccount.account.bots.map((bot, index) => {
          return (
            <Option value={bot.bot_id} key={index}>
              <Row>
                <Col span={5}>
                  <Avatar
                    src={botProfilePictures[bot.bot_icon]}
                    size="small"
                    style={{ marginTop: -3 }}
                  />
                </Col>
                <Col span={19}>
                  <Text>{bot.bot_name}</Text>
                </Col>
              </Row>
            </Option>
          );
        })}
    </Select>
  );
};

export default Bots;
