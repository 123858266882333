import { Image, Space, Typography, Tooltip, Modal } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { BiConversation } from "react-icons/bi";
import { channels, unrecognizedIntentsStatus } from "../../../constants/enums";
import { useGlobalContext } from "../../../contexts/globalContext";
import { ComponentLoader } from "../../../shared-components/LoadingIndicator";
import { getUserDateTime } from "../../../utils/utility";
import { Translation } from "../../../translation/translation";

const { Text } = Typography;
const { confirm } = Modal;

const UnrecognizedIntentsTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const translation = Translation().unrecognizedIntents.table;

  //Load users
  let users = state.accountUsers.users.map((user) => user._id);
  //Add unassigned value beginning of users
  users.unshift("unassigned");

  // Check current bot is notify bot or not
  const isNotifyBot =
    state.currentAccount.account.bots
      .find((bot) => bot.bot_id === state.application.botInAction)
      ?.bot_version?.toUpperCase() === "V0.1";

  const unrecognizedIntentsDataColumns = [
    {
      title: "TIMESTAMP",
      dataIndex: "timeStamp",
      key: "timeStamp",
      ellipsis: true,
      width: 150,
      render: (_, record) => {
        return getUserDateTime(
          record.timeStamp,
          state.currentUser.user.timeZone,
          state.currentUser.user.dateFormat
        );
      },
      filters: [
        {
          text: <Space>Today</Space>,
          value: "today",
        },
        {
          text: <Space>Last 7 days</Space>,
          value: "sevendays",
        },
        {
          text: <Space>Last 30 days</Space>,
          value: "thirtydays",
        },
        {
          text: <Space>Last 60 days</Space>,
          value: "sixtydays",
        },
      ],
      filteredValue:
        (state.filters.filteredInfo["unrecognized-intents-table"] &&
          state.filters.filteredInfo["unrecognized-intents-table"].timeStamp) ||
        null,
    },
    {
      title: "PHRASE",
      dataIndex: "phrase",
      key: "phrase",
      ellipsis: false,
      sorter: (a, b) => {
        let valueA = a.phrase == null ? "" : a.phrase;
        let valueB = b.phrase == null ? "" : b.phrase;
        return valueA.localeCompare(valueB);
      },
      width: 250,
    },
    {
      title: "CHANNEL",
      dataIndex: "channel",
      key: "channel",
      render: (_, record) => {
        const channel = record.channel?.toUpperCase();
        return (
          channels[channel] && (
            <Image src={channels[channel].logo} width={23} preview={false} />
          )
        );
      },
      filters: Object.keys(channels)
        ?.filter((channel) => channel !== "WEBCHAT" && channel !== "GBP") // Filter only the "WEBCHAT", "GBP" channel
        .map((key) => {
          return {
            text: (
              <Space>
                <Image src={channels[key].logo} width={15} />
                {channels[key].displayName}
              </Space>
            ),
            value: key,
          };
        }),
      filteredValue:
        (state.filters.filteredInfo["unrecognized-intents-table"] &&
          state.filters.filteredInfo["unrecognized-intents-table"].channel) ||
        null,
      width: 100,
      align: "center",
    },
    {
      title: "ASSIGNED TO",
      dataIndex: "assignedTo",
      key: "assignedTo",
      ellipsis: true,
      width: 170,
      render: (_, record) => {
        return (
          <Text ellipsis>{record.assignedTo ? record.assignedTo : "-"}</Text>
        );
      },
      filters: users.map((assignedTo) => {
        return {
          text: assignedTo,
          value: assignedTo,
        };
      }),
      filteredValue:
        (state.filters.filteredInfo["unrecognized-intents-table"] &&
          state.filters.filteredInfo["unrecognized-intents-table"]
            .assignedTo) ||
        null,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return <Text ellipsis>{record.status ? record.status : "-"}</Text>;
      },
      filters: unrecognizedIntentsStatus.map((status) => {
        return {
          text: status.key,
          value: status.key,
        };
      }),
      filteredValue:
        (state.filters.filteredInfo["unrecognized-intents-table"] &&
          state.filters.filteredInfo["unrecognized-intents-table"].status) ||
        null,
      ellipsis: true,
      width: 140,
    },
    {
      title: "NOTES",
      dataIndex: "notes",
      key: "notes",
      width: 200,
      render: (_, record) => {
        return <Space>{record.notes ? record.notes : "-"}</Space>;
      },
      ellipsis: false,
    },
  ];

  const unrecognizedIntentsActionColumn = [
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <Space size="middle">
            {!isNotifyBot && (
              <Space>
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission
                      .UNRECOGNIZED_INTENTS_VIEW_CONVERSATION
                      ? translation.viewConversation.infoText
                      : translation.noAccess
                  }
                >
                  {state.currentUser.permission
                    .UNRECOGNIZED_INTENTS_VIEW_CONVERSATION ? (
                    <BiConversation
                      size="16px"
                      style={{
                        color: "#f67665",
                        cursor: "pointer",
                        marginTop: 2,
                      }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_CONVERSATION_FILTER",
                          payload: {
                            conversationFilter: true,
                          },
                        });

                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component:
                              "client-responses-view-conversation-info-panel",
                          },
                        });

                        // Clear existing conversation
                        if (state.conversations.contactsWithTranscripts) {
                          dispatchReducer({
                            type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
                          });
                          dispatchReducer({
                            type: "RD_CLEAR_CURRENT_CONVERSATION",
                          });
                        }

                        // Load selected conversation based on unrecognized intent
                        dispatchMiddleware({
                          type: "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS",
                          payload: {
                            conversationId: record.conversationId,
                            botId: record.botId,
                            component: "conversations-layout",
                            contactId:
                              record.conversationId + "_" + record.botId,
                            leadDate: record.timeStamp,
                            category: "unrecognized-intents",
                          },
                        });
                      }}
                    />
                  ) : (
                    <BiConversation
                      style={{ color: "#C0C0C0", marginTop: 2 }}
                    />
                  )}
                </Tooltip>
              </Space>
            )}

            <Tooltip
              placement="topRight"
              title={
                state.currentUser.permission.UNRECOGNIZED_INTENTS_EDIT
                  ? translation.editUnrecognizedIntent.infoText
                  : translation.noAccess
              }
            >
              {state.currentUser.permission.UNRECOGNIZED_INTENTS_EDIT ? (
                <EditOutlined
                  style={{ color: "#F05742" }}
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_ACTION_ROW_KEY",
                      payload: {
                        component: "unrecognized-intents-table",
                        actionRowKey: record._id,
                      },
                    });
                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "unrecognized-intents-form-panel",
                        title: "Edit Unrecognized Intents",
                      },
                    });
                    dispatchReducer({
                      type: "SET_FORM_MODE",
                      payload: {
                        component: "unrecognized-intents-form",
                        mode: "edit",
                      },
                    });
                  }}
                />
              ) : (
                <EditOutlined style={{ color: "#C0C0C0" }} />
              )}
            </Tooltip>

            {state.apiCallStatus?.[
              "unrecognized-intent-" + record._id + "-delete"
            ]?.loading ? (
              <ComponentLoader
                component={"unrecognized-intent-" + record._id + "-delete"}
                loadingMessage=""
                errorMessage=""
              />
            ) : (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.UNRECOGNIZED_INTENTS_DELETE
                    ? translation.deleteUnrecognizedIntent.infoText
                    : translation.noAccess
                }
              >
                {state.currentUser.permission.UNRECOGNIZED_INTENTS_DELETE ? (
                  <DeleteOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "unrecognized-intents-table",
                          actionRowKey: record._id,
                        },
                      });
                      confirm({
                        title:
                          "Are you sure you want to delete the unrecognized intent?",
                        content:
                          "When clicked the OK button, the unrecognized intent will be deleted permanently",
                        onOk() {
                          dispatchMiddleware({
                            type: "DELETE_UNRECOGNIZED_INTENT(S)",
                            payload: {
                              component:
                                "unrecognized-intent-" + record._id + "-delete",
                              actionRowKey: record._id,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                ) : (
                  <DeleteOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return { unrecognizedIntentsDataColumns, unrecognizedIntentsActionColumn };
};

export default UnrecognizedIntentsTableColumns;
