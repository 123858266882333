import { Space, Typography, Result, Image, Button, Layout } from "antd";
import { channels } from "../../constants/enums";
import BotSettingsMenu from "./BotSettingsMenu";
import { GoogleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const BotGBP = () => {
  return (
    <Layout>
      <BotSettingsMenu />
      <Layout style={{ background: "#ffffff" }}>
        <Layout.Content
          style={{
            padding: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Result
            icon={
              <Image src={channels["GBM"].logo} preview={false} width={75} />
            }
            title={channels["GBP"].displayName}
            style={{ width: 700 }}
            extra={
              <Space direction="vertical" size={20}>
                <Text type="secondary">
                  Business Profile provides a fast and easy way for businesses,
                  products, brands, artists, and organizations to manage their
                  online presence with Google.
                </Text>

                <Button
                  icon={<GoogleOutlined />}
                  type="primary"
                  // onClick={() => {
                  //   window.location.href = "https://accounts.google.com/";
                  // }}
                >
                  Sign in with Google
                </Button>
              </Space>
            }
          />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default BotGBP;
