import { Col, Row, Space, Image, Typography, Button } from "antd";

const { Text } = Typography;

const ConversationWHATSAPP = ({ transcript }) => {
  return (
    <Space size={10} style={{ alignItems: "start" }}>
      {transcript.content && (
        <Row>
          <Col span={24}>
            <Space
              size={10}
              direction="vertical"
              style={{
                width: 300,
                border: "1px solid #C0C0C0",
                padding: 10,
                borderRadius: 10,
              }}
            >
              {transcript.content.header && transcript.content.header.image && (
                <Image
                  preview={false}
                  src={transcript.content.header.image.link}
                />
              )}
              {transcript.content.header && transcript.content.header.text && (
                <Text strong>{transcript.content.header.text}</Text>
              )}
              {transcript.content.body && (
                <Text>{transcript.content.body.text}</Text>
              )}
              {transcript.content.action?.buttons &&
                transcript.content.action.buttons.map((button, index) => {
                  return (
                    <Button style={{ borderRadius: 5 }} key={index}>
                      {button.reply.title}
                    </Button>
                  );
                })}
              {transcript.content.mime_type === "image/jpeg" && (
                <Image preview={false} src={transcript.content_url} />
              )}
            </Space>
          </Col>
        </Row>
      )}
    </Space>
  );
};

export default ConversationWHATSAPP;
