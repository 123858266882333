const botKnowledgebaseReducer = (state, action) => {
  switch (action.type) {
    case "SET_KNOWLEDGEBASE_FILES":
      return {
        ...state,
        botKnowledgebase: action.payload.knowledgebaseFiles,
      };

    case "ADD_KNOWLEDGEBASE_FILE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            uploadedFile: action.payload.uploadedFile,
          },
        },
      };

    case "CLEAR_KNOWLEDGEBASE_UPLOADED_FILE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            uploadedFile: undefined,
          },
        },
      };

    case "SET_KNOWLEDGEBASE_CURRENT_FILE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            currentFile: action.payload.currentFile,
          },
        },
      };

    case "TEST_BOT_KNOWLEDGEBASE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          knowledgebaseTestResult: action.payload.testResult,
        },
      };

    case "CLEAR_KNOWLEDGEBASE_TEST_RESULT":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          knowledgebaseTestResult: undefined,
        },
      };

    case "SET_KNOWLEDGEBASE_STATUS":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            data: state.botKnowledgebase.data.map((knowledgebaseFile) => {
              if (knowledgebaseFile.kb_id === action.payload.kb_id) {
                return {
                  ...knowledgebaseFile,
                  ...{
                    is_published:
                      action.payload.knowledgeBaseStatus.is_published,
                  },
                };
              }
              return knowledgebaseFile;
            }),
          },
        },
      };

    case "DELETE_BOT_KNOWLEDGEBASE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            data: state.botKnowledgebase.data.filter(
              (knowledgebaseFile) =>
                action.payload.kb_id !== knowledgebaseFile.kb_id
            ),
          },
        },
      };

    case "CLEAR_BOT_KNOWLEDGEBASE_FILES":
      return {
        ...state,
        botKnowledgebase: undefined,
      };

    case "SET_CURRENT_STEP":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            currentStep: action.payload,
          },
        },
      };

    case "ADD_REVIEWED_KNOWLEDGEBASE_FILE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            data: [action.payload.reviewedFile, ...state.botKnowledgebase.data],
          },
        },
      };

    case "SET_UPLOAD_TYPE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          uploadType: action.payload.uploadType,
        },
      };

    case "SET_SINGLE_BOT_KNOWLEDGEBASE_FILE":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            singleBotKnowledgebaseFile:
              action.payload.singleBotKnowledgebaseFile,
          },
        },
      };

    case "ADD_BOT_KNOWLEDGEBASE_SECTION":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            singleBotKnowledgebaseFile: {
              ...state.botKnowledgebase.singleBotKnowledgebaseFile,
              data: [
                action.payload?.botKnowledgebaseSection,
                ...state.botKnowledgebase.singleBotKnowledgebaseFile?.data.filter(
                  (section) => section.section_id !== ""
                ),
              ],
            },
          },
        },
      };

    case "EDIT_BOT_KNOWLEDGEBASE_SECTION":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            singleBotKnowledgebaseFile: {
              ...state.botKnowledgebase.singleBotKnowledgebaseFile,
              data: state.botKnowledgebase.singleBotKnowledgebaseFile?.data
                ?.filter((section) => section !== null)
                .map((section) => {
                  if (
                    section?.section_id ===
                    action.payload.botKnowledgebaseSection.section_id
                  ) {
                    return {
                      ...section,
                      ...action.payload.botKnowledgebaseSection,
                    };
                  }
                  return section;
                }),
            },
          },
        },
      };

    case "CLEAR_BOT_KNOWLEDGEBASE_DRAFT_SECTION":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            singleBotKnowledgebaseFile: {
              ...state.botKnowledgebase.singleBotKnowledgebaseFile,
              data: state.botKnowledgebase.singleBotKnowledgebaseFile?.data?.filter(
                (section) => section?.section_id !== ""
              ),
            },
          },
        },
      };

    case "DELETE_BOT_KNOWLEDGEBASE_SECTION":
      return {
        ...state,
        botKnowledgebase: {
          ...state.botKnowledgebase,
          ...{
            singleBotKnowledgebaseFile: {
              ...state.botKnowledgebase.singleBotKnowledgebaseFile,
              data: state.botKnowledgebase.singleBotKnowledgebaseFile?.data.filter(
                (section) => section.section_id !== action.payload.section_id
              ),
            },
          },
        },
      };

    default:
      return state;
  }
};

export default botKnowledgebaseReducer;
