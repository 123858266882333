import { useNavigate } from "react-router-dom";
import ResponsesForm from "./ResponsesForm";
import ResponsesDropdown from "./ResponsesDropdown";
import { Row, Col, Typography, Space, Tabs } from "antd";
import {
  FullPageLoader,
  TabLoader,
} from "../../../shared-components/LoadingIndicator";
import { useGlobalContext } from "../../../contexts/globalContext";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { BulbOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AccessDenied from "../../../shared-components/AccessDenied";

const { Text } = Typography;

const Responses = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();

  useConnectMiddleware(
    () => {
      state.currentUser.permission.ACCOUNT_RESPONSES_VIEW &&
        dispatchMiddleware({
          type: "MWD_LOAD_RESPONSE_CATEGORIES",
          payload: { component: "response-settings" },
        });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return (
    <>
      <Space
        size={15}
        style={{ cursor: "pointer", marginBottom: 10 }}
        onClick={() => navigate("/account-settings")}
      >
        <AiOutlineArrowLeft size={18} style={{ margin: -4 }} />
        Go back to Settings
      </Space>

      {!state.currentUser.permission.ACCOUNT_RESPONSES_VIEW ? (
        <AccessDenied />
      ) : (
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: (
                <Space size={15}>
                  <BulbOutlined size={18} style={{ margin: -4 }} />
                  Responses
                </Space>
              ),
              children: state.responseSettings.responseCategories ? (
                <Row>
                  <Col span={14}>
                    <Space direction="vertical">
                      <Text strong>Custom columns</Text>
                      <Col span={22}>
                        <Text>
                          Configure how responses are displayed and filtered in
                          the Responses
                        </Text>
                      </Col>
                    </Space>
                  </Col>

                  {state.responseSettings.currentResponseCategory && (
                    <Col span={10}>
                      <ResponsesDropdown />
                    </Col>
                  )}

                  {state.apiCallStatus["response-dropdown"] &&
                  state.apiCallStatus["response-dropdown"].loading ? (
                    <FullPageLoader
                      component="response-dropdown"
                      loadingMessage="Loading... Please wait..."
                    />
                  ) : (
                    <>
                      {state.responseSettings.responseProperties && (
                        <Col span={23}>
                          <ResponsesForm />
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              ) : (
                <FullPageLoader
                  component="response-settings"
                  loadingMessage="Loading... Please wait..."
                />
              ),
            },
          ]}
          tabBarExtraContent={
            state.apiCallStatus["response-settings"] &&
            state.apiCallStatus["response-settings"].loading && (
              <TabLoader
                component="response-settings"
                loadingMessage="Synchronizing responses..."
                errorMessage="Error synchronizing responses"
              />
            )
          }
        />
      )}
    </>
  );
};

export default Responses;
