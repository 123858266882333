const botConfigurationReducer = (state, action) => {
  switch (action.type) {
    case "SET_BOT_CONFIGURATION":
      return {
        ...state,
        botConfiguration: action.payload.botConfiguration,
      };

    default:
      return state;
  }
};

export default botConfigurationReducer;
