const webhooksReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_WEBHOOKS":
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          ...{
            webhooksList: action.payload.webhook,
          },
        },
      };

    case "RD_SET_RESPONSE_VALUE_BY_PROPERTY_NAME":
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          ...{
            responseValueByPropertyName:
              action.payload.responseValueByPropertyName,
          },
        },
      };

    case "RD_CREATE_WEBHOOKS":
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          ...{
            webhooksList: [
              ...state.webhooks.webhooksList,
              action.payload.webhook,
            ],
          },
        },
      };

    case "RD_EDIT_WEBHOOKS":
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          ...{
            webhooksList: state.webhooks.webhooksList.map((webhook) =>
              webhook._id === action.payload.webhook._id
                ? Object.assign({}, webhook, action.payload.webhook)
                : webhook
            ),
          },
        },
      };

    case "RD_DELETE_WEBHOOK":
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          ...{
            webhooksList: state.webhooks.webhooksList.filter(
              (webhook) => action.payload.actionRowKey !== webhook._id
            ),
          },
        },
      };

    default:
      return state;
  }
};

export default webhooksReducer;
