import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useResponsesSettingMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************* RESPONSE PROPERTY ***********////
      ////*******************************************////

      //Load response properties
      case "MWD_LOAD_RESPONSE_PROPERTIES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.GET_RESPONSES_PROPERTIES.concat(
              "?category=",
              action.payload.currentResponseCategory ??
                state.responseSettings.currentResponseCategory
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([responseProperties]) => {
            dispatchReducer({
              type: "RD_SET_RESPONSE_PROPERTIES",
              payload: {
                responseProperties,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load Response Properties Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;
    }
  };
};
