import { useConnectApi } from "../connect/connectApi";
import {
  apiEndPoints,
  systemUnrecognizedIntentsProperties,
  unrecognizedIntentsEnums,
} from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { useWindowSize } from "../shared-components/PageReSize";
import Papa from "papaparse";
import { mode } from "../constants/env";

export const useUnrecognizedIntentsMiddleware = () => {
  const { callApi } = useConnectApi();
  const [height] = useWindowSize();

  return async (state, action, dispatchReducer) => {
    let pageSize = Math.round((height - 250) / 55);
    switch (action.type) {
      ////*******************************************////
      ////*********** Unrecognized Intents *********////
      ////*******************************************////

      //Load unrecognized intents
      case "LOAD_UNRECOGNIZED_INTENTS":
        // Bot id
        const botId = "?botId=" + state.application.botInAction;
        // Page size is calculated based on the screen height
        pageSize = action.payload.pageSize || pageSize;
        pageSize = "&pagesize=" + pageSize;
        // Page number
        const pageNumber = "&pagenumber=" + action.payload.pageNumber;
        // Search text
        const { payload } = action;
        // Search text
        const searchText = payload.searchText
          ? `&searchText=${payload.searchText}`
          : "";
        // Filtered value
        const filteredValue = payload.filteredValue
          ? `&${payload.filteredValue}`
          : "";
        // Sort value
        const sortedValue =
          payload.order && payload.columnKey
            ? `&sortkey=${payload.columnKey}`
            : "";
        const sortType =
          payload.order === "ascend"
            ? "&sorttype=1"
            : payload.order === "descend"
            ? "&sorttype=-1"
            : "";

        const queryString =
          botId +
          pageSize +
          pageNumber +
          searchText +
          sortType +
          sortedValue +
          filteredValue;
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.UNRECOGNIZED_INTENTS.concat(queryString),
            ...(!state.accountUsers.users ? [apiEndPoints.ACCOUNT_USERS] : []),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([unrecognizedIntents, users]) => {
            if (action.payload.eventType === "unrecognizedIntentsExport") {
              const modifiedUnrecognizedIntents = unrecognizedIntents?.data.map(
                (item) => {
                  return Object.fromEntries(
                    Object.entries(item)
                      .filter(
                        ([key]) =>
                          !systemUnrecognizedIntentsProperties.includes(key)
                      )
                      .map(([key, value]) => [
                        unrecognizedIntentsEnums[key],
                        value,
                      ])
                  );
                }
              );

              try {
                // Convert the data array to CSV format using Papa Parse
                const csv = Papa.unparse(modifiedUnrecognizedIntents, {
                  escapeFormulae: true,
                });
                // Create a blob object from the CSV data
                const blob = new Blob([csv], {
                  type: "text/csv",
                });
                // Create a temporary anchor element
                const link = document.createElement("a");
                // Set the attributes for the anchor element
                link.href = URL.createObjectURL(blob);
                link.download = `Unrecognized intents.csv`;
                // Append the anchor element to the DOM and simulate a click event
                document.body.appendChild(link);
                link.click();
                // Clean up by removing the temporary anchor element
                document.body.removeChild(link);

                openNotificationWithIcon(
                  "success",
                  "Unrecognized intents downloaded successfully"
                );
                dispatchReducer({
                  type: "SUCCESS_API_TRANSACTION",
                  payload: { component: action.payload.component },
                });
                appInsights.trackEvent({
                  name: "Unrecognized intents Exported",
                  properties: {
                    clientId: state.currentAccount.account.client_id,
                    environment: mode,
                  },
                });
              } catch (error) {
                openErrorNotificationWithDeveloperMessage(error);
                dispatchReducer({
                  type: "ERROR_API_TRANSACTION",
                  payload: { component: action.payload.component, error },
                });
                appInsights.trackException({
                  exception: error,
                  properties: {
                    title: "Export Unrecognized Intents Error",
                    clientId: state.currentAccount.account.client_id,
                    environment: mode,
                  },
                });
              }
            } else {
              dispatchReducer({
                type: "SET_UNRECOGNIZED_INTENTS",
                payload: {
                  unrecognizedIntents,
                },
              });
              users &&
                dispatchReducer({
                  type: "SET_ACCOUNT_USERS",
                  users: users.data,
                });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            }
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Unrecognized Intents Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Edit Unrecognized Intents Properties
      case "EDIT_UNRECOGNIZED_INTENT_PROPERTIES":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.UNRECOGNIZED_INTENTS.concat(
              "?_id=",
              state.filters.actionRowKey["unrecognized-intents-table"]
            ),
          ],
          options: {
            method: "PUT",
            body: action.payload.formUnrecognizedIntentProperties,
          },
          onSuccess: ([editedUnrecognizedIntentProperties]) => {
            dispatchReducer({
              type: "EDIT_UNRECOGNIZED_INTENT_PROPERTIES",
              payload: {
                editedUnrecognizedIntentProperties,
                _id: state.filters.actionRowKey?.["unrecognized-intents-table"],
              },
            });
            openNotificationWithIcon(
              "success",
              "Unrecognized intents updated successfully"
            );

            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "unrecognized-intents-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "unrecognized-intents-form",
                mode: "",
                title: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "unrecognized-intents-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Edit Unrecognized Intents Properties Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete Unrecognized Intents
      case "DELETE_UNRECOGNIZED_INTENT(S)":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.UNRECOGNIZED_INTENTS.concat(
              "?_ids=",
              action.payload.actionRowKey || action.payload.selectedRowKeys
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            if (action.payload.actionRowKey) {
              dispatchReducer({
                type: "RD_CLEAR_ACTION_ROW_KEY",
                payload: { component: "unrecognized-intents-table" },
              });
              dispatchReducer({
                type: "DELETE_SINGLE_UNRECOGNIZED_INTENT",
                payload: {
                  actionRowKey: action.payload.actionRowKey,
                },
              });
            } else {
              dispatchReducer({
                type: "RD_CLEAR_SELECTED_ROW_KEYS",
                payload: {
                  component: "unrecognized-intents-table-selected-row",
                },
              });
              dispatchReducer({
                type: "DELETE_MULTIPLE_UNRECOGNIZED_INTENTS",
                payload: {
                  selectedRowKeys: action.payload.selectedRowKeys,
                },
              });
            }
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "Unrecognized intent(s) deleted successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Unrecognized Intent(s) Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
