import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Button,
  Form,
  Select,
  TimePicker,
} from "antd";
import moment from "moment";
import { useGlobalContext } from "../../../contexts/globalContext";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import { countryDailingCodes } from "../../../constants/enums";

const { Text } = Typography;
const { Option } = Select;

const BroadcastForm = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const [channel] = useState("WHATSAPP");
  const broadcastSettings = state.broadcastSettings;

  const handleBroadcastSettings = (e) => {
    const formData = {
      defaultCountryCode: e.defaultCountryCode,
      allowedTimeRange: {
        fromTime: moment(e.fromTime).format("h:mm a"),
        toTime: moment(e.toTime).format("h:mm a"),
      },
      broadcastDefaultBot: e.broadcastDefaultBot,
    };

    dispatchMiddleware({
      type: "UPDATE_BROADCAST_SETTINGS",
      payload: {
        component: "broadcast-settings-form",
        broadcastSettings: formData,
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      broadcastDefaultBot: broadcastSettings?.broadcastDefaultBot,
      defaultCountryCode: broadcastSettings?.defaultCountryCode,
    });
    if (broadcastSettings?.allowedTimeRange) {
      form.setFieldsValue({
        fromTime: moment(
          broadcastSettings?.allowedTimeRange?.fromTime,
          "h:mm a"
        ),
        toTime: moment(broadcastSettings?.allowedTimeRange?.toTime, "h:mm a"),
      });
    }
  }, []);

  return (
    <>
      {state.apiCallStatus["broadcast-settings-form"] &&
      state.apiCallStatus["broadcast-settings-form"].loading ? (
        <FullPageLoader
          component="broadcast-settings-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleBroadcastSettings}
        >
          <Row>
            {/* Broadcast allowed time range */}
            <Col span={6}>
              <Space direction="vertical">
                <Text strong>Broadcast Timeframe</Text>
                <Text>
                  Configure the permissible timeframe for broadcasting. You can
                  only broadcast within this timeframe.
                </Text>
              </Space>
            </Col>
            <Col span={18}>
              <Row gutter={[0, 0]}>
                <Col span={4} offset={2}>
                  <Form.Item
                    label="From"
                    name={"fromTime"}
                    rules={[
                      {
                        required: true,
                        message: "Please select start time",
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="Start time"
                      use12Hours
                      format="h:mm a"
                      onSelect={(value) => {
                        form.setFieldsValue({
                          fromTime: moment(value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={1} style={{ textAlign: "center" }}></Col>

                <Col span={7}>
                  <Form.Item
                    label="To"
                    name={"toTime"}
                    rules={[
                      {
                        required: true,
                        message: "Please select end time",
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="End time"
                      use12Hours
                      format="h:mm a"
                      onSelect={(value) => {
                        form.setFieldsValue({
                          toTime: moment(value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Divider />

            {/* Broadcast default bot */}
            <Col span={6}>
              <Space direction="vertical">
                <Text strong>Default Bot</Text>
                <Text>
                  Configure the default bot for broadcast messages. This will be
                  the default bot for all contacts to broadcast.
                </Text>
              </Space>
            </Col>
            <Col span={18}>
              <Row>
                <Col offset={2} span={21}>
                  <Form.Item
                    name="broadcastDefaultBot"
                    label="Bot"
                    rules={[
                      {
                        required: true,
                        message: "Please select a bot",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder="Select bot"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children?.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {state.currentAccount.account.bots.map(
                        (bot, index) =>
                          bot.bot_channels?.includes(channel) && (
                            <Option value={bot.bot_id} key={index}>
                              {bot.bot_name}
                            </Option>
                          )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Divider />

            {/* Broadcast country code */}
            <Col span={6}>
              <Space direction="vertical">
                <Text strong>Country Code</Text>
                <Text>
                  Configure the country code for broadcast messages. This will
                  be the default country code for all contacts to broadcast.
                </Text>
              </Space>
            </Col>
            <Col span={18}>
              <Row>
                <Col offset={2} span={21}>
                  <Form.Item
                    name="defaultCountryCode"
                    label="Country Code"
                    rules={[
                      {
                        required: true,
                        message: "Please select the country code",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      options={countryDailingCodes}
                      style={{ width: 300 }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder="Select country code"
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          ?.includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Space size="small">
                  <Button>Cancel</Button>
                  <Button
                    disabled={
                      !state.currentUser.permission.ACCOUNT_BROADCAST_EDIT
                    }
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default BroadcastForm;
