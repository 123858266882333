import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { useWindowSize } from "../../../shared-components/PageReSize";
import useWebhooksTableColumns from "./WebhooksTableColumns";

const WebhooksTable = () => {
  const [state] = useGlobalContext();
  const [height] = useWindowSize();
  const { webhooksDataColumn, webhooksActionColumn } =
    useWebhooksTableColumns();

  const searchText =
    state.filters.searchText["webhooks-search-text-box"] &&
    state.filters.searchText["webhooks-search-text-box"];

  let filteredWebhookList = state.webhooks.webhooksList?.map(webhookConfig => {
    return { ...webhookConfig, triggerCriteria: { ...webhookConfig?.triggerCriteria, criteriaName: webhookConfig?.triggerCriteria?.criteriaName === "partitionKey" ? "Category" : webhookConfig?.triggerCriteria?.criteriaName } }
  });
  if (searchText) {
    filteredWebhookList = filteredWebhookList.filter((webhook) => {
      return (
        webhook?.webhookName
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        webhook?.serviceProvider
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }

  return (
    <>
      <Table
        columns={webhooksDataColumn.concat(webhooksActionColumn)}
        dataSource={filteredWebhookList}
        showSorterTooltip={false}
        pagination={{
          pageSize: Math.round((height - 250) / 45),
          showTotal: () => "Total : " + filteredWebhookList.length,
        }}
      />
    </>
  );
};

export default WebhooksTable;
