import { Row, Col } from "antd";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { useGlobalContext } from "../../../contexts/globalContext";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import BotConfigurationForm from "./BotConfigurationForm";
import AccessDenied from "../../../shared-components/AccessDenied";

const BotConfiguration = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({ type: "CLEAR_BOT_CONFIGURATION" });
      state.currentUser.permission.BOT_CONFIGURATION_VIEW &&
        dispatchMiddleware({
          type: "LOAD_BOT_CONFIGURATION",
          payload: { component: "bot-configuration-list" },
        });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return state.currentUser.permission.BOT_CONFIGURATION_VIEW ? (
    state.botConfiguration ? (
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <BotConfigurationForm />
        </Col>
      </Row>
    ) : (
      <FullPageLoader
        component="bot-configuration-list"
        loadingMessage="Loading... Please wait..."
      />
    )
  ) : (
    <AccessDenied />
  );
};

export default BotConfiguration;
