import { useEffect } from "react";
import { Layout, Menu, Badge, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../contexts/globalContext";
import { TbActivityHeartbeat, TbBrandGoogleAnalytics } from "react-icons/tb";
import { TbRobot } from "react-icons/tb";
import { BiConversation, BiBroadcast } from "react-icons/bi";
import { VscBook } from "react-icons/vsc";
import { RiDashboard3Line } from "react-icons/ri";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineContacts } from "react-icons/ai";
import { TbListCheck } from "react-icons/tb";
import { mode } from "../constants/env";
import { useWindowSize } from "../shared-components/PageReSize";

const { Sider } = Layout;

const SideMenu = ({ handleOnCollapse, collapsed, setCollapsed }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const theme = "dark";
  const navigate = useNavigate();
  const [height] = useWindowSize();

  const menuItems = [
    {
      key: "/",
      icon: <RiDashboard3Line size={20} style={{ marginBottom: -7 }} />,
      label: "DASHBOARD",
    },
    {
      key: "/analytics/responses-charts",
      icon: <TbBrandGoogleAnalytics size={18} style={{ marginBottom: -5 }} />,
      label: "ANALYTICS",
    },
    {
      key: "/client-responses",
      icon: (
        <HiOutlineLightBulb
          size={22}
          style={{ marginBottom: -5, marginLeft: -2 }}
        />
      ),
      label: "RESPONSES",
    },
    {
      key: "/broadcast-manager",
      icon: <BiBroadcast size={18} style={{ marginBottom: -5 }} />,
      label: "BROADCAST MANAGER",
    },
    {
      key: "/conversations",
      icon: (
        <Space direction="vertical" size={0}>
          <Space>
            <BiConversation size={18} style={{ marginBottom: -5 }} />

            <Badge
              count={
                state.conversations.unreadMessagesCount > 99
                  ? "99+"
                  : state.conversations.unreadMessagesCount > 0
                  ? state.conversations.unreadMessagesCount
                  : 0
              }
              size="small"
              style={{ position: "relative", left: -10, top: -10 }}
            />
          </Space>
        </Space>
      ),
      label: "CONVERSATIONS",
    },
    {
      key: "/contacts",
      icon: <AiOutlineContacts size={20} style={{ marginBottom: -5 }} />,
      label: "CONTACTS",
    },
    {
      key: "/knowledge-area/message-templates",
      icon: <VscBook size={19} style={{ marginBottom: -5 }} />,
      label: "BOT KNOWLEDGE AREA",
    },
    mode !== "PROD" && {
      key: "/activity-area/activity-logs",
      icon: <TbActivityHeartbeat size={22} style={{ marginBottom: -5 }} />,
      label: "BOT ACTIVITY AREA",
    },
    {
      key: "/bot-settings/general",
      icon: <TbRobot size={22} style={{ marginBottom: -5 }} />,
      label: "BOT SETTINGS",
    },
    !(mode === "PROD" || mode === "PREPROD") && {
      key: "/tickets",
      icon: <TbListCheck size={22} style={{ marginBottom: -5 }} />,
      label: "TICKETS",
    },
  ];

  let selectedKey = window.location.pathname;

  if (selectedKey.startsWith("/knowledge-area")) {
    selectedKey = "/knowledge-area/message-templates";
  } else if (selectedKey.startsWith("/bot-settings")) {
    selectedKey = "/bot-settings/general";
  } else if (selectedKey.startsWith("/activity-area")) {
    selectedKey = "/activity-area/activity-logs";
  }

  useEffect(() => {
    setCollapsed(true);
    if (!sessionStorage.getItem("selectedMenu")) {
      sessionStorage.setItem("selectedMenu", "/");
    }
  }, []);

  return (
    <Sider
      collapsedWidth="80"
      onCollapse={handleOnCollapse}
      collapsed={collapsed}
      width="256"
      theme={theme}
    >
      <Menu
        items={menuItems}
        mode="inline"
        theme={theme}
        style={{ padding: "15px 0 0 0" }}
        onClick={({ key }) => {
          sessionStorage.setItem("selectedMenu", key);
          //In order for the sound to play during live agent request (during the browser tab being inactive), we need to play a sound before user goes inactive.
          //As soon as user logs in, they need to click on the Conversation Menu on the left, and then they can go inactive.
          //Now that the sounds is played while clicking on the menu, even if they go inactive, the sound will play when live agent is requested.
          if (key === "/conversations") {
            new Audio("alert-sounds/empty-sound.mp3").play();

            // If conversation filter enabled means Clear the filter, when the user navigates to other pages
            if (state.conversations.conversationFilter) {
              // Clear the filtered conversation and load the conversations
              dispatchReducer({
                type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
              });

              // Clear the current conversation
              dispatchReducer({
                type: "RD_CLEAR_CURRENT_CONVERSATION",
              });

              // Set the conversation response link to false
              dispatchReducer({
                type: "RD_SET_CONVERSATION_FILTER",
                payload: {
                  conversationFilter: false,
                },
              });

              // If already on the conversation page, and the conversation menu is clicked, load the conversations
              // If switching from other pages, cleared the conversation state above, so it load the conversations using Conversations.js
              window.location.pathname === "/conversations" &&
                dispatchMiddleware({
                  type: "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                  payload: {
                    component: "conversations-layout",
                    pageSize: Math.round(height / 60),
                    pageNumber: state.conversations.contactsWithTranscripts
                      ? Math.floor(
                          state.conversations.contactsWithTranscripts.length /
                            12
                        ) + 1
                      : 1,
                    delta: false,
                  },
                });
            }
          }
          navigate(key);
        }}
        selectedKeys={[sessionStorage.getItem("selectedMenu")]}
      />
    </Sider>
  );
};

export default SideMenu;
