const unrecognizedIntentsReducer = (state, action) => {
  switch (action.type) {
    case "SET_UNRECOGNIZED_INTENTS":
      return {
        ...state,
        unrecognizedIntents: action.payload.unrecognizedIntents,
      };

    case "CLEAR_UNRECOGNIZED_INTENTS":
      return {
        ...state,
        unrecognizedIntents: null,
      };

    case "EDIT_UNRECOGNIZED_INTENT_PROPERTIES":
      return {
        ...state,
        unrecognizedIntents: {
          ...state.unrecognizedIntents,
          data: state.unrecognizedIntents?.data.map((unrecognizedIntent) =>
            unrecognizedIntent._id === action.payload._id
              ? {
                  ...unrecognizedIntent,
                  ...action.payload.editedUnrecognizedIntentProperties,
                }
              : unrecognizedIntent
          ),
        },
      };

    case "DELETE_SINGLE_UNRECOGNIZED_INTENT":
      return {
        ...state,
        unrecognizedIntents: {
          ...state.unrecognizedIntents,
          data: state.unrecognizedIntents?.data.filter(
            (unrecognizedIntent) =>
              action.payload.actionRowKey !== unrecognizedIntent._id
          ),
          totalRecords: state.unrecognizedIntents?.totalRecords - 1,
        },
      };

    case "DELETE_MULTIPLE_UNRECOGNIZED_INTENTS":
      return {
        ...state,
        unrecognizedIntents: {
          ...state.unrecognizedIntents,
          data: state.unrecognizedIntents?.data.filter(
            (unrecognizedIntent) =>
              !action.payload.selectedRowKeys.includes(unrecognizedIntent._id)
          ),
          totalRecords:
            state.unrecognizedIntents?.totalRecords -
            action.payload.selectedRowKeys.length,
        },
      };

    default:
      return state;
  }
};

export default unrecognizedIntentsReducer;
