import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Tag, Space, Modal, Switch, Tooltip } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";

const { confirm } = Modal;

const ContactManagerTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const tableColumns = (state.contactManagerSettings.tableColumns || []).sort(
    (a, b) => a?.display_order - b?.display_order
  );

  //Sort contact based on display order

  const contactManagerDataColumns = tableColumns.map((column) => {
    return {
      title: column.display_name?.toUpperCase() || column?.key?.toUpperCase(),
      dataIndex: column.key,
      key: column.key,
      width: column.width || 100,
      ellipsis: true,
      align: column.key === "isUnsubscribed" ? "center" : "left",
      sorter: !["tags", "mobile", "whatsappId", "isUnsubscribed"].includes(
        column.key
      )
        ? (a, b) => {
            let valueA = !a[column.key] ? "" : a[column.key];
            let valueB = !b[column.key] ? "" : b[column.key];
            return valueA.localeCompare(valueB);
          }
        : null,
      filters:
        column.key === "isUnsubscribed"
          ? [
              {
                text: "SUBSCRIBED",
                value: false,
              },
              {
                text: "UNSUBSCRIBED",
                value: true,
              },
            ]
          : null,
      onFilter:
        column.key === "isUnsubscribed"
          ? (value, record) => record[column.key] === value
          : null,
      filteredValue:
        (column.key === "isUnsubscribed" &&
          state.filters.filteredInfo["contact-manager-table"] &&
          state.filters.filteredInfo["contact-manager-table"].isUnsubscribed) ||
        null,
      render: (value) => {
        if (column.key === "tags") {
          return value ? (
            value
              ?.split(",")
              .filter((tag) => tag.trim() !== "")
              .map((tag, index) => {
                return (
                  <Tag
                    color="blue"
                    key={index}
                    type="round"
                    style={{ borderRadius: "4px" }}
                  >
                    {tag.trim()}
                  </Tag>
                );
              })
          ) : (
            <Space>-</Space>
          );
        } else if (column.key === "isUnsubscribed") {
          return (
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={value}
              disabled
            />
          );
        } else {
          // Default rendering for other columns
          return <Space>{value ? value : "-"}</Space>;
        }
      },
    };
  });

  const contactManagerActionColumns = [
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: 100,
      align: "center",
      onCell: () => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // This will avoid onRow being called
          },
        };
      },
      render: (_, record) => {
        return (
          <>
            <Space size={24}>
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.CONTACT_EDIT
                    ? "Edit Contact"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission.CONTACT_EDIT ? (
                  <EditOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "contact-manager-table",
                          actionRowKey: record._id,
                        },
                      });
                      dispatchReducer({
                        type: "OPEN_FORM_PANEL",
                        payload: {
                          component: "contact-manager-form-panel",
                          title: "Edit Contact",
                        },
                      });
                      dispatchReducer({
                        type: "SET_FORM_MODE",
                        payload: {
                          component: "contact-manager-form",
                          mode: "edit",
                        },
                      });
                    }}
                  />
                ) : (
                  <EditOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>

              {state.apiCallStatus[
                "contact-manager-" + record._id + "-delete"
              ] &&
              state.apiCallStatus["contact-manager-" + record._id + "-delete"]
                .loading ? (
                <ComponentLoader
                  component={"contact-manager-" + record._id + "-delete"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="topRight"
                  title={
                    state.currentUser.permission.CONTACT_DELETE
                      ? "Delete Contact"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.CONTACT_DELETE ? (
                    <DeleteOutlined
                      style={{ color: "#F05742" }}
                      onClick={() => {
                        dispatchReducer({
                          type: "RD_SET_ACTION_ROW_KEY",
                          payload: {
                            component: "contact-manager-table",
                            actionRowKey: record._id,
                          },
                        });
                        confirm({
                          title: "Are you sure you want to delete the contact?",
                          content:
                            "When clicked the OK button, the contact will be deleted permanently",
                          onOk() {
                            dispatchMiddleware({
                              type: "MWD_DELETE_SINGLE_CONTACT",
                              payload: {
                                component:
                                  "contact-manager-" + record._id + "-delete",
                                actionRowKey: record._id,
                              },
                            });
                          },
                          onCancel() {},
                        });
                      }}
                    />
                  ) : (
                    <DeleteOutlined style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  return {
    contactManagerDataColumns,
    contactManagerActionColumns,
  };
};

export default ContactManagerTableColumns;
