import { useGlobalContext } from "../../../contexts/globalContext";
import { Button, Space, Input, Divider, Tooltip, Row, Col } from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { RiFilterOffLine } from "react-icons/ri";
import { Excel } from "antd-table-saveas-excel";
import useAccountUsersTableColumns from "./AccountUsersTableColumns";

const AccountUsersHeader = () => {
  const [state, dispatchReducer] = useGlobalContext();
  const { dataColumns } = useAccountUsersTableColumns();

  return (
    <Row>
      <Col span={18}>
        <Space>
          <Input
            disabled={state.accountUsers.users.length === 0}
            prefix={<SearchOutlined />}
            placeholder="Search by name"
            value={
              state.filters.searchText["account-users-search-text-box"] &&
              state.filters.searchText["account-users-search-text-box"]
            }
            style={{ width: 400 }}
            onChange={(e) => {
              const currValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "account-users-search-text-box",
                  searchText: currValue,
                },
              });
            }}
            allowClear={{ clearIcon: <CloseOutlined /> }}
          />

          <Divider type="vertical" />

          <Tooltip placement="top" title="Clear filter(s)">
            <Button
              disabled={
                state.filters.filteredInfo["account-user-table"]
                  ? state.filters.filteredInfo["account-user-table"].role
                    ? false
                    : true
                  : true
              }
              icon={<RiFilterOffLine style={{ margin: -2 }} />}
              onClick={() => {
                dispatchReducer({
                  type: "RD_SET_FILTERED_INFO",
                  payload: {
                    component: "account-user-table",
                    filteredInfo: {},
                  },
                });
              }}
            />
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              state.currentUser.permission.ACCOUNT_USERS_EXPORT
                ? "Export Users"
                : "You do not have required permission"
            }
          >
            <Button
              disabled={
                state.accountUsers.users.length === 0 ||
                !state.currentUser.permission.ACCOUNT_USERS_EXPORT
              }
              icon={<DownloadOutlined />}
              onClick={() => {
                const excel = new Excel();
                excel
                  .addSheet("users")
                  .addColumns(dataColumns)
                  .addDataSource(state.accountUsers.users, {
                    str2Percent: true,
                  })
                  .saveAs("users.xlsx");
              }}
            />
          </Tooltip>
        </Space>
      </Col>

      <Col span={6} style={{ textAlign: "right" }}>
        <Tooltip
          placement="topRight"
          title={
            state.currentUser.permission.ACCOUNT_USER_CREATE
              ? "Add User"
              : "You do not have required permission"
          }
        >
          <Button
            disabled={!state.currentUser.permission.ACCOUNT_USER_CREATE}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatchReducer({
                type: "OPEN_FORM_PANEL",
                payload: {
                  component: "account-user-form-panel",
                  title: "Add New User",
                },
              });
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "account-user-form",
                  mode: "add",
                  title: "Add New User",
                },
              });
            }}
          >
            Add User
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default AccountUsersHeader;
