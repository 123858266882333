import { useState, useEffect } from "react";
import { Table } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import { useWindowSize } from "../../../shared-components/PageReSize";
import { convertObjectToString } from "../../../utils/utility";
import UnrecognizedIntentsTableColumns from "./UnrecognizedIntentsTableColumns";

const UnrecognizedIntentsTable = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [height] = useWindowSize();
  const { unrecognizedIntentsDataColumns, unrecognizedIntentsActionColumn } =
    UnrecognizedIntentsTableColumns();
  const [pageSize, setPageSize] = useState();

  let unrecognizedIntents = state.unrecognizedIntents?.data;

  const selectedRowKeys =
    state.filters.selectedRowKeys?.["unrecognized-intents-table-selected-row"];

  useEffect(() => {
    dispatchReducer({
      type: "RD_SET_SEARCH_TEXT",
      payload: {
        component: "unrecognized-intents-search-text-box",
        searchText: null,
      },
    });
    dispatchReducer({
      type: "RD_SET_PAGINATION",
      payload: {
        component: "unrecognized-intents-table",
        pagination: null,
      },
    });
    dispatchReducer({
      type: "RD_SET_FILTERED_INFO",
      payload: {
        component: "unrecognized-intents-table",
        filteredInfo: null,
      },
    });
    dispatchReducer({
      type: "RD_SET_SORTED_INFO",
      payload: {
        component: "unrecognized-intents-table",
        sortedInfo: null,
      },
    });
    dispatchReducer({
      type: "RD_CLEAR_SELECTED_ROW_KEYS",
      payload: {
        component: "unrecognized-intents-table-selected-row",
      },
    });
    // For clearing eslint warning dispatchReducer used in dependency array
    // Refer OneNote - portal useful links for more details
  }, [dispatchReducer, state.application.botInAction]);

  return (
    <Table
      columns={unrecognizedIntentsDataColumns.concat(
        unrecognizedIntentsActionColumn
      )}
      dataSource={unrecognizedIntents}
      loading={
        state.apiCallStatus["unrecognized-intents-table"] &&
        state.apiCallStatus["unrecognized-intents-table"].loading
      }
      rowSelection={{
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          dispatchReducer({
            type: "RD_SET_SELECTED_ROW_KEYS",
            payload: {
              component: "unrecognized-intents-table-selected-row",
              selectedRowKeys,
            },
          });
        },
      }}
      onChange={(pagination, filters, sorter) => {
        dispatchReducer({
          type: "RD_SET_PAGINATION",
          payload: {
            component: "unrecognized-intents-table",
            pagination,
          },
        });
        dispatchReducer({
          type: "RD_SET_FILTERED_INFO",
          payload: {
            component: "unrecognized-intents-table",
            filteredInfo: filters,
          },
        });
        dispatchReducer({
          type: "RD_SET_SORTED_INFO",
          payload: {
            component: "unrecognized-intents-table",
            sortedInfo: sorter,
          },
        });

        dispatchMiddleware({
          type: "LOAD_UNRECOGNIZED_INTENTS",
          payload: {
            component: "unrecognized-intents-table",
            searchText:
              state.filters.searchText?.[
                "unrecognized-intents-search-text-box"
              ],
            //convert object to string
            filteredValue: convertObjectToString(filters || {}),
            ...sorter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
          },
        });
      }}
      pagination={{
        // Set default page number
        current: state.filters.pagination?.["unrecognized-intents-table"]
          ? state.filters.pagination["unrecognized-intents-table"].current
          : 1,
        total: state.unrecognizedIntents.totalRecords,
        showSizeChanger:
          state.unrecognizedIntents.totalRecords > 20 ? true : false,
        pageSize: pageSize ?? Math.round((height - 250) / 55),
        pageSizeOptions: ["20", "30", "50", "100"],
        onShowSizeChange: (_, size) => {
          setPageSize(size);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      rowKey="_id"
    />
  );
};

export default UnrecognizedIntentsTable;
