const analyticsReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_RESPONSES_BY_CHANNEL":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            responsesCharts: {
              ...state.analytics.responsesCharts,
              ...{
                responsesByChannel: action.payload.responsesByChannel,
              },
            },
          },
        },
      };

    case "RD_SET_RESPONSES_By_ACTION":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            responsesCharts: {
              ...state.analytics.responsesCharts,
              ...{
                responsesByAction: action.payload.responsesByAction,
              },
            },
          },
        },
      };

    case "RD_SET_RESPONSES_By_ASSIGNED_PERSON":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            responsesCharts: {
              ...state.analytics.responsesCharts,
              ...{
                responsesByAssignedPerson:
                  action.payload.responsesByAssignedPerson,
              },
            },
          },
        },
      };

    case "RD_SET_RESPONSES_BY_DATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            responsesCharts: {
              ...state.analytics.responsesCharts,
              ...{
                responsesByDate: action.payload.responsesByDate,
              },
            },
          },
        },
      };

    case "RD_SET_BROADCAST_MESSAGE_BY_DATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            broadcastCharts: {
              ...state.analytics.broadcastCharts,
              ...{
                broadcastMessageByDate: action.payload.broadcastMessageByDate,
              },
            },
          },
        },
      };

    case "RD_SET_BROADCAST_MESSAGE_BY_TEMPLATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            broadcastCharts: {
              ...state.analytics.broadcastCharts,
              ...{
                broadcastMessageByTemplate:
                  action.payload.broadcastMessageByTemplate,
              },
            },
          },
        },
      };

    case "RD_SET_BROADCAST_MESSAGE_BY_CHANNEL":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            broadcastCharts: {
              ...state.analytics.broadcastCharts,
              ...{
                broadcastMessageByChannel:
                  action.payload.broadcastMessageByChannel,
              },
            },
          },
        },
      };

    case "RD_SET_INTENTS_COUNT":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            knowledgeAreaCharts: {
              ...state.analytics.knowledgeAreaCharts,
              ...{
                intents: action.payload.intents,
              },
            },
          },
        },
      };

    case "RD_SET_LIVE_AGENT_BY_CHANNEL":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                liveagentByChannel: action.payload.liveagentByChannel,
              },
            },
          },
        },
      };

    case "RD_SET_BROADCAST_BY_TEMPLATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            broadcastCharts: {
              ...state.analytics.broadcastCharts,
              ...{
                broadcastByTemplate: action.payload.broadcastByTemplate,
              },
            },
          },
        },
      };

    case "RD_SET_BROADCAST_BY_CHANNEL":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            broadcastCharts: {
              ...state.analytics.broadcastCharts,
              ...{
                broadcastByChannel: action.payload.broadcastByChannel,
              },
            },
          },
        },
      };

    case "RD_SET_BROADCAST_BY_DATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            broadcastCharts: {
              ...state.analytics.broadcastCharts,
              ...{
                broadcastByDate: action.payload.broadcastByDate,
              },
            },
          },
        },
      };

    case "RD_SET_CONVERSATION_CHANNEL_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                conversationsByChannel: action.payload.conversationsByChannel,
              },
            },
          },
        },
      };

    case "RD_SET_CONVERSATION_BROWSER_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                conversationByBrowser: action.payload.conversationsByBrowser,
              },
            },
          },
        },
      };

    case "RD_SET_CONVERSATION_LOCATION_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                conversationByLocation: action.payload.conversationByLocation,
              },
            },
          },
        },
      };

    case "RD_SET_NONE_INTENT_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                noneIntentMessage: action.payload.noneIntentMessage,
              },
            },
          },
        },
      };

    case "RD_SET_FILTER_BY_DATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            ...state.analytics.filterByChannelForCharts,
            filterByDateForCharts: action.payload.filterByDateForCharts,
            responsesCharts: {
              ...state.analytics.responsesCharts,
              ...{
                responsesByChannel: undefined,
                responsesByAction: undefined,
                responsesByAssignedPerson: undefined,
                responsesByDate: undefined,
              },
            },
            conversationCharts: {
              liveagentCountByChannel: undefined,
              conversationCount: undefined,
              conversationsByChannel: undefined,
              conversationsByDate: undefined,
              messagesByChannel: undefined,
              messagesByDate: undefined,
              conversationByBrowser: undefined,
              conversationByLocation: undefined,
              noneIntentMessage: undefined,
            },
            broadcastCharts: {
              broadcastMessageByDate: undefined,
              broadcastMessageByTemplate: undefined,
              broadcastMessageByChannel: undefined,
              broadcastByTemplate: undefined,
              broadcastByChannel: undefined,
              broadcastByDate: undefined,
            },
            knowledgeAreaCharts: {
              intents: undefined,
              knowledgeBaseMessage: undefined,
            },
            contactCharts: {
              contactByCreatedSource: undefined,
              contactSubscriptionStatus: undefined,
            },
          },
        },
      };

    case "RD_SET_FILTER_BY_CHANNEL":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            ...state.analytics.filterByDateForCharts,
            filterByChannelForCharts: action.payload.filterByChannelForCharts,
            responsesCharts: {
              ...state.analytics.responsesCharts,
              ...{
                responsesByChannel: undefined,
                responsesByAction: undefined,
                responsesByAssignedPerson: undefined,
                responsesByDate: undefined,
              },
            },
            conversationCharts: {
              liveagentCountByChannel: undefined,
              conversationCount: undefined,
              conversationsByChannel: undefined,
              conversationsByDate: undefined,
              messagesByChannel: undefined,
              messagesByDate: undefined,
            },
            broadcastCharts: {
              broadcastMessageByDate: undefined,
              broadcastMessageByTemplate: undefined,
              broadcastMessageByChannel: undefined,
              broadcastByTemplate: undefined,
              broadcastByChannel: undefined,
              broadcastByDate: undefined,
            },
            knowledgeAreaCharts: {
              unrecognizedIntentsByChannel: undefined,
              unrecognizedIntentsByDate: undefined,
              unrecognizedIntentsByAssignee: undefined,
              unrecognizedIntentsByStatus: undefined,
            },
            contactCharts: {
              contactByCreatedSource: undefined,
              contactSubscriptionStatus: undefined,
            },
          },
        },
      };

    case "RD_SET_CONVERSATION_DATE_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                conversationsByDate: action.payload.conversationsByDate,
              },
            },
          },
        },
      };

    case "RD_SET_MESSAGE_CHANNEL_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                messagesByChannel: action.payload.messagesByChannel,
              },
            },
          },
        },
      };

    case "RD_SET_MESSAGE_DATE_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                messagesByDate: action.payload.messagesByDate,
              },
            },
          },
        },
      };

    case "RD_SET_CONTACT_BY_CREATED_SOURCE_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            contactCharts: {
              ...state.analytics.contactCharts,
              ...{
                contactByCreatedSource: action.payload.contactByCreatedSource,
              },
            },
          },
        },
      };

    case "RD_SET_CONTACT_SUBSCRIPTION_STATUS_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            contactCharts: {
              ...state.analytics.contactCharts,
              ...{
                contactSubscriptionStatus:
                  action.payload.contactSubscriptionStatus,
              },
            },
          },
        },
      };

    case "RD_SET_CONTACT_COUNT_BY_COUNTRY":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            contactCharts: {
              ...state.analytics.contactCharts,
              ...{
                contactByCountry: action.payload.contactByCountry,
              },
            },
          },
        },
      };

    case "RD_SET_ACTIVE_AND_INACTIVE_USERS_COUNT":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            conversationCharts: {
              ...state.analytics.conversationCharts,
              ...{
                activeAndInactiveUsersCount:
                  action.payload.activeAndInactiveUsersCount,
              },
            },
          },
        },
      };

    case "SET_UNRECOGNIZED_INTENTS_BY_ASSIGNEE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            knowledgeAreaCharts: {
              ...state.analytics.knowledgeAreaCharts,
              ...{
                unrecognizedIntentsByAssignee:
                  action.payload.unrecognizedIntentsByAssignee,
              },
            },
          },
        },
      };

    case "SET_UNRECOGNIZED_INTENTS_BY_CHANNEL":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            knowledgeAreaCharts: {
              ...state.analytics.knowledgeAreaCharts,
              ...{
                unrecognizedIntentsByChannel:
                  action.payload.unrecognizedIntentsByChannel,
              },
            },
          },
        },
      };

    case "SET_UNRECOGNIZED_INTENTS_BY_STATUS":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            knowledgeAreaCharts: {
              ...state.analytics.knowledgeAreaCharts,
              ...{
                unrecognizedIntentsByStatus:
                  action.payload.unrecognizedIntentsByStatus,
              },
            },
          },
        },
      };

    case "SET_UNRECOGNIZED_INTENTS_BY_DATE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...{
            knowledgeAreaCharts: {
              ...state.analytics.knowledgeAreaCharts,
              ...{
                unrecognizedIntentsByDate:
                  action.payload.unrecognizedIntentsByDate,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default analyticsReducer;
