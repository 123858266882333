import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import moment from "moment";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";
import { generateRandomBackgroundColor } from "../utils/utility";

export const useConversationsMiddlewarev2 = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    // Use middleware based on liveagent version
    if (
      parseInt(
        state.currentAccount.account.modules?.conversation.live_agent_version
      ) === 2
    ) {
      switch (action.type) {
        ////************************************************////
        ////**************** CONVERSATIONS API *************////
        ////************************************************////
        //Update contacts list along with transcripts
        case "MWD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                ...[
                  "?",
                  !action.payload.delta
                    ? `pagesize=${action.payload.pageSize}&pagenumber=${action.payload.pageNumber}`
                    : `date=~gte~${state.conversations.lastUpdatedAt}`,
                ]
              ),

              // Check if live agent is enabled
              // Get the live conversation
              ...(state.currentAccount.account.modules.conversation
                ?.pull_live_conversation_on_refresh &&
              !state.conversations.contactsWithTranscripts?.length
                ? [apiEndPoints.CONVERSATION_STATUS]
                : []),
              ...(!state.accountUsers.users
                ? [apiEndPoints.ACCOUNT_USERS]
                : []),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([
              contactsWithTranscripts,
              conversationStatus,
              users,
            ]) => {
              dispatchReducer({
                type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                payload: {
                  contactsWithTranscripts,
                },
              });

              // Check if live agent is enabled.
              if (
                state.currentAccount.account.modules.conversation
                  ?.pull_live_conversation_on_refresh &&
                !state.conversations.contactsWithTranscripts?.length
              ) {
                // Modify array of conversation status into object

                conversationStatus = conversationStatus?.reduce(
                  (conversationStatus, conversation) => {
                    // Current agent conversation status
                    if (
                      conversation.live_agent_id ===
                      state.currentUser.user.userID
                    ) {
                      if (conversation.live_agent_status === "JOINED") {
                        conversationStatus?.liveConversations.push(
                          conversation.conversation_id
                        );
                      }
                    }
                    // Waiting for live agent
                    else if (conversation.live_agent_status === "WAITING") {
                      conversationStatus?.waitingForLiveAgent.push(
                        conversation.conversation_id
                      );
                    }

                    // Co-agent agent conversation status
                    else if (conversation.live_agent_status === "JOINED") {
                      conversationStatus?.coAgentLiveConversations.push(
                        conversation.conversation_id
                      );
                    }
                    return conversationStatus;
                  },
                  {
                    liveConversations: [],
                    waitingForLiveAgent: [],
                    coAgentLiveConversations: [],
                  }
                );
                dispatchReducer({
                  type: "RD_CONVERSATIONS_AGENT_JOIN",
                  payload: {
                    liveConversations: conversationStatus?.liveConversations,
                  },
                });
                dispatchReducer({
                  type: "SET_WAITING_FOR_LIVEAGENT",
                  payload: {
                    waitingForLiveAgent:
                      conversationStatus?.waitingForLiveAgent,
                  },
                });
                dispatchReducer({
                  type: "SET_CO_AGENT_LIVE_CONVERSATIONS",
                  payload: {
                    coAgentLiveConversations:
                      conversationStatus?.coAgentLiveConversations,
                  },
                });
              } else {
                // If conversationStatus is empty in onSuccess, update users to its value.
                !state.accountUsers.users &&
                  dispatchReducer({
                    type: "SET_ACCOUNT_USERS",
                    // set color for each user
                    users: users?.data?.map((user) => ({
                      ...user,
                      colorCode: generateRandomBackgroundColor(),
                    })),
                  });
              }
              // If users is empty, update users to its value.
              !state.accountUsers.users &&
                dispatchReducer({
                  type: "SET_ACCOUNT_USERS",
                  // set color for each user
                  users: users?.data?.map((user) => ({
                    ...user,
                    colorCode: generateRandomBackgroundColor(),
                  })),
                });

              // Get the on hold conversations from local storage
              !action.payload.delta &&
                dispatchReducer({
                  type: "RD_SET_ON_HOLD_CONVERSATIONS",
                  payload: {
                    onHoldConversations: localStorage.getItem(
                      "onHoldConversations"
                    )
                      ? JSON.parse(localStorage.getItem("onHoldConversations"))
                      : [],
                  },
                });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        //Update transcript for a contact
        case "MWD_UPDATE_CONVERSATIONS_TRANSCRIPTS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                "?_id=",
                action.payload.contactId,
                "&pagesize=" + action.payload.pageSize,
                "&date=~lt~" + action.payload.lastMessageTimestamp
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([transcripts]) => {
              dispatchReducer({
                type: "RD_UPDATE_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  ...transcripts,
                  ...{ contactId: action.payload.contactId },
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException(JSON.stringify(error), {
                title: "Update Conversation Transcript Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        // If conversation triggered from response - view conversation
        case "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                ...[
                  `?conversation_id=${action.payload.conversationId.replace(
                    "+",
                    ""
                  )}&bot_id=${action.payload.botId}`,
                ]
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: async ([contactsWithTranscripts]) => {
              await callApi({
                urls: [
                  apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                    "?_id=",
                    action.payload.contactId?.replace("+", ""),
                    "&date=~gte~" +
                      // For resolving the responses date after no message issue, we are fetching data from the last 36 hours prior to the lead date
                      new Date(
                        new Date(action.payload.leadDate).getTime() -
                          36 * 60 * 60 * 1000
                      ).toISOString() +
                      "&module=responses"
                  ),
                ],
                options: {
                  method: "GET",
                },
                onSuccess: ([transcripts]) => {
                  dispatchReducer({
                    type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                    payload: {
                      contactsWithTranscripts: {
                        ...contactsWithTranscripts,
                        messages: transcripts?.data,
                      },
                    },
                  });

                  dispatchReducer({
                    type: "RD_SET_CURRENT_CONVERSATION",
                    payload: {
                      currentConversation: {
                        ...(contactsWithTranscripts?.data?.[0] || {}),
                        messages: transcripts?.data,
                      },
                    },
                  });
                  dispatchReducer({
                    type: "SUCCESS_API_TRANSACTION",
                    payload: { component: action.payload.component },
                  });

                  appInsights.trackEvent({
                    name: "Responses - View Conversation",
                    properties: {
                      environment: mode,
                      clientId: state.currentAccount.account.client_id,
                      clientName:
                        state.currentAccount.account?.general?.company,
                      category: action.payload.category,
                    },
                  });
                },
                onError: (error) => {
                  dispatchReducer({
                    type: "ERROR_API_TRANSACTION",
                    payload: { component: action.payload.component, error },
                  });
                },
              });
            },

            onError: (error) => {
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        //Agent joining the conversation
        case "MWD_CONVERSATIONS_AGENT_JOIN":
          console.log("Action ->", action);

          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });

          const conversationData =
            state.conversations.contactsWithTranscripts.find(
              (contactWithTranscripts) =>
                contactWithTranscripts._id ===
                action.payload.conversationId.concat("_", action.payload.botId)
            );

          console.log("Conversation Data ->", conversationData);

          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                "?_id=",
                action.payload.conversationId.concat("_", action.payload.botId),
                "&date=~gt~",
                state.conversations.lastUpdatedAt
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: async ([transcripts]) => {
              appInsights.trackEvent({
                name: "Conversations - Liveagent joined the conversation",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                  clientName: state.currentAccount.account?.general?.company,
                },
              });

              dispatchReducer({
                type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                  data: transcripts.data,
                },
              });

              dispatchReducer({
                type: "UPDATE_WAITING_FOR_LIVEAGENT",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                },
              });

              dispatchReducer({
                type: "RD_CONVERSATIONS_AGENT_JOIN",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Conversations Agent Join Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              console.log(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        //Agent leaving the conversation
        case "MWD_CONVERSATIONS_AGENT_LEAVE":
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                "?date=~gte~",
                state.conversations.lastUpdatedAt
              ),
              apiEndPoints.CONVERSATIONS.concat(
                "/conversations/unreadMessagesCount"
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([contactsWithTranscripts, unreadMessagesCount]) => {
              dispatchReducer({
                type: "RD_CONVERSATIONS_AGENT_LEAVE",
                payload: {
                  contactId: action.payload.conversationId.concat(
                    "_",
                    action.payload.botId
                  ),
                },
              });

              dispatchReducer({
                type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                payload: {
                  contactsWithTranscripts,
                },
              });

              dispatchReducer({
                type: "SET_UNREAD_MESSAGE_COUNT",
                payload: { unreadMessagesCount },
              });

              appInsights.trackEvent({
                name: "Conversations - Liveagent ended the conversation",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Agent Leave Conversation Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
            },
          });

          break;

        //Agent sending messages to the user
        case "MWD_CONVERSATIONS_AGENT_MESSAGE":
          const currentMoment = moment().utc().format();
          dispatchReducer({
            type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
            payload: {
              contactId: action.payload.conversationId.concat(
                "_",
                action.payload.botId
              ),
              data: [
                {
                  date: currentMoment,
                  is_from_user: false,
                  type: action.payload.type ?? "text",
                  message: action.payload.message,
                  is_agent: true,
                  agent_id: state.currentUser.user.userID,
                  isSending: action.payload.isSending,
                  //Based on isSending the component was added
                  component: action.payload.isSending
                    ? action.payload.component
                    : undefined,
                  directlineMessageId: action.payload.directlineMessageId,
                  deliveryStatus: [
                    {
                      status: action.payload.deliveryStatus,
                      timestamp: currentMoment,
                    },
                  ],
                  isSendFailed: action.payload.isSendFailed ?? false,
                },
              ],
            },
          });

          //Finally the component removed
          !action.payload.isSending &&
            dispatchReducer({
              type: "RD_REMOVE_CONVERSATIONS_TRANSCRIPTS",
              payload: {
                _id: action.payload.conversationId.concat(
                  "_",
                  action.payload.botId
                ),
                component: action.payload.component,
              },
            });

          break;

        //Handling messages from socket
        //ALL ***ACTION PAYLOADS*** ARE FROM WEB SOCKET (NOT FROM MIDDLEWARE / COMPONENTS)
        case "CONVERSATION_SUBSCRIBED_MESSAGE":
        case "MWD_CONVERSATIONS_FROM_WEB_SOCKET":
          if (action.payload.message_type === "JOIN") {
            dispatchReducer({
              type: "SET_WAITING_FOR_LIVEAGENT",
              payload: {
                contactId:
                  action.payload.conversationID + "_" + action.payload.botId,
              },
            });
            await callApi({
              urls: [
                apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                  "?date=~gte~",
                  state.conversations.lastUpdatedAt
                ),
                apiEndPoints.CONVERSATIONS.concat(
                  "/conversations/unreadMessagesCount"
                ),
              ],
              options: {
                method: "GET",
              },
              onSuccess: ([contactsWithTranscripts, unreadMessagesCount]) => {
                dispatchReducer({
                  type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                  payload: {
                    contactsWithTranscripts,
                  },
                });

                dispatchReducer({
                  type: "SET_UNREAD_MESSAGE_COUNT",
                  payload: { unreadMessagesCount },
                });
              },
              onError: () => {},
            });
          } else if (action.payload.message_type === "END") {
            const conversationData =
              state.conversations.contactsWithTranscripts?.find(
                (contactWithTranscripts) =>
                  contactWithTranscripts._id === action.payload._id
              );

            await callApi({
              urls: [
                apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                  "?_id=",
                  action.payload._id,
                  "&date=~gt~",
                  conversationData.messages[
                    conversationData.messages.length - 1
                  ].date
                ),
                apiEndPoints.CONVERSATIONS.concat(
                  "/conversations/unreadMessagesCount"
                ),
              ],
              options: {
                method: "GET",
              },
              onSuccess: ([transcripts, unreadMessagesCount]) => {
                dispatchReducer({
                  type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
                  payload: {
                    contactId: action.payload._id,
                    data: transcripts.data,
                  },
                });

                dispatchReducer({
                  type: "RD_CONVERSATIONS_AGENT_LEAVE",
                  payload: {
                    contactId: action.payload._id,
                  },
                });

                dispatchReducer({
                  type: "UPDATE_WAITING_FOR_LIVEAGENT",
                  payload: {
                    contactId: action.payload._id,
                  },
                });

                dispatchReducer({
                  type: "SET_UNREAD_MESSAGE_COUNT",
                  payload: { unreadMessagesCount },
                });
              },
              onError: (error) => {
                appInsights.trackException(JSON.stringify(error), {
                  title: "Conversation Agent Leave Web Socket Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                });
                dispatchReducer({
                  type: "ERROR_API_TRANSACTION",
                  payload: {
                    component:
                      "agent-leave-" +
                      state.conversations.currentConversation._id +
                      "-button",
                    error,
                  },
                });
                openErrorNotificationWithDeveloperMessage(error);
              },
            });
          } else if (action.payload.message_type === "MESSAGE") {
            console.log(action.payload);

            if (
              action.payload.message &&
              (action.payload.message === "*!**timedout**!*" ||
                // If user send "bot" then leave the conversation
                action.payload.message.toLowerCase() === "bot")
            ) {
              dispatchReducer({
                type: "RD_CONVERSATIONS_AGENT_LEAVE",
                payload: {
                  contactId: action.payload._id,
                },
              });

              await callApi({
                urls: [
                  apiEndPoints.CONVERSATIONS_CONTACTS.concat(
                    "?date=~gte~",
                    action.payload.timestamp
                  ),
                  apiEndPoints.CONVERSATIONS.concat(
                    "/conversations/unreadMessagesCount"
                  ),
                ],
                options: {
                  method: "GET",
                },
                onSuccess: ([contactsWithTranscripts, unreadMessagesCount]) => {
                  dispatchReducer({
                    type: "RD_UPDATE_CONVERSATIONS_CONTACTS_WITH_TRANSCRIPTS",
                    payload: {
                      contactsWithTranscripts,
                    },
                  });

                  dispatchReducer({
                    type: "SET_UNREAD_MESSAGE_COUNT",
                    payload: { unreadMessagesCount },
                  });
                },
                onError: (error) => {
                  appInsights.trackException({
                    exception: error,
                    properties: {
                      title: "Agent Leave Conversation Error",
                      clientId: state.currentAccount.account.client_id,
                      environment: mode,
                    },
                  });
                },
              });
            } else {
              dispatchReducer({
                type: "RD_ADD_NEW_CONVERSATIONS_TRANSCRIPTS",
                payload: {
                  contactId: action.payload._id,
                  data: [
                    {
                      date: action.payload.timestamp,
                      is_from_user: true,
                      type: action.payload.attachment?.length
                        ? action.payload.attachment[0].contentType
                        : "text",
                      message: action.payload.attachment?.length
                        ? action.payload.attachment[0].contentUrl
                        : action.payload.message,
                      is_agent: false,
                    },
                  ],
                },
              });
            }
          } else if (action.payload.message_type === "DELIVERY_STATUS") {
            dispatchReducer({
              type: "UPDATE_CONVERSATIONS_TRANSCRIPTS_DELIVERY_STATUS",
              payload: {
                contactId: action.payload.conversationId,
                directlineMessageId: action.payload.directlineMessageId,
                deliveryStatus: action.payload.deliveryStatus,
              },
            });
          }

          break;

        //Load Live agent notes
        case "LOAD_LIVE_AGENT_NOTES":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              apiEndPoints.LIVE_AGENT_NOTES.concat(
                "?_id=",
                state.conversations.currentConversation._id,
                "&sortKey=timestamp&sortType=1",
                action.payload.searchText
                  ? "&searchText=" + action.payload.searchText
                  : "",
                action.payload.pageNumber
                  ? "&pagenumber=" + action.payload.pageNumber
                  : "",
                action.payload.pageSize
                  ? "&pagesize=" + action.payload.pageSize
                  : ""
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([notes]) => {
              dispatchReducer({
                type: "LOAD_LIVE_AGENT_NOTES",
                payload: {
                  contactId: state.conversations.currentConversation._id,
                  notes,
                  actionType: action.payload.actionType,
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Load Notes Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        //Add / Edit Live agent note
        case "ADD_EDIT_LIVE_AGENT_NOTE":
          const { form, conversations, filters } = state;
          const currentConversationId = conversations.currentConversation._id;
          const actionRowKey = filters.actionRowKey["live-agent-notes-form"];

          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              form["live-agent-notes-form"].mode === "add"
                ? `${apiEndPoints.LIVE_AGENT_NOTES}?_id=${currentConversationId}`
                : `${apiEndPoints.LIVE_AGENT_NOTES}?noteID=${actionRowKey}`,
            ],
            options: {
              method:
                state.form["live-agent-notes-form"].mode === "add"
                  ? "POST"
                  : "PUT",
              body: action.payload.note,
            },
            onSuccess: ([note]) => {
              if (state.form["live-agent-notes-form"].mode === "add") {
                dispatchReducer({
                  type: "ADD_LIVE_AGENT_NOTE",
                  payload: {
                    contactId: state.conversations.currentConversation._id,
                    note,
                  },
                });
                dispatchReducer({
                  type: "CLOSE_INFO_PANEL",
                  payload: {
                    component: "conversation-note-text-editor-info-panel",
                  },
                });
                appInsights.trackEvent({
                  name: "Live agent note added",
                  properties: {
                    environment: mode,
                    clientId: state.currentAccount.account.client_id,
                  },
                });
                openNotificationWithIcon("success", "Note added successfully");
              } else if (state.form["live-agent-notes-form"].mode === "edit") {
                dispatchReducer({
                  type: "EDIT_LIVE_AGENT_NOTE",
                  payload: {
                    contactId: state.conversations.currentConversation._id,
                    note,
                  },
                });
                dispatchReducer({
                  type: "CLOSE_INFO_PANEL",
                  payload: {
                    component: "conversation-note-text-editor-info-panel",
                  },
                });
                appInsights.trackEvent({
                  name: "Live agent note modified",
                  properties: {
                    environment: mode,
                    clientId: state.currentAccount.account.client_id,
                  },
                });
                openNotificationWithIcon(
                  "success",
                  "Note modified successfully"
                );
              }
              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "live-agent-notes-form",
                  mode: "",
                  title: "",
                },
              });
              dispatchReducer({
                type: "RD_CLEAR_ACTION_ROW_KEY",
                payload: { component: "live-agent-notes-form" },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Add/Edit Live agent notes Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        //Delete Live agent notes
        case "DELETE_LIVE_AGENT_NOTE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              apiEndPoints.LIVE_AGENT_NOTES.concat(
                "?noteID=",
                action.payload.noteId,
                "&liveAgentID=",
                state.currentUser.user.userID
              ),
            ],
            options: {
              method: "DELETE",
            },
            onSuccess: () => {
              dispatchReducer({
                type: "DELETE_LIVE_AGENT_NOTE",
                payload: {
                  noteId: action.payload.noteId,
                  contactId: state.conversations.currentConversation._id,
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
              openNotificationWithIcon("success", "Note deleted successfully");
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Delete Note Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        //Load AI Response
        case "LOAD_AI_ASSISTANT_HELP_TEXT":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [apiEndPoints.AI_ASSISTANT],
            options: {
              method: "POST",
              header: {
                "api-key":
                  state?.currentAccount?.account?.openai
                    ?.portal_oai_services_api_key,
              },
              body: {
                prompt: "Human:" + action.payload.question + "\nAI:",
                max_tokens: 60,
                temperature: 0,
              },
              isTokenRequired: false,
            },
            onSuccess: ([aiAssistantHelpText]) => {
              dispatchReducer({
                type: "SET_AI_ASSISTANT_HELP_TEXT",
                payload: {
                  aiAssistantHelpText: aiAssistantHelpText?.choices[0]?.text,
                },
              });

              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });

              appInsights.trackEvent({
                name: "AI Assistant - Load AI Assistant Help Text",
                properties: {
                  tokenUsed: aiAssistantHelpText?.usage?.total_tokens,
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Load Ai Assistant Response Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: {
                  component: action.payload.component,
                  error,
                },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        // Conversations summary
        // Get conversations
        case "SUMMARIZE_CONVERSATION":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: {
              component: action.payload.component,
            },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS_TRANSCRIPTS.concat(
                "?_id=",
                action.payload.conversationId,
                "&pagesize=" + action.payload.numberOfConversationToSummarize
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: async ([transcript]) => {
              // Get Summary of the conversation
              if (transcript?.data?.length > 0) {
                const prompt = transcript?.data.reduce((acc, message) => {
                  if (message.type === "text") {
                    const prefix = message.is_from_user
                      ? "User: "
                      : message.is_agent
                      ? "Agent: "
                      : "Bot: ";
                    acc += `${prefix}${message.message} \n`;
                  }
                  return acc;
                }, "");

                await callApi({
                  urls: [apiEndPoints.AI_CONVERSATION_SUMMARY],
                  options: {
                    method: "POST",
                    header: {
                      "api-key":
                        state?.currentAccount?.account?.openai
                          ?.portal_oai_services_api_key,
                    },
                    body: {
                      prompt: `Please analyze the entire conversation below and provide a concise summary that captures its meaning and ends with a period (.). ${prompt} \n\nSummary:`,
                      max_tokens: 100,
                      temperature: 0,
                    },
                    isTokenRequired: false,
                  },
                  onSuccess: ([conversationSummary]) => {
                    dispatchReducer({
                      type: "SET_CONVERSATION_SUMMARY",
                      payload: {
                        conversationSummary: {
                          summary: conversationSummary?.choices[0]?.text ?? "",
                          usage: conversationSummary.usage,
                        },
                      },
                    });

                    appInsights.trackEvent({
                      name: "Conversation Summary - Summarized Conversation",
                      properties: {
                        tokenUsed: conversationSummary?.usage?.total_tokens,
                        environment: mode,
                        clientId: state.currentAccount.account.client_id,
                      },
                    });
                  },
                  onError: (error) => {
                    appInsights.trackException({
                      exception: error,
                      properties: {
                        title: "Load Conversation summary Error",
                        clientId: state.currentAccount.account.client_id,
                        environment: mode,
                      },
                    });
                    dispatchReducer({
                      type: "ERROR_API_TRANSACTION",
                      payload: {
                        component: action.payload.component,
                        error,
                      },
                    });
                    openErrorNotificationWithDeveloperMessage({
                      title: "INXT14002: Failed to summarize conversation",
                      description:
                        "Unable to summarize conversation. Please check more details.",
                      developer_message: JSON.stringify(error),
                    });
                  },
                });
              } else {
                dispatchReducer({
                  type: "SET_CONVERSATION_SUMMARY",
                  payload: {
                    conversationSummary: "",
                  },
                });
              }
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Load Transcript Based on Timespan Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        // Load conversation user state
        case "LOAD_CONVERSATION_USER_STATE":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS.concat(
                "/conversation/contactinfo",
                "?channel=" +
                  (state.conversations.currentConversation.channel === "WEB"
                    ? // For web channel, the channel is directline. This is handled by botframework.
                      // We don't have control on state file name. So, we are hardcoding it here.
                      "directline"
                    : state.conversations.currentConversation.channel),
                "&_id=" +
                  (state.conversations.currentConversation.user_id?.startsWith(
                    "+"
                  )
                    ? state.conversations.currentConversation.user_id?.replace(
                        "+",
                        ""
                      )
                    : state.conversations.currentConversation.user_id)
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([conversationUserState]) => {
              dispatchReducer({
                type: "SET_CONVERSATION_USER_STATE",
                payload: { conversationUserState },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException({
                exception: error,
                properties: {
                  title: "Load Conversation User State Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                },
              });
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
            },
          });
          break;

        // Load unread messages count
        case "LOAD_UNREAD_MESSAGES_COUNT":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS.concat(
                "/conversations/unreadMessagesCount"
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: ([unreadMessagesCount]) => {
              dispatchReducer({
                type: "SET_UNREAD_MESSAGE_COUNT",
                payload: { unreadMessagesCount },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;

        // Clear unread messages count
        case "CLEAR_UNREAD_MESSAGES_COUNT":
          dispatchReducer({
            type: "START_API_TRANSACTION",
            payload: { component: action.payload.component },
          });
          await callApi({
            urls: [
              apiEndPoints.CONVERSATIONS.concat(
                "/conversations/clearUnreadMessagesCount?_id=",
                action.payload.contactId
              ),
            ],
            options: {
              method: "GET",
            },
            onSuccess: () => {
              dispatchReducer({
                type: "CLEAR_UNREAD_MESSAGES_COUNT",
                payload: {
                  contactId: action.payload.contactId,
                  unreadMessagesCount: action.payload.unreadMessagesCount,
                },
              });
              dispatchReducer({
                type: "SUCCESS_API_TRANSACTION",
                payload: { component: action.payload.component },
              });
            },
            onError: (error) => {
              appInsights.trackException(
                JSON.stringify(error),

                {
                  title: "Clear Unread Messages Count Error",
                  clientId: state.currentAccount.account.client_id,
                  environment: mode,
                }
              );
              dispatchReducer({
                type: "ERROR_API_TRANSACTION",
                payload: { component: action.payload.component, error },
              });
              openErrorNotificationWithDeveloperMessage(error);
            },
          });
          break;
      }
    }
  };
};
