import { Input, Col, Row, Divider, Space, Tooltip, Button, Modal } from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  CloseOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { RiFilterOffLine } from "react-icons/ri";
import {
  TabLoader,
  ComponentLoader,
} from "../../../shared-components/LoadingIndicator";
import { convertObjectToString } from "../../../utils/utility";
import { Translation } from "../../../translation/translation";

const { confirm } = Modal;

const UnrecognizedIntentsHeader = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const translation = Translation().unrecognizedIntents.header;

  const selectedRowKeys =
    state.filters.selectedRowKeys?.["unrecognized-intents-table-selected-row"];

  // Convert filter object to string
  const filteredValue = convertObjectToString(
    state.filters.filteredInfo?.["unrecognized-intents-table"] || {}
  );
  const sortedValue =
    state.filters.sortedInfo?.["unrecognized-intents-table"] || {};
  const pagination =
    state.filters.pagination?.["unrecognized-intents-table"] || {};

  return (
    <Row>
      <Col span={20}>
        <Space>
          <Input
            disabled={state.unrecognizedIntents.data.length === 0}
            prefix={<SearchOutlined />}
            placeholder="Search by phrase and notes"
            value={
              state.filters.searchText?.["unrecognized-intents-search-text-box"]
            }
            style={{ width: 400 }}
            onChange={(e) => {
              const currentValue = e.target.value;
              dispatchReducer({
                type: "RD_SET_SEARCH_TEXT",
                payload: {
                  component: "unrecognized-intents-search-text-box",
                  searchText: currentValue,
                },
              });
            }}
            onPressEnter={(e) => {
              dispatchMiddleware({
                type: "LOAD_UNRECOGNIZED_INTENTS",
                payload: {
                  component: "unrecognized-intents-table",
                  filteredValue,
                  ...sortedValue,
                  searchText: e.target.value,
                  pageSize: pagination.pageSize,
                  pageNumber: 1,
                },
              });
              dispatchReducer({
                type: "RD_SET_PAGINATION",
                payload: {
                  component: "unrecognized-intents-table",
                  pagination: {
                    ...state.filters.pagination?.["unrecognized-intents-table"],
                    current: 1,
                  },
                },
              });
            }}
            allowClear={{
              clearIcon: (
                <CloseOutlined
                  onClick={() => {
                    dispatchReducer({
                      type: "RD_SET_SEARCH_TEXT",
                      payload: {
                        component: "unrecognized-intents-search-text-box",
                        searchText: "",
                      },
                    });
                    dispatchMiddleware({
                      type: "LOAD_UNRECOGNIZED_INTENTS",
                      payload: {
                        component: "unrecognized-intents-table",
                        pageNumber: 1,
                        pageSize: pagination?.pageSize,
                        filteredValue,
                        ...sortedValue,
                        searchText: "",
                      },
                    });
                    dispatchReducer({
                      type: "RD_SET_PAGINATION",
                      payload: {
                        component: "unrecognized-intents-table",
                        pagination: {
                          ...state.filters.pagination?.[
                            "unrecognized-intents-table"
                          ],
                          current: 1,
                        },
                      },
                    });
                  }}
                />
              ),
            }}
          />
          <Divider type="vertical" />

          <Tooltip placement="top" title="Clear filter(s)">
            <Button
              disabled={
                state.filters.filteredInfo["unrecognized-intents-table"] &&
                (state.filters.filteredInfo["unrecognized-intents-table"]
                  .timeStamp ||
                  state.filters.filteredInfo["unrecognized-intents-table"]
                    .channel ||
                  state.filters.filteredInfo["unrecognized-intents-table"]
                    .assignedTo ||
                  state.filters.filteredInfo["unrecognized-intents-table"]
                    .status)
                  ? false
                  : true
              }
              icon={<RiFilterOffLine style={{ margin: -2 }} />}
              onClick={() => {
                dispatchReducer({
                  type: "RD_SET_FILTERED_INFO",
                  payload: {
                    component: "unrecognized-intents-table",
                    filteredInfo: {},
                  },
                });

                dispatchMiddleware({
                  type: "LOAD_UNRECOGNIZED_INTENTS",
                  payload: {
                    component: "unrecognized-intents-table",
                    pageNumber: 1,
                    pageSize: pagination?.pageSize,
                    ...sortedValue,
                    searchText:
                      state.filters.searchText?.[
                        "unrecognized-intents-search-text-box"
                      ],
                  },
                });
                dispatchReducer({
                  type: "RD_SET_PAGINATION",
                  payload: {
                    component: "unrecognized-intents-table",
                    pagination: {
                      ...pagination,
                      current: 1,
                    },
                  },
                });
                dispatchReducer({
                  type: "RD_SET_SEARCH_TEXT",
                  payload: {
                    component: "unrecognized-intents-search-text-box",
                    searchText: "",
                  },
                });
              }}
            />
          </Tooltip>

          {state.apiCallStatus["unrecognized-intents-export-button"]
            ?.loading ? (
            <TabLoader
              component="unrecognized-intents-export-button"
              errorMessage="Error exporting responses"
            />
          ) : (
            <Tooltip placement="top" title={"Export Unrecognized Intents"}>
              <Button
                disabled={state.unrecognizedIntents.totalRecords === 0}
                icon={<DownloadOutlined />}
                onClick={() => {
                  dispatchMiddleware({
                    type: "LOAD_UNRECOGNIZED_INTENTS",
                    payload: {
                      component: "unrecognized-intents-export-button",
                      filteredValue,
                      ...sortedValue,
                      searchText:
                        state.filters.searchText?.[
                          "unrecognized-intents-search-text-box"
                        ],
                      pageSize: pagination.pageSize,
                      pageNumber: 1,
                      eventType: "unrecognizedIntentsExport",
                    },
                  });
                }}
              />
            </Tooltip>
          )}
        </Space>
      </Col>

      <Col span={4} style={{ textAlign: "right" }}>
        {state.apiCallStatus?.["unrecognized-intents-table-selected-row"]
          ?.loading ? (
          <ComponentLoader
            component="unrecognized-intents-table-selected-row"
            loadingMessage="Loading..."
            errorMessage=""
          />
        ) : (
          <Tooltip
            placement="topRight"
            title={
              state.currentUser.permission.UNRECOGNIZED_INTENTS_DELETE
                ? !selectedRowKeys?.length
                  ? translation.deleteUnrecognizedIntents.warningText
                  : translation.deleteUnrecognizedIntents.infoText
                : translation.noAccess
            }
          >
            <Button
              type="primary"
              disabled={
                !state.currentUser.permission.UNRECOGNIZED_INTENTS_DELETE ||
                !selectedRowKeys?.length
              }
              onClick={() => {
                confirm({
                  title:
                    "Are you sure you want to delete the unrecognized intent(s)?",
                  content:
                    "When clicked the OK button, the unrecognized intent(s) will be deleted permanently",
                  onOk() {
                    dispatchMiddleware({
                      type: "DELETE_UNRECOGNIZED_INTENT(S)",
                      payload: {
                        component: "unrecognized-intents-table-selected-row",
                        selectedRowKeys,
                      },
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              Delete Unrecognized Intents
            </Button>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default UnrecognizedIntentsHeader;
