import React, { useState } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Col, Input, Row, Typography, Space, Form, Card, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AiOutlineSend } from "react-icons/ai";
import { MdOutlineRestartAlt } from "react-icons/md";
import { AiLoader } from "../../../shared-components/LoadingIndicator";
import { TypingEffect } from "../../../shared-components/TypingEffect";
import { Translation } from "../../../translation/translation";

const { Text } = Typography;
const { TextArea } = Input;

const BotKnowledgebaseTest = ({ botInAction }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();

  const translation = Translation().botKnowledgeArea.botKnowledgebase.test;

  const [status, setStatus] = useState(false);

  let knowledgebaseTestResult =
    state.botKnowledgebase?.knowledgebaseTestResult?.answer;

  const handleFormSubmit = (e) => {
    dispatchMiddleware({
      type: "TEST_BOT_KNOWLEDGEBASE",
      payload: {
        botInAction,
        query: e.knowledgebaseTest,
        status,
        component: "test-bot-knowledgebase-form",
      },
    });
  };

  return (
    <Row gutter={[0, 15]}>
      <Col span={16}>
        <Space size="middle">
          <CloseOutlined
            style={{ fontSize: "18px" }}
            onClick={() => {
              dispatchReducer({
                type: "CLOSE_INFO_PANEL",
                payload: {
                  component: "knowledgebase-test-info-panel",
                },
              });
            }}
          />

          <Text strong>{translation.title}</Text>
        </Space>
      </Col>

      <Col span={8}>
        <Select
          size="small"
          style={{
            width: "100%",
          }}
          defaultValue={false}
          onChange={(value) => {
            setStatus(value);
          }}
        >
          <Select.Option value={false}>Draft</Select.Option>
          <Select.Option value={true}>Published</Select.Option>
        </Select>
      </Col>

      <Col span={24}>
        <Text>
          This is a test playground for the bot. Type your query here and see
          how the bot responds.
        </Text>
      </Col>

      <Col span={24}>
        <Form form={form} onFinish={handleFormSubmit}>
          <Row>
            <Col span={23}>
              <Form.Item
                name="knowledgebaseTest"
                rules={[
                  {
                    required: true,
                    message: translation.knowledgebaseTestFormItem.required,
                  },
                  {
                    max: 400,
                    message: "Maximum 400 characters allowed",
                  },
                ]}
              >
                <TextArea
                  placeholder={
                    translation.knowledgebaseTestFormItem.placeholder
                  }
                  allowClear={{
                    clearIcon: <CloseOutlined />,
                  }}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    form.submit();
                  }}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  showCount
                  maxLength={400}
                  style={{
                    width: "98%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item>
                <AiOutlineSend
                  size={20}
                  style={{
                    color: "#F05742",
                    marginTop: "5px",
                    marginRight: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    form.submit();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24}>
        {state.apiCallStatus?.["test-bot-knowledgebase-form"]?.loading ||
        state.apiCallStatus?.["test-bot-knowledgebase-form"]?.error ? (
          <AiLoader
            component="test-bot-knowledgebase-form"
            loadingMessage="Processing your query"
            errorMessage="Something went wrong. Please try again later"
          />
        ) : knowledgebaseTestResult ? (
          <Card
            size="small"
            className="knowledgebase-test-answer-container"
            style={{ height: "50vh", overflow: "scroll" }}
          >
            <Space
              direction="vertical"
              style={{
                width: "100%",
              }}
            >
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>{translation.testResult.answer}</Text>
                <Text
                  type="secondary"
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    color: "#F05742",
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      "knowledgebaseTestConversationID",
                      "user" + Date.now()
                    );

                    dispatchReducer({
                      type: "CLEAR_KNOWLEDGEBASE_TEST_RESULT",
                    });
                  }}
                >
                  <MdOutlineRestartAlt
                    style={{
                      fontSize: "15px",
                      marginRight: "5px",
                      marginBottom: -2,
                      color: "#F05742",
                    }}
                  />
                  Restart Conversation
                </Text>
              </Space>

              <TypingEffect
                message={knowledgebaseTestResult}
                tokenUsage={
                  state.botKnowledgebase.knowledgebaseTestResult?.total_tokens
                }
                source={state.botKnowledgebase.knowledgebaseTestResult?.source}
                aiModal={botInAction?.qna?.openai_completion_name}
              />
            </Space>
          </Card>
        ) : (
          <Card size="small">
            <Space direction="vertical">
              <Text type="secondary">
                Your query will be processed by the bot and the response will be
                displayed here.
              </Text>
            </Space>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default BotKnowledgebaseTest;
