import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Space,
  Divider,
  Button,
  Typography,
  Image,
  Layout,
  Modal,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useGlobalContext } from "../../contexts/globalContext";
import { botProfilePictures } from "../../constants/enums";
import BotSettingsMenu from "./BotSettingsMenu";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import AccessDenied from "../../shared-components/AccessDenied";

const { Text } = Typography;

const BotProfile = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBot, setSelectedBot] = useState();

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  useEffect(() => {
    setSelectedBot(botProfilePictures[botToBeEdited.bot_icon]);
    form.setFieldsValue({
      botDisplayName: botToBeEdited.bot_name,
      botNotes: botToBeEdited.bot_notes,
      announcementMessage:
        botToBeEdited.bot_announcement?.message ||
        botToBeEdited.bot_announcement_message,
      announcementTitle: botToBeEdited.bot_announcement?.title,
    });
  }, [state.application.botInAction]);

  const handleSubmitBotDetails = (e) => {
    let selectedSplitedBot = selectedBot.split("/");
    let modifiedBotIconName =
      selectedSplitedBot[selectedSplitedBot.length - 1].split(".")[0];

    const formBotData = {
      bot_name: e.botDisplayName,
      bot_notes: e.botNotes,
      bot_icon: modifiedBotIconName,
      ...(botToBeEdited.bot_announcement
        ? {
            bot_announcement: {
              message: e.announcementMessage,
              title: e.announcementTitle,
            },
          }
        : { bot_announcement_message: e.announcementMessage }),
    };

    dispatchMiddleware({
      type: "MWD_UPDATE_BOT_DETAILS",
      payload: {
        botSetting: formBotData,
        component: "bot-settings-general-form",
      },
    });
  };

  return (
    <>
      {!state.currentUser.permission.BOT_SETTINGS_VIEW ? (
        <AccessDenied />
      ) : (
        <Layout>
          <BotSettingsMenu />
          <Layout style={{ background: "#ffffff" }}>
            <Layout.Content
              style={{
                padding: "25px",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {state.apiCallStatus["bot-settings-general-form"] &&
              state.apiCallStatus["bot-settings-general-form"].loading ? (
                <FullPageLoader
                  component="bot-settings-general-form"
                  loadingMessage="Submitting... Please wait..."
                />
              ) : (
                <Form
                  layout="vertical"
                  autoComplete="off"
                  form={form}
                  requiredMark={false}
                  onFinish={handleSubmitBotDetails}
                >
                  <Row gutter={24}>
                    <Col span={12}>
                      <Row>
                        <Col span={24} style={{ marginBottom: "20px" }}>
                          <Row>
                            <Col span={24}>
                              <Text strong>Subscription Package</Text>
                            </Col>
                            <Col span={24}>
                              <Text type="secondary">
                                {botToBeEdited?.subscription_package ?? "-"}
                              </Text>
                            </Col>

                            <Col span={24} style={{ marginTop: "20px" }}>
                              <Text strong>Bot System Name</Text>
                            </Col>
                            <Col span={24}>
                              <Text type="secondary">
                                {botToBeEdited.bot_id
                                  ? botToBeEdited.bot_id
                                  : "-"}
                              </Text>
                            </Col>

                            <Col span={24} style={{ marginTop: "20px" }}>
                              <Text strong>Bot Version</Text>
                            </Col>
                            <Col span={24}>
                              <Text type="secondary">
                                {botToBeEdited.bot_version
                                  ? botToBeEdited.bot_version
                                  : "-"}
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="botDisplayName"
                            label={<Text strong>Display Name</Text>}
                            rules={[
                              {
                                required: true,
                                message: "Please enter display name",
                              },
                              { whitespace: true },

                              {
                                max: 100,
                                message:
                                  "The display name should not be more then 100 characters",
                              },
                            ]}
                            hasFeedback
                            valuePropName="value"
                          >
                            <Input style={{ width: "95%" }} />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="botNotes"
                            label={<Text strong>Notes</Text>}
                            rules={[
                              { whitespace: true },
                              {
                                max: 1024,
                                message:
                                  "The bot notes should not be more then 1024 characters",
                              },
                            ]}
                            hasFeedback
                            valuePropName="value"
                          >
                            <TextArea
                              placeholder="Write a note"
                              rows={4}
                              style={{ resize: "none", width: "95%" }}
                            />
                          </Form.Item>
                        </Col>

                        <Divider />

                        <Col span={24} style={{ marginBottom: "20px" }}>
                          <Space size={0} direction="vertical">
                            <Text strong>Announcement</Text>
                            <Text type="secondary">
                              (This will be delivered to all the recipient in
                              chat right after the Welcome Message)
                            </Text>
                          </Space>
                        </Col>

                        {botToBeEdited.bot_announcement ? (
                          <>
                            <Col span={24}>
                              <Form.Item
                                name="announcementTitle"
                                rules={[
                                  { whitespace: true },

                                  {
                                    max: 50,
                                    message:
                                      "The title should not be more then 50 characters",
                                  },
                                ]}
                                hasFeedback
                                valuePropName="value"
                              >
                                <Input
                                  style={{ width: "95%" }}
                                  placeholder="Announcement title"
                                />
                              </Form.Item>
                            </Col>

                            <Col span={24}>
                              <Form.Item
                                name="announcementMessage"
                                rules={[
                                  { whitespace: true },
                                  {
                                    max: 1024,
                                    message:
                                      "The announcement should not be more then 1024 characters",
                                  },
                                ]}
                                hasFeedback
                                valuePropName="value"
                              >
                                <TextArea
                                  placeholder="Announcement Message"
                                  rows={4}
                                  style={{ resize: "none", width: "95%" }}
                                  showCount
                                  maxLength={1024}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <Col span={24}>
                            <Form.Item
                              name="announcementMessage"
                              rules={[
                                { whitespace: true },
                                {
                                  max: 1024,
                                  message:
                                    "The announcement should not be more then 1024 characters",
                                },
                              ]}
                              hasFeedback
                              valuePropName="value"
                            >
                              <TextArea
                                placeholder="Announcement Message"
                                rows={4}
                                showCount
                                maxLength={1024}
                                style={{ resize: "none", width: "95%" }}
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Col>

                    <Col span={12} style={{ textAlign: "center" }}>
                      <Space direction="vertical">
                        <Space>
                          <Text strong>Picture</Text>
                        </Space>
                        {selectedBot ? (
                          <Image
                            src={selectedBot}
                            width="125px"
                            style={{
                              border: "1px solid #EFEFEE",
                              padding: 20,
                            }}
                            preview={false}
                          />
                        ) : (
                          <Image
                            src={botProfilePictures[botToBeEdited.bot_icon]}
                            width="125px"
                            style={{
                              border: "1px solid #EFEFEE",
                              padding: 20,
                            }}
                            preview={false}
                          />
                        )}

                        <Space
                          style={{
                            textDecoration: "underline",
                            color: "#F05742",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIsModalVisible(true);
                          }}
                        >
                          Change Picture
                        </Space>
                      </Space>

                      <Modal
                        open={isModalVisible}
                        onOk={() => {
                          setIsModalVisible(false);
                        }}
                        onCancel={() => {
                          setIsModalVisible(false);
                        }}
                        width="500px"
                        footer={null}
                      >
                        {Object.keys(botProfilePictures).map(
                          (botIcon, index) => {
                            return (
                              <>
                                <Image
                                  key={index}
                                  value={selectedBot}
                                  preview={false}
                                  src={botProfilePictures[botIcon]}
                                  style={{
                                    width: "70px",
                                    margin: "10px",
                                  }}
                                  onClick={(e) => {
                                    const url = new URL(e.target.src).pathname;
                                    setSelectedBot(url);
                                    setIsModalVisible(false);
                                  }}
                                />
                              </>
                            );
                          }
                        )}
                      </Modal>
                    </Col>

                    <Col span={24}>
                      <Divider />
                      <Space size="small">
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              )}
            </Layout.Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default BotProfile;
