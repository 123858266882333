import { Area } from "@ant-design/plots";
import { Typography, Tooltip, Row, Col, Space } from "antd";
import { IoIosInformationCircleOutline } from "react-icons/io";

const { Text } = Typography;

const TicketsRaisedTodayDateChart = () => {
  const ticketCount = [
    { date: "00:00", count: 0 },
    { date: "02:00", count: 1 },
    { date: "04:00", count: 0 },
    { date: "06:00", count: 0 },
    { date: "08:00", count: 0 },
    { date: "10:00", count: 0 },
    { date: "12:00", count: 2 },
    { date: "14:00", count: 0 },
    { date: "16:00", count: 0 },
    { date: "18:00", count: 0 },
    { date: "20:00", count: 0 },
    { date: "22:00", count: 0 },
    { date: "23:59", count: 0 }, // End of the day
  ];

  const config = {
    smooth: true,
    xField: "date",
    yField: "count",
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    color: "#a8071a",
    slider: {
      start: 0,
      end: 1, // End of the day
    },
  };

  return (
    <>
      <Row>
        <Col span={23}>
          <Text strong>Tickets By Date</Text>
        </Col>
        <Col span={1}>
          <Space>
            <Tooltip
              style={{ marginTop: 5 }}
              placement="topRight"
              title="This statistic shows the count of tickets raised today date."
            >
              <IoIosInformationCircleOutline size="1.5em" />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Area {...config} data={ticketCount} style={{ height: "230px" }} />
    </>
  );
};

export default TicketsRaisedTodayDateChart;
