const cannedResponsesReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_CANNED_RESPONSES":
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          ...{
            liveAgentCannedResponses: action.payload.liveAgentCannedResponses,
          },
        },
      };

    case "RD_SET_CANNED_RESPONSES_FOLDER_LIST":
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          ...{
            cannedResponsesFolderList: action.payload.cannedResponsesFolderList,
          },
        },
      };

    case "RD_ADD_CANNED_RESPONSES":
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          ...{
            liveAgentCannedResponses: [
              ...state.cannedResponses.liveAgentCannedResponses,
              action.payload.cannedResponse,
            ],
          },
        },
      };

    case "RD_EDIT_CANNED_RESPONSES":
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          ...{
            liveAgentCannedResponses:
              state.cannedResponses.liveAgentCannedResponses.map(
                (cannedResponse) =>
                  cannedResponse._id === action.payload.cannedResponse._id
                    ? Object.assign(
                        {},
                        cannedResponse,
                        action.payload.cannedResponse
                      )
                    : cannedResponse
              ),
          },
        },
      };

    case "RD_DELETE_CANNED_RESPONSES":
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          ...{
            liveAgentCannedResponses:
              state.cannedResponses.liveAgentCannedResponses.filter(
                (cannedResponse) =>
                  action.payload.actionRowKey !== cannedResponse._id
              ),
          },
        },
      };

    case "RD_SET_CURRENT_CANNED_RESPONSE_FOLDER":
      return {
        ...state,
        cannedResponses: {
          ...state.cannedResponses,
          ...{
            currentCannedResponseFolder:
              action.payload.currentCannedResponseFolder,
          },
        },
      };

    default:
      return state;
  }
};

export default cannedResponsesReducer;
