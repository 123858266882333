import React from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Drawer,
  Typography,
  Card,
  Row,
  Col,
  Space,
  Form,
  Input,
  Button,
  Empty,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  FullPageLoader,
  TabLoader,
  ComponentLoader,
} from "../../../shared-components/LoadingIndicator";
import openNotificationWithIcon from "../../../utils/notification";
import { Translation } from "../../../translation/translation";
import { formatText } from "../../../utils/utility";

const { Text } = Typography;
const { TextArea } = Input;

const BotKnowledgebaseInfoPanel = ({ botInAction }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [form] = Form.useForm();

  const translation = Translation().botKnowledgeArea.botKnowledgebase.section;

  const handleFormSubmit = (formValues) => {
    dispatchMiddleware({
      type: "ADD_EDIT_BOT_KNOWLEDGEBASE_SECTION",
      payload: {
        botInAction,
        formValues: formValues.section[0],
        component: "bot-knowledgebase-section-form",
      },
    });
  };

  const searchText =
    state.filters.searchText["bot-knowledgebase-section-search-text-box"] &&
    state.filters.searchText["bot-knowledgebase-section-search-text-box"];

  let filteredSections =
    state.botKnowledgebase?.singleBotKnowledgebaseFile?.data;

  if (searchText) {
    filteredSections =
      state.botKnowledgebase?.singleBotKnowledgebaseFile?.data?.filter(
        ({ section_title, section_content }) => {
          return (
            (section_title &&
              section_title.toLowerCase().includes(searchText.toLowerCase())) ||
            (section_content &&
              section_content.toLowerCase().includes(searchText.toLowerCase()))
          );
        }
      );
  }
  return (
    <Drawer
      title={
        state.infoPanel["bot-knowledgebase-info-panel"] &&
        state.infoPanel["bot-knowledgebase-info-panel"].title
      }
      onClose={() => {
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "bot-knowledgebase-info-panel",
          },
        });

        dispatchReducer({
          type: "RD_CLEAR_ACTION_ROW_KEY",
          payload: { component: "bot-knowledgebase-info-panel" },
        });
      }}
      open={
        state.infoPanel["bot-knowledgebase-info-panel"] &&
        state.infoPanel["bot-knowledgebase-info-panel"].visible
      }
      destroyOnClose={true}
      placement="right"
      width={700}
      bodyStyle={{ backgroundColor: "#F4F7F9" }}
    >
      {state.apiCallStatus["bot-knowledgebase-info-panel"] &&
      state.apiCallStatus["bot-knowledgebase-info-panel"].loading ? (
        <FullPageLoader
          component="bot-knowledgebase-info-panel"
          loadingMessage={translation.loadingMessage}
        />
      ) : (
        <Row gutter={[5, 15]}>
          <Col span={15}>
            <Input
              disabled={
                state.botKnowledgebase?.singleBotKnowledgebaseFile?.data
                  ?.length === 0
              }
              value={
                state.filters.searchText[
                  "bot-knowledgebase-section-search-text-box"
                ] &&
                state.filters.searchText[
                  "bot-knowledgebase-section-search-text-box"
                ]
              }
              prefix={<SearchOutlined />}
              placeholder={translation.header.searchSectionInputBox.placeholder}
              style={{ width: 350 }}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onChange={(e) => {
                const currentValue = e.target.value;
                dispatchReducer({
                  type: "RD_SET_SEARCH_TEXT",
                  payload: {
                    component: "bot-knowledgebase-section-search-text-box",
                    searchText: currentValue,
                  },
                });
              }}
            />
          </Col>

          <Col span={9}>
            <Tooltip
              title={
                !state.currentUser.permission.BOT_KNOWLEDGEBASE_SECTION_CREATE
                  ? translation.noAccess
                  : ""
              }
              placement="topRight"
            >
              <Button
                type="primary"
                style={{
                  float: "right",
                }}
                disabled={
                  !state.currentUser.permission.BOT_KNOWLEDGEBASE_SECTION_CREATE
                }
                onClick={() => {
                  if (
                    state.filters.actionRowKey["bot-knowledgebase-edit-section"]
                  ) {
                    dispatchReducer({
                      type: "RD_CLEAR_ACTION_ROW_KEY",
                      payload: {
                        component: "bot-knowledgebase-edit-section",
                      },
                    });
                  }

                  dispatchReducer({
                    type: "SET_FORM_MODE",
                    payload: {
                      component: "bot-knowledgebase-section-form",
                      mode: "add",
                    },
                  });

                  dispatchReducer({
                    type: "ADD_BOT_KNOWLEDGEBASE_SECTION",
                    payload: {
                      botKnowledgebaseSection: {
                        section_id: "",
                        section_title: "",
                        section_content: "",
                      },
                    },
                  });

                  form.setFieldsValue({
                    section: [
                      {
                        section_title: "",
                        section_content: "",
                      },
                    ],
                  });
                }}
              >
                <PlusOutlined />{" "}
                {translation.header.addSectionButton.buttonText}
              </Button>
            </Tooltip>
          </Col>

          <Col span={24}>
            <Form
              form={form}
              onFinish={handleFormSubmit}
              onFinishFailed={() => {
                openNotificationWithIcon(
                  "error",
                  "Form Submission Failed! Please check the form for errors"
                );
              }}
            >
              <Row gutter={[5, 10]} justify="center">
                {filteredSections?.length !== 0 ? (
                  filteredSections?.map((section, index) => {
                    return (
                      <Col span={24} key={index}>
                        {/*Add_Edit kb section */}
                        {state.filters.actionRowKey[
                          "bot-knowledgebase-edit-section"
                        ] === section.section_id ||
                        section.section_id === "" ? (
                          <Row gutter={[5, 10]}>
                            <Col span={24}>
                              <Form.List name="section">
                                {(fields) => (
                                  <Space
                                    direction="vertical"
                                    size={12}
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }) => {
                                        return (
                                          <Row gutter={[5, 5]} key={key}>
                                            <Col span={24}>
                                              <Card
                                                size="small"
                                                className="card-rounded-corner"
                                                style={{
                                                  minHeight:
                                                    state.apiCallStatus[
                                                      "bot-knowledgebase-section-form"
                                                    ] &&
                                                    state.apiCallStatus[
                                                      "bot-knowledgebase-section-form"
                                                    ]?.loading
                                                      ? 80
                                                      : 0,
                                                }}
                                              >
                                                {state.apiCallStatus[
                                                  "bot-knowledgebase-section-form"
                                                ] &&
                                                state.apiCallStatus[
                                                  "bot-knowledgebase-section-form"
                                                ].loading ? (
                                                  <React.Fragment
                                                    style={{
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    <TabLoader
                                                      component="bot-knowledgebase-section-form"
                                                      loadingMessage={
                                                        translation.form
                                                          .loadingMessage
                                                      }
                                                      errorMessage={
                                                        translation.form
                                                          .errorMessage
                                                      }
                                                    />
                                                  </React.Fragment>
                                                ) : (
                                                  <Row
                                                    gutter={[5, 10]}
                                                    key={key}
                                                    style={{
                                                      marginBottom:
                                                        fields.length - 1 ===
                                                        parseInt(name)
                                                          ? 0
                                                          : 10,
                                                    }}
                                                  >
                                                    <Col span={19}>
                                                      <Form.Item
                                                        {...restField}
                                                        name={[
                                                          name,
                                                          "section_title",
                                                        ]}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              translation.form
                                                                .sectionTitleFormItem
                                                                .required,
                                                          },
                                                          {
                                                            max: 200,
                                                            message:
                                                              translation.form
                                                                .sectionTitleFormItem
                                                                .maximum,
                                                          },
                                                        ]}
                                                        className="kb-form-item"
                                                      >
                                                        <Input
                                                          placeholder={
                                                            translation.form
                                                              .sectionTitleFormItem
                                                              .placeholder
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={5}>
                                                      <Space
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "right",
                                                        }}
                                                      >
                                                        <Form.Item>
                                                          <Button
                                                            onClick={() => {
                                                              dispatchReducer({
                                                                type: "RD_CLEAR_ACTION_ROW_KEY",
                                                                payload: {
                                                                  component:
                                                                    "bot-knowledgebase-edit-section",
                                                                },
                                                              });

                                                              state.form[
                                                                "bot-knowledgebase-section-form"
                                                              ]?.mode ===
                                                                "add" &&
                                                                dispatchReducer(
                                                                  {
                                                                    type: "CLEAR_BOT_KNOWLEDGEBASE_DRAFT_SECTION",
                                                                  }
                                                                );
                                                            }}
                                                            size="small"
                                                          >
                                                            {
                                                              translation.form
                                                                .cancelButton
                                                                .buttonText
                                                            }
                                                          </Button>
                                                        </Form.Item>

                                                        <Form.Item>
                                                          <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            size="small"
                                                          >
                                                            {
                                                              translation.form
                                                                .saveButton
                                                                .buttonText
                                                            }
                                                          </Button>
                                                        </Form.Item>
                                                      </Space>
                                                    </Col>

                                                    <Col span={24}>
                                                      <Form.Item
                                                        {...restField}
                                                        name={[
                                                          name,
                                                          "section_content",
                                                        ]}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              translation.form
                                                                .sectionContentFormItem
                                                                .required,
                                                          },
                                                          {
                                                            max: 4000,
                                                            message:
                                                              translation.form
                                                                .sectionContentFormItem
                                                                .maximum,
                                                          },
                                                        ]}
                                                        style={{
                                                          marginBottom:
                                                            fields.length -
                                                              1 ===
                                                            parseInt(name)
                                                              ? 10
                                                              : 1,
                                                        }}
                                                      >
                                                        <TextArea
                                                          placeholder={
                                                            translation.form
                                                              .sectionContentFormItem
                                                              .placeholder
                                                          }
                                                          autoSize={{
                                                            minRows: 3,
                                                          }}
                                                          showCount
                                                          maxLength={4000}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                )}
                                              </Card>
                                            </Col>

                                            <Col
                                              span={1}
                                              style={{
                                                textAlign: "center",
                                              }}
                                            ></Col>
                                          </Row>
                                        );
                                      }
                                    )}
                                  </Space>
                                )}
                              </Form.List>
                            </Col>
                          </Row>
                        ) : (
                          <Card
                            title={
                              <Row>
                                <Col span={22}>
                                  <Text strong ellipsis>
                                    {section.section_title}
                                  </Text>
                                </Col>

                                <Col span={2}>
                                  <Space
                                    size="middle"
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    {state.currentUser.permission
                                      .BOT_KNOWLEDGEBASE_SECTION_EDIT ? (
                                      <EditOutlined
                                        style={{
                                          color: "#F05742",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          dispatchReducer({
                                            type: "SET_FORM_MODE",
                                            payload: {
                                              component:
                                                "bot-knowledgebase-section-form",
                                              mode: "edit",
                                            },
                                          });

                                          dispatchReducer({
                                            type: "RD_SET_ACTION_ROW_KEY",
                                            payload: {
                                              component:
                                                "bot-knowledgebase-edit-section",
                                              actionRowKey: section.section_id,
                                            },
                                          });

                                          form.setFieldsValue({
                                            section: [
                                              {
                                                section_title:
                                                  section.section_title,
                                                section_content:
                                                  section.section_content,
                                              },
                                            ],
                                          });
                                        }}
                                      />
                                    ) : (
                                      <Tooltip
                                        title={translation.noAccess}
                                        placement="topRight"
                                      >
                                        <EditOutlined
                                          style={{
                                            color: "#C0C0C0",
                                            cursor: "not-allowed",
                                          }}
                                        />
                                      </Tooltip>
                                    )}

                                    {state.currentUser.permission
                                      .BOT_KNOWLEDGEBASE_SECTION_DELETE ? (
                                      state.apiCallStatus?.[
                                        "bot-knowledgebase-" +
                                          section.section_id +
                                          "-delete"
                                      ]?.loading ? (
                                        <ComponentLoader
                                          component={
                                            "bot-knowledgebase-" +
                                            section.section_id +
                                            "-delete"
                                          }
                                          loadingMessage=""
                                          errorMessage=""
                                        />
                                      ) : (
                                        <Popconfirm
                                          title={
                                            translation.form.deleteButton.title
                                          }
                                          placement="topRight"
                                          icon={
                                            <AiOutlineExclamationCircle
                                              size={18}
                                              style={{
                                                color: "#faad14",
                                                marginTop: "1px",
                                              }}
                                            />
                                          }
                                          onConfirm={() => {
                                            dispatchReducer({
                                              type: "RD_SET_ACTION_ROW_KEY",
                                              payload: {
                                                component:
                                                  "bot-knowledgebase-delete-section",
                                                actionRowKey:
                                                  section.section_id,
                                              },
                                            });

                                            dispatchMiddleware({
                                              type: "DELETE_BOT_KNOWLEDGEBASE_SECTION",
                                              payload: {
                                                botInAction,
                                                section_id: section.section_id,
                                                component:
                                                  "bot-knowledgebase-" +
                                                  section.section_id +
                                                  "-delete",
                                              },
                                            });
                                          }}
                                          cancelButtonProps={{
                                            style: {
                                              color: "white",
                                              backgroundColor: "#f05742",
                                            },
                                          }}
                                          okType="default"
                                          okText={
                                            translation.form.deleteButton.okText
                                          }
                                          cancelText={
                                            translation.form.deleteButton
                                              .cancelText
                                          }
                                        >
                                          <DeleteOutlined
                                            style={{
                                              color: "#F05742",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Popconfirm>
                                      )
                                    ) : (
                                      <Tooltip
                                        title={translation.noAccess}
                                        placement="topRight"
                                      >
                                        <DeleteOutlined
                                          style={{
                                            color: "#C0C0C0",
                                            cursor: "not-allowed",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Space>
                                </Col>
                              </Row>
                            }
                            size="small"
                            className="card-rounded-corner"
                          >
                            <Text>{formatText(section.section_content)}</Text>
                          </Card>
                        )}
                      </Col>
                    );
                  })
                ) : (
                  <Col span={24}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={translation.form.empty.description}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

export default BotKnowledgebaseInfoPanel;
