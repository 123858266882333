import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useConversationFlowMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************** Conversation FLow **********////
      ////*******************************************////

      //Load Conversation Flow
      case "MWD_LOAD_CONVERSATION_FLOW":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        callApi({
          urls: [apiEndPoints.CONVERSATION_FLOW],
          options: {
            method: "GET",
          },
          onSuccess: ([flow]) => {
            dispatchReducer({
              type: "RD_SET_CONVERSATION_FLOW",
              payload: {
                flow: flow.data,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Conversation Flow Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Add / Edit Conversation Flow
      case "MWD_ADD_EDIT_CONVERSATION_FLOW":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            ...(state.form["conversation-flow-form"].mode === "add"
              ? [apiEndPoints.CONVERSATION_FLOW]
              : [
                  apiEndPoints.CONVERSATION_FLOW.concat(
                    "?_id=",
                    state.filters.actionRowKey["conversation-flow-table"]
                  ),
                ]),
          ],
          options: {
            method:
              state.form["conversation-flow-form"].mode === "add"
                ? "POST"
                : "PUT",
            body: action.payload.flow,
          },
          onSuccess: ([response]) => {
            if (state.form["conversation-flow-form"].mode === "add") {
              dispatchReducer({
                type: "RD_ADD_CONVERSATION_FLOW",
                payload: {
                  flow: response,
                },
              });
              openNotificationWithIcon(
                "success",
                "Conversation flow added successfully"
              );
            } else if (state.form["conversation-flow-form"].mode === "edit") {
              dispatchReducer({
                type: "RD_UPDATE_CONVERSATION_FLOW",
                payload: {
                  flow: response,
                },
              });
              openNotificationWithIcon(
                "success",
                "Conversation flow modified successfully"
              );
            }
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "conversation-flow-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "conversation-flow-form",
                mode: "",
                title: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "conversation-flow-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add/Edit Conversation Flow Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_DELETE_CONVERSATION_FLOW":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.CONVERSATION_FLOW.concat(
              "?_ids=",
              action.payload.actionRowKey
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "RD_DELETE_CONVERSATION_FLOW",
              payload: {
                actionRowKey: action.payload.actionRowKey,
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "conversation-flow-table" },
            });
            openNotificationWithIcon(
              "success",
              "Conversation flow deleted successfully"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete Conversation Flow Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
