const conversationFlowReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_CONVERSATION_FLOW":
      return {
        ...state,
        conversationFlow: {
          ...state.conversationFlow,
          ...{
            flows: action.payload.flow,
          },
        },
      };

    case "RD_ADD_CONVERSATION_FLOW":
      return {
        ...state,
        conversationFlow: {
          ...state.conversationFlow,
          ...{ flows: [...state.conversationFlow.flows, action.payload.flow] },
        },
      };

    case "RD_UPDATE_CONVERSATION_FLOW":
      return {
        ...state,
        conversationFlow: {
          ...state.conversationFlow,
          ...{
            flows: state.conversationFlow.flows.map((flow) =>
              flow._id === action.payload.flow._id
                ? Object.assign({}, flow, action.payload.flow)
                : flow
            ),
          },
        },
      };

    case "RD_DELETE_CONVERSATION_FLOW":
      return {
        ...state,
        conversationFlow: {
          ...state.conversationFlow,
          ...{
            flows: state.conversationFlow.flows.filter(
              (flow) => action.payload.actionRowKey !== flow._id
            ),
          },
        },
      };

    default:
      break;
  }
};

export default conversationFlowReducer;
