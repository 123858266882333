import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useBroadcastSettingsMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////************* Broadcast settings *************////
      ////*******************************************////

      //Load broadcast settings
      case "MWD_LOAD_BROADCAST_SETTINGS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.BROADCAST_SETTINGS.concat(
              "?botId=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([broadcastSettings]) => {
            dispatchReducer({
              type: "SET_BROADCAST_SETTINGS",
              payload: {
                broadcastSettings: {
                  ...broadcastSettings?.broadcast,
                  _id: broadcastSettings._id,
                },
              },
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Broadcast Settings Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Update broadcast settings
      case "UPDATE_BROADCAST_SETTINGS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.BROADCAST_SETTINGS.concat(
              "?_id=",
              state.broadcastSettings?._id,
              "&botId=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            body: action.payload.broadcastSettings,
          },
          onSuccess: ([broadcastSettings]) => {
            dispatchReducer({
              type: "SET_BROADCAST_SETTINGS",
              payload: {
                broadcastSettings: {
                  ...broadcastSettings?.broadcast,
                  _id: broadcastSettings?._id,
                },
              },
            });

            openNotificationWithIcon(
              "success",
              "Broadcast settings have been successfully updated"
            );
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Broadcast settings Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
