const automatedrepliesReducer = (state, action) => {
  switch (action.type) {
    case "RD_SET_AUTOMATED_REPLY":
      return {
        ...state,
        automatedReplies: {
          ...state.automatedReplies,
          ...{
            broadcastAutomatedReplies: action.payload.automatedValue,
          },
        },
      };

    case "RD_ADD_AUTOMATED_REPLY":
      return {
        ...state,
        automatedReplies: {
          ...state.automatedReplies,
          ...{
            broadcastAutomatedReplies: [
              ...state.automatedReplies.broadcastAutomatedReplies,
              action.payload.automatedValue,
            ],
          },
        },
      };

    case "RD_EDIT_AUTOMATED_REPLY":
      return {
        ...state,
        automatedReplies: {
          ...state.automatedReplies,
          ...{
            broadcastAutomatedReplies:
              state.automatedReplies.broadcastAutomatedReplies.map(
                (automatedReply) =>
                  automatedReply._id === action.payload.automatedValue._id
                    ? Object.assign(
                        {},
                        automatedReply,
                        action.payload.automatedValue
                      )
                    : automatedReply
              ),
          },
        },
      };

    case "RD_DELETE_AUTOMATED_REPLY":
      return {
        ...state,
        automatedReplies: {
          ...state.automatedReplies,
          ...{
            broadcastAutomatedReplies:
              state.automatedReplies.broadcastAutomatedReplies.filter(
                (automatedReply) =>
                  action.payload.actionRowKey !== automatedReply._id
              ),
          },
        },
      };

    default:
      return state;
  }
};

export default automatedrepliesReducer;
