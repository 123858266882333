import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";
import { generateRandomBackgroundColor } from "../utils/utility";

export const useAccountUsersMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////**************** ACCOUNT USER *************////
      ////*******************************************////
      //Load Account Users Details
      case "MWD_LOAD_ACCOUNT_USERS_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.ACCOUNT_USERS],
          options: {
            method: "GET",
          },
          onSuccess: ([users]) => {
            // set color for each user
            users.data.forEach((user) => {
              user.colorCode = generateRandomBackgroundColor();
            });
            dispatchReducer({ type: "SET_ACCOUNT_USERS", users: users.data });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load Users Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Add / Edit Account User
      case "MWD_ADD_EDIT_ACCOUNT_USER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            ...(state.form["account-user-form"].mode === "add"
              ? [apiEndPoints.ACCOUNT_USERS]
              : [
                  apiEndPoints.ACCOUNT_USERS.concat(
                    "?userID=",
                    state.filters.actionRowKey["account-user-table"]
                  ),
                ]),
          ],
          options: {
            method:
              state.form["account-user-form"].mode === "add" ? "POST" : "PUT",
            body: action.payload.user,
          },
          onSuccess: ([response]) => {
            if (state.form["account-user-form"].mode === "add") {
              dispatchReducer({
                type: "ADD_ACCOUNT_USER",
                user: response,
              });
              openNotificationWithIcon("success", "User added successfully");
            } else if (state.form["account-user-form"].mode === "edit") {
              dispatchReducer({
                type: "EDIT_ACCOUNT_USER",
                user: response,
              });
              openNotificationWithIcon("success", "User modified successfully");
            }
            dispatchReducer({
              type: "CLOSE_FORM_PANEL",
              payload: {
                component: "account-user-form-panel",
              },
            });
            dispatchReducer({
              type: "SET_FORM_MODE",
              payload: {
                component: "account-user-form",
                mode: "",
                title: "",
              },
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "account-user-table" },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add/Edit User Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete Account User
      case "MWD_DELETE_SINGLE_ACCOUNT_USER":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.ACCOUNT_USERS.concat(
              "?userid=",
              action.payload.actionRowKey
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "DELETE_SINGLE_ACCOUNT_USER",
              actionRowKey: action.payload.actionRowKey,
            });
            dispatchReducer({
              type: "RD_CLEAR_ACTION_ROW_KEY",
              payload: { component: "account-user-table" },
            });
            openNotificationWithIcon("success", "User deleted successfully");
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete User Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
