import React, { useEffect } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Input,
  Space,
  Typography,
  Divider,
  Popconfirm,
} from "antd";
import { mode } from "../../../constants/env";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import openNotificationWithIcon from "../../../utils/notification";
import { Translation } from "../../../translation/translation";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import { appInsights } from "../../../AppInsights";

const { Text } = Typography;
const { TextArea } = Input;

const BotKnowledgeBaseSubmit = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [form] = Form.useForm();

  const translation =
    Translation().botKnowledgeArea.botKnowledgebase.formPanel.review;

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );
  const uploadedFile = state.botKnowledgebase?.uploadedFile;

  const handleFormSubmit = (values) => {
    const formValues = {
      kb_name: uploadedFile?.kb_name,
      kb_version: uploadedFile?.kb_version,
      data_source: uploadedFile?.data_source,
      user_id: state.currentUser.user.userID,
      data: values.chunkedFile
        .filter(({ title, content }) => title !== "" && content !== "")
        .map(({ title, content }) => {
          return {
            section_title: title,
            section_content: content,
          };
        }),
      tags: uploadedFile?.tags?.map(({ key, value }) => {
        return {
          key,
          value,
          type: "string",
        };
      }),
      file_name: uploadedFile?.file_name,
    };

    // For storing logs in AppInsights
    const appinsightsLogs = {
      properties: {
        environment: mode,
        clientName: state.currentAccount.account?.general?.company,
        clientId: state.currentAccount.account.client_id,
      },
    };

    if (uploadedFile?.knowledgebaseSource === "template") {
      appinsightsLogs.name = `Create Knowledgebase - Template`;
      appinsightsLogs.properties = {
        ...appinsightsLogs.properties,
        templateType: uploadedFile.selectedTemplate?.title,
        sectionTitle: values.chunkedFile
          .filter(({ title, content }) => title !== "" && content !== "")
          .map(({ title }) => title),
      };
    } else {
      appinsightsLogs.name = `Create Knowledgebase - Document`;
      appinsightsLogs.properties = {
        ...appinsightsLogs.properties,
        fileName: uploadedFile.file_name,
      };
    }

    dispatchMiddleware({
      type: "REVIEW-SUBMIT_KNOWLEDGEBASE_FILE",
      payload: {
        botInAction,
        formValues,
        appinsightsLogs,
        component: "knowledgebase-review-form",
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      chunkedFile: state.botKnowledgebase?.uploadedFile?.data?.map((item) => {
        return {
          title: item.section_title,
          content: item.section_content,
        };
      }),
    });
  }, []);

  return state.apiCallStatus["knowledgebase-review-form"] &&
    state.apiCallStatus["knowledgebase-review-form"].loading ? (
    <FullPageLoader
      component="knowledgebase-review-form"
      loadingMessage={translation.loadingMessage}
    />
  ) : (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      onFinishFailed={() => {
        openNotificationWithIcon("error", translation.error);
      }}
    >
      <Row gutter={[5, 8]}>
        <Col span={24}>
          <Text>
            {uploadedFile?.knowledgebaseSource === "template" ? (
              <Space direction="vertical">
                <Text strong>{uploadedFile.selectedTemplate?.title}</Text>
                <Text>
                  Here you can see the pre-defined knowledge content for the
                  {" " + uploadedFile.selectedTemplate?.title}. You can
                  customize it as per your need. You can also add more sections
                  to it.
                </Text>
              </Space>
            ) : (
              translation.label
            )}
          </Text>
        </Col>

        <Col span={24}>
          <Form.List name="chunkedFile">
            {(fields, { add, remove }) => (
              <Space
                direction="vertical"
                size={12}
                style={{
                  width: "100%",
                }}
              >
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Row gutter={[5, 5]} key={key}>
                      <Col span={23}>
                        <Card size="small" className="card-rounded-corner">
                          <Row
                            gutter={[8, 12]}
                            key={key}
                            style={{
                              marginBottom:
                                fields.length - 1 === parseInt(name) ? 0 : 10,
                            }}
                          >
                            <Col span={20}>
                              <Form.Item
                                {...restField}
                                name={[name, "title"]}
                                rules={[
                                  {
                                    max: 200,
                                    message:
                                      translation.chunkedFileFormList
                                        .titleFormItem.maximum,
                                  },
                                ]}
                                className="kb-form-item"
                              >
                                <Input
                                  placeholder={
                                    translation.chunkedFileFormList
                                      .titleFormItem.placeholder
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "content"]}
                                rules={[
                                  {
                                    max: 4000,
                                    message:
                                      translation.chunkedFileFormList
                                        .contentFormItem.maximum,
                                  },
                                ]}
                                style={{
                                  marginBottom:
                                    fields.length - 1 === parseInt(name)
                                      ? 10
                                      : 1,
                                }}
                              >
                                <TextArea
                                  placeholder={
                                    translation.chunkedFileFormList
                                      .contentFormItem.placeholder
                                  }
                                  autoSize={{ minRows: 2, maxRows: 6 }}
                                  showCount
                                  maxLength={4000}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col
                        span={1}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Space
                          direction="vertical"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <Popconfirm
                            title={translation.chunkedFileFormList.delete.title}
                            placement="topRight"
                            icon={
                              <AiOutlineExclamationCircle
                                size={18}
                                style={{
                                  color: "#faad14",
                                  marginTop: "1px",
                                }}
                              />
                            }
                            onConfirm={() => {
                              remove(name);
                            }}
                            cancelButtonProps={{
                              style: {
                                color: "white",
                                backgroundColor: "#f05742",
                              },
                            }}
                            okType="default"
                            okText={translation.chunkedFileFormList.delete.yes}
                            cancelText={
                              translation.chunkedFileFormList.delete.no
                            }
                          >
                            <DeleteOutlined
                              style={{
                                color: "#F05742",
                              }}
                            />
                          </Popconfirm>

                          {fields.length - 1 === parseInt(name) && (
                            <PlusOutlined
                              style={{
                                color: "#F05742",
                              }}
                              onClick={() => {
                                add();
                              }}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  );
                })}
              </Space>
            )}
          </Form.List>
        </Col>

        <Col span={24}>
          <Divider />
          <Form.Item>
            <Space
              style={{
                textAlign: "left",
              }}
            >
              <Button
                onClick={() => {
                  dispatchReducer({
                    type: "SET_CURRENT_STEP",
                    payload: 1,
                  });
                }}
              >
                {translation.previous.buttonText}
              </Button>

              <Button type="primary" htmlType="submit">
                {translation.submit.buttonText}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BotKnowledgeBaseSubmit;
