import { useConnectApi } from "../connect/connectApi";
import { apiEndPoints } from "../constants/enums";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../utils/notification";
import { URIs } from "../constants/env";
import moment from "moment";
import { channels } from "../constants/enums";
import { appInsights } from "../AppInsights";
import { mode } from "../constants/env";

export const useAccountApiMiddleware = () => {
  const { callApi } = useConnectApi();

  return async (state, action, dispatchReducer) => {
    switch (action.type) {
      ////*******************************************////
      ////**************** ACCOUNT API *************////
      ////*******************************************////

      //Get API subscription key
      case "MWD_ACCOUNT_API_GET_API_KEY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        await callApi({
          urls: [
            apiEndPoints.GET_API_KEY.concat(
              "&clientid=",
              state.currentAccount.account.client_id
            ),
          ],
          options: {
            method: "GET",
            header: { userID: state.currentUser.user.userID },
          },
          onSuccess: ([apiKey]) => {
            dispatchReducer({
              type: "RD_ACCOUNT_API_SET_API_KEY",
              apiKey: apiKey.primaryKey,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load API Key Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Generate API subscription
      case "MWD_ACCOUNT_API_GENERATE_API_KEY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        await callApi({
          urls: [apiEndPoints.GENERATE_API_KEY],
          options: {
            method: "POST",
            header: { userID: state.currentUser.user.userID },
            body: {
              clientid: action.payload.client_id,
            },
          },
          onSuccess: ([apiKey]) => {
            dispatchReducer({
              type: "RD_ACCOUNT_API_GENERATE_API_KEY",
              apiKey: apiKey.properties.primaryKey,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "API Key generated successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Generate API Key Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Re-generate API subscription
      case "MWD_ACCOUNT_API_REGENERATE_API_KEY":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        await callApi({
          urls: [
            apiEndPoints.REGENERATE_API_KEY.concat(
              "&clientid=",
              state.currentAccount.account.client_id
            ),
          ],
          options: {
            method: "PUT",
            header: { userID: state.currentUser.user.userID },
          },
          onSuccess: ([apiKey]) => {
            dispatchReducer({
              type: "RD_ACCOUNT_API_REGENERATE_API_KEY",
              apiKey,
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "API Key re-generated successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Re-generate API Key Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Integrate WhatsApp using 360dialog
      case "ONBOARD_WHATSAPP_USING_360DIALOG":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        await callApi({
          urls: [
            apiEndPoints.ONBOARD_WHATSAPP_360DIALOG.concat(
              "?bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "POST",
            body: {
              channels: action.payload.clientDetails.channels,
              clientid: action.payload.clientDetails.client,
            },
          },
          onSuccess: ([account]) => {
            dispatchReducer({
              type: "SET_CURRENT_ACCOUNT",
              payload: { account },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            openNotificationWithIcon(
              "success",
              "API key generated successfully"
            );

            appInsights.trackEvent({
              name: "WhatsApp Onboarded using 360dialog",
              properties: {
                environment: mode,
                clientId: state.currentAccount.account.client_id,
              },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Generate WABA key Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Integrate WhatsApp using meta
      case "ONBOARD_WHATSAPP_USING_META":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        await callApi({
          urls: [
            apiEndPoints.WHATSAPP_META.concat(
              "/onboarding?bot_id=",
              state.application.botInAction,
              "&code=",
              action.payload.code,
              "&phone_number=",
              action.payload.currentBot.bot_channel_info.WHATSAPP
                ?.waba_phone_number
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([account]) => {
            dispatchReducer({
              type: "SET_CURRENT_ACCOUNT",
              payload: { account },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "configure-whatsapp-info-panel",
              },
            });
            openNotificationWithIcon(
              "success",
              "WhatsApp onboarded successfully"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Onboard Meta WhatsApp Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      // Phone number status check
      case "REFRESH_WHATSAPP_PHONE_NUMBER_STATUS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });

        await callApi({
          urls: [
            apiEndPoints.WHATSAPP_META.concat(
              "/phoneNumber/status?bot_id=",
              state.application.botInAction,
              "&waba_id=",
              action.payload.wabaId,
              "&waba_phone_number_id=",
              action.payload.wabaPhoneNumberId
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([account]) => {
            dispatchReducer({
              type: "SET_CURRENT_ACCOUNT",
              payload: { account },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Refresh WhatsApp Phone Number Status Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      case "MWD_UPDATE_BOT_DETAILS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.UPDATE_BOT_DETAILS.concat(
              "?bot_id=",
              state.application.botInAction
            ),
          ],
          options: {
            method: "PUT",
            body: action.payload.botSetting,
          },
          onSuccess: ([response]) => {
            dispatchReducer({
              type: "RD_UPDATE_BOT_DETAILS",
              payload: {
                botSetting: response,
              },
            });
            openNotificationWithIcon(
              "success",
              "Bot details updated successfully"
            );

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Update Bot Details Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      case "MWD_SEND_REQUEST":
        await callApi({
          urls: [URIs.CHANNEL_REQUEST],
          options: {
            method: "POST",
            body: {
              properties: {
                clientid: state.currentAccount.account.client_id,
                channel_requested: channels[action.payload.channel].displayName,
                requested_by: state.currentUser.user.email,
                requested_datetime: moment().format(
                  state.currentUser.user.dateFormat
                ),
              },
            },

            isTokenRequired: false,
          },
          onSuccess: () => {
            openNotificationWithIcon(
              "success",
              "Thank you for your interest. We will get back to you as soon as possible"
            );
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Send Channel Request Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //WhatsApp usage details
      case "MWD_LOAD_WHATSAPP_USAGE_DATA":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });

        await callApi({
          urls: [
            apiEndPoints.WHATSAPP_USAGE.concat(
              `?waba_client_id=${action.payload.clientId || ""}&from_month=${
                action.payload.currentMonth
              }&bot_id=${action.payload.botId}&channel_id=${
                action.payload.channelId || ""
              }`
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([whatsappUsage]) => {
            dispatchReducer({
              type: "RD_SET_WHATSAPP_USAGE_DATA",
              payload: {
                whatsappUsage,
              },
            });

            dispatchReducer({
              type: "SHOW_CURRENT_BALANCE_DETAILS",
            });

            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException(JSON.stringify(error), {
              title: "Load WhatsApp Usage Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Add / Edit App Registration
      case "ADD_EDIT_APP_REGISTRATION":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: {
            component: action.payload.component,
          },
        });
        await callApi({
          urls: [
            ...(state.form?.["app-registration-form"]?.mode !== "edit"
              ? [apiEndPoints.APP_REGISTRATION]
              : [
                  apiEndPoints.APP_REGISTRATION.concat(
                    "?appId=",
                    action.payload.id
                  ),
                ]),
          ],
          options: {
            method:
              state.form?.["app-registration-form"]?.mode !== "edit"
                ? "POST"
                : "PUT",
            body: action.payload.appRegistrationValues,
          },
          onSuccess: ([appRegistrationValues]) => {
            if (state.form?.["app-registration-form"]?.mode !== "edit") {
              dispatchReducer({
                type: "SET_APP_REGISTRATION_DETAILS",
                payload: {
                  appRegistrationValues,
                },
              });
              openNotificationWithIcon(
                "success",
                "App Registered successfully"
              );

              appInsights.trackEvent({
                name: "App Registered",
                properties: {
                  environment: mode,
                  clientId: state.currentAccount.account.client_id,
                },
              });
            } else {
              dispatchReducer({
                type: "EDIT_APP_REGISTRATION_DETAILS",
                payload: {
                  appRegistrationValues,
                },
              });

              dispatchReducer({
                type: "SET_FORM_MODE",
                payload: {
                  component: "app-registration-form",
                  mode: "",
                  title: "",
                },
              });

              dispatchReducer({
                type: "CLOSE_FORM_PANEL",
                payload: {
                  component: "app-registration-form",
                },
              });
              openNotificationWithIcon("success", "App Updated successfully");
            }
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Add/Edit App Registration Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
          },
        });
        break;

      //Delete App
      case "DELETE_APP":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.APP_REGISTRATION.concat(
              "?appId=",
              action.payload.appRegistration.id
            ),
          ],
          options: {
            method: "DELETE",
          },
          onSuccess: () => {
            dispatchReducer({
              type: "DELETE_APP",
            });
            openNotificationWithIcon("success", "App deleted successfully");
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Delete App Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Regenerate App Secret
      case "REGENERATE_APP_SECRET":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [
            apiEndPoints.APP_REGISTRATION.concat(
              "/clientSecret/regenerate?appId=",
              action.payload.appRegistration.id,
              "&clientSecretName=",
              action.payload.appRegistration.AD_B2C_CLIENT_SECRET_NAME
            ),
          ],
          options: {
            method: "GET",
          },
          onSuccess: ([secretDetails]) => {
            dispatchReducer({
              type: "REGENERATE_APP_SECRET",
              payload: {
                secretDetails,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Regenerate App Secret Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      //Get App registration details
      case "LOAD_APP_REGISTRATION_DETAILS":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.APP_REGISTRATION],
          options: {
            method: "GET",
          },
          onSuccess: ([appDetails]) => {
            dispatchReducer({
              type: "SET_APP_REGISTRATION_DETAILS",
              payload: {
                appRegistrationValues: appDetails,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Load App Registration Details Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;

      case "LOAD_APP_REGISTRATION_ACCESS_TOKEN":
        dispatchReducer({
          type: "START_API_TRANSACTION",
          payload: { component: action.payload.component },
        });
        await callApi({
          urls: [apiEndPoints.APP_REGISTRATION.concat("/oauth2/token")],
          options: {
            method: "POST",
            body: action.payload.appRegistration,
          },
          onSuccess: ([accessToken]) => {
            dispatchReducer({
              type: "SET_APP_REGISTRATION_ACCESS_TOKEN",
              payload: {
                accessTokenDetails: accessToken,
              },
            });
            dispatchReducer({
              type: "SUCCESS_API_TRANSACTION",
              payload: { component: action.payload.component },
            });
          },
          onError: (error) => {
            appInsights.trackException({
              exception: error,
              properties: {
                title: "Access Token Create Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
            dispatchReducer({
              type: "ERROR_API_TRANSACTION",
              payload: { component: action.payload.component, error },
            });
            openErrorNotificationWithDeveloperMessage(error);
          },
        });
        break;
    }
  };
};
