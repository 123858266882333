export const englishText = {
  contactManager: {
    header: {
      noAccess: "You do not have required permission",
      addContact: {
        infoText: "Add Contact",
        buttonText: "Add",
      },
      importContact: {
        infoText: "Import Contact",
        buttonText: "Import",
      },
      deleteContact: {
        infoText: "Delete Contact",
        buttonText: "Delete",
      },
      searchContact: {
        placeHolder: "Search by name, mobile number or tags",
      },
    },
    table: {
      editContact: {
        infoText: "Edit Contact",
      },
      deleteContact: {
        infoText: "Delete Contact",
      },
    },
  },
  clientResponses: {
    header: {
      noAccess: "You do not have required permission",
      responsesSettings: {
        infoText: "View response settings",
      },
      createBroadcast: {
        infoText: "Create a new broadcast",
        warningText: "Select at least one WhatsApp contacts",
        // Icon used
        buttonText: "",
      },
    },
    infoPanel: {
      noAccess: "You do not have required permission",
      responseTimeline: {
        showInfoText: "Show response timeline",
        hideInfoText: "Hide response timeline",
      },
    },
    infoPanelV2: {
      noAccess: "You do not have required permission",
      deliveryServices: {
        notConfigured: "No delivery service configured",
        lalamove: {
          bookDelivery: {
            infoText: "Book Delivery",
            buttonText: "Book Delivery",
          },
          checkQuotationDetails: {
            infoText: "Check Quotation Details",
            buttonText: "Check Quotation Details",
          },
          checkOrderStatus: {
            infoText: "Check Order Status",
            buttonText: "Check Order Status",
          },
          createNewQuotation: {
            infoText: "Create New Quotation",
            buttonText: "Create New Quotation",
            description:
              "Sorry your order has expired. Please get a new quotation.",
          },

          deliveryDetailsForm: {
            title: "Delivery Details",
            loadingMessage: "Loading... Please wait",
            pickupAddressFormItem: {
              label: "Pickup Address",
              placeholder: "Select the Pickup Address",
              required: "Please select the Pickup Address",
            },
            serviceTypeFormItem: {
              label: "Service Type",
              placeholder: "Select the Service Type",
              required: "Please select the Service Type",
            },
            deliveryAddressFormItem: {
              label: "Delivery Address",
              placeholder: "Enter the Delivery Address",
              required: "Please enter the Delivery Address",
            },
            pickupTime: {
              title: "Pickup Time:",
            },
            getQuotation: {
              infoText: "Get Quotation",
              buttonText: "Get Quotation",
            },
          },
          quotationDetails: {
            title: "Quotation Details",
            quotationId: {
              title: "Quotation ID:",
            },
            orderNow: {
              infoText: "Order Now",
              buttonText: "Order Now",
            },
          },
          orderDetails: {
            title: "Order Details:",
            loadingMessage: "Loading... Please wait",
            status: {
              title: "Status:",
            },
            pickupAddress: {
              title: "Pickup Address:",
            },
            deliveryAddress: {
              title: "Delivery Address:",
            },
            orderId: {
              title: "Order ID:",
            },

            scheduleAt: {
              title: "Schedule At:",
            },
            expiresAt: {
              title: "Expires At:",
            },
            serviceType: {
              title: "Service Type:",
            },
            totalPrice: {
              title: "Total Price:",
            },
            distance: {
              title: "Distance:",
            },
            orderNow: {
              infoText: "Order Now",
              buttonText: "Order Now",
            },
            checkOrderStatus: {
              infoText: "Check Order Status",
              buttonText: "Check Order Status",
            },
          },
        },
      },
    },
  },
  conversations: {
    footer: {
      noAccess: "You do not have required permission",

      attachments: {
        infoText: "Attachments",
        title: "Preview",
        image: {
          imageSizeError: "The maximum size of image should be 4 MB",
          tryOtherImage: "Try another image",
        },
        video: {
          videoSizeError: "The maximum size of video should be 4 MB",
          tryOtherVideo: "Try another video",
        },
        document: {
          documentSizeError: "The maximum size of document should be 4 MB",
          tryOtherDocument: "Try another document",
        },
      },
      conversationSummary: {
        infoText: "Conversation Summary",
      },
      notes: {
        infoText: "Notes",
        noNotesFound: "No notes here yet",
        title: "Notes",
        deleteNote: {
          infoText: "Delete Note",
          warningText: "You can only delete your own note",
        },
        editNote: {
          infoText: "Edit Note",
          warningText: "You can only edit your own note",
        },
      },
    },
  },
  broadcastManager: {
    header: {
      noAccess: "You do not have required permission",
      broadcastSettings: {
        infoText: "View broadcast settings",
      },
      broadcastPricingCalculator: {
        infoText: "Pricing Estimator",
        title: "Broadcast Pricing Estimator",
        broadcastPricingForm: {
          recipientCountryFormItem: {
            label: "Recipient Country",
            placeholder: "Ex: Singapore",
            required: "Please select a country",
          },
          messageTypeFormItem: {
            label: "Message Type",
            required: "Please select a message type",
            marketingMessage: "Marketing Message",
            utilityMessage: "Utility Message",
          },
          recipientCountFormItem: {
            label: "Recipient Count",
            required: "Please enter the recipient count",
            invalid: "Please enter valid number",
            maximum: "Recipient count should be less then 1,00,000",
            placeholder: "Ex: 1000",
          },
          calculateFormItem: {
            buttonText: "Calculate",
          },
          totalPrice: {
            label: "Total Price (US)",
          },
        },
      },
    },
  },
  unrecognizedIntents: {
    header: {
      noAccess: "You do not have required permission",
      deleteUnrecognizedIntents: {
        infoText: "Delete Unrecognized Intents",
        warningText: "Select at least one Unrecognized Intent",
      },
    },
    table: {
      noAccess: "You do not have required permission",
      editUnrecognizedIntent: {
        infoText: "Edit Unrecognized Intent",
      },
      deleteUnrecognizedIntent: {
        infoText: "Delete Unrecognized Intent",
      },
      viewConversation: {
        infoText: "View Conversation",
      },
    },
  },
  messageTemplates: {
    header: {
      noAccess: "You do not have required permission",
      createTemplate: {
        infoText: "Create Template",
        buttonText: "Create Template",
        pending: "Pending WhatsApp Configuration",
        title: "Create New Template",
      },
      refreshTemplates: {
        infoText: "Refresh Templates",
      },
    },
    formPanel: {
      form: {
        noAccess: "You do not have required permission",
        enterAllFieldsWarning: "Please enter all the required fields",
        templateNameFormItem: {
          title: "Template Name",
          label: "Provide a name for your template",
          placeholder: "Enter template name. Ex: new_year_promotion",
          required: "Please enter template name",
          minimum: "Template name should be more then 5 characters",
          maximum: "Template name should be less then 50 characters",
          pattern: "Only lowercase alphanumeric characters are allowed.",
        },
        templateCategoryFormItem: {
          title: "Template Category",
          label: "Select the category of your template",
          placeholder: "Select category Ex: Marketing",
          required: "Please select category",
          marketing: "Marketing",
          utility: "Utility",
          authentication: "Authentication",
        },
        templateChannelFormItem: {
          title: "Channel",
          label: "Which channel will you use this template for",
          placeholder: "Select channel",
          required: "Please select channel",
          whatsapp: "WhatsApp",
          instagram: "Instagram",
          facebook: "Facebook",
        },
        allowCategoryChangeFormItem: {
          title: "Allow Category Change",
          label: "Allow meta to decide the category of your template",
          tooltipText:
            "Enabling category change allows Meta to assign whatever category they determine to be appropriate. We recommend having this enabled to avoid rejected templates due to miscategorization. Please note that templates can still get rejected for other reasons unrelated to their categorization.",
          yes: "Yes",
          no: "No",
        },
        templateLanguageFormItem: {
          title: "Language",
          label: "Select the language for your template",
          placeholder: "Select language. Ex: English (en)",
          required: "Please select language.",
        },
        templateTypeFormItem: {
          title: "Template Type",
          label: "Select the type for your template",
          placeholder: "Select type",
          required: "Please select type",
          textOnly: "Standard (text only)",
          mediaInteractive: "Media & Interactive",
          carousel: "Carousel",
        },
        templateBodyFormItem: {
          title: "BODY",
          addVariable: "Add Variable",
          placeholder: "Type your template body here. Ex: Hello {{1}}!...",
          required: "Please enter template body",
          minimum: "Template body should be more then 5 characters",
          maximum: "Template body should be less then 1024 characters",
          addVariable: "Add Variable",
        },
        quickReplyButtonFormItem: {
          title: "Quick Reply",
          placeholder: "Type your button label here. Ex: Book appointment",
          required: "Please enter button label",
          minimum: "Button label should be more then 2 characters",
          maximum: "Button label should be less then 20 characters",
        },
        urlButtonLabelFormItem: {
          title: "URL",
          placeholder: "Button label here. Ex: Visit our website",
          required: "Please enter URL",
          maximum: "URL label should be less then 20 characters",
          invalid: "Please enter valid URL label",
        },
        urlButtonValueFormItem: {
          placeholder: "Button redirect URL here. Ex: https://inextlabs.ai",
          required: "Please enter URL",
          invalid: "Please enter valid URL",
          maximum: "URL should be less then 1024 characters",
        },
        phoneNumberButtonLabelFormItem: {
          title: "Phone Number",
          placeholder: "Button label here. Ex: Call us",
          required: "Please enter phone number label",
          maximum: "Phone number label should be less then 20 characters",
          invalid: "Please enter valid phone number",
        },
        phoneNumberButtonValueFormItem: {
          placeholder: "Whatsapp number here. Ex: +6597799776",
          required: "Please enter phone number",
          invalid: "Please enter valid phone number. Ex: +6597799776",
        },
        templateContent: {
          title: "Template Content",
          description: "Create your template content here",
          textOnlyFormItem: {
            title: "MESSAGE BODY",
            placeholder: "Type your template body here. Ex: Hello {{1}}!...",
            required: "Please enter template body",
            minimum: "Template body should be more then 5 characters",
            maximum: "Template body should be less then 1024 characters",
            addVariable: "Add Variable",
          },
          mediaInteractiveFormItem: {
            buildingBlocks: {
              title: "Building Blocks",
              description: "Select message template building blocks.",
              header: "Header",
              body: "Body",
              bodyMandatoryTooltip: "Body is mandatory for media templates",
              footer: "Footer",
              buttons: "Buttons",
            },
            headerFormItem: {
              title: "HEADER",
              placeholder:
                "Type your template header here. Ex: New Year Promotion!",
              required: "Please enter header",
              minimum: "Header should be more then 5 characters",
              maximum: "Header should be less then 60 characters",
              text: "Text",
              image: "Image",
              video: "Video",
              document: "Document",
            },
            bodyFormItem: {
              title: "BODY",
              placeholder: "Type your template body here. Ex: Hello {{1}}!...",
              required: "Please enter body",
              minimum: "Body should be more then 5 characters",
              maximum: "Body should be less then 1024 characters",
              addVariable: "Add Variable",
            },
            footerFormItem: {
              title: "FOOTER",
              placeholder:
                "Type your template footer here. Ex: Powered by iNextLabs",
              required: "Please enter footer",
              minimum: "Footer should be more then 5 characters",
              maximum: "Footer should be less then 60 characters",
              invalid: "Do not use placeholders in the footer",
            },
            buttonsFormItem: {
              title: "BUTTONS",
              maximum: "You can add maximum 10 buttons",
              addButton: "Add Button",
              infoText:
                "Buttons are optional interactive components, perform specific actions when tapped in templates, allowing up to 10 buttons with limits on types and combinations.",
              validGrouping: {
                exampleTitle: "Examples of valid groupings",
                infoText:
                  "You can either have all the “Quick Reply” buttons first and then have all the “URL / Phone Number” buttons or vice versa.",
              },
              invalidGrouping: {
                exampleTitle: "Examples of invalid groupings",
                infoText:
                  "You cannot have “Quick Reply buttons between “URL / Phone Number” buttons or vice versa.",
              },
              buttonType: {
                quickReply: "Quick Reply",
              },
              button: {
                title: "BUTTON",
                callToAction: {
                  url: {
                    title: "URL",
                  },
                  phoneNumber: {
                    title: "Phone Number",
                  },
                },
              },
            },
          },
          carouselFormItem: {
            title: "CAROUSEL",
            description:
              "A carousel template is a collection of cards that can be used to showcase multiple products, services, or any other items in a single message, with a horizontal scroll view.",
            cards: {
              title: "CARDS",
              addCard: "Add Card",
              description:
                "Each card is an interactive component that can contain header, body, and buttons. You can add up to 10 cards.",
              card: "Card",
              header: {
                title: "HEADER",
                image: "Image",
                video: "Video",
              },
              body: {
                title: "BODY",
                required: "Please input your card body!",
                minimum: "Card body should be more then 5 characters",
                maximum: "Card body should be less then 150 characters",
                invalid: "Placeholders are not allowed in the card body!",
                placeholder:
                  "Type your card body here. Ex: Delicious food, great service...",
              },
              buttons: {
                title: "BUTTONS",
                addButton: "Add Button",
                button: "Button",
              },
            },
          },
        },
        formFooter: {
          noAccess: "You do not have required permission",
          previewAndSubmit: {
            infoText: "Preview & Submit",
            buttonText: "Preview & Submit",
          },
          cancel: {
            infoText: "Cancel",
            buttonText: "Cancel",
          },
        },
      },
      preview: {
        title: "PREVIEW TEMPLATE",
        exampleInfoText:
          "The example values will be sent to Meta for template approval. It will help to validate the template and speed up the approval process. Please provide meaningful value to the placeholder for approval purpose.",
        valueMapping: {
          templateHeader: {
            title: "MESSAGE HEADER",
            description: "Enter the example values for the placeholders below",
            image: {
              description: "Enter the example URL for the image below",
              invalid: "Enter the valid image URL",
            },
            video: {
              description: "Enter the example URL for the video below",
              invalid: "Enter the valid video URL",
            },
            document: {
              description: "Enter the example URL for the document below",
              invalid: "Enter the valid document URL",
            },
          },
          templateBody: {
            title: "MESSAGE BODY",
            description: "Enter the example values for the placeholders below",
            noPlaceholders:
              "You haven't used any variable placeholders in your text",
            infoToUsePlaceholder:
              "In order to add a variable, go back to the editor and insert at the given position,",
            exampleForPlaceholder:
              "Example: Hey {{1}}! Your order {{2}} is ready for pickup.",
          },
          templateFooter: {
            title: "MESSAGE FOOTER",
          },
          templateButton: {
            title: "BUTTONS",
            description: "Enter the example values for the placeholders below.",
            url: "URL",
          },
          carousel: {
            title: "CAROUSEL",
            card: {
              title: "Card",
              header: {
                exampleInfoText:
                  "Enter the example image or video URL for each card.",
                placeholder: "Ex: https://inextlabs.ai/inextlabs-logo.png",
                invalid: "Enter the valid image or video URL",
              },
            },
          },
        },
        messagePreview: {
          title: "MESSAGE PREVIEW",
        },
        footer: {
          backToEditor: {
            infoText: "Back to Editor",
            buttonText: "Back to Editor",
          },
          submitTemplate: {
            warningText: "Please provide example values for the placeholders",
            infoText: "Submit Template",
            buttonText: "Submit Template",
          },
        },
      },
    },
  },
  accountSettings: {
    billing: {
      title: "Billing",
      subscription: {
        title: "Subscription",
        sideMenu: {
          contactDetails: {
            title: "Contact Details",
          },
          billingDetails: {
            title: "Billing Details",
          },
          paymentMethod: {
            title: "Payment Method",
            paymentGateway: {
              title: "Payment Gateway",
            },
          },
        },
        subscriptionDetails: {
          title: "Subscriptions",
          subscriptionId: "Subscription Id",
          email: "Email",
          subscriptionTerm: "Subscription Term",
        },
        notAvailable: {
          title: "Subscription info not found",
          info: " The Subscription Information page is your central hub for managing all aspects of your subscription to our services. This page provides you with a comprehensive overview of your current subscription plan, billing details, and options for making changes to your subscription. If your subscription info is missing, it could be due to the following reasons:",
          reason1: {
            title: "Enterprise Plan:",
            description:
              " Customers on an enterprise plan may have customized billing arrangements and features that are managed separately from the standard subscription interface.",
          },
          reason2: {
            title: "Billed via Partner:",
            description:
              " If the customer's subscription is billed through a partner or third-party entity, their subscription details might not be directly accessible in the user account settings.",
          },
          reason3: {
            title: "Onboarding Team Update:",
            description:
              " In cases where the onboarding team hasn't yet updated the subscription details, customers may need to wait for their specific plan information to be integrated into the system before it becomes visible in their account settings.",
          },
          support:
            "Please reach out to our support team at support@inextlabs.com for clarification or support.",
        },
      },
    },
    api: {
      appRegistration: {
        noAccess: "You do not have required permission",
        title: "App Registration",
        appDetails: "App Details",
        appName: {
          title: "App Name:",
          description:
            "This is the name of your application. You can change this name at any time.",
        },
        clientId: {
          title: "Client Id:",
          description:
            "This is the unique identifier for your application. You will need this to authenticate your application with our API.",
        },
        clientSecret: {
          title: "Client Secret:",
          description:
            "This is the unique secret for your application. Please treat this as confidential and do not share.",
          expiresAt: "Expires At:",
          createdAt: "Created At:",
        },
        redirectUri: {
          title: "Redirect URIs:",
          description1:
            "We only allow certain website addresses to receive your login or logout information after you've successfully logged in or out.",
          description2:
            "The redirect URI provided in your login server request must match one of the listed URIs.",
          noRedirectUri: "No Redirect URIs found",
        },
        editButton: "Edit",
        deleteButton: "Delete",

        form: {
          noAccess: "You do not have required permission",
          appNameFormItem: {
            title: "App Name",
            label: "Provide a name for your application",
            description:
              "Provide a name for your application. This name will be displayed in the application list and will help you to identify the application.",
            placeholder: "Enter the app name",
            required: "Please enter the app name",
            minimum: "App name should be more then 3 characters",
            maximum: "App name should be less then 100 characters",
          },
          clientSecretNameFormItem: {
            title: "Secret Name",
            label: "Provide a name for your secret",
            description:
              "Provide a name for your secret. Please treat this as confidential and do not share.",
            placeholder: "Enter secret name",
            required: "Please enter secret name",
            minimum: "Secret name should be more then 5 characters",
            maximum: "Secret name should be less then 100 characters",
          },
          redirectUriFormItem: {
            title: "Redirect URI",
            label: "Provide a redirect URI for your application",
            description:
              "The redirect URI is the endpoint to which the authorization server redirects after successfully authorizing the app.",
            placeholder: "Enter the redirect URI",
            required: "Please enter redirect URI",
            minimum: "Redirect URI should be more then 5 characters",
            maximum: "Redirect URI should be less then 200 characters",
            invalid: "Please enter valid redirect URI",
          },

          submit: {
            infoText: "Submit",
            buttonText: "Submit",
          },
          cancel: {
            infoText: "Cancel",
            buttonText: "Cancel",
          },
        },
      },
    },
    deliveryServices: {
      title: "Delivery Services",
      lalamove: {
        noAccess: "You do not have required permission",
        title: "Lalamove",
        goBackToSettings: "Go back to Settings",
        form: {
          noAccess: "You do not have required permission",
          apiKeyFormItem: {
            title: "API Key",
            label: "API Key",
            description: "Configure your Lalamove API Key to enable delivery.",
            placeholder: "Enter your API Key",
            required: "Please enter the API Key",
            minimum: "API Key should be more then 3 characters",
            maximum: "API Key should be less then 100 characters",
          },
          appSecretFormItem: {
            title: "APP Secret",
            label: "APP Secret",
            description:
              "Configure your Lalamove APP Secret to enable delivery.",
            placeholder: "Enter your APP Secret",
            required: "Please enter the APP Secret",
            minimum: "APP Secret should be more then 3 characters",
            maximum: "APP Secret should be less then 100 characters",
          },
          pickupAddressFormItem: {
            title: "Pickup Address",
            label: "Address",
            description:
              "Configure your Pickup Address for Lalamove order pickup.",
            name: {
              label: "Branch Name",
              placeholder:
                "Enter user-friendly name such as branch name or store name...",
              required: "Please enter the Branch Name",
              minimum: "Name should be more then 3 characters",
              maximum: "Name should be less then 100 characters",
            },
            phoneNumber: {
              label: "Branch Phone Number",
              placeholder: "Phone number such as +65 12345678...",
              required: "Please enter the Branch Phone Number",
              minimum: "Phone Number should be more then 3 characters",
              maximum: "Phone Number should be less then 20 characters",
            },
            address: {
              label: "Branch Address",
              placeholder:
                "Enter your address such as street name, building name, floor number...",
              required: "Please enter the Pickup Address",
              minimum: "Pickup Address should be more then 3 characters",
              maximum: "Pickup Address should be less then 100 characters",
            },
          },
          languageSelectionFormItem: {
            title: "Language",
            label: "Language",
            description:
              "Configure your preferred Language for Lalamove delivery.",
            placeholder: "Select your preferred Language",
            required: "Please select the Language",
          },
          marketSelectionFormItem: {
            title: "Market",
            label: "Market",
            description:
              "Configure your preferred Market for Lalamove delivery.",
            placeholder:
              "Select your preferred Market like Singapore, Indonesia, Malaysia...",
            required: "Please select the Market",
          },
          isEnableFormItem: {
            title: "Status",
            label: "Status",
            description: "Enable or Disable Lalamove delivery.",
          },
          scheduleAtFormItem: {
            title: "Schedule At",
            label: "Schedule At",
            description: "Configure your delay time for Lalamove order pickup.",
            placeholder: "Select your preferred delay time",
            required: "Please select the delay time",
          },
          serviceTypeFormItem: {
            title: "Service Type",
            label: "Service Type",
            description:
              "Configure your preferred Service Type for Lalamove delivery.",
            placeholder: "Select your preferred Service Type",
            required: "Please select the Service Type",
          },
          save: {
            infoText: "Save",
            buttonText: "Save",
          },
          cancel: {
            infoText: "Cancel",
            buttonText: "Cancel",
          },
        },
      },
    },
  },

  botKnowledgeArea: {
    botKnowledgebase: {
      moduleNotSubscribed: {
        title: "Bot Knowledgebase",
        description:
          "Unlock the potential of your workplace with a cutting-edge GPT-powered bot designed to enhance the 'Bring Your Own Data' (BYOD) experience. Harness the power of AI to streamline operations, empower your employees, and elevate productivity in the modern, device-diverse workplace.",

        benefit1: {
          description:
            "You can build your Bot Knowledgebase by importing file from your local machine.",
        },
        benefit2: {
          description:
            "User-contributed knowledge empowers the bot to handle complexity and tackle unique challenges effectively.",
        },
        buttons: {
          activateNow: "Activate Now",
          knowMore: "Know More",
        },
      },
      header: {
        noAccess: "You do not have required permission",
        addKnowledgebase: {
          infoText: "Add",
          buttonText: "Add",
          formPanelTitle: "Add New Knowledgebase",
        },
        searchKnowledgebase: {
          placeholder: "Search by KB Name",
        },
        testKnowledgebase: {
          infoText: "Test",
          buttonText: "Test",
          formPanelTitle: "Test",
        },
      },
      formPanel: {
        bestPractices: {
          title: "Best Practices",
          bestPractice1: {
            title: "Quality Pre-processing",
            description:
              "Streamline document processing for cleaner data. This involves metadata removal, special character handling, and text normalization, allowing the model to focus on language patterns, not data noise.",
          },
          bestPractice2: {
            title: "Balanced Tokenization",
            description:
              "Find the right tokenization balance. Too detailed tokens use too much memory, while overly coarse tokens lose vital context. Experiment with different methods to strike that balance.",
          },
          bestPractice3: {
            title: "Remove Redundancy",
            description:
              "Remove duplicates and similar documents to improve model training efficiency. Use techniques like stemming and lemmatization to reduce word dimensionality.",
          },
          bestPractice4: {
            title: "Data Augmentation",
            description:
              "Diversify training data using augmentation techniques, such as synonym replacement, paraphrasing, and text variations, to improve model generalization and versatility.",
          },
          next: {
            infoText: "Next",
            buttonText: "Next",
          },
        },
        uploadFileForm: {
          title: "Upload File",
          loadingMessage: "Submitting... Please wait...",
          error: "Form Submission Failed! Please check the form for errors",
          knowledgebaseNameFormItem: {
            title: "Knowledgebase Name",
            label: "Provide a name for your knowledgebase",
            required: "Please enter the knowledgebase name",
            maximum: "Knowledgebase name should be less then 35 characters",
            placeholder: "Example: FAQ, Catalog, Customer Support...",
          },
          knowledgebaseFileFormItem: {
            title: "Knowledgebase File",
            label:
              "Based on this file, your bot will be trained to answer user queries",
            required: "Please upload your file",
            maximumSizeError: "File size should be less than 1MB",
            chooseAnotherFile: "Choose another file",
            infoText1: "Click here to upload your",
            infoText2: "Document File",
            note: {
              title: "Note:",
              description: "Upload DOC, DOCX, or PDF files under 1MB",
            },
          },
          knowledgebaseVersionFormItem: {
            title: "Knowledgebase Version",
            label:
              "Versions are helpful for determining which knowledgebase needs to be used",
            required: "Please enter the knowledgebase version",
            maximum: "Knowledgebase version should be less then 5 characters",
            pattern: "Knowledgebase version must be number only",
            placeholder: "Example: 1.0, 2.0, 3.0...",
          },
          knowledgebaseTagsFormList: {
            title: "Tags",
            label:
              "Tags are used to categorize knowledgebases for better organization",
            keyFormItem: {
              placeholder: "Example: Country, Product...",
              maximum: "Tag key must be less than 35 character",
              pattern: "Tag key must be text only",
            },
            valueFormItem: {
              placeholder: "Example: Singapore, Restaurant...",
              maximum: "Tag value must be less than 35 character",
              pattern: "Tag value must be text only",
            },
          },
          previous: {
            infoText: "Previous",
            buttonText: "Previous",
          },
          save: {
            infoText: "Save + Review",
            buttonText: "Save + Review",
          },
        },
        review: {
          error: "Submission Failed! Please check the form for errors",
          loadingMessage:
            "Creating knowledgebase... This may take a few minutes. Please wait...",

          title: "Review + Submit",
          warningText: "Please complete the previous steps to view this step",
          label:
            "Please review the processed file to ensure all the data is correct. Make any necessary changes before submitting.",
          chunkedFileFormList: {
            sectionNameFormItem: {
              maximum: "Section name must be less than 200 character",
              pattern: "Section name must be text only",
              placeholder: "Please enter the section name",
            },
            titleFormItem: {
              maximum: "Title must be less than 200 character",
              pattern: "Title must be text only",
              placeholder: "Please enter the title",
            },
            contentFormItem: {
              maximum: "Content must be less than 4000 character",
              placeholder: "Please enter the content",
            },
            delete: {
              title: "Are you sure to delete this section?",
              yes: "Yes",
              no: "No",
            },
          },
          previous: {
            infoText: "Previous",
            buttonText: "Previous",
          },
          submit: {
            infoText: "Submit",
            buttonText: "Submit",
          },
        },
      },
      table: {
        noAccess: "You do not have required permission",
        timestamp: {
          title: "TIMESTAMP",
          filters: {
            today: "Today",
            sevenDays: "Last 7 days",
            thirtyDays: "Last 30 days",
            sixtyDays: "Last 60 days",
          },
        },
        kbName: {
          title: "KB NAME",
        },
        version: {
          title: "VERSION",
        },
        fileName: {
          title: "FILE NAME",
        },
        status: {
          title: "STATUS",
          filters: {
            published: "Published",
            draft: "Draft",
          },
        },
        action: {
          title: "ACTION",
          edit: {
            title: "Edit Knowledgebase",
          },
          publish: {
            infoText: "Publish Knowledgebase",
            title: "Are you sure you want to publish the knowledgebase?",
            warningText:
              "When clicked the OK button, the knowledgebase will be published",
            ok: "Ok",
            cancel: "Cancel",
          },
          unpublish: {
            infoText: "Unpublish Knowledgebase",
            title: "Are you sure you want to unpublish the knowledgebase?",
            warningText:
              "When clicked the OK button, the knowledgebase will be unpublished",
            ok: "Ok",
            cancel: "Cancel",
          },
          delete: {
            title: "Are you sure you want to delete the knowledgebase?",
            warningText:
              "When clicked the OK button, the knowledgebase will be deleted permanently",
            ok: "Ok",
            cancel: "Cancel",
          },
        },
      },
      test: {
        title: "Test",
        loadingMessage: "Please wait...",
        errorMessage: "Error testing...",
        knowledgebaseTestFormItem: {
          required: "Please enter a question",
          maximum: "Question should be less then 100 characters",
          placeholder: "Enter a question to test the knowledgebase",
        },
        testResult: {
          answer: "Answer:",
          tokens: "Tokens:",
          infoText: "answers will appear here.",
        },
      },
      section: {
        noAccess: "You do not have required permission",
        loadingMessage: "Loading... Please wait...",
        header: {
          searchSectionInputBox: {
            placeholder: "Search by title or content",
          },

          addSectionButton: {
            infoText: "Add Section",
            buttonText: "Add Section",
          },
        },
        form: {
          loadingMessage: "Submitting... Please wait...",
          errorMessage: "Something went wrong, please try again later.",
          sectionTitleFormItem: {
            required: "Please enter the section title",
            maximum: "Section title should be less then 200 characters",
            placeholder:
              "Provide a title for this section. Ex: How to create a new ticket?",
          },
          sectionContentFormItem: {
            required: "Please enter the section content",
            maximum: "Section content should be less then 4000 characters",
            placeholder:
              "Provide the content for this section. Ex: To create a new ticket...",
          },
          saveButton: {
            infoText: "Save",
            buttonText: "Save",
          },
          cancelButton: {
            infoText: "Cancel",
            buttonText: "Cancel",
          },
          deleteButton: {
            title: "Are you sure you want to delete this section?",
            okText: "Yes",
            cancelText: "No",
          },
          empty: {
            description: "No content found",
          },
        },
      },
    },
    whatsappFlows: {
      header: {
        noAccess: "You do not have required permission",
        createWhatsappFlow: {
          infoText: "Create Flow",
          buttonText: "Create Flow",
          formPanelTitle: "Create New Flow",
        },
        searchWhatsappFlows: {
          placeholder: "Search by flow name",
        },
      },
      table: {
        noAccess: "You do not have required permission",
        name: {
          title: "FLOW NAME",
        },
        categories: {
          title: "CATEGORIES",
        },
        status: {
          title: "STATUS",
        },
        action: {
          title: "ACTION",
          edit: {
            title: "Edit Flow",
          },
          delete: {
            title: "Are you sure you want to delete the flow?",
            warningText:
              "When clicked the OK button, the flow will be deleted permanently!",
            ok: "Ok",
            cancel: "Cancel",
          },
        },
      },
    },
  },
};
