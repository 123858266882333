const dashBoardReducer = (state, action) => {
  switch (action.type) {
    case "SET_BROADCASTS_COUNT":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            broadcastsCount: action.payload.broadcastsCount,
          },
        },
      };

    case "SET_BROADCASTS_MESSAGE_SENT_COUNT":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            broadcastsMessageSentCount:
              action.payload.broadcastsMessageSentCount,
          },
        },
      };

    case "RD_SET_CLIENT_RESPONSES_COUNT":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            clientResponsesCount: action.payload,
          },
        },
      };

    case "RD_SET_WAITING_FOR_LIVE_AGENT_COUNT":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            waitingForLiveAgent: action.payload.liveAgentWaitingCount,
          },
        },
      };

    case "RD_SET_CONVERSATION_BY_DATE":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            conversationByDate: action.payload.conversationByDate,
          },
        },
      };

    case "RD_SET_CONVERSATION_BY_TODAY_COUNT":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            conversationByToday: action.payload.conversationByToday,
          },
        },
      };

    case "RD_SET_LATEST_NEWS_UPDATE":
      return {
        ...state,
        dashBoard: {
          ...state.dashBoard,
          ...{
            latestNews: action.payload.latestNews,
          },
        },
      };

    default:
      return state;
  }
};

export default dashBoardReducer;
