import React from "react";
import { Row, Col, Space, Typography } from "antd";
import { TinyArea } from "@ant-design/plots";
import { useGlobalContext } from "../../contexts/globalContext";
import useConnectMiddleware from "../../connect/connectMiddleware";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { mode } from "../../constants/env";

const { Text, Title } = Typography;

const ClientResponsesTotalCount = ({ period }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  useConnectMiddleware(
    () => {
      dispatchReducer({
        type: "RD_SET_CLIENT_RESPONSES_COUNT",
        payload: undefined,
      });
      dispatchMiddleware({
        type: "MWD_LOAD_CLIENT_RESPONSES_COUNT",
        payload: { component: "client-responses-tiny-chart" },
      });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  let clientResponsesCount = state.dashBoard.clientResponsesCount;

  // For demo account only showing dummy data
  if (
    mode === "STAGING" &&
    state.currentAccount.account.database === "inextlabs-demo"
  ) {
    clientResponsesCount = [
      100, 0, 20, 0, 0, 0, 10, 0, 0, 0, 60, 0, 100, 0, 10, 0, 1, 50, 5, 0, 0, 3,
      0, 0, 0, 97, 1, 0, 3, 80,
    ];
  }

  const botToBeEdited = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const config = {
    data: clientResponsesCount,
    height: 50,
    autoFit: true,
    smooth: true,
    line: {
      color: "#237804",
    },
    areaStyle: {
      fill: "#d9f7be",
    },
  };

  return (
    <>
      {clientResponsesCount ? (
        <Row>
          <Col span={24}>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ textAlign: "center" }}>
                {["STANDARD", "STARTER", "ADVANTAGE"].includes(
                  botToBeEdited.subscription_package
                )
                  ? "TOTAL RESPONSES"
                  : " CLIENT RESPONSES"}
              </Title>
              <Title level={1} style={{ textAlign: "center" }}>
                {clientResponsesCount.reduce(
                  (previousResponseCount, currentResponseCount) =>
                    previousResponseCount + currentResponseCount,
                  0
                )}
              </Title>
            </Space>
          </Col>
          <Col span={24}>
            <TinyArea {...config} />
          </Col>
          <Col span={24}>
            <Text type="secondary" italic>
              last {period} days
            </Text>
          </Col>
        </Row>
      ) : (
        <ComponentLoader
          component="client-responses-tiny-chart"
          loadingMessage=""
        />
      )}
    </>
  );
};

export default ClientResponsesTotalCount;
