import { notification, Typography, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Text } = Typography;

const openNotificationWithIcon = (type, message) => {
  let style = {};
  if (type === "success") {
    style = {
      color: "rgba(0, 0, 0, 0.65)",
      border: "1px solid #b7eb8f",
      backgroundColor: "#f6ffed",
    };
  } else if (type === "error") {
    style = {
      color: "rgba(0, 0, 0, 0.65)",
      border: "1px solid #ffa39e",
      backgroundColor: "#fff1f0",
    };
  }

  if (!message) {
    message =
      "There seems to be a system error. Please try again after sometime.";
  }

  notification[type]({
    message,
    style,
  });
};

export default openNotificationWithIcon;

// Module will not have multiple default exports
export const openErrorNotificationWithDeveloperMessage = (error) => {
  let style = {
    color: "rgba(0, 0, 0, 0.65)",
    border: "1px solid #ffa39e",
    backgroundColor: "#fff1f0",
  };

  let description = "";
  let title = "";

  if (error?.title && error?.description) {
    title = (error.code || "") + " " + error.title;
    description = error.description;
  } else {
    // If message is not available, display a default error message
    title = "System Error";
    description =
      "There seems to be a system error. Please try again after some time.";
  }

  notification["error"]({
    message: <Text strong>{title}</Text>,
    description: <Text>{description}</Text>,
    style,
    btn: error.developer_message && (
      <Collapse
        size="small"
        ghost
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{
              color: "#F05742",
              fontSize: "13px",
            }}
          />
        )}
      >
        <Collapse.Panel
          header={
            <Text
              type="danger"
              style={{
                fontSize: "13px",
              }}
            >
              Show more details
            </Text>
          }
          key="1"
          className="show-more-details-btn"
          style={{
            maxHeight: 400,
            overflow: "auto",
          }}
        >
          <Text>
            {typeof error.developer_message === "object"
              ? JSON.stringify(error.developer_message)
              : error.developer_message}
          </Text>
        </Collapse.Panel>
      </Collapse>
    ),
  });
};
