import React from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Col, Row, Result, Button, Space } from "antd";
import BotKnowledgebaseTest from "./BotKnowledgebaseTest";
import BotKnowledgebaseHeader from "./BotKnowledgebaseHeader";
import ModuleNotSubscribed from "../../../shared-components/ModuleNotSubscribed";
import AccessDenied from "../../../shared-components/AccessDenied";
import useConnectMiddleware from "../../../connect/connectMiddleware";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import BotKnowledgebaseTable from "./BotKnowledgebaseTable";
import BotKnowledgebaseFormPanel from "./BotKnowledgebaseFormPanel";
import BotKnowledgebaseInfoPanel from "./BotKnowledgebaseInfoPanel";
import { URIs } from "../../../constants/env";

const BotKnowledgebase = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const gptBotInAction = botInAction.qna?.qna_source?.toUpperCase() === "GPT";

  useConnectMiddleware(
    () => {
      gptBotInAction &&
        state.botKnowledgebase &&
        botInAction.qna?.version >= 2 &&
        dispatchReducer({
          type: "CLEAR_BOT_KNOWLEDGEBASE_FILES",
        });

      state.currentUser.permission.BOT_KNOWLEDGEBASE_VIEW &&
        gptBotInAction &&
        botInAction.qna?.version >= 2 &&
        dispatchMiddleware({
          type: "LOAD_BOT_KNOWLEDGEBASE_DETAILS",
          payload: {
            botInAction,
            component: "knowledgebase-table",
          },
        });
    },
    {
      botInAction: state.application.botInAction,
    }
  );

  return state.currentUser.permission.BOT_KNOWLEDGEBASE_VIEW ? (
    gptBotInAction ? (
      botInAction.qna?.version >= 2 ? (
        state.botKnowledgebase ? (
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <BotKnowledgebaseHeader />
            </Col>

            <Col
              span={
                state.infoPanel?.["knowledgebase-test-info-panel"]?.visible
                  ? 17
                  : 24
              }
            >
              <BotKnowledgebaseTable botInAction={botInAction} />
            </Col>

            <Col
              span={
                state.infoPanel?.["knowledgebase-test-info-panel"]?.visible
                  ? 7
                  : 0
              }
            >
              <BotKnowledgebaseTest botInAction={botInAction} />
            </Col>
            <BotKnowledgebaseFormPanel />
            <BotKnowledgebaseInfoPanel botInAction={botInAction} />
          </Row>
        ) : (
          <FullPageLoader
            component="knowledgebase-table"
            loadingMessage="Loading... Please wait..."
          />
        )
      ) : (
        <Result
          title="Knowledgebase Version Not Supported"
          subTitle="
          This screen is only available for knowledgebase version 2 and above. For upgrading your knowledgebase version, please contact our support team."
          extra={
            <Space>
              <Button
                type="primary"
                key="contactSupport"
                onClick={async () => {
                  window.open(
                    "mailto:support@inextlabs.com?subject=" +
                      `Request to upgrade knowledgebase version - ${state.currentAccount.account?.general?.company}` +
                      "&body=" +
                      "Hi, I would like to request an upgrade of my knowledgebase version. Please assist me with the process."
                  );
                }}
              >
                Contact Support
              </Button>

              <Button
                key="learnMore"
                onClick={() =>
                  window.open(
                    URIs.HELP_AND_SUPPORT_DOCUMENTATION.concat(
                      "/docs/Overview/index.html"
                    )
                  )
                }
              >
                Learn More
              </Button>
            </Space>
          }
        />
      )
    ) : (
      <ModuleNotSubscribed module="bot-knowledgebase" />
    )
  ) : (
    <AccessDenied />
  );
};

export default BotKnowledgebase;
